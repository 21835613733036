import React, {useCallback, useMemo} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {AssetFolder} from 'lib/models';
// actions
import {deleteItem, setCollection} from 'actions/shared';
// components
import DeleteDialog from 'components/core/ui/DeleteDialog';


/**
 * Render deleting folder dialog
 * @param {object} props - component props
 * @param {boolean} props.open - open dialog flag
 */
export default function DeleteFolderDialog(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();
    const folder_path = history.location.state?.folderPath || match.params.identifier;

    const asset_folders_placement = useMemo(() => new AssetFolder().getPlacement(), []);
    const asset_folders_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);

    const {folder, folders} = useSelector((state) => {
        const folderIds = folder_path?.split('_').slice(-2);
        // undefined id - root folder
        const [parentId, folderId] = folderIds ? folderIds.length === 1 ? [undefined, folderIds[0]] : folderIds : [];
        const parentFolders = state.shared.get('items')
            .get(parentId ? `${asset_folders_placement}-${parentId}` : asset_folders_placement);
        const currentFolder = parentFolders?.find((childFolder) => childFolder.get(asset_folders_identifier) === folderId);

        return {folder: currentFolder, folders: parentFolders};
    }, shallowEqual);

    const onClose = useCallback((newPath) => {
        history.push(newPath || history.location.pathname.replace('/delete', ''));
    }, [history.location.pathname]);

    return <DeleteDialog open={props.open} handleClose={() => onClose()}
        item={intl.formatMessage({id: 'assets.list.deletedialog.delete_folder'})}
        handleConfirm={() => {
            dispatch(deleteItem(
                AssetFolder, `${asset_folders_placement}-${folder?.get(asset_folders_identifier)}`,
                folder?.getIn(['links', 'self']), folder
            )).then(() => {
                const parentId = folder.get('parent_uuid');
                const parentPlacement = parentId ? `${asset_folders_placement}-${parentId}` : asset_folders_placement;
                dispatch(setCollection(AssetFolder,  parentPlacement, 
                    folders.filter(childFolder => childFolder.get(asset_folders_identifier) !== folder.get(asset_folders_identifier))
                ));
                const newFolderPath = folder_path.split('_');
                newFolderPath.splice(-1);
                onClose(`/asset-manager/${newFolderPath.join('_')}`);
            });
        }} />;
}
