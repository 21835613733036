import React from 'react';
import {FormattedMessage} from 'react-intl';
import {reduxForm} from 'redux-form';
import {makeStyles} from '@material-ui/styles';
import validator from 'lib/valitator';
// components
import Form from 'components/core/ui/Form';
import DMUpgradeVersionField from 'components/core/ui/fields/DMUpgradeVersionField';
// material-ui
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/core/ui/mui/Button';
// icons
import ConfirmIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';


const useStyles = makeStyles((theme) => ({
    form: {
        margin: '0px',
        flexDirection: 'column',
        alignItems: 'stretch'
    }
}));

/**
 * Render confirmation of upgrading version
 *
 * @param {object} props - component props
 * @param {(name: string, value: any) => void} props.change - redux-form change function, required for reset switched field
 * @param {() => void} props.handleSubmit - redux-form submit function
 * @param {() => void} props.onClose - close dialog function
 */
function UpgradeConfirmation(props) {
    const {change, handleSubmit, onClose} = props;
    const classes = useStyles();

    return <Form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
            <DMUpgradeVersionField change={change} />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose()}>
                <CancelIcon />
                <FormattedMessage id='actions.cancel' />
            </Button>
            <Button type='submit' variant='contained' color='primary'>
                <ConfirmIcon />
                <FormattedMessage id='actions.confirm' />
            </Button>
        </DialogActions>
    </Form>;
}

const UpgradeConfirmationForm = reduxForm({
    form: 'upgradeConfirmationForm',
    validate: (data) => {
        const errors = {params: {}};
        if (data === undefined) { data = {}; }
        if (data.params === undefined) { data.params = {}; }
        const {version, url} = data.params;

        if (version) {
            validator.isEmpty(null, errors, 'params.url', url);
        } else if (url) {
            validator.isEmpty(null, errors, 'params.version', version);
        } else {
            validator.isNotNull(null, errors, 'params.version', version);
            validator.isNotNull(null, errors, 'params.url', url);
        }

        return errors;
    },
    enableReinitialize: true
})(UpgradeConfirmation);

export default UpgradeConfirmationForm;
