import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useLocalPagination, useLocalFilter, useLocalSort} from 'lib/filters';
import {Setting, SettingGroup, ProductGroup} from 'lib/models';
import {Map as ImmutableMap} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
import Field, {SearchField, SettingFieldType} from 'components/core/ui/Field';
import LocalPagination from 'components/core/ui/LocalPagination';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import Filters from 'components/core/ui/Filters';


/**
 * Renders Setting from backend - to view and manage them
 * With Local Pagination
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Setting().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement]),
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        groups_items: state.shared.getIn(['items', 'setting-groups']),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups'])
    }), shallowEqual);
    // router & style
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='settings.list.table.name' />},
        {sortName: 'param_name', label: <FormattedMessage id='settings.list.table.param_name' />},
        {sortName: 'group', label: <FormattedMessage id='settings.list.table.group' />},
        {label: <FormattedMessage id='settings.list.table.product_groups' />},
        {sortName: 'no_profiles', label: <FormattedMessage id='settings.list.table.no_profiles' />, numeric: true}
    ];
    // sorting & filtering & pagination
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded || !props.groups_loaded, new Setting().getPlacement(), tableHead.map(item => item.sortName).filter(sortName => !!sortName), 'name', 'desc', {
            group: (obj) => obj.get('group') && (props.groups_items.find(group => group.get(new SettingGroup().getUniqueIdentifier()) === obj.get('group')) || new ImmutableMap({name: ''})).get('name')
        });
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing || !props.product_groups_loaded, new Setting().getPlacement(),
        ['search', 'field_type', 'indexed', 'group', 'product_group'], ['name', 'param_name'],
        {
            indexed: (obj, value) => value === 'true'
                ? (obj.get('indexed') && obj.getIn(['indexed', 'enabled']))
                : !(obj.get('indexed') && obj.getIn(['indexed', 'enabled'])),
            group: (obj, value) => obj.get('group') && obj.get('group') === props.groups_items.map(group => group.get(new SettingGroup().getUniqueIdentifier())).find(group_id => group_id === value),
            product_group: (obj, value) => obj.get('product_groups') && obj.get('product_groups').includes(props.product_groups_items.map(product_group => product_group.get(new ProductGroup().getUniqueIdentifier())).find(product_group_id => product_group_id === value))
        });
    const [paginatedItems, page, pages, setPage] = useLocalPagination(filteredItems);
    // related sorting
    const [sortedGroups] = useLocalSort(props.groups_items, !props.groups_loaded);
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Setting, items_placement, 'settings'));
        }
    }, [props.loaded]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.groups_loaded === false) {
            dispatch(fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false}));
        }
    }, [props.groups_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='settings.list.title' />}
            subheader={<FormattedMessage id='settings.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/settings/add')}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='settings' />
            </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='settings.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <SettingFieldType fieldType='NoReduxSelect' value={filters.field_type || ''}
                              onChange={event => filterItems('field_type', event.target.value)} />
            <Field label={<FormattedMessage id='settings.list.filter.indexed' />}
                   fieldType='NoReduxSelect' value={filters.indexed || ''}
                   onChange={event => filterItems('indexed', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'><FormattedMessage id='settings.list.filter.indexed.choice.true' /></MenuItem>
                <MenuItem value='false'><FormattedMessage id='settings.list.filter.indexed.choice.false' /></MenuItem>
            </Field>
            <SpaceDivider none />
            <Field label={<FormattedMessage id='settings.list.filter.group' />}
                   fieldType='NoReduxSelect' value={filters.group || ''}
                   onChange={event => filterItems('group', event.target.value)}
                   loading={!props.groups_loaded}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {sortedGroups.map((group, idx) =>
                    <MenuItem key={idx} value={group.get(new SettingGroup().getUniqueIdentifier())}>{group.get('name')}</MenuItem>
                )}
            </Field>
            <Field label={<FormattedMessage id='settings.list.filter.product_group' />}
                   fieldType='NoReduxSelect' value={filters.product_group || ''}
                   onChange={event => filterItems('product_group', event.target.value)}
                   loading={!props.product_groups_loaded}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {sortedProductGroups.map((product_group, idx) =>
                    <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                )}
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'settings.list.table.empty'}
                                              values={{link: passed_props.permission === 'RW'
                                                      ? <Link to='/settings/add' className='hover-border'>
                                                          <FormattedMessage id='settings.list.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='settings.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && paginatedItems.map((setting, idx) => {
                        const onClick = () => props.state === null ? history.push(`/settings/${setting.get(new Setting().getUniqueIdentifier())}`) : {};
                        const group = setting.get('group') && props.groups_items.find(el => el.get(new SettingGroup().getUniqueIdentifier()) === setting.get('group'));

                        return <TableRow key={idx} hover>
                            <TableCell link onClick={onClick}>
                                {setting.get('name')}
                            </TableCell>
                            <TableCell link onClick={onClick}>
                                {setting.get('param_name')}
                            </TableCell>
                            <TableCell>
                                {!props.groups_loaded
                                    ? <CircularProgress size={36} />
                                    : group
                                        ? <Link className='hover-border' to={`/setting-groups/${group.get(new SettingGroup().getUniqueIdentifier())}`}>
                                            {group.get('name')}
                                        </Link>
                                        : ''}
                            </TableCell>
                            <TableCell>
                                {!props.product_groups_loaded
                                    ? <CircularProgress size={36} />
                                    : setting.get('product_groups')
                                    ? setting.get('product_groups').map((product_group_id, idx) => {
                                        const product_group = props.product_groups_items.find(el => el.get(new ProductGroup().getUniqueIdentifier()) === product_group_id);

                                        return product_group
                                            ? <span key={idx} className='divide-links'>
                                                <Link className='hover-border' to={`/product-families/${product_group.get(new ProductGroup().getUniqueIdentifier())}`}>
                                                    {product_group.get('name')}
                                                </Link>
                                            </span>
                                            : '';
                                    })
                                    : ''}
                            </TableCell>
                            <TableCell numeric link onClick={onClick}>
                                {setting.get('no_profiles')}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <LocalPagination page={page} pages={pages}
                                 onChange={(page) => setPage(page)} />
            </Table>} />
    </Card>;
}
