import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// components
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
// material-ui
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles((theme) => ({
    table: {
        '& .MuiTableCell-root': {
            color: theme.palette.snomGrey[800],
            padding: `0 ${theme.spacing(1)}px`,
            '&.fileType': {
                minWidth: 0,
                width: `${theme.spacing(20)}px`,
                paddingLeft: `${theme.spacing(8)}px`
            },
            '&.date': {
                minWidth: 0,
                width: `${theme.spacing(12.5)}px`
            }
        }
    }
}));

/**
 * Render asset files and fodlers in list
 * @param {object} props - props object
 * @param {boolean} props.isLoading - loading flag
 * @param {boolean} props.editable - allow manage
 */
export default function AssetFileList(props) {
    const {isLoading, editable, children} = props;
    const classes = useStyles();

    return <Table className={classes.table} size={4}>
        <TableHead>
            <TableRow>
                <TableCell className='fileType'><FormattedMessage id='assets.file_manager.list.header.type' /></TableCell>
                <TableCell><FormattedMessage id='assets.file_manager.list.header.name' /></TableCell>
                <TableCell className='date'><FormattedMessage id='assets.file_manager.list.header.size' /></TableCell>
                <TableCell className='date'><FormattedMessage id='assets.file_manager.list.header.uploadDate' /></TableCell>
                {editable && <TableCell checkbox />}
            </TableRow>
        </TableHead>
        <TableBody>
            {isLoading ? <TableRow>
                <TableCell colSpan='100%'><LinearProgress /></TableCell>
            </TableRow> : children}
        </TableBody>
    </Table>;
}
