/**
 * Try to find Product based on provided MAC
 *
 * @param mac - Phone MAC
 * @param products - List of ALL products
 * @private
 */
// are macs in range based on Products
export function findProductByMac(mac, products) {
    // literate through every product
    return (products || []).find(product => {
        return (product.get('mac_ranges') || []).some(mac_range => {
            // get start and end mac_range
            const start = mac_range.get('start').toString(16).toLowerCase();
            const end = mac_range.get('end').toString(16).toLowerCase();
            return (start <= mac && mac <= end);
        });
    });
}
