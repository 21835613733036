import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Simple select field for Setting.field_type
 */
export default function SettingFieldType(props) {
    // Split some stuff from props
    const {name, label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' name={name || 'field_type'}
                  label={label || `${intl.formatMessage({id: 'settingfieldtypefield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        <MenuItem value='text'><FormattedMessage id='settingfieldtypefield.choices.text' /></MenuItem>
        <MenuItem value='textarea'><FormattedMessage id='settingfieldtypefield.choices.textarea' /></MenuItem>
        <MenuItem value='password'><FormattedMessage id='settingfieldtypefield.choices.password' /></MenuItem>
        <MenuItem value='number'><FormattedMessage id='settingfieldtypefield.choices.number' /></MenuItem>
        <MenuItem value='url'><FormattedMessage id='settingfieldtypefield.choices.url' /></MenuItem>
        <MenuItem value='url_image'><FormattedMessage id='settingfieldtypefield.choices.url_image' /></MenuItem>
        <MenuItem value='ip'><FormattedMessage id='settingfieldtypefield.choices.ip' /></MenuItem>
        <MenuItem value='color'><FormattedMessage id='settingfieldtypefield.choices.color' /></MenuItem>
        <MenuItem value='time'><FormattedMessage id='settingfieldtypefield.choices.time' /></MenuItem>
        <MenuItem value='checkbox'><FormattedMessage id='settingfieldtypefield.choices.checkbox' /></MenuItem>
        <MenuItem value='select'><FormattedMessage id='settingfieldtypefield.choices.select' /></MenuItem>
        <MenuItem value='certificate'><FormattedMessage id='settingfieldtypefield.choices.certificate' /></MenuItem>
    </Field>;
}
