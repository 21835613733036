import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import Moment from 'moment';
import {Project} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField} from 'components/core/ui/Field';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Products from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Project().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'order_date', label: <FormattedMessage id='projects.list.table.order_date' />},
        {sortName: 'project_name', label: <FormattedMessage id='projects.list.table.project_name' />},
        {sortName: 'project_stage', label: <FormattedMessage id='projects.list.table.project_stage' />},
        {sortName: 'enduser_company_contact_person', label: <FormattedMessage id='projects.list.table.enduser_company_contact_person' />},
        {sortName: 'enduser_company_name', label: <FormattedMessage id='projects.list.table.enduser_company_name' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName).filter(sortName => !!sortName), 'order_date', 'asc');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['search'], ['project_name', 'enduser_company_name', 'enduser_company_contact_person']);

    // fetch projects
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Project, items_placement, props.company.getIn(['links', 'projects'])));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='projects.list.title' />}
            subheader={<FormattedMessage id='projects.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/partner/projects/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='projects' />
                </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='projects.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                        </TableRow>
                    </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id='projects.list.table.empty' />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && filteredItems.map((project) => {
                                const id = project.get(new Project().getUniqueIdentifier());
                                const onClick = () => props.state === null ? history.push(`/partner/projects/${id}`) : {};

                                return <TableRow key={id} hover link onClick={onClick}>
                                    <TableCell>{Moment(project.get('order_date')).startOf('day').format('l')}</TableCell>
                                    <TableCell>{project.get('project_name')}</TableCell>
                                    <TableCell>{project.get('project_stage')}</TableCell>
                                    <TableCell>{project.get('enduser_company_contact_person')}</TableCell>
                                    <TableCell>{project.get('enduser_company_name')}</TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
            </Table>} />
    </Card>;
}
