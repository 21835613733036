import React, {useMemo, useCallback, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {Company, Role, SalesCluster} from 'lib/models';
import useFeatureFlag from 'lib/featureFlag';
import storage from 'store';
import {theme as srapsTheme, portalTheme} from 'theme';
// Actions
import {toggleMenu} from 'actions/app';
// Components
import {Link} from 'react-router-dom';
import AppHeadline from 'components/core/ui/AppHeadline';
import PlatformSwitcher from 'components/core/ui/PlatformSwitcher';
import Tabs from 'components/core/ui/Tabs';
// material-ui
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MUIMenuItem from 'components/core/ui/mui/MenuItem';
import Tab from 'components/core/ui/mui/Tab';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import ExpansionPanelSummary from 'components/core/ui/mui/ExpansionPanelSummary';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import BackIcon from '@material-ui/icons/ChevronLeftOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';


const useStyles = makeStyles(theme => ({
    // tabs at the top of menu
    tabBar: {
        marginBottom: '0',
        // modify style
        background: 'transparent',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 'none',
        overflow: 'visible',
        // border-bottom
        '&:after': {
            position: 'absolute',
            zIndex: '-1',
            left: '0',
            right: '0',
            bottom: '0',
            content: '""',
            display: 'block',
            height: '2px',
            background: theme.palette.divider
        }
    },
    tab: {
        // two tabs next to each other
        width: '50%',
        minWidth: '0'
    },
    // style of menu items
    menuItem: {
        position: 'relative',
        // tweak proportions
        padding: `4px ${theme.spacing(4)}px`,
        minHeight: 'auto',
        // get rid of disabled style
        opacity: '1 !important',
        // selected style
        '&.selected': {
            // remove background
            backgroundColor: 'transparent !important',
            // keep hover background
            '&:hover': {
                backgroundColor: `${theme.palette.action.hover} !important`
            },
            // icon and text
            '& svg, & div, & span': {
                color: theme.palette.primary[500]
            }
        }
    },
    menuItemIcon: {
        minWidth: '0', // get rid of not necessary min-width
        marginRight: `${theme.spacing(3)}px` // space between icon and text
    },
    menuDivider: {
        margin: `${theme.spacing(1)}px 0`
    },
    menuItemSubheader: {
        // reduce size
        lineHeight: '32px',
        padding: `0 ${theme.spacing(2)}px`,
        '&.sraps': {
            color: srapsTheme.palette.primary.main
        },
        '&.portal': {
            color: portalTheme.palette.primary.main
        }
    },
    // pseudo left border
    menuItemBorder: {
        // | border
        '&:before': {
            display: 'block',
            content: '""',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            width: '2px',
            background: theme.palette.primary[500],
            // hidden by default
            height: '0',
            // control hiding (to bottom)
            marginTop: 'auto',
            // allow animation
            transition: theme.transitions.create(['height', 'background'],
                {duration: theme.transitions.duration.short})
        },
        // selected style
        '&.selected': {
            // | border
            '&:before': {
                // show
                height: '100%',
                // control showing (to bottom)
                marginTop: '0'
            }
        }
    },
    // expansion panel for SRAPS / Partner Portal admin sections
    menuExpansion: {
        // remove spacing and style
        border: '0',
        '&, &.expanded': {
            margin: '0'
        }
    },
    menuExpansionSummary: {
        // remove spacing
        padding: '0',
        '&, &.expanded': {
            minHeight: '0'
        }
    },
    menuExpansionSummaryContent: {
        // button space
        paddingRight: `${theme.spacing(6)}px`,
        // remove spacing
        '&, &.expanded': {
            margin: '0'
        }
    },
    menuExpansionSummaryIcon: {
        // move to the right side
        position: 'absolute',
        // into divider (perfect fit)
        top: `-${theme.spacing(1)}px`,
        right: '0',
        // get rid of negative margin
        marginRight: '0'
    },
    menuExpansionContent: {
        // lower proportion
        padding: `${theme.spacing(1)}px 0`
    },
    // holder of menuList
    menuListHolder: {
        // plain holder, so we can apply margin to menuList without breaking react-swipe
        '&.hide': {
            // reduce height of not visible menuList, so we also reduce size of browser scrollbar
            height: '1px'
        }
    },
    // holder of menu items
    menuList: {
        // admin one
        '&.admin': {
            // hide first divider
            '& hr:first-child': {
                display: 'none'
            },
            // reduce proportions of Menu so more items fit in
            '& $menuItem': {
                paddingTop: '0',
                paddingBottom: '0'
            }
        }
    },
    // mobile menu contains logo
    logoHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // same size like header
        height: '64px',
        // style (mimic and connect with header)
        boxShadow: theme.shadows[4]
    },
    // PC menu (fixed)
    desktopMenuHolder: {
        // size of menu
        width: `${280 + theme.spacing(2)}px`,
        // tabs holder
        '& $tabBar': {
            paddingLeft: `${theme.spacing(2)}px`,
            '&:after': {
                left: `${theme.spacing(2)}px`
            }
        },
        // menuItems holder
        '& $menuList': {
            // position
            margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            // style
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius
        }
    },
    // hider of PC menu
    desktopMenuHider: {
        overflow: 'hidden',
        // same size as menu
        width: `${280 + theme.spacing(2)}px`,
        // smooth hide menu
        '&.smooth': {
            // animation to smoothly hide menu
            transition: theme.transitions.create('width',
                {duration: theme.transitions.duration.short})
        },
        // hide menu
        '&.hide': {
            width: '0'
        },
        // display only on PC
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    // Mobile menu (swipeable drawer)
    mobileMenuHolder: {
        // size of menu
        width: '280px',
        maxWidth: '100%'
    }
}));

/**
 * Single item in menu with permission check
 */
function MenuItem(passed_props) {
    const {model_name, path, intl_id} = passed_props;
    const classes = useStyles();
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        openMobileMenu: state.app.get('openMobileMenu'),
        permissions: state.auth.get('permissions'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);
    // cached permission check
    const shouldRender = useMemo(() => {
        const permission_name = new Role().getPermission(path);
        const permission = props.permissions.get(permission_name);
        // special condition
        if (path === '/partner/submissions' && props.company.get('cluster_company_submission_enabled') === false) {
            return false;
        }
        return !permission || permission !== 'X';
    }, [JSON.stringify(props.permissions), path, props.company.get('cluster_company_submission_enabled')]);

    if (!shouldRender) {
        return null;
    }
    return <MUIMenuItem
        button disabled={props.state !== null}
        onClick={() => {
            history.push(path);
            props.openMobileMenu && dispatch(toggleMenu(false));
        }}
        _classes={{root: `${classes.menuItem} ${classes.menuItemBorder}`, selected: 'selected'}}
        selected={pathname === path || pathname.startsWith(`${path}/`)}>
        <ListItemIcon className={classes.menuItemIcon}>
            {model_name === 'back'
                ? <BackIcon />
                : <ModelIcon model={model_name} />
            }
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id={intl_id} />} />
    </MUIMenuItem>;
}

/**
 * Menu Section with heading and dividing line
 */
function MenuDivider(passed_props) {
    const {paths, intl_id, className, expansion = false, identifier, ...rest_of_props} = passed_props;
    // router
    const {pathname} = useLocation();
    const selected = paths.some(path => pathname === path || pathname.startsWith(`${path}/`));
    // redux store
    const props = useSelector(state => ({
        permissions: state.auth.get('permissions'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);
    // cached permission check
    const shouldRender = useMemo(() => {
        const no_permissions = [];
        paths.forEach(path => {
            const permission_name = new Role().getPermission(path);
            const permission = props.permissions.get(permission_name);
            if (permission && permission === 'X') {
                no_permissions.push(permission);
            }
            // special condition
            if (path === '/partner/submissions' && props.company.get('cluster_company_submission_enabled') === false) {
                no_permissions.push(permission);
            }
        });
        return no_permissions.length !== paths.length;
    }, [JSON.stringify(props.permissions), paths, props.company.get('cluster_company_submission_enabled')]);
    // classes
    const classes = useStyles();
    const titleClasses = [
        className, classes.menuItemSubheader,
        expansion ? classes.menuItemBorder : '',
        identifier ? identifier : '',
        selected ? 'selected' : ''
    ].filter(Boolean).join(' ');
    // expansion panel
    const storageExpanded = storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-admin-expansion`)?.[identifier];
    const [expanded, setRawExpanded] = useState(storageExpanded !== undefined ? storageExpanded : selected);
    const setExpanded = useCallback(expanded => {
        // save choice to storage to
        storage.set(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-admin-expansion`,
            {
                ...(storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-admin-expansion`) || {}),
                [identifier]: expanded
            }
        );
        setRawExpanded(expanded);
    }, []);

    if (!shouldRender) {
        return null;
    }
    const title = <ListSubheader
        key={`${paths.join('_')}_1`} disableSticky
        className={titleClasses}
        {...rest_of_props}>
        <FormattedMessage id={intl_id} />
    </ListSubheader>;
    return [ // Menu doesn't accept <React.Fragment>
        <Divider key={`${paths.join('_')}_0`}
                 className={classes.menuDivider} />,
        expansion
            ? <ExpansionPanel
                key={`${paths.join('_')}_1`}
                expanded={expanded} onChange={() => setExpanded(!expanded)}
                className={classes.menuExpansion}
                expansionPanelDetailsProps={{className: classes.menuExpansionContent}}
                expansionPanelSummary={<ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    _classes={{
                        root: classes.menuExpansionSummary,
                        content: classes.menuExpansionSummaryContent,
                        expanded: 'expanded',
                        expandIcon: classes.menuExpansionSummaryIcon
                    }}>
                    {title}
                </ExpansionPanelSummary>}
                {...rest_of_props}
            />
            : title
    ];
}

/**
 * Wrapper of Menu rendering menu either in SwipeableDrawer for modal or in DesktopHolder
 */
function MenuWrapper(passed_props) {
    const {srapsAccess, portalAccess, children} = passed_props;
    const classes = useStyles();
    // router
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        docState: state.shared.getIn(['state', 'documentation']),
        openMobileMenu: state.app.get('openMobileMenu')
    }), shallowEqual);
    // breakpoint
    const mobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    return mobile
        ? <SwipeableDrawer open={props.openMobileMenu}
                           onOpen={() => dispatch(toggleMenu(true))}
                           onClose={() => dispatch(toggleMenu(false))}>
            <div className={classes.mobileMenuHolder}>
                <Link className={classes.logoHolder}
                      to={`${(portal && portalAccess) || !srapsAccess ? '/partner' : ''}/home`}
                      onClick={() => dispatch(toggleMenu(false))}>
                    <AppHeadline id='mobile-menu' />
                </Link>
                {children}
            </div>
        </SwipeableDrawer>
        : <div className={`${classes.desktopMenuHider}${pathname.startsWith('/documentation') && !['loading', 'error'].includes(props.docState) && props.state !== 'fetching_items_doc-versions' ? ' hide' : ''}`}>
            <div className={classes.desktopMenuHolder}>
                {children}
            </div>
        </div>;
}

/**
 * Application Menu for Logged User only
 */
export default function Menu(passed_props) {
    const {srapsAccess, portalAccess, canRequestPortalAccess} = passed_props;
    const classes = useStyles();
    // router
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');
    // redux store
    const props = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const salesClustersPlacement = new SalesCluster().getPlacement();
        const salesClusters = state.shared.getIn(['items', salesClustersPlacement]);
        const salesClustersLoaded = state.shared.getIn(['loaded', salesClustersPlacement]);
        let is_free_dm = false;
        if (company && salesClustersLoaded) {
            const cluster = salesClusters.find(item => item.get('countries').includes(company.get('country')));
            is_free_dm = !!cluster && cluster.get('free_dm');
        }

        return {
            user: auth_user,
            company: company,
            is_free_dm
        };
    }, shallowEqual);
    // cached initial tab
    const initialSelectedTab = useMemo(() => [
        '/all-', '/statistics', '/provisioning-file-types', '/product-families', '/products', '/setting-groups', '/settings', '/pages',
        '/sales-clusters', '/asset-manager', '/distributors', '/pbx-partners', '/submission-products', '/companies', '/customers',
        '/dead-letter-queue', '/permissions', '/feature-flags'
    ].filter(el => pathname.startsWith(el)).length > 0 ? 1 : 0, [pathname]);
    // feature flags
    const company = props.company?.get(new Company().getUniqueIdentifier());
    const featureFlag_assets = useFeatureFlag('partner_assets', {company});
    const featureFlag_releaseNotes = useFeatureFlag('release_notes', {company});
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company});
    const featureFlag_deadLetter = useFeatureFlag('dead_letter_queue', {company});
    const featureFlag_phoneLink = useFeatureFlag('phonelink', {company});

    return <div>
        <MenuWrapper srapsAccess={srapsAccess} portalAccess={portalAccess}>
            <React.Fragment>
                {portal && !portalAccess
                    ? <MenuList className={classes.menuList}>
                        <MenuItem model_name='back' path='/home' intl_id='menu.customer.back.sraps' />
                    </MenuList>
                    : !portal && !srapsAccess
                        ? <MenuList className={classes.menuList}>
                            <MenuItem model_name='back' path='/partner/home' intl_id='menu.customer.back.portal' />
                        </MenuList>
                        : <Tabs
                            appBarProps={{color: 'default', className: classes.tabBar}}
                            tabs={props.user.isAdmin() && !portal && srapsAccess ? [
                                <Tab key={0} className={classes.tab} label={<FormattedMessage id='menu.tab.customer' />} />,
                                <Tab key={1} className={classes.tab} label={<FormattedMessage id='menu.tab.admin' />} />
                            ] : null}
                            initialSelectedTab={initialSelectedTab}
                            tabsProps={{variant: 'fullWidth', scrollButtons: 'off'}}
                            content={[
                                <div key={0}>{portal
                                    ? <MenuList className={classes.menuList}>
                                        <MenuItem model_name='home' path='/partner/home' intl_id='menu.customer.portal.home' />
                                        <MenuItem model_name='dashboard' path='/partner/dashboard' intl_id='menu.customer.portal.dashboard' />
                                        <MenuItem model_name='about' path='/partner/about' intl_id='menu.customer.portal.about' />
                                        <MenuItem model_name='distributors' path='/partner/distributors' intl_id='menu.customer.portal.distributors' />
                                        <MenuDivider intl_id='menu.section.customer.portal.rebate' paths={['/submissions', '/claim']} />
                                        <MenuItem model_name='claim' path='/partner/claim' intl_id='menu.customer.portal.claim' />
                                        <MenuItem model_name='submissions' path='/partner/submissions' intl_id='menu.customer.portal.submissions' />
                                        <MenuItem model_name='projects' path='/partner/projects' intl_id='menu.customer.portal.projects' />
                                        <MenuDivider intl_id='menu.section.customer.portal.settings' paths={['/company', '/profile']} />
                                        <MenuItem model_name='company' path='/partner/company' intl_id='menu.customer.portal.company' />
                                        <MenuItem model_name='user' path='/partner/profile' intl_id='menu.customer.portal.profile' />
                                        <MenuDivider intl_id='menu.section.customer.portal.service' paths={['/faq', '/assets']} />
                                        <MenuItem model_name='faq' path='/partner/faq' intl_id='menu.customer.portal.faq' />
                                        {featureFlag_assets &&
                                            <MenuItem model_name='assets' path='/partner/assets' intl_id='menu.customer.portal.assets' />
                                        }
                                    </MenuList>
                                    : <MenuList className={classes.menuList}>
                                        <MenuItem model_name='home' path='/home' intl_id='menu.customer.home' />
                                        {featureFlag_releaseNotes && <MenuItem model_name='changelog' path='/changelog' intl_id='menu.customer.changelog' />}
                                        {(featureFlag_deviceManager && props.company?.get('has_subscription')) &&
                                            <MenuItem model_name='dashboard' path='/dashboard' intl_id='menu.customer.dashboard' />
                                        }
                                        <MenuDivider intl_id='menu.section.customer.endpoint_management' paths={['/phones', '/provisioning-profiles']} />
                                        <MenuItem model_name='endpoints' path='/phones' intl_id='menu.customer.endpoints' />
                                        <MenuItem model_name='provisioning_profiles' path='/provisioning-profiles' intl_id='menu.customer.provprofiles' />
                                        <MenuItem model_name='provisioning_files' path='/provisioning-files' intl_id='menu.customer.provfiles' />
                                        <MenuDivider intl_id='menu.section.customer.phone_staging' paths={['/product-defaults']} />
                                        <MenuItem model_name='product_defaults' path='/product-defaults' intl_id='menu.customer.productdefaults' />
                                        {/*
                                          * The whole 'Apps' section should probably be hidden by a separate feature flag,
                                          * but since PhoneLink is the only app for now, this should work fine.
                                          */}
                                        {featureFlag_phoneLink && <MenuDivider intl_id='menu.section.customer.apps' paths={['/phonelink']} />}
                                        {featureFlag_phoneLink && <MenuItem model_name='phonelink' path='/phonelink' intl_id='menu.customer.phonelink' />}
                                        <MenuDivider intl_id='menu.section.customer.settings' paths={['/my-companies', '/company', '/profile']} />
                                        {(props.company && props.company.get('company_type') === 'reseller') &&
                                            <MenuItem model_name='companies' path='/my-companies' intl_id='menu.customer.companies' />
                                        }
                                        <MenuItem model_name='company' path='/company' intl_id='menu.customer.company' />
                                        <MenuItem model_name='user' path='/profile' intl_id='menu.customer.profile' />
                                        {featureFlag_deviceManager &&
                                            <MenuItem model_name='subscription' path='/subscription' intl_id={`menu.customer.${props.is_free_dm ? 'enable_dm' : 'subscription'}`} />
                                        }
                                        <MenuDivider intl_id='menu.section.customer.system' paths={['/exports', '/activity']} />
                                        <MenuItem model_name='exports' path='/exports' intl_id='menu.customer.exports' />
                                        <MenuItem model_name='activities' path='/activity' intl_id='menu.customer.activities' />
                                        {(props.company && props.company.get('api_enabled')) && [
                                            <MenuDivider key={0} intl_id='menu.section.customer.api' paths={['/api-keys', '/documentation']} />,
                                            <MenuItem key={1} model_name='api_keys' path='/api-keys' intl_id='menu.customer.apikeys' />,
                                            <MenuItem key={2} model_name='documentation' path='/documentation' intl_id='menu.customer.documentation' />
                                        ]}
                                    </MenuList>}
                                </div>,
                                ...(props.user.isAdmin() && !portal && srapsAccess ? [<div key={1}>
                                    <MenuList className={`${classes.menuList} admin`}>
                                        <MenuDivider intl_id='menu.section.admin.statistics' paths={['/statistics']} />
                                        <MenuItem model_name='statistics' path='/statistics/general' intl_id='menu.admin.statistics.general' />
                                        <MenuItem model_name='statistics_users' path='/statistics/users' intl_id='menu.admin.statistics.users' />
                                        <MenuDivider
                                            intl_id='menu.section.admin.sraps'
                                            paths={[
                                                '/all-phones', '/all-provisioning-profiles', '/provisioning-file-types', '/all-provisioning-files',
                                                '/all-product-defaults', '/product-families', '/products',
                                                '/setting-groups', '/settings'
                                            ]}
                                            expansion identifier='sraps'>
                                            <MenuDivider intl_id='menu.section.admin.endpoint_management' paths={['/all-phones', '/all-provisioning-profiles', '/provisioning-file-types', '/all-provisioning-files']} />
                                            <MenuItem model_name='endpoints' path='/all-phones' intl_id='menu.admin.endpoints_global' />
                                            <MenuItem model_name='provisioning_profiles' path='/all-provisioning-profiles' intl_id='menu.admin.provprofiles_global' />
                                            <MenuItem model_name='provisioning_file_type' path='/provisioning-file-types' intl_id='menu.admin.provfile_types' />
                                            <MenuItem model_name='provisioning_files' path='/all-provisioning-files' intl_id='menu.admin.provfiles_global' />
                                            <MenuDivider intl_id='menu.section.admin.products' paths={['/all-product-defaults', '/product-families', '/products']} />
                                            <MenuItem model_name='product_defaults' path='/all-product-defaults' intl_id='menu.admin.productdefaults_global' />
                                            <MenuItem model_name='product_groups' path='/product-families' intl_id='menu.admin.product_groups' />
                                            <MenuItem model_name='products' path='/products' intl_id='menu.admin.products' />
                                            <MenuDivider intl_id='menu.section.admin.settings' paths={['/setting-groups', '/settings']} />
                                            <MenuItem model_name='setting_groups' path='/setting-groups' intl_id='menu.admin.setting_groups' />
                                            <MenuItem model_name='settings' path='/settings' intl_id='menu.admin.settings' />
                                        </MenuDivider>
                                        <MenuDivider
                                            intl_id='menu.section.admin.portal'
                                            paths={['/pages', '/sales-clusters', '/distributors', '/pbx-partners', '/submission-products', '/all-submissions', '/all-claims', '/asset-manager']}
                                            expansion identifier='portal'>
                                            <MenuItem model_name='pages' path='/pages' intl_id='menu.admin.pages' />
                                            <MenuItem model_name='sales_clusters' path='/sales-clusters' intl_id='menu.admin.sales_clusters' />
                                            <MenuItem model_name='distributors' path='/distributors' intl_id='menu.admin.distributors' />
                                            <MenuItem model_name='pbx_partners' path='/pbx-partners' intl_id='menu.admin.pbxpartners' />
                                            <MenuItem model_name='submission_products' path='/submission-products' intl_id='menu.admin.submission_products' />
                                            <MenuItem model_name='claims' path='/all-claims' intl_id='menu.admin.claims_global' />
                                            <MenuItem model_name='submissions' path='/all-submissions' intl_id='menu.admin.submissions_global' />
                                            <MenuItem model_name='asset_manager' path='/asset-manager' intl_id='menu.admin.asset_manager' />
                                        </MenuDivider>
                                        <MenuDivider intl_id='menu.section.admin.customers' paths={['/companies', '/customers', '/permissions']} />
                                        <MenuItem model_name='companies' path='/companies' intl_id='menu.admin.companies' />
                                        <MenuItem model_name='users' path='/customers' intl_id='menu.admin.users' />
                                        <MenuItem model_name='api_keys' path='/all-api-keys' intl_id='menu.admin.apikeys_global' />
                                        <MenuItem model_name='roles' path='/permissions' intl_id='menu.admin.permissions' />
                                        <MenuDivider intl_id='menu.section.admin.system' paths={['/exports', '/statistics', '/all-activity', '/feature-flags']} />
                                        <MenuItem model_name='exports' path='/all-exports' intl_id='menu.admin.exports' />
                                        <MenuItem model_name='statistics_performance' path='/statistics/performance' intl_id='menu.admin.statistics.performance' />
                                        {featureFlag_deadLetter && <MenuItem model_name='dead_letters' path='/dead-letter-queue' intl_id='menu.admin.dead_letter_queue' />}
                                        <MenuItem model_name='activities' path='/all-activity' intl_id='menu.admin.activities' />
                                        <MenuItem model_name='feature_flags' path='/feature-flags' intl_id='menu.admin.feature_flags' />
                                        {featureFlag_releaseNotes && <MenuItem model_name='changelog' path='/all-changelogs' intl_id='menu.admin.changelogs' />}
                                    </MenuList>
                                </div>] : [])
                            ]}
                        />}
                {(
                    (portal && portalAccess && srapsAccess) ||
                    (!portal && srapsAccess && (canRequestPortalAccess || portalAccess))
                ) && <PlatformSwitcher inMenu />}
            </React.Fragment>
        </MenuWrapper>
    </div>;
}
