import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {Page} from 'lib/models';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory, useLocation} from 'react-router';
// Actions
import {setState} from 'actions/app';
import {fetchItems, saveItem, deleteItem} from 'actions/shared';
// Components
import Builder from 'components/modules/pages/Builder';
import {RenderDynamicPage} from 'components/modules/pages/DynamicPage';
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {LanguageField, DynamicPageSlug} from 'components/core/ui/Field';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MUISwitch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import CopyIcon from '@material-ui/icons/AddToPhotosOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import PreviewModeIcon from '@material-ui/icons/Visibility';
import EditModeIcon from '@material-ui/icons/Edit';
import StructureModeIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';
import Moment from 'moment';


const useStyles = makeStyles(theme => ({
    switchHolder: {
        // simulate .dynamic formControl
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        // switch & labels next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // move second checkbox to the right side
        '&:nth-child(2)': {
            marginLeft: 'auto'
        }
    },
    switchLabel: {
        // FormControlLabel
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        // space between switch and label
        '&:first-child': {
            marginRight: `${theme.spacing(0.5)}px`
        },
        '&:last-child': {
            marginLeft: `${theme.spacing(0.5)}px`
        },
        // style
        ...theme.typography.body1,
        // icon
        '& svg': {
            // space between icon and label
            marginRight: `${theme.spacing(1)}px`,
            // mute & reduce
            color: theme.palette.text['secondary'],
            fontSize: theme.typography.pxToRem(19)
        },
        // smooth transition for styles
        '&, & svg': {
            transition: theme.transitions.create(['opacity', 'color'],
                {duration: theme.transitions.duration.short})
        },
        // specific styles
        '&.active': {
            '&, & svg': {
                color: theme.palette.primary[500]
            },
            '&.green': {
                '&, & svg': {
                    color: theme.palette.green[500]
                }
            },
            '&.red': {
                '&, & svg': {
                    color: theme.palette.danger[500]
                }
            }
        },
        '&.disabled': {
            cursor: 'default',
            opacity: theme.palette.action.disabledOpacity
        }
    },
    switch: {
        '& .track': {
            opacity: '1',
            background: theme.palette.primary[500]
        },
        '&.green': {
            '& .track': {
                background: theme.palette.green[500]
            }
        },
        '&.grey': {
            '& .track': {
                background: theme.palette.grey[400]
            }
        }
    },
    // holder of Builder component
    builderHolder: {
        // highlight
        borderTop: `2px solid ${theme.palette.primary['500']}`,
        borderBottom: `2px solid ${theme.palette.primary['500']}`,
        // .structure gradient border
        '&:before, &:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '0',
            right: '0',
            height: '2px',
            opacity: '0',
            transition: theme.transitions.create('opacity',
                {duration: theme.transitions.duration.short}),
            background: `linear-gradient(to right, ${theme.palette.primary['500']}, ${theme.palette.danger['500']})`
        },
        '&:before': {
            top: '-2px'
        },
        '&:after': {
            bottom: '-2px'
        },
        // style
        transition: theme.transitions.create('border',
            {duration: theme.transitions.duration.short}),
        // merge borders with cards
        margin: '-1px 0',
        position: 'relative',
        zIndex: '10',
        // space for content
        padding: `${theme.spacing(2)}px 0`,
        '&.preview': {
            borderColor: theme.palette.green[500]
        },
        '&.structure': {
            '&:before, &:after': {
                opacity: '1'
            }
        }
    }
}));

/**
 * Renders detail of Pages - ADD & EDIT
 */
function Detail(props) {
    const classes = useStyles();
    const edit = !!props.match.params.identifier;
    const items_placement = props.items_placement;
    // router
    const history = useHistory();
    const {search} = useLocation();
    // local state
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog

    /**
     * During initialization fetch ALL items, we need all of them for isUnique validation
     */
    useEffect(() => {
        if (props.loaded === false) {
            props.fetchItems(Page, items_placement, 'pages');
        }
    }, [props.loaded]);

    return <div>
        {[`fetching_items_${items_placement}`, `deleting_item_${items_placement}`].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='pages.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='page' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='pages.detail.notfound.title' />}
                text={<FormattedMessage id='pages.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <CardFirst>
                <CardHeader subheader={<FormattedMessage id='pages.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='pages.detail.edit.title' />
                                : <FormattedMessage id='pages.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='page' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <DynamicPageSlug
                            disabled={!!edit} required
                            helperText={<FormattedMessage id='pages.detail.form.fields.identifier.help' />}
                        />
                        <LanguageField name='language' required disabled={!!edit}
                                       helperText={<FormattedMessage id='pages.detail.form.fields.language.help' />} />
                        {edit && <React.Fragment>
                            <SpaceDivider />
                            <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='pages.detail.form.fields.created_at' />}
                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                            <Field name='updated_at' fieldType='TextField' label={<FormattedMessage id='pages.detail.form.fields.updated_at' />}
                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                        </React.Fragment>}
                    </Form>
                </CardContent>
                <hr />
                <CardContent>
                    <div>
                        <Typography variant='body1'><FormattedMessage id='pages.builder.title' /></Typography>
                        <Typography variant='body2'><FormattedMessage id='pages.builder.subheader' /></Typography>
                    </div>
                    <SpaceDivider double />
                    <Form>
                        <label className={classes.switchHolder}>
                            <span className={`${classes.switchLabel}${!search ? '' : ' active'}`}>
                                <EditModeIcon />
                                <FormattedMessage id='pages.builder.configuration.mode.edit' />
                            </span>
                            <MUISwitch checked={!search} onChange={(event) => history.replace({search: event.target.checked ? '' : 'edit'})}
                                       color='default'
                                       classes={{
                                           root: `${classes.switch}${!search ? ' green' : ''}`,
                                           thumb: 'thumb',
                                           track: 'track'
                                       }} />
                            <span className={`${classes.switchLabel}${!search ? ' active green' : ''}`}>
                                <PreviewModeIcon />
                                <FormattedMessage id='pages.builder.configuration.mode.preview' />
                            </span>
                        </label>
                        <ThemeProvider alt>
                            <label className={classes.switchHolder}>
                                <MUISwitch disabled={!search} checked={['?edit_structure', '?edit_raw'].includes(search)}
                                           onChange={(event) => history.replace({search: event.target.checked ? 'edit_structure' : 'edit'})}
                                           color='secondary'
                                           classes={{
                                               root: `${classes.switch}${!['?edit_structure', '?edit_raw'].includes(search) || !search ? ' grey' : ''}`,
                                               thumb: 'thumb',
                                               track: 'track'
                                           }} />
                                <span className={`${classes.switchLabel}${!search ? ' disabled' : ['?edit_structure', '?edit_raw'].includes(search) ? ' active red' : ''}`}>
                                    <StructureModeIcon />
                                    <FormattedMessage id='pages.builder.configuration.mode.structure' />
                                </span>
                            </label>
                            <Button disabled={!search || !['?edit_structure', '?edit_raw'].includes(search)}
                                    onClick={() => history.replace({search: search === '?edit_structure' ? 'edit_raw' : 'edit_structure'})}>
                                {'RAW'}
                            </Button>
                        </ThemeProvider>
                    </Form>
                </CardContent>
            </CardFirst>
            <div className={`${classes.builderHolder}${!search ? ' preview' : search === '?edit_structure' ? ' structure' : ''}`}>
                {!search
                    ? <RenderDynamicPage data={props.formValues.content} identifier={props.formValues.page_slug} />
                    : search === '?edit_raw'
                    ? <ThemeProvider alt>
                        <Form onSubmit={props.handleSubmit}>
                            <Field
                                fieldType='NoReduxTextField' size='full' color='secondary' multiline
                                value={JSON.stringify(props.formValues.content)}
                                onChange={(event) => {
                                    try {
                                        props.change('content', JSON.parse(event.target.value));
                                    } catch (err) {}
                                }}
                            />
                        </Form>
                </ThemeProvider>
                    : <Builder data={props.formValues.content} setData={(data) => props.change('content', data)}
                               structure={search === '?edit_structure'} />}
            </div>
            <CardLast>
                {[
                    `saving_item_${items_placement}`, `saved_item_${items_placement}`, `failed_save_item_${items_placement}`,
                    `copy_item_${items_placement}`
                ].includes(props.state)
                    ? <CardActionsLoader success={[`saved_item_${items_placement}`, `copy_item_${items_placement}`].includes(props.state)}
                                         failure={props.state === `failed_save_item_${items_placement}`}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                        <CardActions>
                            <Button disabled={props.state !== null}
                                    onClick={() => props.history.push('/pages')}>
                                <CancelIcon />
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            {props.permission === 'RW' && <React.Fragment>
                                {edit && <React.Fragment>
                                    <ThemeProvider>
                                        <Button variant='outlined' color='primary' disabled={props.state !== null}
                                                onClick={() => {
                                                    props.setState(`copy_item_${items_placement}`);
                                                    props.history.push('/pages/add');
                                                    setTimeout(() => {
                                                        Object.entries(props.formValues).map(([key, value]) => props.change(key, value));
                                                    }, 0);
                                                }}>
                                            <CopyIcon />
                                            <FormattedMessage id='actions.copy' />
                                        </Button>
                                    </ThemeProvider>
                                    <Button
                                        variant='contained' color='secondary' disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                        <DeleteIcon />
                                        <FormattedMessage id='actions.delete' />
                                    </Button>
                                </React.Fragment>}
                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                        onClick={() => props.handleSubmit()}>
                                    <SaveIcon />
                                    <FormattedMessage id='actions.save' />
                                </Button>
                            </React.Fragment>}
                        </CardActions>
                    </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'pages.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'pages.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(Page, items_placement, props.item.getIn(['links', 'self']), props.item).then(() => {
                            props.history.push('/pages');
                        });
                    }}
                />}
            </CardLast>
        </div>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    // get props.items without props.item for unique validation
    const items_without_self = props.item ? props.items.filter(el => el.get('page_slug') !== props.item.get('page_slug') && el.get('language') !== props.item.get('language')) : props.items;

    validator.isNotNull(null, errors, 'page_slug', data.page_slug) &&
    validator.isSlug(null, errors, 'page_slug', data.page_slug) &&
    validator.isUniqueTogether(
        props.intl.formatMessage({id: 'pages.detail.error.identifier'}),
        errors, 'page_slug', [data.page_slug, data.language].join(';'),
        items_without_self, ['page_slug', 'language']);
    validator.isNotNull(null, errors, 'language', data.language);

    return errors;
};

const DetailForm = reduxForm({
    form: 'pageForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new Page().popUpFields(props.formValues);

        // save object
        return dispatch(saveItem(Page, props.items_placement, props.item ? props.item.getIn(['links', 'self']) : 'pages', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get('page_slug') && create_data.get('language')) {
                props.history.push(`/pages/${create_data.get('page_slug')}-${create_data.get('language')}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items_placement = new Page().getPlacement();
    const items = state.shared.getIn(['items', items_placement]);
    const identifier = props.match.params.identifier || '';
    const item = identifier && items.find(el => el.get(new Page().getUniqueIdentifier()) === `${identifier.slice(0, -3)}-${identifier.slice(-2)}`);

    return {
        state: state.app.get('state'),
        items_placement: items_placement,
        item: item,
        items: items,
        loaded: state.shared.getIn(['loaded', items_placement]),
        initialValues: props.match.params.identifier
            ? item
                ? item.toJS()
                : undefined
            : {language: 'en', content: []},
        formValues: getFormValues('pageForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
