import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, getFormSyncErrors, FieldArray, SubmissionError} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {Company, Submission, Distributor} from 'lib/models';
import {useLocalSort} from 'lib/filters';
import {List as ImmutableList} from 'immutable';
// Actions
import {setState, addMessage} from 'actions/app';
import {fetchItems, fetchItem, saveItem, deleteItem, markFiltered, uploadFiles} from 'actions/shared';
// Components
import Rebate from 'components/modules/submissions/Rebate';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {CompanyField, SubmissionStatusField, SubmissionProductVariantField,
    DropFile, InFormContent, FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Row, Col} from 'components/core/ui/Grid';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';


const useStyles = makeStyles(theme => ({
    rebateCol: {
        position: 'sticky',
        top: `${theme.spacing(2)}px`
    },
    errorMessage: {
        // double the size of fields
        width: `${(320 * 2) + (theme.spacing(2))}px`,
        // match into fields proportions
        margin: `${theme.spacing(1)}px`
    },
    productQuantity: {
        // lower size of the field
        width: '172px' // matching with button bellow
    },
    linkIconButton: {
        '&, &:hover': {
            // make sure to overwrite global Application <a> style
            color: theme.palette.action.active
        }
    },
    fieldArray: {
        // fields next to each other
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    fieldArrayNewRowHolder: {
        // 'X' size (we are on new row)
        marginLeft: `${48 + theme.spacing(2)}px`
    },
    seqNrField: {
        '& input': {
            userSelect: 'all' // easier copy
        }
    }
}));

/**
 * Renders N*Invoices in FieldArray, DropFile and Invoice Number
 */
function RenderInvoicesFieldArray(props) {
    // style
    const classes = useStyles();

    return <div className={classes.fieldArray}>
        {props.fields.map((field, idx) => <div key={props.formValues?.invoices?.[idx]?.uuid || idx}>
            {props.editable
                ? <React.Fragment>
                    <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={props.disabled}>
                        <InFormContent inline>
                            <IconButton color='primary' onClick={() => props.fields.remove(idx)}
                                        disabled={props.disabled}>
                                <DeleteIcon />
                            </IconButton>
                        </InFormContent>
                    </Tooltip>
                    <Field name={`${field}.number`} fieldType='TextField' disabled={props.disabled}
                           label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.invoices.number'})}*`} />
                    <SpaceDivider none />
                    <div className={classes.fieldArrayNewRowHolder}>
                        <DropFile label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.invoices.invoice'})}*`}
                                  helperText={<FormattedMessage id='submissions.detail.form.fields.invoices.invoice.help' />}
                                  initialFile={props.initialValues?.invoices?.[idx]
                                      ? {id: props.initialValues.invoices[idx].file, url: props.initialValues.invoices[idx].file_url}
                                      : {}}
                                  selectedFile={props.formValues?.invoices?.[idx]
                                      ? {id: props.formValues.invoices[idx].file, url: props.formValues.invoices[idx].file_url}
                                      : {}}
                                  fieldChange={(id, url) => {
                                      props.change(`${field}.file`, id);
                                      props.change(`${field}.file_url`, url);
                                      props.change(`${field}.file_to_upload`, null);
                                  }}
                                  onChange={(file) => {
                                      props.change(`${field}.file`, file);
                                      props.change(`${field}.file_url`, null);
                                      props.change(`${field}.file_to_upload`, file);
                                  }}
                                  preOpen={true}
                                  required disabled={props.disabled} />
                        <SpaceDivider none />
                        <Field name={`${field}.file`} fieldType='ErrorField' />
                    </div>
                    <SpaceDivider />
                </React.Fragment>
                : <FieldWithIconHolder>
                    <Field withIcon disabled={true} name={`${field}.number`} fieldType='TextField'
                           label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.invoices.invoice'})}*`} />
                    <FieldIcon className={classes.linkIconButton} component='a' target='_blank' rel='noreferrer'
                               disabled={!props.formValues?.invoices?.[idx]?.file_url}
                               href={props.formValues?.invoices?.[idx]?.file_url}>
                        <LinkToIcon />
                    </FieldIcon>
                </FieldWithIconHolder>}
        </div>)}
        {props.editable && <InFormContent>
            <Button variant='outlined'
                    disabled={props.disabled || props.fields.length >= 3} color='primary'
                    onClick={() => {
                        props.fields.push({uuid: `newone-${props.newInvoiceID}`});
                        props.setNewInvoiceID(props.newInvoiceID + 1);
                    }}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </Button>
            <SpaceDivider />
            <Typography variant='subtitle2'><FormattedMessage id='submissions.detail.invoices.info2' /></Typography>
            <Typography variant='subtitle2'><FormattedMessage id='submissions.detail.invoices.info' /></Typography>
            <SpaceDivider />
        </InFormContent>}
    </div>;
}

/**
 * Renders N*SubmissionProducts in FieldArray, quantity and SubmissionProduct variants
 */
function RenderProductsFieldArray(props) {
    // style
    const classes = useStyles();

    return <div className={classes.fieldArray}>
        {props.fields.map((field, idx) => <div key={idx}>
            {props.editable && <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={props.disabled}>
                <InFormContent inline>
                    <IconButton color='primary' onClick={() => props.fields.remove(idx)}
                                disabled={props.disabled}>
                        <DeleteIcon />
                    </IconButton>
                </InFormContent>
            </Tooltip>}
            <Field name={`${field}.quantity`} fieldType='TextField' type='number'
                   label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.items.quantity'})}*`}
                   className={classes.productQuantity}
                   disabled={!props.editable || props.disabled} />
            <SpaceDivider none />
            <div className={props.editable ? classes.fieldArrayNewRowHolder : ''}>
                <SubmissionProductVariantField
                    name={`${field}.product_code`}
                    label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.items.product_code'})}*`}
                    change={props.change}
                    selectedValue={props.formValues?.items?.[idx]?.product_code || undefined}
                    disabledVariants={props.formValues?.items?.map(el => el?.product_code).filter(el => el) || []}
                    preOpen={true}
                    disabled={!props.editable || props.disabled}
                    doNotFetchSubmissionProducts
                />
            </div>
            <SpaceDivider />
        </div>)}
        {props.editable && <InFormContent>
            <Button variant='outlined'
                    disabled={props.disabled} color='primary'
                    onClick={() => props.fields.push(props.initialProductValues)}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </Button>
            <SpaceDivider />
        </InFormContent>}
    </div>;
}

/**
 * Renders detail of Submission - ADD & EDIT
 */
function Detail(props) {
    // style
    const classes = useStyles();
    // local state
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [newInvoiceID, setNewInvoiceID] = useState(0);
    // sorting
    const [sortedDistributors] = useLocalSort(props.distributors_items, !props.distributors_loaded);

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, 'companies', ['companies', props.selectedCompanyIdentifier], {success_affect_state: !props.edit});
        } else if (props.edit) {
            if (!props.item) {
                props.fetchItem(Submission,
                    props.selectedCompanyIdentifier === 'my'
                        ? 'submissions'
                        : `submissions-${props.selectedCompanyIdentifier}`,
                    [
                        props.selectedCompany.getIn(['links', 'submissions']),
                        props.match.params.identifier
                    ],
                    {company: props.selectedCompany}
                );
            } else if (props.state === 'fetching_item_companies') {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany]);
    // related data fetch without affecting state, Distributors for required field
    useEffect(() => {
        if (props.distributors_loaded === false) {
            props.fetchItems(Distributor, 'distributors', 'distributors', null, null, {affect_state: false});
        }
    }, [props.distributors_loaded]);

    return <div>
        {[
            'fetching_item_companies',
            'fetching_item_submissions', `fetching_item_submissions-${props.selectedCompanyIdentifier}`,
            'deleting_item_submissions', `deleting_item_submissions-${props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='submissions.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='submission' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : props.edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='submissions.detail.notfound.title' />}
                text={<FormattedMessage id='submissions.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <Row wrap>
            <Col width='66.6667%'>
                <Card>
                    <CardHeader subheader={<FormattedMessage id='submissions.detail.subheader' />}
                                title={props.edit
                                    ? <FormattedMessage id='submissions.detail.edit.title' />
                                    : <FormattedMessage id='submissions.detail.add.title' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='submission' />
                                </ActionButton>} />
                    {(!props.requirements && !props.edit && !props.user.isAdmin()) && <CardContent className='text-center'>
                        <Typography variant='subtitle2'><FormattedMessage id='submissions.detail.requirements' /></Typography>
                        <SpaceDivider />
                        <Button color='primary' variant='outlined'
                                disabled={props.state !== null}
                                onClick={() => props.history.push('/partner/company')}>
                            <ModelIcon model='company' />
                            <FormattedMessage id='submissions.detail.requirements.button' />
                        </Button>
                    </CardContent>}
                    {(props.requirements || props.edit) && <CardContent>
                        <Form onSubmit={props.handleSubmit}>
                            <Field name='distributor' fieldType='Select'
                                   label={`${props.intl.formatMessage({id: 'submissions.detail.form.fields.distributor'})}*`}
                                   helperText={<FormattedMessage id='submissions.detail.form.fields.distributor.help' />}
                                   disabled={!props.distributors_loaded || !props.editable || props.disabled}
                                   loading={!props.distributors_loaded}>
                                <MenuItem fake value={props.formValues?.distributor} />
                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                {sortedDistributors.filter(distributor => distributor.get('active')).map((distributor, idx) =>
                                    <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                                )}
                            </Field>
                            {props.location.pathname.startsWith('/all-') && <CompanyField
                                value={!props.edit && props.selectedCompanyIdentifier === 'my' ? ''
                                    : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                                change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                                disabled={props.edit} all={false}
                                fetch_company={false}
                                my_companies={!props.location.pathname.startsWith('/all-')}
                            />}
                            {(props.user.isAdmin() && props.selectedCompany && !props.requirements) && <React.Fragment>
                                <SpaceDivider none />
                                <ErrorMessage className={classes.errorMessage}>
                                    <FormattedMessage id='submissions.detail.requirements.admin' />
                                </ErrorMessage>
                            </React.Fragment>}
                            {props.edit && <React.Fragment>
                                <SpaceDivider none />
                                {props.user.isAdmin() && <FieldWithIconHolder>
                                    <Field withIcon disabled={true} name='user' fieldType='TextField'
                                           label={<FormattedMessage id='submissions.detail.form.fields.user' />} />
                                    <FieldIcon disabled={props.state !== null || props.permissions.get('user') === 'X' || !props.formValues?.user}
                                               onClick={() => props.history.push(`/customers/${props.formValues.user}${props.selectedCompany.get(new Company().getUniqueIdentifier()) !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${props.selectedCompany.get(new Company().getUniqueIdentifier())}` : ''}`)}>
                                        <LinkToIcon />
                                    </FieldIcon>
                                </FieldWithIconHolder>}
                                <SubmissionStatusField
                                    required name='status' hideDisabledArrow
                                    disabled={!props.user.isAdmin() || !props.editable} />
                                <SpaceDivider />
                                <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='submissions.detail.form.fields.created_at' />}
                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                <Field name='updated_at' fieldType='TextField' label={<FormattedMessage id='submissions.detail.form.fields.updated_at' />}
                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                {props.user.isAdmin() && <React.Fragment>
                                    <SpaceDivider none />
                                    <Field disabled={true} name='salesforce_id' fieldType='TextField' label={<FormattedMessage id='submissions.detail.form.fields.salesforce_id' />}
                                           helperText={<FormattedMessage id='submissions.detail.form.fields.salesforce_id.help' />} />
                                    <Field disabled={true} name='seq_nr' fieldType='TextField' className={classes.seqNrField}
                                           label={<FormattedMessage id='submissions.detail.form.fields.seq_nr' />}
                                           helperText={<FormattedMessage id='submissions.detail.form.fields.seq_nr.help' />} />
                                </React.Fragment>}
                            </React.Fragment>}
                            {(props.edit && (props.user.isAdmin() || props.formValues?.note)) && <React.Fragment>
                                <SpaceDivider />
                                <Field name='note' fieldType='TextArea' label={<FormattedMessage id='submissions.detail.form.fields.note' />}
                                       helperText={props.user.isAdmin() && <FormattedMessage id='submissions.detail.form.fields.note.help' />}
                                       disabled={!props.user.isAdmin() || !props.editable} />
                            </React.Fragment>}
                            <SpaceDivider />
                        </Form>
                        <SpaceDivider />
                        <hr />
                        <div>
                            <Typography variant='body1'><FormattedMessage id='submissions.detail.form.fields.invoices' /></Typography>
                            <Typography variant='body2'><FormattedMessage id='submissions.detail.form.fields.invoices.help' /></Typography>
                        </div>
                        <SpaceDivider />
                        {(props.submitFailed && props.error && ['invoices_required', 'file_bad_type', 'file_too_large'].includes(props.error)) && <React.Fragment>
                            <ErrorMessage>
                                <FormattedMessage id={props.error === 'invoices_required'
                                    ? 'submissions.detail.form.fields.invoices.required'
                                    : props.error === 'file_too_large'
                                        ? 'upload.error.size'
                                        : 'upload.error.type'} />
                            </ErrorMessage>
                            <SpaceDivider />
                        </React.Fragment>}
                        <Form onSubmit={props.handleSubmit}>
                            <FieldArray name='invoices' component={RenderInvoicesFieldArray} props={{...props, newInvoiceID: newInvoiceID, setNewInvoiceID: setNewInvoiceID}} />
                        </Form>
                        <hr />
                        <div>
                            <Typography variant='body1'><FormattedMessage id='submissions.detail.form.fields.items' /></Typography>
                            <Typography variant='body2'><FormattedMessage id='submissions.detail.form.fields.items.help' /></Typography>
                        </div>
                        <SpaceDivider />
                        {(props.submitFailed && props.error && ['items_required'].includes(props.error)) && <React.Fragment>
                            <ErrorMessage>
                                <FormattedMessage id='submissions.detail.form.fields.items.required' />
                            </ErrorMessage>
                            <SpaceDivider />
                        </React.Fragment>}
                        <Form onSubmit={props.handleSubmit}>
                            <FieldArray name='items' component={RenderProductsFieldArray} props={props} />
                        </Form>
                        <hr />
                    </CardContent>}
                    {[
                        'uploading_submissions', `uploading_submissions-${props.selectedCompanyIdentifier}`,
                        'uploaded_submissions', `uploaded_submissions-${props.selectedCompanyIdentifier}`,
                        'failed_upload_submissions', `failed_upload_submissions-${props.selectedCompanyIdentifier}`,
                        'saving_item_submissions', `saving_item_submissions-${props.selectedCompanyIdentifier}`,
                        'saved_item_submissions', `saved_item_submissions-${props.selectedCompanyIdentifier}`,
                        'added_item_submissions', `added_item_submissions-${props.selectedCompanyIdentifier}`,
                        'failed_save_item_submissions', `failed_save_item_submissions-${props.selectedCompanyIdentifier}`
                    ].includes(props.state)
                        ? <CardActionsLoader
                            success={[
                                'saved_item_submissions', `saved_item_submissions-${props.selectedCompanyIdentifier}`,
                                'added_item_submissions', `added_item_submissions-${props.selectedCompanyIdentifier}`
                            ].includes(props.state)}
                            failure={[
                                'failed_upload_submissions', `failed_upload_submissions-${props.selectedCompanyIdentifier}`,
                                'failed_save_item_submissions', `failed_save_item_submissions-${props.selectedCompanyIdentifier}`
                            ].includes(props.state)}
                            postAnimation={success => {
                                // in case of add
                                if (success && ['added_item_submissions', `added_item_submissions-${props.selectedCompanyIdentifier}`].includes(props.state) && !props.location.pathname.startsWith('/all-')) {
                                    props.setState(null);
                                    // inform user about success
                                    props.addMessage({intl_id: 'submissions.detail.add.success', path: '/partner/submissions'});
                                    // redirect to list
                                    props.history.push('/partner/submissions');
                                } else {
                                    props.setState(null);
                                }
                            }} />
                        : <ThemeProvider alt>
                            <CardActions>
                                <Button disabled={props.state !== null}
                                        onClick={() => props.history.push(
                                            `/${props.location.pathname.startsWith('/all-') ? 'all-' : 'partner/'}submissions${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`)
                                        }>
                                    <CancelIcon />
                                    <FormattedMessage id='actions.cancel' />
                                </Button>
                                {(props.permission === 'RW') && <React.Fragment>
                                    {(props.edit && props.editable) &&
                                        <Button variant='contained' color='secondary' disabled={props.disabled || props.state !== null}
                                                onClick={() => setDeleteDialogOpen(true)}>
                                            <DeleteIcon />
                                            <FormattedMessage id='actions.delete' />
                                        </Button>
                                    }
                                    {(props.requirements && props.editable) &&
                                        <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                                onClick={props.handleSubmit}>
                                            <SaveIcon />
                                            <FormattedMessage id='actions.save' />
                                        </Button>
                                    }
                                </React.Fragment>}
                            </CardActions>
                        </ThemeProvider>}
                    {(props.edit && props.editable && props.permission === 'RW') && <DeleteDialog
                        item={props.intl.formatMessage({id: 'submissions.detail.deletedialog.item'})}
                        items={props.intl.formatMessage({id: 'submissions.detail.deletedialog.items'})}
                        open={deleteDialogOpen}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleConfirm={() => {
                            setDeleteDialogOpen(false);
                            props.deleteItem(Submission, `submissions${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`, props.item.getIn(['links', 'self']), props.item).then(result => {
                                if (result !== false) {
                                    // mark paginated lists for reload
                                    props.markFiltered(Submission, 'submissions-global');
                                    props.history.push(
                                        `/${props.location.pathname.startsWith('/all-') ? 'all-' : 'partner/'}submissions${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                                }
                            });
                        }}
                    />}
                </Card>
            </Col>
            <Col width='33.3333%' className={classes.rebateCol}>
                <Rebate data={props.formValues && props.formValues.items}
                        total_rebate={props.item && props.item.get('total_rebate')}
                        selectedCompany={props.selectedCompany}
                        editable={props.editable} />
            </Col>
        </Row>}
    </div>;
}

const validate = (data, props) => {
    const errors = {items: {}, invoices: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    // validate form
    validator.isNotNull(null, errors, 'distributor', data.distributor);

    // validate invoices fieldArray
    if (data.invoices && data.invoices.length) {
        data.invoices.forEach((el, idx) => {
            // prepare error array
            if (!errors.invoices[idx]) {
                errors.invoices[idx] = {};
            }
            // validation
            validator.isNotNull(null, errors, `invoices.${idx}.number`, data.invoices[idx] && data.invoices[idx].number) &&
            validator.isUnique(
                props.intl.formatMessage({id: 'submissions.detail.error.invoices.number'}),
                errors, `invoices.${idx}.number`, data.invoices[idx] && data.invoices[idx].number,
                data.invoices.map((invoice, idy) => idy !== idx && invoice && invoice.number), null);
            validator.isNotNull(null, errors, `invoices.${idx}.file`, data.invoices[idx] && data.invoices[idx].file);
        });
    } else {
        errors['invoices'] = props.intl.formatMessage({id: 'errors.validator.required'});
    }

    // validate items fieldArray
    if (data.items && data.items.length) {
        data.items.forEach((el, idx) => {
            // prepare error array
            if (!errors.items[idx]) {
                errors.items[idx] = {};
            }
            // validation
            validator.isNotNull(null, errors, `items.${idx}.quantity`, data.items[idx] && data.items[idx].quantity);
            if (data.items[idx] && data.items[idx].quantity) {
                validator.isInt(null, errors, `items.${idx}.quantity`, data.items[idx].quantity, {allow_leading_zeroes: false, min: 0});
            }
            validator.isNotNull(null, errors, `items.${idx}.product_code`, data.items[idx] && data.items[idx].product_code);
        });
    } else {
        errors['items'] = props.intl.formatMessage({id: 'errors.validator.required'});
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'submissionForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation
    onSubmit: (values, dispatch, props) => {
        // prevent save() when requirements not met or non admins in edit without open/pending status
        if (!props.requirements || (!props.editable && !props.disabled)) {
            return;
        }
        // extra care for fieldArrays, regular isNotNull validation does not work since field does not exists
        if (props.formErrors.invoices.length) {
            throw new SubmissionError({_error: 'invoices_required'});
        } else if (props.formErrors.items.length) {
            throw new SubmissionError({_error: 'items_required'});
        }
        // pop-up some values which we don't want to send
        let rest_of_data = new Submission().popUpFields(props.formValues, props.user.isAdmin());

        // get files to upload for each invoice
        let files_to_upload = [];
        rest_of_data.invoices.forEach((invoice) => {
            // do we have file to upload?
            if (invoice.file_to_upload) {
                files_to_upload.push(invoice.file_to_upload);
            }
        });

        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));

        return dispatch(uploadFiles(`submissions${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`, props.selectedCompany.getIn(['links', 'submission-invoices']), files_to_upload)).then(results => {
            let id = 0;
            // append files to data for each invoice
            rest_of_data.invoices.forEach((invoice, idx) => {
                // do we have upload result to this variant?
                if (invoice.file_to_upload) {
                    // add image (ID) from newly uploaded images (replaces file blop)
                    rest_of_data.invoices[idx].file = results[id].data.file_id;
                    id++;
                }
                // remove data which we don't want to send
                delete rest_of_data.invoices[idx].file_url;
                delete rest_of_data.invoices[idx].file_to_upload;
                delete rest_of_data.invoices[idx].uuid;
            });
            // popUpFields for related objects
            rest_of_data.items.forEach((product, idx) => {
                // pop-up rebate
                delete rest_of_data.items[idx].product_rebate;
            });
            // save object
            return dispatch(saveItem(
                Submission,
                props.selectedCompanyIdentifier === 'my' ? 'submissions' : `submissions-${props.selectedCompanyIdentifier}`,
                props.item
                    ? props.item.getIn(['links', 'self'])
                    : props.selectedCompanyIdentifier === 'my'
                    ? props.company.getIn(['links', 'submissions'])
                    : props.selectedCompany.getIn(['links', 'submissions']),
                rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false, different_add_state: true}
            )).then(create_data => {
                // in case of add, redirect to detail
                if (!props.item && create_data.get(new Submission().getUniqueIdentifier())) {
                    props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : 'partner/'}submissions/${create_data.get(new Submission().getUniqueIdentifier())}${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                }
                // mark not updated lists for reload
                return dispatch(markFiltered(Submission, 'submissions-global'));
            });
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    const items = selectedCompanyIdentifier === 'my' ? state.shared.getIn(['items', 'submissions']) : state.shared.getIn(['items', `submissions-${selectedCompanyIdentifier}`]) || ImmutableList();
    const item = items.find(el => el.get(new Submission().getUniqueIdentifier()) === props.match.params.identifier);

    // process invoices (we need extra UUID to keep proper state value in <DropFile /> after changing field index
    let processedInvoices = [];
    item && item.toJS().invoices.forEach((invoice, idx) => {
        processedInvoices.push({uuid: `initialvalue-${idx}`, ...invoice});
    });

    // permission flags
    const edit = !!props.match.params.identifier;
    const requirements = !!(selectedCompany && !new Company().getPPRequiredFields().some(field_name => !selectedCompany.get(field_name)));
    const disabled = !requirements || props.permission === 'R' || company.get('cluster_company_submission_enabled') === false;
    const editable = !edit ||
        (item && ['open', 'pending'].includes(item.get('status')) && company.get('cluster_company_submission_enabled') !== false) ||
        (item && auth_user.isAdmin() && !['finished', 'declined'].includes(item.get('status')) && !item.get('salesforce_id'));

    return {
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        initialValues: props.match.params.identifier
            ? item ? {...item.toJS(), invoices: processedInvoices} : undefined
            : {status: 'open', items: [], invoices: []},
        initialProductValues: {quantity: 1},
        distributors_loaded: state.shared.getIn(['loaded', 'distributors']),
        distributors_items: state.shared.getIn(['items', 'distributors']),
        company: company,
        user: auth_user,
        requirements: requirements,
        permissions: state.auth.get('permissions'),
        edit,
        disabled,
        editable,
        formValues: getFormValues('submissionForm')(state),
        formErrors: getFormSyncErrors('submissionForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    addMessage,
    fetchItems,
    fetchItem,
    saveItem,
    deleteItem,
    markFiltered,
    uploadFiles
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
