import React, {useRef, useMemo, useState, useEffect, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
// Components
import ReactSwipe from 'react-swipe';
import {Row, Col} from 'components/core/ui/Grid';
// material-ui
import MUITabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    tabBar: {
        position: 'relative',
        zIndex: `${theme.zIndex.appBar - 50}`,
        // position (grid)
        marginBottom: `${theme.spacing(2)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            marginBottom: `${theme.spacing(1)}px`
        },
        // modify style (card like)
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden', // borderRadius needs this to work
        // border-bottom
        '&:after': {
            position: 'absolute',
            zIndex: '-1',
            left: '0',
            right: '0',
            bottom: '0',
            content: '""',
            display: 'block',
            height: '2px',
            background: theme.palette.grey[300]
        }
    },
    // holder of ReactSwipe
    holder: {
        // post-animation selected
        '&.active': {
            // allow sticky
            '& > .react-swipe-container': {
                overflow: 'visible !important',
                '& > div': {
                    width: 'auto !important',
                    overflow: 'visible !important',
                    '& > .active': {
                        position: 'static !important'
                    },
                    '& > .hide + div': {
                        marginTop: '-1px'
                    }
                }
            }
        }
    },
    // holder of content in tab
    tabContentHolder: {
        '&.hide': {
            // reduce height of not visible tab, so we also reduce size of browser scrollbar
            height: '1px',
            overflow: 'visible'
        },
        // post-animation selected
        '&.active': {
            // remove original transform to allow position: 'sticky' inside
            transform: 'none !important'
        }
    }
}));

/**
 * Integration of react-swipe with @mui tabs
 */
export default function Tabs(props) {
    const classes = useStyles();
    const {tabs = [], content = [], initialSelectedTab = 0,
        destroyNotSelected = false, onChange = (tab) => {},
        tabsProps = {}, appBarProps = {}
    } = props;
    // local state
    const [selectedTab, setSelectedTab] = useState(initialSelectedTab);
    const [debouncedSelectedTab, setRawDebouncedSelectedTab] = useState(selectedTab);
    // extra useCallBack, since using directly function breaks swiper for some reason
    const setDebouncedSelectedTab = useCallback(tab => {
        setRawDebouncedSelectedTab(tab);
    }, []);
    const swipeRef = useRef(null);
    // breakpoint
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const responsiveTabsProps = useMemo(() => mobile
        ? {
            variant: 'scrollable',
            scrollButtons: 'on'
        }
        : {
            variant: 'fullWidth',
            scrollButtons: 'off'
        }, [mobile]);

    // ensure we are at correct tab when initialSelectedTab changes
    useEffect(() => {
        if (swipeRef.current && selectedTab !== initialSelectedTab) {
            setSelectedTab(initialSelectedTab);
            swipeRef.current.swipe.slide(initialSelectedTab);
        }
    }, [initialSelectedTab]);
    // ensure we have correct selectedTab once tabs are not used
    useEffect(() => {
        if (tabs === null) {
            setSelectedTab(initialSelectedTab);
        }
    }, [tabs === null]);

    return tabs
        ? <React.Fragment>
            <AppBar
                position='static' color='default'
                {...appBarProps}
                className={[classes.tabBar, appBarProps.className].filter(Boolean).join(' ')}
            >
                <MUITabs value={selectedTab}
                         onChange={(event, tab) => {
                             if (tab !== selectedTab) {
                                 setSelectedTab(tab);
                                 onChange(tab);
                                 // make sure to actually slide content
                                 swipeRef.current.swipe.slide(tab);
                             }
                         }}
                         indicatorColor='primary'
                         TabScrollButtonProps={{
                             disabled: false
                         }}
                         {...responsiveTabsProps}
                         {...tabsProps}>
                    {tabs}
                </MUITabs>
            </AppBar>
            <Row nospace>
                <Col width='100%' className={[classes.holder, selectedTab === debouncedSelectedTab && destroyNotSelected ? 'active' : ''].filter(Boolean).join(' ')}>
                    <ReactSwipe ref={swipeRef}
                                swipeOptions={{
                                    startSlide: initialSelectedTab,
                                    continuous: false,
                                    disableScroll: true,
                                    speed: theme.transitions.duration.standard,
                                    transitionEnd: setDebouncedSelectedTab
                                }}>
                        {content.map((c, idx) => <div
                            key={idx}
                            className={[
                                classes.tabContentHolder,
                                selectedTab !== idx ? 'hide' : '',
                                selectedTab === idx && debouncedSelectedTab === idx ? 'active' : ''
                            ].filter(Boolean).join(' ')}>
                            {(!destroyNotSelected || [selectedTab, debouncedSelectedTab].includes(idx)) && c}
                        </div>)}
                    </ReactSwipe>
                </Col>
            </Row>
        </React.Fragment>
        : content;
}
