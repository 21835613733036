import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {humanFileSize} from 'lib/util';
// components
import DMInfoCard from 'components/modules/tickets/dm/DMInfoCard';
// material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import MemoryIcon from '@material-ui/icons/MemoryOutlined';


const useStyles = makeStyles((theme) => ({
    // info and progress container
    contentHolder: {
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: `${theme.spacing(1)}px`
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: `${theme.spacing(1)}px`
        }
    },
    // holder of two circular progresses and text in center
    progress: {
        // move into cardheader
        [theme.breakpoints.up('xl')]: {
            marginTop: `-${theme.spacing(5)}px`
        },
        [theme.breakpoints.down('md')]: {
            marginTop: `-${theme.spacing(5)}px`
        },
        // align content inside
        display: 'flex',
        alignItems: 'center', justifyContent: 'center',
        position: 'relative',
        '& .progress_bg': {
            position: 'absolute',
            zIndex: '10',
            opacity: '0.2'
        },
        '& .progress': {
            position: 'relative',
            height: '100%',
            zIndex: '20',
            strokeLinecap: 'round'
        },
        '& .stats': {
            position: 'absolute',
            zIndex: '30',
            margin: '0',
            textAlign: 'center'
        }
    }
}));

/**
 * Render device manager memory status with indicator
 */
export default function DMMemory(props) {
    const {item} = props;
    const classes = useStyles();

    const [usedValue, remainingValue, totalValue, lastUpdate] = useMemo(() => {
        const data = item?.getIn(['data', 'MemoryStatusFree']);
        const remaining = data?.get('value') * 1024;
        const total = item?.getIn(['data', 'MemoryStatusTotal', 'value']) * 1024 * 1024;
        const used = total - remaining;

        return [used, remaining, total, data?.get('updated_at')];
    }, [item.getIn(['data', 'MemoryStatusFree']) && JSON.stringify(item.getIn(['data', 'MemoryStatusFree']))]);

    return <DMInfoCard
        title={<FormattedMessage id='devicemanagement.type.memorystatusfree' />}
        icon={<MemoryIcon />}
        lastUpdate={lastUpdate}
        info={[
            <FormattedMessage id='devicemanagement.type.memorystatusfree.total' values={{value: totalValue ? humanFileSize(totalValue) : '-'}} />,
            <FormattedMessage id='devicemanagement.type.memorystatusfree.used' values={{value: remainingValue ? humanFileSize(usedValue) : '-'}} />,
            <FormattedMessage id='devicemanagement.type.memorystatusfree.remaining' values={{value: remainingValue ? humanFileSize(remainingValue) : '-'}} />
        ]}
        className={classes.contentHolder}>
        {({info}) => <React.Fragment>
            {info}
            <div>
                <div className={classes.progress}>
                    <CircularProgress className='progress_bg' thickness={3} size={150} variant='determinate' value={100} />
                    <CircularProgress className='progress' thickness={3} size={150} variant='determinate' value={(usedValue && totalValue && Math.round(usedValue / totalValue * 100)) || 0} />
                    <h6 className='stats newfont'>
                        <strong>{humanFileSize(usedValue)}</strong><br />
                        <small><FormattedMessage id='devicemanagement.type.memorystatusfree.graph.used' /></small>
                    </h6>
                </div>
            </div>
        </React.Fragment>}
    </DMInfoCard>;
}
