import React from 'react';
import {FormattedMessage} from 'react-intl';
// Components
import ThemeProvider from 'components/ThemeProvider';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SubmitIcon from '@material-ui/icons/CheckOutlined';


/**
 * Renders dialog with text and accept button
 */
function SB327ComplianceDialog(props) {
    const {open, handleConfirm, handleClose} = props;

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
            <FormattedMessage id='sb327.title' />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id='sb327.description.settings_list' values={{settings: <ul>
                        {props.settings_list.map(setting => <li key={setting.get('uuid')}><strong>{setting.get('param_name')}</strong></li>)}
                    </ul>}} />
                <SpaceDivider/>
                <FormattedMessage id='sb327.description.proceed' />
            </DialogContentText>
            <SpaceDivider />
        </DialogContent>
        <ThemeProvider alt>
            <DialogActions>
                <Button onClick={handleClose}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button variant='contained' color='primary'
                        onClick={handleConfirm}>
                    <SubmitIcon />
                    <FormattedMessage id='sb327.form.submit' />
                </Button>
            </DialogActions>
        </ThemeProvider>
    </Dialog>;
}

export default SB327ComplianceDialog;
