import React, {useEffect, useMemo, useRef} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FieldArray} from 'redux-form';
import {FormattedMessage} from 'react-intl';
// Components
import SettingField, {validateSettingsField, getSettingInitialValue, getSettingsInitialValues} from 'components/core/ui/fields/SettingField';
import IndexedSettingField from 'components/core/ui/fields/IndexedSettingField';
import Field, {InFormContent} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Tooltip from 'components/core/ui/mui/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';


/**
 * Render N*SettingFields in FieldArray
 */
function RenderFieldArray(props) {
    const {name, fields, FieldComponent, autoFocus, ...rest_of_props} = props;
    const {permission, permissions} = useSelector(state => ({
        permissions: state.auth.get('permissions'),
        permission: state.auth.get('permission')
    }), shallowEqual);
    const disabled = (!props.excludePermission && permissions.get(permission) === 'R') || props.disabled;
    const ref = useRef(null);

    // implementation of autoFocus
    useEffect(() => {
        if (autoFocus && ref.current) {
            ref.current.focus();
        }
    }, [autoFocus]);

    return <React.Fragment>
        {fields.length
            ? fields.map((field, idx) => <React.Fragment key={idx}>
                <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={disabled}>
                    <InFormContent inline>
                        <IconButton color='primary' onClick={() => fields.remove(idx)}
                                    disabled={disabled}>
                            <DeleteIcon />
                        </IconButton>
                    </InFormContent>
                </Tooltip>
                <FieldComponent {...rest_of_props} namePrefix={null} name={field} />
                <SpaceDivider />
            </React.Fragment>)
            : <Field name={name} fieldType='ErrorField' />}
        <InFormContent>
            <Button ref={ref}
                    variant='outlined'
                    disabled={disabled}
                    color='primary' onClick={() => fields.push()}>
                <AddIcon />
                <FormattedMessage id={props.setting.get('field_type') === 'certificate' ? 'certificatefield.add' : 'actions.create'} />
            </Button>
        </InFormContent>
    </React.Fragment>;
}

/**
 * Renders Setting Field or Indexed Setting Field based on Provided setting
 */
export default function SettingFieldDecider(props) {
    const FieldComponent = useMemo(() => {
        if (props.setting.get('indexed') && props.setting.getIn(['indexed', 'enabled']) && !props.indexed_inside) {
            return IndexedSettingField;
        } else {
            return SettingField;
        }
    }, [props.setting.get('indexed'), props.indexed_inside]);
    const fieldArray = useMemo(() => {
        return ['certificate'].includes(props.setting.get('field_type'));
    }, [props.setting.get('field_type')]);

    if (fieldArray) {
        return <FieldArray
            name={`${props.namePrefix ? `${props.namePrefix}.` : ''}${props.name || 'value'}`}
            component={RenderFieldArray}
            props={{
                FieldComponent: FieldComponent,
                name: `${props.namePrefix ? `${props.namePrefix}.` : ''}${props.name || 'value'}`,
                ...props
            }} />;
    } else {
        return <FieldComponent {...props} />;
    }
}

export {validateSettingsField, getSettingInitialValue, getSettingsInitialValues};
