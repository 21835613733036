import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, injectIntl} from 'react-intl';
import {getFormSubmitErrors, getFormSyncErrors, getFormValues, reduxForm, SubmissionError, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {Base64} from 'js-base64';
import {Company, Endpoint, EndpointsImport, FeatureFlag, Product, ProductGroup, ProvisioningProfile, Setting, SettingGroup, Subscription, SubscriptionPlan, User} from 'lib/models';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
import {useLocalSort} from 'lib/filters';
import useFeatureFlag, {evaluateFeatureFlag} from 'lib/featureFlag';
import {findProductByMac} from 'lib/endpoint';
// Actions
import {setState} from 'actions/app';
import {
    deleteItem,
    fetchItem,
    fetchItems,
    markFiltered,
    saveItem,
    setCollection,
    simplePost,
    uploadFiles
} from 'actions/shared';
// Components
import SettingsManager from 'components/modules/settings/SettingsManager';
import ProvisioningFiles from 'components/modules/provfiles/List';
import VoiceQuality from 'components/modules/voicequality/List';
import ProvisioningLogs from 'components/modules/provlogs/List';
import Tickets from 'components/modules/tickets/List';
import DeviceManagement from 'components/modules/tickets/DeviceManagement';
import ThemeProvider from 'components/ThemeProvider';
import {EndpointStatusActionButton} from 'components/core/ui/fields/EndpointStatusField';
import SB327ComplianceDialog from 'components/modules/endpoints/SB327ComplianceDialog';
import DMStateDialog from 'components/core/ui/DMStateDialog';
import DMActivateDialog from 'components/core/ui/DMActivateDialog';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {CompanyField, DropFile, FieldIcon, FieldWithIconHolder, getSettingsInitialValues,
    InFormContent, MACSField, validateSettingsField} from 'components/core/ui/Field';
import Tabs from 'components/core/ui/Tabs';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Tab from 'components/core/ui/mui/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import CopyIcon from '@material-ui/icons/AddToPhotosOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';
import DownloadIcon from '@material-ui/icons/SaveAltOutlined';


const useStyles = makeStyles(theme => ({
    phoneIcon: {
        // flip
        transform: 'scaleX(-1)'
    },
    macField: {
        '& input': {
            userSelect: 'all' // easier copy of MAC address
        }
    },
    dmEnabledWarning: {
        textAlign: 'right',
        marginBottom: `-${theme.spacing(2)}px`,
        // style like an alert
        '& > div': {
            display: 'inline-block',
            border: `1px solid ${theme.palette.orange[500]}`,
            color: theme.palette.orange[500],
            borderRadius: theme.shape.borderRadius,
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
        }
    },
    // holder of tabs (single create / upload import)
    createTabsBar: {
        // size of 2 fields next to each other
        width: '656px'
    },
    createTab: {
        // two tabs next to each other
        width: '50%',
        minWidth: '0',
        maxWidth: 'none'
    }
}));


/**
 * CardActions for saving, deleting and returning back to List.
 *  - Used multiple times in Detail <Tabs />
 */
function EndpointCardActions(props) {
    const {edit, selectedCompanyIdentifier, permission, handleSubmit, setDeleteDialogOpen, formValues, change} = props;
    // redux store
    const dispatch = useDispatch();
    const {state} = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);
    // router
    const history = useHistory();
    const {pathname, hash} = useLocation();

    return [
        'saving_item_endpoints', `saving_item_endpoints-${selectedCompanyIdentifier}`,
        'saved_item_endpoints', `saved_item_endpoints-${selectedCompanyIdentifier}`,
        'failed_save_item_endpoints', `failed_save_item_endpoints-${selectedCompanyIdentifier}`,
        'uploading_endpoints-imports', `uploading_endpoints-imports-${selectedCompanyIdentifier}`,
        'uploaded_endpoints-imports', `uploaded_endpoints-imports-${selectedCompanyIdentifier}`,
        'failed_upload_endpoints-imports', `failed_upload_endpoints-imports-${selectedCompanyIdentifier}`,
        'saving_item_endpoints-imports', `saving_item_endpoints-imports-${selectedCompanyIdentifier}`,
        'saved_item_endpoints-imports', `saved_item_endpoints-imports-${selectedCompanyIdentifier}`,
        'failed_save_item_endpoints-imports', `failed_save_item_endpoints-imports-${selectedCompanyIdentifier}`,
        'copy_item_endpoints-settings'
    ].includes(state)
        ? <CardActionsLoader
            success={[
                'saved_item_endpoints', `saved_item_endpoints-${selectedCompanyIdentifier}`,
                'saved_item_endpoints-imports', `saved_item_endpoints-imports-${selectedCompanyIdentifier}`,
                'copy_item_endpoints-settings'
            ].includes(state)}
            failure={[
                'failed_save_item_endpoints', `failed_save_item_endpoints-${selectedCompanyIdentifier}`,
                'failed_upload_endpoints-imports', `failed_upload_endpoints-imports-${selectedCompanyIdentifier}`,
                'failed_save_item_endpoints-imports', `failed_save_item_endpoints-imports-${selectedCompanyIdentifier}`
            ].includes(state)}
            postAnimation={() => {
                if (['saved_item_endpoints-imports', `saved_item_endpoints-imports-${selectedCompanyIdentifier}`].includes(state)) {
                    // redirect to import tab after success animation
                    history.push('/phones#imports');
                }
                dispatch(setState(null));
            }}
        />
        : <ThemeProvider alt>
            <CardActions>
                <Button disabled={state !== null}
                        onClick={() => history.push(
                            `/${pathname.startsWith('/all-') ? 'all-' : ''}phones${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`)}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                {permission === 'RW' && <React.Fragment>
                    {(edit && !hash) && <Button variant='outlined' color='primary' disabled={state !== null}
                        onClick={() => {
                            dispatch(setState('copy_item_endpoints-settings'));
                            history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}phones/add${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                            setTimeout(() => {
                                Object.entries(formValues).map(([key, value]) => change(key, value));
                            }, 0);
                        }}>
                        <CopyIcon />
                        <FormattedMessage id='actions.copy' />
                    </Button>}
                    {(edit && !!setDeleteDialogOpen) && <Button variant='contained' color='secondary' disabled={state !== null}
                                                               onClick={() => setDeleteDialogOpen(true)}>
                        <DeleteIcon />
                        <FormattedMessage id='actions.delete' />
                    </Button>}
                    <Button variant='contained' color='primary' type='submit' disabled={state !== null}
                            onClick={() => handleSubmit()}>
                        <SaveIcon />
                        <FormattedMessage id='actions.save' />
                    </Button>
                </React.Fragment>}
            </CardActions>
        </ThemeProvider>;
}

/**
 * Renders detail of Endpoint - ADD & EDIT
 */
function Detail(props) {
    const featureFlag_provLogs = useFeatureFlag('provisioning_logging', {
        mac: props.match.params.identifier,
        company: props.selectedCompany?.get(new Company().getUniqueIdentifier()),
        user: props.user.get(new User().getUniqueIdentifier())
    });
    const featureFlag_provLogs_frontend = useFeatureFlag('provisioning_logging_frontend', {
        mac: props.match.params.identifier,
        company: props.selectedCompany?.get(new Company().getUniqueIdentifier()),
        user: props.user.get(new User().getUniqueIdentifier())
    });
    const featureFlag_deviceManager = props.featureFlag_deviceManager;
    const featureFlag_sb327 = useFeatureFlag('sb327', {
        mac: props.match.params.identifier,
        company: props.selectedCompany?.get(new Company().getUniqueIdentifier())
    });
    // style
    const classes = useStyles();
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sb32ComplianceDialogOpen, setSb32ComplianceDialogOpen] = useState(false);
    const [fileTouched, setFileTouched] = useState(false);
    const [activateDMDialogSeen, setActivateDMDialogSeen] = useState(false);
    // sorting
    const [sortedProvisioningProfiles] = useLocalSort(props.provisioning_profiles_items, !props.provisioning_profiles_loaded);
    const [sortedGroups] = useLocalSort(props.groups_items, !props.groups_loaded);
    const [sortedSettings] = useLocalSort(props.settings_items, !props.settings_loaded);
    const sortedFilteredSettings = useMemo(() => {
        if (props.product && props.product.get('groups') && props.product_groups_loaded && props.settings_loaded) {
            const product_groups_ids = props.product_groups_items.filter(product_group => props.product.get('groups').includes(product_group.get(new ProductGroup().getUniqueIdentifier()))).map(product_group => product_group.get(new ProductGroup().getUniqueIdentifier()));
            return sortedSettings.filter(setting => setting.get('product_groups') && product_groups_ids.filter(product_group_id => setting.get('product_groups').includes(product_group_id)).size);
        } else {
            return new ImmutableList();
        }
    }, [sortedSettings, props.product, props.product_groups_loaded, props.settings_loaded]);
    // company field's props
    const {renderCompanyField, companyValue} = useMemo(() => ({
        renderCompanyField: props.company.get('company_type') === 'reseller' || props.location.pathname.startsWith('/all-'),
        companyValue: !edit && props.selectedCompanyIdentifier === 'my' ? '' : props.selectedCompanyIdentifier === 'my'
            ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier
    }), [props.company.get('company_type'), props.location.pathname, props.location.search]);

    const macsExampleFileUrl = useMemo(() => {
        return window.URL.createObjectURL(new Blob(['000000001001,000000001002|000000001003;000000001004 000000001005\n000000001006'], {type: 'text/plain'}));
    }, []);

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', props.selectedCompanyIdentifier],
                {success_affect_state: !edit, ignore_403: !props.location.pathname.startsWith('/all-')});
        } else if (edit) {
            // if not loaded or dm_enabled, fetch additional info to get endpoint data
            if (!props.item || (featureFlag_deviceManager && props.item.get('dm_enabled'))) {
                props.fetchItem(Endpoint,
                    props.selectedCompanyIdentifier === 'my' ? 'endpoints' : `endpoints-${props.selectedCompanyIdentifier}`,
                    [
                        props.selectedCompany.getIn(['links', 'endpoints']),
                        props.match.params.identifier
                    ],
                    {company: props.selectedCompany, affect_state: !props.item}
                );
            }
            if (['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state) && props.item) {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany]);
    // related data fetch without affecting state
    useEffect(() => {
        if (props.provisioning_profiles_loaded === false && props.selectedCompany) {
            props.fetchItems(ProvisioningProfile, props.provisioning_profiles_placement, props.selectedCompany.getIn(['links', 'provisioning-profiles']), null, null, {affect_state: false});
        }
    }, [props.selectedCompany, props.provisioning_profiles_loaded]);
    useEffect(() => {
        if (props.products_loaded === false) {
            props.fetchItems(Product, 'products', 'products', null, null, {affect_state: false});
        }
    }, [props.products_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            props.fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false});
        }
    }, [props.product_groups_loaded]);
    useEffect(() => {
        if (props.groups_loaded === false) {
            props.fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false});
        }
    }, [props.groups_loaded]);
    useEffect(() => {
        if (props.settings_loaded === false) {
            props.fetchItems(Setting, 'settings', 'settings', null, null, {affect_state: false});
        }
    }, [props.settings_loaded]);
    useEffect(() => {
        if (props.settings_loaded && props.item && props.item.get('settings_manager')) {
            props.setCollection(Setting, 'settings-selected', props.settings_items.filter(
                setting => [...props.item.get('settings_manager').keys()].includes(setting.get(new Setting().getUniqueIdentifier()))
            ));
        } else if (props.settings_selected.size && props.state !== 'copy_item_endpoints-settings') {
            props.setCollection(Setting, 'settings-selected', []);
        }
    }, [props.settings_loaded, props.item]);
    useEffect(() => {
        if (props.selectedCompany && props.selectedCompany.get('has_subscription') && props.subscriptionPlan_loaded === false && featureFlag_deviceManager) {
            props.fetchItems(SubscriptionPlan, props.subscriptionPlan_placement, props.selectedCompany.getIn(['links', 'subscription-plans']), null, null, {affect_state: false});
        }
    }, [props.selectedCompany, props.subscriptionPlan_loaded]);
    // check for subscription
    useEffect(() => {
        if (props.selectedCompany && props.selectedCompany.get('has_subscription') && !props.subscription && featureFlag_deviceManager) {
            props.fetchItem(Subscription, 'subscriptions-global', props.selectedCompany.getIn(['links', 'subscription']), {affect_state: null});
        }
    }, [props.selectedCompany]);
    // unmount cleanup
    useEffect(() => {
        return () => {
            if (props.macs_information.size) {
                props.setCollection(Product, 'endpoint_macs_information', []);
            }
            if (props.invalid_macs.size) {
                props.setCollection(Product, 'endpoint_invalid_macs', []);
            }
            if (props.settings_selected.size) {
                props.setCollection(Setting, 'settings-selected', []);
            }
        };
    }, []);

    /**
     * Make sure the endpoint + settings are SB-327 compliant.
     */
    const handleSubmit = () => {
        if (props.state === null && props.formValues) {
            if (props.formValues.autoprovisioning_enabled === false && (
                (props.product_groups && props.product_groups.find(product_group => product_group.get('vpn')) && props.formValues.vpn === true) ||
                (props.settings_selected.size > 0 && !(props.settings_selected.size === 1 && props.settings_selected.first().get('param_name') === 'setting_server'))
            )) {
                if (featureFlag_sb327) {
                    const payload = {
                        settings: _selectedSettingsToSettingsMap(props.settings_selected, props.formValues.settings_manager),
                        provisioning_profile: props.formValues.provisioning_profile,
                        initial_provisioning: true
                    };
                    checkSB327Compliance(payload)
                        .then(() => enableAutoProvisioning())
                        .catch(() => setSb32ComplianceDialogOpen(true));
                }
                else {
                    enableAutoProvisioning();
                }
            }
            else if (featureFlag_sb327 && props.formValues.autoprovisioning_enabled) {
                const payload = {
                    settings: _selectedSettingsToSettingsMap(props.settings_selected, props.formValues.settings_manager),
                    provisioning_profile: props.formValues.provisioning_profile
                };

                checkSB327Compliance(payload)
                    .then(() => props.handleSubmit())
                    .catch(() => setSb32ComplianceDialogOpen(true));
            }
            else {
                props.handleSubmit();
            }
        }
    };

    const enableAutoProvisioning = () => {
        props.change('autoprovisioning_enabled', true);
        // dirty fix, because redux-form change doesn't return promise so autoprovisioning_enabled is still false
        setTimeout(() => props.handleSubmit(), 0);
    };

    /*
     * Triggers an API request to check if the current endpoint configuration is
     * compliant with SB-327. Returns a promise for asynchronous execution based
     * on the endpoint's compliance status.
     */
    const checkSB327Compliance = (payload) => {
        return new Promise((resolve, reject) => {
            props.dispatch(simplePost(
                'checking_sb327_compliance',
                props.item.getIn(['links', 'sb327-compliance']),
                payload,
                {success_state: null, post_method: 'put'}
            )).then((response) => {
                if (response.data.is_compliant) {
                    resolve();
                } else {
                    reject();
                }
            });
        });
    };

    const onChangeCompany = useCallback(value => {
        // clear value
        props.change('provisioning_profile', '');
        // remove invalid_macs collection
        props.setCollection(Product, 'endpoint_invalid_macs', []);
        // trigger validation so we display proper field error
        props.change('_validationHack', Date.now());
        // actually change company
        props.history.push({search: value ? `?company=${value}` : '', hash: props.location.hash});
    }, [props.location.hash]);

    return <div>
        {[
            'fetching_item_companies', 'fetching_item_my-companies',
            'fetching_item_endpoints', `fetching_item_endpoints-${props.selectedCompanyIdentifier}`,
            'deleting_item_endpoints', `deleting_item_endpoints-${props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='endpoints.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='endpoint' className={classes.phoneIcon} />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='endpoints.detail.notfound.title' />}
                text={<FormattedMessage id='endpoints.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <React.Fragment>
                <Tabs
                    tabs={!edit ? null : [
                        <Tab key={0} label={<FormattedMessage id='endpoints.detail.tabs.main' />}
                             error={props.submitFailed && props.error && props.error.includes('main')}
                             softDisabled={props.state !== null} />,
                        <Tab key={1} label={<FormattedMessage id='endpoints.detail.tabs.settings' />}
                             error={props.submitFailed && props.error && props.error.includes('settings')}
                             softDisabled={props.state !== null} />,
                        <Tab key={2} label={<FormattedMessage id='endpoints.detail.tabs.files' />}
                             softDisabled={props.state !== null} />,
                        ...(featureFlag_provLogs && featureFlag_provLogs_frontend ? [
                            <Tab key={3} label={<FormattedMessage id='endpoints.detail.tabs.logs' />}
                                 softDisabled={props.state !== null} />
                        ] : []),
                        ...(featureFlag_deviceManager && (props.product?.get('device_manager_compatible') || props.initialValues.dm_enabled) ? [
                            <Tab key={4} label={<FormattedMessage id='endpoints.detail.tabs.voicequality' />}
                                 disabled={!props.initialValues.dm_enabled} softDisabled={props.state !== null} />,
                            <Tab key={5} label={<FormattedMessage id='endpoints.detail.tabs.tickets' />}
                                 disabled={!props.initialValues.dm_enabled} softDisabled={props.state !== null} />
                        ] : [])
                    ]}
                    initialSelectedTab={([
                        '#settings', '#files',
                        ...(featureFlag_provLogs && featureFlag_provLogs_frontend ? ['#logs'] : []),
                        ...(featureFlag_deviceManager && props.initialValues.dm_enabled ? ['#voice-quality', '#tickets'] : [])
                    ].findIndex(tab_hash => tab_hash === props.location.hash) + 1) || 0}
                    onChange={(tab) => {
                        props.history.push({
                            hash: [
                                null, '#settings', '#files',
                                ...(featureFlag_provLogs && featureFlag_provLogs_frontend ? ['#logs'] : []),
                                ...(featureFlag_deviceManager && props.initialValues.dm_enabled ? ['#voice-quality', '#tickets'] : [])
                            ][tab],
                            // keep Company but clear all other query params (various filters that are individual per tab)
                            search: props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`
                        });
                        // trigger validation hack to have proper validation errors after fields are destroyed
                        setTimeout(() => props.change('_validationHack', Date.now()), 0);
                    }}
                    destroyNotSelected
                    content={[
                        <div key={0}>
                            <Card>
                                <CardHeader title={<FormattedMessage id={`endpoints.detail.${edit ? 'edit' : 'add'}.title`} />}
                                            subheader={<FormattedMessage id={`endpoints.detail.${edit ? 'edit' : 'add'}.subheader`} />}
                                            action={featureFlag_deviceManager && edit && props.item && props.item.get('dm_enabled')
                                                ? <EndpointStatusActionButton endpoint={props.item} permission={props.permission} />
                                                : <ActionButton iconButton disabled>
                                                    <ModelIcon model='endpoint' className={classes.phoneIcon} />
                                                </ActionButton>} />
                                <CardContent>
                                    <Row wrap>
                                        <Col width={edit ? '50%' : '100%'}>
                                            <Tabs tabs={edit || !props.endpointsImportEnabled ? null : ['manual', 'file'].map((tab, idx) => <Tab
                                                key={idx} className={classes.createTab}
                                                label={<FormattedMessage id={`endpoints.detail.tabs.${tab}`} />}
                                                subtitle={<FormattedMessage id={`endpoints.detail.tabs.${tab}.subtitle`} />}
                                            />)}
                                                initialSelectedTab={[null, '#file'].findIndex((hash) => hash === props.history.location.hash) > 0 ? 1 : 0} destroyNotSelected
                                                onChange={(tabIndex) => props.history.push({hash: [null, '#file'][tabIndex], search: props.location.search})}
                                                appBarProps={{className: classes.createTabsBar}}
                                                content={[
                                                    <div key={0}>
                                                        <Form onSubmit={() => handleSubmit()}>
                                                            {edit
                                                                ? <Field name='name' fieldType='TextField' label={<FormattedMessage id='endpoints.detail.form.fields.name' />} />
                                                                : <MACSField
                                                                    label={`${props.intl.formatMessage({id: 'endpoints.detail.form.fields.macs'})}*`}
                                                                    helperText={<FormattedMessage id='endpoints.detail.form.fields.macs.help' />}
                                                                    macs_information={props.macs_information}
                                                                    invalid_macs={props.invalid_macs}
                                                                />}
                                                            {renderCompanyField && <CompanyField value={companyValue} change={onChangeCompany} disabled={edit}
                                                                all={false} fetch_company={false} my_companies={!props.location.pathname.startsWith('/all-')}
                                                            />}
                                                            {!edit
                                                                ? <InFormContent>
                                                                    <FormattedMessage id='endpoints.detail.mac_overwrite' />
                                                                    <SpaceDivider double />
                                                                </InFormContent>
                                                                : <SpaceDivider />}
                                                            {edit && <Field disabled={true} name='mac' fieldType='TextField' required label={props.intl.formatMessage({id: 'endpoints.detail.form.fields.mac'})} className={classes.macField} />}
                                                            <Field name='provisioning_profile' fieldType='Select' label={<FormattedMessage id='endpoints.detail.form.fields.provprofile' />}
                                                                helperText={<FormattedMessage id='endpoints.detail.form.fields.provprofile.help' />}
                                                                disabled={!props.provisioning_profiles_loaded} loading={!props.provisioning_profiles_loaded}>
                                                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                                                <MenuItem fake value={props.formValues && props.formValues.provisioning_profile} />
                                                                {sortedProvisioningProfiles.map((provprofile, idx) =>
                                                                    <MenuItem key={idx} value={provprofile.get(new ProvisioningProfile().getUniqueIdentifier())}>{provprofile.get('name')}</MenuItem>
                                                                )}
                                                            </Field>
                                                            {!edit && props.initialValues?.dm_enabled && !activateDMDialogSeen &&
                                                                <DMActivateDialog accept={() => setActivateDMDialogSeen(true)} />
                                                            }
                                                        </Form>
                                                    </div>,
                                                    (props.endpointsImportEnabled && !edit) && <div key={1}>
                                                        <Form onSubmit={() => handleSubmit()}>
                                                            <DropFile
                                                                required label='File' initialFile={{}} selectedFile={{}}
                                                                error={!!(fileTouched && (props.submitErrors.file_id?.[0] || props.formErrors.file))}
                                                                onChange={(file) => {
                                                                    props.change('file', file);
                                                                    props.clearSubmitErrors();
                                                                    setFileTouched(true);
                                                                }}
                                                                helperText={fileTouched
                                                                    ? (props.submitErrors.file_id?.[0] || props.formErrors.file)
                                                                    : <FormattedMessage id='endpoints.detail.form.fields.file.help' />}
                                                            />
                                                            <div>
                                                                {renderCompanyField && <CompanyField value={companyValue} change={onChangeCompany} all={false}
                                                                    fetch_company={false} my_companies={!props.location.pathname.startsWith('/all-')}
                                                                />}
                                                                <SpaceDivider/>
                                                                <Field name='provisioning_profile' fieldType='Select' label={<FormattedMessage id='endpoints.detail.form.fields.provprofile' />}
                                                                    helperText={<FormattedMessage id='endpoints.detail.form.fields.provprofile.help' />}
                                                                    disabled={!props.provisioning_profiles_loaded} loading={!props.provisioning_profiles_loaded}>
                                                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                                                    <MenuItem fake value={props.formValues && props.formValues.provisioning_profile} />
                                                                    {sortedProvisioningProfiles.map((provprofile, idx) =>
                                                                        <MenuItem key={idx} value={provprofile.get(new ProvisioningProfile().getUniqueIdentifier())}>{provprofile.get('name')}</MenuItem>
                                                                    )}
                                                                </Field>
                                                                <SpaceDivider none />
                                                                <Button className='downloadButton' startIcon={<DownloadIcon />} component='a' href={macsExampleFileUrl} download='macs-example.txt'>
                                                                    <FormattedMessage id='endpoints.detail.tabs.file.exampleFile' />
                                                                </Button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                ]} />
                                            {edit && <Form onSubmit={() => handleSubmit()}>
                                                <SpaceDivider />
                                                <FieldWithIconHolder>
                                                    <Field withIcon={props.user.isAdmin()} disabled={true} fieldType='NoReduxTextField' label={<FormattedMessage id='endpoints.detail.form.fields.product' />}
                                                           value={props.products_loaded
                                                               ? props.product
                                                                   ? props.product.get('name')
                                                                   : props.intl.formatMessage({id: 'filters.none'})
                                                               : ''} />
                                                    {props.user.isAdmin() && <FieldIcon
                                                        disabled={props.permissions.get('product') === 'X' || props.state !== null || !props.product}
                                                        onClick={() => props.permissions.get('product') !== 'X' && props.state === null && props.product
                                                            ? props.history.push(`/products/${props.product.get(new Product().getUniqueIdentifier())}`)
                                                            : {}}>
                                                        <LinkToIcon />
                                                    </FieldIcon>}
                                                </FieldWithIconHolder>
                                                <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='endpoints.detail.form.fields.created_at' />}
                                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                                <SpaceDivider />
                                                <Field name='warranty_expires_at' fieldType='TextField' label={<FormattedMessage id='endpoints.detail.form.fields.warranty_expires_at' />}
                                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                                <SpaceDivider none />
                                                <Field name='warranty_exp_warning_period' fieldType='Select' label={<FormattedMessage id='endpoints.detail.form.fields.warranty_exp_warning_period' />}
                                                       helperText={<FormattedMessage id='endpoints.detail.form.fields.warranty_exp_warning_period.help' />}>
                                                    <MenuItem value={0}><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                                    {[3, 6, 12].map(n => <MenuItem key={n} value={n}>
                                                        <FormattedMessage id='endpoints.detail.form.fields.warranty_exp_warning_period.length.n' values={{n: n}} />
                                                    </MenuItem>)}
                                                </Field>
                                            </Form>}
                                        </Col>
                                        {edit && <Col width='50%'>
                                            <Form onSubmit={() => handleSubmit()}>
                                                {(props.product_groups && props.product_groups.find(product_group => product_group.get('vpn'))) && <Field
                                                    name='vpn' fieldType='Checkbox' size='regular'
                                                    label={<FormattedMessage id='endpoints.detail.form.fields.vpn' />}
                                                    helperText={<FormattedMessage
                                                        id='endpoints.detail.form.fields.vpn.help'
                                                        values={{
                                                            link: <a
                                                                href='https://service.snom.com/display/wiki/Configuring+VPN+on+Snom+Deskphones#ConfiguringVPNonSnomDeskphones-InstalltheVPNpatchontheSnomphone'
                                                                target='_blank' rel='noreferrer' className='hover-border'>
                                                                <FormattedMessage id='endpoints.detail.form.fields.vpn.help.link' />
                                                            </a>
                                                        }} />}
                                                />}
                                                {(featureFlag_deviceManager && (props.product?.get('device_manager_compatible') || props.initialValues.dm_enabled)) && <React.Fragment>
                                                    <Field
                                                        name='dm_enabled' fieldType='Checkbox' size='regular'
                                                        label={<FormattedMessage id='endpoints.detail.form.fields.dm_enabled' />}
                                                        helperText={<FormattedMessage id='endpoints.detail.form.fields.dm_enabled.help' />}
                                                        disabled={!props.selectedCompany?.get('has_subscription') && !props.initialValues.dm_enabled}
                                                    />
                                                    <DMStateDialog
                                                        state={props.formValues.dm_enabled}
                                                        initialState={props.initialValues.dm_enabled}
                                                        changeState={state => props.change('dm_enabled', state)}
                                                    />
                                                </React.Fragment>}
                                                <SpaceDivider none />
                                                {props.selectedCompany?.get('provisioning_strategy') === '3CX' && <Field
                                                    name='custom_fields.patch_3cx' fieldType='Checkbox' size='regular'
                                                    label={<FormattedMessage id='endpoints.detail.form.fields.custom_fields.patch_3cx' />}
                                                    helperText={<FormattedMessage id='endpoints.detail.form.fields.custom_fields.patch_3cx.help' />} />}
                                            </Form>
                                        </Col>}
                                    </Row>
                                    {(!props.initialValues.dm_enabled && props.formValues.dm_enabled && props.subscription?.get('trial_active') && props.subscriptionPlan?.get('trial_limit') && props.subscriptionPlan?.get('trial_limit') === props.selectedCompany?.get('dm_enabled_endpoints')) && <React.Fragment>
                                        <SpaceDivider />
                                        <div className={classes.dmEnabledWarning}>
                                            <div><FormattedMessage id='endpoints.detail.warning.dm_enabled.trial' /></div>
                                        </div>
                                    </React.Fragment>}
                                </CardContent>
                                <EndpointCardActions
                                    edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                    handleSubmit={handleSubmit} setDeleteDialogOpen={setDeleteDialogOpen} formValues={props.formValues} change={props.change} />
                            </Card>
                            {(featureFlag_deviceManager && (edit && props.initialValues.dm_enabled)) && <React.Fragment>
                                <SpaceDivider grid />
                                <DeviceManagement permission={props.permission} item={props.item} />
                            </React.Fragment>}
                        </div>,
                        ...(!edit ? [] : [
                            <div key={1}>
                                <SettingsManager
                                    location='endpoints'
                                    permission={props.permission}
                                    change={props.change}
                                    formValues={props.formValues}
                                    handleSubmit={handleSubmit}
                                    product={props.product || null}
                                    sortedGroups={sortedGroups}
                                    sortedSettings={sortedFilteredSettings}
                                    settings_selected={props.settings_selected}
                                    cardActions={<EndpointCardActions
                                        edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                        handleSubmit={handleSubmit}
                                        formValues={props.formValues} change={props.change}
                                    />}
                                />
                            </div>,
                            <div key={2}>
                                <ProvisioningFiles
                                    location='endpoint' doNotFetchProductGroup
                                    filterProductGroups={props.product ? props.product.get('groups').toJS() || [] : []}
                                    initialItemSelectedFileIDs={props.initialValues.provisioning_files || []}
                                    itemSelectedFileIDs={props.formValues.provisioning_files || []}
                                    itemSelectFileIDs={(ids) => props.change('provisioning_files', ids)}
                                    cardActions={<EndpointCardActions
                                        edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                        handleSubmit={handleSubmit}
                                        formValues={props.formValues} change={props.change}
                                    />}
                                />
                            </div>,
                            ...(featureFlag_provLogs ? [<div key={3}>
                                {<ProvisioningLogs item={props.item} has_subscription={props.selectedCompany?.has_subscription} is_admin={props.user.isAdmin()}/>}
                            </div>] : []),
                            ...(featureFlag_deviceManager && props.initialValues.dm_enabled ? [
                                <div key={4}>
                                    <VoiceQuality permission={props.permission} item={props.item} />
                                </div>,
                                <div key={5}>
                                    <Tickets permission={props.permission} item={props.item} />
                                </div>
                            ] : [])
                        ])
                    ]}>
                </Tabs>
                {(edit && props.permission === 'RW') && <React.Fragment>
                    <DeleteDialog
                        item={props.intl.formatMessage({id: 'endpoints.detail.deletedialog.item'})}
                        items={props.intl.formatMessage({id: 'endpoints.detail.deletedialog.items'})}
                        open={deleteDialogOpen}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleConfirm={() => {
                            setDeleteDialogOpen(false);
                            props.deleteItem(Endpoint, `endpoints${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`, props.item.getIn(['links', 'self']), props.item).then(() => {
                                // mark paginated lists for reload
                                props.markFiltered(Endpoint, `endpoints${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`);
                                props.markFiltered(Endpoint, 'endpoints-global');
                                props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}phones${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                            });
                        }}
                    />
                    { (props.product) && <SB327ComplianceDialog
                                settings_list={props.settings_items?.filter(setting => featureFlag_sb327 && props.product.get('sb327_settings').includes(setting.get('uuid')))}
                                open={sb32ComplianceDialogOpen}
                                handleClose={() => {
                                    setSb32ComplianceDialogOpen(false);
                                }}
                                handleConfirm={() => {
                                    setSb32ComplianceDialogOpen(false);
                                    enableAutoProvisioning();
                                }}
                            />
                    }
                </React.Fragment>}
            </React.Fragment>}
    </div>;
}


function _getCommonProductGroups(macsInformation) {
    // get product groups and get rid of undefined values when text field is empty yet
    const productGroups = macsInformation.map(item => item?.product?.get('groups').toJS()).filter(item => item) || [];
    // check if macs are related to the same product group
    return productGroups.reduce((accumulator, current) => accumulator.filter(value => current !== undefined && current.includes(value)), productGroups[0]);
}

const validate = (data, props) => {
    const errors = {settings_manager: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    let nested_settings = data.settings_manager ? new ImmutableMap(Object.entries(data.settings_manager)) : new ImmutableMap(); // nested fix

    // in case provisioning profile have dm_enabled, endpoint must also have it
    if (props.featureFlag_deviceManager && props.provisioning_profile && props.provisioning_profile.get('dm_enabled')) {
        validator.isTrue(
            props.intl.formatMessage({id: 'endpoints.detail.error.dm_enabled'}),
            errors, 'dm_enabled', data.dm_enabled);
    }

    // field validation
    validator.isInt(null, errors, 'warranty_exp_warning_period', data.warranty_exp_warning_period, {allow_leading_zeroes: false, min: 0});

    // we are creating
    if (!props.item) {
        // in case loading file
        if (props.location.hash === '#file' && props.endpointsImportEnabled) {
            validator.isNotNull(null, errors, 'file', data.file);
        } else {
            // macs field
            validator.isNotNull(null, errors, 'macs', data.macs);
            let macs_information = [];
            if (data.macs) {
                for (let mac of data.macs.split('\n')) {
                    // optimize string
                    mac = mac.replace(/\s/g, '').toLowerCase();
                    // check validity (basically isHexadecimal & isLength exactly '12')
                    let valid = /^[-a-fA-F0-9]{12}$/.test(mac);
                    // process hexadecimal mac
                    mac = mac.toString(16);
                    // try to find product
                    let product = findProductByMac(mac, props.products_items);
                    // push result to info
                    macs_information.push({mac: mac, product: product, valid: valid});
                    // mark field error
                    if (!errors['macs']) {
                        if (props.invalid_macs.includes(mac)) {
                            // taken mac (already used in different Company)
                            errors['macs'] = props.intl.formatMessage({id: 'endpoints.detail.error.macs.taken'});
                        } else if (!valid) {
                            // invalid value
                            errors['macs'] = props.intl.formatMessage({id: 'endpoints.detail.error.macs.invalid'});
                        } else if (!product) {
                            // invalid product
                            errors['macs'] = props.intl.formatMessage({id: 'endpoints.detail.error.macs.products'});
                        }
                    }
                }
            }
            // check if we have provisioning profile
            if (props.provisioning_profile) {
                const macGroups = _getCommonProductGroups(macs_information);
                // check if macs are related to the same product group
                if (!errors['macs'] && macGroups && !macGroups.length) {
                    errors['macs'] = props.intl.formatMessage({id: 'endpoints.detail.error.macs.product_group'});
                }
                // show error if provisioning profile doesn't match product group of macs
                if (macGroups && macGroups.length && !macGroups.find(productGroup => productGroup === props.provisioning_profile?.get('product_group'))) {
                    errors['provisioning_profile'] = props.intl.formatMessage({id: 'endpoints.detail.error.macs.provisioning_profile'});
                }
            }
            // set Information to collection
            props.setCollection(Product, 'endpoint_macs_information', macs_information);
        }
    } else {
        // get provisioning profile product group
        const pProfileGroup = props.provisioning_profile?.get('product_group');
        if (pProfileGroup && !props.product?.get('groups').toJS().some(group => group === pProfileGroup)) {
            errors['provisioning_profile'] = props.intl.formatMessage({id: 'endpoints.detail.error.provisioning_profile'});
        }

        if (props.settings_loaded) {
            // validate Selected Settings
            props.settings_selected.forEach((setting) => {
                // make sure to validate only NOT INDEXED fields (they have validation in IndexedSettingField)
                if (setting.get('indexed') && !setting.getIn(['indexed', 'enabled'])) {
                    errors['settings_manager'][setting.get(new Setting().getUniqueIdentifier())] = validateSettingsField(
                        nested_settings.get(setting.get(new Setting().getUniqueIdentifier())) || {},
                        setting, props.intl
                    );
                } else {
                    validator.isNotNull(
                        null, errors, `settings_manager.${setting.get(new Setting().getUniqueIdentifier())}`,
                        nested_settings.get(setting.get(new Setting().getUniqueIdentifier()))
                    );
                }
            });
        }
    }

    return errors;
};

/**
 * Convert Endpoint record toObject(). Used for initial values
 *
 * @param endpoint - Endpoint model
 */
function toObject(endpoint) {
    let itemObject = endpoint.toJS();
    itemObject.settings_manager = itemObject.settings_manager || {};
    // process settings
    endpoint.settings_manager && endpoint.settings_manager.forEach((values, key) => {
        // certificate process
        if (values.getIn(['attrs', 'type']) === 'base64') {
            const certificates = [];
            values.get('value').forEach(value => {
                certificates.push(Base64.decode(value));
            });
            itemObject.settings_manager[key]['value'] = certificates;
        }
    });
    return itemObject;
}

const DetailForm = reduxForm({
    form: 'endpointForm',
    validate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    touchOnChange: true, // necessary to have correct validation for SettingFields
    onSubmit: (values, dispatch, props) => {
        // extra care for validation in Tabs in edit that are not selected and fields inside are currently destroyed
        if (props.item) {
            const {settings_manager: settings_manager_errors, ...errors} = props.formErrors;
            const submit_errors = [];
            // settings tab
            if (Object.values(settings_manager_errors || {}).filter(error =>
                error.attrs ? !!((error.value && (typeof error.value !== 'object' || Object.values(error.value).filter(Boolean).length)) || error.attrs.perm) : !!error).length) {
                submit_errors.push('settings');
            }
            // main tab
            if (Object.keys(errors || {}).length) {
                submit_errors.push('main');
            }
            if (submit_errors.length) {
                throw new SubmissionError({_error: submit_errors});
            }
        }

        // pop-up some values which we don't want to send
        const rest_of_endpoint_data = new Endpoint().popUpFields(props.formValues);
        let {_validationHack, ...rest_of_data} = rest_of_endpoint_data;
        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));

        const placement = `endpoints${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`;

        // process VPN (only for some Product Groups)
        if (!props.product_groups || !props.product_groups.find(product_group => product_group.get('vpn'))) {
            delete rest_of_data.vpn;
        }
        // process custom_fields (only for some Company)
        if (props.selectedCompany?.get('provisioning_strategy') !== '3CX') {
            delete rest_of_data.custom_fields;
        }
        // device manager
        if (!props.featureFlag_deviceManager) {
            delete rest_of_data.dm_enabled;
        }

        // we are creating
        if (!props.item) {
            // create via file
            if (props.location.hash === '#file' && props.endpointsImportEnabled) {
                const placement = `${new EndpointsImport().getPlacement()}${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`;

                return props.uploadFiles(placement, props.selectedCompany.getIn(['links', 'endpoint-bulk-file-upload']), [values.file])
                    .then(([{data}]) => {
                        const importData = new EndpointsImport().popUpFields({...rest_of_endpoint_data, file_id: data.file_id});
                        return props.saveItem(EndpointsImport, placement, props.selectedCompany.getIn(['links', 'endpoint-bulk-load-tasks']), importData);
                    });
            }
            // split macs from data
            let {macs, ...rest_of_rest_of_data} = rest_of_data;
            macs = macs.split('\n');

            // prepare promises
            const promises = [];
            for (let mac of macs) {
                // optimize string
                mac = mac.replace(/\s/g, '').toLowerCase();
                // add promise
                promises.push(dispatch(saveItem(
                    Endpoint, placement,
                    [props.selectedCompany.getIn(['links', 'endpoints']), mac],
                    rest_of_rest_of_data, null,
                    {save_method: 'put', affect_state: false, ignore_400: true, ignore_403: true})));
            }

            dispatch(setState(`saving_item_${placement}`));
            // create all endpoints
            return Promise.all(promises).then((results) => {
                // check for errors
                if (results.includes(false)) {
                    dispatch(setState(`failed_save_item_${placement}`));
                    // get invalid macs from results (already used)
                    let used_macs = [];
                    macs.forEach((mac, idx) => {
                        // check result
                        if (results[idx] === false) {
                            // optimize string
                            mac = mac.replace(/\s/g, '').toLowerCase();
                            // push to collection
                            used_macs.push(mac);
                        }
                    });
                    // set Products to collection
                    props.setCollection(Product, 'endpoint_invalid_macs', used_macs);
                    // trigger validation so we display field error
                    props.change('_validationHack', Date.now());
                } else {
                    dispatch(setState(`saved_item_${placement}`));
                    // check if we have only one request
                    if (macs.length === 1) {
                        // redirect to detail
                        props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}phones/${results[0].get(new Endpoint().getUniqueIdentifier())}${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                    } else {
                        // redirect to list after success animation
                        setTimeout(() => {
                            props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}phones${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                        }, 1500);
                    }
                }

                if (!props.formValues.mac) { // in case of adding new endpoint
                    // make sure to reset the form as the values are changed in the edit and we no longer want add fields
                    dispatch(reduxFormReset('endpointForm'));
                }

                // mark paginated lists for reload
                dispatch(markFiltered(Endpoint, placement));
                return dispatch(markFiltered(Endpoint, 'endpoints-global'));
            }).catch(() => {
                dispatch(setState(`failed_save_item_${placement}`));
            });
        } else {
            // process settings
            let settings = {}; // we will send only selected settings
            props.settings_selected.forEach(setting => {
                let value = new ImmutableMap(Object.entries(rest_of_data['settings_manager'])).get(setting.get(new Setting().getUniqueIdentifier()));
                settings[setting.get(new Setting().getUniqueIdentifier())] = value;
                // certificate process
                if (setting.get('field_type') === 'certificate') {
                    let certificates = [];
                    value.value.forEach(certificate => {
                        certificates.push(Base64.encode(certificate));
                    });
                    settings[setting.get(new Setting().getUniqueIdentifier())]['value'] = certificates;
                }
            });
            rest_of_data['settings_manager'] = settings;

            return dispatch(saveItem(Endpoint, placement, props.item.getIn(['links', 'self']), rest_of_data, props.item, {update_method: 'put'})).then(() => {
                // mark not updated lists for reload
                return dispatch(markFiltered(Endpoint, 'endpoints-global'));
            });
        }
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);
    const subscription = selectedCompanyIdentifier !== 'my'
        ? state.shared.getIn(['items', 'subscriptions-global']).find(el => el.getIn(['links', 'self']) === company.getIn(['links', 'subscription']))
        : state.auth.get('subscription');
    const subscriptionPlan_placement = `${new SubscriptionPlan().getPlacement()}${selectedCompanyIdentifier === 'my' ? '' : `-${selectedCompanyIdentifier}`}`;

    const items = selectedCompanyIdentifier === 'my' ? state.shared.getIn(['items', 'endpoints']) : state.shared.getIn(['items', `endpoints-${selectedCompanyIdentifier}`]) || ImmutableList();
    const item = items.find(el => el.get(new Endpoint().getUniqueIdentifier()) === props.match.params.identifier);
    const itemObject = item ? toObject(item) : {};

    const provprofile_placement = selectedCompanyIdentifier === 'my' ? 'provisioning-profiles' : `provisioning-profiles-${selectedCompanyIdentifier}`;
    const product = item ? state.shared.getIn(['items', 'products']).find(el => el.getIn(['links', 'self']) === item.getIn(['links', 'product'])) : null;

    const formValues = getFormValues('endpointForm')(state);
    const provisioning_profile = formValues && formValues.provisioning_profile && state.shared.getIn(['loaded', provprofile_placement]) ? state.shared.getIn(['items', provprofile_placement]).find(el => el.uuid === formValues.provisioning_profile) : undefined;

    let initialValues = undefined;
    if (props.match.params.identifier && item) {
        // Initial values for edit mode.
        initialValues = {
            provisioning_files: [],
            vpn: false,
            custom_fields: {
                patch_3cx: false
            },
            dm_enabled: false,
            ...itemObject,
            settings_manager: {
                ...getSettingsInitialValues(state),
                ...itemObject.settings_manager
            }
        };
    } else {
        // Initial values for adding a new endpoint.
        initialValues = {
            provisioning_files: [],
            autoprovisioning_enabled: false,
            vpn: false,
            custom_fields: {
                patch_3cx: false
            },
            dm_enabled: provisioning_profile ? provisioning_profile.dm_enabled : false,
            warranty_exp_warning_period: 3,
            settings: {}
        };
    }

    return {
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        initialValues,
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        product_groups: product && state.shared.getIn(['loaded', 'product-groups']) ? state.shared.getIn(['items', 'product-groups']).filter(el => product.get('groups').includes(el.get(new ProductGroup().getUniqueIdentifier()))) : undefined,
        products_loaded: state.shared.getIn(['loaded', 'products']),
        products_items: state.shared.getIn(['items', 'products']),
        product: product,
        provisioning_profiles_loaded: !!state.shared.getIn(['loaded', provprofile_placement]),
        provisioning_profiles_items: state.shared.getIn(['items', provprofile_placement]) || ImmutableList(),
        provisioning_profiles_placement: provprofile_placement,
        provisioning_profile: provisioning_profile,
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        groups_items: state.shared.getIn(['items', 'setting-groups']),
        settings_loaded: state.shared.getIn(['loaded', 'settings']),
        settings_items: state.shared.getIn(['items', 'settings']),
        settings_selected: state.shared.getIn(['items', 'settings-selected']),
        macs_information: state.shared.getIn(['items', 'endpoint_macs_information']),
        invalid_macs: state.shared.getIn(['items', 'endpoint_invalid_macs']),
        company: company,
        user: auth_user,
        subscription: subscription,
        subscriptionPlan: subscription && state.shared.getIn(['items', subscriptionPlan_placement])?.find(el => el.get(new SubscriptionPlan().getUniqueIdentifier()) === subscription.get('plan_id')),
        subscriptionPlan_loaded: !!state.shared.getIn(['loaded', subscriptionPlan_placement]),
        subscriptionPlan_placement: subscriptionPlan_placement,
        formValues: formValues,
        formErrors: getFormSyncErrors('endpointForm')(state),
        submitErrors: getFormSubmitErrors('endpointForm')(state),
        permissions: state.auth.get('permissions'),
        endpointsImportEnabled: evaluateFeatureFlag(state.shared.getIn(['items', 'feature-flags'])
            ?.find(el => el.get(new FeatureFlag().getUniqueIdentifier()) === 'endpoint_bulk_import'),
            {company: selectedCompany?.get(new Company().getUniqueIdentifier())}),
        featureFlag_deviceManager: evaluateFeatureFlag(state.shared.getIn(['items', 'feature-flags'])
            ?.find(el => el.get(new FeatureFlag().getUniqueIdentifier()) === 'device_manager'),
            {company: selectedCompany?.get(new Company().getUniqueIdentifier())})
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    fetchItem,
    saveItem,
    deleteItem,
    markFiltered,
    setCollection,
    uploadFiles,
    reduxFormReset
}, dispatch))(DetailForm);

/*
 * Helper function for transforming the selected settings to a map of
 * settings ready to be sent to the API.
 */
function _selectedSettingsToSettingsMap(settings_selected, settings_manager) {
    let settings = {}; // we will send only selected settings
    settings_selected.forEach(setting => {
        let value = new ImmutableMap(Object.entries(settings_manager)).get(setting.get(new Setting().getUniqueIdentifier()));
        settings[setting.get(new Setting().getUniqueIdentifier())] = value;
        // certificate process
        if (setting.get('field_type') === 'certificate') {
            let certificates = [];
            value.value.forEach(certificate => {
                certificates.push(Base64.encode(certificate));
            });
            settings[setting.get(new Setting().getUniqueIdentifier())]['value'] = certificates;
        }
    });

    return settings;
}

export default injectIntl(withRouter(ConnectedDetail));
