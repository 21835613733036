import React, {useCallback, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {Statistics} from 'lib/models';
import {theme} from 'theme';
import {countries} from 'lib/countrieslist';
// actions
import {setState} from 'actions/app';
import {fetchLinks, fetchItem} from 'actions/shared';
// components
import Graph from 'components/core/ui/Graph';
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import UsersStatisticsRegTime from '@material-ui/icons/GroupAddOutlined';
import GeoDistributionIcon from '@material-ui/icons/TrackChangesOutlined';


const useStyles = makeStyles((theme) => ({
    matchCardContent: {
        height: `${theme.spacing(40)}px`,
        overflow: 'auto'
    }
}));

/**
 * Render users stats and graphs
 */
export default function StatisticUsers() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const props = useSelector((state) => {
        const findStatistic = (identifier) => state.shared.getIn(['items', 'statistics']).find(el => el.get(new Statistics().getUniqueIdentifier()) === identifier);
        const summary = findStatistic('summary')?.toJS();
        const stats_user_reg_time = findStatistic('user_reg_timeline')?.toJS();
        const stats_user_geo_distri = findStatistic('user_geo_distri')?.toJS();
        const loaded_links = state.api.getIn(['loaded_links', 'reports']);

        // process summary stats data
        // summarize number of end users and number of resellers
        if (summary?.data) {
            summary.data = [
                {value: summary.data.number_of_end_users + summary.data.number_of_resellers},
                {value: summary.data.number_of_end_users}, {value: summary.data.number_of_resellers}
            ];
        }

        return {
            loaded_links, state: state.app.get('state'),
            stats_summary: summary?.data, stats_summary_loading: !summary?.data && !loaded_links,
            stats_user_reg_time, stats_user_reg_time_loading: !stats_user_reg_time?.data && !loaded_links,
            stats_user_geo_distri: stats_user_geo_distri?.data, stats_user_geo_distri_loading: !stats_user_geo_distri?.data && !loaded_links
        };
    }, shallowEqual);
    // fetch reports and statistics
    useEffect(() => {
        if (props.loaded_links === false) {
            dispatch(fetchLinks('reports', 'reports', {success_affect_state: false}));
        } else {
            const statistics = [
                {item: props.stats_summary, name: 'summary', url: 'reports.summary'},
                {item: props.stats_user_reg_time, name: 'user_reg_timeline', url: 'reports.user-registration-timeline'},
                {item: props.stats_user_geo_distri, name: 'user_geo_distri', url: 'reports.user-geographical-distribution'}
            ];

            if (statistics.find(el => !el.item?.length)) {
                dispatch(setState('fetching_items_statistics'));
                // fetch statistics
                Promise.allSettled(statistics.map((el) => !el.item?.length &&
                    dispatch(fetchItem(Statistics, 'statistics', el.url,
                        {expand_item: new Statistics({statistic_name: el.name}), expand_item_data: true, affect_state: false}))
                )).finally(() => dispatch(setState(null)));
            }
        }
    }, [props.loaded_links]);
    useEffect(() => {
        if (props.state === 'fetching_links_reports') {
            dispatch(setState(null));
        }
    }, [props.state]);

    const formatUserGeoGraphLabel = useCallback((label) => countries.find(el => el.get('id') === label.toUpperCase())?.get('name'), []);

    return <React.Fragment>
        <Card>
            <CardHeader title={<FormattedMessage id='statistics.users.title' />} subheader={<FormattedMessage id='statistics.users.subheader' />}
                action={<ActionButton iconButton disabled><ModelIcon model='statistics_users' /></ActionButton>} />
            <CardContent>
                <Graph type='inline-list' data={props.stats_summary} options={{
                    colors: [theme.graphs[1]], links: ['/customers'],
                    base_intl: 'statistics.users.summary.data', labels: ['total', 'number_of_end_users', 'number_of_resellers']
                }} loading={props.stats_summary_loading} />
            </CardContent>
        </Card>
        <SpaceDivider grid />
        <Row wrap>
            <Col flex='2'><Card>
                <CardHeader title={<FormattedMessage id='statistics.users.reg_time.title' />}
                            subheader={<FormattedMessage id='statistics.users.reg_time.subheader' />}
                            action={<ActionButton iconButton disabled><UsersStatisticsRegTime /></ActionButton>} />
                <CardContent>
                    <Graph type='area' data={props.stats_user_reg_time} momentFormat='MM/DD' loading={props.stats_user_reg_time_loading}
                        options={{colors: [theme.graphs[1]], base_intl: 'statistics.users.reg_time.data', labels: ['total'],
                            series_name_key: 'statistic_name', label_key: 'date', value_key: 'total'}} />
                </CardContent>
            </Card></Col>
            <Col flex='1'><Card>
                <CardHeader title={<FormattedMessage id={'statistics.users.geo_distri.title'} />}
                            subheader={<FormattedMessage id={'statistics.users.geo_distri.subheader'} />}
                            action={<ActionButton iconButton disabled><GeoDistributionIcon /></ActionButton>} />
                <CardContent className={classes.matchCardContent}>
                    <Graph data={props.stats_user_geo_distri} loading={props.stats_user_geo_distri_loading} type='list'
                        options={{colors: [theme.graphs[1]], label_key: 'country', value_key: 'total'}} formatLabel={formatUserGeoGraphLabel} />
                </CardContent>
            </Card></Col>
        </Row>
    </React.Fragment>;
}
