import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
import {Company, ProductGroup} from 'lib/models';
import {useHistory, useLocation} from 'react-router';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {Link} from 'react-router-dom';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CircularProgress from 'components/core/ui/mui/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    // div with related links
    resources: {
        // get rid of ', ' divider in last link
        '& a:last-of-type ~ span': {
            display: 'none'
        }
    },
    // resourceAvatar
    avatar: {
        // breathing
        padding: `0 ${theme.spacing(0.5)}px`,
        // to right side (matching ActionButton padding proportion)
        margin: `-7px -${theme.spacing(2)}px -7px ${theme.spacing(2)}px`,
        // match ActionButton proportion
        width: 'auto',
        minWidth: '40px', // same as height
        // style
        backgroundColor: theme.palette.primary[100],
        backgroundImage: theme.gradients.blue.color,
        backgroundSize: theme.gradients.blue.backgroundSize,
        backgroundPosition: theme.gradients.blue.position,
        borderTopRightRadius: '100px',
        borderBottomRightRadius: '100px',
        // remove gradient text inherited from outlined button
        WebkitBackgroundClip: 'border-box',
        WebkitTextFillColor: 'currentcolor',
        // match button font
        ...theme.typography.button
    },
    // loader in resourceAvatar
    loader: {
        display: 'block'
    },
    // div with related buttons
    actionButtonHolders: {
        // next to each other and stretch
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'stretch',
        flexWrap: 'wrap',
        // to sides
        margin: `-${theme.spacing(0.5)}px`
    },
    actionButton: {
        flexGrow: '1',
        // same margin
        '&, &:first-child, &:last-child': {
            margin: `${theme.spacing(0.5)}px !important`
        },
        // label itself
        '& .MuiButton-label > span': {
            flexGrow: '1'
        }
    },
    // holder of additional statistics
    additionalNumbersTooltip: {
        // position and proportion
        margin: `-1px ${theme.spacing(1.5)}px -1px 0`, // connect tooltip with button (even when flipped)
        padding: '0',
        // style
        fontSize: theme.typography.pxToRem(12),
        borderRadius: '0',
        background: 'transparent',
        border: '0'
    },
    // holder
    additionalNumbers: {
        // style
        border: `1px solid ${theme.palette.primary[500]}`,
        color: theme.palette.primary[500],
        background: theme.palette.common.white
    },
    // additional statistics in tooltip
    additionalNumber: {
        // align items
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // product group
        '& > div:first-child': {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
        },
        // number
        '& > div:last-child': {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            fontWeight: theme.typography.fontWeightBold
        }
    }
}));

/**
 * Avatar in button with statistics information
 */
const ResourceAvatar = React.forwardRef((props, ref) => {
    const {number: rawNumber} = props;
    const classes = useStyles();
    // process number, it might be map
    let number = rawNumber;
    try {
        number = number.get('value');
    } catch (error) {}

    return <Avatar className={classes.avatar} ref={ref} variant='square'>
        <div>{!number && number !== 0 ? <CircularProgress size={24} _classes={{progress: classes.loader}} /> : number}</div>
    </Avatar>;
});

/**
 * Company stuff related links - Resources
 */
export default function Resources(passed_props) {
    const {item, expandable = false, in_list = false} = passed_props;
    const classes = useStyles();
    const intl = useIntl();
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        permissions: state.auth.get('permissions'),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups'])
    }), shallowEqual);
    // local state
    const [show, setShow] = useState(false);

    /**
     * During initialization fetch Product Groups if needed
     */
    useEffect(() => {
        if (!in_list && props.permissions.get('endpoint') !== 'X' && props.product_groups_loaded === false) {
            // fetch items but don't affect state
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    // hidden to increase loading performance
    if (expandable && !show) {
        return <a className='hover-border' href='#' onClick={(event) => {
            event.preventDefault(); setShow(true);
        }}>
            {`--${intl.formatMessage({id: 'companies.resources.expand'})}--`}
        </a>;
    }
    const id = item.get(new Company().getUniqueIdentifier());
    const company_id = props.company.get(new Company().getUniqueIdentifier());

    if (in_list) {
        return <div className={classes.resources}>
            {props.permissions.get('endpoint') !== 'X' && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}phones${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.endpoints' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {props.permissions.get('provisioning_profile') !== 'X' && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}provisioning-profiles${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.provprofiles' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {props.permissions.get('provisioning_files') !== 'X' && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}provisioning-files${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.provfiles' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {(props.permissions.get('companies') !== 'X') && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}product-defaults${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.productdefaults' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {(props.permissions.get('submission') !== 'X' && pathname.startsWith('/companies')) && <React.Fragment>
                <Link className='hover-border' to={`/all-submissions?company=${id}`}>
                    <FormattedMessage id='companies.resources.portal.submissions' />
                </Link>
                <span>{', '}</span>
                <Link className='hover-border' to={`/all-claims?company=${id}`}>
                    <FormattedMessage id='companies.resources.portal.claim' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {(pathname.startsWith('/companies') && props.company.get('company_type') === 'reseller' && props.permissions.get('companies') !== 'X') && <React.Fragment>
                <a className='hover-border' href='#' onClick={(e) => { e.preventDefault(); history.push({search: `?company=${id}`}); }}>
                    <FormattedMessage id='companies.resources.mycompanies' />
                </a>
                <span>{', '}</span>
            </React.Fragment>}
            {(item && item.get('parent') && pathname.startsWith('/companies') && item.get('parent') !== company_id) && <React.Fragment>
                <Link className='hover-border' to={`/companies/${item.get('parent')}`}>
                    <FormattedMessage id='companies.resources.parent' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {props.company.get('api_enabled') && props.permissions.get('token') !== 'X' && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}api-keys${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.api_keys' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            {props.permissions.get('activity') !== 'X' && <React.Fragment>
                <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}activity${id === company_id ? '' : `?company=${id}`}`}>
                    <FormattedMessage id='companies.resources.activities' />
                </Link>
                <span>{', '}</span>
            </React.Fragment>}
            <Link className='hover-border' to={`/${pathname.startsWith('/companies') ? 'all-' : ''}exports${id === company_id ? '' : `?company=${id}`}`}>
                <FormattedMessage id='companies.resources.exports' />
            </Link>
            <span>{', '}</span>
        </div>;
    } else {
        return <div className={classes.actionButtonHolders}>
            {props.permissions.get('endpoint') !== 'X' && <Tooltip
                disabled={props.state !== null}
                placement='bottom-end' classes={{tooltip: classes.additionalNumbersTooltip}}
                title={props.product_groups_loaded
                    ? <div className={classes.additionalNumbers}>
                        {item.get('no_endpoints_per_group') && [...item.get('no_endpoints_per_group')].map((entry, idx) => {
                            let product_group = props.product_groups_items.find(el => el.get(new ProductGroup().getUniqueIdentifier()) === entry[0]);
                            if (product_group) {
                                return <div key={idx} className={classes.additionalNumber}>
                                    <div>{product_group.get('name')}</div>
                                    <div>{entry[1]}</div>
                                </div>;
                            } else {
                                return <span key={idx} />;
                            }
                        })}
                    </div>
                    : <div />}>
                <ActionButton
                    className={classes.actionButton}
                    disabled={props.state !== null}
                    variant='outlined'
                    color='primary'
                    onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}phones${id === company_id ? '' : `?company=${id}`}`)}>
                    <ModelIcon model='endpoints' />
                    <FormattedMessage id='companies.resources.endpoints' />
                    <ResourceAvatar number={item.getIn(['no_endpoints_per_group', 'total'])} />
                </ActionButton>
            </Tooltip>}
            {props.permissions.get('provisioning_profile') !== 'X' && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}provisioning-profiles${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='provisioning_profiles' />
                <FormattedMessage id='companies.resources.provprofiles' />
                <ResourceAvatar number={item.get('no_provisioning_profiles')} />
            </ActionButton>}
            {props.permissions.get('provisioning_files') !== 'X' && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}provisioning-files${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='provisioning_files' />
                <FormattedMessage id='companies.resources.provfiles' />
            </ActionButton>}
            {(props.permissions.get('companies') !== 'X') && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}product-defaults${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='product_defaults' />
                <FormattedMessage id='companies.resources.productdefaults' />
            </ActionButton>}
            {(props.permissions.get('submission') !== 'X' && (pathname.startsWith('/company') || pathname.startsWith('/companies'))) && <React.Fragment>
                <ActionButton
                    className={classes.actionButton}
                    disabled={props.state !== null}
                    variant='outlined'
                    color='primary'
                    onClick={() => props.state === null ? history.push(pathname.startsWith('/company')
                        ? '/partner/submissions'
                        : `/all-submissions?company=${id}`
                    ) : {}}>
                    <ModelIcon model='submissions' />
                    <FormattedMessage id='companies.resources.portal.submissions' />
                </ActionButton>
                <ActionButton
                    className={classes.actionButton}
                    disabled={props.state !== null}
                    variant='outlined'
                    color='primary'
                    onClick={() => props.state === null ? history.push(pathname.startsWith('/company')
                        ? '/partner/claim'
                        : `/all-claims?company=${id}`
                    ) : {}}>
                    <ModelIcon model='claim' />
                    <FormattedMessage id='companies.resources.portal.claim' />
                </ActionButton>
            </React.Fragment>}
            {(item && item.get('company_type') === 'reseller' && props.permissions.get('companies') !== 'X') && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(pathname.startsWith('/company')
                    ? '/my-companies'
                    : `/companies?company=${id}`
                )}>
                <ModelIcon model='companies' />
                <FormattedMessage id='companies.resources.mycompanies' />
                <ResourceAvatar number={item.get('no_child_companies')} />
            </ActionButton>}
            {(item && item.get('parent') && pathname.startsWith('/companies') && item.get('parent') !== company_id) && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/companies/${item.get('parent')}`)}>
                <ModelIcon model='company' />
                <FormattedMessage id='companies.resources.parent' />
            </ActionButton>}
            {props.company.get('api_enabled') && props.permissions.get('token') !== 'X' && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}api-keys${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='api_keys' />
                <FormattedMessage id='companies.resources.api_keys' />
            </ActionButton>}
            {props.permissions.get('activity') !== 'X' && <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}activity${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='activities' />
                <FormattedMessage id='companies.resources.activities' />
            </ActionButton>}
            <ActionButton
                className={classes.actionButton}
                disabled={props.state !== null}
                variant='outlined'
                color='primary'
                onClick={() => history.push(`/${pathname.startsWith('/companies') ? 'all-' : ''}exports${id === company_id ? '' : `?company=${id}`}`)}>
                <ModelIcon model='exports' />
                <FormattedMessage id='companies.resources.exports' />
            </ActionButton>
        </div>;
    }
}
