import React, {useMemo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import Moment from 'moment';
import {Claim, Company, SubmissionProduct, SubmissionProductVariant} from 'lib/models';
import {useLocalSort} from 'lib/filters';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, markFiltered, saveItem} from 'actions/shared';
// Components
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder, ClaimStatusField, CountryField, SubmissionProductVariantField} from 'components/core/ui/Field';
import ThemeProvider from 'components/ThemeProvider';
import SubmissionProductVariantButton from 'components/core/ui/SubmissionProductVariantButton';
import ClaimedProductStatusCard from 'components/core/ui/ClaimedProductStatusCard';
import {withRouter} from 'react-router-dom';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';


const useStyles = makeStyles(theme => ({
    // holder of SubmissionProductVariantButtons
    buttonsHolder: {
        // next to each other & center
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        // to sides
        margin: `-${theme.spacing(1)}px`,
        '& .spv-button': {
            // space between
            margin: `${theme.spacing(1)}px`
        }
    },
    col: {
        alignSelf: 'stretch'
    },
    detailCard: {
        height: '100%'
    },
    requestClaimingButtonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& button': {
            margin: 'auto'
        }
    }
}));

/**
 * Render requesting for other products
 */
function RequestClaimingOtherProducts(props) {
    // style
    const classes = useStyles();
    // local state
    const [open, setOpen] = useState(false);
    // sorting
    const claimableByRequestVariants = useMemo(() => {
        return props.submission_products_items.flatMap(sproduct =>
            sproduct.get('variants').map(variant => (variant.get('active') && variant.get('claimable') === 'on_request') ? variant : null)
        ).filter(variant => variant);
    }, [props.submission_products_items]);
    const [sortedClaimableByRequestVariants] = useLocalSort(claimableByRequestVariants, !props.submission_products_loaded);

    return !!claimableByRequestVariants?.size && <React.Fragment>
        <SpaceDivider />
        <div className={classes.requestClaimingButtonWrapper}>
            <Button variant='outlined' color='primary' onClick={() => setOpen(true)} disabled={!sortedClaimableByRequestVariants}>
                <FormattedMessage id='claims.detail.add.claim.other.button' />
            </Button>
        </div>
        <Dialog open={open} onClose={() => setOpen(false)} scroll='body' fullWidth maxWidth='lg'>
            <DialogTitle>
                <FormattedMessage id='claims.detail.add.claim.other.title' />
                <Typography><FormattedMessage id='claims.detail.add.claim.other.subheader' /></Typography>
            </DialogTitle>
            <DialogContent>
                <div className={classes.buttonsHolder}>
                    {sortedClaimableByRequestVariants.map((variant, idx) => {
                        let variant_uuid = variant.get(new SubmissionProductVariant().getUniqueIdentifier());

                        return <SubmissionProductVariantButton
                            key={idx} spvariant={variant}
                            disabledLike={props.permission !== 'RW'}
                            onClick={() => props.change('product', variant_uuid)}
                            selected={variant_uuid === props.formValues.product}
                        />;
                    })}
                </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    {props.permission === 'RW' && <Button variant='contained' color='primary' type='submit' onClick={() => {
                        props.handleSubmit();
                        setOpen(false);
                    }}>
                        <FormattedMessage id='claims.detail.add.submit' />
                    </Button>}
                </DialogActions>
        </Dialog>
    </React.Fragment>;
}

/**
 * Renders form which allows user to submit request to 'Claim his free phone'
 * or detail for admin to edit 'Claim' state
 */
function Detail(props) {
    // style
    const classes = useStyles();
    // local state
    const edit = !!props.match.params.identifier || !!props.item;
    // sorting
    const claimableVariants = useMemo(() => {
        return props.submission_products_items.flatMap(sproduct => sproduct.get('variants').map(variant => variant.get('active') && variant.get('claimable') === 'claimable' ? variant : null)).filter(variant => variant);
    }, [props.submission_products_items]);
    const [sortedClaimableVariants] = useLocalSort(claimableVariants, !props.submission_products_loaded);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        // check if we have item or not
        if (props.match.params.identifier && !props.selectedCompany) {
            props.fetchItem(Company, 'companies', ['companies', props.match.params.identifier], {success_affect_state: !edit});
        }
    }, [props.match.params.identifier]);
    useEffect(() => {
        if (props.selectedCompany) {
            if (!props.item) {
                props.fetchItem(Claim, 'claims-global', props.selectedCompany.getIn(['links', 'free-phone-order']));
            } else if (props.state === 'fetching_item_companies') {
                props.setState(null);
            }
        }
    }, [props.selectedCompany]);
    // related data fetch without affecting state, SubmissionsProducts for variants
    useEffect(() => {
        if (props.submission_products_loaded === false) {
            props.fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false});
        }
    }, [props.submission_products_loaded]);

    return <div>
        {['fetching_item_claims-global', 'fetching_item_companies'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='claims.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='claim' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='claims.detail.notfound.title' />}
                text={<FormattedMessage id='claims.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <Row wrap>
            <Col width={props.item?.get('status') ? '50%' : '100%'} className={classes.col}>
                <Card className={classes.detailCard}>
                    <CardHeader title={edit
                        ? props.location.pathname.startsWith('/all-claims')
                            ? <FormattedMessage id='claims.detail.edit.title' />
                            : <FormattedMessage id='claims.detail.view.title' />
                        : <FormattedMessage id='claims.detail.add.title' />}
                                subheader={edit
                                    ? props.location.pathname.startsWith('/all-claims')
                                        ? <FormattedMessage id='claims.detail.edit.subheader' />
                                        : <FormattedMessage id='claims.detail.view.subheader' />
                                    : <FormattedMessage id='claims.detail.add.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='claim' />
                                </ActionButton>} />
                    {edit
                        ? <CardContent>
                            <Form onSubmit={props.handleSubmit}>
                                {props.user.isAdmin() && <FieldWithIconHolder>
                                    <Field withIcon disabled={true} name='user' fieldType='TextField'
                                           label={<FormattedMessage id='claims.detail.form.fields.user' />} />
                                    <FieldIcon disabled={props.state !== null || props.permissions.get('user') === 'X' || !props.formValues.user}
                                               onClick={() => props.history.push(`/customers/${props.formValues.user}${props.selectedCompany.get(new Company().getUniqueIdentifier()) !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${props.selectedCompany.get(new Company().getUniqueIdentifier())}` : ''}`)}>
                                        <LinkToIcon />
                                    </FieldIcon>
                                </FieldWithIconHolder>}
                                <ClaimStatusField disabled={!props.location.pathname.startsWith('/all-claims')} required name='status' hideDisabledArrow />
                                <SpaceDivider />
                                <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='claims.detail.form.fields.created_at' />}
                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                <Field name='updated_at' fieldType='TextField' label={<FormattedMessage id='claims.detail.form.fields.updated_at' />}
                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                {props.location.pathname.startsWith('/all-claims') && <React.Fragment>
                                    <SpaceDivider />
                                    <SubmissionProductVariantField
                                        name='product' disabled
                                        selectedValue={props.formValues.product || ''}
                                        doNotFetchSubmissionProducts
                                    />
                                </React.Fragment>}
                            </Form>
                        </CardContent>
                        : <React.Fragment>
                            <CardHeader center title={<FormattedMessage id='claims.detail.add.claim.title' />}
                                        subheader={<FormattedMessage id='claims.detail.add.claim.subheader' />} />
                            <CardContent>
                                {props.submission_products_loaded
                                    ? <React.Fragment>
                                        <div className={classes.buttonsHolder}>
                                            {sortedClaimableVariants.map((variant, idx) => {
                                                const variant_uuid = variant.get(new SubmissionProductVariant().getUniqueIdentifier());

                                                return <SubmissionProductVariantButton
                                                    key={idx} spvariant={variant}
                                                    disabledLike={props.permission !== 'RW'}
                                                    onClick={() => props.change('product', variant_uuid)}
                                                    selected={variant_uuid === props.formValues.product}
                                                />;
                                            })}
                                        </div>
                                        <RequestClaimingOtherProducts {...props} />
                                    </React.Fragment>
                                    : <LinearProgress />}
                                <SpaceDivider double />
                            </CardContent>
                        </React.Fragment>}
                    {['saving_item_claims-global', 'saved_item_claims-global', 'failed_save_item_claims-global'].includes(props.state)
                        ? <CardActionsLoader success={props.state === 'saved_item_claims-global'}
                                             failure={props.state === 'failed_save_item_claims-global'}
                                             postAnimation={() => props.setState(null)} />
                        : <ThemeProvider alt>
                            <CardActions>
                                {props.location.pathname.startsWith('/all-claims') && <Button
                                    disabled={props.state !== null}
                                    onClick={() => props.history.push('/all-claims')}>
                                    <CancelIcon />
                                    <FormattedMessage id='actions.cancel' />
                                </Button>}
                                {(props.permission === 'RW' && (!edit || props.location.pathname.startsWith('/all-claims'))) && <Button
                                    variant='contained' color='primary' type='submit' disabled={props.state !== null || !props.formValues || !props.formValues.product}
                                    onClick={() => props.handleSubmit()}>
                                    <SaveIcon />
                                    {edit
                                        ? <FormattedMessage id='actions.save' />
                                        : <FormattedMessage id='claims.detail.add.submit' />
                                    }
                                </Button>}
                            </CardActions>
                        </ThemeProvider>}
                </Card>
            </Col>
            <Col width='50%' className={classes.col}>
                {props.location.pathname.startsWith('/all-claims') ? <Card>
                    <CardHeader title={<FormattedMessage id='claims.detail.company.title' />}
                                subheader={<FormattedMessage id='claims.detail.company.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='company' />
                                </ActionButton>} />
                    <CardContent>
                        <Form>
                            <FieldWithIconHolder>
                                <Field fieldType='NoReduxSelect' disabled={true} withIcon hideDisabledArrow
                                       label={<FormattedMessage id='claims.detail.company.form.fields.company' />}
                                       value={props.match.params.identifier}>
                                    <MenuItem value={props.match.params.identifier}>{props.selectedCompany.get('name')}</MenuItem>
                                </Field>
                                <FieldIcon disabled={props.state !== null}
                                           onClick={() => props.state !== null ? {} : props.history.push(`/companies/${props.match.params.identifier}`)}>
                                    <LinkToIcon />
                                </FieldIcon>
                            </FieldWithIconHolder>
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.vat' />}
                                   value={props.selectedCompany.get('vat') || ''} />
                            <SpaceDivider none />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.email' />}
                                   value={props.selectedCompany.get('email') || ''} />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.phone' />}
                                   value={props.selectedCompany.get('phone') || ''} />
                            <SpaceDivider none />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.website' />}
                                   value={props.selectedCompany.get('website') || ''} />
                        </Form>
                        <SpaceDivider />
                        <SpaceDivider double />
                        <Typography variant='body1'><FormattedMessage id='claims.detail.company.delivery.title' />
                        </Typography>
                        <SpaceDivider />
                        <Form>
                            <CountryField fieldType='NoReduxSelect' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.country' />}
                                          value={props.selectedCompany.get(props.selectedCompany.get('delivery_street') ? 'delivery_country' : 'country') || ''} />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.city' />}
                                   value={props.selectedCompany.get(props.selectedCompany.get('delivery_street') ? 'delivery_city' : 'city') || ''} />
                            <SpaceDivider none />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.street' />}
                                   value={props.selectedCompany.get(props.selectedCompany.get('delivery_street') ? 'delivery_street' : 'street') || ''} />
                            <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='claims.detail.company.form.fields.postal_code' />}
                                   value={props.selectedCompany.get(props.selectedCompany.get('delivery_street') ? 'delivery_postal_code' : 'postal_code') || ''} />
                        </Form>
                    </CardContent>
                    <SpaceDivider double />
                </Card> : props.item?.get('status') && <ClaimedProductStatusCard claimproduct={props.item} />}
            </Col>
        </Row>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    return errors;
};

const DetailForm = reduxForm({
    form: 'claimForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new Claim().popUpFields(props.formValues, props.location.pathname.startsWith('/all-claims'));

        return dispatch(saveItem(Claim, 'claims-global', props.item ? props.item.getIn(['links', 'self']) : props.selectedCompany.getIn(['links', 'free-phone-order']), rest_of_data, props.item, {save_method: 'put', update_method: 'put'}));
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', 'companies']);
    const company = companies.find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompany = props.location.pathname.startsWith('/all-claims')
        ? companies.find(el => el.get(new Company().getUniqueIdentifier()) === props.match.params.identifier)
        : company;
    const item = !selectedCompany ? undefined : state.shared.getIn(['items', 'claims-global']).find(el => el.getIn(['links', 'self']) === selectedCompany.getIn(['links', 'free-phone-order']));

    return {
        state: state.app.get('state'),
        item: item,
        submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
        submission_products_items: state.shared.getIn(['items', 'submission-products']),
        selectedCompany: selectedCompany,
        company: company,
        user: auth_user,
        initialValues: props.match.params.identifier || item
            ? item ? item.toJS() : undefined
            : {},
        permissions: state.auth.get('permissions'),
        formValues: getFormValues('claimForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    markFiltered
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
