import React from 'react';
import MUIExpansionPanelDetails from '@material-ui/core/AccordionDetails';


/**
 * Basically MUI ExpansionPanelDetails
 */
export default function ExpansionPanelDetails(props) {
    return <MUIExpansionPanelDetails {...props} />;
}
