import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        '&.loading': {
            // TableCells
            '& > td': {
                // hide
                opacity: '0.5'
            }
        },
        '&.link': {
            // link cursor
            cursor: 'pointer'
        },
        '&.drag': {
            // hidden visibility causes rendering error, opacity: 0 is good enough
            visibility: 'visible !important'
        },
        '&.active': {
            backgroundColor: theme.palette.snomGrey[100]
        }
    }
}));

/**
 * Basically MUI TableRow with optional styles
 *  'link' - correct cursor for clickable row
 *  'drag' - DragTableRow
 */
export default function TableRow(props) {
    // Split some stuff from props
    let {_classes, className, link, drag, active, loading, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let {root, ...rest_of_classes} = _classes || {};
    let rootClasses = [
        classes.root, root, className,
        loading ? 'loading' : '',
        link ? 'link' : '',
        drag ? 'drag' : '',
        active ? 'active' : ''
    ].filter(Boolean).join(' ');

    return <MUITableRow
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}
