import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import CircularProgress from 'components/core/ui/mui/CircularProgress';
import MUIButton from '@material-ui/core/Button';


const useStyles = makeStyles(theme => ({
    button: {
        // loading visual
        '&.loading': {
            // get rid of disabled style
            opacity: '1'
        }
    },
    buttonLabel: {
        // icon inside
        '& > svg, & > .icon': {
            fontSize: theme.typography.pxToRem(19), // lower size
            marginRight: `${theme.spacing(1)}px`, // space between
            '&:last-child': {
                marginRight: '0',
                marginLeft: `${theme.spacing(1)}px` // space between
            },
            // icon is alone
            '&:first-child:last-child': {
                marginLeft: '0',
                marginRight: '0'
            }
        },
        // loading visual
        '&.loading': {
            // hide icon and text
            '& > svg, & > .icon, & > span': {
                opacity: '0'
            }
        }
    },
    // .loader
    progressHolder: {
        position: 'absolute',
        top: `${theme.spacing(0.5)}px`,
        left: '0',
        right: '0',
        margin: '0 auto',
        fontSize: '0',
        // variant of button
        '&.contained': {
            '&.primary, &.secondary': {
                // success / failure icons
                '& .success, & .failure': {
                    // correct color (white) to make them visible
                    color: 'inherit'
                }
            }
        }
    },
    // progress itself
    progress: {
        color: 'inherit'
    }
}));

/**
 * Extends Button to simply add space between Icon and text
 * - core is styled in theme.overrides.MuiButton
 */
const Button = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, variant, color, children, loading, success, failure, postAnimation, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, label, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.button, root, className,
        loading ? 'loading' : ''
    ].filter(Boolean).join(' ');
    const labelClasses = [
        classes.buttonLabel, label,
        loading ? 'loading' : ''
    ].filter(Boolean).join(' ');
    const progressHolderClasses = [
        classes.progressHolder,
        variant, color,
        'loader' // used in ActionButton
    ].filter(Boolean).join(' ');
    const progressClasses = [
        classes.progress
    ].filter(Boolean).join(' ');

    return <MUIButton
        ref={ref}
        classes={{
            root: rootClasses,
            label: labelClasses,
            ...rest_of_classes
        }}
        variant={variant}
        color={color}
        {...rest_of_props}>
        {children}
        {loading && <CircularProgress
            size={32} _classes={{root: progressHolderClasses, progress: progressClasses}}
            success={success} failure={failure} postAnimation={postAnimation} />}
    </MUIButton>;
});
export default Button;
