import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Simple select field for Company Type
 */
export default function CompanyTypeField(props) {
    // Split some stuff from props
    const {name, label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' name={name || 'company_type'}
                  label={label || `${intl.formatMessage({id: 'companytypefield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        <MenuItem value='reseller'><FormattedMessage id='companytypefield.choice.reseller' /></MenuItem>
        <MenuItem value='end_user'><FormattedMessage id='companytypefield.choice.end_user' /></MenuItem>
    </Field>;
}
