import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDebounce} from 'use-debounce';
import {makeStyles} from '@material-ui/core/styles';
import {formatLastUpdate} from 'lib/util';
import {theme} from 'theme';
// components
import DMActionButton from 'components/modules/tickets/dm/DMActionButton';
// material-ui
import {alpha} from '@material-ui/core/styles/colorManipulator';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import Button from 'components/core/ui/mui/Button';
// icons
import UpdateIcon from '@material-ui/icons/SyncOutlined';
import DownloadIcon from '@material-ui/icons/SaveAltOutlined';



const useStyles = makeStyles(theme => ({
    dmTableRow: {
        '& td': {
            transition: theme.transitions.create('background',
                {duration: theme.transitions.duration.short}),
            background: 'transparent'
        },
        '&.highlight': {
            '& td': {
                background: alpha(theme.palette.primary.light, 0.1)
            }
        }
    }
}));

/**
 * Render 1 TableRow with dm data
 */
export default function DMTableRow(props) {
    const {
        item, Model, placement, permission,
        identifier, identities = false, Icon, action, valueProcess
    } = props;
    const intl = useIntl();
    const classes = useStyles();
    // local state
    const lastUpdate = item.getIn(['data', `${identifier}${identities ? '.1' : ''}`, 'updated_at']);
    const [debouncedLastUpdate] = useDebounce(lastUpdate, theme.transitions.duration.standard * 2);

    return <TableRow hover className={`${classes.dmTableRow}${lastUpdate !== debouncedLastUpdate ? ' highlight' : ''}`}>
        <TableCell name>
            <Icon className='icon' />
            <span className='name'>{intl.formatMessage({id: `devicemanagement.type.${identifier.toLowerCase()}`})}</span>
        </TableCell>
        <TableCell>
            {item.getIn(['data', identifier, 'file_url'])
                ? <Button
                    component='a' target='_blank' rel='noreferrer'
                    href={item.getIn(['data', identifier, 'file_url'])}
                    style={{marginLeft: `-${theme.spacing(2)}px`}}>
                    <DownloadIcon />
                    <FormattedMessage id='actions.download' />
                </Button>
                : (valueProcess
                    ? valueProcess(identifier, item, item.getIn(['data', identifier, 'value']))
                    : item.getIn(['data', identifier, 'value']))
                || '-'}
        </TableCell>
        <TableCell>
            {formatLastUpdate(lastUpdate) || '-'}
        </TableCell>
        {permission === 'RW' && <TableCell className='text-right'>
            { action && <DMActionButton
                   action={action} Model={Model} placement={placement} item={item}
                    icon={<UpdateIcon />} label={<FormattedMessage id='tickettypefield.choices.update' />}
                />}
        </TableCell>}
    </TableRow>;
}
