import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
// material-ui
import Button from 'components/core/ui/mui/Button';


const useStyles = makeStyles(theme => ({
    // button with Product image and name to select / change Product
    button: {
        // tweak proportion
        padding: `${theme.spacing(2)}px`,
        // tweak style
        borderRadius: theme.shape.borderRadius,
        // styles
        '&:hover': {
            '& .img-holder': {
                boxShadow: theme.shadows[2]
            }
        },
        '&.selected': {
            background: theme.palette.grey[200],
            '& .img-holder': {
                borderColor: theme.palette.success[500]
            }
        },
        '&.Mui-disabled': {
            opacity: '1'
        },
        // hard disable status, you can use 'disabledLike' prop for soft one
        '&.disabled': {
            '&, &:hover': {
                '& .img-holder': {
                    opacity: theme.palette.action.disabledOpacity,
                    boxShadow: 'none'
                }
            },
            '& .title': {
                color: theme.palette.action.disabled
            }
        }
    },
    buttonLabel: {
        display: 'block', // turn span into div
        width: `${theme.spacing(20)}px`, // fixed size
        '& .img-holder': {
            // fixed size
            height: `${theme.spacing(20)}px`,
            padding: `${theme.spacing(2)}px`,
            // space between image and title
            marginBottom: `${theme.spacing(1)}px`,
            // style
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white,
            overflow: 'hidden',
            // smooth
            transition: theme.transitions.create(['box-shadow', 'border'],
                {duration: theme.transitions.duration.short}),
            // v-align image
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // image itself
            '& img': {
                display: 'block',
                maxHeight: '100%'
            }
        },
        '& .title': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: `${theme.spacing(6)}px`, // 2 rows
            color: theme.palette.text.primary
        },
        '& .loader': {
            // manually v-align
            top: `${theme.spacing(3)}px`
        }
    }
}));

/**
 * Renders SubmissionProductVariant to either open dialog or select it
 */
export default function SubmissionProductVariantButton(props) {
    // split some stuff from props
    const {_classes, className, spvariant, loading, selected, disabled, disabledLike, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {buttonRoot, buttonLabel} = _classes || {};
    const rootClasses = [
        classes.button, buttonRoot, className,
        'spv-button',
        disabled ? 'disabled' : '',
        selected ? 'selected' : ''
    ].filter(Boolean).join(' ');
    const labelClasses = [
        classes.buttonLabel, buttonLabel
    ].filter(Boolean).join(' ');

    return <Button
        _classes={{root: rootClasses, label: labelClasses}}
        disabled={disabled || disabledLike}
        loading={loading}
        {...rest_of_props}>
        {(!loading && spvariant)
            ? <React.Fragment>
                <span className='img-holder'>
                    <img src={spvariant.get('image_url') ? spvariant.get('image_url') : require('img/portal/product_preview.png')}
                         alt={spvariant.get('name')} />
                </span>
                <span className='title'>{spvariant.get('name')}</span>
            </React.Fragment>
            : <span className='title'><FormattedMessage id='submissionproductvariantbutton.howto' /></span>
        }
    </Button>;
}
