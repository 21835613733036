import React, {useId, useMemo} from 'react';
import ReactApexChart from 'react-apexcharts';
// components
import Line from 'components/core/ui/graphs/Line';
import Pie from 'components/core/ui/graphs/Pie';
import List from 'components/core/ui/graphs/List';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';


/**
 * Graph component to render all kinds of Statistics
 */
export default function Graph(props) {
    const {type = 'line', loading, ...rest_of_props} = props;
    // we need unique id for graph in order to control it via ApexCharts manager
    const id = useId();

    // get proper graph component to render
    const GraphComponent = useMemo(() => {
        switch (type) {
            case 'line':
            case 'area':
                return Line;
            case 'pie':
                return Pie;
            case 'list':
            case 'inline-list':
                return List;
            default:
                return ReactApexChart;
        }
    }, [type]);

    return loading || !props.data ? <React.Fragment>
        <SpaceDivider /><LinearProgress /><SpaceDivider />
    </React.Fragment>
    : <GraphComponent id={id} type={type} {...rest_of_props} />;
}
