import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {SalesCluster} from 'lib/models';
import {countries} from 'lib/countrieslist';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import Field, {CountryField, SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import InActiveIcon from '@material-ui/icons/CloseOutlined';


/**
 * Renders Sales Clusters from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new SalesCluster().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='salesclusters.list.table.name' />},
        {sortName: 'countries', label: <FormattedMessage id='salesclusters.list.table.countries' />},
        {sortName: 'submission_enabled', label: <FormattedMessage id='salesclusters.list.table.submission_enabled' />, numeric: true}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName), 'name', 'desc', {
            countries: (sales_cluster) => sales_cluster.get('countries').size
        });
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['search', 'countries', 'submission_enabled'], ['name', 'countries'], {
            countries: (sales_cluster, value) => sales_cluster.get('countries').includes(value),
            search_countries: (sales_cluster, searchValue, searchOptimize) => sales_cluster.get('countries') && countries.filter(country => searchOptimize(country.get('name')).includes(searchValue)).map(country => country.get('id')).some(country_id => sales_cluster.get('countries').includes(country_id))
        });

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!props.loaded) {
            dispatch(fetchItems(SalesCluster, items_placement, 'sales-clusters'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='salesclusters.list.title' />}
            subheader={<FormattedMessage id='salesclusters.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/sales-clusters/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='sales_clusters' />
                </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='salesclusters.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <CountryField fieldType='NoReduxSelect' name='countries' label={<FormattedMessage id='salesclusters.list.filter.countries' />}
                          value={filters.countries || ''}
                          onChange={event => filterItems('countries', event.target.value)} />
            <Field label={<FormattedMessage id='salesclusters.list.filter.submission_enabled' />}
                   fieldType='NoReduxSelect' value={filters.submission_enabled || ''}
                   onChange={event => filterItems('submission_enabled', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'>
                    <ListItemIcon className='icon'><ActiveIcon color='primary' /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='salesclusters.list.filter.submission_enabled.choice.enabled' /></ListItemText>
                </MenuItem>
                <MenuItem value='false'>
                    <ListItemIcon className='icon'><InActiveIcon /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='salesclusters.list.filter.submission_enabled.choice.disabled' /></ListItemText>
                </MenuItem>
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                        </TableRow>
                    </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'salesclusters.list.table.empty'}
                                                      values={{link: passed_props.permission === 'RW'
                                                              ? <Link to='/sales-clusters/add' className='hover-border'>
                                                                  <FormattedMessage id='salesclusters.list.table.empty.link' />
                                                              </Link>
                                                              : <FormattedMessage id='salesclusters.list.table.empty.link' />}} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && filteredItems.map(sales_cluster =>
                                <TableRow key={sales_cluster.get('uuid')} link hover
                                          onClick={() => props.state === null ? history.push(`/sales-clusters/${sales_cluster.get(new SalesCluster().getUniqueIdentifier())}`) : {}}>
                                    <TableCell>{sales_cluster.get('name')}</TableCell>
                                    <TableCell>
                                        {countries
                                            .filter(el => sales_cluster.get('countries').includes(el.get('id')))
                                            .map((el) => el.get('name')).join(', ')}
                                    </TableCell>
                                    <TableCell numeric>{sales_cluster.get('submission_enabled') ? <ActiveIcon color='primary' /> : <InActiveIcon />}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>}
            </Table>} />
    </Card>;
}
