import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import InvisibleEnterSubmit from 'components/core/ui/InvisibleEnterSubmit';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        minWidth: '0',
        margin: `0 -${theme.spacing(1)}px -${theme.spacing(1)}px`,
        '&.center': {
            justifyContent: 'center'
        },
        '&.cardactions': {
            margin: '0 0 -10px 0' // match size of button in <CardActions
        },
        // responsive
        [theme.breakpoints.down((320 * 2) + theme.spacing(12) + 1)]: {
            justifyContent: 'center'
        }
    }
}));

/**
 * When you have fields, you also need form.
 *
 * Usage like <Form onSubmit={this.props.handleSubmit}>...</Form>
 *
 * Form handles disabled status and submit with Enter
 */
export default function Form(passed_props) {
    // redux store
    const props = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);
    // Split some stuff from props
    let {center, onSubmit, children, className, inCardActions, formLike, ...rest_of_props} = passed_props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let rootClasses = [
        classes.root, className,
        center ? 'center' : '',
        inCardActions ? 'cardactions' : ''
    ].filter(Boolean).join(' ');

    if (formLike) {
        return <div className={rootClasses}
                    {...rest_of_props}>
            {children}
        </div>;
    }

    return <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.state === null && onSubmit) {
            onSubmit();
        }
    }}
                 className={rootClasses}
                 {...rest_of_props}>
        {children}
        {onSubmit && <InvisibleEnterSubmit />}
    </form>;
};
