import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import {DeadLetter} from 'lib/models';
// Actions
import {deleteItem, fetchItem, removeFromCollection, simplePost} from 'actions/shared';
// Components
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {JSONField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import RetryIcon from '@material-ui/icons/ReplayOutlined';


/**
 * Renders detail of Dead Letter - View, delete, delete with retry
 */
function Detail(props) {
    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (!props.item) {
            props.fetchItem(DeadLetter, props.items_placement, ['dead-letters', props.match.params.identifier]);
        }
    }, [props.match.params.identifier]);

    return <div>
        {!props.item && props.state !== `fetching_item_${props.items_placement}`
            ? <ErrorCard
                title={<FormattedMessage id='deadletters.detail.notfound.title' />}
                text={<FormattedMessage id='deadletters.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />}
            />
            : <Card>
                <CardHeader title={<FormattedMessage id='deadletters.detail.title' />}
                            subheader={<FormattedMessage id='deadletters.detail.subheader' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='dead_letter' />
                            </ActionButton>} />
                {[`fetching_item_${props.items_placement}`, `deleting_item_${props.items_placement}`, 'posting_retry_dead_letter'].includes(props.state)
                    ? <CardContent>
                        <LinearProgress />
                    </CardContent>
                    : props.formValues && <React.Fragment>
                    <CardContent>
                        <Row wrap>
                            <Col width='50%'>
                                <Form onSubmit={props.handleSubmit}>
                                    <Field name='actor_name' fieldType='TextField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.actor_name' />} />
                                    <Field name='queue_name' fieldType='TextField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.queue_name' />} />
                                    <SpaceDivider />
                                    <Field name='message_id' fieldType='TextField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.message_id' />} />
                                    <Field name='id' fieldType='TextField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.id' />} />
                                    <SpaceDivider />
                                    <Field name='created_at' fieldType='DateField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.created_at' />} />
                                    <Field name='ttl' fieldType='DateField' disabled label={<FormattedMessage id='deadletters.detail.form.fields.ttl' />} />
                                </Form>
                            </Col>
                            <Col width='50%'>
                                <Form onSubmit={props.handleSubmit}>
                                    <JSONField name='args' disabled label={<FormattedMessage id='deadletters.detail.form.fields.args' />}
                                               selectedValue={JSON.stringify(props.formValues.args)} />
                                </Form>
                            </Col>
                        </Row>
                        <SpaceDivider double />
                        <Form onSubmit={props.handleSubmit}>
                            <JSONField name='options' disabled size='full' label={<FormattedMessage id='deadletters.detail.form.fields.options' />}
                                       selectedValue={JSON.stringify(props.formValues.options)} />
                        </Form>
                    </CardContent>
                    <ThemeProvider alt>
                        <CardActions>
                            <Button disabled={props.state !== null}
                                    onClick={() => props.history.push('/dead-letter-queue')}>
                                <CancelIcon />
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            <Button variant='contained' color='secondary' disabled={props.state !== null}
                                    onClick={() => {
                                        props.deleteItem(DeadLetter, props.items_placement, props.item.getIn(['links', 'self']), props.item).then(() => {
                                            // mark paginated lists for reload
                                            props.markFiltered(DeadLetter, props.items_placement);
                                            props.history.push('/dead-letter-queue');
                                        });
                                    }}>
                                <DeleteIcon />
                                <FormattedMessage id='actions.delete' />
                            </Button>
                            <Button variant='contained' color='primary' disabled={props.state !== null}
                                    onClick={() => {
                                        props.simplePost('retry_dead_letter', props.item.getIn(['links', 'self']), {retry: true}, {post_method: 'delete', success_state: null, failure_state: null}).then(() => {
                                            props.removeFromCollection(DeadLetter, props.items_placement, props.item);
                                            // mark paginated lists for reload
                                            props.markFiltered(DeadLetter, props.items_placement);
                                            props.history.push('/dead-letter-queue');
                                        });
                                    }}>
                                <RetryIcon />
                                <FormattedMessage id='actions.retry' />
                            </Button>
                        </CardActions>
                    </ThemeProvider>
                </React.Fragment>}
            </Card>
        }
    </div>;
}

const DetailForm = reduxForm({
    form: 'deadLetterForm',
    enableReinitialize: true,
    onSubmit: () => {}
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items_placement = new DeadLetter().getPlacement();
    const item = state.shared.getIn(['items', items_placement]).find(el => el.get(new DeadLetter().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        items_placement: items_placement,
        item: item,
        products_loaded: state.shared.getIn(['loaded', 'products']),
        products_items: state.shared.getIn(['items', 'products']),
        initialValues: props.match.params.identifier
            ? item
                ? item.toJS()
                : undefined
            : {},
        formValues: getFormValues('deadLetterForm')(state)
    };
}, (dispatch) => bindActionCreators({
    fetchItem,
    deleteItem,
    removeFromCollection,
    simplePost
}, dispatch))(DetailForm);

export default withRouter(ConnectedDetail);
