import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {SubmissionProduct, Product} from 'lib/models';
import {useLocalFilter, useLocalSort} from 'lib/filters';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import Field, {SearchField} from 'components/core/ui/Field';
import {Link, useHistory} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import ClaimableIcon from '@material-ui/icons/CheckOutlined';
import NotClaimableIcon from '@material-ui/icons/CloseOutlined';
import ClaimableOnRequestIcon from '@material-ui/icons/CreateOutlined';


const useStyles = makeStyles(theme => ({
    // holder of rebate information in variants
    rebateHolder: {
        // Registered: X €
        '& span': {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            marginRight: `${theme.spacing(1)}px`,
            '&:last-child': {
                marginRight: '0'
            }
        },
        // value €
        '& strong': {
            // fixed width for better readability
            width: '35px', // up to 3 digits
            display: 'inline-block',
            textAlign: 'right',
            whiteSpace: 'nowrap'
        }
    },
    // holder of claimable icons
    claimableHolder: {
        // ClaimableOnRequestIcon '+' with ClaimableIcon
        '& > svg:nth-child(2)': {
            // reduce size
            fontSize: '12px',
            // remove space taken with margin and move into checkmark
            marginLeft: '-6px',
            marginRight: '-6px'
        }
    }
}));

/**
 * Renders Submission Products from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new SubmissionProduct().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement]),
        products_loaded: state.shared.getIn(['loaded', 'products']),
        products_items: state.shared.getIn(['items', 'products'])
    }), shallowEqual);
    // router & style
    const history = useHistory();
    const classes = useStyles();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='submissionproducts.list.table.name' />},
        {sortName: 'code', label: <FormattedMessage id='submissionproducts.list.table.code' />},
        {label: <FormattedMessage id='submissionproducts.list.table.rebates' />},
        {sortName: 'claim', label: <FormattedMessage id='submissionproducts.list.table.claim' />, numeric: true}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new SubmissionProduct().getPlacement(), tableHead.map(item => item.sortName), 'name', 'desc',
        {claim: (obj) => obj.get('variants').filter(variant => variant.get('claimable') === 'claimable' || variant.get('claimable') === 'on_request').size});
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new SubmissionProduct().getPlacement(), ['search', 'claim'], ['name', 'code'],
        {claim: (obj, value) => (!!obj.get('variants').filter(variant => variant.get('claimable') === value).size)});

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(SubmissionProduct, items_placement, 'submission-products'));
        }
    }, [props.loaded]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.products_loaded === false) {
            dispatch(fetchItems(Product, 'products', 'products', null, null, {affect_state: false}));
        }
    }, [props.products_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='submissionproducts.list.title' />}
            subheader={<FormattedMessage id='submissionproducts.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/submission-products/add')}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='submission_products' />
            </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='submissionproducts.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='submissionproducts.list.filter.claimable' />}
                   fieldType='NoReduxSelect' value={filters.claim || ''}
                   onChange={(event) => filterItems('claim', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {[
                    ['claimable', <ClaimableIcon color='primary' />],
                    ['on_request', <ClaimableOnRequestIcon color='primary' />],
                    ['not_claimable', <NotClaimableIcon />]
                ].map(([value, icon]) => <MenuItem key={value} value={value}>
                    <ListItemIcon className='icon'>{icon}</ListItemIcon>
                    <ListItemText className='text'>
                        <FormattedMessage id={`submissionproducts.list.filter.claimable.choices.${value}`} />
                    </ListItemText>
                </MenuItem>)}
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'submissionproducts.list.table.empty'} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((sproduct, idx) => {
                        // try to find product based on code
                        const product = props.products_items.find(product => product.get('code') === sproduct.get('code'));
                        const claimable = !!sproduct.get('variants').filter(variant => variant.get('claimable') === 'claimable').size;
                        const claimableOnRequest = !!sproduct.get('variants').filter(variant => variant.get('claimable') === 'on_request').size;

                        return <TableRow key={idx} link hover
                                         onClick={() => props.state === null ? history.push(`/submission-products/${sproduct.get(new SubmissionProduct().getUniqueIdentifier())}`) : {}}>
                            <TableCell>{sproduct.get('name')}</TableCell>
                            <TableCell>{product
                                ? <Link className='hover-border' to={`/products/${product.get(new Product().getUniqueIdentifier())}`}>
                                    {sproduct.get('code')}
                                </Link>
                                : sproduct.get('code')}
                            </TableCell>
                            <TableCell>{sproduct.get('variants').map((variant, idx) => <div key={idx} className={classes.rebateHolder}>
                                <FormattedMessage id='submissionproducts.list.table.rebate.registered' values={{
                                    value: <strong>{`${variant.getIn(['rebates', 'default', 'registered'])} €`}</strong>
                                }} />
                                <FormattedMessage id='submissionproducts.list.table.rebate.silver' values={{
                                    value: <strong>{`${variant.getIn(['rebates', 'default', 'silver'])} €`}</strong>
                                }} />
                                <FormattedMessage id='submissionproducts.list.table.rebate.gold' values={{
                                    value: <strong>{`${variant.getIn(['rebates', 'default', 'gold'])} €`}</strong>
                                }} />
                                {variant.get('rebates').size > 1 && <span className='text-primary'>
                                    {'*'}
                                </span>}
                            </div>)}</TableCell>
                            <TableCell numeric>
                                {claimable || claimableOnRequest ? <div className={classes.claimableHolder}>
                                    {claimable && <ClaimableIcon color='primary' />}
                                    {claimableOnRequest && <ClaimableOnRequestIcon color='primary' />}
                                </div>
                                     : <NotClaimableIcon />}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>} />
    </Card>;
}
