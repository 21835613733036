import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import {makeStyles} from '@material-ui/core/styles';
import validator from 'lib/valitator';
import {TicketScheduler, ProvisioningProfile, Endpoint} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {TicketTypeField, DMUpgradeVersionField} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import CronInput from 'components/core/ui/fields/CronInput';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    card: {
        overflow: 'auto'
    }
}));

/**
 * Renders detail of Ticket Scheduler - ADD & EDIT
 */
function Detail(props) {
    const classes = useStyles();

    return !props.formValues ? null : <Card className={classes.card}>
        <CardHeader subheader={<FormattedMessage id='ticketschedulers.detail.subheader' />}
                    title={!!props.item
                        ? <FormattedMessage id='ticketschedulers.detail.edit.title' />
                        : <FormattedMessage id='ticketschedulers.detail.add.title' />}
                    action={<ActionButton iconButton disabled>
                        <ModelIcon model='ticket_scheduler' />
                    </ActionButton>} />
        <CardContent>
            <Form onSubmit={props.handleSubmit}>
                <Field name='name' fieldType='TextField' label={<FormattedMessage id='ticketschedulers.detail.form.fields.name' />}
                       helperText={<FormattedMessage id='ticketschedulers.detail.form.fields.name.help' />}/>
                <Field name='status' fieldType='Checkbox' label={<FormattedMessage id='ticketschedulers.detail.form.fields.status' />} />
                <SpaceDivider />
            </Form>
            <hr />
            <Typography variant='body1'><FormattedMessage id='ticketschedulers.detail.section.task' /></Typography>
            <SpaceDivider />
            <Form onSubmit={props.handleSubmit}>
                <TicketTypeField name='type' required />
                {props.formValues.type === 'upgrade' && <DMUpgradeVersionField change={props.change} />}
            </Form>
            <hr />
            <SpaceDivider />
            <CronInput
                name='schedule' change={props.change} touch={props.touch} formName={props.form}
                label={`${props.intl.formatMessage({id: 'ticketschedulers.detail.form.fields.schedule'})}*`}
                helperText={<FormattedMessage
                    id='ticketschedulers.detail.form.fields.schedule.help'
                    values={{
                        link: <a href='https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html'
                                 className='hover-border' target='_blank' rel='noreferrer'>
                            <FormattedMessage id='ticketschedulers.detail.form.fields.schedule.help.link' />
                        </a>}}
                />}
            />
        </CardContent>
        {[`saving_item_${props.placement}`, `saved_item_${props.placement}`, `failed_save_item_${props.placement}`].includes(props.state)
            ? <CardActionsLoader success={props.state === `saved_item_${props.placement}`}
                                 failure={props.state === `failed_save_item_${props.placement}`}
                                 postAnimation={(success) => {
                                     props.setState(null);
                                     if (success) {
                                         props.close();
                                     }
                                 }} />
            : <ThemeProvider alt>
                <CardActions>
                    <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                            onClick={() => props.handleSubmit()}>
                        <SaveIcon />
                        <FormattedMessage id='actions.save' />
                    </Button>
                </CardActions>
            </ThemeProvider>}
    </Card>;
}


const validate = (data, props) => {
    const errors = {params: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'type', data.type);
    validator.isCron(null, errors, 'schedule', data.schedule);
    // upgrade validation
    if (data.type === 'upgrade') {
        if (data.params === undefined) { data.params = {}; }
        if (data.params.version) {
            validator.isEmpty(null, errors, 'params.url', data.params.url);
        } else if (data.params.url) {
            validator.isEmpty(null, errors, 'params.version', data.params.version);
        } else {
            validator.isNotNull(null, errors, 'params.version', data.params.version);
            validator.isNotNull(null, errors, 'params.url', data.params.url);
        }
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'ticketSchedulerForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new TicketScheduler().popUpFields(props.formValues);
        rest_of_data['status'] = rest_of_data['status'] ? 'active' : 'inactive';

        return dispatch(saveItem(TicketScheduler, props.placement,
            props.item ? props.item.getIn(['links', 'self']) : props.related_item.getIn(['links', props.ticket_group ? 'ticket_group-schedulers' : 'ticket-scheduler']),
            rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false}));
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    return {
        state: state.app.get('state'),
        ticket_group: props.ticket_group || false,
        placement: `${new TicketScheduler().getPlacement()}-${props.ticket_group
            ? props.related_item.get(new ProvisioningProfile().getUniqueIdentifier())
            : props.related_item.get(new Endpoint().getUniqueIdentifier())}`,
        item: props.item,
        related_item: props.related_item,
        initialValues: props.item
            ? props.item.toJS()
            // every hour
            : {schedule: '0 * * * ? *', status: true},
        formValues: getFormValues('ticketSchedulerForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(ConnectedDetail);
