import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
// components
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import {makeStyles} from '@material-ui/core/styles';
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import SubmitIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';


const useStyles = makeStyles(theme => ({
    // holder of everything
    dialog: {
        '& .MuiPaper-root' : {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            textAlign: 'center',
            padding: `${theme.spacing(2)}px 0`
        }
    },
    // holder of icon
    stateIcon: {
        color: theme.palette.primary[500],
        // icon itself
        '& svg': {
            fontSize: `${theme.spacing(7)}px`,
            display: 'block'
        }
    }
}));

/**
 * DMStateDialog to make sure User knows about data deletion
 */
export default function DMStateDialog(props) {
    const {state, initialState, changeState} = props;
    // styles
    const classes = useStyles();
    // local state
    const [open, setOpen] = useState(false); // flag for dialog open/close state

    useEffect(() => {
        // open dialog only when unchecking
        !state && state !== initialState && setOpen(true);
    }, [state]);

    return <Dialog open={open} className={classes.dialog}>
        <div className={classes.stateIcon}>
            <InfoIcon />
        </div>
        <DialogTitle>
            <FormattedMessage id='dmstatedialog.title' />
        </DialogTitle>
        <DialogContent>
            <FormattedMessage id='dmstatedialog.description.1' /><br />
            <FormattedMessage id='dmstatedialog.description.2' />
            <SpaceDivider />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {setOpen(false); changeState(true);}}>
                <CancelIcon />
                <FormattedMessage id='actions.cancel' />
            </Button>
            <Button variant='contained' color='secondary' onClick={() => setOpen(false)}>
                <SubmitIcon />
                <FormattedMessage id='actions.confirm' />
            </Button>
        </DialogActions>
    </Dialog>;
}
