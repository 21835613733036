import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import storage from 'store';
import {AssetFolder} from 'lib/models';
import {theme} from 'theme';
// actions
import {fetchItem, setLocalState} from 'actions/shared';
// components
import Button from 'components/core/ui/mui/Button';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import {Col, Row} from 'components/core/ui/Grid';
// material ui
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
// icons
import GradientHomeIcon from 'components/core/vectors/GradientHomeIcon';
import FolderIcon from '@material-ui/icons/Folder';
import ListIcon from '@material-ui/icons/Reorder';
import GridIcon from '@material-ui/icons/ViewModule';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';


const useToggleStyles = makeStyles((theme) => ({
    viewToggle: {
        minWidth: `${theme.spacing(9.5)}px`,
        minHeight: '0',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        background: theme.palette.snomGrey[100],
        boxShadow: theme.innerShadow,
        borderRadius: `${theme.spacing(3.75)}px`,
        '& .MuiTab-root': {
            // show tab over indicator
            zIndex: theme.zIndex.mobileStepper,
            width: `${theme.spacing(4.5)}px`,
            height: `${theme.spacing(4.5)}px`,
            minWidth: '0',
            minHeight: '0',
            padding: '0',
            color: theme.palette.snomGrey[800],
            opacity: '1',
            borderRadius: `${theme.spacing(11.25)}px`,
            transition: theme.transitions.create(['color'],
                {duration: theme.transitions.duration.short}),
            '&.Mui-selected': {
                color: theme.palette.common.white
            }
        },
        '& .MuiTabs-indicator': {
            height: `${theme.spacing(4.5)}px`,
            backgroundColor: theme.palette.snomGrey[800],
            borderRadius: `${theme.spacing(10)}px`
        }
    }
}));

/**
 * Switch asset manager's view type
 */
export const AssetManagerViewToggle = (props) => {
    const {value, onChange} = props;
    const classes = useToggleStyles();

    return <Tabs className={classes.viewToggle} variant='scrollable' scrollButtons='off' value={value}
        onChange={() => onChange(value === 'grid' ? 'list' : 'grid')}>
        <Tab value='grid' icon={<GridIcon />} />
        <Tab value='list' icon={<ListIcon />} />
    </Tabs>;
};

const useStyles = makeStyles(theme => ({
    toolbar: {
        width: '100%',
        height: `${theme.spacing(5)}px`,
        display: 'flex',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(2)}px`,
        alignItems: 'stretch',
        minHeight: '0',
        backgroundColor: theme.palette.snomGrey[100],
        '& .MuiIconButton-sizeSmall.MuiIconButton-edgeStart': {
            height: `${theme.spacing(5)}px`,
            width: `${theme.spacing(5)}px`
        }
    },
    homeButton: {
        width: '100%',
        height: `${theme.spacing(5)}px`,
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
        borderRadius: '0',
        marginBottom: `${theme.spacing(1)}px`,

        '&.Mui-disabled': {
            opacity: '1'
        },
        '& .MuiListItemIcon-root': {
            minWidth: '0',
            marginRight: `${theme.spacing(2)}px`
        },
        '& .MuiButton-label': {
            textAlign: 'center',
            flex: 'none',
            fontWeight: '500',
            // gradient
            backgroundImage: theme.gradients.green.color,
            backgroundSize: theme.gradients.green.backgroundSize,
            backgroundPosition: theme.gradients.green.position,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        }
    },
    breadcrumbsRoot: {
        overflowX: 'auto',
        alignSelf: 'stretch',
        marginRight: 'auto'
    },
    breadcrumbsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        color: theme.palette.snomGrey[800],
        '&, & .MuiBreadcrumbs-li': {
            height: '100%'
        },
        '& .MuiBreadcrumbs-li' :{
            flexShrink: '0'
        }
    },
    breadcrumbsListItem: {
        height: '100%',
        color: theme.palette.snomGrey[800],
        padding: `${theme.spacing(1)}px`,
        borderRadius: '0',

        '& .MuiButton-startIcon': {
            color: theme.palette.snomGrey[400],
            marginRight: `${theme.spacing(1.5)}px`
        },
        '& .MuiTypography-body1': {
            fontWeight: theme.typography.button.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            whiteSpace: 'nowrap'
        },
        '&.Mui-disabled': {
            opacity: 1,
            color: theme.palette.primary.main,
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            }
        }
    },
    drawer: {
        '&.MuiDrawer-docked': {
            '& .MuiDrawer-paper': {
                position: 'relative'
            }
        },
        '& .MuiDrawer-paper': {
            width: '300px'
        }
    }
}));

/**
 * Header of asset manager page, contains breadcrunbs and action buttons
 * @param {object} props - props object
 * @param {string} props.permission - permission type
 * @param {string} props.folderPath - folder path
 * @param {string} props.rootFolder - root folder
 * @param {(path) => void} props.onClickBreadcrumbButton - breadcrumb button click handler
 * @param {() => void} props.onClickHome - click home handler
 * @param {'grid'|'list'} props.viewType - view type value
 * @param {boolean} props.disableToolbar - do not render Toolbar
 * @param {(value: string) => void} props.onChangeView - asset view changing handler
 * @param {JSX.Element} props.foldersMenu - folder menu render
 */
export default function AssetManagerLayout(props) {
    const {permission, rootFolder: rootFolderId, folderPath, onClickHome, foldersMenu, disableToolbar, children} = props;
    const history = useHistory();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [addButtonEl, setAddButtonEl] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);

    const root_path = useMemo(() => '/asset-manager', []);
    const asset_folders_placement = useMemo(() => new AssetFolder().getPlacement(), []);
    const asset_folders_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);
    const root_folder_placement = useMemo(() => rootFolderId && `${asset_folders_placement}-${rootFolderId}-item`, [rootFolderId, asset_folders_placement]);

    const {items, rootFolder, rootFolderLoaded, rootFolderLoading, state, viewType, rootLink} = useSelector((state) => {
        const rootFolder = rootFolderId && state.shared.getIn(['items', root_folder_placement, 0]);
        const rootFolderLoaded = rootFolderId && state.shared.getIn(['loaded', root_folder_placement, 0]);
    
        return ({
            state: state.app.get('state'),
            items: state.shared.get('items'),
            rootFolder,
            rootFolderLoaded,
            rootFolderLoading: rootFolderId && !rootFolderLoaded && !rootFolder,
            viewType: state.shared.getIn(['state', 'assets-view-type']),
            rootLink: state.api?.get('client')?.rootMap.get('folders')
        });
    }, shallowEqual);

    useEffect(() => {
        if (!rootFolderLoaded && root_folder_placement) {
            dispatch(fetchItem(AssetFolder, root_folder_placement, `${rootLink}${rootFolderId}`, {affect_state: false}));
        }
    }, [rootFolderLoaded, root_folder_placement]);

    useEffect(() => {
        setShowDrawer(false);
    }, [history.location.pathname]);

    // convert path to array for breadcrumbs
    const pathList = useMemo(() => {
        return (folderPath || match?.params.identifier)?.split('_')
            .reduce((accArrayPath, id) => {
                const lastPath = accArrayPath[accArrayPath.length - 1];
                const link = lastPath?.link ? `${lastPath.link}_${id}` : id;
                const folder = items.get(
                    lastPath?.id ? `${asset_folders_placement}-${lastPath.id}` : asset_folders_placement
                )?.find((childFolder) => childFolder.get(asset_folders_identifier) === id);

                return folder ? [...accArrayPath, {
                    link, id,
                    label: folder?.get('name')
                }] : accArrayPath;
            }, []).filter(path => path.link && path.id !== rootFolderId);
    }, [!state?.match(asset_folders_placement), match?.params.identifier, folderPath]);

    const onClickAddMenuItem = useCallback((path) => {
        history.push(path);
        setAddButtonEl(null);
    }, []);

    const onClickBreadcrumbButton = useCallback((path) => {
        if (props.onClickBreadcrumbButton) {
            props.onClickBreadcrumbButton(path);
        } else {
            history.push(`${root_path}/${path}`);
        }
    }, []);

    return <Row nospace stretch>
        {foldersMenu && <Drawer className={classes.drawer} variant={isMobile ? 'temporary' : 'permanent'} open={!isMobile || showDrawer} onClose={() => setShowDrawer(false)}>
            <Button size='large' className={classes.homeButton} disabled={rootFolderLoading}
                startIcon={!rootFolderLoading && <GradientHomeIcon />} onClick={() => onClickHome ? onClickHome() : history.push(root_path)}>
                {rootFolderLoading ? <React.Fragment><Box component='span' width='100%'><LinearProgress /></Box><br /></React.Fragment>
                    : (rootFolder ? rootFolder.get('name') : <FormattedMessage id='assets.list.header.home' />)}
            </Button>
            {foldersMenu}
        </Drawer>}
        <Col>
            {!disableToolbar && <Toolbar className={classes.toolbar}>
                {isMobile && <IconButton size='small' edge='start' onClick={() => setShowDrawer(true)}><MenuIcon /></IconButton>}
                <Breadcrumbs classes={{root: classes.breadcrumbsRoot, ol: classes.breadcrumbsList}} separator='>' maxItems={4} itemsBeforeCollapse={2}>
                    {pathList?.map(({id, link, label}, index) => {
                        const isFirst = index === 0;
                        const isLast = index === pathList.length - 1;

                        return <Button key={id} className={classes.breadcrumbsListItem} disabled={isLast}
                            startIcon={isFirst ? <FolderIcon /> : null} onClick={() => onClickBreadcrumbButton(link)}>
                            <Typography>{label}</Typography>
                        </Button>;
                    })}
                </Breadcrumbs>
                <AssetManagerViewToggle value={props.viewType || viewType} onChange={(newValue) => {
                    if (props.onChangeView) {
                        props.onChangeView(newValue);
                    } else {
                        // save choice to storage
                        storage.set(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-assets-view-type`, newValue);
                        dispatch(setLocalState(newValue, 'assets-view-type'));
                    }
                }} />
                {permission === 'RW' && <React.Fragment>
                    <Button onClick={(e) => match.path !== root_path && match.params.identifier ? setAddButtonEl(e.currentTarget) : history.push(`${root_path}/add`)} variant='contained' color='primary' startIcon={<AddIcon />}>
                        <FormattedMessage id='assets.list.header.add' />
                    </Button>
                    <Menu anchorEl={addButtonEl} open={!!addButtonEl} onClose={() => setAddButtonEl(null)}>
                        <MenuItem onClick={() => {
                            onClickAddMenuItem(`${root_path}/${match.params.identifier ? `${match.params.identifier}/add` : 'add'}`);
                        }}>
                            <FormattedMessage id='assets.list.header.add.folder' />
                        </MenuItem>
                        <MenuItem onClick={() => {
                            onClickAddMenuItem(`${root_path}/${match.params.identifier}/files/add`);
                        }}>
                            <FormattedMessage id='assets.list.header.add.files' />
                        </MenuItem>
                    </Menu>
                </React.Fragment>}
            </Toolbar>}
            {children}
        </Col>
    </Row>;
}
