import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormSubmitErrors, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {addMessage} from 'actions/app';
import {loginProcedure, setState as setAuthState} from 'actions/auth';
import {simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import ForgottenPasswordWave from 'components/core/vectors/waves/ForgottenPassword';
import ForgottenPasswordIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import Submit1Icon from '@material-ui/icons/SendOutlined';
import Submit2Icon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';


/**
 * ForgottenPassword process
 *
 * First 'null' state -> Email form
 * Then 'reset_password_request' state -> Success message, visit inbox
 *
 * First 'null' state with token -> New password form
 * Then -> 'logged' / success;not_approved; redirect to not_approved / invalid; redirect to forgotten-password
 */
function ForgottenPassword(props) {
    // local state
    const [showPasswords, setShowPasswords] = useState(false);

    /**
     * During initialization check for Token
     */
    useEffect(() => {
        // in case of invalid token
        if (props.token && props.submitErrors.token) {
            // remove token from URL (after postAnimation)
            setTimeout(() => {
                props.history.replace(`${props.portal ? '/partner' : ''}/forgotten-password`);
            }, 1500);
        }
    }, [props.token, props.submitErrors]);
    // unmount cleanup, make sure to reset Auth state
    useEffect(() => {
        return () => {
            // do not clear logged and other cases!
            if (![null, 'logged', 'posted_reset_password', 'not_approved'].includes(props.authState)) {
                props.setAuthState(null);
            }
        };
    }, []);

    return <Card>
        {['posted_reset_password_request', 'posted_reset_password_request_afterAnim'].includes(props.authState)
            ? <CardHeader
                title={<FormattedMessage id='forgottenpassword.success.title' />}
                subheader={<FormattedMessage id='forgottenpassword.success.subheader' />}
                action={<ActionButton iconButton disabled>
                    <SuccessIcon />
                </ActionButton>}
            />
            : <React.Fragment>
                <CardHeader
                    title={<FormattedMessage id='forgottenpassword.title' />}
                    subheader={!props.token
                        ? <FormattedMessage id='forgottenpassword.subheader.1' />
                        : <FormattedMessage id='forgottenpassword.subheader.2' />
                    }
                    action={<ActionButton iconButton disabled>
                        <ForgottenPasswordIcon />
                    </ActionButton>}
                />
                {!props.token
                    ? <CardContent>
                        {(props.submitFailed && props.submitErrors.token) && <React.Fragment>
                            <ErrorMessage><FormattedMessage id='forgottenpassword.error.invalid_token' /></ErrorMessage>
                            <SpaceDivider />
                        </React.Fragment>}
                        <Form onSubmit={props.handleSubmit} center>
                            <Field name='email' type='email' fieldType='TextField' label={<FormattedMessage id='forgottenpassword.form.fields.email' />} />
                        </Form>
                    </CardContent>
                    : <CardContent>
                        <Form onSubmit={props.handleSubmit} center>
                            <Field name='password' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'forgottenpassword.form.fields.password'})}*`}
                                   helperText={<FormattedMessage id='forgottenpassword.form.fields.password.help' values={{length: 12}} />} />
                            <FieldWithIconHolder>
                                <Field withIcon name='password2' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'forgottenpassword.form.fields.password2'})}*`} />
                                <FieldIcon onClick={() => setShowPasswords(!showPasswords)}>
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </FieldIcon>
                            </FieldWithIconHolder>
                        </Form>
                    </CardContent>}
            </React.Fragment>}
        <ThemeProvider wild>
            {['posting_reset_password_request', 'posting_reset_password', 'posted_reset_password', 'posted_reset_password_not_approved',
                'posted_reset_password_request', 'failed_reset_password_request', 'failed_reset_password'].includes(props.authState)
                ? <CardActionsLoader failure={['failed_reset_password_request', 'failed_reset_password'].includes(props.authState)}
                                     success={['posted_reset_password_request', 'posted_reset_password_not_approved'].includes(props.authState)}
                                     postAnimation={success => {
                                         if (success) {
                                             if (props.authState === 'posted_reset_password_not_approved') {
                                                 props.setAuthState('not_approved');
                                                 props.history.push(`${props.portal ? '/partner' : ''}/not-approved`);
                                                 props.addMessage({intl_id: 'forgottenpassword.success.not_approved', path: '/not-approved'});
                                             } else {
                                                 props.setAuthState('posted_reset_password_request_afterAnim');
                                             }
                                         } else {
                                             props.setAuthState(null);
                                         }
                                     }} />
                : <CardActions>
                    <Button onClick={() => props.history.push(props.portal ? '/partner' : '/')}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    {props.authState === null && <Button variant='contained' color='primary' type='submit' onClick={props.handleSubmit}>
                        {!props.token ? <Submit1Icon /> : <Submit2Icon />}
                        <FormattedMessage id={`forgottenpassword.form.submit.${!props.token ? '1' : '2'}`} />
                    </Button>}
                </CardActions>}
        </ThemeProvider>
        <SpaceDivider />
        <ForgottenPasswordWave />
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    if (!props.token) {
        validator.isNotNull(null, errors, 'email', data.email) &&
        validator.isEmail(null, errors, 'email', data.email);
    } else {
        validator.isLength(
            props.intl.formatMessage({id: 'forgottenpassword.error.length'}, {length: 12}), errors, 'password', data.password,
            {min: 12, max: 128}) &&
        validator.isNotNull(null, errors, 'password', data.password) &&
        validator.containsUpperCase(props.intl.formatMessage({id: 'forgottenpassword.error.uppercase'}), errors, 'password', data.password) &&
        validator.containsLowerCase(props.intl.formatMessage({id: 'forgottenpassword.error.lowercase'}), errors, 'password', data.password) &&
        validator.containsNumber(props.intl.formatMessage({id: 'forgottenpassword.error.number'}), errors, 'password', data.password) &&
        validator.containsSpecialCharacter(props.intl.formatMessage({id: 'forgottenpassword.error.special'}), errors, 'password', data.password);
        validator.isNotNull(null, errors, 'password2', data.password2) &&
        validator.equals(
            props.intl.formatMessage({id: 'forgottenpassword.error.no_match'}),
            errors, 'password2',
            data.password, data.password2);
    }

    return errors;
};

const ForgottenPasswordForm = reduxForm({
    form: 'forgottenPasswordForm',
    validate,
    onSubmit: (values, dispatch, props) => (
        !props.token
            ? dispatch(simplePost('reset_password_request', 'password', props.formValues, {post_method: 'delete', setState: props.setAuthState}))
            : dispatch(simplePost('reset_password', 'password', {token: props.token, password: props.formValues.password}, {post_method: 'put', setState: props.setAuthState, ignore_403: true})).then((result) => {
                if (result === false) {
                    return dispatch(setAuthState('posted_reset_password_not_approved'));
                } else {
                    return dispatch(loginProcedure(result)).then(() => {
                        props.history.push(`${props.portal ? '/partner' : ''}/home`);
                        return dispatch(addMessage({intl_id: 'forgottenpassword.success.login', path: `${props.portal ? '/partner' : ''}/home`}));
                    });
                }
            })
    )
})(ForgottenPassword);

const ConnectedForgottenPassword = connect((state, props) => {
    const portal = props.location.pathname.startsWith('/partner');

    return {
        portal: portal,
        authState: state.auth.get('state'),
        submitErrors: getFormSubmitErrors('forgottenPasswordForm')(state),
        token: new URLSearchParams(props.location.search).get('token'),
        initialValues: !props.token ? {
            service: portal ? 'partner_portal' : 'sraps'
        } : {},
        formValues: getFormValues('forgottenPasswordForm')(state)
    };
}, (dispatch) => bindActionCreators({
    addMessage,
    simplePost,
    loginProcedure,
    setAuthState
}, dispatch))(ForgottenPasswordForm);

export default injectIntl(withRouter(ConnectedForgottenPassword));
