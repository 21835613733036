import React from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';


/**
 * DeleteDialog to make sure User doesn't accidentally delete anything.
 */
export default function DeleteDialog(props) {
    const state = useSelector(state => state.app.get('state'));
    const intl = useIntl();

    return <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
            <FormattedMessage id={props.multiple ? 'deletedialog.multiple.title' : 'deletedialog.title'}
                              values={{
                                  item: props.item || intl.formatMessage({id: 'deletedialog.item'}),
                                  items: props.items || intl.formatMessage({id: 'deletedialog.multiple.items'})
                              }} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id={props.multiple ? 'deletedialog.multiple.description' : 'deletedialog.description'}
                                  values={{
                                      item: props.item || intl.formatMessage({id: 'deletedialog.item'}),
                                      items: props.items || intl.formatMessage({id: 'deletedialog.multiple.items'})
                                  }} />
            </DialogContentText>
            <DialogContentText>
                <FormattedMessage id='deletedialog.description2' />
            </DialogContentText>
        </DialogContent>
        <ThemeProvider alt>
            <DialogActions>
                <Button disabled={state !== null}
                        onClick={() => props.handleClose()}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button variant='contained' color='secondary'
                        disabled={state !== null}
                        onClick={() => props.handleConfirm()}>
                    <DeleteIcon />
                    <FormattedMessage id='actions.delete' />
                </Button>
            </DialogActions>
        </ThemeProvider>
    </Dialog>;
}
