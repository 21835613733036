'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
// Components
import Field from 'components/core/ui/Field';
// icons
import InvalidIcon from '@material-ui/icons/CloseOutlined';


const styles = theme => ({
    // Holder of custom MACsField
    macsFieldHolder: {
        display: 'inline-flex',
        position: 'relative',
        '& $macsField.textarea': {
            // input holder
            '& > div': {
                paddingTop: '6px', // this is default
                paddingRight: '100px', // space for macsValidity
                paddingBottom: '0', // we remove bottom 7px padding
                // input itself
                '& textarea': {
                    lineHeight: '26px' // increase line-height for better readability
                }
            }
        }
    },
    macsField: {},
    // holder of validity information
    macsValidity: {
        // position (right-top aligned)
        position: 'absolute',
        top: `${(theme.spacing(2)) + 6}px`, // label padding + input top padding
        right: `${theme.spacing(1)}px`,
        // half-size of input
        width: '100px'
    },
    // validity info per row
    macValidity: {
        position: 'relative',
        // limit-size (row like)
        height: '26px',
        lineHeight: '26px',
        textAlign: 'right',
        paddingBottom: '1px', // border size
        // divider line
        '&:after': {
            // pseudo element
            content: '""',
            display: 'block',
            // position
            position: 'absolute',
            left: '-220px', // full-size
            right: '0',
            bottom: '0',
            // border
            height: '1px',
            background: theme.palette.divider
        },
        // no divider for last child
        '&:last-child:after': {
            content: 'none'
        },
        // mac info (product)
        '& .text': {
            // style
            color: theme.palette.success[500],
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        // icon (x)
        '& svg': {
            // manual v-align (24px size)
            marginTop: '1px'
        },
        // invalid value
        '&.invalid': {
            '&, & .text': {
                color: theme.palette.danger[500]
            },
            '&:after': {
                background: theme.palette.danger[300]
            }
        }
    }
});

/**
 * TextArea with support for multiple MAC values
 */
class MACSField extends React.Component {
    render() {
        // Split some stuff from props
        let {intl, classes, name, macs_information, invalid_macs, ...rest_of_props} = this.props;

        return <div className={classes.macsFieldHolder}>
            <Field name={name || 'macs'} fieldType='TextArea'
                   className={classes.macsField} size='regular'
                   minRows={1} maxRows={null}
                   {...rest_of_props} />
            <div className={classes.macsValidity}>
                {macs_information.map((info, idx) =>
                    <div key={idx} className={`${classes.macValidity}${invalid_macs.includes(info.get('mac')) || !info.get('valid') || !info.get('product') ? ' invalid' : ''}`}>
                        {invalid_macs.includes(info.get('mac'))
                            ? <div className='text'><FormattedMessage id='endpoints.detail.error.macs.taken.short' /></div>
                            : !info.get('valid') || !info.get('product')
                            ? <InvalidIcon />
                            : <div className='text'>{info.getIn(['product', 'name'])}</div>}
                    </div>
                )}
            </div>
        </div>;
    }
}

MACSField = withStyles(styles)(MACSField);
export default injectIntl(MACSField);
