import React, {useState, useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Endpoint, Ticket} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {saveItem} from 'actions/shared';
// Components
import Field from 'components/core/ui/Field';
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import OnlineIcon from '@material-ui/icons/CloudCircleOutlined';
import OfflineIcon from '@material-ui/icons/OfflineBoltOutlined';
import NullIcon from '@material-ui/icons/HighlightOffOutlined';
import UnsupportedIcon from '@material-ui/icons/NotInterestedOutlined';
import UnavailableIcon from '@material-ui/icons/ErrorOutlineOutlined';
import RebootIcon from '@material-ui/icons/LoopOutlined';


const useStyles = makeStyles(theme => ({
    statusIcon: {
        // variants
        '&.online': {
            color: theme.palette.success[500]
        },
        '&.offline': {
            color: theme.palette.danger[500]
        },
        '&.unsupported': {
            color: theme.palette.grey[300]
        },
        '&.null': {
            color: theme.palette.grey[500]
        },
        '&.temporarily_unavailable': {
            color: theme.palette.orange[500]
        }
    },
    actionButton: {
        // add extra empty shadow so we can later add active one
        boxShadow: `inset 0px 0px 0px 0px transparent, ${theme.noneShadow}, 0px 1000px 0 ${theme.palette.common.white} inset`,
        '&:hover': {
            boxShadow: `inset 0px 0px 0px 0px transparent, ${theme.shadows[2]}, 0px 1000px 0 ${theme.palette.common.white} inset`
        },
        // menu is open
        '&.active': {
            boxShadow: `inset 0px 1px 5px ${theme.palette.action.disabled}, ${theme.noneShadow}, 0px 1000px 0 ${theme.palette.common.white} inset`
        }
    }
}));

/**
 * Render Endpoint Status Icon
 *
 * Props:
 *  identifier - Status, e.g. 'online'
 */
const StatusIcon = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, identifier, ...rest_of_props} = props;
    // Merge classes from props and our custom
    const classes = useStyles();
    const {root} = _classes || {};
    const rootClasses = [
        classes.statusIcon, root, className,
        identifier || 'null'
    ].filter(Boolean).join(' ');

    const IconElement = useMemo(() => {
        switch (identifier) {
            case 'online':
                return OnlineIcon;
            case 'offline':
                return OfflineIcon;
            case 'unsupported':
                return UnsupportedIcon;
            case 'temporarily_unavailable':
                return UnavailableIcon;
            default:
                return NullIcon;
        }
    }, [identifier]);

    return <IconElement className={rootClasses} ref={ref} {...rest_of_props} />;
});
export {StatusIcon};

/**
 * ActionButton with device status and menu to trigger dm quick action
 */
export function EndpointStatusActionButton(passed_props) {
    // Split some stuff from props
    const {endpoint, permission} = passed_props;
    // local state
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // Merge classes from props and our custom
    const classes = useStyles();
    const buttonClasses = [
        classes.actionButton,
        open ? 'active' : ''
    ].filter(Boolean).join(' ');
    // redux store
    const dispatch = useDispatch();
    const ticket_placement = `${new Ticket().getPlacement()}-${endpoint.get(new Endpoint().getUniqueIdentifier())}`;
    const props = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);

    // quick actions
    const triggerReboot = () => {
        dispatch(saveItem(Ticket, ticket_placement, endpoint.getIn(['links', 'tickets']),
            {type: 'operate_reboot', params: {}}));
    };

    return <React.Fragment>
        <ThemeProvider alt>
            <ActionButton className={buttonClasses}
                          variant={endpoint.get('status')?.toLowerCase() === 'online' ? 'outlined' : undefined}
                          color={endpoint.get('status')?.toLowerCase() === 'online' ? 'primary' : endpoint.get('status')?.toLowerCase() === 'offline' ? 'secondary' : undefined}
                          disabled={endpoint.get('status')?.toLowerCase() !== 'online' || permission !== 'RW'}
                          loading={[`saving_item_${ticket_placement}`, `saved_item_${ticket_placement}`, `failed_save_item_${ticket_placement}`].includes(props.state)}
                          success={props.state === `saved_item_${ticket_placement}`}
                          failure={props.state === `failed_save_item_${ticket_placement}`}
                          postAnimation={() => dispatch(setState(null))}
                          onClick={(event) => {
                              setAnchorEl(event.currentTarget);
                              setOpen(!open);
                          }}>
                <StatusIcon identifier={endpoint.get('status')?.toLowerCase()} />
                <FormattedMessage id={`endpointstatusfield.choice.${endpoint.get('status')?.toLowerCase() || 'null'}`} />
            </ActionButton>
        </ThemeProvider>
        <Menu open={open} anchorEl={anchorEl} getContentAnchorEl={null}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
              }}
              onClose={() => setOpen(false)}>
            <MenuItem disabled={props.state !== null}
                      onClick={() => {
                          triggerReboot();
                          setOpen(false);
                      }}>
                <ListItemIcon className='icon'><RebootIcon /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id='endpoints.detail.dm.actions.reboot' /></ListItemText>
            </MenuItem>
        </Menu>
    </React.Fragment>;

}

/**
 * Select field for Endpoint status with Icon and Text
 */
export default function EndpointStatusField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'endpointstatusfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Endpoint().getStatuses().map((identifier, idx) =>
            <MenuItem value={identifier} key={idx}>
                <ListItemIcon className='icon'><StatusIcon identifier={identifier} /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id={`endpointstatusfield.choice.${identifier}`} /></ListItemText>
            </MenuItem>
        )}
    </Field>;
}
