import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
import {useDebounce} from 'use-debounce';
import {FormattedMessage} from 'react-intl';
// material-ui
import MUIExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelChild from 'components/core/ui/mui/ExpansionPanelChild';
import ExpansionPanelSummary from 'components/core/ui/mui/ExpansionPanelSummary';
import ExpansionPanelDetails from 'components/core/ui/mui/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';


const useStyles = makeStyles(theme => ({
    panel: {
        border: `1px solid ${theme.palette.grey[300]}`,
        marginTop: '-1px',
        marginBottom: '-1px',
        // get rid of default top border
        '&:before': {
            content: 'none'
        },
        // expanded status
        '&.expanded': {
            marginTop: `${theme.spacing(2)}px`,
            marginBottom: `${theme.spacing(2)}px`,
            '&:first-child': {
                marginTop: '-1px'
            },
            '&:last-child': {
                marginBottom: '-1px'
            }
        }
    }
}));

/**
 * Integration of MUI ExpansionPanel
 */
export default function ExpansionPanel(props) {
    // Split some stuff from props
    const {_classes = {}, className, expanded, onChange,
        expansionPanelSummary, title, title_intl, subtitle, subtitle_intl,
        loading = false, destroyNotSelected = false, noLink,
        expansionPanelDetailsProps = {}, children, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, detail, ...rest_of_classes} = _classes;
    const rootClasses = [
        root, className, classes.panel
    ].filter(Boolean).join(' ');
    const detailClasses = [
        detail, expansionPanelDetailsProps.className
    ].filter(Boolean).join(' ');

    // support for destroyNotSelected
    const [debouncedExpanded] = useDebounce(expanded, theme.transitions.duration.standard);

    return <MUIExpansionPanel
        classes={{
            root: rootClasses,
            expanded: 'expanded',
            ...rest_of_classes
        }}
        expanded={expanded} onChange={onChange}
        TransitionProps={destroyNotSelected ? {timeout: theme.transitions.duration.standard} : {}}
        {...rest_of_props}>
        {expansionPanelSummary || <ExpansionPanelSummary expandIcon={!noLink && <ExpandMoreIcon />} noLink={noLink}>
            <ExpansionPanelChild>
                {loading ? <LinearProgress /> : <React.Fragment>
                    <Typography variant='body1'>{title || <FormattedMessage id={title_intl} />}</Typography>
                    {(subtitle || subtitle_intl) && <Typography variant='body2'>{subtitle || <FormattedMessage id={subtitle_intl} />}</Typography>}
                </React.Fragment>}
            </ExpansionPanelChild>
        </ExpansionPanelSummary>}
        {((!destroyNotSelected || expanded || debouncedExpanded) && children) && <ExpansionPanelDetails
            {...expansionPanelDetailsProps} className={detailClasses}>
            <ExpansionPanelChild>
                {children}
            </ExpansionPanelChild>
        </ExpansionPanelDetails>}
    </MUIExpansionPanel>;
}
