import React, {useEffect, useState, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {User, Company, getModel} from 'lib/models';
import {useLocation} from 'react-router';
// Components
import {Link} from 'react-router-dom';
// Actions
import {simplePost, markOutdated, setCollection} from 'actions/shared';
import {addMessage, removeMessage} from 'actions/app';


/**
 * Components handling Websocket integration, messages to user and more
 */
export default function Websocket() {
    // local state
    const ws = useRef(null); // direct reference to ws instance
    const [firstInit, setFirstInit] = useState(true); // at the firstInit we don't show disconnected notification
    const [heartbeat, setHeartbeat] = useState(null); // ping-pong periodic check if ws is still communicating
    const heartbeatTime = 10; // ping every X seconds
    const [reconnectTime, setReconnectTime] = useState(0);
    const reconnectTimeMax = 60; // max slow down limit
    const [subscribedCompanies, setSubscribedCompanies] = useState([]); // list of companies we are subscribed to
    // router
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        ws_notification: state.app.get('notifications').find(notification => notification.get('identifier') === 'ws'),
        user: state.auth.get('user'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        selectedCompany: state.shared.getIn(['items', 'companies']).find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')) || state.shared.getIn(['items', 'my-companies']).find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
        permissions: state.auth.get('permissions')
    }), shallowEqual);

    /**
     * During mount trigger initialization
     */
    useEffect(() => {
        if (ws.current === null) {
            initialize();
        }
    }, []);
    // unmount cleanup
    useEffect(() => {
        return () => ws.current ? ws.current.close() : {};
    }, []);

    /**
     * Manage channel subscription to selectedCompany
     */
    useEffect(() => {
        // make sure we have ws running, first company is subscribed,
        // we have company and we are not subscribed already
        if (ws.current && ws.current.readyState === 1 && subscribedCompanies.length &&
            props.selectedCompany && !subscribedCompanies.includes(props.selectedCompany.get(new Company().getUniqueIdentifier()))) {
            subscribeToChannels(props.selectedCompany.get(new Company().getUniqueIdentifier()));
        }
    }, [props.selectedCompany, ws.current?.readyState, JSON.stringify(subscribedCompanies)]);

    /**
     * Manage offline notification
     */
    useEffect(() => {
        if (!firstInit && ws.current && ws.current.readyState !== 1) {
            if (!props.ws_notification) {
                dispatch(addMessage({
                    identifier: 'ws',
                    intl_id: 'websocket.offline',
                    icon: 'offline',
                    type: 'error',
                    removable: false,
                    animation: 'pulse'
                }, true));
            }
        } else if (props.ws_notification) {
            dispatch(removeMessage('ws', true));
        }
    }, [firstInit, ws.current?.readyState]);

    /**
     * Initialize websocket instance and set to state
     */
    const initialize = () => {
        setSubscribedCompanies([]);
        ws.current = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
        ws.current.onmessage = (event) => handleMessage(event);
        ws.current.onopen = () => authorize();
        ws.current.onclose = () => handleClose();
        ws.current.onerror = () => ws.current.close();
    };

    /**
     * Clear instance and let's start again after reconnectTime
     */
    const handleClose = () => {
        if (heartbeat) {
            clearInterval(heartbeat);
            setHeartbeat(null);
        }
        const newReconnectTime = (reconnectTime + 10) < reconnectTimeMax ? (reconnectTime + 10) : reconnectTimeMax;
        setFirstInit(false);
        setReconnectTime(newReconnectTime);
        setTimeout(() => initialize(), newReconnectTime * 1000);
    };

    /**
     * Authorize websocket with User ws token
     */
    const authorize = () => {
        // get Users ws token from API
        dispatch(simplePost(
            'websocket_token', props.user.getIn(['links', 'ws']), {},
            {affect_state: false, ignore_ax_timeout: true}
        )).then(result => {
            if (result && result.data && result.data.token && ws.current && ws.current.readyState === 1) {
                // authenticate
                ws.current.send(JSON.stringify({
                    type: 'authenticate',
                    request_id: 'authenticate',
                    data: {token: result.data.token}
                }));
            }
        });
    };

    /**
     * Start checking if ws instance is alive
     */
    const startHeartbeat = () => {
        setHeartbeat(
            setInterval(() => {
                try {
                    ws.current.send('ping');
                } catch (error) {}
            }, heartbeatTime * 1000)
        );
    };

    /**
     * Subscribe authorized ws to channels
     *
     * @param identifier - 'my' string or company unique ID
     */
    const subscribeToChannels = (identifier = 'my') => {
        // channels
        const company_identifier = identifier === 'my' ? props.company.get(new Company().getUniqueIdentifier()) : identifier;
        const channels = [
            ...(identifier === 'my' ? [`user:${props.user.get(new User().getUniqueIdentifier())}`] : []),
            props.user.isAdmin() && identifier === 'my' ? 'admin:admin' : `company:${company_identifier}`,
            ...(identifier === 'my' && props.permissions.get('notifications') !== 'X' ? [`dm_notification:${company_identifier}`] : []),
            `dm_endpoint:${company_identifier}`,
            `dm_ticket:${company_identifier}`,
            `dm_ticket_group:${company_identifier}`,
            `dm_ticket_scheduler:${company_identifier}`,
            `phonelink:${company_identifier}`
        ];

        setSubscribedCompanies([...subscribedCompanies, company_identifier]);
        const data = {
            type: 'subscribe',
            request_id: 'subscribe',
            channel: channels
        };
        ws.current.send(JSON.stringify(data));
    };

    /**
     * Handle websocket message
     *
     * @param event - event from Websocket
     */
    const handleMessage = (event) => {
        // pong from heartbeat ping
        if (event.data === 'pong') {
            return;
        }

        // parse data
        const data = JSON.parse(event.data);

        if (data.response_id === 'authenticate') { // authorization
            startHeartbeat();
            subscribeToChannels();
        } else if (data.response_id === 'subscribe') { // channel subscription
            // we don't need to do anything
        } else if (data && data.data) { // generic message
            const model = data.channel?.startsWith('dm_') ? data.channel.split(':')[0].replace('dm_', '') : data.data.obj;

            // must be supported by the frontend
            if (!['export', 'activity', 'notification', 'endpoint', 'ticket', 'ticket_group', 'endpoint_bulk_load_task', 'phonelink'].includes(model)) {
                return;
            }

            // Exports append special informative message for User
            if (model === 'export' && data.channel === `user:${props.user.get(new User().getUniqueIdentifier())}`) {
                // let user know, that new export is ready
                dispatch(addMessage({
                    intl_id: 'exports.request.ready',
                    intl_values: {
                        link: <Link to={`/${props.user.isAdmin() ? 'all-' : ''}exports`} className='hover-border'>
                            <FormattedMessage id='exports.request.ready.link' />
                        </Link>
                    },
                    remove_paths: ['/exports', '/all-exports']
                }));
            }

            // get company_identifier for placement
            const company_id = data.data.company ? data.data.company : data.channel?.startsWith('company:') ? data.channel.replace('company:', '') : null;
            const company_identifier = company_id && company_id !== props.company.get(new Company().getUniqueIdentifier()) ? company_id : null;
            // get model from response for placement and model
            const Model = getModel(`${model === 'notification' ? 'dm' : ''}${model}`);
            const modelPlacement = `${new Model().getPlacement()}${model === 'ticket' ? `-${data.data.obj.endpoint_mac}` : model === 'ticket_group' ? `-${data.data.obj.provisioning_profile}` : company_identifier ? `-${company_identifier}` : ''}`;
            const modelGlobalPlacement = `${new Model().getPlacement()}-global`;

            // some models can be directly updated in a collection due to full model in response
            if (data.data.action === 'update' && ['notification', 'endpoint', 'ticket', 'ticket_group'].includes(model)) {
                dispatch(setCollection(Model, modelPlacement, [data.data.obj], true));
                // mark outdated global collection
                dispatch(markOutdated(Model, modelGlobalPlacement));
            } else {
                // mark outdated, components itself will handle everything else
                dispatch(markOutdated(Model, modelPlacement));
                dispatch(markOutdated(Model, modelGlobalPlacement));
            }
        }
    };

    return null;
}
