'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {SubmissionProduct, SubmissionProductVariant} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {EnhancedReduxField} from 'components/core/ui/Field';
import SubmissionProductVariantButton from 'components/core/ui/SubmissionProductVariantButton';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import FormControl from '@material-ui/core/FormControl';
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
// icons
import ClearIcon from '@material-ui/icons/ClearOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';


const styles = theme => ({
    formControl: {
        // style taken from Field.js formControl
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`
    },
    // holder of SubmissionProductVariantButtons in DialogContent
    buttonsHolder: {
        // next to each other
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // to sides
        margin: `-${theme.spacing(0.5)}px`,
        '& .spv-button': {
            // space between
            margin: `${theme.spacing(0.5)}px`
        }
    },
    button: {
        '&.label': {
            // extra space for label
            marginTop: `${theme.spacing(2)}px`
        }
    }
});

/**
 * Render hidden input with SubmissionProductVariantButton
 */
const _renderField = (field) => {
    return <FormControl className={field.classes.formControl}
                        error={field.meta.touched && field.meta.error ? true : undefined}>
        <input {...field.input} type='hidden' />
        {field.label && <InputLabel shrink={true}>{field.label}</InputLabel>}
        <SubmissionProductVariantButton
            className={`${field.classes.button}${field.label ? ' label' : ''}`}
            spvariant={field.spvariant}
            loading={field.loading}
            onClick={field.onOpen}
            disabledLike={field.disabled} />
        {(field.meta.touched && field.meta.error) && <FormHelperText>{field.meta.error}</FormHelperText>}
    </FormControl>;
};

/**
 * Redux Field for SubmissionProductVariant, Select that uses Dialog with
 */
class SubmissionProductVariantField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false, // open/close state of select dialog
            sortedVariants: [] // sorted SubmissionProductVariants
        };
    };

    /**
     * During initialization fetch items if needed
     */
    componentDidMount() {
        this.fetchSubmissionProducts();
        // open dialog during initialization if set
        if (this.props.preOpen && !this.props.selectedValue) {
            this.setState({open: true});
        }
    }

    /**
     * Fetch Products without affecting state
     */
    fetchSubmissionProducts() {
        // do we have data loaded already?
        if (this.props.submission_products_loaded === false) {
            if (!this.props.doNotFetchSubmissionProducts) {
                // fetch items but don't affect state
                this.props.fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false});
            }
        } else {
            this.initialSortItems(this.getVariants(), 'sortedVariants');
        }
    }

    /**
     * Watch for SubmissionProducts loaded status to sort and process variants
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // in case of SubmissionProducts are newly loaded
        if (!prevProps.submission_products_loaded && this.props.submission_products_loaded) {
            // process and sort variants
            this.initialSortItems(this.getVariants(), 'sortedVariants');
        }
    }

    /**
     * Get variants from SubmissionProducts
     */
    getVariants() {
        let variants = [];
        this.props.submission_products_items.map(el => el.get('variants').map(variant => variant.get('active') ? variants.push(variant) : {}));
        return ImmutableList(variants);
    }

    /**
     * Initially sort items by name
     *
     * @param items - Fetched items from store, e.g. this.props.products_items
     * @param placement - placement in state, e.g. sortedProducts
     */
    initialSortItems(items, placement) {
        let sortedItems = items.toArray();
        sortedItems = sortedItems.slice().sort((a, b) => a.get('name').toLowerCase() === b.get('name').toLowerCase() ? 0 : a.get('name').toLowerCase() < b.get('name').toLowerCase() ? -1 : 1);
        this.setState({[placement]: sortedItems});
    }

    render() {
        // Split some stuff from props
        let {permissions, permission, intl, submission_products_loaded, submission_products_items, products_loaded, products_items, product_groups_loaded, product_groups_items,
            fetchItems, classes, doNotFetchSubmissionProducts, disabled, selectedValue, change, name, label, preOpen, disabledVariants, ...rest_of_props} = this.props;
        // get selectedVariant from selectedValue
        let selectedVariant = null;
        this.props.submission_products_items.map(submission_product => submission_product.get('variants').forEach(variant => {
            if (variant.get(new SubmissionProduct().getUniqueIdentifier()) === selectedValue) {
                selectedVariant = variant;
            }
        }));

        return <React.Fragment>
            <EnhancedReduxField
                name={name}
                component={_renderField}
                classes={classes}
                spvariant={selectedVariant}
                loading={!submission_products_loaded}
                label={label}
                onOpen={() => this.setState({open: true})}
                disabled={disabled}
                {...rest_of_props}
            />
            {!disabled && <Dialog open={this.state.open} onClose={() => this.setState({open: false})}
                                  scroll='body' fullWidth maxWidth='lg'>
                <DialogTitle><FormattedMessage id='submissionproductvariantfield.dialog.title' /></DialogTitle>
                {!submission_products_loaded
                    ? <DialogContent>
                        <SpaceDivider loading />
                        <LinearProgress />
                        <SpaceDivider loading />
                    </DialogContent>
                    : <DialogContent>
                        <div className={classes.buttonsHolder}>
                            {this.state.sortedVariants.map((variant, idx) => {
                                let variant_uuid = variant.get(new SubmissionProductVariant().getUniqueIdentifier());

                                return <SubmissionProductVariantButton
                                    key={idx} spvariant={variant}
                                    onClick={() => {
                                        change(name, variant_uuid);
                                        this.setState({open: false});
                                    }}
                                    selected={variant_uuid === selectedValue}
                                    disabled={variant_uuid !== selectedValue && (!disabledVariants ? false : disabledVariants.includes(variant_uuid))}
                                />;
                            })}
                        </div>
                    </DialogContent>}
                <DialogActions>
                    <Button onClick={() => this.setState({open: false})}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    {this.props.selectedValue && <Button
                        variant='outlined'
                        onClick={() => {
                            this.props.change(name, null);
                            this.setState({open: false});
                        }}>
                        <ClearIcon />
                        <FormattedMessage id='actions.clear' />
                    </Button>}
                </DialogActions>
            </Dialog>}
        </React.Fragment>;
    }
}

SubmissionProductVariantField = withStyles(styles)(SubmissionProductVariantField);
SubmissionProductVariantField = connect((state) => ({
    permissions: state.auth.get('permissions'),
    permission: state.auth.get('permission'),
    submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
    submission_products_items: state.shared.getIn(['items', 'submission-products'])
}), (dispatch) => bindActionCreators({
    fetchItems
}, dispatch))(SubmissionProductVariantField);

export default injectIntl(SubmissionProductVariantField);
