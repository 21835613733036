import {Map, OrderedMap, List} from 'immutable';
import storage from 'store';
import keymirror from 'keymirror';
import {listAsModel, asModel, Paginator, DocVersion} from 'lib/models';


export const constants = keymirror({
    SHARED_SET_COLLECTION: null,
    SHARED_UPDATE_PAGINATOR: null,
    SHARED_REMOVE_FROM_COLLECTION: null,
    SHARED_SET_FILTER: null,
    SHARED_SET_ORDERING: null,
    SHARED_FILTERED: null,
    SHARED_OUTDATED: null,
    SHARED_LOCAL_STATE: null
});

const defaults = Map({
    loaded: Map({
        'users': false,
        'companies': false,
        'my-companies': false,
        'roles': false,
        'changelogs': false,
        'release_notes': false,
        'subscription-plans': false,
        'invoices': false,
        'payment-methods': false,
        'product-groups': false,
        'products': false,
        'submission-products': false,
        'endpoints': false,
        'endpoints-global': false,
        'endpoints-imports': false,
        'voice-quality': false,
        'dead-letters': false,
        'dm_notifications': false,
        'setting-groups': false,
        'settings': false,
        'provisioning-profiles': false,
        'provisioning-profiles-global': false,
        'provisioning-files': false,
        'provisioning-files-global': false,
        'provisioning-file-types': false,
        'firmware': false,
        'product-defaults': false,
        'exports': false,
        'activities': false,
        'activities-global': false,
        'api-keys': false,
        'doc-versions': false,
        'distributors': false,
        'pbx-partners': false,
        'submissions': false,
        'submissions-global': false,
        'projects': false,
        'claims-global': false,
        'pages': false,
        'sales_clusters': false,
        'feature-flags': false,
        'phonelink-source': false
    }),
    outdated: Map({}), // might contain same records as 'loaded'
    state: Map({ // local state of some components
        'documentation': null, // loading state of redoc
        'doc-selected': new DocVersion(), // selected DocVersion
        'setting-added': null, // newly added setting via settings manager
        'assets-view-type': storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-assets-view-type`) === 'list' ? 'list' : 'grid' // file assets manager view type
    }),
    paginator: Map({
        'users': new Paginator(),
        'companies': new Paginator(),
        'my-companies': new Paginator(),
        // my-companies-${company_identifier}
        'dm_notifications': new Paginator(),
        'endpoints': new Paginator(),
        // endpoints-${company_identifier}
        'endpoints-global': new Paginator(),
        'voice-quality': new Paginator(),
        // provisioning-logs-${endpoint_identifier}
        // tickets-${endpoint_identifier}
        // ticket-schedulers-${endpoint_identifier|provprofile_identifier}
        'provisioning-profiles-global': new Paginator(),
        // ticket-groups-${provprofile_identifier}
        'dead-letters': new Paginator(),
        'activities': new Paginator(),
        // activities-${company_identifier}
        'activities-global': new Paginator(),
        'claims-global': new Paginator(),
        'submissions-global': new Paginator()
    }),
    page_references: Map({}),
    items: Map({
        'tables': List(),
        'users': List(),
        'companies': List(),
        // companyUsers-${company_identifier}
        'my-companies': List(),
        // my-companies-${company_identifier}
        'roles': List(),
        'changelogs': List(),
        'release_notes': List(),
        'subscription-plans': List(),
        // 'subscription-plans-${company_identifier}': List(),
        'subscriptions-global': List(),
        'invoices': List(),
        'payment-methods': List(),
        'product-groups': List(),
        'products': List(),
        'submission-products': List(),
        'endpoints': List(),
        // endpoints-${company_identifier}
        'endpoints-global': List(),
        'endpoint_macs_information': List(),
        'endpoint_invalid_macs': List(),
        'endpoints-imports': List(),
        // endpoints-imports-${company_identifier}
        'endpoints-imports-errors': List(),
        // 'endpoints-imports-errors-{task_identifier}': List(),
        'voice-quality': List(),
        // provisioning-logs-${endpoint_identifier}
        // voice-quality-${company_identifier / endpoint_identifier}
        // tickets-${endpoint_identifier / provisioning_profile_identifier}
        // ticket-scheduler-${endpoint_identifier / provisioning_profile_identifier}
        'dm_notifications': List(),
        'dead-letters': List(),
        'setting-groups': List(),
        'settings': List(),
        'settings-selected': List(),
        'provisioning-profiles': List(),
        // provisioning-profiles-${company_identifier}
        'provisioning-profiles-global': List(),
        'provisioning-files': List(),
        // provisioning-files-${company_identifier}
        'provisioning-files-global': List(),
        'provisioning-file-types': List(),
        'firmware': List(),
        // firmware-${company_identifier}
        'product-defaults': List(),
        // product-defaults-${company_identifier}
        'statistics': List(),
        'exports': List(),
        // exports-${company_identifier}
        'activities': List(),
        // activities-${company_identifier}
        'activities-global': List(),
        'api-keys': List(),
        // api-keys-${company_identifier}
        'doc-versions': List(),
        'distributors': List(),
        'pbx-partners': List(),
        'submissions': List(),
        // submissions-${company_identifier}
        'submissions-global': List(),
        'projects': List(),
        'claims-global': List(),
        'pages': List(),
        'sales_clusters': List(),
        'feature-flags': List(),
        'feature-flags-definition': List(),
        'phonelink-source': List()
    }),
    filters: Map({}), // active filters
    ordering: Map({}) // active ordering
});

const reducer = (state = defaults, action) => {
    switch (action.type) {
        // sets items/removes to store
        case constants.SHARED_SET_COLLECTION:
            // implementation of paginator
            if (action.paginator) {
                // set new paginator
                // update parent collection (e.g. 'users' without page)
                // create (for page 1) items reference filled with IDs or append to existing (every other page is next page)
                // marks loaded as 'true' (action.loaded)
                return state.setIn(['paginator', action.placement], asModel(Paginator, {
                    ...(state.getIn(['paginator', action.placement]) || new Paginator()).toJS(),
                    ...action.paginator
                }))
                    .setIn(['items', action.placement], List(state.getIn(['items', action.placement], List()).reduce((result, el) => result.set(el.get(new action.model().getUniqueIdentifier()), el), OrderedMap()).merge(listAsModel(action.model, action.items).reduce((result, el) => result.set(el.get(new action.model().getUniqueIdentifier()), el), OrderedMap())).values()))
                    .setIn(['page_references', action.placement], action.paginator.page === 1
                        ? List(action.items.map(el => el[new action.model().getUniqueIdentifier()]))
                        : state.getIn(['page_references', action.placement]).concat(List(action.items.map(el => el[new action.model().getUniqueIdentifier()])))
                    )
                    .setIn(['loaded', action.placement], action.loaded).setIn(['outdated', action.placement], false);
            }

            // now update / set / reset collection
            return (action.model && action.placement && action.items)
                ? action.update
                    // update list (for detail, pagination, etc.) - updates current IDs and add new missing items
                    // magic happening here: https://stackoverflow.com/a/43658124
                    ? state.setIn(['items', action.placement], List(state.getIn(['items', action.placement], List()).reduce((result, el) => result.set(el.get(new action.model().getUniqueIdentifier()), el), OrderedMap()).merge(listAsModel(action.model, action.items).reduce((result, el) => result.set(el.get(new action.model().getUniqueIdentifier()), el), OrderedMap())).values()))
                    // create list, mark loaded as 'true' (action.loaded)
                    : state.setIn(['items', action.placement], listAsModel(action.model, action.items)).setIn(['loaded', action.placement], action.loaded).setIn(['outdated', action.placement], false)
                : state.set('items', new Map({...defaults.get('items').toJS(), 'feature-flags': state.getIn(['items', 'feature-flags'])})).set('loaded', new Map({...defaults.get('loaded').toJS(), 'feature-flags': state.getIn(['loaded', 'feature-flags'])})).set('outdated', defaults.get('outdated'));

        // update active filter
        case constants.SHARED_SET_FILTER:
            return state.setIn(['filters', action.placement], action.filters || {});

        // update active ordering
        case constants.SHARED_SET_ORDERING:
            return state.setIn(['ordering', action.placement], action.ordering || {});

        // update paginator
        case constants.SHARED_UPDATE_PAGINATOR:
            return (action.paginator && action.placement)
                ? state.setIn(['paginator', action.placement], asModel(Paginator, {
                    ...(state.getIn(['paginator', action.placement]) || new Paginator()).toJS(),
                    ...action.paginator
                }))
                : state.set('paginator', defaults.get('paginator'));

        // removes single item from collection
        case constants.SHARED_REMOVE_FROM_COLLECTION:
            return state.setIn(['items', action.placement], List(state.getIn(['items', action.placement]), List()).filter(el => el.get(new action.model().getUniqueIdentifier()) !== action.item.get(new action.model().getUniqueIdentifier())));

        // turns 'loaded' into false. It is indicator for 'needs reload'
        case constants.SHARED_FILTERED:
            return state.setIn(['loaded', action.placement], false);

        // turns 'outdated' into true. It is indicator for 'needs reload'
        case constants.SHARED_OUTDATED:
            return state.setIn(['outdated', action.placement], true);

        // sets local state of some component
        case constants.SHARED_LOCAL_STATE:
            return action.placement
                ? state.setIn(['state', action.placement], action.state)
                : state.set('state', defaults.get('state'));

        default:
            return state;
    }
};
export default reducer;
