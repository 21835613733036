import React, {useMemo} from 'react';
import Moment from 'moment';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// Components
import {TypeIcon} from 'components/core/ui/fields/TicketTypeField';
import {StatusIcon} from 'components/core/ui/fields/TicketStatusField';
import {Row, Col} from 'components/core/ui/Grid';
import JSONPretty from 'lib/JSONPretty';
import {JSONFieldTheme} from 'components/core/ui/fields/JSONField';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Table from 'components/core/ui/mui/Table';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import {alpha, lighten} from '@material-ui/core/styles/colorManipulator';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import DownloadIcon from '@material-ui/icons/SaveAltOutlined';


const useStyles = makeStyles(theme => ({
    tableWrapper: {
        height: '100%'
    },
    table: {
        height: '100%',
        borderTop: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        '&.right': {
            borderLeft: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            '&.right': {
                borderTop: 'none',
                borderLeft: 'none'
            }
        }
    },
    // left cells working as headline
    headlineCell: {
        textAlign: 'right',
        // limit size
        width: '150px',
        // divider between (taken from mui tableCell)
        borderRight: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        // variants
        '&.finished_at': {
            color: theme.palette.success[500]
        },
        '&.updated_at': {
            color: theme.palette.primary[500]
        },
        '&.expires_at': {
            color: theme.palette.danger[500]
        }
    },
    // cell containing JSONPretty
    jsonCell: {
        // to sides
        padding: '0',
        // match json background
        background: JSONFieldTheme.background,
        // holder of json
        '& pre': {
            // extra breathing space
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
        }
    }
}));

/**
 * Endpoint Ticket or Provisioning Profile Ticket Group detail from Device Management
 */
export default function Detail(passed_props) {
    const {item, ticket_group = false} = passed_props;
    const classes = useStyles();
    const intl = useIntl();
    const intl_base = useMemo(() => ticket_group ? 'ticketgroups' : 'tickets', [ticket_group]);

    // renders Title for table
    const headlineCell = (intl_id, cls) => <TableCell className={`${classes.headlineCell}${cls ? ` ${cls}` : ''}`}>
        <Typography variant='subtitle2'>
            {`${intl.formatMessage({id: intl_id})}: `}
        </Typography>
    </TableCell>;

    return <React.Fragment>
        <CardHeader
            title={<FormattedMessage id={`${intl_base}.detail.title`} />}
            subheader={`#${item.get(ticket_group ? 'group_id' : 'ticket_id')}`}
            action={<ActionButton iconButton disabled>
                <ModelIcon model={ticket_group ? 'ticket_group' : 'ticket'} />
            </ActionButton>}
        />
        <Row wrap nospace stretch>
            <Col>
                <Table size={1} _classes={{root: `${classes.table} left`, wrapper: classes.tableWrapper}}>
                    <TableBody>
                        <TableRow>
                            {headlineCell(`${intl_base}.detail.form.fields.type`)}
                            <TableCell name>
                                <TypeIcon identifier={item.get('type')} className='icon' />
                                <span className='text'>{intl.formatMessage({id: `tickettypefield.choices.${item.get('type')}`})}</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {headlineCell(`${intl_base}.detail.form.fields.status`)}
                            <TableCell name>
                                <StatusIcon identifier={item.get('status')} className='icon' />
                                <span className='text'>{intl.formatMessage({id: `ticketstatusfield.choices.${item.get('status')}`})}</span>
                            </TableCell>
                        </TableRow>
                        {ticket_group && <TableRow>
                            {headlineCell('ticketgroups.detail.form.fields.no_tickets')}
                            <TableCell>
                                {item.get('no_tickets') || 0}
                            </TableCell>
                        </TableRow>}
                        {['scheduled_at', 'created_at', 'updated_at', 'finished_at', ...(ticket_group ? [] : ['expires_at'])].map(date => <TableRow key={date}>
                            {headlineCell(`${intl_base}.detail.form.fields.${date}`, (item.get(date) && (date !== 'expires_at' || item.get('status') === 'expired')) && date)}
                            <TableCell>
                                {item.get(date) ? `${Moment(item.get(date)).fromNow()} [${Moment(item.get(date)).format('l, LT')}]` : '-'}
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </Col>
            <Col>
                <Table size={1} _classes={{root: `${classes.table} right`, wrapper: classes.tableWrapper}}>
                    <TableBody>
                        <TableRow>
                            {headlineCell(`${intl_base}.detail.form.fields.params`)}
                            <TableCell className={classes.jsonCell}>
                                <JSONPretty json={item.get('params') ? item.get('params').toJS() : null} theme={JSONFieldTheme} />
                            </TableCell>
                        </TableRow>
                        {!ticket_group && <TableRow>
                            {headlineCell(`${intl_base}.detail.form.fields.result_file`)}
                            <TableCell>
                                {item.get('result_file')
                                    ? <Button component='a' target='_blank' rel='noreferrer'
                                              href={item.get('result_file')}>
                                        <DownloadIcon />
                                        <FormattedMessage id='actions.download' />
                                    </Button> : '-'}
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </Col>
        </Row>
    </React.Fragment>;
}
