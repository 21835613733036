import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Page} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


/**
 * Simple select field for (dynamic) Page page_slug
 */
export default function DynamicPageSlug(props) {
    // Split some stuff from props
    const {name, label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' name={name || 'page_slug'}
                  label={label || `${intl.formatMessage({id: 'dynamicpageslug.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Page().getIdentifiers().map(identifier => <MenuItem key={identifier} value={identifier}>
            <ListItemIcon className='icon'><ModelIcon model={identifier} /></ListItemIcon>
            <ListItemText className='text'><FormattedMessage id={`menu.customer.portal.${identifier}`} /></ListItemText>
        </MenuItem>)}
    </Field>;
}
