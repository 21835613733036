import React from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Simple select field for User Department
 */
export default function DepartmentField(props) {
    const {name, label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' name={name || 'department'}
                  label={label || `${intl.formatMessage({id: 'departmentfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        <MenuItem value='Accounting'><FormattedMessage id='departmentfield.choices.accounting' /></MenuItem>
        <MenuItem value='HR'><FormattedMessage id='departmentfield.choices.hr' /></MenuItem>
        <MenuItem value='HW & Software Department'><FormattedMessage id='departmentfield.choices.hw_sw' /></MenuItem>
        <MenuItem value='Internal Sales'><FormattedMessage id='departmentfield.choices.internal_sales' /></MenuItem>
        <MenuItem value='IT'><FormattedMessage id='departmentfield.choices.it' /></MenuItem>
        <MenuItem value='Management'><FormattedMessage id='departmentfield.choices.management' /></MenuItem>
        <MenuItem value='Marketing'><FormattedMessage id='departmentfield.choices.marketing' /></MenuItem>
        <MenuItem value='Productmanagement'><FormattedMessage id='departmentfield.choices.product_management' /></MenuItem>
        <MenuItem value='Purchase'><FormattedMessage id='departmentfield.choices.purchase' /></MenuItem>
        <MenuItem value='RMA / SERVICE'><FormattedMessage id='departmentfield.choices.rma_service' /></MenuItem>
        <MenuItem value='Sales'><FormattedMessage id='departmentfield.choices.sales' /></MenuItem>
        <MenuItem value='Support'><FormattedMessage id='departmentfield.choices.support' /></MenuItem>
    </Field>;
}
