import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {SettingGroup, Setting} from 'lib/models';
import {Map as ImmutableMap} from 'immutable';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Actions
import {removeFromCollection} from 'actions/shared';
// Components
import SelectingSettings from 'components/modules/settings/SelectingSettings';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import {SettingField} from 'components/core/ui/Field';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import Tooltip from 'components/core/ui/mui/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import DeleteIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    // holder of column with SelectingSettings
    selectingSettingsCol: {
        position: 'sticky',
        top: `${theme.spacing(2)}px`
    }
}));

/**
 * Integration of Settings Fields for Endpoints and Provisioning Profiles
 */
export default function SettingsManager(passed_props) {
    const {location, namePrefix = 'settings_manager', permission, change,
        formValues, handleSubmit, sortedGroups, sortedSettings, settings_selected,
        product, product_group, cardActions
    } = passed_props;
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        setting_added: state.shared.getIn(['state', 'setting-added'])
    }), shallowEqual);
    // style
    const classes = useStyles();

    return <Row wrapSwitch>
        <Col width='66.6667%'>
            <Card>
                <CardHeader title={<FormattedMessage id={`${location}.detail.form.settings.title`} />}
                            subheader={settings_selected.size
                                ? <FormattedMessage id={`${location}.detail.form.settings.subheader`} />
                                : <FormattedMessage id={`${location}.detail.form.settings.empty`} />}
                />
                <CardContent>
                    {(['fetching_items_settings'].includes(props.state) || !props.groups_loaded)
                        ? <LinearProgress />
                        : !settings_selected.size
                            ? <SpaceDivider loader />
                            : !!settings_selected.size && sortedGroups.map(group => !!settings_selected.filter(setting => setting.get('group') === group.get(new SettingGroup().getUniqueIdentifier())).size && <div key={group.get(new SettingGroup().getUniqueIdentifier())}>
                            <div>
                                <Typography variant='body1'>{group.get('name')}</Typography>
                                <Typography variant='body2'>{group.get('description')}</Typography>
                            </div>
                            <SpaceDivider />
                            <Form onSubmit={() => handleSubmit()}>
                                <AutoCompleteOff />
                                {settings_selected
                                    .filter(setting => setting.get('group') === group.get(new SettingGroup().getUniqueIdentifier()))
                                    .sort((a, b) => a.get('name').toLowerCase() === b.get('name').toLowerCase() ? 0 : a.get('name').toLowerCase() < b.get('name').toLowerCase() ? -1 : 1)
                                    .map(setting => <React.Fragment key={setting.get(new Setting().getUniqueIdentifier())}>
                                        {permission === 'RW'
                                            ? <Tooltip title={<FormattedMessage id='actions.remove' />}>
                                                <IconButton onClick={() => dispatch(removeFromCollection(Setting, 'settings-selected', setting))}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : <IconButton disabled={true}>
                                                <DeleteIcon />
                                            </IconButton>}
                                        {['certificate'].includes(setting.get('field_type')) && <SpaceDivider />}
                                        <SettingField
                                            namePrefix={`${namePrefix}.${setting.get(new Setting().getUniqueIdentifier())}`}
                                            setting={setting}
                                            change={change}
                                            autoFocus={props.setting_added && props.setting_added.get(new Setting().getUniqueIdentifier()) === setting.get(new Setting().getUniqueIdentifier())}
                                            selectedValue={new ImmutableMap(Object.entries(formValues[namePrefix])).get(setting.get(new Setting().getUniqueIdentifier()))
                                                ? setting.get('indexed') && setting.getIn(['indexed', 'enabled'])
                                                    ? new ImmutableMap(Object.entries(formValues[namePrefix])).get(setting.get(new Setting().getUniqueIdentifier()))
                                                    : new ImmutableMap(Object.entries(formValues[namePrefix])).get(setting.get(new Setting().getUniqueIdentifier())).value
                                                : undefined
                                            }
                                        />
                                        <SpaceDivider />
                                    </React.Fragment>)}
                            </Form>
                            <SpaceDivider />
                        </div>)}
                </CardContent>
                {cardActions}
            </Card>
        </Col>
        <Col width='33.3333%' className={classes.selectingSettingsCol}>
            <SelectingSettings
                product={product}
                filterProductGroup={product_group}
                sortedGroups={sortedGroups}
                sortedItems={sortedSettings}
                validationHack={() => change('_validationHack', Date.now())}
                permission={permission}
            />
        </Col>
    </Row>;
}
