import Moment from 'moment/moment';

/**
 * Converts string breaks into html.
 *
 * e.g.:
 *  oneline\nsecondline
 * into:
 *  oneline<br />
 *  secondline
 *
 * @param str - sentence
 * @returns {string} - HTML result
 */
export function breakLine(str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
}

/**
 * Parse HEX (#C89664) to Snom RGBA (200 150 100 255).
 *
 * @param hex - HEX
 * @returns {string} - Snom RGBA
 */
export function hexToSnomRGBA(hex) {
    try {
        hex = hex.replace('#', '');
        const R = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
        const G = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
        const B = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
        return `${R} ${G} ${B} 255`;
    } catch (error) {
        return hex;
    }
}

/**
 * Parse Snom RGBA (200 150 100 255) to HEX (#C89664)
 *
 * @param rgba - Snom RGBA
 * @returns {string} - HEX
 */
export function snomRGBAtoHex(rgba) {
    try {
        rgba = rgba.split(' ');
        const R = parseInt(rgba[0], 10).toString(16);
        const G = parseInt(rgba[1], 10).toString(16);
        const B = parseInt(rgba[2], 10).toString(16);
        return `#${R.length === 1 ? `0${R}` : R}${G.length === 1 ? `0${G}` : G}${B.length === 1 ? `0${B}` : B}`;
    } catch (error) {
        return rgba;
    }
}

/**
 * Parse links for Paginator from API response headers
 *
 * @param link_header - API response header 'link'
 */
export function parseLinkHeader(link_header) {
    // Split parts by comma
    const parts = link_header.split(',');
    const links = {};
    // Parse each part into a named link
    parts.forEach((part) => {
        const section = part.split(';');
        const url = section[0].replace(/<(.*)>/, '$1').trim();
        const name = section[1].replace(/rel="(.*)"/, '$1').trim();
        links[name] = url;
    });
    return links;
}

/**
 * Convert bytes to human readable string
 *
 * @param size - File size in bytes
 * @returns string - Return File size in human readable format, e.g. '15 MB'
 */
export function humanFileSize(size) {
    if (!size || size === 0) return '0 MB';
    const f = Math.floor(Math.log(size) / Math.log(1024));
    return `${parseFloat((size / Math.pow(1024, f)).toFixed(2))} ${['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][f]}`;
}

/**
 * Get currency symbol based on currency code
 *
 * @param currency - currency code, e.g. EUR
 * @returns string - currency symbol, e.g. €
 */
export function getCurrencySymbol(currency) {
    const currency_symbols = {
        'USD': '$', // US Dollar
        'EUR': '€', // Euro
        'CRC': '₡', // Costa Rican Colón
        'GBP': '£', // British Pound Sterling
        'ILS': '₪', // Israeli New Sheqel
        'INR': '₹', // Indian Rupee
        'JPY': '¥', // Japanese Yen
        'KRW': '₩', // South Korean Won
        'NGN': '₦', // Nigerian Naira
        'PHP': '₱', // Philippine Peso
        'PLN': 'zł', // Polish Zloty
        'PYG': '₲', // Paraguayan Guarani
        'THB': '฿', // Thai Baht
        'UAH': '₴', // Ukrainian Hryvnia
        'VND': '₫' // Vietnamese Dong
    };
    return currency ? (currency_symbols[currency.toUpperCase()] || currency) : currency;
}

/**
 * Copy provided value to the clipboard (ctrl + c)
 *
 * @param value - value to copy
 */
export function copy(value) {
    try {
        return navigator.clipboard.writeText(value);
    } catch (e) { // we might not have navigator (unsecure domain) or writeText permission
        // fallback to execCommand
        return new Promise((resolve) => {
            // create text field with value, select, copy and remove it
            const textField = document.createElement('textarea');
            textField.innerText = value;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            // finish promise
            return resolve();
        });
    }
}

/**
 * Format date to show duration to now and full date with time in brackets
 *
 * @param {string} date - target date
 * @returns {string} - humanized date, e.g. '9 days [September 7. 2022 5:09 PM]'
 */
export function formatLastUpdate(date) {
    return date && `${(Moment.isMoment(date) ? date : Moment(date)).fromNow()} [${(Moment.isMoment(date) ? date : Moment(date)).format('l, LT')}]`;
}
