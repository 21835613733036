import React, {useMemo, useState} from 'react';
import {theme} from 'theme';
// Components
import {closestCorners, DndContext, DragOverlay, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {SortableContext, useSortable, verticalListSortingStrategy, horizontalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';


/**
 * Item for SortableContainer
 */
function SortableItem(props) {
    const {id, Item, disabled, itemProps = {}} = props;
    const {attributes, isDragging, listeners, setNodeRef, transform, transition} = useSortable({
        id: id, disabled: disabled, animateLayoutChanges: () => {}
    });

    return <Item
        {...itemProps}
        style={{
            ...(isDragging ? {opacity: '0.5'} : {}),
            transform: CSS.Transform.toString(transform),
            transition: transition
        }}
        ref={setNodeRef}
        {...attributes} {...listeners}
    />;
}

/**
 * Integration of @dnd-kit for drag sorting
 */
export default function SortableContainer(props) {
    const {items, Item, disabled = false, delay = false, vertical = true,
        getItemProps = (item_value) => {}, onDragStart = () => {},
        onDragEnd = () => {}} = props;
    const sensors = useSensors(
        useSensor(PointerSensor, delay ? {
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        } : undefined)
    );
    const [activeItem, setActiveItem] = useState(null);
    const activeItemProps = useMemo(() => {
        return activeItem ? getItemProps(activeItem) : {};
    }, [activeItem]);

    return <DndContext sensors={sensors} collisionDetection={closestCorners}
                       onDragStart={(event) => {
                           setActiveItem(event.active.id);
                           onDragStart(event);
                       }}
                       onDragEnd={(event) => {
                           setActiveItem(null);
                           onDragEnd(event);
                       }}
                       onDragCancel={() => setActiveItem(null)}>
        <SortableContext items={items}
                         strategy={vertical
                             ? verticalListSortingStrategy
                             : horizontalListSortingStrategy}>
            {items.map(item => <SortableItem
                key={item} id={item} disabled={disabled}
                Item={Item} itemProps={getItemProps(item)}
            />)}
        </SortableContext>
        <DragOverlay dropAnimation={null} zIndex={theme.zIndex.tooltip}>
            {activeItem && <Item
                {...activeItemProps}
                className={[
                    activeItemProps.className || '',
                    'dragging'
                ].filter(Boolean).join(' ')}
            />}
        </DragOverlay>
    </DndContext>;
}
