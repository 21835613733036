import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
// icons
import Tier0 from '@material-ui/icons/CheckOutlined';
import Tier1 from '@material-ui/icons/FavoriteOutlined';
import Tier2 from '@material-ui/icons/ThumbUpOutlined';
import Tier3 from '@material-ui/icons/StarOutlined';


const useStyles = makeStyles(theme => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`
    }
}));

/**
 * Hook to obtain TierIcon and color
 */
export function useTierInfo(tierIndex) {
    // get Icon component and severity based on tierIndex
    return useMemo(() => {
        switch (tierIndex) {
            case 1:
                return [Tier1, theme.palette.secondary.main, theme.gradients.green.color1, theme.gradients.green.color2];
            case 2:
                return [Tier2, theme.gradients.orange.color2, theme.gradients.orange.color1, theme.gradients.orange.color2];
            case 3:
                return [Tier3, theme.gradients.wild.color2, theme.gradients.wild.color1, theme.gradients.wild.color2];
            default:
                return [Tier0, theme.palette.primary.main, theme.gradients.blue.color1, theme.gradients.blue.color2];
        }
    }, [tierIndex]);
}

/**
 * Hook to obtain calculatedPrice, activeTier and tierIndex
 */
export function useTierCalculation(used_devices = 0, tiers = []) {
    return useMemo(() => {
        // obtain activeTier
        const activeTier = tiers?.find(tier => !tier.get('up_to') || (used_devices || 0) <= tier.get('up_to'));
        return [
            (used_devices || 0) * ((activeTier?.get('unit_amount') || 0) * 1000) / 1000,
            activeTier,
            tiers?.findIndex(tier => tier.get('up_to') === activeTier.get('up_to'))
        ];
    }, [used_devices, tiers && JSON.stringify(tiers)]);
}

/**
 * Show tier label with icon, e.g. '✓ Basic Price'
 */
export default function TierLabel(props) {
    const {tierIndex = 0} = props;
    const classes = useStyles();
    const [TierIcon, color] = useTierInfo(tierIndex);

    return tierIndex <= 3 && <div className={classes.label}>
        <TierIcon style={{color: color}} />
        <FormattedMessage id={`subscription.tier.label.${tierIndex}`} />
    </div>;
}
