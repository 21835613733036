import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Company} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import StatusSignedUpIcon from '@material-ui/icons/NewReleasesOutlined';
import StatusVerifiedIcon from '@material-ui/icons/CheckOutlined';
import StatusSigPendingIcon from '@material-ui/icons/MoreHorizOutlined';
import StatusApprovedIcon from '@material-ui/icons/DoneAllOutlined';
import StatusDeclinedIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    approvalStatusIcon: {
        // variants
        '&.approved': {
            color: theme.palette.success[500]
        },
        '&.declined': {
            color: theme.palette.danger[500]
        }
    }
}));

/**
 * Render Company Approval Status Icon
 *
 * Props:
 *  identifier - Approval status, e.g. 'approved'
 */
export function ApprovalStatusIcon(props) {
    const {identifier} = props;
    const classes = useStyles();

    let IconElement;
    switch (identifier) {
        case 'signed_up':
            IconElement = StatusSignedUpIcon;
            break;
        case 'verified':
            IconElement = StatusVerifiedIcon;
            break;
        case 'signature_pending':
            IconElement = StatusSigPendingIcon;
            break;
        case 'approved':
            IconElement = StatusApprovedIcon;
            break;
        case 'declined':
            IconElement = StatusDeclinedIcon;
            break;
        default:
            return <span />;
    }

    return <IconElement className={`${classes.approvalStatusIcon} ${identifier}`} />;
}

/**
 * Select field for Company approval status with Icon and Text
 */
export default function CompanyStatusField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'companystatusfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Company().getStatuses().map((identifier, idx) =>
            <MenuItem value={identifier} key={idx}>
                <ListItemIcon className='icon'><ApprovalStatusIcon identifier={identifier} /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id={`companystatusfield.choice.${identifier}`} /></ListItemText>
            </MenuItem>
        )}
    </Field>;
}

