import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment';
import DatePicker from 'components/core/ui/DatePicker';
// Components
import {SearchField} from 'components/core/ui/Field';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// material-ui
import Popper from '@material-ui/core/Popper';


const useStyles = makeStyles(theme => ({
    // in order to make ClickAwayListener work, we need to wrap functional component in simple div
    rootHolder: {
        // make sure not to overflow and behave like field
        display: 'inline-flex',
        maxWidth: '100%'
    }
}));

/**
 * Enhanced Search Field with Dates handling, perfect for date filters
 */
export default function DateSearchField(props) {
    const {search, value, ...rest_of_props} = props;
    const classes = useStyles();
    // process date
    const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    const humanDateFormat = 'l';
    const momentValue = value && Moment(value, dateFormat, true).isValid() ? Moment(value) : null;
    // local state
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={classes.rootHolder}>
            <SearchField
                onFocus={(e) => {
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                }}
                postChildren={() => <DatePicker
                    onChange={(value) => {
                        // change value and close
                        search(value.format(dateFormat));
                        setOpen(false);
                    }}
                    selected={momentValue} dateFormat={humanDateFormat} showTimeSelect={false}
                    inline fixedHeight disabledKeyboardNavigation
                    calendarContainer={props => <Popper
                        open={open} anchorEl={anchorEl} placement='bottom-start'
                        modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}, hide: {enabled: false}}}>
                        <div className={props.className}>
                            {props.children}
                        </div>
                        <div className='react-datepicker-popper' data-placement='bottom-start' style={{marginTop: 0}}>
                            <div className='react-datepicker__triangle' />
                        </div>
                    </Popper>}
                    customInput={<input type='hidden' />}
                />}
                errorValidation={(searchValue) => !!searchValue && !Moment(searchValue, humanDateFormat, true).isValid()}
                value={momentValue ? momentValue.format(humanDateFormat) : value}
                search={(searchValue) => {
                    if (!searchValue) {
                        // reset
                        search('');
                    } else if (Moment(searchValue, humanDateFormat, true).isValid()) {
                        // only if valid actually change
                        search(Moment(searchValue).format(dateFormat));
                    }
                }}
                searchIcon={false}
                {...rest_of_props}
            />
        </div>
    </ClickAwayListener>;
}
