import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {AssetFolder} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// components
import FolderActionButton from 'components/modules/assets/FolderActionButton';
import Checkbox from 'components/core/ui/mui/Checkbox';
// material-ui
import {alpha} from '@material-ui/core/styles/colorManipulator';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import AddIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';


const useStyles = makeStyles(theme => ({
    childList: {
        paddingLeft: `${theme.spacing(1.75)}px`,
        overflow: 'hidden'
    },
    listItem: {
        marginBottom: `${theme.spacing(0.5)}px`,
        paddingLeft: `${theme.spacing(1)}px`,
        backgroundColor: theme.palette.snomGrey[100],
        color: theme.palette.snomGrey[800],
        '&, & .MuiSvgIcon-root': {
            transition: theme.transitions.create(['background', 'color', 'transform'],
                {duration: theme.transitions.duration.short})
        },
        '&.addFolder': {
            '& .MuiListItemText-primary': {
                fontWeight: theme.typography.h6.fontWeight
            },
            '& .MuiListItemIcon-root': {
                color: theme.palette.snomGrey[800]
            },
            '&:hover .MuiListItemIcon-root': {
                color: theme.palette.secondary.main
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: `${theme.spacing(2)}px`,
            color: theme.palette.snomGrey[400],
            alignItems: 'center',
            '& .collapseIcon, & .addIcon': {
                color: theme.palette.snomGrey[800]
            }
        },
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.light, 0.2),
            '&, & .MuiListItemIcon-root': {
                color: theme.palette.secondary.main
            }
        },
        '&:hover, &:hover .MuiListItemIcon-root': {
            color: theme.palette.secondary.main
        }
    },
    listItemSecondaryAction: {
        right: `${theme.spacing(1)}px`
    },
    loaderItem: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        '& .MuiLinearProgress-root': {
            width: '100%'
        }
    },
    collapseButton: {
        marginTop: '-1px',
        marginBottom: '-1px',
        // rotate icon once the folder is opened
        '& .MuiSvgIcon-root.active': {
            transform: 'rotate(90deg)'
        }
    }
}));

/**
 * Add Folder menu item
 */
export function AddFolderMenuItem() {
    const history = useHistory();
    const match = useRouteMatch();
    const classes = useStyles();

return <ListItem className={`${classes.listItem} addFolder`} disableGutters dense button
    onClick={() => history.push(`/asset-manager/${match.params.identifier ? `${match.params.identifier}/add` : 'add'}`)}>
        <ListItemIcon><AddIcon /></ListItemIcon>
        <ListItemText primary={<FormattedMessage id='assets.list.menu.add_folder' />} />
    </ListItem>;
};

/**
 * Folder menu item rendered in list
 * @param {object} props - props
 * @param {AssetFolder} props.folder - asset folder item
 * @param {string} props.parentPath - parent path of the folder
 * @param {boolean} props.isRoot - root path flag
 * @param {boolean} props.editable - allow edit flag
 * @param {boolean} props.disabled - disabled flag
 * @param {string} props.targetFolder - selected folder to manipulate
 * @param {string} props.value - pathname value
 * @param {string} props.initialValue - initial pathname value
 * @param {(value: string) => void} props.onChange - on change pathname 
 * @param {() => void} props.expandParent - expand parent folder 
 * @param {boolean} props.unmountOnExit - unmount on collapse child folders
 */
export default function FolderMenuItem(props) {
    const {folder, parentPath, isRoot, unmountOnExit = true, expandParent,
        editable = true, disabled, value = '', onChange} = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const asset_folders_placement = useMemo(() => new AssetFolder().getPlacement(), []);
    const asset_folders_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);
    const folderId = useMemo(() => folder?.get(asset_folders_identifier) || '', [folder?.get(asset_folders_identifier)]);
    // highlight and disable target folder
    const isTargetFolder = useMemo(() => props.targetFolder === folderId,
        [props.targetFolder, folderId]);
    // construct path for the current folder
    const currentPath = useMemo(() => `${parentPath ? `${parentPath}_` : ''}${folderId}`,
        [parentPath, isRoot, folderId]);
    const isInRoute = useMemo(() => value.includes(currentPath) || props.initialValue?.includes(currentPath), [value, currentPath, props.initialValue]);
    const isExactRoute = useMemo(() => value === (value.includes('_') ? currentPath : folderId), [value, currentPath, folderId]);
    const isInitialValue = useMemo(() => !!props.initialValue && props.initialValue === (props.initialValue.includes('_') ? currentPath : folderId), [props.initialValue, currentPath, folderId]);
    const isEditable = useMemo(() => !props.select && editable && props.permission === 'RW', [props.select, editable, props.permission]);
    // collapse local state
    const [collapsed, setCollapsed] = useState(isInRoute || isInitialValue);
    const [expandIcon, setExpandIcon] = useState(collapsed);
    const listItemRef = useRef(null);
    // select child folders
    const {asset_folders_items, asset_folders_loaded, asset_folders_loading} = useSelector(state => {
        const currentFolderPlacement = folderId ? `${asset_folders_placement}-${folderId}` : asset_folders_placement;
        const items = state.shared.getIn(['items', currentFolderPlacement]);
        const items_loaded = state.shared.getIn(['loaded', currentFolderPlacement]);

        return {
            asset_folders_loading: !items?.size && !items_loaded,
            asset_folders_loaded: items_loaded,
            asset_folders_items: items
        };
    }, shallowEqual);

    const collapseIn = useMemo(() => {
        if (isTargetFolder) {
            return false;
        }

        return asset_folders_items?.size ? collapsed : isExactRoute;
    }, [asset_folders_items?.size, collapsed, isExactRoute, isTargetFolder]);

    // fetch child folders
    useEffect(() => {
        if (!asset_folders_loaded) {
            dispatch(fetchItems(
                AssetFolder, folderId ? `${asset_folders_placement}-${folderId}` : asset_folders_placement,
                folder?.getIn(['links', 'folders']) || 'folders', null, null, {affect_state: !props.select}));
        }
    }, [asset_folders_loaded, folderId, props.select]);
    useEffect(() => {
        if (isExactRoute) {
            setCollapsed(true);
        }
    }, [isExactRoute]);
    // expand icon after rendering to keep it's animation on change route 
    useEffect(() => {
        setExpandIcon(isTargetFolder ? false : collapsed);
    }, [collapsed, isTargetFolder]);
    // forcedly open all collapses up to current folder
    useLayoutEffect(() => {
        if (history.location.state?.forceOpen && isInRoute && !isExactRoute) {
            setCollapsed(true);
        }
    }, [history.location.state?.forceOpen]);
    // scroll to current folder on force open
    useEffect(() => {
        if ((history.location.state?.forceOpen || props.targetFolder) && isExactRoute) {
            listItemRef.current?.scrollIntoView({block: 'center', behavior: 'smooth'});
        }
    }, [history.location.state?.forceOpen, isTargetFolder]);
    useEffect(() => {
        if (isInitialValue) {
            expandParent?.();
        }
    }, [isInitialValue]);

    return <React.Fragment>
        {asset_folders_loading ? <ListItem className={`${classes.loaderItem} ${classes.listItem}`}>
            <LinearProgress />
        </ListItem>
        : <ListItem selected={isTargetFolder || isExactRoute} disabled={isTargetFolder}
            disableGutters dense button className={classes.listItem} innerRef={listItemRef}
            onClick={() => {
                // do not collapse on select parent folder
                if (props.select || isExactRoute || (!isInRoute && !collapsed)) {
                    setCollapsed(!collapsed);
                }
                if (!props.select) {
                    onChange?.(currentPath, {isExactRoute, isInRoute, isRoot});
                }
            }}>
            <ListItemIcon>
                {asset_folders_items?.size ? <IconButton size='small' edge='start'
                    className={classes.collapseButton} disabled={isTargetFolder}
                    // allow collapse without redirect
                    onClick={(e) => {
                        e.stopPropagation();
                        setCollapsed(!collapsed);
                    }}
                    onMouseDown={e => e.stopPropagation()}>
                    <CollapseIcon className={`collapseIcon ${expandIcon ? 'active' : ''}`} /> 
                </IconButton> : null}
                <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={folder?.get('name') || <FormattedMessage id='assets.list.menu.root' />}
                primaryTypographyProps={{noWrap: true}} />
            {isExactRoute && isEditable && <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                <FolderActionButton />
            </ListItemSecondaryAction>}
            {props.select && !isTargetFolder && <ListItemSecondaryAction>
                <Checkbox disabled={disabled || isInitialValue} checked={value === folderId || isExactRoute} onChange={() => onChange?.((value === folderId || isExactRoute) ? '' : currentPath)} />
            </ListItemSecondaryAction>}
        </ListItem>}
        <Collapse in={collapseIn} timeout='auto' unmountOnExit={unmountOnExit}>
            <List disablePadding className={classes.childList}>
                {asset_folders_items?.map((childFolder) => (
                    <FolderMenuItem {...props} key={childFolder?.get(asset_folders_identifier)} expandParent={() => {
                        setCollapsed(true);
                        expandParent?.();
                    }}
                        isRoot={!folder} folder={childFolder} parentPath={currentPath} />))}
                {isExactRoute && isEditable && <AddFolderMenuItem />}
            </List>
        </Collapse>
    </React.Fragment>;
}
