import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {reduxForm} from 'redux-form';
import {FormattedMessage, injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import validator from 'lib/valitator';
import {AssetFolder} from 'lib/models';
// actions
import {setState} from 'actions/app';
import {saveItem, fetchItems, setCollection} from 'actions/shared';
// components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import Button from 'components/core/ui/mui/Button';
import AssetFolderSelect from 'components/core/ui/fields/AssetFolderSelectField';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// material ui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';


const useFormStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        margin: 0
    },
    textField: {
        width: '100%',
        margin: `0 0 ${theme.spacing(1)}px`
    }
}));

/**
 * Render unmounted detail form
 */
function DetailForm(props) {
    const classes = useFormStyles();

    return <React.Fragment>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id={`assets.list.${props.edit || 'add'}_folder.dialog.subtitle`} />
            </DialogContentText>
            <Form className={classes.form} onSubmit={props.handleSubmit}>
                {props.edit !== 'move'
                    ? <Field name='name' fieldType='TextField' autoFocus className={classes.textField}
                        label={`${props.intl.formatMessage({id: 'assets.list.add_folder.dialog.form.name.label'})}*`} />
                    : <AssetFolderSelect name='parent_uuid' targetFolder={props.folderId} />}
            </Form>
        </DialogContent>
        {['saving_item_asset_folders', 'failed_save_item_asset_folders', 'saved_item_asset_folders', 'fetching_items_asset_folders']
            .some((state) => props.state?.match(state))  || (props.edit && !props.folder)
            ? <CardActionsLoader dialogActions
                    failure={props.state?.match('failed_save_item_asset_folders')}
                    success={props.state?.match('saved_item_asset_folders')}
                    postAnimation={(success) => !success ? props.state && props.setState(null) : {}} />
            : <DialogActions>
                <Button onClick={props.onClose}  onMouseDown={(e) => e.preventDefault()}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button variant='contained' color='primary' type='submit' onClick={props.handleSubmit} startIcon={<SaveIcon/>}>
                    <FormattedMessage id='actions.save' />
                </Button>
            </DialogActions>}
    </React.Fragment>;
}

const validate = (data, props) => {
    const errors = {};
    validator.isNotNull(null, errors, 'name', data.name);
    validator.equalsNot(
        props.intl.formatMessage({id: 'assets.list.move_folder.dialog.form.parent_uuid.error.already_placed'}), errors, 'parent_uuid',
        data.parent_uuid, props.initialValues?.parent_uuid
    );

    return errors;
};

const DetailReduxForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        const {folder, edit, folderId, parentId, history, location, match,
            asset_folders_placement, asset_folders_identifier} = props;
        const link = edit ? folder?.getIn(['links', 'self']) : folder?.getIn(['links', 'folders']);
        const defaultUrl = 'folders';
        const placement = folderId ? `${asset_folders_placement}-${folderId}` : asset_folders_placement;
        const formData = new AssetFolder().popUpFields(values);
        const folderPath = values.parent_uuid?.split('_').slice(-2) || null;

        if (edit === 'move') {
            // set new parent id
            formData.parent_uuid = folderPath?.[folderPath.length === 1 ? 0 : 1];
        }

        return props.saveItem(AssetFolder, placement, link || defaultUrl,
            formData, undefined, edit ? {save_method: 'put'} : undefined)
        // post save actions
        .then(() => {
            let placement = asset_folders_placement;
            let url = defaultUrl;

            switch (edit) {
                case 'rename': {
                    placement = parentId ? `${asset_folders_placement}-${parentId}` : asset_folders_placement;
                    url = parentId && folder ? `${folder.getIn (['links', 'root-folders'])}${parentId}/folders/` : defaultUrl;
                    break;
                }
                case 'move': {
                    const newParentId = folderPath.length === 1 ? folderPath[0] : folderPath[1];
                    placement = newParentId ? `${asset_folders_placement}-${newParentId}` : asset_folders_placement;
                    url = newParentId && folder ? `${folder.getIn (['links', 'root-folders'])}${newParentId}/folders/` : defaultUrl;
                    break;
                }
                // add
                default: {
                    placement = folderId ? `${asset_folders_placement}-${folderId}` : asset_folders_placement;
                    url = folderId && folder ? folder.getIn(['links', 'folders']) : defaultUrl;
                }
            }

            return props.fetchItems(AssetFolder, placement, url);
        }).then(({data}) => {
            switch (edit) {
                case 'rename': {
                    history.push(history.location.pathname.replace('/edit', ''));
                    break;
                }
                case 'move': {
                    const placement = parentId ? `${asset_folders_placement}-${parentId}` : asset_folders_placement;
                    props.setCollection(AssetFolder, placement, props.parentsFolders?.filter((childFolder) => childFolder.get(asset_folders_identifier) !== folderId));
                    history.push(`/asset-manager/${values.parent_uuid ? `${values.parent_uuid}_${folderId}` : folderId}`, {forceOpen: true});
                    break;
                }
                // add
                default: {
                    const folder_path = location.state?.folderPath || match.params.identifier;
                    const asset_folders_identifier = new AssetFolder().getUniqueIdentifier();
                    const newFolderId = data.find(childFolder => childFolder.name === values.name)?.[asset_folders_identifier];
                    history.push(`/asset-manager/${folder_path ? `${folder_path}_${newFolderId}` : newFolderId}`, {forceOpen: true});
                }
            }
        });
    }
})(DetailForm);

const ConnectedDetail = connect((state, props) => {
    const {edit, match, location} = props;
    const folderPath = location.state?.folderPath || match.params.identifier;
    const folderIds = folderPath?.split('_').slice(-2);
    const asset_folders_placement = new AssetFolder().getPlacement();
    const asset_folders_identifier = new AssetFolder().getUniqueIdentifier();
    // undefined id - root folder
    const [parentId, folderId] = folderIds ? folderIds.length === 1 ? [undefined, folderIds[0]] : folderIds : [];
    const parentsFolders = state.shared.get('items')
        .get(parentId ? `${asset_folders_placement}-${parentId}` : asset_folders_placement);
    const folder = parentsFolders?.find((childFolder) => childFolder.get(asset_folders_identifier) === folderId);
    const initialValues = edit ? folder?.toJS() : undefined;

    if (edit === 'move' && initialValues) {
        const path = folderPath?.split('_');
        path?.splice(-1);
        initialValues.parent_uuid = path?.join('_');
    }

    return {
        state: state.app.get('state'),
        form: edit ? `${edit}_folder_${folderId}` : 'add_folder',
        initialValues,
        folder, folderId, parentId, validate, parentsFolders,
        asset_folders_placement, asset_folders_identifier
    };
}, (dispatch) => bindActionCreators({
    saveItem, fetchItems, setState, setCollection
}, dispatch))(DetailReduxForm);

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        // set default
        display: 'block',
        width: '570px',
        padding: `${theme.spacing(6)}px ${theme.spacing(9)}px ${theme.spacing(6)}px`,

        '& .MuiDialogTitle-root': {
            padding: 0,
            marginBottom: `${theme.spacing(1)}px`
        },
        '& .MuiDialogContent-root': {
            padding: 0,
            marginBottom: `${theme.spacing(5)}px`,
            '& .MuiDialogContentText-root': {
                marginBottom: theme.spacing(7)
            }
        },
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`
        }
    }
}));

/**
 * Detail form to manipulate folders
 */
function Detail(props) {
    const {edit, open, history} = props;
    const classes = useStyles();

    const onClose = useCallback(() => {
        history.push(history.location.pathname.replace(/\/(add|edit|move)/, ''));
    }, [history.location.pathname]);

    return <Dialog maxWidth='sm' open={open} onClose={onClose} classes={{paper: classes.dialogPaper}}>
        <DialogTitle>
            <FormattedMessage id={`assets.list.${edit || 'add'}_folder.dialog.title`} />
        </DialogTitle>
        <ConnectedDetail {...props} onClose={onClose} />
    </Dialog>;
}

export default withRouter(injectIntl(Detail));
