import storage from 'store';
import {constants} from 'reducers/auth';
import {SubmissionError} from 'redux-form';
import {handleErrors, getErrorCode} from 'actions/errors';
import {addMessage, removeMessage} from 'actions/app';
import {fetchItem, setCollection, updatePaginator, setLocalState, fetchItems} from 'actions/shared';
import {Company, FeatureFlag, Role, SalesCluster} from 'lib/models';
import {evaluateFeatureFlag} from 'lib/featureFlag';


/**
 * Simple action creator to modify auth state
 *
 * @param {string|null} state - e.g. 'ready', 'fetching', 'error'
 */
export function setState(state) {
    return {
        type: constants.AUTH_STATE,
        state
    };
}

/**
 * Simple action creator to add/update User in store
 *
 * @param user - raw User data or null (set's empty user)
 */
export function setUser(user) {
    return {
        type: constants.AUTH_USER,
        user
    };
}

/**
 * Simple action creator to add/update User Role Permissions in store
 *
 * @param role - raw Role data or null (set's null permissions)
 */
export function setPermissions(role) {
    return {
        type: constants.AUTH_PERMISSIONS,
        role
    };
}

/**
 * Set current used Permission to store
 */
export function setPermission(permission) {
    return {
        type: constants.AUTH_PERMISSION,
        permission
    };
}

/**
 * Simple action creator to add/update company Subscription
 *
 * @param subscription - raw Subscription data or null (removes subscription)
 */
export function setSubscription(subscription) {
    return {
        type: constants.AUTH_SUBSCRIPTION,
        subscription
    };
}

/**
 * Simple action creator to set/remove Invitation in store
 *
 * @param invitation - raw Invitation data or null (removes invitation)
 */
export function setInvitation(invitation) {
    return {
        type: constants.AUTH_INVITATION,
        invitation
    };
}

/**
 * Function to process Login -> not just from Login, but also from Registration, Forgotten Password, etc.
 *
 * @param result - result from request which will sign user, like 'tokens'
 */
export function loginProcedure(result) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // get values from result
        const key = result.data.token.api_key;
        const secret = result.data.token.secret;
        const account_link = result.data.links.user;
        const token_link = result.data.links.self;
        const admin_access = !!result.data.admin_access;

        // Save data to storage
        storage.set(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`,
            {key, secret, account_link, token_link, admin_access});

        // Authorize the client
        client.authorize(key, secret);

        // now obtain User
        return client.get(account_link).then(result => {
            // add admin_access to result data
            return dispatch(setUser({admin_access: admin_access, ...result.data}));
        }).then(() => {
            return dispatch(postLoginActions());
        }).then(() => {
            // we are logged
            return dispatch(setState('logged'));
        }).catch(error => {
            return handleErrors('loginProcedure', dispatch, getState, error, null);
        });
    };
}

/**
 * Try to login user from store, loadLocalState
 */
export function loginFromStore() {
    return (dispatch, getState) => {
        // get data from storage
        const storage_data = storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`);
        if (!storage_data || !storage_data.key || !storage_data.secret || !storage_data.account_link || !storage_data.token_link) {
            storage.remove(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`);
            // we don't want to continue
            return;
        }

        // alright, let's try to log user
        dispatch(setState('logging'));

        // Authorize the client
        const client = getState().api.get('client');
        client.authorize(storage_data.key, storage_data.secret);

        // now obtain User
        return client.get(storage_data.account_link).then(result => {
            // add admin_access to result data
            return dispatch(setUser({admin_access: (storage_data.admin_access && (storage_data.admin_access === true || storage_data.admin_access === 'true')), ...result.data}));
        }).then(() => {
            return dispatch(postLoginActions());
        }).then(() => {
            // we are logged
            return dispatch(setState('logged'));
        }).catch(error => {
            dispatch(setState(null));
            const error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                case 401:
                case 403:
                    // de-authorize client
                    client.deauthorize();
                    // clear old storage
                    storage.remove(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`);
                    return;
            }
            return handleErrors('loginFromStore', dispatch, getState, error, error_code);
        });
    };
}

/**
 * After login in User (with loginProcedure or loginFromStore) perform some actions
 */
export function postLoginActions() {
    return (dispatch, getState) => {
        const state = getState();
        const client = state.api.get('client');
        const user = state.auth.get('user');

        // we need User Company
        return dispatch(fetchItem(Company, 'companies', user.getIn(['links', 'company']))).then(company => {
            // device manager (subscription) feature flag and link do subscription
            if (evaluateFeatureFlag(state.shared.getIn(['items', 'feature-flags'])?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'device_manager'),
                {company: company.get(new Company().getUniqueIdentifier())}) && company.get('has_subscription')) {
                // fetch Subscription
                return client.get(company.getIn(['links', 'subscription']));
            } else {
                // just skip
                return Promise.resolve();
            }
        }).then(result => {
            if (result) {
                // set to store
                dispatch(setSubscription(result.data));
            }
            // handle Permissions
            const user_role_link = user.getIn(['links', 'role']);
            // do we have link
            if (user_role_link) {
                // fetch User Role
                return client.get(user_role_link);
            } else {
                // just skip
                return new Promise(resolve => resolve({data: {permissions: {}}}));
            }
        }).then(result => {
            // prepare object with everything RW
            const rwPermissions = Object.fromEntries(Object.keys(new Role().getPermissionsMapping()).map(permission => [permission, 'rw']));
            // merge with result
            const permissions = {...rwPermissions, ...result.data.permissions};
            // set to store
            return dispatch(setPermissions({
                ...result.data, permissions: permissions
            }));
        })
        // fetch all Sales Clusters
        .then(() => dispatch(fetchItems(SalesCluster, new SalesCluster().getPlacement(), 'sales-clusters')))
        .then(() => {
            // make sure that we don't have any messages left
            return dispatch(removeMessage(null));
        });
    };
}

/**
 * Login user from provided data
 *
 * @param data - Data containing username and password
 */
export function login(data) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        dispatch(setState('logging'));
        return client.post('tokens', data).then(result => {
            return dispatch(loginProcedure(result));
        }).catch(error => {
            dispatch(setState('login_failed')); // trigger failure animation, loader will reset state
            const error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    // display field errors
                    throw new SubmissionError(error.response.data.details);
                case 401:
                case 403:
                    if (error.response.data.code === 'not_approved') {
                        throw new SubmissionError({_error: 'not_approved'});
                    } else {
                        throw new SubmissionError({_error: 'invalid_credentials'});
                    }
            }
            return handleErrors('login', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Clear all stored stuff from Store
 */
export function clearStore() {
    return (dispatch, getState) => {
        const state = getState();
        const client = state.api.get('client');

        // de-authorize client
        client.deauthorize();
        // clear storage
        storage.remove(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`);
        // clear auth user and permissions
        dispatch(setUser(null));
        dispatch(setPermissions(null));
        dispatch(setSubscription(null));
        // clear fetched stuff which can vary for other User
        dispatch(setCollection(null, null, null));
        // clear paginators
        dispatch(updatePaginator(null, null));
        // clear local state of components
        dispatch(setLocalState(null, null));
        // clear messages and notifications
        dispatch(removeMessage(null));
        dispatch(removeMessage(null, true));
    };
}

/**
 * Logout user - clear token, store, user
 *
 * @param portal - Logout from Portal
 */
export function logout(portal = false) {
    return (dispatch, getState) => {
        const state = getState();
        const client = state.api.get('client');

        // ok, start logging out
        dispatch(setState('logging_out'));

        // get data from storage
        const storage_data = storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}`);
        // we already have removed storage = logout directly without api call
        if (!storage_data || !storage_data.token_link) {
            // clear store
            dispatch(clearStore());
            // done
            dispatch(setState(null));
            // inform user about what we have done (expired message instead of success)
            return dispatch(addMessage({intl_id: 'logout.expired', type: 'info', path: portal ? '/partner' : '/', strict: true}));
        }

        // actually delete token from backend
        return client.delete(storage_data.token_link).then(() => {
            // clear store
            dispatch(clearStore());
            // done
            dispatch(setState(null));
            // inform user about what we have done
            return dispatch(addMessage({intl_id: 'logout.success', type: 'info', path: portal ? '/partner' : '/', strict: true}));
        }).catch(error => {
            dispatch(setState(null));
            return handleErrors('logout', dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch Invitation from server to display basic information to User so he can registerViaInvitation
 *
 * @param data - Data containing token
 * @param portal - Portal invitation
 */
export function fetchInvitation(data, portal = false) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        dispatch(setState('fetching_invitation'));
        return client.get('invitation', data).then(result => {
            dispatch(setState(null));
            return dispatch(setInvitation(result.data));
        }).catch(error => {
            dispatch(setState(null));
            const error_code = getErrorCode(error);
            switch (error_code) {
                // invalid token
                case 404:
                    dispatch(setState('invalid_token'));
                    return dispatch(addMessage({intl_id: 'invitation.error.invalid_token', type: 'error', path: portal ? '/partner' : '/', strict: true}));
            }
            return handleErrors('fetchInvitation', dispatch, getState, error, error_code);
        });
    };
}
