import React, {useCallback, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {TicketGroup} from 'lib/models';
// actions
import {setState} from 'actions/app';
import {saveItem} from 'actions/shared';
// components
import UpgradeConfirmationForm from 'components/modules/tickets/dm/DMUpgradeForm';
// material-ui
import Button from 'components/core/ui/mui/Button';
import {TypeIcon} from 'components/core/ui/fields/TicketTypeField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// icons
import ConfirmIcon from '@material-ui/icons/CheckOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    button: {
        // recolor label to slightly distinct it from text
        color: theme.palette.snomGrey[900]
    }
}));

/**
 * Component rendering button with action
 */
export default function DMActionButton(passed_props) {
    const {action, disabled = false, Model, placement, item, icon, label,
        className, ...rest_of_props} = passed_props;
    const classes = useStyles();
    const buttonClasses = [
        classes.button, className
    ].filter(Boolean).join(' ');
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);
    // local state
    const [open, setOpen] = useState(false); // flag for dialog open/close state
    const [confirmTitle, confirmText] = useMemo(() => {
        switch (action) {
            case 'operate_factory_reset':
                return [
                    'devicemanagement.type.operate_factory_reset.confirm.title',
                    'devicemanagement.type.operate_factory_reset.confirm.text'
                ];
            case 'upgrade':
                return ['devicemanagement.type.upgrade.confirm.title'];
            default:
                return [null, null];
        }
    }, [action]);

    // create new ticket with requested action
    const createTicket = useCallback((action, Model, placement, item, params = {}) => {
        setOpen(false);
        dispatch(saveItem(Model, placement,
            Model === TicketGroup ? item.getIn(['links', 'ticket_groups']) : item.getIn(['links', 'tickets']),
            {type: action, params}, null,
            {state_name: `${placement}_${action}`})).then(() => setOpen(false));
    }, []);

    return <React.Fragment>
        <Button
            className={buttonClasses}
            disabled={props.state !== null || disabled}
            loading={[`saving_item_${placement}_${action}`, `saved_item_${placement}_${action}`, `failed_save_item_${placement}_${action}`].includes(props.state)}
            success={props.state === `saved_item_${placement}_${action}`}
            failure={props.state === `failed_save_item_${placement}_${action}`}
            postAnimation={() => dispatch(setState(null))}
            onClick={() => confirmTitle
                ? setOpen(!open)
                : createTicket(action, Model, placement, item)
            } {...rest_of_props}>
            {icon || <TypeIcon identifier={action} />}
            {label || <FormattedMessage id={`tickettypefield.choices.${action}`} />}
        </Button>
        {confirmTitle && <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <FormattedMessage id={confirmTitle} />
            </DialogTitle>
            {confirmText && <DialogContent>
                <DialogContentText>
                    <FormattedMessage id={confirmText} />
                </DialogContentText>
            </DialogContent>}
            {action === 'upgrade'
                ? <UpgradeConfirmationForm
                    item={item}
                    onClose={() => setOpen(false)}
                    onSubmit={(data) => createTicket(action, Model, placement, item, data.params)}
                />
                : <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        <CancelIcon />
                        <FormattedMessage id='actions.cancel' />
                    </Button>
                    <Button variant='contained' color='primary'
                            onClick={() => createTicket(action, Model, placement, item)}>
                        <ConfirmIcon />
                        <FormattedMessage id='actions.confirm' />
                    </Button>
                </DialogActions>}
        </Dialog>}
    </React.Fragment>;
}
