import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// material-ui
import Typography from '@material-ui/core/Typography';
// vectors
import AddedIcon from '@material-ui/icons/AddOutlined';
import FixedIcon from '@material-ui/icons/DoneOutlined';
import ChangedIcon from '@material-ui/icons/Edit';
import RemovedIcon from '@material-ui/icons/Remove';
import DeprecatedIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles((theme) => ({
    container: {
        '& p.MuiTypography-h6': {
            fontSize: theme.typography.pxToRem(16)
        }
    },
    changelogVersion: {
        color: theme.palette.snomGrey.dark,
        marginBottom: `${theme.spacing(3)}px`
    },
    changeListTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: `${theme.spacing(2)}px`,
        '& .MuiTypography-h6': {
            lineHeight: theme.typography.pxToRem(22)
        },
        '&.added': {
            color: theme.palette.primary.main
        },
        '&.fixed': {
            color: theme.graphs[4]
        },
        '&.changed': {
            color: theme.palette.orange.main
        },
        '&.removed': {
            color: theme.palette.snomGrey.dark
        },
        '&.deprecated': {
            color: theme.palette.snomGrey[400]
        }
    },
    changelogDescription: {
        color: theme.palette.snomGrey.dark,
        marginBottom: `${theme.spacing(3)}px`
    },
    changeList: {
        margin: 0,
        paddingLeft: `${theme.spacing(7)}px`,
        '&:not(:last-of-type)': {
            marginBottom: `${theme.spacing(1)}px`
        },
        '& .MuiTypography-h6': {
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.h5.lineHeight,
            color: theme.palette.snomGrey.dark
        }
    }
}));

/**
 * Render list of changes
 * @param {object} props - props object
 * @param {object} props.item - changelog item (ReleaseNote or DevChangelog)
 * @param {boolean} props.newfont - use new font
 * @param {string} props.className - root container class name
 */
export default function ChangelogList(props) {
    const {newfont, item, className} = props;
    const classes = useStyles();

    const changelogListWithIcon = useMemo(() => [
        {icon: <AddedIcon />, type: 'added'}, {icon: <FixedIcon />, type: 'fixed'},
        {icon: <ChangedIcon />, type: 'changed'}, {icon: <RemovedIcon />, type: 'removed'},
        {icon: <DeprecatedIcon />, type: 'deprecated'}],
    []);

    return <div className={[classes.container, className].filter(Boolean).join(' ')}>
        {changelogListWithIcon.map(({type, icon}, index) => {
            const list = item.get(type);

            return list?.size ? <React.Fragment key={index}>
                <div className={`${classes.changeListTitle} ${type}`}>
                    {icon}
                    <Typography variant='h6' component='p' className={newfont ? 'newfont' : ''}><FormattedMessage id={`changelog.list.${type}.title`} /></Typography>
                </div>
                <ul className={classes.changeList}>
                    {list.map((changeItem, index) => <li key={index}>
                        <Typography variant='h6' component='p' className={newfont ? 'newfont' : ''}>{changeItem}</Typography>
                    </li>)}
                </ul>
            </React.Fragment> : null;
        })}
    </div>;
}
