import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {DocVersion} from 'lib/models';
// Actions
import {fetchItems, setLocalState} from 'actions/shared';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import BackIcon from '@material-ui/icons/ChevronLeftOutlined';


const useStyles = makeStyles(theme => ({
    // CardContent holding loader
    lastCardContent: {
        // always act like .MuiCardContent-root:last-child
        paddingBottom: `${theme.spacing(3)}px`
    },
    docErrorHider: {
        // hide documentation error
        '& ~ div': {
            display: 'none'
        }
    },
    // Menu above documentation (back to SRAPS)
    menuList: {
        // merge with documentation menu
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        paddingBottom: '0',
        // matching size
        width: `${280 + theme.spacing(1.5)}px`,
        // matching style
        backgroundColor: theme.palette.grey[100],
        // move with content
        position: 'fixed',
        zIndex: '10',
        top: '64px', // size of header
        // responsive (matching with <Documentation> small breakpoint
        [theme.breakpoints.down('sm')]: {
            // full size
            position: 'static',
            width: '100%',
            // to header
            paddingTop: '0'
        }
    },
    // MenuItem in extended menu
    menuItem: {
        // matching with Menu.js menuItem (44px height)
        padding: `6px ${theme.spacing(4)}px`,
        minHeight: 'auto',
        // get rid of disabled style
        opacity: '1 !important',
        // responsive
        [theme.breakpoints.down('sm')]: {
            display: 'none' // not needed since we have App menu on mobile
        }
    },
    menuItemIcon: {
        minWidth: '0', // get rid of not necessary min-width
        marginRight: `${theme.spacing(3)}px` // space between icon and text
    },
    // holder of button in Menu to switch documentation version
    versionButtonHolder: {
        // divider between menuItem
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: `${theme.spacing(1)}px`,
        paddingBottom: `${theme.spacing(1)}px`,
        marginTop: `${theme.spacing(1)}px`,
        // center button
        textAlign: 'center',
        // responsive
        [theme.breakpoints.down('sm')]: {
            marginTop: '0'
        }
    },
    versionButton: {
        '&, &:last-child': {
            margin: '0'
        }
    }
}));

/**
 * Handles errors, loading and other stuff that are not directly in <Documentation />.
 * ! Separated component to prevent loading loop of <Documentation /> with state update
 */
export default function Content() {
    const intl = useIntl();
    const classes = useStyles();
    // router
    const history = useHistory();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        docState: state.shared.getIn(['state', 'documentation']),
        docSelected: state.shared.getIn(['state', 'doc-selected']),
        loaded: state.shared.getIn(['loaded', 'doc-versions']),
        items: state.shared.getIn(['items', 'doc-versions'])
    }), shallowEqual);
    // local state
    const [versionMenuOpen, setVersionMenuOpen] = useState(false);
    const [versionMenuAnchor, setVersionMenuAnchor] = useState(null);

    /**
     * During initialization fetch Documentation links
     */
    useEffect(() => {
        // check if we need to load stuff
        if (props.loaded === false) {
            dispatch(setLocalState('loading', 'documentation'));
            dispatch(fetchItems(DocVersion, 'doc-versions', 'documentation')).then(() => {}).catch(() => {
                dispatch(setLocalState('error', 'documentation'));
            });
        }
        if (props.loaded && props.docState === 'loading') {
            // preselect local version if possible
            if (process.env.NODE_ENV === 'development') {
                try {
                    const local_url = 'openapi/openapi.yml';
                    dispatch(setLocalState(new DocVersion({version: 'local', url: require(`img/${local_url}`)}), 'doc-selected'));
                } catch (e) {
                    dispatch(setLocalState('error', 'documentation'));
                }
            // preselect latest version if possible
            } else if (props.items.first()) {
                dispatch(setLocalState(props.items.first(), 'doc-selected'));
            } else {
                dispatch(setLocalState('error', 'documentation'));
            }
        }
    }, [props.loaded]);

    return <React.Fragment>
        {props.loaded && <Menu
            open={versionMenuOpen}
            anchorEl={versionMenuAnchor}
            onClose={() => setVersionMenuOpen(false)}>
            {props.items.map((doc_version, idx) =>
                <MenuItem key={idx} onClick={() => {
                    dispatch(setLocalState(doc_version, 'doc-selected'));
                    setVersionMenuOpen(false);
                }}>
                    {`${doc_version.get('version')}${idx === 0
                        ? ` (${intl.formatMessage({id: 'documentation.list.version.latest'})})`
                        : ''}`}
                </MenuItem>
            )}
        </Menu>}
        {props.docState === 'loading' || props.state === 'fetching_items_doc-versions'
            ? <CardContent className={classes.lastCardContent}>
                <SpaceDivider loading />
                <LinearProgress />
                <SpaceDivider loading />
            </CardContent>
            : props.docState === 'error'
            ? <React.Fragment>
                <CardHeader title={<FormattedMessage id='documentation.list.error' />}
                            subheader={<FormattedMessage id='documentation.list.error.description' />} />
                <div className={classes.docErrorHider} />
            </React.Fragment>
            : <React.Fragment>
                <MenuList className={classes.menuList}>
                    <MenuItem button disabled={props.state !== null}
                              onClick={() => history.push('/home')}
                              _classes={{root: classes.menuItem, selected: 'selected'}}>
                        <ListItemIcon className={classes.menuItemIcon}>
                            <BackIcon />
                        </ListItemIcon>
                        <ListItemText primary={<FormattedMessage id='documentation.list.back' />} />
                    </MenuItem>
                    <div className={classes.versionButtonHolder}>
                        <ActionButton variant='outlined' color='primary'
                                      className={classes.versionButton}
                                      disabled={![null, 'error'].includes(props.docState) || !props.loaded}
                                      onClick={(event) => {
                                          setVersionMenuAnchor(event.currentTarget);
                                          setVersionMenuOpen(true);
                                      }}>
                            <ModelIcon model='documentation' />
                            <span>{(props.loaded && props.docSelected && props.items.size)
                                ? `${props.docSelected.get('version')}${props.items.first().get(new DocVersion().getUniqueIdentifier()) === props.docSelected.get(new DocVersion().getUniqueIdentifier())
                                    ? ` (${intl.formatMessage({id: 'documentation.list.version.latest'})})`
                                    : ''}`
                                : '---'}
                                </span>
                        </ActionButton>
                    </div>
                </MenuList>
            </React.Fragment>}
    </React.Fragment>;
}
