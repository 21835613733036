import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {ProductGroup} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItems, fetchItem, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * Renders detail of Product Group - ADD & EDIT
 */
function Detail(props) {
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItem(ProductGroup, 'product-groups', ['product-groups', props.match.params.identifier]);
        }
    }, [props.match.params.identifier]);

    return <div>
        {['fetching_item_product-groups', 'deleting_item_product-groups'].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='productgroups.detail.unknown.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='product_group' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='productgroups.detail.notfound.title' />}
                text={<FormattedMessage id='productgroups.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='productgroups.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='productgroups.detail.edit.title' />
                                : <FormattedMessage id='productgroups.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='product_group' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'productgroups.detail.form.fields.name'})}*`} />
                        <Field name='vpn' fieldType='Checkbox' size='regular'
                               label={<FormattedMessage id='productgroups.detail.form.fields.vpn' />}
                               helperText={<FormattedMessage id='productgroups.detail.form.fields.vpn.help' />} />
                        <SpaceDivider />
                        <Field name='description' fieldType='TextArea' label={<FormattedMessage id='productgroups.detail.form.fields.description' />} />
                    </Form>
                </CardContent>
                {['saving_item_product-groups', 'saved_item_product-groups', 'failed_save_item_product-groups'].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_product-groups'}
                                         failure={props.state === 'failed_save_item_product-groups'}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/product-families')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <React.Fragment>
                            {edit &&
                                <Button variant='contained' color='secondary' disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>
                            }
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'productgroups.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'productgroups.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(ProductGroup, 'product-groups', props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'productgroups.detail.delete.fail'}).then(result => {
                                if (result !== false) {
                                    props.history.push('/product-families');
                                }
                            });
                    }}
                />}
            </Card>
        </div>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);

    return errors;
};

const DetailForm = reduxForm({
    form: 'productGroupForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new ProductGroup().popUpFields(props.formValues);

        return dispatch(saveItem(ProductGroup, 'product-groups', props.item ? props.item.getIn(['links', 'self']) : 'product-groups', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new ProductGroup().getUniqueIdentifier())) {
                props.history.push(`/product-families/${create_data.get(new ProductGroup().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const item = state.shared.getIn(['items', 'product-groups']).find(el => el.get(new ProductGroup().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        item: item,
        initialValues: props.match.params.identifier
            ? item ? item.toJS() : undefined
            : {vpn: false},
        formValues: getFormValues('productGroupForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    fetchItem,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
