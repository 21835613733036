import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// Components
import Sticky from 'react-sticky-el';
import {ScrollSync, ScrollSyncPane} from 'react-scroll-sync';


const useStyles = makeStyles(theme => ({
    stickyContainer: {
        position: 'relative',
        // sticky element
        '& > div > div': {
            zIndex: '500',
            // original translateZ(0px) causes invisible border
            transform: 'none !important'
        }
    }
}));

/**
 * Integration of react-sticky, sticky header for table.
 *
 * Usage:
 *  <StickyTable
 *      header={<Table><TableHead>...</TableHead></Table>}
 *      content={<Table>....</Table>}
 *      footer={<Table><Pagination /></Table>} />
 */
export default function StickyTable(props) {
    const {header, content, footer, disabled = false} = props;
    const classes = useStyles();
    // workaround in order to replace checkPositionIntervalId
    const [disabledReinitialize, setReinitialize] = useState(false);

    useEffect(() => {
        if (!disabledReinitialize) {
            setReinitialize(true);
        }
    }, [content]);
    useEffect(() => {
        if (disabledReinitialize) {
            setReinitialize(false);
        }
    }, [disabledReinitialize]);

    return <ScrollSync>
        <div className={classes.stickyContainer}>
            {header && <Sticky
                disabled={disabledReinitialize || disabled}
                boundaryElement={`.${classes.stickyContainer}`}
                hideOnBoundaryHit={false}
                mode='top'
                bottomOffset={footer ? 52 : 0}>
                <ScrollSyncPane>
                    {header}
                </ScrollSyncPane>
            </Sticky>}
            <ScrollSyncPane>
                {content}
            </ScrollSyncPane>
            {footer && <Sticky
                disabled={disabledReinitialize || disabled}
                boundaryElement={`.${classes.stickyContainer}`}
                hideOnBoundaryHit={false}
                mode='bottom'
                bottomOffset={header ? 52 : 0}>
                <ScrollSyncPane>
                    {footer}
                </ScrollSyncPane>
            </Sticky>}
        </div>
    </ScrollSync>;
}
