import React from 'react';
// components
import ErrorPage from 'components/modules/pages/ErrorPage';


/**
 * Not Found Page (404)
 */
export default function NotFound() {
    return <ErrorPage type='notfound' />;
}
