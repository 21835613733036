import React, {useCallback, useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {PhoneLinkSource} from 'lib/models';
// Actions
import {deleteItem, fetchItems, setCollection} from 'actions/shared';
import {addMessage, setState} from 'actions/app';
// Components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from 'components/core/ui/mui/Button';
import CardActions from 'components/core/ui/mui/CardActions';
// icons
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import DisconnectIcon from '@material-ui/icons/LinkOff';
import ComingSoonIcon from '@material-ui/icons/WatchLater';
import MigrationReportsIcon from '@material-ui/icons/TableChart';


/**
 * Button component to signify that a PhoneLink source is existing
 * but not yet ready for OAuth connections.
 */
function ComingSoonButton() {
    return <Button variant='contained'
                   color='primary'
                   component='button'
                   onClick={null}
                   disabled={true}>
        <ComingSoonIcon/>
        <FormattedMessage id='phonelink.coming_soon'/>
    </Button>;
}

/**
 * Button component that moves the user to the PhoneLink
 * migration reports page.
 */
function GotoMigrationReportsButton({onClick, disabled}) {
    return <Button variant='contained'
                   color='primary'
                   component='button'
                   onClick={onClick}
                   disabled={disabled}>
        <MigrationReportsIcon/>
        <FormattedMessage id='phonelink.view_migrations'/>
    </Button>;
}

/**
 * Button component for starting an OAuth connection to a
 * given PhoneLink data source.
 */
function StartOauthConnectionButton({onClick, disabled}) {
    return <Button variant='contained'
                   color='primary'
                   component='button'
                   onClick={onClick}
                   disabled={disabled}>
        <PlayCircleOutlineIcon/>
        <FormattedMessage id='phonelink.connect'/>
    </Button>;
}


/**
 * Button component for disconnecting a PhoneLink source
 * from the user's SRAPS account.
 */
function OauthDisconnectButton({onClick}) {
    return <ThemeProvider alt>
        <Button variant='contained'
                color='secondary'
                onClick={onClick}>
            <DisconnectIcon/>
            <FormattedMessage id='phonelink.disconnect'/>
        </Button>
    </ThemeProvider>;
}

/**
 * Card component for a PhoneLink source.
 */
function SourceCard(props) {
    return (<Card style={{display: 'flex', flexDirection: 'column'}}>
        <CardHeader title={<Typography variant='h5'>{props.name}</Typography>} subheader={props.description}/>
        <CardContent style={{marginTop: 'auto'}}>
            <p>
                <Typography component='span'>Website: </Typography>
                <a href={props.website} target='_blank' rel='noreferrer'>{props.name}</a>
            </p>

            <CardActions>
                {
                    props.connectDisabled ?
                        <ComingSoonButton/>
                        : props.connected ?
                            <GotoMigrationReportsButton onClick={props.onMigrationReportsClick}
                                                        disabled={props.connectDisabled}/> :
                            <StartOauthConnectionButton onClick={props.onConnect}
                                                        disabled={props.connectDisabled}/>
                }
                {
                    props.connected && <OauthDisconnectButton onClick={props.onDisconnect}/>
                }
            </CardActions>
        </CardContent>
    </Card>);
}


/**
 * PhoneLink source selection page.
 */
function List(props) {
    // Fetch PhoneLink source data on load.
    useEffect(() => {
        if (props.loaded === false) {
            props.fetchItems(PhoneLinkSource, props.phonelink_source_placement, props.company.getIn(['links', 'phonelink']));
        }
    }, [props.loaded]);

    const handleConnect = useCallback(source => {
        props.setState('opening-oauth-url');
        window.location.href = source.oauth_url;
    }, []);

    const handleMigrationReportsClick = useCallback(source => {
        props.history.push(`/phonelink/${source.identifier}`);
    }, []);

    const handleDisconnect = useCallback(source => {
        props.deleteItem(
            PhoneLinkSource,
            `${props.phonelink_source_placement}`,
            [props.company.getIn(['links', 'phonelink']), source.identifier],
            source
        )
            .then(() => {
                props.addMessage({
                    intl_id: 'phonelink.list.oauth_disconnected',
                    type: 'success'
                });
                const updatedSources = props.items.map(item => {
                    if (item.identifier === source.identifier) {
                        item.connected = false;
                    }

                    return item;
                });

                props.setCollection(PhoneLinkSource, props.phonelink_source_placement, updatedSources, false ,false);
            });
    }, []);

    return (
        <Card>
            <CardHeader title={<FormattedMessage id='phonelink.list.title'/>}
                        subheader={<FormattedMessage id={['opening-oauth-url'].includes(props.state)
                            ? 'phonelink.list.subtitle.oauth_start'
                            : ['deleting_item_phonelink-source'].includes(props.state)
                                ? 'phonelink.list.subtitle.oauth_disconnected'
                                : 'phonelink.list.subtitle'}/>}/>
            <CardContent>
                {
                    ['opening-oauth-url', 'deleting_item_phonelink-source'].includes(props.state) || !props.loaded ?
                        <LinearProgress/> :
                        <Grid container spacing={3}>
                            {props.items.map(source => (
                                <Grid container item md={6} key={source.name}>
                                    <SourceCard name={source.name}
                                                description={source.description}
                                                website={source.website}
                                                connected={source.connected}
                                                connectDisabled={source.oauth_url === null}
                                                onConnect={() => handleConnect(source)}
                                                onMigrationReportsClick={() => handleMigrationReportsClick(source)}
                                                onDisconnect={() => handleDisconnect(source)}/>
                                </Grid>
                            ))}
                        </Grid>
                }
            </CardContent>
        </Card>
    );
}

const ConnectedList = connect((state, props) => {
    const phonelink_source_placement = new PhoneLinkSource().getPlacement();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

    return {
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', phonelink_source_placement]),
        items: state.shared.getIn(['items', phonelink_source_placement]),
        phonelink_source_placement,
        company
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    deleteItem,
    addMessage,
    setCollection
}, dispatch))(List);


export default injectIntl(withRouter(ConnectedList));
