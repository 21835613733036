import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    input: {
        // hide it
        position: 'absolute',
        top: '0',
        left: '-9999px',
        zIndex: '-1',
        width: '1px',
        height: '1px',
        visibility: 'hidden',
        // get rid of style
        padding: '0',
        margin: '0',
        background: 'transparent',
        border: '0'
    }
}));

/**
 * Add this component at the end of the Form to allow submitting form with Enter key
 */
export default function InvisibleEnterSubmit() {
    const classes = useStyles();

    return <input type='submit' tabIndex='-1' className={classes.input} />;
};
