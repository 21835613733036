import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// Components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
// icons
import RefreshIcon from '@material-ui/icons/RefreshOutlined';


const useStyles = makeStyles(theme => ({
    iconButton: {
        // get rid of disabled style
        opacity: '1 !important'
    }
}));

/**
 * ErrorCard to display #404, unavailable API and other errors
 */
export default function ErrorCard(props) {
    const {title, text, icon, refreshIcon = false, children} = props;
    const classes = useStyles();

    return <Card>
        <CardHeader
            title={title}
            subheader={text}
            action={<ThemeProvider alt>
                <ActionButton iconButton disabled
                              className={classes.iconButton}>
                    {icon}
                </ActionButton>
            </ThemeProvider>}
        />
        {refreshIcon && <CardActions>
            <Button variant='contained' color='primary'
                    onClick={() => window.location.reload()}>
                <RefreshIcon />
                <FormattedMessage id='errors.refresh' />
            </Button>
        </CardActions>}
        {children}
    </Card>;
}
