import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUIMenu from '@material-ui/core/Menu';


const useStyles = makeStyles(theme => ({
    paper: {
        // icon and text next to each other support
        '& .icon, & .text': {
            display: 'inline-flex',
            verticalAlign: 'middle'
        },
        // correct proportion of icon
        '& .icon': {
            minWidth: '0', // get rid of not necessary min-width
            marginRight: `${theme.spacing(2)}px` // space between icon and text
        },
        // correct proportion of text
        '& .text': {
            margin: '0',
            '& > span': {
                lineHeight: '1.1875em'
            }
        }
    }
}));

/**
 * Extends Menu component to change style
 */
const Menu = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {paper, ...rest_of_classes} = _classes || {};
    const paperClasses = [
        classes.paper, paper, className
    ].filter(Boolean).join(' ');

    return <MUIMenu
        ref={ref}
        classes={{
            paper: paperClasses,
            ...rest_of_classes
        }}
        {...rest_of_props}
    />;
});
export default Menu;
