import React from 'react';
// components
import ErrorPage from 'components/modules/pages/ErrorPage';


/**
 * Unauthorized Page (403); Not Permission to view certain page
 */
export default function Unauthorized() {
    return <ErrorPage type='unauthorized' />;
}
