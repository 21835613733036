// Moment
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/ru';
// Strings
import enTexts from 'locale/en';
import csTexts from 'locale/cs';
import deTexts from 'locale/de';
import esTexts from 'locale/es';
import itTexts from 'locale/it';
import frTexts from 'locale/fr';
import ruTexts from 'locale/ru';
import enPortalTexts from 'locale/portal/en';
import csPortalTexts from 'locale/portal/cs';
import dePortalTexts from 'locale/portal/de';
import esPortalTexts from 'locale/portal/es';
import itPortalTexts from 'locale/portal/it';
import frPortalTexts from 'locale/portal/fr';
import ruPortalTexts from 'locale/portal/ru';


/**
 * Get proper messages and locale for React-INTL
 *
 * @param locale - locale we are looking for 'en', 'de'
 * @returns [] - messages, locale
 */
export function getINTLMessages(locale) {
    // get proper messages
    let messages = null;

    switch (locale) {
        case 'en':
            messages = {...enTexts, ...enPortalTexts};
            break;
        case 'cs':
            messages = {...csTexts, ...csPortalTexts};
            break;
        case 'de':
            messages = {...deTexts, ...dePortalTexts};
            break;
        case 'es':
            messages = {...esTexts, ...esPortalTexts};
            break;
        case 'it':
            messages = {...itTexts, ...itPortalTexts};
            break;
        case 'fr':
            messages = {...frTexts, ...frPortalTexts};
            break;
        case 'ru':
            messages = {...ruTexts, ...ruPortalTexts};
            break;
        case 'keys':
            messages = Object.keys({...enTexts, ...enPortalTexts}).reduce((o, key) => Object.assign(o, {[key]: key}), {});
            locale = 'en-gb';
            break;
    }
    // support for missing translation string
    if (process.env.NODE_ENV !== 'development' && !process.env.REACT_APP_STAGING && !['en', 'en-gb'].includes(locale)) {
        messages = {...enTexts, ...enPortalTexts, ...messages};
    }

    return [messages, locale];
}
