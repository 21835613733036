import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {ProvisioningProfile, Company, Paginator} from 'lib/models';
import {useHistory, useLocation} from 'react-router';
import {useLocalFilter, useLocalSort, usePaginatedItems} from 'lib/filters';
import {List as ImmutableList} from 'immutable';
import useFeatureFlag from 'lib/featureFlag';
// Actions
import {fetchItems, fetchItem, updatePaginator} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField, CompanyField} from 'components/core/ui/Field';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import InActiveIcon from '@material-ui/icons/CloseOutlined';


/**
 * Renders Provisioning Profiles for specific Company of User from backend - to view and manage them
 */
export default function List(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = searchParams.get('company')
            // ensure we don't create duplicate 'my' collection with UUID
            ? !pathname.startsWith('/all-') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                ? 'my'
                : searchParams.get('company')
            : pathname.startsWith('/all-') ? 'all' : 'my';

        const items_placement = `${new ProvisioningProfile().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user
        };
    }, shallowEqual);
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='provprofiles.list.table.name' />},
        ...(company_identifier === 'all' ? [{sortName: 'company', label: <FormattedMessage id='provprofiles.list.global.table.company' />}] : []),
        {sortName: 'created_at', label: <FormattedMessage id='provprofiles.list.table.created_at' />},
        ...(featureFlag_deviceManager ? [{sortName: 'dm_enabled', label: <FormattedMessage id='provprofiles.list.table.dm_enabled' />, numeric: true}] : []),
        {sortName: 'no_settings', label: <FormattedMessage id='provprofiles.list.table.no_settings' />, numeric: true}
    ];
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new ProvisioningProfile().getUniqueIdentifier());
    const items = company_identifier === 'all' ? paginatedItems : props.items;
    // sorting
    const [apiSortName, apiSortDirection] = [false, false];
    const [sortedItems, sortItems, localSortName, localSortDirection, sortInitializing] = useLocalSort(
        company_identifier === 'all' ? ImmutableList() : items, company_identifier !== 'all' && !props.loaded, items_placement, tableHead.map(item => item.sortName), 'name', 'desc', {
            no_settings: (obj) => (obj.get('settings') || ImmutableList()).size
        });
    const [sortName, sortDirection] = company_identifier === 'all' ? [apiSortName, apiSortDirection] : [localSortName, localSortDirection];
    // filtering
    const [localFilteredItems, filterItems, filters, filtersInitializing, postponedFilters] = useLocalFilter(
        sortedItems, sortInitializing,
        `${new ProvisioningProfile().getPlacement()}${pathname.startsWith('/all-') ? '-global' : ''}`,
        ['company', ...(pathname.startsWith('/all-') ? [] : ['search'])], ['name'], {},
        company_identifier === 'all' && props.state !== null);
    const filteredItems = company_identifier === 'all' ? items : localFilteredItems;

    /**
     * During initialization fetch items
     */
    // all, pagination with API filters
    useEffect(() => {
        if (company_identifier === 'all' && !filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(ProvisioningProfile, items_placement, 'provisioning-profiles', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), company_identifier]);
    // company, local filters
    useEffect(() => {
        if ((company_identifier === 'my' || (company_identifier !== 'all' && props.selectedCompany)) && props.loaded === false) {
            dispatch(fetchItems(ProvisioningProfile, items_placement,
                props.selectedCompany.getIn(['links', 'provisioning-profiles'])
            ));
        }
    }, [props.loaded, props.selectedCompany, company_identifier]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'provprofiles.list.global.title'
                : 'provprofiles.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'provprofiles.list.global.subheader'
                : 'provprofiles.list.subheader'} />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`)}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='provisioning_profiles' />
            </ActionButton>}
        />
        <Filters disabled={company_identifier === 'all' && props.state !== null}>
            {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
                all={pathname.startsWith('/all-')} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            {company_identifier !== 'all' && <SearchField
                label={<FormattedMessage id='provprofiles.list.search' />}
                value={postponedFilters.search || filters.search || ''}
                search={(search) => filterItems('search', search)} />}
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {company_identifier === 'all'
                                    ? sortName === item.sortName
                                        ? <TableSortLabel disabled active direction={sortDirection}>
                                            {item.label}
                                        </TableSortLabel>
                                        : item.label
                                    : <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}
                                                      disabled={passed_props.dashboard}>
                                        {item.label}
                                    </TableSortLabel>}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`
                ].includes(props.state) || filtersInitializing ? <PaginationLoading
                    paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                    tableHead={tableHead} paginator={props.paginator}
                />
                    : !items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'provprofiles.list.table.empty'}
                                                      values={{link: passed_props.permission === 'RW'
                                                              ? <Link className='hover-border' to={`/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`}>
                                                                  <FormattedMessage id='provprofiles.list.table.empty.link' />
                                                              </Link>
                                                              : <FormattedMessage id='provprofiles.list.table.empty.link' />}} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && filteredItems.map((provprofile, idx) => {
                        const link = pathname.startsWith('/all-')
                            ? `/all-provisioning-profiles/${provprofile.get(new ProvisioningProfile().getUniqueIdentifier())}${provprofile.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${provprofile.get('company')}` : ''}`
                            : `/provisioning-profiles/${provprofile.get(new ProvisioningProfile().getUniqueIdentifier())}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`;
                        const onClick = () => props.state === null ? history.push(link) : {};

                        return <TableRow key={idx} hover>
                            <TableCell link onClick={onClick}>{provprofile.get('name')}</TableCell>
                            {company_identifier === 'all' && <TableCell>
                                <Link className='hover-border' to={`/companies/${provprofile.get('company')}`}>
                                    {provprofile.get('company_name')}
                                </Link>
                            </TableCell>}
                            <TableCell link onClick={onClick}>{Moment(provprofile.get('created_at')).format('l, LT')}</TableCell>
                            {featureFlag_deviceManager && <TableCell numeric>
                                {provprofile.get('dm_enabled') ? <ActiveIcon color='primary' /> : <InActiveIcon />}
                            </TableCell>}
                            <TableCell numeric link onClick={onClick}>{(provprofile.get('settings') || ImmutableList()).size}</TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={company_identifier !== 'all' ? undefined : <Table size={1}>
                <Pagination model={ProvisioningProfile} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
    </Card>;
}
