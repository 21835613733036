import React, {useMemo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, initialize as reduxFormInitialize} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {Company, User, Role, Distributor, PBXPartner, SalesCluster} from 'lib/models';
import {useLocalSort} from 'lib/filters';
import useFeatureFlag from 'lib/featureFlag';
// Actions
import {setState} from 'actions/app';
import {fetchItems, fetchItem, saveItem, deleteItem, markFiltered, setCollection} from 'actions/shared';
// Components
import PartnerLevel from 'components/modules/companies/PartnerLevel';
import ThemeProvider from 'components/ThemeProvider';
import Resources from 'components/modules/companies/Resources';
import AddUser from 'components/modules/users/AddUser';
import Invite from 'components/modules/users/Invite';
import DetailUsers from 'components/modules/users/DetailUsers';
import SubscriptionBanner from 'components/modules/subscription/Banner';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {CountryField, CompanyTypeField, CompanyStatusField, CompanySourcesField, CompanyPartnerLevelField, SalesClustersField} from 'components/core/ui/Field';
import Tabs from 'components/core/ui/Tabs';
import {withRouter} from 'react-router-dom';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Tab from 'components/core/ui/mui/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import ResourcesIcon from '@material-ui/icons/LayersOutlined';


/**
 * Renders detail of Company
 */
function Detail(props) {
    // local state
    const edit = !!props.match.params.identifier || props.location.pathname.replace('/partner', '').startsWith('/company');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [expanded, setExpanded] = useState(1);
    // sorting
    const [locallySortedDistributors] = useLocalSort(props.distributors_items, !props.distributors_loaded);
    const sortedDistributors = useMemo(() => {
        return locallySortedDistributors.filter(distributor => distributor.get('active'));
    }, [locallySortedDistributors]);
    const [locallySortedPBXPartners] = useLocalSort(props.pbx_partners_items, !props.pbx_partners_loaded);
    const sortedPBXPartners = useMemo(() => {
        return locallySortedPBXPartners.filter(pbx_partner => pbx_partner.get('active'));
    }, [locallySortedPBXPartners]);
    // feature flags
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company: props.match.params.identifier || props.item?.get(new Company().getUniqueIdentifier())});

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        // no matter loaded state, fetch additional info to get correct statistics information for resources
        if (edit) {
            // ensure we don't fetch user company under my-companies
            if (!props.location.pathname.startsWith('/my-companies') || props.match.params.identifier !== props.company.get(new Company().getUniqueIdentifier())) {
                props.fetchItem(Company, props.location.pathname.startsWith('/my-companies') ? 'my-companies' : 'companies',
                    ['companies', props.match.params.identifier || props.item.get(new Company().getUniqueIdentifier())],
                    {affect_state: !props.item, success_affect_state: false}
                );
            }
        }
        // success_affect_state false
    }, [props.match.params.identifier, edit]);
    // company users
    useEffect(() => {
        if (props.item) {
            if (props.company_users_loaded === false && props.permissions.get('user') !== 'X') {
                props.fetchItems(User, `companyUsers-${props.item.get(new Company().getUniqueIdentifier())}`, props.item.getIn(['links', 'users']));
            } else if (props.state === 'fetching_item_companies') {
                props.setState(null);
            }
        }
    }, [props.item, props.company_users_loaded, props.permissions.get('user')]);
    // related data fetch without affecting state for related fields
    useEffect(() => {
        if (props.roles_loaded === false && props.permissions.get('user') !== 'X') {
            props.fetchItems(Role, 'roles', 'roles', null, null, {affect_state: false});
        }
    }, [props.roles_loaded, props.permissions.get('user')]);
    useEffect(() => {
        if (props.distributors_loaded === false && props.permissions.get('distributor') !== 'X' && ((props.portalAccess && props.user.get('pp_tos_accepted')) || (props.user.isAdmin()))) {
            props.fetchItems(Distributor, 'distributors', 'distributors', null, null, {affect_state: false});
        }
    }, [props.distributors_loaded, props.permissions.get('distributor'), props.portalAccess, props.user.get('pp_tos_accepted')]);
    useEffect(() => {
        if (props.pbx_partners_loaded === false && props.permissions.get('pbx_partner') !== 'X' && ((props.portalAccess && props.user.get('pp_tos_accepted')) || (props.user.isAdmin()))) {
            props.fetchItems(PBXPartner, 'pbx-partners', 'pbx-partners', null, null, {affect_state: false});
        }
    }, [props.pbx_partners_loaded, props.permissions.get('pbx_partner'), props.portalAccess, props.user.get('pp_tos_accepted')]);
    useEffect(() => {
        if (!props.sales_clusters_loaded && props.permissions.get('sales_clusters') !== 'X') {
            props.fetchItems(SalesCluster, 'sales_clusters', 'sales-clusters', null, null, {affect_state: false});
        }
    }, [props.sales_clusters_loaded, props.permissions.get('sales_clusters')]);

    return <div>
        {['fetching_item_companies', 'deleting_item_companies', 'fetching_item_my-companies', 'deleting_item_my-companies'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='companies.detail.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='company' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
                ? <ErrorCard
                    title={<FormattedMessage id='companies.detail.notfound.title' />}
                    text={<FormattedMessage id='companies.detail.notfound.text' />}
                    icon={<WarningIcon color='secondary' />} />
                : props.formValues && <Tabs
                tabs={!edit || props.permissions.get('user') === 'X' ? null : [
                    <Tab key={0} label={<FormattedMessage id='companies.detail.tabs.main' />} />,
                    <Tab key={1} label={<FormattedMessage id='companies.detail.tabs.employees' />} />
                ]}
                initialSelectedTab={props.location.hash === '#employees' ? 1 : 0}
                tabsProps={{indicatorColor: 'primary'}}
                onChange={(tab) => props.history.push({
                    hash: tab === 1 ? '#employees' : null, search: ''
                })}
                content={[
                    <Row key={0} wrap>
                        <Col width={edit ? '66.6667%' : '100%'}>
                            <CardFirst>
                                <CardHeader
                                    title={<FormattedMessage id={props.location.pathname.replace('/partner', '').startsWith('/company')
                                        ? 'companies.detail.my.title'
                                        : props.location.pathname.startsWith('/my-companies')
                                            ? 'companies.detail.my_companies.title'
                                            : 'companies.detail.title'}
                                    />}
                                    subheader={<FormattedMessage id={props.location.pathname.replace('/partner', '').startsWith('/company')
                                        ? 'companies.detail.my.subheader'
                                        : props.location.pathname.startsWith('/my-companies')
                                            ? 'companies.detail.my_companies.subheader'
                                            : 'companies.detail.subheader'
                                    } />}
                                    action={<ActionButton iconButton disabled>
                                        <ModelIcon model='company' />
                                    </ActionButton>} />
                            </CardFirst>
                            <ExpansionPanel expanded={expanded === 1} onChange={() => setExpanded(expanded === 1 ? 0 : 1)}
                                            title_intl='companies.detail.basic.title'>
                                <Form onSubmit={props.handleSubmit}>
                                    <AutoCompleteOff />
                                    <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.basic.form.fields.name'})}*`} />
                                    <CompanyTypeField required hideDisabledArrow disabled={edit && !props.user.isAdmin()} />
                                    <SpaceDivider none />
                                    <Field name='email' type='email' fieldType='TextField' label={<FormattedMessage id='companies.detail.basic.form.fields.email' />} />
                                    <Field name='phone_number' fieldType='TextField' label={<FormattedMessage id='companies.detail.basic.form.fields.phone' />} />
                                    <SpaceDivider none />
                                    <Field name='website' fieldType='URLField' label={<FormattedMessage id='companies.detail.basic.form.fields.website' />}
                                           change={props.change} selectedValue={props.formValues && props.formValues.website} />
                                    {props.user.isAdmin() && <React.Fragment>
                                        <Field name='salesforce_id' fieldType='TextField' label={<FormattedMessage id='companies.detail.basic.form.fields.salesforce_id' />} />
                                        <SpaceDivider none />
                                        <CompanySourcesField selectedValue={props.formValues && props.formValues.signup_sources} disabled />
                                        <CompanyStatusField required name='status' />
                                    </React.Fragment>}
                                    {edit && <React.Fragment>
                                        <SpaceDivider double />
                                        <Field name='uuid' fieldType='TextField' disabled={true} label={`${props.intl.formatMessage({id: 'companies.detail.basic.form.fields.uuid'})}*`}
                                               helperText={<FormattedMessage id='companies.detail.basic.form.fields.uuid.help' />} />
                                        <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='companies.detail.basic.form.fields.created_at' />}
                                               format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                        {props.user.isAdmin() && props.permissions.get('sales_clusters') !== 'X' && <React.Fragment>
                                            <SpaceDivider none />
                                            <SalesClustersField
                                                fieldType='NoReduxSelect' disabled={true}
                                                value={(props.formValues?.country
                                                    && props.sales_clusters_items?.find((sales_cluster) =>
                                                        sales_cluster.get('countries').find((country) =>
                                                            country === props.formValues.country
                                                        )))?.get('uuid') || ''} />
                                        </React.Fragment>}
                                    </React.Fragment>}
                                </Form>
                                <SpaceDivider />
                                <SpaceDivider double />
                                <Typography variant='body1'><FormattedMessage id='companies.detail.address.title' /></Typography>
                                <SpaceDivider />
                                <Form onSubmit={props.handleSubmit}>
                                    <CountryField name='country' label={`${props.intl.formatMessage({id: 'companies.detail.address.form.fields.country'})}*`} />
                                    <Field name='city' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.address.form.fields.city'})}*`} />
                                    <SpaceDivider none />
                                    <Field name='street' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.address.form.fields.street'})}*`} />
                                    <Field name='postal_code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.address.form.fields.postal_code'})}*`} />
                                    <SpaceDivider />
                                    <Field name='delivery' fieldType='Switch' label={<FormattedMessage id='companies.detail.delivery.toggle' />}
                                           onChange={(event, value, prevValue) => {
                                               if (!value) {
                                                   // clear delivery values
                                                   props.change('delivery_country', null);
                                                   props.change('delivery_city', null);
                                                   props.change('delivery_street', null);
                                                   props.change('delivery_postal_code', null);
                                               }
                                           }} />
                                </Form>
                                {(props.formValues && props.formValues.delivery) && <React.Fragment>
                                    <SpaceDivider />
                                    <Typography variant='body1'><FormattedMessage id='companies.detail.delivery.title' /></Typography>
                                    <SpaceDivider />
                                    <Form onSubmit={props.handleSubmit}>
                                        <CountryField name='delivery_country' label={`${props.intl.formatMessage({id: 'companies.detail.delivery.form.fields.delivery_country'})}*`} />
                                        <Field name='delivery_city' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.delivery.form.fields.delivery_city'})}*`} />
                                        <SpaceDivider none />
                                        <Field name='delivery_street' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.delivery.form.fields.delivery_street'})}*`} />
                                        <Field name='delivery_postal_code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.delivery.form.fields.delivery_postal_code'})}*`} />
                                    </Form>
                                </React.Fragment>}
                                <SpaceDivider />
                                <SpaceDivider double />
                                <Typography variant='body1'><FormattedMessage id='companies.detail.billing.title' /></Typography>
                                <SpaceDivider />
                                <Form onSubmit={props.handleSubmit}>
                                    {new Company().getPPRequiredFields().filter(field => field !== 'distributor').map((field, idx) => {
                                        return <React.Fragment key={field}>
                                            <Field name={field} fieldType='TextField'
                                                label={<FormattedMessage id={`companies.detail.billing.form.fields.${field}`} />}
                                                helperText={['vat', 'iban', 'bic'].includes(field) && <FormattedMessage id={`companies.detail.billing.form.fields.${field}.help`} />} />
                                            {!!(idx % 2) && <SpaceDivider none />}
                                        </React.Fragment>;
                                    })}
                                </Form>
                                <SpaceDivider double />
                            </ExpansionPanel>
                            {(props.user.isAdmin() && !props.portal) && <ExpansionPanel
                                expanded={expanded === 2} onChange={() => setExpanded(expanded === 2 ? 0 : 2)}
                                title_intl='companies.detail.api.title'>
                                <Form onSubmit={props.handleSubmit}>
                                    <Field name='api_enabled' fieldType='Checkbox' size='regular'
                                           label={<FormattedMessage id='companies.detail.api.form.fields.api_enabled' />}
                                           helperText={<FormattedMessage id='companies.detail.api.form.fields.api_enabled.help' />}
                                           onChange={(event, value) => props.change('api_rate_limit', value ? 5000 : 0)} />
                                    {(props.formValues && props.formValues.api_enabled) && <React.Fragment>
                                        <Field name='api_rate_limit' fieldType='TextField' type='number'
                                               label={<FormattedMessage id='companies.detail.api.form.fields.api_rate_limit' />}
                                               helperText={<FormattedMessage id='companies.detail.api.form.fields.api_rate_limit.help' />} />
                                    </React.Fragment>}
                                    <SpaceDivider none />
                                    <Field name='provisioning_strategy' fieldType='Select'
                                           label={<FormattedMessage id='companies.detail.api.form.fields.provisioning_strategy' />}
                                           helperText={<FormattedMessage id='companies.detail.api.form.fields.provisioning_strategy.help' />}>
                                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                        <MenuItem value='Default'><FormattedMessage id='companies.detail.api.form.fields.provisioning_strategy.choices.default' /></MenuItem>
                                        <MenuItem value='3CX'><FormattedMessage id='companies.detail.api.form.fields.provisioning_strategy.choices.3cx' /></MenuItem>
                                    </Field>
                                    {featureFlag_deviceManager && <React.Fragment>
                                        { edit && props.item && typeof(props.item.get('endpoint_tickets_limit')) === 'number'
                                        ? <Field name='endpoint_tickets_limit' fieldType='TextField' type='number'
                                            label={<FormattedMessage id='companies.detail.api.form.fields.endpoint_tickets_limit' />}
                                            helperText={<FormattedMessage id='companies.detail.api.form.fields.endpoint_tickets_limit.help' />} />
                                        : <Field fieldType='TextField'
                                            label={<FormattedMessage id='companies.detail.api.form.fields.endpoint_tickets_limit' />}
                                            helperText={<FormattedMessage id='companies.detail.api.form.fields.endpoint_tickets_limit.temporarily_unavailable' />}
                                            disabled={true} />
                                        }
                                    </React.Fragment>}
                                </Form>
                                <SpaceDivider double />
                            </ExpansionPanel>}
                            {(edit && props.item && props.item.get('company_type') === 'reseller' && ((props.portalAccess && props.user.get('pp_tos_accepted')) || props.user.isAdmin())) && <ExpansionPanel
                                expanded={expanded === 3} onChange={() => setExpanded(expanded === 3 ? 0 : 3)}
                                title_intl={props.portal ? 'companies.detail.portal.portal.title' : 'companies.detail.portal.title'}>
                                <Form onSubmit={props.handleSubmit}>
                                    {props.user.isAdmin() && <React.Fragment>
                                        <CompanyPartnerLevelField required disabled={!props.user.isAdmin()} />
                                        <Field name='partner_active' fieldType='Checkbox' size='regular'
                                               label={<FormattedMessage id='companies.detail.portal.form.fields.partner_active' />} />
                                        <SpaceDivider none />
                                        <Field name='submission_enabled' fieldType='Checkbox' size='full'
                                               label={<FormattedMessage id='companies.detail.portal.form.fields.submission_enabled' />}
                                               helperText={<FormattedMessage id='companies.detail.portal.form.fields.submission_enabled.help' />} />
                                    </React.Fragment>}
                                    {props.permissions.get('distributor') !== 'X' && <React.Fragment>
                                        {props.user.isAdmin() && <SpaceDivider />}
                                        <Field name='distributor' fieldType='Select'
                                               label={`${props.intl.formatMessage({id: 'companies.detail.portal.form.fields.distributor'})}${props.portal ? '*' : ''}`}
                                               disabled={!props.distributors_loaded} loading={!props.distributors_loaded}>
                                            <MenuItem fake value={props.formValues.distributor} />
                                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                            {sortedDistributors.map((distributor, idx) =>
                                                <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                                            )}
                                        </Field>
                                        <Field name='distributor_2' fieldType='Select'
                                               label={<FormattedMessage id='companies.detail.portal.form.fields.distributor_2' />}
                                               disabled={!props.distributors_loaded} loading={!props.distributors_loaded}>
                                            <MenuItem fake value={props.formValues.distributor_2} />
                                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                            {sortedDistributors.map((distributor, idx) =>
                                                <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                                            )}
                                        </Field>
                                    </React.Fragment>}
                                    {props.permissions.get('pbx_partner') !== 'X' && <React.Fragment>
                                        {(props.user.isAdmin() || props.permissions.get('distributor') !== 'X') && <SpaceDivider />}
                                        <Field name='pbx_partners' fieldType='Select' multiple
                                               label={`${props.intl.formatMessage({id: 'companies.detail.portal.form.fields.pbx_partners'})}${props.portal ? '*' : ''}`}
                                               disabled={!props.pbx_partners_loaded} loading={!props.pbx_partners_loaded}>
                                            <MenuItem fake value={props.formValues.pbx_partners} />
                                            {sortedPBXPartners.map((pbx_partner, idx) =>
                                                <MenuItem key={idx} value={pbx_partner.get(new PBXPartner().getUniqueIdentifier())}>{pbx_partner.get('name')}</MenuItem>
                                            )}
                                        </Field>
                                    </React.Fragment>}
                                </Form>
                                <SpaceDivider double />
                            </ExpansionPanel>}
                            <CardLast>
                                {[
                                    'saving_item_companies', 'saving_item_my-companies',
                                    'saved_item_companies', 'saved_item_my-companies',
                                    'failed_save_item_companies', 'failed_save_item_my-companies'
                                ].includes(props.state)
                                    ? <CardActionsLoader success={['saved_item_companies', 'saved_item_my-companies'].includes(props.state)}
                                                         failure={['failed_save_item_companies', 'failed_save_item_my-companies'].includes(props.state)}
                                                         postAnimation={() => props.setState(null)} />
                                    : <ThemeProvider alt>
                                        <CardActions>
                                            {!props.location.pathname.replace('/partner', '').startsWith('/company') &&
                                                <Button disabled={props.state !== null}
                                                        onClick={() => props.history.push(
                                                            props.location.pathname.startsWith('/my-companies') ? '/my-companies' : '/companies')}>
                                                    <CancelIcon />
                                                    <FormattedMessage id='actions.cancel' />
                                                </Button>
                                            }
                                            {(props.permission === 'RW' || props.editing_self) && <React.Fragment>
                                                {(edit && !props.editing_self) &&
                                                    <Button variant='contained' color='secondary' disabled={props.state !== null}
                                                            onClick={() => setDeleteDialogOpen(true)}>
                                                        <DeleteIcon />
                                                        <FormattedMessage id='actions.delete' />
                                                    </Button>
                                                }
                                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                                        onClick={() => props.handleSubmit()}>
                                                    <SaveIcon />
                                                    <FormattedMessage id='actions.save' />
                                                </Button>
                                            </React.Fragment>}
                                        </CardActions>
                                    </ThemeProvider>}
                                {(edit && !props.editing_self && props.permission === 'RW') && <DeleteDialog
                                    item={props.intl.formatMessage({id: 'companies.detail.deletedialog.item'})}
                                    items={props.intl.formatMessage({id: 'companies.detail.deletedialog.items'})}
                                    open={deleteDialogOpen}
                                    handleClose={() => setDeleteDialogOpen(false)}
                                    handleConfirm={() => {
                                        setDeleteDialogOpen(false);
                                        props.deleteItem(Company, props.location.pathname.startsWith('/my-companies') ? 'my-companies' : 'companies', props.item.getIn(['links', 'self']), props.item,
                                            {error_message_intl: 'companies.detail.delete.fail'}).then(result => {
                                            if (result !== false) {
                                                // mark paginated lists for reload
                                                props.markFiltered(Company, 'companies');
                                                props.markFiltered(Company, 'my-companies');
                                                props.history.push(props.location.pathname.startsWith('/my-companies') ? '/my-companies' : '/companies');
                                            }
                                        });
                                    }}
                                />}
                            </CardLast>
                        </Col>
                        {edit && <Col width='33.3333%'>
                            {props.portal
                                ? <PartnerLevel item={props.item} />
                                : <React.Fragment>
                                    <Card>
                                        <CardHeader
                                            title={<FormattedMessage id='companies.detail.resources.title' />}
                                            subheader={<FormattedMessage id='companies.detail.resources.subheader' />}
                                            action={<ActionButton iconButton disabled>
                                                <ResourcesIcon />
                                            </ActionButton>}
                                        />
                                        <CardContent>
                                            {props.item && <Resources item={props.item} />}
                                        </CardContent>
                                    </Card>
                                    {featureFlag_deviceManager && <React.Fragment>
                                        <SpaceDivider grid />
                                        <SubscriptionBanner
                                            company={props.editing_self ? undefined : props.item}
                                            cardheader={<CardHeader
                                                title={<FormattedMessage id='companies.detail.subscription.title' />}
                                                subheader={<FormattedMessage id='companies.detail.subscription.subheader' />}
                                            />}
                                            simple
                                        />
                                    </React.Fragment>}
                                </React.Fragment>}
                            <SpaceDivider grid />
                            <Card>
                                <CardHeader
                                    title={<FormattedMessage id='companies.detail.agreements.title' />}
                                    subheader={<FormattedMessage id='companies.detail.agreements.subheader' />}
                                />
                                <CardContent>
                                    {props.item.get('contract_url')
                                        ? <a href={props.item.get('contract_url')} className='hover-border'>
                                            <FormattedMessage id='companies.detail.agreements.data_processing' />
                                        </a>
                                        : <FormattedMessage id='companies.detail.agreements.data_processing' />}
                                </CardContent>
                            </Card>
                        </Col>}
                    </Row>,
                    ...(!edit || props.permissions.get('user') === 'X' ? [] : [<Row key={1} wrap>
                        <Col width='66.6667%'>
                            <DetailUsers item_company={props.item} />
                        </Col>
                        <Col width='33.3333%'>
                            <Invite item_company={props.item} />
                            {props.user.isAdmin() && <React.Fragment>
                                <SpaceDivider grid />
                                <AddUser item_company={props.item} />
                            </React.Fragment>}
                        </Col>
                    </Row>])
                ]} />}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'company_type', data.company_type);
    if (data.email) {
        validator.isEmail(null, errors, 'email', data.email);
    }
    if (props.user.isAdmin()) {
        validator.isNotNull(null, errors, 'status', data.status);
        validator.isInt(null, errors, 'api_rate_limit', data.api_rate_limit, {allow_leading_zeroes: false, min: 0});
        validator.isNotNull(null, errors, 'provisioning_strategy', data.provisioning_strategy);
        validator.isNotNull(null, errors, 'partner_level', data.partner_level);
    }
    validator.isNotNull(null, errors, 'country', data.country);
    validator.isNotNull(null, errors, 'city', data.city);
    validator.isNotNull(null, errors, 'street', data.street);
    validator.isNotNull(null, errors, 'postal_code', data.postal_code);
    if (data.delivery) {
        validator.isNotNull(null, errors, 'delivery_country', data.delivery_country);
        validator.isNotNull(null, errors, 'delivery_city', data.delivery_city);
        validator.isNotNull(null, errors, 'delivery_street', data.delivery_street);
        validator.isNotNull(null, errors, 'delivery_postal_code', data.delivery_postal_code);
    }

    // prevent partner portal form from submit if additional fields are not filled
    if (props.portal) {
        validator.isNotNull(null, errors, 'distributor', data.distributor);
        validator.isNotNull(null, errors, 'pbx_partners', data.pbx_partners);
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'companyForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_company_data = new Company().popUpFields(props.formValues, props.user.isAdmin());
        const {delivery, ...rest_of_data} = rest_of_company_data;

        return dispatch(saveItem(Company, props.location.pathname.startsWith('/my-companies') ? 'my-companies' : 'companies', props.item ? props.item.getIn(['links', 'self']) : props.company.getIn(['links', 'child-companies']), rest_of_data, props.item, {update_method: 'put'})).then(create_data => {
            // we may did changes that affected routes
            if (props.editing_self && (
                rest_of_data.company_type !== props.company.get('company_type') ||
                rest_of_data.api_enabled !== props.company.get('api_enabled')
            )) {
                // we must re-initialize form, since we are in newly created route component
                props.reduxFormInitialize('companyForm', props.formValues);
            }

            // in case of add, redirect to detail
            if (!props.item && create_data.get(new Company().getUniqueIdentifier())) {
                props.history.push(`/my-companies/${create_data.get(new Company().getUniqueIdentifier())}`);
                // mark paginated lists for reload
                dispatch(markFiltered(Company, 'companies'));
                return dispatch(markFiltered(Company, 'my-companies'));
            } else {
                // mark not updated lists for reload
                return dispatch(markFiltered(Company, props.location.pathname.startsWith('/my-companies') ? 'companies' : 'my-companies'));
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

    let item = state.shared.getIn(['items', 'companies']).find(el => el.get(new Company().getUniqueIdentifier()) === props.match.params.identifier);
    if (props.location.pathname.startsWith('/my-companies')) {
        item = state.shared.getIn(['items', 'my-companies']).find(el => el.get(new Company().getUniqueIdentifier()) === props.match.params.identifier);
    } else if (props.location.pathname.replace('/partner', '').startsWith('/company')) {
        item = company;
    }
    const companyUsersLocation = item ? `companyUsers-${item.get(new Company().getUniqueIdentifier())}` : 'companyUsers-unknown';
    const auth_user = state.auth.get('user');
    const sales_clusters_placement = new SalesCluster().getPlacement();

    return {
        portal: props.location.pathname.startsWith('/partner'),
        state: state.app.get('state'),
        item: item,
        company_users_loaded: !!state.shared.getIn(['loaded', companyUsersLocation]),
        initialValues: props.match.params.identifier || props.location.pathname.replace('/partner', '').startsWith('/company')
            ? item ? {delivery: !!item.get('delivery_street'), ...item.toJS()} : undefined
            : auth_user.isAdmin() ? {provisioning_strategy: 'Default', api_enabled: true, api_rate_limit: 5000, delivery: false, signup_sources: ['sraps']} : {delivery: false, signup_sources: ['sraps']},
        editing_self: item ? item.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']) : false,
        roles_loaded: state.shared.getIn(['loaded', 'roles']),
        distributors_loaded: state.shared.getIn(['loaded', 'distributors']),
        distributors_items: state.shared.getIn(['items', 'distributors']),
        pbx_partners_loaded: state.shared.getIn(['loaded', 'pbx-partners']),
        pbx_partners_items: state.shared.getIn(['items', 'pbx-partners']),
        sales_clusters_items: state.shared.getIn(['items', sales_clusters_placement]),
        sales_clusters_loaded: state.shared.getIn(['loaded', sales_clusters_placement]),
        company: company,
        user: auth_user,
        permissions: state.auth.get('permissions'),
        formValues: getFormValues('companyForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    reduxFormInitialize,
    fetchItems,
    fetchItem,
    saveItem,
    deleteItem,
    markFiltered,
    setCollection
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
