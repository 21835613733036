import React, {useMemo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {useLocalSort} from 'lib/filters';
import {Company, ApiKey, Role} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Credentials from 'components/modules/apikeys/Credentials';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import Field, {CompanyField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';


/**
 * Renders detail of API Key with it's token secret - ADD & EDIT
 */
function Detail(props) {
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // sorting
    const [locallySortedRolesByName,,,, sortInitializing] = useLocalSort(props.roles_items, !props.roles_loaded);
    const [locallySortedRoles] = useLocalSort(locallySortedRolesByName, sortInitializing, null, ['for_supergroup'], 'for_supergroup', 'asc');
    const sortedRoles = useMemo(() => {
        return props.item_is_admin ? locallySortedRoles : locallySortedRoles.filter(role => !role.get('for_supergroup'));
    }, [locallySortedRoles, props.item_is_admin]);

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', props.selectedCompanyIdentifier],
                {success_affect_state: !edit, ignore_403: !props.location.pathname.startsWith('/all-')});
        } else if (edit) {
            if (!props.item) {
                props.fetchItem(ApiKey,
                    props.selectedCompanyIdentifier === 'my' ? 'api-keys' : `api-keys-${props.selectedCompanyIdentifier}`,
                    [
                        props.selectedCompany.getIn(['links', 'tokens']),
                        props.match.params.identifier
                    ],
                    {company: props.selectedCompany}
                );
            } else if (['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)) {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany]);
    // related data fetch without affecting state for related fields
    useEffect(() => {
        if (props.roles_loaded === false) {
            props.fetchItems(Role, 'roles', 'roles', null, null, {affect_state: false});
        }
    }, [props.roles_loaded]);

    return <div>
        {[
            'fetching_item_companies', 'fetching_item_my-companies',
            'fetching_item_api-keys', `fetching_item_api-keys-${props.selectedCompanyIdentifier}`,
            'deleting_item_api-keys', `deleting_item_api-keys-${props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='apikeys.detail.unknown.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='api_key' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='apikeys.detail.notfound.title' />}
                text={<FormattedMessage id='apikeys.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <Row wrapSwitch>
            <Col width={edit ? '66.6667%' : '100%'}>
                <Card>
                    <CardHeader subheader={<FormattedMessage id='apikeys.detail.subheader' />}
                                title={edit
                                    ? <FormattedMessage id='apikeys.detail.edit.title' />
                                    : <FormattedMessage id='apikeys.detail.add.title' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='api_key' />
                                </ActionButton>} />
                    <CardContent>
                        <Form onSubmit={props.handleSubmit}>
                            <AutoCompleteOff />
                            <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'apikeys.detail.form.fields.name'})}*`} />
                            {(props.company.get('company_type') === 'reseller' || props.location.pathname.startsWith('/all-')) && <CompanyField
                                value={!edit && props.selectedCompanyIdentifier === 'my' ? ''
                                    : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                                change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                                disabled={edit} all={false}
                                fetch_company={false}
                                my_companies={!props.location.pathname.startsWith('/all-')}
                            />}
                            <SpaceDivider />
                            <Field name='role' fieldType='Select' label={`${props.intl.formatMessage({id: 'apikeys.detail.form.fields.role'})}*`}
                                   helperText={<FormattedMessage id='companies.detail.adduser.form.fields.role.help' />}
                                   disabled={props.state !== null || !props.roles_loaded}
                                   loading={!props.roles_loaded}>
                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                <MenuItem fake value={props.formValues && props.formValues.role} />
                                {sortedRoles.map((role, idx) =>
                                    <MenuItem key={idx} value={role.get(new Role().getUniqueIdentifier())}>
                                        {role.get('for_supergroup') ? <React.Fragment>
                                            <ListItemIcon className='icon'><AdminIcon color='primary' /></ListItemIcon>
                                            <ListItemText className='text'>{role.get('name')}</ListItemText>
                                        </React.Fragment> : role.get('name')}
                                    </MenuItem>
                                )}
                            </Field>
                        </Form>
                    </CardContent>
                    {[
                        'saving_item_api-keys', `saving_item_api-keys-${props.selectedCompanyIdentifier}`,
                        'saved_item_api-keys', `saved_item_api-keys-${props.selectedCompanyIdentifier}`,
                        'failed_save_item_api-keys', `failed_save_item_api-keys-${props.selectedCompanyIdentifier}`
                    ].includes(props.state)
                        ? <CardActionsLoader success={['saved_item_api-keys', `saved_item_api-keys-${props.selectedCompanyIdentifier}`].includes(props.state)}
                                             failure={['failed_save_item_api-keys', `failed_save_item_api-keys-${props.selectedCompanyIdentifier}`].includes(props.state)}
                                             postAnimation={() => props.setState(null)} />
                        : <ThemeProvider alt>
                        <CardActions>
                            <Button disabled={props.state !== null}
                                    onClick={() => props.history.push(
                                        `/${props.location.pathname.startsWith('/all-api-keys') ? 'all-' : ''}api-keys${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`)}>
                                <CancelIcon />
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            {props.permission === 'RW' && <React.Fragment>
                                {edit &&
                                    <Button variant='contained' color='secondary' disabled={props.state !== null}
                                            onClick={() => setDeleteDialogOpen(true)}>
                                        <DeleteIcon />
                                        <FormattedMessage id='actions.delete' />
                                    </Button>
                                }
                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                        onClick={() => props.handleSubmit()}>
                                    <SaveIcon />
                                    <FormattedMessage id='actions.save' />
                                </Button>
                            </React.Fragment>}
                        </CardActions>
                    </ThemeProvider>}
                    {(edit && props.permission === 'RW') && <DeleteDialog
                        item={props.intl.formatMessage({id: 'apikeys.detail.deletedialog.item'})}
                        items={props.intl.formatMessage({id: 'apikeys.detail.deletedialog.items'})}
                        open={deleteDialogOpen}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleConfirm={() => {
                            setDeleteDialogOpen(false);
                            props.deleteItem(ApiKey,
                                `api-keys${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`,
                                props.item.getIn(['links', 'self']),
                                props.item
                            ).then(() => {
                                props.history.push(`/${props.location.pathname.startsWith('/all-api-keys') ? 'all-' : ''}api-keys${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                            });
                        }}
                    />}
                </Card>
            </Col>
            {edit && <Col width='33.3333%'>
                <Credentials
                    access_key_id={props.formValues && props.formValues.access_key_id}
                    access_key_secret={props.formValues && props.formValues.access_key_secret}
                />
            </Col>}
        </Row>}
    </div>;
}

const validate = (data, props) => {
    const errors = {settings: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'role', data.role);

    return errors;
};

const DetailForm = reduxForm({
    form: 'apiKeysForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new ApiKey().popUpFields(props.formValues);

        return dispatch(saveItem(
            ApiKey,
            props.selectedCompanyIdentifier === 'my' ? 'api-keys' : `api-keys-${props.selectedCompanyIdentifier}`,
            props.item
                ? props.item.getIn(['links', 'self'])
                : props.selectedCompany.getIn(['links', 'tokens']),
            rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false}
        )).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new ApiKey().getUniqueIdentifier())) {
                props.history.push(`/${props.location.pathname.startsWith('/all-api-keys') ? 'all-' : ''}api-keys/${create_data.get(new ApiKey().getUniqueIdentifier())}${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    const items = selectedCompanyIdentifier === 'my' ? state.shared.getIn(['items', 'api-keys']) : state.shared.getIn(['items', `api-keys-${selectedCompanyIdentifier}`]) || ImmutableList();
    const item = items.find(el => el.get(new ApiKey().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        item_is_admin: auth_user.isAdmin() && ((item && auth_user.getIn(['links', 'company']) === item.getIn(['links', 'company'])) || selectedCompanyIdentifier === 'my'),
        initialValues: props.match.params.identifier
            ? item
                ? item.toJS()
                : undefined
            : {},
        roles_loaded: state.shared.getIn(['loaded', 'roles']),
        roles_items: state.shared.getIn(['items', 'roles']),
        user: auth_user,
        company: company,
        formValues: getFormValues('apiKeysForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
