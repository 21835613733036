import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {Distributor} from 'lib/models';
import {useLocalSort, useLocalFilter} from 'lib/filters';
import {countries} from 'lib/countrieslist';
import {Map as ImmutableMap} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Field, {SearchField, CountryField, SalesClustersField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import InActiveIcon from '@material-ui/icons/CloseOutlined';


/**
 * Renders Distributors from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Distributor().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement]),
        sales_clusters: state.shared.getIn(['items', 'sales_clusters']),
        permissions: state.auth.get('permissions')
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='distributors.list.table.name' />},
        {sortName: 'country', label: <FormattedMessage id='distributors.list.table.country' />},
        {sortName: 'created_at', label: <FormattedMessage id='distributors.list.table.created_at' />},
        {sortName: 'active', label: <FormattedMessage id='distributors.list.table.active' />, numeric: true}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing || !props.sales_clusters, new Distributor().getPlacement(), ['search', 'country', 'sales_cluster', 'active'], ['name'],
        {sales_cluster: (obj, value) => !!props.sales_clusters?.find((cluster) => cluster.get('uuid') === value && cluster.get('countries').includes(obj.get('country')))}
    );

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Distributor, items_placement, 'distributors'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='distributors.list.title' />}
            subheader={<FormattedMessage id='distributors.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/distributors/add')}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='distributors' />
            </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='distributors.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <CountryField
                label={<FormattedMessage id='distributors.list.filter.country' />}
                fieldType='NoReduxSelect' value={filters.country || ''}
                onChange={event => filterItems('country', event.target.value)} />
            {props.permissions.get('sales_clusters') !== 'X' && (
                <SalesClustersField fieldType='NoReduxSelect' value={filters.sales_cluster || ''}
                                onChange={(event) => filterItems('sales_cluster', event.target.value)} />
            )}
            <Field label={<FormattedMessage id='distributors.list.filter.active' />}
                   fieldType='NoReduxSelect' value={filters.active || ''}
                   onChange={(event) => filterItems('active', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'>
                    <ListItemIcon className='icon'><ActiveIcon color='primary' /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='distributors.list.filter.active.choices.active' /></ListItemText>
                </MenuItem>
                <MenuItem value='false'>
                    <ListItemIcon className='icon'><InActiveIcon /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='distributors.list.filter.active.choices.inactive' /></ListItemText>
                </MenuItem>
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <em>
                                <FormattedMessage id={props.items.size ? 'filters.empty' : 'distributors.list.table.empty'}
                                                  values={{link: passed_props.permission === 'RW'
                                                          ? <Link to='/distributors/add' className='hover-border'>
                                                              <FormattedMessage id='distributors.list.table.empty.link' />
                                                          </Link>
                                                          : <FormattedMessage id='distributors.list.table.empty.link' />}} />
                            </em>
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map(distributor =>
                        <TableRow key={distributor.get(new Distributor().getUniqueIdentifier())} link hover
                                  onClick={() => props.state === null ? history.push(`/distributors/${distributor.get(new Distributor().getUniqueIdentifier())}`) : {}}>
                            <TableCell>{distributor.get('name')}</TableCell>
                            <TableCell>{distributor.get('country') ? (countries.find(country => country.get('id') === distributor.get('country')) || new ImmutableMap({name: distributor.get('country')})).get('name') : ''}</TableCell>
                            <TableCell>{Moment(distributor.get('created_at')).format('l, LT')}</TableCell>
                            <TableCell numeric>{distributor.get('active') ? <ActiveIcon color='primary' /> : <InActiveIcon />}</TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>} />
    </Card>;
}
