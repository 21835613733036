'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// Action
import {fetchItem} from 'actions/shared';
// Components
import Dropzone from 'react-dropzone';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import AddIcon from '@material-ui/icons/LibraryAddOutlined';
import ErrorIcon from '@material-ui/icons/WarningOutlined';
import ClearIcon from '@material-ui/icons/ClearOutlined';
import ResetIcon from '@material-ui/icons/RotateLeftOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';


const styles = theme => ({
    // formControl root
    root: {
        // style taken from Field.js formControl
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        width: '320px',
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10',
        // cursor like link
        cursor: 'pointer',
        // variants
        '&.disabled': {
            cursor: 'not-allowed'
        }
    },
    // visual border around fileld
    contentHolder: {
        // visual
        border: `1px solid ${theme.palette.grey[300]}`,
        // variants
        '&.rejected': {
            borderColor: theme.palette.danger['main']
        },
        '&.label': {
            // extra space for label
            marginTop: `${theme.spacing(2)}px`
        }
    },
    // ratio of content
    ratio: {
        position: 'relative',
        width: '100%',
        // proportion (16:9)
        paddingBottom: '56.25%',
        '&.square': {
            paddingBottom: '100%'
        }
    },
    // content itself or hover-block
    content: {
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        top: '0',
        // visual
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text['secondary'],
        // smooth
        transition: theme.transitions.create(['background-color'],
            {duration: theme.transitions.duration.short}),
        '&:not(.disabled):hover': {
            backgroundColor: theme.palette.action['hover']
        },
        // variants
        '&.rejected': {
            color: theme.palette.danger['main']
        },
        // don't crop and dont repeat image
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        // center content with flex
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        // icon
        '& svg': {
            // increase size
            fontSize: theme.typography.pxToRem(34) // matched with CardActionsLoader
        },
        // text (<FormattedMessage />)
        '& span': {
            // new row
            display: 'block',
            flexGrow: '1',
            width: '100%',
            // space between icon and text
            marginTop: `${theme.spacing(1)}px`
        },
        // hover variant when we have image preview
        '&.hover-block': {
            // hidden by default
            opacity: '0',
            // smooth appearance
            transition: theme.transitions.create(['opacity'],
                {duration: theme.transitions.duration.short}),
            // visual
            color: theme.palette.common.white,
            '&, &:not(.disabled):hover': {
                background: theme.palette.action['active'] // half-transparent black
            },
            '&:hover': {
                opacity: '1'
            }
        }
    },
    // holder of clear & reset buttons
    buttonHolder: {
        display: 'flex',
        // connect borders
        marginTop: '-1px',
        // buttons inside
        '& .button': {
            flexGrow: '1',
            '&, &:last-child': {
                margin: '0'
            }
        }
    },
    actionButton: {
        // fit nicely next to each other
        borderRadius: '0',
        '&, &:hover': {
            // make sure to overwrite global Application <a> style
            color: theme.palette.text.primary
        }
    }
});

/**
 * Integration of react-dropzone, used as an FileField without direct Redux-Form integration
 *
 * Props:
 *  label               - message for Label above box
 *  helperText          - optional HelperText bellow the field
 *  initialFile         - id & url of already uploaded file
 *  selectedFile        - current id & url of already uploaded file
 *  loading             - display <CircularProgress /> and disable file dropping / editing
 *  disabled            - disabled file dropping / editing
 *  excludePermission   - ignore setting of permission
 *  imageOnly           - accepts only Images
 *  preOpen             - opens a SelectFile dialog on mount
 *  disablePreview      - hide clear button and do not show preview
 *  onChange            - action done with File update (e.g. (file) => this.props.change('file', file))
 *  fieldChange         - changes id and url of already uploaded file (e.g. (id, url) => { this.props.change('file_id', id); this.props.change('file_url', url); }
 */
class DropFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            state: null, // status of the component e.g. 'error' / 'fetching'
            file: null, // current file blop
            imageURL: null, // url to be rendered as backgroundImage
            fileName: null // name of the file
        };
        this.dropZoneRef = React.createRef(); // ref for react-dropzone instance
    }

    /**
     * During initialization preOpen dialog
     */
    componentDidMount() {
        // should launch opened?
        if (this.props.preOpen && !this.props.selectedFile.id) {
            this.dropZoneRef.current.open();
        }
        if (this.props.selectedFile.id && this.props.selectedFile.url) {
            this.processInitialFile();
        }
    }

    /**
     * Watch for initialFile to change
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        if (this.props.initialFile.id !== prevProps.initialFile.id) {
            // process initial file again to ensure that reset button works correctly
            this.processInitialFile();
        }
    }

    /**
     * Process provided initial file
     */
    processInitialFile() {
        // process initial file
        if (this.props.initialFile.id && this.props.initialFile.url) {
            this.setState({file: null, fileName: this.props.initialFile.id});
            if (['gif', 'jpeg', 'jpg', 'png', 'svg'].includes(this.props.initialFile.url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase())) {
                this.setState({imageURL: this.props.initialFile.url});
            }
        }
    }

    /**
     * File to process by component
     *
     * @param file - File blop
     * @param options - {} contains optional options
     *  rejected    - default: false; file rejected by the DropZone
     *  firstTime   - default: false; indicates that props.onChange() shouldn't be invoked
     */
    processFile(file, options = {}) {
        // process options and it's defaults
        let rejected = options.rejected !== undefined ? options.rejected : false;
        let firstTime = options.firstTime !== undefined ? options.firstTime : false;

        // update state
        !this.props.disablePreview && this.setState({
            file: file || null,
            imageURL: null,
            fileName: file ? file.name : null,
            state: rejected ? 'rejected' : null});
        if (!firstTime) {
            this.props.onChange(file);
        }
        // image process
        if (file && file.type && file.type.match('image.*') && !this.props.disablePreview) {
            const reader = new FileReader();
            reader.onload = () => this.setState({imageURL: reader.result});
            reader.readAsDataURL(file);
        }
    }

    render() {
        // Split some stuff from props
        let {intl, dispatch, fetchItem, classes, _classes, className, permission, permissions,
            excludePermission, label, helperText, initialFile, selectedFile, loading, error, required,
            disabled, imageOnly, preOpen, onChange, fieldChange, placeholderText, placeholderIcon, ...rest_of_props} = this.props;
        // include 'fetching' state to loading
        loading = loading || this.state.state === 'loading';
        // include loading and permission check to disabled status
        disabled = (!excludePermission && permissions.get(permission) === 'R') || disabled || loading;
        // Merge classes from props and our custom
        let {root, contentHolder, content, buttonHolder, actionButton} = _classes || {};
        let rootClasses = [
            classes.root, root, className,
            disabled ? 'disabled' : ''
        ].filter(Boolean).join(' ');
        let contentHolderClasses = [
            classes.contentHolder, contentHolder,
            label ? 'label' : '',
            this.state.state || ''
        ].filter(Boolean).join(' ');
        let contentClasses = [
            classes.content, content,
            disabled ? 'disabled' : '',
            this.state.state || ''
        ].filter(Boolean).join(' ');
        let buttonHolderClasses = [
            classes.buttonHolder, buttonHolder
        ].filter(Boolean).join(' ');
        let actionButtonClasses = [
            classes.actionButton, actionButton
        ].filter(Boolean).join(' ');

        return <FormControl fullWidth required={required} className={rootClasses} error={this.state.state === 'rejected'}>
            {label && <InputLabel shrink={true}>{label}</InputLabel>}
            <Dropzone disabled={disabled}
                      multiple={false}
                      onDropAccepted={acceptedFiles => this.processFile(acceptedFiles[0])}
                      onDropRejected={() => this.processFile(null, {rejected: true})}
                      accept={imageOnly && {'image/*': []}}
                      ref={this.dropZoneRef}
                      {...rest_of_props}>
                {({getRootProps, getInputProps}) => <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={contentHolderClasses}>
                        <div className={classes.ratio}>
                            {loading
                                ? <div className={classes.content}>
                                    <CircularProgress size={80} />
                                </div>
                                : <div className={contentClasses}
                                       style={this.state.imageURL
                                           ? {backgroundColor: '#fff', backgroundImage: `url("${this.state.imageURL}")`} : {}}>
                                    {this.state.fileName && !this.state.imageURL
                                        ? <React.Fragment>
                                            <span>{this.state.fileName}</span>
                                        </React.Fragment>
                                        : this.state.state === 'rejected'
                                        ? <React.Fragment>
                                            <ErrorIcon />
                                            <FormattedMessage id='dropfile.rejected' />
                                        </React.Fragment>
                                        : !this.state.imageURL && <React.Fragment>
                                        {placeholderIcon || <AddIcon />}
                                        {placeholderText || <FormattedMessage id='dropfile.howto' />}
                                    </React.Fragment>}
                                </div>}
                            {(!disabled && (this.state.file || this.state.imageURL)) && <div className={`${classes.content} hover-block`}>
                                {placeholderIcon || <AddIcon />}
                                {placeholderText || <FormattedMessage id='dropfile.howto' />}
                            </div>}
                        </div>
                    </div>
                </div>}
            </Dropzone>
            {(this.state.file || selectedFile.id || (initialFile.id && selectedFile.id !== initialFile.id) || (this.state.imageURL && this.state.imageURL === selectedFile.url)) && <div className={buttonHolderClasses}>
                {initialFile.id && (initialFile.id !== selectedFile.id || this.state.file) && <ActionButton
                    variant='outlined' className={actionButtonClasses}
                    disabled={disabled}
                    onClick={() => {
                        if (this.state.file) {
                            this.processFile(null);
                        }
                        fieldChange(initialFile.id, initialFile.url);
                        this.processInitialFile();
                    }}>
                    <ResetIcon />
                    <FormattedMessage id='actions.reset' />
                </ActionButton>}
                {(this.state.file || selectedFile.id) && <ActionButton
                    variant='outlined' className={actionButtonClasses}
                    disabled={disabled}
                    onClick={() => {
                        this.processFile(null);
                        if (selectedFile.id) {
                            fieldChange(null, null);
                        }
                    }}>
                    <ClearIcon />
                    <FormattedMessage id='actions.clear' />
                </ActionButton>}
                {selectedFile.url && <ActionButton
                    variant='outlined' className={actionButtonClasses}
                    component='a' target='_blank' rel='noreferrer'
                    href={selectedFile.url}>
                    <LinkToIcon />
                    <FormattedMessage id='actions.open' />
                </ActionButton>}
            </div>}
            {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>;
    }
}

DropFile = withStyles(styles)(DropFile);
DropFile = connect((state, props) => ({
    permissions: state.auth.get('permissions'),
    permission: state.auth.get('permission')
}), (dispatch) => bindActionCreators({
    fetchItem
}, dispatch))(DropFile);

export default injectIntl(DropFile);
