import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, reset as reduxFormReset} from 'redux-form';
import validator from 'lib/valitator';
import {listAsModel, Setting, AttrsSettings, SettingGroup, ProductGroup, FeatureFlag} from 'lib/models';
import {evaluateFeatureFlag} from 'lib/featureFlag';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
import {useLocalSort} from 'lib/filters';
// Actions
import {setState} from 'actions/app';
import {fetchItems, saveItem, deleteItem, markFiltered} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Preview from 'components/modules/settings/Preview';
import Form from 'components/core/ui/Form';
import Field, {SettingFieldType, ChoicesField, AutoCompleteField, validateSettingsField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import {Row, Col} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SettingPreviewIcon from '@material-ui/icons/ShortTextOutlined';
import SettingStatisticIcon from '@material-ui/icons/BarChartOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


const useStyles = makeStyles(theme => ({
    // format of Link in Setting description
    descriptionLinkFormat: {
        whiteSpace: 'nowrap', // one-liner
        userSelect: 'all' // easier copy
    },
    // title, subheader and number next to it
    statistic: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > h6': {
            marginLeft: `${theme.spacing(1)}px`
        }
    }
}));

/**
 * Renders detail of Setting - ADD & EDIT
 */
function Detail(props) {
    const classes = useStyles();
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog
    // sorting
    const [sortedGroups] = useLocalSort(props.groups_items, !props.groups_loaded);
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);

    // sections AutoComplete
    const [sectionSuggestions, setSectionSuggestions] = useState(undefined);
    const sectionSearch = (search) => {
        props.change('section', search);
        setSectionSuggestions(!search ? new ImmutableList() : props.items
            // search
            .filter(setting => setting.get('section').replace(/\s/g, '').toLowerCase().includes(search.replace(/\s/g, '').toLowerCase()))
            // map for AutoCompleteField
            .map(setting => new ImmutableMap({value: setting.get('section')}))
            // remove duplicates
            .toSet().toList()
        );
    };

    /**
     * During initialization fetch ALL items if we don't have item (Edit)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItems(Setting, 'settings', 'settings');
        }
    }, [props.match.params.identifier]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.groups_loaded === false) {
            props.fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false});
        }
    }, [props.groups_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            props.fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false});
        }
    }, [props.product_groups_loaded]);

    return <div>
        {['fetching_items_settings', 'deleting_item_settings'].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='settings.detail.unknown.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='setting' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='settings.detail.notfound.title' />}
                text={<FormattedMessage id='settings.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <Row wrapSwitch>
            <Col width='66.6667%'>
                <Card>
                    <CardHeader subheader={<FormattedMessage id='settings.detail.subheader' />}
                                title={edit
                                    ? <FormattedMessage id='settings.detail.edit.title' />
                                    : <FormattedMessage id='settings.detail.add.title' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='setting' />
                                </ActionButton>} />
                    <CardContent>
                        <Form onSubmit={props.handleSubmit}>
                            <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.name'})}*`}
                                   helperText={<FormattedMessage id='settings.detail.form.fields.name.help' />} />
                            <SettingFieldType required onChange={(event, value) => {
                                // clear value during change to ensure correct value type
                                props.reduxFormReset('previewFieldForm');
                                // clear values which may vary between field types
                                props.change('default_value', null);
                                props.change('choices', []);
                                props.change('sortable', false);
                                // set certificates properly
                                if (value === 'certificate') {
                                    props.change('indexed.enabled', false);
                                    props.change('uses_permissions', false);
                                    props.change('multiple', true);
                                } else {
                                    props.change('multiple', false);
                                }
                            }} />
                            {['select'].includes(props.formValues.field_type) && <React.Fragment>
                                <SpaceDivider none />
                                <ChoicesField name='choices'
                                              label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.choices'})}*`}
                                              selectedValue={props.formValues.choices}
                                              change={(name, value) => {
                                                  // clear value to ensure that we have updated choices and we are not using deleted choice
                                                  props.reduxFormReset('previewFieldForm');
                                                  // make change
                                                  props.change(name, value);
                                                  // trigger validation so we display proper field error
                                                  props.change('_validationHack', Date.now());
                                              }}
                                />
                            </React.Fragment>}
                            {['select'].includes(props.formValues.field_type) &&
                                <Field name='multiple' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.multiple' />}
                                       helperText={<FormattedMessage id='settings.detail.form.fields.multiple.help' />}
                                       onChange={(event, value) => {
                                           // clear value during change to ensure correct value type
                                           props.reduxFormReset('previewFieldForm');
                                           if (value === false) {
                                               props.change('sortable', false);
                                           } else {
                                               props.change('default_value', null);
                                           }
                                       }} />
                            }
                            {(['select'].includes(props.formValues.field_type) && props.formValues.multiple === true) &&
                                <Field name='sortable' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.sortable' />}
                                       helperText={<FormattedMessage id='settings.detail.form.fields.sortable.help' />}
                                       onChange={() => { props.reduxFormReset('previewFieldForm'); }} />
                            }
                            {!['certificate'].includes(props.formValues.field_type) && <React.Fragment>
                                <SpaceDivider none />
                                {(!props.formValues.multiple) && <Field
                                    name='default_value' fieldType='TextField' label={<FormattedMessage id='settings.detail.form.fields.default_value' />}
                                    helperText={<FormattedMessage id='settings.detail.form.fields.default_value.help' />} />
                                }
                                <Field name='uses_permissions' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.uses_permissions' />}
                                       helperText={<FormattedMessage id='settings.detail.form.fields.uses_permissions.help' />}
                                       size='regular' />
                            </React.Fragment>}
                            {!['time', 'checkbox'].includes(props.formValues.field_type) && <React.Fragment>
                                <SpaceDivider none />
                                <Field name='allow_null' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.allow_null' />}
                                       helperText={<FormattedMessage id='settings.detail.form.fields.allow_null.help' />} size='regular'
                                       onChange={() => {
                                           // clear value during change to ensure correct error shown
                                           props.reduxFormReset('previewFieldForm');
                                       }}/>
                            </React.Fragment>}
                            <SpaceDivider none />
                            <Field name='description' fieldType='TextArea' label={<FormattedMessage id='settings.detail.form.fields.description' />}
                                   helperText={<FormattedMessage id='settings.detail.form.fields.description.help'
                                                                 values={{link_format: <span className={classes.descriptionLinkFormat}>
                                                                     {'<a href="URL" class="hover-border" target="_blank" rel="noreferrer">LINK</a>'}
                                                                 </span>}} />} />
                        </Form>
                        {!['certificate'].includes(props.formValues.field_type) && <React.Fragment>
                            <SpaceDivider double />
                            <hr />
                            <Typography variant='body1'><FormattedMessage id='settings.detail.section.indexed' /></Typography>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='indexed.enabled' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.indexed' />}
                                       helperText={<FormattedMessage id='settings.detail.form.fields.indexed.help' />}
                                       onChange={(event, value) => {
                                           // clear value during change, because of change from array to single value only
                                           props.reduxFormReset('previewFieldForm');
                                           // clear f-keys for non-indexed fields
                                           if (value === false) {
                                               props.change('fkey', false);
                                               props.change('indexed.nested.enabled', false);
                                           } else {
                                               // not supported default value
                                               props.change('default_value', null);
                                           }
                                       }}
                                       size='regular'
                                />
                                {props.formValues.indexed.enabled && <React.Fragment>
                                    <Field name='fkey' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.fkey' />}
                                           helperText={<FormattedMessage id='settings.detail.form.fields.fkey.help' />}
                                           onChange={(event, value) => {
                                               if (props.featureFlagContextKeyUI && value) {
                                                   // Checking the f-key turns context_key off
                                                   props.change('context_key', false);
                                                   // Reset index ranges
                                                   props.change('indexed.start', 0);
                                                   props.change('indexed.max_size', 0);
                                               }
                                           }}/>
                                    {props.featureFlagContextKeyUI && <Field name='context_key' fieldType='Checkbox'
                                            label={<FormattedMessage id='settings.detail.form.fields.context_key'/>}
                                            helperText={<FormattedMessage
                                                id='settings.detail.form.fields.context_key.help'/>}
                                            onChange={(event, value) => {
                                                if (value === false) {
                                                    // Reset index ranges
                                                    props.change('indexed.start', 0);
                                                    props.change('indexed.max_size', 0);
                                                } else {
                                                    // Force the index ranges to be 0-12
                                                    props.change('indexed.start', 0);
                                                    props.change('indexed.max_size', 12);
                                                    // Checking the context_key turns f-key off
                                                    props.change('fkey', false);
                                                }
                                            }}/>}
                                    <SpaceDivider none />
                                    <Field name='indexed.start' type='number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.indexed.start'})}*`}
                                           helperText={<FormattedMessage id='settings.detail.form.fields.indexed.start.help' />} disabled={props.featureFlagContextKeyUI && props.formValues.context_key}/>
                                    <Field name='indexed.max_size' type='number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.indexed.max_size'})}*`} disabled={props.featureFlagContextKeyUI && props.formValues.context_key} />
                                    <SpaceDivider />
                                    <Field name='indexed.nested.enabled' fieldType='Checkbox' label={<FormattedMessage id='settings.detail.form.fields.double_indexed' />}
                                           helperText={<FormattedMessage id='settings.detail.form.fields.double_indexed.help' />}
                                           onChange={() => {
                                               // clear value during change, as we store indexes differently
                                               props.reduxFormReset('previewFieldForm');
                                           }}
                                           size='regular'
                                    />
                                    {props.formValues.indexed.nested.enabled && <React.Fragment>
                                        <SpaceDivider none />
                                        <Field name='indexed.nested.start' type='number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.indexed.start'})}*`}
                                               helperText={<FormattedMessage id='settings.detail.form.fields.indexed.start.help' />} />
                                        <Field name='indexed.nested.max_size' type='number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.indexed.max_size'})}*`} />
                                    </React.Fragment>}
                                </React.Fragment>}
                            </Form>
                        </React.Fragment>}
                        <SpaceDivider double />
                        <hr />
                        <Typography variant='body1'><FormattedMessage id='settings.detail.section.path' /></Typography>
                        <hr />
                        <Form onSubmit={props.handleSubmit}>
                            <Field name='param_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.param_name'})}*`}
                                   helperText={<FormattedMessage id='settings.detail.form.fields.param_name.help' />} />
                            <AutoCompleteField label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.section'})}*`}
                                               helperText={<FormattedMessage id='settings.detail.form.fields.section.help' />}
                                               value={props.formValues.section}
                                               search={(search) => sectionSearch(search)}
                                               change={(value) => props.change('section', value)}
                                               items={sectionSuggestions}
                                               onFocus={sectionSuggestions === undefined
                                                   ? props.initialValues && props.initialValues.section
                                                       ? () => sectionSearch(props.formValues.section)
                                                       : setSectionSuggestions(new ImmutableList())
                                                   : undefined
                                               } />
                        </Form>
                        <SpaceDivider double />
                        <hr />
                        <Typography variant='body1'><FormattedMessage id='settings.detail.section.related' /></Typography>
                        <hr />
                        <Form onSubmit={props.handleSubmit}>
                            <Field name='group' fieldType='Select' label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.group'})}*`}
                                   disabled={!props.groups_loaded} loading={!props.groups_loaded}>
                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                <MenuItem fake value={props.formValues.group} />
                                {sortedGroups.map((group, idx) =>
                                    <MenuItem key={idx} value={group.get(new SettingGroup().getUniqueIdentifier())}>{group.get('name')}</MenuItem>
                                )}
                            </Field>
                            <Field name='product_groups' fieldType='Select' multiple
                                   label={`${props.intl.formatMessage({id: 'settings.detail.form.fields.product_groups'})}*`}
                                   helperText={<FormattedMessage id='settings.detail.form.fields.product_groups.help' />}
                                   disabled={!props.product_groups_loaded} loading={!props.product_groups_loaded}>
                                <MenuItem fake value={props.formValues.product_groups} />
                                {sortedProductGroups.map((product_group, idx) =>
                                    <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                                )}
                            </Field>
                            <SpaceDivider none />
                            <Field name='provisioning_strategy' fieldType='Select'
                                    label={<FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy' />}
                                    helperText={<FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.help' />}>
                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                <MenuItem value='Default'><FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.choices.default' /></MenuItem>
                                <MenuItem value='3CX'><FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.choices.3cx' /></MenuItem>
                            </Field>
                        </Form>
                    </CardContent>
                    {['saving_item_settings', 'saved_item_settings', 'failed_save_item_settings'].includes(props.state)
                        ? <CardActionsLoader success={props.state === 'saved_item_settings'}
                                             failure={props.state === 'failed_save_item_settings'}
                                             postAnimation={() => props.setState(null)} />
                        : <ThemeProvider alt>
                        <CardActions>
                            <Button disabled={props.state !== null}
                                    onClick={() => props.history.push('/settings')}>
                                <CancelIcon />
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            {props.permission === 'RW' && <React.Fragment>
                                {edit &&
                                    <Button variant='contained' color='secondary' disabled={props.state !== null}
                                            onClick={() => setDeleteDialogOpen(true)}>
                                        <DeleteIcon />
                                        <FormattedMessage id='actions.delete' />
                                    </Button>
                                }
                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                        onClick={() => props.state === null ? props.handleSubmit() : {}}>
                                    <SaveIcon />
                                    <FormattedMessage id='actions.save' />
                                </Button>
                            </React.Fragment>}
                        </CardActions>
                    </ThemeProvider>}
                    {(edit && props.permission === 'RW') && <DeleteDialog
                        item={props.intl.formatMessage({id: 'settings.detail.deletedialog.item'})}
                        items={props.intl.formatMessage({id: 'settings.detail.deletedialog.items'})}
                        open={deleteDialogOpen}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleConfirm={() => {
                            setDeleteDialogOpen(false);
                            props.deleteItem(Setting, 'settings', props.item.getIn(['links', 'self']), props.item,
                                {error_message_intl: 'settings.detail.delete.fail'}).then(result => {
                                    if (result !== false) {
                                        // make sure that Setting Groups will be re-fetched (due to no_settings)
                                        props.dispatch(markFiltered(SettingGroup, 'setting-groups'));
                                        props.history.push('/settings');
                                    }
                                });
                        }}
                    />}
                </Card>
            </Col>
            <Col width='33.3333%'>
                <Card>
                    <CardHeader title={<FormattedMessage id='settings.detail.preview.title' />}
                                subheader={<FormattedMessage id='settings.detail.preview.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <SettingPreviewIcon />
                                </ActionButton>} />
                    <CardContent>
                        <Preview setting={new Setting({
                            ...props.formValues,
                            choices: props.formValues.choices.map(choice => new ImmutableMap(choice)),
                            attrs: listAsModel(AttrsSettings, props.formValues.attrs),
                            indexed: new ImmutableMap(Object.entries(props.formValues.indexed))
                        })} />
                    </CardContent>
                </Card>
                {edit && <React.Fragment>
                    <SpaceDivider grid />
                    <Card>
                        <CardHeader title={<FormattedMessage id='settings.detail.statistics.title' />}
                                    subheader={<FormattedMessage id='settings.detail.statistics.subheader' />}
                                    action={<ActionButton iconButton disabled>
                                        <SettingStatisticIcon />
                                    </ActionButton>} />
                        <CardContent className={classes.statistic}>
                            <div>
                                <Typography variant='subtitle1'><FormattedMessage id='settings.detail.statistics.no_profiles' />{':'}</Typography>
                                <Typography variant='caption'><FormattedMessage id='settings.detail.statistics.no_profiles.subheader' /></Typography>
                            </div>
                            <Typography variant='h6'>{props.formValues.no_profiles || 0}</Typography>
                        </CardContent>
                    </Card>
                </React.Fragment>}

            </Col>
        </Row>}
    </div>;
}

/**
 * Convert Setting record toObject(). Used for initial values
 *
 * @param setting - Setting model
 */
function toObject(setting) {
    setting = setting.toJS();
    if (setting.product_groups) {
        setting.product_groups = [...setting.product_groups];
    }
    return {
        ...setting,
        indexed: {
            ...(setting.indexed || {}),
            nested: {
                enabled: false,
                start: 0,
                max_size: 0,
                ...(setting.indexed?.nested || {})
            }
        }
    };
}

const validate = (data, props) => {
    const errors = {indexed: {nested: {}}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
     // nested fix
    const nested_indexed = data.indexed || {};
    const nested_double_indexed = data.indexed?.nested || {};

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'field_type', data.field_type);
    validator.isNotNull(null, errors, 'param_name', data.param_name);
    validator.isNotNull(null, errors, 'section', data.section);
    if (data.field_type && data.field_type === 'select') {
        validator.isNotNull(null, errors, 'choices', data.choices);
    }
    if (nested_indexed.enabled) {
        validator.isNotNull(null, errors, 'fkey', data.fkey);
        validator.isNotNull(null, errors, 'indexed.start', nested_indexed.start);
        if (nested_indexed.start) {
            validator.isInt(null, errors, 'indexed.start', nested_indexed.start, {allow_leading_zeroes: false, min: 0});
        }
        validator.isNotNull(null, errors, 'indexed.max_size', nested_indexed.max_size);
        if (nested_indexed.max_size) {
            validator.isInt(null, errors, 'indexed.max_size', nested_indexed.max_size, {allow_leading_zeroes: false, min: 0});
        }
        if (nested_double_indexed.enabled) {
            validator.isNotNull(null, errors, 'indexed.nested.start', nested_double_indexed.start);
            if (nested_indexed.start) {
                validator.isInt(null, errors, 'indexed.nested.start', nested_double_indexed.start, {allow_leading_zeroes: false, min: 0});
            }
            validator.isNotNull(null, errors, 'indexed.nested.max_size', nested_double_indexed.max_size);
            if (nested_indexed.max_size) {
                validator.isInt(null, errors, 'indexed.nested.max_size', nested_double_indexed.max_size, {allow_leading_zeroes: false, min: 0});
            }
        }
    }
    if (data.section && data.section.includes('<') && data.section.includes('>')) {
        validator.isTrue(
            props.intl.formatMessage({id: 'settings.detail.error.indexed.required'}),
            errors, 'indexed.enabled', nested_indexed.enabled);
    }
    if (data.default_value && data.field_type) {
        // validators of default_value for individual field_types
        if (data.field_type === 'checkbox') {
            validator.includes(
                props.intl.formatMessage({id: 'settings.detail.error.default_value.invalid.checkbox'}),
                errors, 'default_value',
                data.default_value, ['on', 'off', 'true', 'false']
            );
        } else if (data.field_type === 'select') {
            validator.includes(
                props.intl.formatMessage({id: 'settings.detail.error.default_value.invalid.select'}),
                errors, 'default_value',
                data.default_value, data.choices.map(choice => choice.value)
            );
        } else {
            errors['default_value'] = validateSettingsField(
                {value: data.default_value},
                new Setting({field_type: data.field_type}),
                props.intl, false
            ).value;
        }
    }
    validator.isNotNull(null, errors, 'group', data.group);
    validator.isNotNull(null, errors, 'product_groups', data.product_groups);
    validator.isNotNull(null, errors, 'provisioning_strategy', data.provisioning_strategy);

    return errors;
};

const DetailForm = reduxForm({
    form: 'settingForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let rest_of_setting_data = new Setting().popUpFields(props.formValues);
        let {_validationHack, ...rest_of_data} = rest_of_setting_data;

        return dispatch(saveItem(Setting, 'settings', props.item ? props.item.getIn(['links', 'self']) : 'settings', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // make sure that Setting Groups will be re-fetched (due to no_settings). We must re-fetch immediately instead set markFiltered because Setting Group Field would be disabled
            dispatch(fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false}));
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new Setting().getUniqueIdentifier())) {
                props.history.push(`/settings/${create_data.get(new Setting().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items = state.shared.getIn(['items', 'settings']);
    const item = items.find(el => el.get(new Setting().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        item: item,
        items: items,
        initialValues: props.match.params.identifier
            ? item
                ? toObject(item)
                : undefined
            : {
                name: 'Label',
                field_type: 'text',
                multiple: false,
                sortable: false,
                choices: [],
                indexed: {
                    enabled: false,
                    start: 0,
                    max_size: 0,
                    nested: {
                        enabled: false,
                        start: 0,
                        max_size: 0
                    }
                },
                fkey: false,
                uses_permissions: true,
                allow_null: false,
                product_groups: [],
                provisioning_strategy: 'Default'
            },
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        groups_items: state.shared.getIn(['items', 'setting-groups']),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        formValues: getFormValues('settingForm')(state),
        featureFlagContextKeyUI: evaluateFeatureFlag(state.shared.getIn(['items', 'feature-flags'])
            ?.find(el => el.get(new FeatureFlag().getUniqueIdentifier()) === 'context_key_ui'))
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem,
    deleteItem,
    markFiltered,
    reduxFormReset
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
