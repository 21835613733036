import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {shallowEqual, useSelector} from 'react-redux';
import {SalesCluster} from 'lib/models';
// Components
import SubscriptionPlans from 'components/modules/subscription/List';
import Invoices from 'components/modules/invoices/List';
import PaymentMethods from 'components/modules/paymentmethods/List';
import Tabs from 'components/core/ui/Tabs';
// material-ui
import Tab from 'components/core/ui/mui/Tab';


/**
 * Main holder of Subscriptions pages (plans, invoices, cards) that are under Tabs
 */
export default function Subscriptions(passed_props) {
    // redux store
    const {invoices_permission, payment_methods_permission, is_free_dm} = useSelector(state => {
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
        const salesClustersPlacement = new SalesCluster().getPlacement();
        const salesClusters = state.shared.getIn(['items', salesClustersPlacement]);
        const salesClustersLoaded = state.shared.getIn(['loaded', salesClustersPlacement]);
        let is_free_dm = false;
        if (company && salesClustersLoaded) {
            const cluster = salesClusters.find(item => item.get('countries').includes(company.get('country')));
            is_free_dm = !!cluster && cluster.get('free_dm');
        }
    
    
        return ({
            invoices_permission: state.auth.get('permissions').get('invoice'),
            payment_methods_permission: state.auth.get('permissions').get('payment_method'),
            is_free_dm
        });
    }, shallowEqual);
    // router
    const history = useHistory();
    const {hash} = useLocation();

    return ((invoices_permission === 'X' && payment_methods_permission === 'X') || is_free_dm)
        ? <SubscriptionPlans {...passed_props} />
        : <Tabs
            tabs={[
                'subscription',
                ...(invoices_permission !== 'X' ? ['invoices'] : []),
                ...(payment_methods_permission !== 'X' ? ['payment_methods'] : [])
            ].map(tab => <Tab
                key={tab} label={<FormattedMessage id={`subscription.tabs.${tab}`} />}
            />)}
            initialSelectedTab={([
                ...(invoices_permission !== 'X' ? ['#invoices'] : []),
                ...(payment_methods_permission !== 'X' ? ['#payment_methods'] : [])
            ].findIndex(tab_hash => tab_hash === hash.split('?')[0]) + 1) || 0}
            onChange={tab => history.push(
                {
                    hash: [
                        null,
                        ...(invoices_permission !== 'X' ? ['#invoices'] : []),
                        ...(payment_methods_permission !== 'X' ? ['#payment_methods'] : [])
                    ][tab]
                })
            }
            destroyNotSelected
            content={[
                <div key={0}>
                    <SubscriptionPlans {...passed_props} />
                </div>,
                ...(invoices_permission !== 'X' ? [<div key={1}>
                    <Invoices {...{permission: invoices_permission}} />
                </div>] : []),
                ...(payment_methods_permission !== 'X' ? [<div key={2}>
                    <PaymentMethods {...{permission: payment_methods_permission}} />
                </div>] : [])
            ]}
        />;
}
