import {useState, useEffect, useMemo} from 'react';
import {useLocation, useHistory} from 'react-router';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {List as ImmutableList} from 'immutable';
// Actions
import {setFilter, setOrdering} from 'actions/shared';


/**
 * Core of all filters
 *
 * @param supportedFilters - list of reserved filters to be used in query params
 * @param passedInitializing - indicator that component should wait with filtering
 * @private
 */
const _filtersCore = (supportedFilters, passedInitializing = false) => {
    // router
    const history = useHistory();
    const {search, pathname, hash} = useLocation();
    const searchParams = new URLSearchParams(search);
    // local state
    const [rawInitializing, setInitializing] = useState(true);
    const [initializedPath, setInitializedPath] = useState(pathname);
    const initializing = (!rawInitializing && initializedPath !== pathname)
        ? true : rawInitializing;

    /**
     * Watch for pathname changes to reinitialize filters
     * e.g. switching from /all-phones to /phones
     */
    useEffect(() => {
        // marks full reinitialization with firstMountFunction
        setInitializing(true);
        // actually trigger filter with dependency change
        setInitializedPath(pathname);
    }, [pathname]);

    /**
     * Enhance useEffect that watches for changes to apply filters
     *
     * @param filterFunction - function to filter items
     * @param firstMountFunction - function triggered during first mount
     */
    const enhanceInitialization = (filterFunction, firstMountFunction) => {
        // no need to filter
        if (passedInitializing || initializedPath !== pathname) {
            return;
        }
        // first run during mount
        if (initializing) {
            const firstMountResult = firstMountFunction();

            if (firstMountResult) {
                setInitializing(false);
            } else {
                // prevent from filtering, not yet ready
                return;
            }
        }
        filterFunction();
    };

    /**
     * Processes filters into query params
     *
     * @param data - filters object {order_by: sortName}
     * @param push - push or replace?
     */
    const pushToURL = (data, push = true) => {
        const url = {
            hash: hash,
            search: new URLSearchParams({
                // make sure to keep query params that are not handled by the ordering
                ...[...searchParams].reduce((obj, [key, value]) => supportedFilters.includes(key) ? obj : ({...obj, [key]: value}), {}),
                ...data
            }).toString()
        };
        push ? history.push(url) : history.replace(url);
    };

    return [searchParams, pushToURL, enhanceInitialization, initializing, initializedPath];
};

/** Convert sortName and sortDirection into ordering, e.g. ('user', 'desc') => '-user' */
export const getOrdering = (sortName, sortDirection) => `${sortDirection === 'desc' ? '-' : ''}${sortName}`;
/** Convert ordering into sortName and sortDirection, e.g. '-user' => ('user', 'desc') */
export const getSorting = (ordering) => ({
    sortName: ordering[0] === '-' ? ordering.substring(1) : ordering,
    sortDirection: ordering[0] === '-' ? 'desc' : 'asc'
});

/**
 * Enhance List component with local sorting of items
 *
 * @param items - immutable list of items from redux
 * @param passedInitializing - indicator that component should wait with filtering
 * @param placement - where sorted status should be stored, only if you want to store it somewhere
 * @param supportedOrdering - array of sorting to be used
 * @param initialSortName - initial field to sort items with, e.g. 'name'
 * @param initialSortDirection - direction to sort items with, 'asc' / 'desc'
 * @param specialCases - object containing names and special functions for special treatment, e.g.
 *  {sortName: (obj) => obj.get(sortName)}
 */
export const useLocalSort = (items, passedInitializing = false, placement = null, supportedOrdering = ['name'], initialSortName = 'name', initialSortDirection = 'desc', specialCases = {}) => {
    // filters core
    const [searchParams, pushToURL, enhanceInitialization, initializing, initializedPath] = _filtersCore(
        ['order_by'], passedInitializing);
    // redux store
    const dispatch = useDispatch();
    const RAWstoreOrdering = useSelector(state => placement ? state.shared.getIn(['ordering', placement]) : undefined);
    const storeOrdering = RAWstoreOrdering && supportedOrdering.includes(getSorting(RAWstoreOrdering).sortName) ? RAWstoreOrdering : undefined;
    // local state
    const [sortedItems, setSortedItems] = useState(items);
    // get ordering from query params or initial values
    const ordering = placement && supportedOrdering.includes(getSorting(searchParams.get('order_by') || '').sortName)
        ? searchParams.get('order_by')
        : getOrdering(initialSortName, initialSortDirection);
    const {sortName, sortDirection} = getSorting(ordering);

    /**
     * Watch for changes in items collection or ordering to apply sorting
     */
    useEffect(() => enhanceInitialization(_sort, () => {
        // check if we have ordering in the store
        if (placement && storeOrdering && storeOrdering !== ordering) {
            // change URL with proper query params, this will re-trigger this function
            pushToURL({order_by: storeOrdering}, false);
            return false;
        } else {
            return true;
        }
    }), [items, ordering, initializedPath, passedInitializing]);

    /**
     * Actually sort items from sortName and sortDirection (ordering)
     * @private
     */
    const _sort = () => {
        // check if we need to update ordering in store
        if (placement && (!storeOrdering || storeOrdering !== ordering)) {
            dispatch(setOrdering(placement, ordering));
        }

        // sort items
        setSortedItems(items.sort((a, b) => {
            const a_field =
                specialCases[sortName]
                    ? specialCases[sortName](a)
                    : typeof a.get(sortName) === 'string'
                        ? a.get(sortName).toLowerCase()
                        : a.get(sortName) || '';
            const b_field =
                specialCases[sortName]
                    ? specialCases[sortName](b)
                    : typeof b.get(sortName) === 'string'
                        ? b.get(sortName).toLowerCase()
                        : b.get(sortName) || '';

            if (a_field < b_field) {
                return sortDirection === 'desc' ? -1 : 1;
            } else if (a_field > b_field) {
                return sortDirection === 'desc' ? 1 : -1;
            } else {
                return 0;
            }
        }));
    };

    /**
     * Public function to trigger sorting
     *
     * @param newSortName - name to be used for sorting
     */
    const sortItems = (newSortName) => {
        // to change ordering without placement, replace initial values
        if (!placement) { return; }
        // flip sort direction in unchanged sortName
        const newSortDirection = sortName === newSortName
            ? sortDirection === 'desc' ? 'asc' : 'desc'
            : sortDirection;
        // get data to sort
        const dataToSort = newSortName === initialSortName && newSortDirection === initialSortDirection ? {}
            : {order_by: getOrdering(newSortName, newSortDirection)};

        // push to URL
        pushToURL(dataToSort);
    };

    return [sortedItems, sortItems, sortName, sortDirection, initializing];
};

/**
 * Enhance list component with filters (use this for API filtering)
 *
 * @param passedInitializing - indicator that component should wait with filtering
 * @param placement - where the data should be stored (used for setFilter)
 * @param supportedFilters - array of filters to be used
 * @param postpone - new filter will be postponed instead of immediately updated
 * @param filter - optional filter function that actually filters items
 * @param filterExtraDep - extra dependency for useEffect()
 */
export const useFilter = (passedInitializing = false, placement, supportedFilters = [], postpone = false, filter = () => {}, filterExtraDep = false) => {
    // filters core
    const [searchParams, pushToURL, enhanceInitialization, initializing, initializedPath] = _filtersCore(
        supportedFilters, passedInitializing);
    // redux store
    const dispatch = useDispatch();
    const RAWstoreFilters = useSelector(state => state.shared.getIn(['filters', placement]));
    const storeFilters = Object.fromEntries(supportedFilters
        .map(filter => [filter, (RAWstoreFilters || {})[filter]])
        .filter(([key, value]) => value));
    // local state
    const [postponedFilters, setPostponedFilters] = useState(null);
    // get filters from query params
    const filters = Object.fromEntries(supportedFilters
        // get value from searchParams for each filter
        .map(filter => [filter, searchParams.get(filter)])
        // remove empty values
        .filter(([key, value]) => value));

    /**
     * Watch for changes in items collection, filters or passed initialization to apply filtering
     */
    useEffect(() => enhanceInitialization(_filter, () => {
        // check if we have filtered data in the store
        if (storeFilters && Object.keys(storeFilters).length && !Object.keys(filters).length) {
            // change URL with proper query params, this will re-trigger this function
            pushToURL(storeFilters, false);
            return false;
        } else {
            return true;
        }
    }), [filterExtraDep, JSON.stringify(filters), initializedPath, passedInitializing]);

    /**
     * Watch postponed filters to actually trigger change
     */
    useEffect(() => {
        if (!postpone && postponedFilters !== null) {
            pushToURL(postponedFilters);
            setPostponedFilters(null);
        }
    }, [postpone, JSON.stringify(postponedFilters)]);

    /**
     * Ensure that query filters are matching store filters
     * @private
     */
    const _filter = () => {
        // check if we need to update filters in store
        if (!storeFilters || !shallowEqual(storeFilters, filters)) {
            dispatch(setFilter(placement, filters));
        }

        // actually filter items
        filter();
    };

    /**
     * Public function to trigger filtering
     *
     * @param key - name of filter
     * @param value - value to filter
     */
    const filterItems = (key = null, value = null) => {
        // get data to filter
        const dataToFilter = key === 'reset' ? {}
            : Object.fromEntries(Object.entries(
                // add new key & value to existing object
                {...filters, [key]: value})
                // remove empty values
                .filter(([key, value]) => value));

        // check if we are postponing filters or not
        if (postpone) {
            // update local state
            setPostponedFilters(dataToFilter);
        } else {
            // push to URL
            pushToURL(dataToFilter);
        }
    };

    return [filterItems, filters, initializing, postponedFilters || {}];
};


/**
 * Enhance list component with local filtering of items (search)
 *  - This expands useFilter() function that is used for API filtering.
 *
 * @param items - immutable list of items from redux
 * @param passedInitializing - indicator that component should wait with filtering
 * @param placement - where the data should be stored (used for setFilter)
 * @param supportedFilters - array of filters to be used
 * @param searchFields - array of fields to use search in, e.g.
 *  ['created_at', 'description']
 * @param specialCases - object containing names and special functions for special treatment, e.g.
 *  {
 *      filter: (obj, value) => obj.get(filter).includes(value)
 *      search_filter: (obj, searchValue, searchOptimize, value) => searchOptimize(obj.get(filter)).includes(searchValue)
 *  }
 * @param postpone - new filter will be postponed instead of immediately updated
 */
export const useLocalFilter = (items, passedInitializing = false, placement, supportedFilters = ['search'], searchFields = ['name'], specialCases = {}, postpone = false) => {
    // local state
    const [filteredItems, setFilteredItems] = useState(items);
    // filters core
    const [filterItems, filters, initializing, postponedFilters] = useFilter(passedInitializing, placement, supportedFilters, postpone, () => _filter(), items);

    /**
     * Remove spaces and lower case string for search
     * @param str - string to be optimized for search
     */
    const searchOptimize = (str) => {
        return str ? `${str}`.replace(/\s/g, '').toLowerCase() : '';
    };

    /**
     * Actually filters items from filters
     * @private
     */
    const _filter = () => {
        if (!Object.keys(filters).length) {
            // reset to default
            setFilteredItems(items);
        } else {
            setFilteredItems(items.filter(el =>
                !Object.entries(filters).map(([key, value]) =>
                    specialCases[key]
                        // special treatment
                        ? specialCases[key](el, value)
                        : key === 'search'
                            // search
                            ? !!searchFields.filter(searchField => {
                                // optimize value for search
                                const searchValue = searchOptimize(value);

                                // support special cases with search_ prefix
                                if (specialCases[`search_${searchField}`]) {
                                    return specialCases[`search_${searchField}`](el, searchValue, searchOptimize, value);
                                } else {
                                    return searchOptimize(el.get(searchField)).includes(searchValue);
                                }
                            }).length
                            // filter
                            : (Array.isArray(el.get(key)) ? el.get(key) : ([null, undefined].includes(el.get(key)) ? '' : el.get(key)).toString())
                                .includes(value)
                ).includes(false)
            ));
        }
    };

    return [filteredItems, filterItems, filters, initializing, postponedFilters];
};

/**
 * Enhance list component with selecting for Mass Actions
 *
 * @param items - immutable list of items from redux
 * @param initialItems - initially selected items
 * @param selectItems - extra function to connect selecting with external logic
 * @param resetOnChange - should selectedItems reset on change of items
 */
export const useSelecting = (items, resetOnChange = true, initialItems = ImmutableList(), selectItems = (items) => {}) => {
    const [selectedItems, setSelectedItems] = useState(initialItems);

    /**
     * Watch for changes in items collection to reset to default (initialItems)
     */
    useEffect(() => {
        setSelectedItems(initialItems);
    }, [initialItems]);
    useEffect(() => {
        if (resetOnChange) {
            setSelectedItems(initialItems);
        }
    }, [items]);

    /**
     * Controls selecting / deselecting
     *
     * @param item - item to select or string 'all' / 'clear'
     */
    const selectItem = (item) => {
        let itemsToSelect;
        if (['all', 'clear'].includes(item)) {
            // all works like toggle
            if (selectedItems.size === items.size || item === 'clear') {
                itemsToSelect = ImmutableList();
            } else {
                itemsToSelect = items;
            }
        } else {
            // check if we have item selected -> deselect
            if (selectedItems.includes(item)) {
                itemsToSelect = selectedItems.filter(el => el !== item);
            } else {
                itemsToSelect = selectedItems.push(item);
            }
        }
        // set
        setSelectedItems(itemsToSelect);
        selectItems(itemsToSelect);
    };

    return [selectedItems, selectItem];
};

/**
 * Enhance list component with local pagination
 *
 * @param items - immutable list of items from redux
 */
export const useLocalPagination = (items) => {
    const [page, setPage] = useState(1);
    const paginate_by = parseInt(process.env.REACT_APP_PAGINATION, 10);
    const pages = useMemo(() => {
        return Math.ceil(items.size / paginate_by);
    }, [items, page]);
    const paginatedItems = useMemo(() => {
        return items.slice((page - 1) * paginate_by, page * paginate_by);
    }, [items, page]);

    /**
     * Watch for changes in items collection to reset to first page
     */
    useEffect(() => {
        setPage(1);
    }, [items]);

    return [paginatedItems, page, pages, setPage];
};

/**
 * Helper function that slices Items collection with the page from page_references
 *
 * @param items - collection of items to be paginated
 * @param paginator - Paginator instance
 * @param page_references - collection of page_references
 * @param identifier - model identifier e.g. new Endpoint().getUniqueIdentifier()
 * @param slice - should we slice items or just sort by page_references
 */
export const usePaginatedItems = (items, paginator, page_references, identifier, slice = true) => {
    const page = paginator.get('page');
    const paginate_by = paginator.get('paginate_by');

    const paginatedItems = useMemo(() => {
        // slice page_references with current page and get records from items collection
        return (slice ? page_references.slice((page - 1) * paginate_by, page * paginate_by) : page_references).map(
            ref => items.find(el => el.get(identifier) === ref)).filter(el => !!el);
    }, [items, page, page_references, slice]);

    return [paginatedItems];
};
