import React, {useMemo} from 'react';
import {useLocation} from 'react-router';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {theme, portalTheme, altTheme, portalAltTheme, wildTheme} from 'theme';
// Components
import Application from 'components/Application';


/**
 * Provides Material-UI theme for whole application
 */
export default function ThemeProvider(passed_props) {
    const {alt, wild, children} = passed_props;
    const {pathname} = useLocation();
    const portal = useMemo(() => {
        return [
            '/partner', '/submission-products', '/pages', '/sales-clusters', '/distributors',
            '/pbx-partners', '/all-claims', '/all-submissions', '/asset-manager'
        ].some(page => pathname.startsWith(page));
    }, [pathname]);

    return <MuiThemeProvider theme={wild ? wildTheme : portal
        ? alt ? portalAltTheme : portalTheme
        : alt ? altTheme : theme}>
        {children || <Application />}
    </MuiThemeProvider>;
}
