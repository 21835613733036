import React, {useEffect} from 'react';
import Moment from 'moment';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import {DevChangelog} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// components
import ChangelogList from 'components/modules/changelog/ChangelogList';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles((theme) => ({
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        gap: `${theme.spacing(1)}px`,
        '& > div:last-of-type hr': {
            display: 'none'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(1)}px`
    }
}));

/**
 * Renders dev changelog of the application
 * @param {object} props - Component props
 */
export default function DevChangelogCard(props) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const {items, items_loaded, permission} = useSelector((state) => ({
        items: state.shared.getIn(['items', 'dev_changelogs']),
        items_loaded: state.shared.getIn(['loaded', 'dev_changelogs']),
        permission: ['R', 'RW'].includes(state.auth.getIn(['permissions', 'dev_changelog']))
    }), shallowEqual);

    // fetch dev changelogs without affecting state by default
    useEffect(() => {
        if (!items_loaded && permission) {
            dispatch(
                fetchItems(DevChangelog, new DevChangelog().getPlacement(), 'dev-changelog', null, null, {affect_state: false})
            );
        }
    }, [items_loaded, permission]);

    return permission ? <Card {...props}>
        <CardContent className={classes.cardContent}>
            {!items_loaded ? <LinearProgress /> : items?.toArray().map((versionItem, index) => {
                return <div key={index}>
                    <div className={classes.header}>
                        <Typography variant='h6'>
                            {versionItem.get('build_number')}
                        </Typography>
                        <Typography>
                            {Moment(versionItem.get('created_at')).format('l')}
                        </Typography>
                    </div>
                    <ChangelogList key={index} item={versionItem} />
                    <hr />
                </div>;
            })}
        </CardContent>
    </Card> : null;
}
