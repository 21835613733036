import React from 'react';
import {FormattedMessage} from 'react-intl';
// Components
import PartnerLevelIndicator from 'components/core/ui/PartnerLevelIndicator';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// icons
import PartnerLevelIcon from '@material-ui/icons/StarBorderOutlined';


/**
 * Renders card with PartnerLevelIndicator
 */
export default function PartnerLevel(props) {
    const {item} = props;

    return <Card>
        <CardHeader
            title={<FormattedMessage id='companies.detail.partnerlevel.title' />}
            subheader={<FormattedMessage id='companies.detail.partnerlevel.subheader' />}
            action={
                <ActionButton iconButton disabled>
                    <PartnerLevelIcon />
                </ActionButton>
            }
        />
        <CardContent>
            {item.get('partner_level') && <PartnerLevelIndicator partnerLevel={item.get('partner_level')} variant='block' />}
        </CardContent>
        <SpaceDivider height={12} />
    </Card>;
}
