import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        width: '100%',
        height: `${theme.spacing(2)}px`,
        '&.half': {
            height: `${theme.spacing(1)}px`
        },
        '&.double': {
            height: `${theme.spacing(4)}px`
        },
        '&.none': {
            height: '0'
        },
        '&.grid': {
            height: `${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                height: `${theme.spacing(1)}px`
            }
        },
        '&.loader': {
            height: `${theme.spacing(0.5)}px` // size of linear progress
        },
        '&.loading': {
            // bellow and above divider
            height: `${30 + (theme.spacing(2))}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                height: `${38 + (theme.spacing(1))}px`
            }
        }
    }
}));

/**
 * Space divider to divide content with... space
 *
 * Props:
 *  double - (spacing 4) double the size of divider
 *  half   - (spacing 1) half the size of divider
 *  none   - (0) zero height, it's just divider (useful in Flex <Form /> instead of <br />)
 *  grid   - (spacing 2, responsive 1) size as grid spacing
 *  loader - (spacing 0.5) size as <LinearProgress />
 *  loading - (spacing 2 + 30) huge loader to be used in combination with LinearProgress
 */
export default function SpaceDivider(props) {
    const {height, className, double, half, none, grid, loading, loader, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        className,
        classes.root,
        double ? 'double' : half ? 'half' : none ? 'none' : grid ? 'grid' : loading ? 'loading' : loader ? 'loader' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses}
                style={height ? {height: height} : undefined}
                {...rest_of_props}
    />;
}
