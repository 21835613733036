import React, {useEffect, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
import {ProductGroup, Statistics} from 'lib/models';
import {countries} from 'lib/countrieslist';
// actions
import {fetchItem, fetchItems, fetchLinks} from 'actions/shared';
import {setState} from 'actions/app';
// components
import Graph from 'components/core/ui/Graph';
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardContent from 'components/core/ui/mui/CardContent';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SummaryIcon from '@material-ui/icons/AssessmentOutlined';
import TopCompaniesIcon from '@material-ui/icons/LocationCityOutlined';
import RegTimeIcon from '@material-ui/icons/PhoneOutlined';
import RegTimeCumIcon from '@material-ui/icons/TrendingUpOutlined';
import GeoDistributionIcon from '@material-ui/icons/TrackChangesOutlined';


const useStyles = makeStyles(theme => ({
    // match heights perfectly, no matter the size of content
    matchCardContent: {
        height: `${theme.spacing(40)}px`, // 5 rows fit
        overflow: 'auto'
    }
}));

/**
 * Render general statistic
 */
export default function StatisticGeneral() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const props = useSelector(state => {
        const findStatistic = (identifier) => state.shared.getIn(['items', 'statistics']).find(el => el.get(new Statistics().getUniqueIdentifier()) === identifier)?.toJS();
        const product_group_id = new ProductGroup().getUniqueIdentifier();
        const product_groups_items = state.shared.getIn(['items', 'product-groups'])?.toJS();

        const stats_endpoints_reg_time = findStatistic('endpoints_reg_timeline');
        const stats_endpoints_reg_time_cum = findStatistic('endpoints_reg_timeline_cumulative');

        // fill the series with products
        const stats_endpoints_reg_time_series = stats_endpoints_reg_time ? [{name: 'total', ...stats_endpoints_reg_time}, ...product_groups_items?.map((item) => ({id: item[product_group_id], name: item.name, data: []})) || []] : [];
        const stats_endpoints_reg_time_cum_series = stats_endpoints_reg_time_cum ? [{name: 'total', ...stats_endpoints_reg_time_cum}, ...product_groups_items?.map((item) => ({id: item[product_group_id], name: item.name, data: []})) || []] : [];

        // fill products data
        if (stats_endpoints_reg_time?.data?.length) {
            stats_endpoints_reg_time.data.forEach((item) => {
                Object.entries(item.product_groups).forEach(([id, value]) => {
                    const foundIndex = stats_endpoints_reg_time_series.findIndex((series_item) => series_item.id === id);
                    stats_endpoints_reg_time_series[foundIndex]?.data.push({total: value, date: item.date});
                });
            });
        }
        if (stats_endpoints_reg_time_cum?.data?.length) {
            stats_endpoints_reg_time_cum.data.forEach((item) => {
                Object.entries(item.product_groups).forEach(([id, value]) => {
                    const foundIndex = stats_endpoints_reg_time_cum_series.findIndex((series_item) => series_item.id === id);
                    stats_endpoints_reg_time_cum_series[foundIndex]?.data.push({total: value, date: item.date});
                });
            });
        }

        return {
            state: state.app.get('state'),
            loaded_links: state.api.getIn(['loaded_links', 'reports']),
            stats_summary: findStatistic('summary')?.data,
            stats_endpoints_top_companies: findStatistic('endpoints_top_companies')?.data,
            stats_endpoints_reg_time: stats_endpoints_reg_time_series,
            stats_endpoints_reg_time_cum: stats_endpoints_reg_time_cum_series,
            stats_endpoints_geo_distri: findStatistic('endpoints_geo_distri')?.data,
            product_groups_loaded: state.shared.getIn(['loaded', 'product-groups'])
        };           
    }, shallowEqual);
    // Endpoints requires Product Groups
    useEffect(() => {
        if (!props.product_groups_loaded) {
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);
    // fetch statistics
    useEffect(() => {
        if (props.loaded_links === false) {
            dispatch(fetchLinks('reports', 'reports', {success_affect_state: false}));
        } else {
            const statistics = [
                {item: props.stats_summary, name: 'summary', url: 'reports.summary'},
                {item: props.stats_endpoints_top_companies, name: 'endpoints_top_companies', url: 'reports.endpoint-top-companies'},
                {item: props.stats_endpoints_reg_time, name: 'endpoints_reg_timeline', url: 'reports.endpoint-registration-timeline'},
                {item: props.stats_endpoints_reg_time_cum, name: 'endpoints_reg_timeline_cumulative', url: 'reports.endpoint-cumulative-registration-timeline'},
                {item: props.stats_endpoints_geo_distri, name: 'endpoints_geo_distri', url: 'reports.endpoint-geographical-distribution'}
            ];

            if (statistics.find(el => !el.item?.length)) {
                dispatch(setState('fetching_items_statistics'));
                // fetch statistics
                Promise.allSettled(statistics.map((el) => !el.item?.length &&
                    dispatch(fetchItem(
                        Statistics, 'statistics', el.url,
                        {expand_item: new Statistics({statistic_name: el.name}), expand_item_data: true, affect_state: false})))
                ).finally(() => dispatch(setState(null)));
            }
        }
    }, [props.loaded_links]);
    useEffect(() => {
        if (props.state === 'fetching_links_reports') {
            dispatch(setState(null));
        }
    }, [props.state]);

    const formatEndpointGeoGraphLabel = useCallback((label) => countries.find(el => el.get('id') === label.toUpperCase())?.get('name'), []);

    return <React.Fragment>
        <Card>
            <CardHeader title={<FormattedMessage id='statistics.performance.title' />}
                        subheader={<FormattedMessage id='statistics.performance.subheader' />}
                        action={<ActionButton iconButton disabled><ModelIcon model='statistics' /></ActionButton>} />
        </Card>
        <SpaceDivider grid /> 
        <React.Fragment>
            <Row wrap>
                <Col width='50%'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='statistics.general.summary.title' />}
                            subheader={<FormattedMessage id='statistics.general.summary.subheader' />}
                            action={<ActionButton iconButton disabled><SummaryIcon /></ActionButton>} />
                        <CardContent>
                            <Graph type='list' data={props.stats_summary} loading={!props.loaded_links} options={{
                                    base_intl: 'statistics.general.summary.data',
                                    labels: [
                                        'number_of_provisioning_profiles', 'average_endpoints_per_reseller',
                                        'average_profiles_per_account', 'average_settings_per_profile'
                                    ]
                                }} />
                        </CardContent>
                    </Card>
                </Col>
                <Col width='50%'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='statistics.general.endpoints_top_companies.title' />}
                                    subheader={<FormattedMessage id='statistics.general.endpoints_top_companies.subheader' />}
                                    action={<ActionButton iconButton disabled><TopCompaniesIcon /></ActionButton>} />
                        <CardContent className={classes.matchCardContent}>
                            <Graph type='list' data={props.stats_endpoints_top_companies} loading={!props.loaded_links}
                                options={{
                                    colors: [theme.graphs[1]],
                                    value_key: 'total', label_key: 'name',
                                    link: '/companies/', link_id: 'company_id'
                                }} />
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            <SpaceDivider grid />
            <Row wrap>
                <Col flex='2'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='statistics.general.endpoints_reg_time.title' />}
                                    subheader={<FormattedMessage id='statistics.general.endpoints_reg_time.subheader' />}
                                    action={<ActionButton iconButton disabled><RegTimeIcon /></ActionButton>} />
                        <CardContent>
                            <Graph type='area' data={props.stats_endpoints_reg_time} loading={!props.loaded_links}
                                momentFormat='MM/DD/YY' options={{
                                    base_intl: 'statistics.general.endpoints_reg_time.data',
                                    label_key: 'date', value_key: 'total',
                                    colors: [theme.palette.grey[700], theme.graphs[1], theme.graphs[2],
                                            theme.graphs[3], theme.graphs[4], theme.graphs[5]]
                            }} />
                        </CardContent>
                    </Card>
                </Col>
                <Col flex='1'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='statistics.general.endpoints_geo_distri.title' />}
                                    subheader={<FormattedMessage id='statistics.general.endpoints_geo_distri.subheader' />}
                                    action={<ActionButton iconButton disabled><GeoDistributionIcon /></ActionButton>} />
                        <CardContent className={classes.matchCardContent}>
                            <Graph type='list' data={props.stats_endpoints_geo_distri} loading={!props.loaded_links}
                                options={{colors: [theme.graphs[1]], label_key: 'country', value_key: 'total'}} formatLabel={formatEndpointGeoGraphLabel} />
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            <SpaceDivider grid />
            <Card>
                <CardHeader title={<FormattedMessage id='statistics.general.endpoints_reg_time_cum.title' />}
                            subheader={<FormattedMessage id='statistics.general.endpoints_reg_time_cum.subheader' />}
                            action={<ActionButton iconButton disabled><RegTimeCumIcon /></ActionButton>} />
                <CardContent>
                <Graph type='area' data={props.stats_endpoints_reg_time_cum} loading={!props.loaded_links}
                    momentFormat='MM/DD/YY' options={{
                    base_intl: 'statistics.general.endpoints_reg_time_cum.data',
                    label_key: 'date', value_key: 'total',
                    colors: [theme.palette.grey[700], theme.graphs[1], theme.graphs[2],
                            theme.graphs[3], theme.graphs[4], theme.graphs[5]]
                }} />
                </CardContent>
            </Card>
        </React.Fragment>
    </React.Fragment>;
}
