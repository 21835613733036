import React from 'react';
// Components
import Pagination from 'components/core/ui/Pagination';


/**
 * Local pagination for tables
 */
export default function LocalPagination(props) {
    const {page, pages, onChange} = props;

    return <Pagination
        page={page - 1}
        count={pages}
        onPageChange={(event, page) => onChange(page + 1)}
    />;
}
