import React, {useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {useLocalSort} from 'lib/filters';
import {User, Company, Role} from 'lib/models';
// Actions
import {setState, addMessage} from 'actions/app';
import {fetchItems, saveItem, markFiltered} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder, SalutationField, DepartmentField} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import AddUserIcon from '@material-ui/icons/PersonAddOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import SubmitIcon from '@material-ui/icons/SendOutlined';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';


/**
 * Card with form to add User to join the Company
 * props.item_company is provided via parent in companies/Detail.js
 */
function AddUser(props) {
    // local state
    const [expanded, setExpanded] = useState(0);
    const [showPasswords, setShowPasswords] = useState(false);
    // sorting
    const [locallySortedRolesByName,,,, sortInitializing] = useLocalSort(props.roles_items, !props.roles_loaded);
    const [locallySortedRoles] = useLocalSort(locallySortedRolesByName, sortInitializing, null, ['for_supergroup'], 'for_supergroup', 'asc');
    const sortedRoles = useMemo(() => {
        return props.item_is_admin ? locallySortedRoles : locallySortedRoles.filter(role => !role.get('for_supergroup'));
    }, [locallySortedRoles, props.item_is_admin]);

    return <div>
        <CardFirst>
            <CardHeader
                title={<FormattedMessage id='companies.detail.adduser.title' />}
                subheader={<FormattedMessage id='companies.detail.adduser.subheader'
                                             values={{company_name: props.item_company && <strong>{props.item_company.get('name')}</strong>}} />}
                action={<ActionButton iconButton disabled>
                    <AddUserIcon />
                </ActionButton>}
            />
        </CardFirst>
        <ExpansionPanel expanded={expanded === 1} onChange={() => setExpanded(expanded === 1 ? 0 : 1)}
                        title_intl='companies.detail.adduser.expansion.title'>
            <Form onSubmit={props.handleSubmit} center>
                <AutoCompleteOff />
                <Field name='username' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.username'})}*`} />
                <SpaceDivider none />
                <SalutationField required />
                <SpaceDivider none />
                <Field name='first_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.first_name'})}*`} />
                <SpaceDivider none />
                <Field name='last_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.last_name'})}*`} />
                <SpaceDivider none />
                <Field name='email' type='email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.email'})}*`} />
                <SpaceDivider none />
                <Field name='phone_number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.phone'})}*`} />
                <SpaceDivider none />
                <DepartmentField required />
                <SpaceDivider none />
                <Field name='role' fieldType='Select' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.role'})}*`}
                       helperText={<FormattedMessage id='companies.detail.adduser.form.fields.role.help' />}
                       disabled={props.state !== null || !props.roles_loaded || !props.permissions.get('assign_roles')}
                       loading={!props.roles_loaded}>
                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                    <MenuItem fake value={props.formValues && props.formValues.role} />
                    {sortedRoles.map((role, idx) =>
                        <MenuItem key={idx} value={role.get(new Role().getUniqueIdentifier())}>
                            {role.get('for_supergroup') ? <React.Fragment>
                                <ListItemIcon className='icon'><AdminIcon color='primary' /></ListItemIcon>
                                <ListItemText className='text'>{role.get('name')}</ListItemText>
                            </React.Fragment> : role.get('name')}
                        </MenuItem>
                    )}
                </Field>
                <SpaceDivider none />
                <Field name='password' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.password'})}*`}
                       helperText={<FormattedMessage id='companies.detail.adduser.form.fields.password.help' values={{length: 12}} />} />
                <SpaceDivider none />
                <FieldWithIconHolder>
                    <Field withIcon name='password2' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.adduser.form.fields.password2'})}*`} />
                    <FieldIcon onClick={() => setShowPasswords(!showPasswords)}>
                        {showPasswords ? <VisibilityOff /> : <Visibility />}
                    </FieldIcon>
                </FieldWithIconHolder>
                <SpaceDivider />
                <Field name='subscription' fieldType='Checkbox'
                       label={<FormattedMessage id='companies.detail.adduser.form.fields.subs' />} />
            </Form>
        </ExpansionPanel>
        <CardLast>
            {['saving_item_companyUsers_add', 'saved_item_companyUsers_add', 'failed_save_item_companyUsers_add'].includes(props.state)
                ? <CardActionsLoader success={props.state === 'saved_item_companyUsers_add'}
                                     failure={props.state === 'failed_save_item_companyUsers_add'}
                                     postAnimation={success => {
                                         props.setState(null);
                                         if (success) {
                                             // success message
                                             props.addMessage({intl_id: 'companies.detail.adduser.success', path: 'on-change'});
                                             // clear form
                                             props.reduxFormReset('companyAddUserForm');
                                         }
                                     }} />
                : <ThemeProvider alt>
                <CardActions>
                    <Button disabled={props.state !== null || !props.item_company || !props.item_company.get(new Company().getUniqueIdentifier())}
                            variant='contained' color='primary' type='submit'
                            onClick={() => props.handleSubmit()}>
                        <SubmitIcon />
                        <FormattedMessage id='companies.detail.adduser.form.submit' />
                    </Button>
                </CardActions>
            </ThemeProvider>}
        </CardLast>
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'username', data.username);
    validator.isNotNull(null, errors, 'salutation', data.salutation);
    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'phone_number', data.phone_number);
    validator.isNotNull(null, errors, 'department', data.department);
    validator.isNotNull(null, errors, 'role', data.role);
    validator.isLength(
        props.intl.formatMessage({id: 'companies.detail.adduser.error.length'}, {length: 12}), errors, 'password', data.password,
        {min: 12, max: 128}) &&
    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.containsUpperCase(props.intl.formatMessage({id: 'companies.detail.adduser.error.uppercase'}), errors, 'password', data.password) &&
    validator.containsLowerCase(props.intl.formatMessage({id: 'companies.detail.adduser.error.lowercase'}), errors, 'password', data.password) &&
    validator.containsNumber(props.intl.formatMessage({id: 'companies.detail.adduser.error.number'}), errors, 'password', data.password) &&
    validator.containsSpecialCharacter(props.intl.formatMessage({id: 'companies.detail.adduser.error.special'}), errors, 'password', data.password);
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'companies.detail.adduser.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);

    return errors;
};

const AddUserForm = reduxForm({
    form: 'companyAddUserForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send, copy object to not immediately modify it's reference
        let {password2, ...rest_of_data} = JSON.parse(JSON.stringify(props.formValues));

        return dispatch(saveItem(User, `companyUsers-${props.item_company.get(new Company().getUniqueIdentifier())}`, props.item_company.getIn(['links', 'users']), rest_of_data, null, {add_mark_filtered: false, state_name: 'companyUsers_add'})).then(() => {
            // make sure that global Users will be re-fetched
            return dispatch(markFiltered(User, 'users'));
        });
    }
})(AddUser);

const ConnectedAddUser = connect((state, props) => {
    const auth_user = state.auth.get('user');

    return {
        state: state.app.get('state'),
        roles_loaded: state.shared.getIn(['loaded', 'roles']),
        roles_items: state.shared.getIn(['items', 'roles']),
        item_is_admin: props.item_company && auth_user.isAdmin() && auth_user.getIn(['links', 'company']) === props.item_company.getIn(['links', 'self']),
        permissions: state.auth.get('permissions'),
        formValues: getFormValues('companyAddUserForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem,
    addMessage,
    markFiltered,
    reduxFormReset
}, dispatch))(AddUserForm);

export default injectIntl(ConnectedAddUser);
