import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {portalTheme} from 'theme';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {Role} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {setPermissions} from 'actions/auth';
import {fetchItem, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {InFormContent} from 'components/core/ui/Field';
import {Row, Col} from 'components/core/ui/Grid';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


const useStyles = makeStyles(theme => ({
    // Alert message to inform about role.code
    message: {
        display: 'inline-flex',
        // proportion
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        // style
        ...theme.typography.caption,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white
    },
    // small hr line under section titles (SRAPS / PC)
    sectionTitleDivider: {
        // change size
        height: `${theme.spacing(0.5)}px`,
        width: `${theme.spacing(20)}px`,
        // align
        marginLeft: '0',
        // style
        background: theme.palette.primary[500],
        backgroundImage: theme.gradients.blue.color,
        backgroundSize: theme.gradients.blue.backgroundSize,
        backgroundPosition: theme.gradients.blue.position,
        '&.portal': {
            background: portalTheme.palette.primary[500],
            backgroundImage: portalTheme.gradients.green.color,
            backgroundSize: portalTheme.gradients.green.backgroundSize,
            backgroundPosition: portalTheme.gradients.green.position
        }
    },
    // title with icon and permission name
    permissionTitle: {
        // icon
        '& > svg': {
            // style from Button.js
            fontSize: theme.typography.pxToRem(19), // lower size
            verticalAlign: 'middle',
            marginRight: `${theme.spacing(1)}px` // space between
        },
        // text
        '& > span': {
            verticalAlign: 'middle'
        }
    },
    // 'code' block with permissions paths
    code: {
        // limit on size of field
        width: '320px',
        maxWidth: '100%',
        // rather scroll then wrap to keep path as one-liner
        overflow: 'auto',
        whiteSpace: 'nowrap'
    }
}));

/**
 * Renders detail of Permission Role with it's permissions - ADD & EDIT
 */
function Detail(props) {
    const classes = useStyles();
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItem(Role, 'roles', ['roles', props.match.params.identifier]);
        }
    }, [props.match.params.identifier]);

    return <div>
        {['fetching_item_roles', 'deleting_item_roles'].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='permissions.detail.unknown.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='role' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='permissions.detail.notfound.title' />}
                text={<FormattedMessage id='permissions.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='permissions.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='permissions.detail.edit.title' />
                                : <FormattedMessage id='permissions.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='role' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'permissions.detail.form.fields.name'})}*`} />
                        <Field name='for_supergroup' fieldType='Checkbox' label={<FormattedMessage id='permissions.detail.form.fields.for_supergroup' />}
                               helperText={<FormattedMessage id='permissions.detail.form.fields.for_supergroup.help' />} />
                        <SpaceDivider none />
                        {(props.item && props.item.get('code')) && <React.Fragment>
                            <InFormContent>
                                <div className={classes.message}>
                                    <FormattedMessage id={`permissions.detail.code${['customer_admin'].includes(props.item.get('code')) ? `.${props.item.get('code')}` : ''}`} />
                                </div>
                            </InFormContent>
                            <SpaceDivider />
                        </React.Fragment>}
                        <Field name='description' fieldType='TextArea' label={<FormattedMessage id='permissions.detail.form.fields.description' />} />
                    </Form>
                </CardContent>
                <SpaceDivider />
                <CardHeader title={<FormattedMessage id='permissions.detail.form.permissions.title' />}
                            subheader={<FormattedMessage id='permissions.detail.form.permissions.subheader' />} />
                <CardContent>
                    <Row wrap>
                        {Object.keys(props.permissions_mapping).map((perm_name, idx) => <React.Fragment key={idx}>
                            {['endpoint', 'submission'].includes(perm_name) && <Col width='100%'>
                                <SpaceDivider />
                                <Typography variant='h6'>
                                    <FormattedMessage id={perm_name === 'endpoint'
                                        ? 'permissions.detail.form.permissions.sraps.title'
                                        : 'permissions.detail.form.permissions.portal.title'} />
                                </Typography>
                                <hr className={`${classes.sectionTitleDivider}${perm_name === 'endpoint' ? '' : ' portal'}`} />
                                <SpaceDivider />
                            </Col>}
                            <Col width='25%' minWidth='320px'>
                                <Typography variant='body1' className={classes.permissionTitle}>
                                    <ModelIcon model={new Role().getCorrectModelName(perm_name)} />
                                    <FormattedMessage id={`permissions.detail.form.fields.permissions.${new Role().getCorrectModelName(perm_name)}`} />
                                </Typography>
                                <SpaceDivider none />
                                <Typography variant='body2'>
                                    <FormattedMessage id='permissions.detail.form.fields.permission.help'
                                                      values={{models: props.intl.formatMessage({id: `permissions.detail.form.fields.permissions.${new Role().getCorrectModelName(perm_name)}.help`})}} />
                                </Typography>
                                <SpaceDivider />
                                <Form onSubmit={props.handleSubmit}>
                                    <Field fieldType='Select' name={`permissions.${perm_name}`}
                                           label={`${props.intl.formatMessage({id: `permissions.detail.form.fields.permissions.${new Role().getCorrectModelName(perm_name)}`})} ${props.intl.formatMessage({id: 'permissions.detail.form.fields.permission'})}*`}>
                                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                        <MenuItem value='RW'><FormattedMessage id='permfield.attrs.perm.rw.long' /></MenuItem>
                                        <MenuItem value='R'><FormattedMessage id='permfield.attrs.perm.r.long' /></MenuItem>
                                        <MenuItem value='X'><FormattedMessage id='permfield.attrs.perm.x.long' /></MenuItem>
                                    </Field>
                                    {perm_name === 'role' && <Field
                                        fieldType='Checkbox' name='permissions.assign_roles'
                                        label={<FormattedMessage id='permissions.detail.form.fields.permissions.assign_roles' />}
                                        helperText={<FormattedMessage id='permissions.detail.form.fields.permissions.assign_roles.help' />} />}
                                </Form>
                                {!!props.permissions_mapping[perm_name].length && <React.Fragment>
                                    <SpaceDivider />
                                    <code className={classes.code}>
                                        {props.permissions_mapping[perm_name].map((path, idx) =>
                                            <React.Fragment key={idx}>{path}<br /></React.Fragment>)
                                        }
                                    </code>
                                </React.Fragment>}
                                <SpaceDivider double />
                            </Col>
                        </React.Fragment>)}
                    </Row>
                </CardContent>
                {['saving_item_roles', 'saved_item_roles', 'failed_save_item_roles'].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_roles'}
                                         failure={props.state === 'failed_save_item_roles'}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/permissions')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission && <React.Fragment>
                            {edit &&
                                <Button variant='contained' color='secondary' disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>
                            }
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'permissions.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'permissions.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(Role, 'roles', props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'permissions.detail.delete.fail'}).then(result => {
                                if (result !== false) {
                                    props.history.push('/permissions');
                                }
                            });
                    }}
                />}
            </Card>
        </div>}
    </div>;
}

let validate = (data, props) => {
    const errors = {permissions: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    let nested_permissions = data.permissions || {}; // nested fix

    validator.isNotNull(null, errors, 'name', data.name);

    Object.keys(props.permissions_mapping).forEach((perm_name) => {
        validator.isNotNull(null, errors, `permissions.${perm_name}`, nested_permissions[perm_name]);
    });
    // integration of PERMISSION_INTERLINK
    if (nested_permissions.endpoint !== 'X' && [nested_permissions.provisioning_profile, nested_permissions.provisioning_files, nested_permissions.product, nested_permissions.settings, nested_permissions.company].includes('X')) {
        errors['permissions']['endpoint'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.endpoints'});
    }
    if (nested_permissions.provisioning_profile !== 'X' && [nested_permissions.provisioning_files, nested_permissions.product, nested_permissions.settings, nested_permissions.company].includes('X')) {
        errors['permissions']['provisioning_profile'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.provisioning_profiles'});
    }
    if (nested_permissions.product_defaults !== 'X' && [nested_permissions.provisioning_files, nested_permissions.product, nested_permissions.settings].includes('X')) {
        errors['permissions']['product_defaults'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.product_defaults'});
    }
    if (nested_permissions.submission_product !== 'X' && [nested_permissions.product, nested_permissions.sales_clusters].includes('X')) {
        errors['permissions']['submission_product'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.submission_products'});
    }
    if (nested_permissions.settings !== 'X' && [nested_permissions.product].includes('X')) {
        errors['permissions']['settings'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.settings'});
    }
    if (nested_permissions.user !== 'X' && [nested_permissions.company, nested_permissions.role].includes('X')) {
        errors['permissions']['user'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.users'});
    }
    if (nested_permissions.token !== 'X' && ([nested_permissions.company, nested_permissions.role].includes('X') || !nested_permissions.assign_roles)) {
        errors['permissions']['token'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.api_keys'});
    }
    if (nested_permissions.submission !== 'X' && [nested_permissions.company, nested_permissions.distributor, nested_permissions.submission_product, nested_permissions.sales_clusters].includes('X')) {
        errors['permissions']['submission'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.submissions'});
    }
    if (nested_permissions.role === 'X') {
        errors['permissions']['role'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.role'});
    }
    if (nested_permissions.subscription === 'X') {
        errors['permissions']['subscription'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.subscription'});
    }
    if (nested_permissions.provisioning_files === 'X' && [nested_permissions.provisioning_file_type, nested_permissions.product]) {
        errors['permissions']['provisioning_file'] = props.intl.formatMessage({id: 'permissions.detail.error.permissions.provisioning_files'});
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'permissionsForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct errors displayed
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new Role().popUpFields(props.formValues);

        return dispatch(saveItem(Role, 'roles', props.item ? props.item.getIn(['links', 'self']) : 'roles', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case we modified logged User Role, we must update Permissions in store
            if (create_data.get(new Role().getUniqueIdentifier()) === props.user.get('role')) {
                dispatch(setPermissions(create_data.toJS()));
            }
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new Role().getUniqueIdentifier())) {
                props.history.push(`/permissions/${create_data.get(new Role().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const item = state.shared.getIn(['items', 'roles']).find(el => el.get(new Role().getUniqueIdentifier()) === props.match.params.identifier);

    const permissions_mapping = new Role().getPermissionsMapping();
    // object with everything 'RW'
    const permissions_defaults = {
        ...Object.fromEntries(Object.keys(permissions_mapping).map(permission => [permission, 'rw'])),
        assign_roles: true
    };

    return {
        state: state.app.get('state'),
        item: item,
        initialValues: props.match.params.identifier
            ? item
                ? item.toJS()
                : undefined
            : {permissions: permissions_defaults, for_supergroup: true},
        permissions_mapping: permissions_mapping,
        user: state.auth.get('user'),
        formValues: getFormValues('permissionsForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    saveItem,
    deleteItem,
    setPermissions
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
