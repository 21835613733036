import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router';
// Actions
import {simplePost} from 'actions/shared';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';
// Components
import CardHeader from 'components/core/ui/mui/CardHeader';
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';

/**
 * Callback component that is called when a PhoneLink OAuth source either accepts/denies the authorization.
 */
function Callback(props) {
    const dispatch = useDispatch();

    const history = useHistory();
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const auth_code = searchParams.get('code');
    const error = searchParams.get('error');
    const error_description = searchParams.get('error_description');
    const identifier = props.match.params.identifier;

    useEffect(() => {
        if (error && error_description) {
            // TODO: Consider if setting an error state is appropriate here,
            //  but considering it is a deliberate user action to reject the
            //  OAuth connection, I decided to not add one and simply redirect.
            history.push('/phonelink');
        } else {
            dispatch(simplePost('phonelink-callback', props.company.getIn(['links', 'phonelink-callback']), {auth_code, identifier}))
                .then(() => history.push('/phonelink'));
                // Should redirect to the actual PhoneLink source detail page once implemented.
                // .then(() => history.push(`/phonelink/${identifier}`));
        }
    }, [auth_code, identifier, error, error_description]);

    return (
        <Card>
            <CardHeader title={<FormattedMessage id='phonelink.callback.title'/>}
                        subheader={<FormattedMessage id='phonelink.callback.subtitle'/>}/>
            <CardContent>
                <LinearProgress/>
            </CardContent>
        </Card>
    );
}

const ConnectedCallback = connect((state, props) => {
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

    return {
        company
    };
})(Callback);


export default injectIntl(withRouter(ConnectedCallback));
