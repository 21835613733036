import {Record, Map, Iterable, fromJS, List} from 'immutable';


/**
 * Convert raw data of model to model.
 * @see https://facebook.github.io/immutable-js/docs/#/fromJS
 *
 * @param Klazz - model
 * @param data - raw data of model
 */
export function asModel(Klazz, data) {
    return fromJS(data, (key, sequence) => (
        Iterable.isIndexed(sequence)
            ? sequence.toList()
            : key === 'links' || key !== '' ? new Map(sequence)
                : new Klazz(sequence)
    ));
}

/**
 * Convert raw list data of model to models.
 *
 * @param Klazz - model
 * @param data - raw data of multiple models
 */
export function listAsModel(Klazz, data) {
    return fromJS(data, (key, sequence) => {
        if (Iterable.isIndexed(sequence)) {
            return sequence.toList();
        } else {
            // special field cases
            if (key === 'minimum_firmware_version') {
                return `${new Map(sequence).get('major')}.${new Map(sequence).get('minor')}.${new Map(sequence).get('patch')}${new Map(sequence).get('build') ? `.${new Map(sequence).get('build')}` : ''}`;
            } else if (sequence.get('rebates')) {
                return new SubmissionProductVariant(sequence);
            } else
            // generic ImmutableMap instead of map
            if (['links'].includes(key) || !Number.isInteger(key)) {
                return new Map(sequence);
            }

            // special behavior for some models (with lists inside of fields)
            switch(Klazz) {
                case SubscriptionPlan:
                    if (sequence.get('unit_amount') !== undefined) {
                        return new SubscriptionTier(sequence);
                    }
                    break;
                case Product:
                    if (sequence.get('code') && sequence.get('name')) {
                        return new Klazz(sequence);
                    }
                    return new Map(sequence);
                case ProvisioningLog:
                    if (sequence.get('response_status')) {
                        return new Klazz(sequence);
                    }
                    return new Map(sequence);
                case Submission:
                    if (sequence.get('number') || sequence.get('product_code')) {
                        return new Map(sequence);
                    }
                    break;
                case Project:
                    if (sequence.get('product')) {
                        return new Map(sequence);
                    }
                    break;
                case Setting:
                case AttrsSettings:
                    if (sequence.get('indexed') && sequence.get('section')) {
                        return new Klazz(sequence);
                    } else if (sequence.get('attr_name')) {
                        return new AttrsSettings(sequence);
                    }
                    return new Map(sequence);
                case Statistics:
                    if (sequence.get('statistic_name')) {
                        return new Klazz(sequence);
                    }
                    return new Map(sequence);
                case Page:
                    if (sequence.get('page_slug')) {
                        return new Klazz({...sequence.toObject(), uuid: `${sequence.get('page_slug')}-${sequence.get('language')}`});
                    }
                    return new Map(sequence);
                case Ticket:
                case TicketGroup:
                case TicketScheduler:
                    if (sequence.get('ticket_id') || sequence.get('group_id') || sequence.get('scheduler_id')) {
                        return new Klazz(sequence);
                    }
                    return new Map(sequence);
                case DeadLetter:
                    if (sequence.get('message_id')) {
                        return new Klazz(sequence);
                    }
                    return new Map(sequence);
                case FeatureFlag:
                    if (sequence.get('context_key') && sequence.get('reference')) {
                        return new FeatureFlagRule(sequence);
                    }
                    return new Map(sequence);
            }
            return new Klazz(sequence);
        }
    });
}

/**
 * Get model Record instance from model_name
 *
 * @param model_name - string name of the model (can be either singular or plural)
 */
export function getModel(model_name) {
    switch (model_name) {
        case 'message':
        case 'messages':
            return Message;
        case 'notification':
        case 'notifications':
            return Notification;
        case 'paginator':
        case 'paginators':
            return Paginator;
        case 'endpoint':
        case 'endpoints':
            return Endpoint;
        case 'endpoints_import':
        case 'endpoints_imports':
        case 'endpoint_bulk_load_task':
            return EndpointsImport;
        case 'provisioning_profile':
        case 'provisioning_profiles':
            return ProvisioningProfile;
        case 'provisioning_file':
        case 'provisioning_files':
            return ProvisioningFile;
        case 'provisioning_file_type':
        case 'provisioning_file_types':
            return ProvisioningFileType;
        case 'provisioning_log':
        case 'provisioning_logs':
            return ProvisioningLog;
        case 'product_default':
        case 'product_defaults':
            return ProductDefaults;
        case 'firmware_update':
        case 'firmware_updates':
            return Firmware;
        case 'company':
        case 'companies':
            return Company;
        case 'user':
        case 'users':
            return User;
        case 'product_group':
        case 'product_groups':
            return ProductGroup;
        case 'product':
        case 'products':
            return Product;
        case 'submission_product':
        case 'submission_products':
            return SubmissionProduct;
        case 'role':
        case 'roles':
            return Role;
        case 'subscription':
        case 'subscriptions':
            return Subscription;
        case 'subscription_plan':
        case 'subscription_plans':
            return SubscriptionPlan;
        case 'invoice':
        case 'invoices':
            return Invoice;
        case 'payment_method':
        case 'payment_methods':
            return PaymentMethod;
        case 'setting_group':
        case 'setting_groups':
            return SettingGroup;
        case 'setting':
        case 'settings':
            return Setting;
        case 'invitation':
        case 'invitations':
            return Invitation;
        case 'export':
        case 'exports':
            return Export;
        case 'activity':
        case 'activities':
            return ActivityLog;
        case 'api_key':
        case 'api_keys':
            return ApiKey;
        case 'documentation':
            return DocVersion;
        case 'distributor':
        case 'distributors':
            return Distributor;
        case 'pbx_partner':
        case 'pbx_partners':
            return PBXPartner;
        case 'submission':
        case 'submissions':
            return Submission;
        case 'claim':
        case 'claims':
            return Claim;
        case 'page':
        case 'pages':
            return Page;
        case 'sales_cluster':
        case 'sales_clusters':
            return SalesCluster;
        case 'ticket':
        case 'tickets':
            return Ticket;
        case 'ticketgroup':
        case 'ticketgroups':
            return TicketGroup;
        case 'ticketscheduler':
        case 'ticketschedulers':
            return TicketScheduler;
        case 'dead_letter':
        case 'dead_letters':
            return DeadLetter;
        case 'dmnotification':
        case 'dmnotifications':
            return DMNotification;
        case 'phonelink':
            return PhoneLinkSource;
        default:
            return Map;
    }
}

/**
 * Informative Message to inform User what is happening
 */
export class Message extends Record({
    identifier: null, // unique identifier to help with removal and display
    intl_id: null, // text in intl
    intl_values: null, // additional values for intl (e.g. <Link />)
    text: null, // text directly (instead of intl)
    type: 'success', // success / error / warning / info
    path: null, // path where message sits until removed or 'on-change'
    remove_paths: null, // remove message when this path is reached
    strict: false, // path check will look for exact match instead of startsWith
    removable: true // can be closed by user
}) {
    getUniqueIdentifier() {
        return 'identifier';
    }
}

/**
 * Informative Notification in Header to inform User what is happening
 */
export class Notification extends Record({
    identifier: null, // unique identifier to help with removal and display
    intl_id: null, // text in intl
    intl_values: null, // additional values for intl (e.g. <Link />)
    text: null, // text directly (instead of intl)
    type: 'info', // success / error / warning / info
    link: null, // notification can work as button
    icon: null, // icon to be used
    animation: null, // pulse / success / grow
    removable: true // can be closed by user
}) {
    getUniqueIdentifier() {
        return 'identifier';
    }
}

/**
 * Model to store links for Paginated requests
 */
export class Paginator extends Record({
    page: 1, // current Page
    maxLoadedPage: null, // maximum loaded Page
    paginate_by: parseInt(process.env.REACT_APP_PAGINATION, 10),
    next: null // link to next page
}) {}

/**
 * Table to stores data from API
 */
export class Table extends Record({
    uuid: null, // our internal unique identifier
    data: null // fetched data from API
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
}

/**
 * Model to store Invitation information
 */
export class Invitation extends Record({
    email: null, // Invitation e-mail

    links: Map()
}) {}

/**
 * General User account
 */
export class User extends Record({
    username: null, // unique identifier
    salutation: null, // Mr./Mrs.
    first_name: null,
    last_name: null,
    email: null,
    phone_number: null,
    mobile_number: null,
    job_title: null, // unused, replaced with department
    department: null, // position in company
    subscription: null, // subscription to newsletter
    role: null, // relationship with Permission Role
    sraps_tos_accepted: null, // indicator for SRAPS welcome modal
    pp_tos_accepted: null, // indicator for Partner Portal welcome modal
    // relationship with Company model
    company: null,
    company_name: null,

    // extra information stored on the user account
    online_training_at: null,
    certified_engineer_at: null,

    salesforce_id: null, // connection with salesforce
    created_at: null, // date
    admin_access: null, // indicator if User is admin (taken from login request)
    links: Map()
}) {
    isAdmin() {
        return !!this.admin_access;
    }
    getUniqueIdentifier() {
        return 'username';
    }
    getPlacement() {
        return 'users';
    }
    popUpFields(data, admin) {
        const {username, company, company_name, admin_access, created_at, sraps_tos_accepted, pp_tos_accepted, links, ...rest_of_data} = data;

        if (admin) {
            return rest_of_data;
        } else {
            // pop-up additional fields which are for admin only
            const {online_training_at, certified_engineer_at, salesforce_id, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
}

/**
 * 'API User', key to give access to an API
 */
export class ApiKey extends Record({
    name: null, // name of API Key, identifier for humans
    access_key_id: null, // unique identifier (uuid)
    access_key_secret: null, // actual secret to access the API
    company: null, // relationship with Company model
    role: null, // relationship with Permission Role

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'access_key_id';
    }
    getPlacement() {
        return 'api-keys';
    }
    popUpFields(data) {
        const {access_key_id, access_key_secret, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * User belongs to some Company
 */
export class Company extends Record({
    uuid: null, // unique identifier
    name: null, // name of Company, identifier for humans
    company_type: null, // type of Company: 'reseller', 'end_user'
    status: null, // approval status of company: 'signed_up', 'verified', 'signature_pending', 'approved', 'declined'
    parent: null, // relationship with Parent Company
    // general information
    email: null,
    phone_number: null,
    website: null,
    signup_sources: null, // ['sraps', 'partner_portal']
    // address
    country: null,
    city: null,
    street: null,
    postal_code: null,
    // delivery address
    delivery_country: null,
    delivery_city: null,
    delivery_street: null,
    delivery_postal_code: null,
    // billing
    bank_name: null,
    vat: null,
    iban: null,
    bic: null,
    // api related
    provisioning_strategy: null, // strategy used for sraps provisioning
    api_enabled: null, // access to API and Documentation
    api_rate_limit: null, // requests per day
    endpoint_tickets_limit: null, // tasks per hour
    // portal related
    partner_level: null, // unverified / registered / silver / gold
    partner_active: null, // marks access to partner portal
    partner_manager: null, // NOT USED! relationship with User
    distributor: null, // relationship with Distributor
    distributor_2: null, // relationship with Distributor
    pbx_partners: null, // relationship with PBXPartners
    submission_enabled: null, // admin field that enables/disables submission creation
    // statistics
    no_provisioning_profiles: null,
    no_child_companies: null,
    no_endpoints_per_group: null,
    dm_compatible_endpoints: null,
    dm_enabled_endpoints: null,

    contract_url: null, // link to data protection contract, generates once on customer verify his email
    cluster_company_submission_enabled: null, // calculated read-only field based on submission_enabled of company and sales cluster
    salesforce_id: null, // connection with salesforce
    has_subscription: null, // relationship with Subscription
    created_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    popUpFields(data, admin) {
        const {uuid, created_at, parent, signup_sources, cluster_company_submission_enabled,
            no_provisioning_profiles, no_child_companies, no_endpoints_per_group,
            has_subscription, dm_compatible_endpoints, dm_enabled_endpoints, contract_url,
            links, ...rest_of_data} = data;

        if (admin) {
            return rest_of_data;
        } else {
            // pop-up additional fields which are for admin only
            const {provisioning_strategy, api_enabled, api_rate_limit, partner_level, partner_active, salesforce_id, submission_enabled, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
    getStatuses() {
        return ['signed_up', 'verified', 'approved', 'declined'];
    }
    // require fields for Partner Portal
    getPPRequiredFields() {
        return ['bank_name', 'vat', 'iban', 'bic', 'distributor'];
    }
}

/**
 * Permissions Roles for Users
 */
export class Role extends Record({
    uuid: null, // unique identifier
    name: null, // name of Permission Role, identifier for humans
    description: null, // description of Role
    for_supergroup: null, // indicator if Role is admin only
    code: null, // indicator for default Role
    permissions: null, // permissions itself

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'roles';
    }
    popUpFields(data) {
        const {uuid, code, links, ...rest_of_data} = data;
        return rest_of_data;
    }
    getPermissionsMapping(type = 'all') {
        const sraps_permissions = {
            'endpoint': ['/phones', '/all-phones', '/voice-quality'],
            'provisioning_profile': ['/provisioning-profiles', '/all-provisioning-profiles'],
            'provisioning_files': ['/provisioning-files', '/all-provisioning-files'],
            'provisioning_file_type': ['/provisioning-file-types'],
            'provisioning_logs': ['/phones/{mac}#logs', '/all-phones/{mac}#logs'],
            'product_defaults': ['/product-defaults', '/all-product-defaults'],
            'token': ['/api-keys', '/all-api-keys'],
            'activity': ['/activity', '/all-activity'],
            'subscription': ['/subscription'],
            'invoice': ['/subscription#invoices'],
            'payment_method': ['/subscription#payment_methods'],
            'release_notes': ['/changelog', '/all-changelogs'],
            'dev_changelog': ['/all-changelogs#dev_changelog']
        };
        const portal_permissions = {
            'submission': ['/submissions', '/all-submissions', '/claim', '/all-claims', '/projects'],
            'submission_product': ['/submission-products'],
            'distributor': ['/distributors'],
            'pbx_partner': ['/pbx-partners'],
            'page': ['/pages', '/faq', '/assets'],
            'sales_clusters': ['/sales-clusters'],
            'asset_manager': ['/asset-manager']
        };

        if (type === 'sraps') {
            return sraps_permissions;
        } else if (type === 'portal') {
            return portal_permissions;
        }
        return {
            'user': ['/customers'],
            'role': ['/permissions'],
            'company': ['/my-companies', '/companies'],
            'product': ['/product-families', '/products'],
            'settings': ['/setting-groups', '/settings'],
            'server_settings': [],
            'statistics': ['/statistics'],
            'notifications': [],
            ...sraps_permissions,
            ...portal_permissions
        };
    }
    getPermission(rawLocation) {
        const location = rawLocation.replace('/partner', '');
        const permissions_mapping = this.getPermissionsMapping();
        let permission = null;
        Object.keys(permissions_mapping).forEach((perm_name) => {
            if (permissions_mapping[perm_name].filter(url => location.startsWith(url)).length > 0) {
                permission = perm_name;
            }
        });
        return permission;
    }
    // correct inconsistency in naming and switch to plural
    getCorrectModelName(permission_name) {
        switch (permission_name) {
            case 'company':
                return 'companies';
            case 'activity':
                return 'activities';
            case 'token':
                return 'api_keys';
            case 'notifications':
                return 'dm_notifications';
            case 'asset_manager':
                return 'asset_manager';
            default:
                return `${permission_name}${permission_name.endsWith('s') ? '' : 's'}`;
        }
    }
}

/**
 * Product groups for Products
 */
export class ProductGroup extends Record({
    uuid: null, // unique identifier
    name: null, // name of Group, identifier for humans
    description: null, // description which products it contains
    vpn: null, // allows / disallows VPN field for Endpoints and Provisioning Profiles

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'product-groups';
    }
    popUpFields(data) {
        const {uuid, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * All Products - basically Phones, but not specific one
 */
export class Product extends Record({
    code: null, // unique identifier
    name: null, // name of Product, identifier for humans
    groups: null, // relationship with ProductGroup
    handsets: null, // handsets for Product Default
    device_manager_compatible: null, // compatible with device manager
    default_warranty_period: null, // length of warranty in months

    // SRAPS API related fields
    verify_mac: null,
    force_format: null,
    setting_server_strategy: null,
    update_strategy: null,
    firmware_strategy: null,
    minimum_firmware_version: null,
    upgrade_enabled: null,
    // json fields
    mac_ranges: null,
    default_settings: null,
    upgrade_firmwares: null,
    sb327_settings: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getPlacement() {
        return 'products';
    }
    popUpFields(data, return_identifier = false) {
        const {links, ...rest_of_data} = data;
        if (return_identifier) {
            const {code, ...rest_of_rest_of_data} = rest_of_data;
            return [rest_of_rest_of_data, code];
        } else {
            return rest_of_data;
        }
    }
}

/**
 * Variant used in SubmissionProduct under variants
 */
export class SubmissionProductVariant extends Record({
    code: null, // unique identifier
    name: null, // identifier for humans
    claimable: null, // claimable state, getClaimable()
    active: null, // published status
    rebates: null, // contains rebate discounts for Submissions
    image: null, // ID of file
    image_url: null, // URL of file itself

    salesforce_id: null // connection with salesforce
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getClaimable() {
        return ['not_claimable', 'claimable', 'on_request'];
    }
}

/**
 * Submission Product contains variants used in Claim requests and Submissions
 */
export class SubmissionProduct extends Record({
    code: null, // unique identifier, which MAY be relationship with Product
    name: null, // identifier for humans
    variants: null, // SubmissionProductVariants in list

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getPlacement() {
        return 'submission-products';
    }
    popUpFields(data, return_identifier = false) {
        const {links, ...rest_of_data} = data;
        if (return_identifier) {
            const {code, ...rest_of_rest_of_data} = rest_of_data;
            return [rest_of_rest_of_data, code];
        } else {
            return rest_of_data;
        }
    }
}

/**
 * Company Default Settings for specific Product
 */
export class ProductDefaults extends Record({
    code: null, // unique identifier and it's identifier of Product (relationship)
    settings: null, // Settings Manager integration
    provisioning_files: null, // relationship with ProvisioningFile(s)

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getPlacement() {
        return 'product-defaults';
    }
    popUpFields(data) {
        const {code, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * One specific Phone, endpoint to it
 */
export class Endpoint extends Record({
    mac: null, // unique identifier
    name: null, // name of Endpoint, identifier for humans
    settings: null, // we ignore this and uses Setting Manager
    settings_manager: null, // configured Setting(s)
    provisioning_profile: null, // relationship with ProvisioningProfile
    setting_server: null, // information about setting_server
    autoprovisioning_enabled: null, // confirmation status of AutoProvisioningDialog
    vpn: null, // triggers VPN feature
    custom_fields: null, // enables custom provisioning strategy, e.g. 3CX
    dm_enabled: null, // allows device manager
    data: null, // data from device manager
    status: null, // device management status, online / offline / null
    provisioning_files: null, // relationship with ProvisioningFile(s)
    // relationship with Company model
    company: null,
    company_name: null,
    // warranty
    purchase_date: null, // date when phone was purchased
    warranty_exp_warning_period: null, // set up of notification of expired warranty
    warranty_expires_at: null, // calculated date

    created_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'mac';
    }
    getPlacement() {
        return 'endpoints';
    }
    popUpFields(data) {
        const {mac, setting_server, data: dm_data, company, company_name,
            warranty_expires_at, created_at, file, links, ...rest_of_data} = data;
        return rest_of_data;
    }
    getStatuses() {
        return ['online', 'offline', null];
    }
}

/**
 * Model to represent 'Endpoint Bulk Load Task'
 */
export class EndpointsImport extends Record({
    task_id: null, // unique identifier
    provisioning_profile: null, // provisioning profile uuid
    company_id: null, // company uuid
    has_errors: null, // boolean
    last_processed: null, // number
    total: null, // number
    status: null, // string (pending, running, cancelled, completed)

    // date
    created_at: null,
    updated_at: null,
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'task_id';
    }
    getPlacement() {
        return 'endpoints-imports';
    }
    popUpFields(data) {
        const {file_id, provisioning_profile} = data;
        return {file_id, provisioning_profile};
    }
}

/**
 * Model to represent 'Endpoint Bulk Load Task Error'
 */
export class EndpointsImportError extends Record({
    task_id: null, // unique identifier
    mac: null, // endpoint mac address
    details: Map(), // error details

    // date
    created_at: null,
    updated_at: null,
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'task_id';
    }
    getPlacement() {
        return 'endpoints-imports-errors';
    }
}

/**
 * Model to represent 'Voice Quality'
 */
export class VoiceQuality extends Record({
    call_id: null, // unique identifier
    mac: null, // relationship with Endpoint
    params: null, // voice quality parameters

    created_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'call_id';
    }
    getPlacement() {
        return 'voice-quality';
    }
    getQuality(metric) {
        return ['impossible', 'very_annoying', 'annoying', 'fair', 'perfect'][Math.floor(parseFloat(metric)) - 1] || 'unknown';
    }
}

/**
 * Provisioning Profile (Settings Groups + Settings) for Endpoints
 */
export class ProvisioningProfile extends Record({
    uuid: null, // unique identifier
    name: null, // name of Profile, identifier for humans
    product_group: null, // relationship with ProductGroup
    settings: null, // configured Setting(s)
    autoprovisioning_enabled: null, // confirmation status of AutoProvisioningDialog
    vpn: null, // triggers VPN feature
    custom_fields: null, // enables custom provisioning strategy, e.g. 3CX
    dm_enabled: null, // allows device-manager
    provisioning_files: null, // relationship with ProvisioningFile(s)
    // relationship with Company model
    company: null,
    company_name: null,

    created_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'provisioning-profiles';
    }
    popUpFields(data) {
        const {uuid, created_at, company, company_name, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Type for Provisioning Files to distinct behaviour with the device itself
 */
export class ProvisioningFileType extends Record({
    code: null, // type of file, unique identifier
    name: null, // name of type, identifier for humans
    allowed_mimetypes: null,

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getPlacement() {
        return 'provisioning-file-types';
    }
    popUpFields(data, create = false) {
        const {created_at, updated_at, links, ...rest_of_data} = data;
        if (create) {
            return rest_of_data;
        } else {
            const {code, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
}

/**
 * Files that can be attached to Endpoints, Provisioning Profiles or Product Defaults
 */
export class ProvisioningFile extends Record({
    uuid: null, // unique identifier
    name: null, // name of File, identifier for humans
    type: null, // relationship with ProvisioningFileType
    product_groups: null, // relationship with Product Group(s) for filters
    file_url: null, // link to file itself
    company_default: null, // flag to indicate 'staging' XML feature
    // relationship with Company
    company: null,
    company_name: null,

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'provisioning-files';
    }
    popUpFields(data, create = false) {
        const {uuid, company, company_name, created_at, updated_at, links, ...rest_of_data} = data;
        if (create) {
            return rest_of_data;
        } else {
            const {type, product_groups, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
}

/**
 * Monitoring of Endpoint connecting to SRAPS
 */
export class ProvisioningLog extends Record({
    // response
    response_body: null, // data
    response_time: null, // date
    response_status: null, // http status code
    response_headers: null, // headers
    setting_server_only: null, // flag if server returned only setting server

    // request
    request_headers: null, // headers
    url: null, // requested url

    created_at: null, // date, used as unique identifier
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'created_at';
    }
    getPlacement() {
        return 'provisioning-logs';
    }
}

/**
 * Settings groups for Settings
 */
export class SettingGroup extends Record({
    uuid: null, // unique identifier
    name: null, // name of Group, identifier for humans
    description: null, // description which settings it contains
    product_defaults_products: null, // relationship with Product(s) for ProductDefault
    provisioning_strategy: null, // strategy used for sraps provisioning

    no_settings: 0, // statistics, how many Settings are in this Groups

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'setting-groups';
    }
    popUpFields(data) {
        const {uuid, no_settings, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Setting which can be added to Setting Group
 */
export class Setting extends Record({
    uuid: null, // unique identifier
    name: null, // label of input
    description: null, // helperText of input
    field_type: null, // type of field, e.g. 'text', 'checkbox', etc.
    param_name: null, // name of input
    group: null, // relationship with SettingGroup
    product_groups: null, // relationship with ProductGroup(s)
    section: null, // param placement section
    multiple: null, // allow multiple values
    sortable: null, // allow sorting values
    choices: null, // choices for select
    indexed: null, // is setting identity related, e.g. 1-50 settings to apply
    fkey: null, // indicator, that attrs fields should be used
    context_key: null, // indicator, that the field should be treated as a context key
    attrs: null, // AttrsSettings rendered in fkey
    default_value: null, // default value for input
    uses_permissions: null, // should setting come with attrs.perm
    allow_null: null, // supports 'empty' value
    provisioning_strategy: null, // option for custom strategy, e.g. for 3CX

    no_profiles: 0, // statistics, how many Settings are in this Groups

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'settings';
    }
    popUpFields(data) {
        const {uuid, no_profiles, links, attrs, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * AttrsSettings in Indexed Settings
 */
export class AttrsSettings extends Record({
    attr_name: null, // unique identifier, name of input
    name: null, // label of input
    description: null, // helperText of input
    field_type: null, // type of field, e.g. 'text', 'checkbox', etc.
    choices: null, // choices for select
    required: null, // true/false not null
    default_value: null, // default value for input
    inside_tag: null, // marker for backend that attr in value
    uses_permissions: false // should setting come with attrs.perm
}) {
    getUniqueIdentifier() {
        return 'attr_name';
    }
}

/**
 * Phone-staging Firmware update, Product and it's Firmware
 */
export class Firmware extends Record({
    code: null, // unique identifier and it's identifier of Product (relationship)
    version: null, // firmware version
    url: null, // some firmwares require url
    handsets: null, // update of handsets
    vpn: null, // triggers VPN feature

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'code';
    }
    getPlacement() {
        return 'firmware';
    }
    popUpFields(data) {
        const {code, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Model for Statistics
 */
export class Statistics extends Record({
    statistic_name: null, // our internal unique identifier
    data: null // fetched data from API
}) {
    getUniqueIdentifier() {
        return 'statistic_name';
    }
}

/**
 * CSV Export of various resources
 */
export class Export extends Record({
    url: null, // unique identifier link to download
    file_size: null, // size of the File
    created_at: null, // date
    model_name: null, // which exports model (e.g. endpoints)
    user: null, // relationship with User, who made Export
    // relationship with Company, which comes the data from
    parent_company: null,
    parent_company_name: null
}) {
    getUniqueIdentifier() {
        return 'url';
    }
    getPlacement() {
        return 'exports';
    }
    // get human-readable size of File
    getSize() {
        const size = this.file_size || 0;
        if (size === 0) return '0 B';
        const f = Math.floor(Math.log(size) / Math.log(1024));
        return `${parseFloat((size / Math.pow(1024, f)).toFixed(2))} ${['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][f]}`;
    }
    // correct inconsistency in naming and switch to plural
    getCorrectModelName(model_name = this.model_name) {
        switch (model_name) {
            case 'company':
                return 'companies';
            case 'free_phone_order':
                return 'claims';
            default:
                return `${model_name}s`;
        }
    }
}

/**
 * Activities happening on SRAPS
 */
export class ActivityLog extends Record({
    uuid: null, // unique identifier
    action: null, // what happen (e.g. 'create')
    message: null, // message about what happen
    created_at: null, // date
    object_name: null, // name of object (e.g. name of provisioning profile)
    model_name: null, // where activity happen (e.g. 'endpoint')
    model_id: null, // relationship with model (e.g. mac of endpoint
    user: null, // relationship with User which could made action
    token: null, // relationship with API Key which could made action
    token_name: null, // name of API Key
    // relationship with Company, where action happen
    company: null,
    company_name: null
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'activities';
    }
    // get all activity names used (in original naming)
    getModelNames() {
        return [
            'endpoint', 'endpointloadtask', 'provisioningprofile',
            'provisioningfile', 'provisioningfiletype', 'product_default',
            'firmware_update', 'company', 'user', 'productgroup', 'product',
            'submissionproduct', 'role', 'settinggroup', 'setting',
            'distributor', 'pbxpartner', 'page', 'freephoneorder',
            'submission', 'invitation', 'token', 'company_payments'
        ];
    }
    // correct inconsistency in naming, returns singular
    getCorrectModelName(model_name = this.model_name) {
        switch (model_name) {
            case 'endpointloadtask':
                return 'endpoints_import';
            case 'provisioningprofile':
                return 'provisioning_profile';
            case 'provisioningfile':
                return 'provisioning_file';
            case 'provisioningfiletype':
                return 'provisioning_file_type';
            case 'productgroup':
                return 'product_group';
            case 'submissionproduct':
                return 'submission_product';
            case 'settinggroup':
                return 'setting_group';
            case 'token':
                return 'api_key';
            case 'pbxpartner':
                return 'pbx_partner';
            case 'freephoneorder':
                return 'claim';
            case 'company_payments':
                return 'billing';
            case 'feature':
                return 'feature_flag';
            default:
                return model_name;
        }
    }
    // get link to page detail (without Model identifier) from correct Model name
    getModelLink(model_name, admin) {
        if (admin) {
            switch (model_name) {
                case 'endpoint':
                    return '/all-phones';
                case 'provisioning_profile':
                    return '/all-provisioning-profiles';
                case 'provisioning_file':
                    return '/all-provisioning-files';
                case 'provisioning_file_type':
                    return '/provisioning-file-types';
                case 'product_default':
                case 'firmware_update':
                    return '/all-product-defaults';
                case 'company':
                case 'billing':
                    return '/companies';
                case 'user':
                    return '/customers';
                case 'product_group':
                    return '/product-families';
                case 'product':
                    return '/products';
                case 'submission_product':
                    return '/submission-products';
                case 'role':
                    return '/permissions';
                case 'setting_group':
                    return '/setting-groups';
                case 'setting':
                    return '/settings';
                case 'invitation':
                    return '/companies';
                case 'api_key':
                    return '/all-api-keys';
                case 'page':
                    return '/pages';
                case 'distributor':
                    return '/distributors';
                case 'pbxpartner':
                    return '/pbx-partners';
                case 'submission':
                    return '/all-submissions';
                case 'claim':
                    return '/all-claims';
                case 'feature_flag':
                    return '/feature-flags';
                default:
                    return null;
            }
        } else {
            switch (model_name) {
                case 'endpoint':
                    return '/phones';
                case 'endpoints_import':
                    return '/phones#imports';
                case 'provisioning_profile':
                    return '/provisioning-profiles';
                case 'provisioning_file':
                    return '/provisioning-files';
                case 'product_default':
                case 'firmware_update':
                    return '/product-defaults';
                case 'company':
                case 'billing':
                    return '/my-companies';
                case 'user':
                    return '/customers';
                case 'invitation':
                    return '/my-companies';
                case 'api_key':
                    return '/api-keys';
                case 'submission':
                    return '/partner/submissions';
                case 'claim':
                    return '/partner/claim';
                default:
                    return null;
            }
        }
    }
}

/**
 * Documentation versions with link to Documentation
 */
export class DocVersion extends Record({
    version: null, // API version and unique identifier
    url: null // link to documentation
}) {
    getUniqueIdentifier() {
        return 'version';
    }
}

/**
 * Distributor to be used in Submissions
 */
export class Distributor extends Record({
    uuid: null, // unique identifier
    name: null, // name of Distributor, identifier for humans
    country: null, // distributor location, used for filters
    active: null, // published status
    url: null, // URL where user is taken
    logo: null, // ID of file
    logo_url: null, // URL of file itself

    salesforce_id: null, // connection with salesforce
    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'distributors';
    }
    popUpFields(data) {
        const {uuid, created_at, updated_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Telephone System Partner to be selected by Partner Portal clients
 */
export class PBXPartner extends Record({
    uuid: null, // unique identifier
    name: null, // name of PBX Partner, identifier for humans
    active: null, // published status

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'pbx-partners';
    }
    popUpFields(data) {
        const {uuid, created_at, updated_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Submission model to get your rebate
 */
export class Submission extends Record({
    uuid: null, // unique identifier
    status: null, // status of the submission
    distributor: null, // relationship with Distributor model
    invoices: null, // relationship with multiple {(invoice) number, file_id, file_url}
    items: null, // relationship with multiple {quantity, SubmissionProductVariants, rebate}
    note: null, // optional note made by admin
    user: null, // relationship with User
    // relationship with Company model
    company: null,
    company_name: null,

    seq_nr: null, // admin number to easily connect Submission with row in csv export
    salesforce_id: null, // connection with salesforce
    total_rebate: null, // calculation of rebate from items
    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'submissions';
    }
    popUpFields(data, admin) {
        const {uuid, user, company, company_name, salesforce_id, seq_nr, total_rebate, updated_at, created_at, links, ...rest_of_data} = data;

        if (admin) {
            return rest_of_data;
        } else {
            // pop-up additional fields which are for admin only
            const {status, note, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
    getStatuses() {
        return ['open', 'checked', 'pending', 'finished', 'declined'];
    }
}

/**
 * Project model to get your rebate
 */
export class Project extends Record({
    uuid: null, // unique identifier

    // reseller
    reseller_company_name: null,
    reseller_company_contact_person: null,
    reseller_company_email: null,
    reseller_company_phone: null,
    reseller_company_country: null,
    reseller_company_city: null,

    // end user
    enduser_company_name: null,
    enduser_company_contact_person: null,
    enduser_company_phone: null,
    enduser_company_email: null,
    enduser_company_country: null,
    enduser_company_city: null,
    enduser_company_street: null,
    enduser_company_postal_code: null,

    // additional
    project_name: null,
    project_type: null,
    project_stage: null,
    project_start_date: null, // date
    project_end_date: null, // date
    distributor: null,
    order_date: null, // date
    number_of_installments: null, // number
    snom_assistance: null,
    comment: null,
    competitor: null,
    pbx_partner: null,

    products: null, // relationship with multiple SubmissionProductVariants
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'projects';
    }
    popUpFields(data) {
        const {uuid, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Model to represent 'Claim your free Phone'
 */
export class Claim extends Record({
    status: null, // status of claimable record
    product: null, // relationship with SubmissionProductVariant model
    user: null, // relationship with User model
    // relationship with Company model
    company: null,
    company_name: null,

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'company';
    }
    getPlacement() {
        return 'claims';
    }
    popUpFields(data, admin) {
        const {uuid, company, company_name, user, created_at, updated_at, links, ...rest_of_data} = data;

        if (admin) {
            return rest_of_data;
        } else {
            // pop-up additional fields which are for admin only
            const {status, ...rest_of_rest_of_data} = rest_of_data;
            return rest_of_rest_of_data;
        }
    }
    getStatuses() {
        return ['open', 'in_progress', 'finished', 'declined'];
    }
}

/**
 * Model to represent 'Asset Folder'
 */
export class AssetFolder extends Record({
    uuid: null, // unique identifier
    name: null, // folder name
    parent_uuid: null, // parent folder, null means root

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'asset_folders';
    }
    popUpFields({name, parent_uuid}) {
        return {name, parent_uuid};
    }
}

/**
 * Model to represent 'Asset File'
 */
export class AssetFile extends Record({
    uuid: null, // unique identifier
    name: null, // folder name
    parent_uuid: null, // parent file, null means root

    mimetype: null, // file MIME type
    file_size: null, // fil size
    thumbnail_url: null, // file thumbnail url
    file_url: null, // link to file
    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'asset_files';
    }
    popUpFields({name, parent_uuid, asset_file_id}) {
        return {name, parent_uuid, asset_file_id};
    }
}

/**
 * Dynamic Pages
 */
export class Page extends Record({
    uuid: null, // our internal unique identifier generated in pagesListAsModel
    page_slug: null, // identifier of page
    language: null, // language of content
    content: null, // raw data to render

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'pages';
    }
    popUpFields(data) {
        const {uuid, created_at, updated_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
    getIdentifiers() {
        return ['faq', 'assets'];
    }
}

/**
 * Model to represent Sales cluster
 */
export class SalesCluster extends Record({
    uuid: null, // unique identifier
    name: null, // name of cluster, identifier for humans
    countries: null, // included countries
    submission_enabled: null, // allow/disabled companies from creating submission
    key: null, // stripe api key
    endpoint_secret: null, // stripe api secret
    free_dm: null, // free device management

    created_at: null, // date
    updated_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'sales_clusters';
    }
    popUpFields(data) {
        const {uuid, links, created_at, updated_at, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Information about Company current subscription
 */
export class Subscription extends Record({
    id: null, // unique identifier
    status: null, // active/inactive
    plan_id: null, // relationship with SubscriptionPlan
    cancel_at_period_end: null, // indicator if subscription is cancelled
    dm_enabled_endpoints: null, // how many endpoints used in dm
    trial_active: null, // is the Subscription in the tiral period

    name: null, // unused, stripe name
    currency: null, // unused, selected currency

    current_period_start: null, // date, start of subscription
    current_period_end: null, // dat, end of subscription
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'id';
    }
}

/**
 * Individual tier of SubscriptionPlan
 */
export class SubscriptionTier extends Record({
    unit_amount: null, // price e.g. 5 (means 5 € per unit)
    up_to: null // up to amount of endpoints, e.g. 100 (means 0-100 endpoints)
}) {}

/**
 * Stripe Subscription plans
 */
export class SubscriptionPlan extends Record({
    id: null, // unique identifier, stripe id
    code: null, // identifier for frontend, e.g. 'basic'
    active: null, // can be selected
    limit: null, // number of allowed endpoints for device management

    name: null, // unused, stripe name
    description: null, // unused, basic information about plan

    price: null, // price, e.g. 3
    currency: null, // price currency, e.g. 'eur'
    interval: null, // price interval, e.g. 'month'
    interval_count: null, // how often, e.g. 1 (means 1 per month)
    tiers: null, // relationship with SubscriptionTier
    trial_days: null, // trial period
    trial_limit: 10, // limit of devices in trial period, temporary hardcoded

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'id';
    }
    getPlacement() {
        return 'subscription-plans';
    }
}

/**
 * Stripe invoice
 */
export class Invoice extends Record({
    id: null, // unique identifier
    currency: null, // price currency, e.g. 'eur'
    amount: null, // price
    status: null, // paid status
    pdf_link: null, // link to invoice file itself
    customer_email: null, // assigned e-mail

    created: null, // date
    due_date: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'id';
    }
    getPlacement() {
        return 'invoices';
    }
    getStatuses() {
        return ['draft', 'open', 'paid', 'uncollectible', 'void'];
    }
}

/**
 * Stored Card in stripe for payments
 */
export class PaymentMethod extends Record({
    id: null, // unique identifier
    kind: null, // type of method, e.g. card
    brand: null, // brand of card, e.g. mastercard
    exp_month: null, // expiration date
    exp_year: null, // expiration date
    last4: null, // last 4 digits for human identification
    default: null, // is default payment method flag

    created: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'id';
    }
    getPlacement() {
        return 'payment-methods';
    }
}

/**
 * Device Management Endpoint Ticket represents device task
 */
export class Ticket extends Record({
    ticket_id: null, // unique identifier
    params: null, // data used for set-up
    status: null, // current status: 'pending', 'sent', etc.
    type: null, // action type: 'get', 'operate', etc.
    result_file: null, // optional generated file

    schedule: null, // relationship with TicketScheduler
    endpoint_mac: null, // relationship with Endpoint
    company_id: null, // relationship with Company

    // dates
    scheduled_at: null, // optional trigger in future
    created_at: null,
    updated_at: null,
    finished_at: null,
    expires_at: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'ticket_id';
    }
    getPlacement() {
        return 'tickets';
    }
    popUpFields(data) {
        const {ticket_id, status, endpoint_mac, company_id, result_file, created_at, updated_at, finished_at, expires_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
    getStatuses() {
        return ['scheduled', 'pending', 'sent', 'expired', 'error', 'resolved'];
    }
    getTypes() {
        return [
            'operate_reboot', 'operate_factory_reset', 'resync', 'upgrade',
            'get_common', 'get_syslog', 'get_sip_trace', 'get_settings'
            // NOT IMPLEMENTED:
            // 'get_gui_state', 'get_xml_minibrowser'
        ];
    }
}

/**
 * Device Management Provisioning Profile group of Endpoint Tickets
 */
export class TicketGroup extends Record({
    group_id: null, // unique identifier
    params: null, // data used for set-up
    status: null, // current status: 'created', 'open', etc.
    type: null, // action type: 'get', 'operate', etc. identical with Ticket.type

    provisioning_profile: null, // relationship with Provisioning Profile
    company_id: null, // relationship with Company

    // dates
    scheduled_at: null, // optional trigger in future
    created_at: null,
    updated_at: null,
    finished_at: null,

    no_tickets: null, // number of Tickets inside this group
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'group_id';
    }
    getPlacement() {
        return 'ticket-groups';
    }
    popUpFields(data) {
        const {group_id, status, provisioning_profile, company_id, created_at, updated_at, finished_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Device Management Scheduler periodically creates Tickets
 */
export class TicketScheduler extends Record({
    scheduler_id: null, // unique identifier
    name: null, // human identifier
    schedule: null, // cron field to set up re-triggers
    params: null, // data used for set-up
    status: null, // current status: 'active', 'inactive'
    type: null, // action type: 'get', 'operate', etc. identical with Ticket.type

    endpoint_mac: null, // relationship with Endpoint
    company_id: null, // relationship with Company

    // dates
    created_at: null,
    updated_at: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'scheduler_id';
    }
    getPlacement() {
        return 'ticket-schedulers';
    }
    popUpFields(data) {
        const {scheduler_id, endpoint_mac, company_id, created_at, updated_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * Device Management Notifications to inform about bad call quality and so on
 */
export class DMNotification extends Record({
    notification_id: null, // unique identifier
    message: null, // notification indicator
    type: 'info', // success / error / warning / info
    seen: null, // marked status

    mac: null, // relationship with Endpoint
    provisioning_profile_id: null, // relationship with Provisioning Profile
    company_id: null, // relationship with Company

    created_at: null, // date
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'notification_id';
    }
    getPlacement() {
        return 'dm_notifications';
    }
    popUpFields(data) {
        const {notification_id, message, mac, provisioning_profile_id, company_id, created_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * When the background task fails, dead letter is created to inform about errors
 */
export class DeadLetter extends Record({
    id: null, // unique identifier (redis id)
    message_id: null, // id in logs
    actor_name: null,  // name of the function
    queue_name: null, // name of the runner

    args: null, // function arguments
    options: null,

    created_at: null, // date
    ttl: null, // date when message will delete by itself

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'id';
    }
    getPlacement() {
        return 'dead-letters';
    }
}

/**
 * Feature Flag definition that provides additional info for validations
 */
export class FeatureFlagDefinition extends Record({
    name: null, // feature flag relationship
    description: null,
    allowed_context_values: null, // list of contexts

    // not used
    default_state: null,
    is_static: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'name';
    }
    getPlacement() {
        return 'feature-flags-definition';
    }
}

/**
 * Feature flag rule model
 */
export class FeatureFlagRule extends Record({
    operator: null, // logical operator
    context_key: null, // feature context
    reference: null, // id list
    logical_operator: null // connects multiple rules together
}) {}

/**
 * Feature flag model containing rules
 */
export class FeatureFlag extends Record({
    name: null, // feature flag name
    enabled: null, // feature is on/off
    rules: null, // list of FeatureFlagRules

    // dates
    created_at: null,
    updated_at: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'name';
    }
    getPlacement() {
        return 'feature-flags';
    }
    popUpFields(data) {
        const {updated_at, created_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * ReleaseNote model containing lists of changes
 */
export class ReleaseNote extends Record({
    uuid: null, // unique identifier
    version: null, // version number
    language: null, // language of release note
    type: null, // type of release: release, regular update, special update, etc.
    description: null, // description of release

    // lists of changes in release
    added: List(),
    fixed: List(),
    changed: List(),
    deprecated: List(),
    removed: List(),

    // dates
    created_at: null,
    updated_at: null,

    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'release_notes';
    }
    getChangelogTypes() {
        return ['added', 'changed', 'fixed', 'removed', 'deprecated'];
    }
    popUpFields(data) {
        const {uuid, updated_at, links, ...rest_of_data} = data;
        return rest_of_data;
    }
}

/**
 * DevChangelog model (read-only)
 */
export class DevChangelog extends Record({
    build_number: null, // build number
    project: null, // project name
    // lists of changes in release
    added: List(),
    fixed: List(),
    changed: List(),
    deprecated: List(),
    removed: List(),

    // dates
    created_at: null,

    // links
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'dev_changelogs';
    }
}

/**
 * PhoneLinkSource model (3rd party data source)
 */
export class PhoneLinkSource extends Record({
    name: null,
    description: null,
    website: null,
    oauth_url: null,
    connected: null,
    identifier: null,
    strategy_description: null,
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'identifier';
    }
    getPlacement() {
        return 'phonelink-source';
    }
}

/**
 * Statistics about an individual PhoneLink migration.
 */
export class PhoneLinkMigrationReport extends Record({
    uuid: null,
    source_identifier: null,
    created_at: null,
    found: null,
    migrated: null,
    already_exists: null,
    failed: null,
    user: null,
    errors: null,
    links: Map()
}) {
    getUniqueIdentifier() {
        return 'uuid';
    }
    getPlacement() {
        return 'phonelink-migration-report';
    }
}
