import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
import Moment from 'moment';
import {shallowEqual, useSelector} from 'react-redux';
import {Company} from 'lib/models';
// Components
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Link} from 'react-router-dom';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import Button from 'components/core/ui/mui/Button';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import HomeWave from 'components/core/vectors/waves/PPHome';
import SnommifiedWave from 'components/core/vectors/waves/Snommifed';
import AssistanceIcon from '@material-ui/icons/ContactSupportOutlined';
import DisclosureIcon from '@material-ui/icons/PostAddOutlined';
import WorldIcon from '@material-ui/icons/PublicOutlined';
// flags
import EnFlag from 'components/core/vectors/flags/en';
import DeFlag from 'components/core/vectors/flags/de';
import AtFlag from 'components/core/vectors/flags/at';
import ChFlag from 'components/core/vectors/flags/ch';
import EsFlag from 'components/core/vectors/flags/es';
import PtFlag from 'components/core/vectors/flags/pt';
import RuFlag from 'components/core/vectors/flags/ru';
import ItFlag from 'components/core/vectors/flags/it';
import FrFlag from 'components/core/vectors/flags/fr';
import FiFlag from 'components/core/vectors/flags/fi';
import SeFlag from 'components/core/vectors/flags/se';
import NoFlag from 'components/core/vectors/flags/no';


const useStyles = makeStyles(theme => ({
    // holder of welcome image
    welcomeHeader: {
        position: 'relative',
        // style
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        '& .wave-holder': {
            position: 'absolute',
            zIndex: '20',
            bottom: '0',
            right: '0',
            height: '80%',
            width: '100%',
            // wave
            '& svg': {
                position: 'absolute',
                bottom: '0',
                right: '0'
            }
        },
        '& .image-holder': {
            '& > img': {
                width: '100%',
                display: 'block'
            }
        },
        // text content
        '& .content': {
            position: 'absolute',
            bottom: `${theme.spacing(5)}px`,
            left: '0',
            right: '0',
            // style
            textAlign: 'center',
            color: theme.palette.common.white,
            // boost title
            '& h2': {
                fontWeight: '500'
            },
            [theme.breakpoints.down('md')]: {
                bottom: `${theme.spacing(1)}px`,
                // reduce size of title
                '& h2': {
                    fontSize: theme.typography.pxToRem(40)
                }
            }
        }
    },
    // welcome text
    welcomeContent: {
        // enlarge CardContent spacing
        '&, &:last-child': {
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
        }
    },
    // disclosure text with button
    disclosureContent: {
        position: 'relative',
        zIndex: '20',
        // move into image
        marginBottom: `-${theme.spacing(6)}px`,
        // enlarge CardContent spacing
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        // button and text next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // responsive
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            textAlign: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0'
        }
    },
    // 'get snommifed' slogan, wave and image
    disclosureImageHolder: {
        position: 'relative',
        zIndex: '10',
        // slogan
        '& h3': {
            position: 'absolute',
            zIndex: '20',
            top: '23%',
            left: '35%',
            right: '0',
            textAlign: 'center',
            // style
            color: theme.palette.common.white,
            // responsive
            [theme.breakpoints.down('xs')]: {
                // reposition
                position: 'relative',
                top: '0',
                left: '0',
                // proportion
                margin: `${theme.spacing(2)}px 0 -${theme.spacing(2)}px`,
                padding: `0 ${theme.spacing(2)}px`,
                // restyle
                color: theme.palette.snomGrey[800],
                // reduce size
                fontSize: theme.typography.pxToRem(40)
            }
        },
        // wave
        '& svg': {
            position: 'relative',
            zIndex: '10',
            // move into image
            marginBottom: '-25%'
        },
        // image
        '& img': {
            display: 'block',
            width: '100%'
        }
    },
    // CardContent description about FAQ
    contactFAQ: {
        position: 'relative',
        // enlarge CardContent spacing
        padding: `${theme.spacing(4)}px`,
        '& > p': {
            position: 'relative',
            zIndex: '20'
        },
        // gradient border
        '&:before': {
            display: 'block',
            content: '""',
            position: 'absolute',
            zIndex: '10',
            top: '0',
            left: '-1px',
            right: '-1px',
            bottom: '0',
            // style (wave gradient)
            backgroundColor: '#FAE333',
            backgroundImage: 'linear-gradient(90deg, #FAE333 0%, #83E599 100%)',
            backgroundOrigin: 'border-box',
            border: '2px solid transparent',
            boxShadow: `0px 1000px 0 ${theme.palette.common.white} inset`
        }
    },
    // CardContent with Contacts
    contacts: {
        '&, &:last-child': {
            padding: `${theme.spacing(3)}px 0`
        }
    },
    // holder of Contact
    contact: {
        padding: `0 ${theme.spacing(2)}px`,
        '&:last-of-type ~ hr': {
            display: 'none'
        },
        // title with icon
        '& h6': {
            // align flag and title next to each other
            display: 'flex',
            alignItems: 'flex-start',
            // style
            fontSize: theme.typography.pxToRem(16),
            // space between title and contact info
            margin: `0 0 ${theme.spacing(0.5)}px`,
            // language flag
            '& svg': {
                // space between icon and title
                marginRight: `${theme.spacing(1)}px`
            },
            '& span': {
                display: 'inline-block',
                marginTop: '2px'
            }
        }
    }
}));

/**
 * Main page of Partner Portal
 */
export default function Portal() {
    // redux
    const props = useSelector(state => ({
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);
    // styles & intl
    const classes = useStyles();
    const intl = useIntl();

    return <Row wrap>
        <Col width='66.6667%'>
            <Card>
                <div className={classes.welcomeHeader}>
                    <div className='image-holder'>
                        <img src={require('img/portal/sraps-team.jpg')} alt={intl.formatMessage({id: 'home.portal.image.alt'})} />
                    </div>
                    <div className='wave-holder'>
                        <HomeWave />
                        <div className='content'>
                            <h2 className='newfont nomargin'><FormattedMessage id='home.portal.welcome' /></h2>
                        </div>
                    </div>
                </div>
                <CardContent className={classes.welcomeContent}>
                    <p className='newfont mop'>
                        <strong>
                            <FormattedMessage id='home.portal.text.1' /><br />
                            <FormattedMessage id='home.portal.text.2' />
                        </strong>
                    </p>
                    <p className='newfont'><FormattedMessage id='home.portal.text.3' /></p>
                    <p className='newfont'><FormattedMessage id='home.portal.text.4' /></p>
                    <p className='newfont'><FormattedMessage id='home.portal.text.5' /></p>
                    <p className='newfont'>
                        <strong>
                            <FormattedMessage id='home.portal.text.6' /><br />
                            <FormattedMessage id='home.portal.text.7' />
                        </strong>
                    </p>
                </CardContent>
            </Card>
            <SpaceDivider grid />
            <Card>
                <CardContent className={classes.disclosureContent}>
                    <p className='newfont'>
                        <FormattedMessage id='home.portal.disclosure.subheader' />
                    </p>
                    <Button variant='contained' color='primary'
                            component='a' target='_blank' rel='noreferrer'
                            href={`https://snom.equeo.de/?id=${props.company.get(new Company().getUniqueIdentifier())}&lang=${Moment.locale()}&redirectUrl=${encodeURIComponent(window.location.href)}`}>
                        <DisclosureIcon />
                        <FormattedMessage id='home.portal.disclosure.button' />
                    </Button>
                </CardContent>
                <div className={classes.disclosureImageHolder}>
                    <h3 className='newfont nomargin'><FormattedMessage id='home.portal.disclosure.title' /></h3>
                    <SnommifiedWave />
                    <img src={require('img/portal/office_d785.jpg')} alt='D785' />
                </div>
            </Card>
        </Col>
        <Col width='33.3333%'>
            <Card>
                <CardHeader
                    title={<FormattedMessage id='home.portal.assistance.title' />}
                    subheader={<FormattedMessage id='home.portal.assistance.subheader' />}
                    action={<ActionButton iconButton disabled>
                        <AssistanceIcon />
                    </ActionButton>} />
                <CardContent className={classes.contactFAQ}>
                    <p className='newfont nomargin'>
                        <FormattedMessage id='home.portal.assistance.description'
                                          values={{faq_link: <Link to='/partner/faq' className='hover-border'>
                                                      <FormattedMessage id='home.portal.assistance.description.faq_link' />
                                                  </Link>}} />
                    </p>
                </CardContent>
                <CardContent className={classes.contacts}>
                    {[
                        ['dach', [<DeFlag />, <AtFlag />, <ChFlag />], 'partner_dach@snom.com'],
                        ['uk', <EnFlag />, 'partner_uk@snom.com'],
                        ['italy', <ItFlag />, 'partner_it@snom.com'],
                        ['france', <FrFlag />, 'partner_fr@snom.com'],
                        ['nordics', [<FiFlag />, <SeFlag />, <NoFlag />], 'partner_nor@snom.com'],
                        ['iberics', [<EsFlag />, <PtFlag />], 'partner_iberics@snom.com'],
                        ['russia', <RuFlag />, 'partner_ru@snom.com'],
                        ['rest', <WorldIcon />, 'partner_world@snom.com']
                    ].map(([title_end_intl, icons, email]) => <React.Fragment key={title_end_intl}>
                            <div className={classes.contact}>
                                <h6 className='newfont'>
                                    {!icons.length ? icons : icons.map((icon, idx) => <React.Fragment key={idx}>{icon}</React.Fragment>)}<FormattedMessage id={`home.portal.assistance.contacts.${title_end_intl}`} />
                                </h6>
                                <p className='nomargin newfont'><a href={`mailto:${email}`} className='hover-border'>{email}</a></p>
                            </div>
                            <hr />
                    </React.Fragment>)}
                </CardContent>
            </Card>
        </Col>
    </Row>;
}
