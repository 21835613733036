import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import {Switch, Redirect} from 'react-router';
import {Route} from 'lib/sentry';
import validator, {initValidator} from 'lib/valitator';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {User, Role, Company, FeatureFlag} from 'lib/models';
import {evaluateFeatureFlag} from 'lib/featureFlag';
// Actions
import {setPermission} from 'actions/auth';
// Components
import Websocket from 'components/core/ui/Websocket';
import InitialDialog from 'components/core/ui/InitialDialog';
import SubscriptionDialog from 'components/modules/subscription/GlobalDialog';
import FormRestoration from 'components/core/ui/FormRestoration';
import Error from 'components/core/ui/Error';
import Messages from 'components/core/ui/Messages';
import Header from 'components/core/ui/Header';
import Menu from 'components/core/ui/Menu';
import Footer from 'components/core/ui/Footer';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Container} from 'components/core/ui/Grid';
import {withRouter} from 'react-router-dom';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// Pages
import Login from 'components/modules/auth/Login';
import ForgottenPassword from 'components/modules/auth/ForgottenPassword';
import Register from 'components/modules/auth/Register';
import Invitation from 'components/modules/auth/Invitation';
import NotApproved from 'components/modules/auth/NotApproved';
import HomeSRAPS from 'components/modules/plain/SRAPS';
import CustomerChangelog from 'components/modules/changelog/CustomerChangelog';
import DashboardSRAPS from 'components/modules/dashboard/SRAPS';
import HomePortal from 'components/modules/plain/Portal';
import AboutPortal from 'components/modules/plain/AboutPortal';
import DashboardPortal from 'components/modules/dashboard/Portal';
import Endpoints from 'components/modules/endpoints/Endpoints';
import EndpointDetail from 'components/modules/endpoints/Detail';
import EndpointsImportsDetail from 'components/modules/endpointsimports/Detail';
import VoiceQualityDetail from 'components/modules/voicequality/Detail';
import ProvisioningProfiles from 'components/modules/provprofiles/List';
import ProvisioningProfileDetail from 'components/modules/provprofiles/Detail';
import ProvisioningFiles from 'components/modules/provfiles/List';
import ProvisioningFileDetail from 'components/modules/provfiles/Detail';
import ProvisioningFileTypes from 'components/modules/provfiletypes/List';
import ProvisioningFileTypeDetail from 'components/modules/provfiletypes/Detail';
import ProductDefaults from 'components/modules/productdefaults/List';
import ProductDefaultDetail from 'components/modules/productdefaults/Detail';
import Submissions from 'components/modules/submissions/List';
import SubmissionDetail from 'components/modules/submissions/Detail';
import Claims from 'components/modules/claims/List';
import ClaimDetail from 'components/modules/claims/Detail';
import Assets from 'components/modules/assets/List';
import Projects from 'components/modules/projects/List';
import ProjectDetail from 'components/modules/projects/Detail';
import StatisticGeneral from './modules/statistics/StatisticGeneral';
import StatisticUsers from 'components/modules/statistics/StatisticUsers';
import StatisticPerformance from 'components/modules/statistics/StatisticPerformance';
import Companies from 'components/modules/companies/List';
import CompanyDetail from 'components/modules/companies/Detail';
import Users from 'components/modules/users/List';
import UserDetail from 'components/modules/users/Detail';
import Subscriptions from 'components/modules/subscription/Subscriptions';
import Exports from 'components/modules/exports/List';
import Activities from 'components/modules/activities/List';
import PhoneLink from 'components/modules/phonelink/List';
import PhoneLinkCallback from 'components/modules/phonelink/Callback';
import PhoneLinkDetail from 'components/modules/phonelink/Detail';
import DeadLetterQueue from 'components/modules/deadletters/List';
import DeadLetterDetail from 'components/modules/deadletters/Detail';
import FeatureFlags from 'components/modules/featureFlags/List';
import FeatureFlagDetail from 'components/modules/featureFlags/Detail';
import Changelogs from 'components/modules/changelog/List';
import ChangelogDetail from 'components/modules/changelog/Detail';
import APIKeys from 'components/modules/apikeys/List';
import APIKeyDetail from 'components/modules/apikeys/Detail';
import Documentation from 'components/modules/documentation/List';
import ProductGroups from 'components/modules/productgroups/List';
import ProductGroupDetail from 'components/modules/productgroups/Detail';
import Permissions from 'components/modules/permissions/List';
import PermissionsDetail from 'components/modules/permissions/Detail';
import Products from 'components/modules/products/List';
import ProductDetail from 'components/modules/products/Detail';
import SubmissionProducts from 'components/modules/submissionproducts/List';
import SubmissionProductDetail from 'components/modules/submissionproducts/Detail';
import SettingGroups from 'components/modules/settinggroups/List';
import SettingGroupDetail from 'components/modules/settinggroups/Detail';
import SalesClusters from 'components/modules/salesclusters/List';
import SalesClusterDetail from 'components/modules/salesclusters/Detail';
import Pages from 'components/modules/pages/List';
import PageDetail from 'components/modules/pages/Detail';
import DynamicPage from 'components/modules/pages/DynamicPage';
import CustomerDistributors from 'components/modules/distributors/CustomerList';
import Distributors from 'components/modules/distributors/List';
import DistributorDetail from 'components/modules/distributors/Detail';
import PBXPartners from 'components/modules/pbxpartners/List';
import PBXPartnerDetail from 'components/modules/pbxpartners/Detail';
import Settings from 'components/modules/settings/List';
import SettingDetail from 'components/modules/settings/Detail';
import NotFound from 'components/modules/NotFound';
import Unauthorized from 'components/modules/Unauthorized';
import AlreadyLogged from 'components/modules/auth/AlreadyLogged';
import Unallowed from 'components/modules/Unallowed';
// icons
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import UnavailableIcon from '@material-ui/icons/SignalCellularOffOutlined';
import MaintenanceIcon from '@material-ui/icons/BuildOutlined';


const global = theme => ({
    '@global': {
        '*, *:before, *:after': {
            boxSizing: 'border-box',
            outline: '0 !important'
        },
        'button::-moz-focus-inner': {
            border: '0'
        },
        'html': {
            minHeight: '100%',
            position: 'relative'
        },
        'body': {
            background: theme.palette.snomGrey[100],
            margin: '0',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.body2.lineHeight,
            color: theme.palette.text['primary'],
            overflowY: 'scroll',
            scrollBehavior: 'smooth',
            // size of footer
            paddingBottom: '92px',
            [theme.breakpoints.up('md')]: {
                paddingBottom: '52px'
            }
        },
        'p': {
            ...theme.typography.body2
        },
        'h1': {
            ...theme.typography.h1
        },
        'h2': {
            ...theme.typography.h2
        },
        'h3': {
            ...theme.typography.h3
        },
        'h4': {
            ...theme.typography.h4
        },
        'h5': {
            ...theme.typography.h5
        },
        'h6': {
            ...theme.typography.h6
        },
        '.newfont': {
            fontFamily: `"DINRoundPro", ${theme.typography.fontFamily}`,
            letterSpacing: 'normal',
            '& strong': {
                fontWeight: '500'
            }
        },
        'p.newfont': {
            fontSize: theme.typography.pxToRem(16)
        },
        '.subtitle1': {
            ...theme.typography.subtitle1
        },
        '.subtitle2': {
            ...theme.typography.subtitle2
        },
        '.text-primary': {
            color: theme.palette.primary[500]
        },
        '.text-secondary': {
            color: theme.palette.secondary[500]
        },
        '.text-success': {
            color: theme.palette.success[500]
        },
        '.text-warning': {
            color: theme.palette.orange[500]
        },
        '.text-danger, .text-failure, .text-error': {
            color: theme.palette.danger[500]
        },
        '.text-grey': {
            color: theme.palette.grey[500]
        },
        '.text-right': {
            textAlign: 'right'
        },
        '.text-center': {
            textAlign: 'center'
        },
        '.text-justify': {
            textAlign: 'justify'
        },
        '.text-capitalize': {
            textTransform: 'capitalize'
        },
        '.notmargin': {
            marginTop: '0'
        },
        '.nobmargin': {
            marginBottom: '0'
        },
        '.nomargin': {
            margin: '0'
        },
        '.nowrap': {
            whiteSpace: 'nowrap'
        },
        'a': {
            display: 'inline-block',
            color: theme.palette.primary[500],
            textDecoration: 'none',
            transition: theme.transitions.create('color',
                {duration: theme.transitions.duration.short}),
            // optional hover-border effect
            '&.hover-border': {
                position: 'relative'
            },
            '&.hover-border:after': {
                content: '""',
                width: '0',
                height: '1px',
                background: 'transparent',
                display: 'block',
                margin: '0 auto',
                position: 'absolute',
                left: '0',
                right: '0',
                transition: theme.transitions.create(['background', 'width'],
                    {duration: theme.transitions.duration.short})
            },
            '&:not(.MuiButton-root):hover': {
                // change color
                color: theme.palette.primary[700]
            },
            '&.hover-border:hover:after': {
                background: theme.palette.primary[700],
                width: '100%'
            }
        },
        '.divide-links': {
            '&:after': {
                content: '", "'
            },
            '&:last-child:after': {
                content: 'none'
            }
        },
        'hr': {
            margin: `${theme.spacing(2)}px auto`,
            height: '1px',
            width: '100%',
            border: '0',
            background: theme.palette.grey[300],
            '&.none': {
                margin: '0 auto'
            },
            '&.gold': {
                background: theme.palette.primary[500]
            }
        },
        'img, svg, iframe': {
            maxWidth: '100%',
            border: '0'
        },
        // 16:9 for iframes
        '.embed': {
            paddingBottom: '56.25%',
            position: 'relative',
            background: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            '& iframe': {
                position: 'absolute',
                left: '0',
                top: '0',
                bottom: '0',
                right: '0',
                height: '100% !important',
                width: '100% !important'
            }
        },
        'table': {
            tableLayout: 'fixed',
            borderSpacing: '0',
            borderCollapse: 'collapse',
            width: '100%',
            maxWidth: '100%',
            '& tr, & td': {
                padding: '0',
                margin: '0'
            }
        },
        'pre': {
            fontFamily: '"Roboto Mono", monospace',
            margin: 0
        },
        'code': {
            display: 'block',
            fontFamily: '"Roboto Mono", monospace',
            padding: `${theme.spacing(1)}px`,
            background: theme.palette.grey[200],
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.shape.borderRadius
        },
        '.mono': {
            fontFamily: '"Roboto Mono", monospace'
        }
    },
    contentHolder: {
        display: 'flex',
        width: '100%'
    },
    main: {
        flexGrow: '1',
        minWidth: '0'
    }
});

const styles = theme => ({
    ...global(theme)
});

/**
 * Main application to render routes
 */
class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedRoutes: []
        };
    }

    /**
     * Localize validator and set Routes and Permissions
     */
    componentDidMount() {
        validator.defaultMessages = {
            isNotNull: this.props.intl.formatMessage({id: 'errors.validator.required'}),
            isURL: this.props.intl.formatMessage({id: 'errors.validator.url'}),
            isEmail: this.props.intl.formatMessage({id: 'errors.validator.email'}),
            isInt: this.props.intl.formatMessage({id: 'errors.validator.number'}),
            isHexColor: this.props.intl.formatMessage({id: 'errors.validator.hexcolor'}),
            isIP: this.props.intl.formatMessage({id: 'errors.validator.ipaddress'}),
            isURLList: [
                this.props.intl.formatMessage({id: 'errors.validator.url_list_1'}),
                this.props.intl.formatMessage({id: 'errors.validator.url_list_2'})
            ],
            isSlug: this.props.intl.formatMessage({id: 'errors.validator.slug'}),
            isDateTime: this.props.intl.formatMessage({id: 'errors.validator.datetime'}),
            isInFuture: this.props.intl.formatMessage({id: 'errors.validator.infuture'}),
            areValidMimeTypes: this.props.intl.formatMessage({id: 'errors.validator.invalid_mimetypes'}),
            __generic__: this.props.intl.formatMessage({id: 'errors.validator.generic'})
        };
        initValidator(validator);
        this.setPermission();
        this.setLoggedRoutes();
    }

    /**
     * Listen to router to scroll page to top and history to update permission
     * Also watch for changes that could affect routes which are cached in the state
     *
     * @param prevProps - Props from previous state
     */
    componentDidUpdate(prevProps) {
        // split locations
        let prevLocationSplit = prevProps.location.pathname.split('/');
        let newLocationSplit = this.props.location.pathname.split('/');
        // pop last part (/add, /detail)
        let prevLocationPop = prevLocationSplit.pop();
        newLocationSplit.pop();
        // route change, exclude create to edit change ('/add' => '/detail')
        if (prevProps.location.pathname !== this.props.location.pathname &&
            !(prevLocationPop === 'add' && prevLocationSplit.toString() === newLocationSplit.toString())) {
            // set new permission
            this.setPermission();
            // jump to top (as normal website behaviour), exclude '/documentation'
            if (prevProps.location.pathname.startsWith('/documentation') !== this.props.location.pathname.startsWith('/documentation')) {
                window.scrollTo(0, 0);
            }
        }

        // note: this is also shared with Menu.js
        // permission change
        if (this.props.permissions !== prevProps.permissions ||
            this.props.featureFlags !== prevProps.featureFlags ||
            this.props.srapsAccess !== prevProps.srapsAccess ||
            this.props.portalAccess !== prevProps.portalAccess) {
            this.setLoggedRoutes();
        // company change
        } else if (this.props.company && prevProps.company && (
            this.props.company.get('company_type') !== prevProps.company.get('company_type') ||
            this.props.company.get('api_enabled') !== prevProps.company.get('api_enabled') ||
            this.props.company.get('submission_enabled') !== prevProps.company.get('submission_enabled')
        )) {
            this.setLoggedRoutes();
        // user pp_tos_accepted / sraps_tos_accepted change
        } else if (this.props.user.get(new User().getUniqueIdentifier()) && prevProps.user.get(new User().getUniqueIdentifier()) && (
            this.props.user.get('pp_tos_accepted') !== prevProps.user.get('pp_tos_accepted') ||
            this.props.user.get('sraps_tos_accepted') !== prevProps.user.get('sraps_tos_accepted')
        )) {
            this.setLoggedRoutes();
        }
    }

    /**
     * Set 'permission' to the store for easy access (e.g. from Fields)
     */
    setPermission() {
        let permission = new Role().getPermission(this.props.location.pathname);
        if (permission !== this.props.permission) {
            this.props.setPermission(permission);
        }
    }

    /**
     * Render Route with additional permission check
     *
     * @param paths - URL location(s) with shared permission e.g. '/phones' or ['/phones/add', '/phones/:identifier']
     * @param Component - Component to render, e.g. Home
     * @param extra_props - Extra props for Component e.g. {type: 'general'}
     */
    renderRoute(paths, Component, extra_props = {}) {
        // in case of one path provided
        if (!Array.isArray(paths)) {
            paths = [paths];
        }
        let permission_name = new Role().getPermission(paths[0]); // paths have to share permission!
        let permission;
        let routes = [];

        if (permission_name) {
            permission = this.props.permissions.get(permission_name);
            // no access paths and read-only '/add' paths should return Unauthorized component
            if (['X', 'R'].includes(permission)) {
                let paths_to_remove = [];
                paths.forEach(path => {
                    if (path.endsWith('/add') || (permission === 'X' && !['/profile', '/company'].includes(path))) {
                        paths_to_remove.push(path);
                        routes.push(<Route key={path} path={path} exact component={Unauthorized} />);
                    }
                });
                paths = paths.filter(path => !paths_to_remove.includes(path));
            }
            // special cases - exclude submission /add for flagged company
            if (permission_name === 'submission' && this.props.company?.get('cluster_company_submission_enabled') === false) {
                paths = paths.filter(path => path !== '/partner/submissions/add');
                routes.push(<Route key='/partner/submissions/add' path='/partner/submissions/add' exact component={Unauthorized} />);
            }
        }

        if (paths.length) {
            routes.push(<Route key={paths} path={paths} exact render={() => <Component permission={permission} {...extra_props} />} />);
        }
        return routes.length > 1 ? routes : routes[0];
    }

    /**
     * Set 'loggedRoutes' with it's permission to the component state
     */
    setLoggedRoutes() {
        // make sure we are really logged
        if (!this.props.user.get(new User().getUniqueIdentifier()) || !this.props.company) {
            return this.setState({loggedRoutes: []});
        }

        let routes = [];
        // dynamic paths
        let companies_list_paths = [];
        let company_detail_paths = [];
        let user_detail_paths = [];
        let submissions_list_paths = [];
        let submission_detail_paths = [];
        let claim_detail_paths = [];

        // access to Partner Portal
        if (this.props.portalAccess) {
            // default Partner Portal routes
            routes = [...routes,
                this.renderRoute('/partner', Redirect, {to: '/partner/home'}),
                this.renderRoute('/partner/home', HomePortal)
            ];

            if (this.props.user.get('pp_tos_accepted')) {
                company_detail_paths = ['/partner/company'];
                user_detail_paths = ['/partner/customers/:identifier', '/partner/profile'];
                submissions_list_paths = ['/partner/submissions'];
                submission_detail_paths = ['/partner/submissions/add', '/partner/submissions/:identifier'];
                claim_detail_paths = ['/partner/claim'];

                // build Partner Portal only routes
                routes = [...routes,
                    this.renderRoute('/partner/dashboard', DashboardPortal),
                    this.renderRoute('/partner/about', AboutPortal),
                    this.renderRoute('/partner/faq', DynamicPage, {identifier: 'faq'}),
                    this.renderRoute('/partner/distributors', CustomerDistributors),
                    this.renderRoute('/partner/projects', Projects),
                    this.renderRoute(['/partner/projects/add', '/partner/projects/:identifier'], ProjectDetail)
                ];
                // partner_assets feature flag
                if (evaluateFeatureFlag(this.props.featureFlags?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'partner_assets'), {company: this.props.company.get(new Company().getUniqueIdentifier())})) {
                    routes = [...routes, this.renderRoute('/partner/assets', DynamicPage, {identifier: 'assets'})];
                }
            }
        } else {
            // default Partner Portal routes (no access)
            routes = [...routes,
                this.renderRoute('/partner', Redirect, {to: '/partner/home'}),
                this.renderRoute('/partner/home', Unallowed, {canRequestPortalAccess: this.props.canRequestPortalAccess})
            ];
        }

        // access to SRAPS
        if (this.props.srapsAccess) {
            // default SRAPS routes
            routes = [...routes,
                this.renderRoute('/', Redirect, {to: '/home'}),
                this.renderRoute('/home', HomeSRAPS)
            ];

            const company = this.props.company.get(new Company().getUniqueIdentifier());

            const featureFlag_releaseNotes = evaluateFeatureFlag(this.props.featureFlags?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'release_notes'), {company});
            if (featureFlag_releaseNotes) {
                routes = [...routes, this.renderRoute('/changelog', CustomerChangelog)];
            }

            const featureFlag_phoneLink = evaluateFeatureFlag(this.props.featureFlags?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'phonelink'), {company});
            if (featureFlag_phoneLink) {
                let phonelink_paths = ['/phonelink'];
                let phonelink_callback_paths = ['/phonelink/:identifier/callback'];
                let phonelink_detail_paths = ['/phonelink/:identifier'];

                routes = [
                    ...routes,
                    this.renderRoute(phonelink_paths, PhoneLink),
                    this.renderRoute(phonelink_callback_paths, PhoneLinkCallback),
                    this.renderRoute(phonelink_detail_paths, PhoneLinkDetail)
                ];
            }

            if (this.props.user.get('sraps_tos_accepted')) {
                let endpoints_list_paths = ['/phones'];
                let endpoint_detail_paths = ['/phones/add', '/phones/:identifier'];
                let endpoints_imports_detail_paths = ['/phones/imports/:identifier'];
                let provprofiles_list_paths = ['/provisioning-profiles'];
                let provprofile_detail_paths = ['/provisioning-profiles/add', '/provisioning-profiles/:identifier'];
                let provfiles_list_paths = ['/provisioning-files'];
                let provfile_detail_paths = ['/provisioning-files/add'];
                let productdefaults_list_paths = ['/product-defaults'];
                let productdefault_detail_paths = ['/product-defaults/add', '/product-defaults/:identifier'];
                let apikeys_list_paths = [];
                let apikey_detail_paths = [];
                let exports_path = ['/exports'];
                let activities_paths = ['/activity'];

                company_detail_paths = ['/company', ...company_detail_paths];
                user_detail_paths = ['/customers/:identifier', '/profile', ...user_detail_paths];

                // reseller routes
                if (this.props.company.get('company_type') === 'reseller') {
                    companies_list_paths = ['/my-companies'];
                    company_detail_paths = ['/my-companies/add', '/my-companies/:identifier', ...company_detail_paths];
                }

                // api-enabled routes
                if (this.props.company.get('api_enabled')) {
                    apikeys_list_paths = ['/api-keys'];
                    apikey_detail_paths = ['/api-keys/add', '/api-keys/:identifier'];

                    // api-enabled only routes
                    routes = [...routes,
                        this.renderRoute('/documentation', Documentation)
                    ];
                }

                // admin routes
                if (this.props.user.isAdmin()) {
                    endpoints_list_paths = [...endpoints_list_paths, '/all-phones'];
                    endpoint_detail_paths = [...endpoint_detail_paths, '/all-phones/add', '/all-phones/:identifier'];
                    provprofiles_list_paths = [...provprofiles_list_paths, '/all-provisioning-profiles'];
                    provprofile_detail_paths = [...provprofile_detail_paths, '/all-provisioning-profiles/add', '/all-provisioning-profiles/:identifier'];
                    provfiles_list_paths = [...provfiles_list_paths, '/all-provisioning-files'];
                    provfile_detail_paths = [...provfile_detail_paths, '/all-provisioning-files/add'];
                    productdefaults_list_paths = [...productdefaults_list_paths, '/all-product-defaults'];
                    productdefault_detail_paths = [...productdefault_detail_paths, '/all-product-defaults/add', '/all-product-defaults/:identifier'];
                    submissions_list_paths = [...submissions_list_paths, '/all-submissions'];
                    submission_detail_paths = [...submission_detail_paths, '/all-submissions/add', '/all-submissions/:identifier'];
                    claim_detail_paths = [...claim_detail_paths, '/all-claims/:identifier'];
                    apikeys_list_paths = [...apikeys_list_paths, '/all-api-keys'];
                    apikey_detail_paths = [...apikey_detail_paths, '/all-api-keys/add', '/all-api-keys/:identifier'];
                    companies_list_paths = [...companies_list_paths, '/companies'];
                    company_detail_paths = ['/companies/:identifier', ...company_detail_paths];
                    exports_path = [...exports_path, '/all-exports'];
                    activities_paths = [...activities_paths, '/all-activity'];

                    // admin only routes
                    routes = [...routes,
                        this.renderRoute('/statistics/performance', StatisticPerformance),
                        this.renderRoute('/statistics/general', StatisticGeneral),
                        this.renderRoute('/statistics/users', StatisticUsers),
                        this.renderRoute('/permissions', Permissions),
                        this.renderRoute(['/permissions/add', '/permissions/:identifier'], PermissionsDetail),
                        this.renderRoute('/provisioning-file-types', ProvisioningFileTypes),
                        this.renderRoute(['/provisioning-file-types/add', '/provisioning-file-types/:identifier'], ProvisioningFileTypeDetail),
                        this.renderRoute('/product-families', ProductGroups),
                        this.renderRoute(['/product-families/add', '/product-families/:identifier'], ProductGroupDetail),
                        this.renderRoute('/products', Products),
                        this.renderRoute(['/products/add', '/products/:identifier'], ProductDetail),
                        this.renderRoute('/submission-products', SubmissionProducts),
                        this.renderRoute(['/submission-products/add', '/submission-products/:identifier'], SubmissionProductDetail),
                        this.renderRoute('/setting-groups', SettingGroups),
                        this.renderRoute(['/setting-groups/add', '/setting-groups/:identifier'], SettingGroupDetail),
                        this.renderRoute('/pages', Pages),
                        this.renderRoute(['/pages/add', '/pages/:identifier'], PageDetail),
                        this.renderRoute('/sales-clusters', SalesClusters),
                        this.renderRoute(['/sales-clusters/add', '/sales-clusters/:identifier'], SalesClusterDetail),
                        this.renderRoute('/distributors', Distributors),
                        this.renderRoute(['/distributors/add', '/distributors/:identifier'], DistributorDetail),
                        this.renderRoute('/pbx-partners', PBXPartners),
                        this.renderRoute(['/pbx-partners/add', '/pbx-partners/:identifier'], PBXPartnerDetail),
                        this.renderRoute('/settings', Settings),
                        this.renderRoute(['/settings/add', '/settings/:identifier'], SettingDetail),
                        this.renderRoute('/customers', Users),
                        this.renderRoute('/all-claims', Claims),
                        this.renderRoute(['/asset-manager', '/asset-manager/add', '/asset-manager/:identifier', '/asset-manager/:identifier/add',
                            '/asset-manager/:identifier/edit', '/asset-manager/:identifier/move', '/asset-manager/:identifier/delete',
                            '/asset-manager/:identifier/files/:file_identifier/delete', '/asset-manager/:identifier/files/:file_identifier/rename',
                            '/asset-manager/:identifier/files/:file_identifier/move', '/asset-manager/:identifier/files/add'
                        ], Assets),
                        this.renderRoute(['/feature-flags'], FeatureFlags),
                        this.renderRoute(['/feature-flags/add', '/feature-flags/:identifier'], FeatureFlagDetail)
                    ];
                    // hide if feature flag is disallowed
                    if (featureFlag_releaseNotes) {
                        routes = [...routes, this.renderRoute(['/all-changelogs'], Changelogs),
                            this.renderRoute(['/all-changelogs/add', '/all-changelogs/:identifier'], ChangelogDetail)];
                    }
                    if (evaluateFeatureFlag(this.props.featureFlags?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'dead_letter_queue'), {company: this.props.company.get(new Company().getUniqueIdentifier())})) {
                        routes = [...routes, this.renderRoute(['/dead-letter-queue'], DeadLetterQueue),
                            this.renderRoute(['/dead-letter-queue/:identifier'], DeadLetterDetail)];
                    }
                }

                // build SRAPS only routes
                routes = [...routes,
                    this.renderRoute(endpoints_list_paths, Endpoints),
                    this.renderRoute(endpoint_detail_paths, EndpointDetail),
                    this.renderRoute(endpoints_imports_detail_paths, EndpointsImportsDetail),
                    this.renderRoute(provprofiles_list_paths, ProvisioningProfiles),
                    this.renderRoute(provprofile_detail_paths, ProvisioningProfileDetail),
                    this.renderRoute(provfiles_list_paths, ProvisioningFiles),
                    this.renderRoute(provfile_detail_paths, ProvisioningFileDetail),
                    this.renderRoute(productdefaults_list_paths, ProductDefaults),
                    this.renderRoute(productdefault_detail_paths, ProductDefaultDetail),
                    this.renderRoute(exports_path, Exports),
                    this.renderRoute(activities_paths, Activities)
                ];
                // Companies list only for reseller or admin
                if (companies_list_paths.length) {
                    routes = [...routes,
                        this.renderRoute(companies_list_paths, Companies)
                    ];
                }
                // API section only for Companies with enabled api or admin
                if (apikeys_list_paths.length && apikey_detail_paths.length) {
                    routes = [...routes,
                        this.renderRoute(apikeys_list_paths, APIKeys),
                        this.renderRoute(apikey_detail_paths, APIKeyDetail)
                    ];
                }
                // Device Management related routes
                const featureFlag_deviceManager = evaluateFeatureFlag(this.props.featureFlags?.find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === 'device_manager'), {company: this.props.company.get(new Company().getUniqueIdentifier())});
                if (featureFlag_deviceManager && this.props.company?.get('has_subscription')) {
                    routes = [...routes,
                        this.renderRoute('/dashboard', DashboardSRAPS),
                        this.renderRoute('/phones/voice-quality/:identifier', VoiceQualityDetail)
                    ];
                }
                // Subscription is binded with Device Manager
                if (featureFlag_deviceManager) {
                    routes = [...routes,
                        this.renderRoute('/subscription', Subscriptions)
                    ];
                }
            }
        } else {
            // default SRAPS routes (no access)
            routes = [...routes,
                this.renderRoute('/', Redirect, {to: '/home'}),
                this.renderRoute('/home', Unallowed)
            ];
        }

        // User and Company detail only with access to either Partner Portal or admin
        if (company_detail_paths.length && user_detail_paths.length) {
            routes = [...routes,
                this.renderRoute(company_detail_paths, CompanyDetail, {portalAccess: this.props.portalAccess}),
                this.renderRoute(user_detail_paths, UserDetail)
            ];
        }
        // Submissions list & detail only for Partner Portal or admin
        if (submissions_list_paths.length && submission_detail_paths.length) {
            routes = [...routes,
                this.renderRoute(submissions_list_paths, Submissions),
                this.renderRoute(submission_detail_paths, SubmissionDetail)
            ];
        }
        // Claim detail only for Partner Portal or admin
        if (claim_detail_paths.length) {
            routes = [...routes, this.renderRoute(claim_detail_paths, ClaimDetail)];
        }

        this.setState({loggedRoutes: routes});
    }

    render() {
        const initialDialog =
            (!this.props.user.get('pp_tos_accepted') && this.props.portal && this.props.portalAccess) ||
            (!this.props.user.get('sraps_tos_accepted') && !this.props.portal && this.props.srapsAccess);

        return <React.Fragment>
            <Helmet>
                <title>{this.props.portal ? 'Partner Portal' : 'SRAPS'}</title>
                <link rel='shortcut icon' href={this.props.portal
                    ? require('img/favicons/portal.ico')
                    : require('img/favicons/sraps.ico')
                } />
            </Helmet>
            <Header srapsAccess={this.props.srapsAccess} portalAccess={this.props.portalAccess} />
            <div className={this.props.classes.contentHolder}>
                {(['ready', 'payment_required'].includes(this.props.apiState) && this.props.authState === 'logged') && <Menu
                    srapsAccess={this.props.srapsAccess} portalAccess={this.props.portalAccess}
                    canRequestPortalAccess={this.props.canRequestPortalAccess}
                />}
                <div className={this.props.classes.main}>
                    <SpaceDivider grid />
                    {['ready', 'payment_required'].includes(this.props.apiState) && this.props.authState !== 'logging_out'
                        ? this.props.authState === 'logged'
                            ? <Container key={0} fullsize={this.props.location.pathname.startsWith('/documentation') && !['loading', 'error'].includes(this.props.docState) && this.props.state !== 'fetching_items_doc-versions'}>
                        <Websocket />
                        <Error />
                        <Messages />
                        <Switch>
                            {this.state.loggedRoutes.map((route) => route)}
                            <Route path={[
                                '/registration', '/invitation', '/forgotten-password',
                                '/partner/registration', '/partner/invitation', '/partner/forgotten-password'
                            ]} exact component={AlreadyLogged} />
                            {!initialDialog && <Route component={NotFound} />}
                        </Switch>
                        {initialDialog && <InitialDialog />}
                        <SubscriptionDialog />
                    </Container>
                            : <Container limited>
                        <Error />
                        <Messages />
                        <Switch>
                            <Route path={['/registration', '/partner/registration']} exact component={Register} />
                            <Route path={['/invitation', '/partner/invitation']} exact component={Invitation} />
                            <Route path={['/forgotten-password', '/partner/forgotten-password']} exact component={ForgottenPassword} />
                            <Route path={['/not-approved', '/partner/not-approved']} exact component={NotApproved} />
                            <Route path={['/', '/partner']} exact component={Login} />
                            <Redirect path='/partner' to='/partner' />
                            <Redirect to='/' />
                        </Switch>
                    </Container>
                        : this.props.apiState === 'fetching' || this.props.authState === 'logging_out'
                        ? <Container key={1} limited>
                        <Card>
                            <CardContent>
                                <SpaceDivider loading />
                                <LinearProgress />
                                <SpaceDivider loading />
                            </CardContent>
                        </Card>
                    </Container>
                        : this.props.apiState === 'unavailable'
                        ? <Container limited>
                        <ErrorCard
                            refreshIcon
                            title={<FormattedMessage id={`errors${this.props.portal ? '.portal' : ''}.unavailable.title`} />}
                            text={<FormattedMessage id={`errors${this.props.portal ? '.portal' : ''}.unavailable.text`} />}
                            icon={<UnavailableIcon color='secondary' />} />
                    </Container>
                        : this.props.apiState === 'maintenance'
                        ? <Container limited>
                        <ErrorCard
                            title={<FormattedMessage id={`errors${this.props.portal ? '.portal' : ''}.maintenance.title`} />}
                            text={<FormattedMessage id={`errors${this.props.portal ? '.portal' : ''}.maintenance.text`} />}
                            icon={<MaintenanceIcon color='secondary' />} />
                    </Container>
                        : this.props.apiState === 'error'
                        ? <Container limited>
                        <ErrorCard
                            refreshIcon
                            title={<FormattedMessage id='errors.general.title' />}
                            text={<FormattedMessage id='errors.general.text' />}
                            icon={<ErrorIcon color='secondary' />} />
                    </Container>
                        : <div />}
                    <SpaceDivider grid />
                    <Footer />
                </div>
            </div>
            <FormRestoration />
        </React.Fragment>;
    }
}

Application = withStyles(styles)(Application);
Application = connect((state, props) => {
    const auth_user = state.auth.get('user');
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));

    const permissions = state.auth.get('permissions');
    const sraps_permissions = Object.keys(new Role().getPermissionsMapping('sraps')).map(permission => permissions.get(permission));
    const portal_permissions = Object.keys(new Role().getPermissionsMapping('portal')).map(permission => permissions.get(permission));

    return {
        featureFlags: state.shared.getIn(['items', 'feature-flags']),
        portal: props.location.pathname.startsWith('/partner'),
        portalAccess: (auth_user.isAdmin() || portal_permissions.includes('R') || portal_permissions.includes('RW')) && (company && company.get('company_type') === 'reseller' && !['unverified', 'declined'].includes(company.get('partner_level')) && company.get('partner_active') === true),
        canRequestPortalAccess: company && company.get('company_type') === 'reseller' && company.get('partner_level') !== 'declined' && (portal_permissions.includes('R') || portal_permissions.includes('RW')),
        srapsAccess: auth_user.isAdmin() || sraps_permissions.includes('R') || sraps_permissions.includes('RW'),
        state: state.app.get('state'),
        apiState: state.api.get('state'),
        authState: state.auth.get('state'),
        docState: state.shared.getIn(['state', 'documentation']),
        user: auth_user,
        permissions: permissions,
        permission: state.auth.get('permission'),
        company: company
    };
}, (dispatch) => bindActionCreators({
    setPermission
}, dispatch))(Application);

Application = withRouter(injectIntl(Application));
export default Application;
