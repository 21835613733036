import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {addMessage} from 'actions/app';
import {setState as setAuthState, fetchInvitation, setInvitation, login} from 'actions/auth';
import {simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder, SalutationField, DepartmentField} from 'components/core/ui/Field';
import ToSDialog from 'components/core/ui/ToSDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import InvitationWave from 'components/core/vectors/waves/Invitation';
import SubmitIcon from '@material-ui/icons/CheckOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';


const useStyles = makeStyles(theme => ({
    wave: {
        // move into content
        marginBottom: `-${theme.spacing(8)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            marginBottom: `-${theme.spacing(4)}px`
        }
    }
}));

/**
 * Registration via invitation
 */
function Invitation(props) {
    const classes = useStyles();
    // local state
    const [showPasswords, setShowPasswords] = useState(false);
    const [tosDialogOpen, setTosDialogOpen] = useState(false);

    /**
     * During initialization fetch invitation data
     */
    useEffect(() => {
        props.fetchInvitation({token: props.token}, props.portal);
        // cleanup, make sure to clear invitation
        return () => props.setInvitation(null);
    }, []);
    useEffect(() => {
        // in case of invalid token redirect to login
        if (props.authState === 'invalid_token') {
            props.setAuthState(null);
            props.history.push(props.portal ? '/partner' : '/');
        }
    }, [props.authState]);

    return <Card>
        <InvitationWave className={classes.wave} />
        <CardHeader title={<FormattedMessage id={`invitation${props.portal ? '.portal' : ''}.title`} />}
                    subheader={<FormattedMessage id='invitation.subheader' />} />
        <CardContent>
            {['fetching_invitation', 'invalid_token'].includes(props.authState)
                ? <LinearProgress />
                : <Form onSubmit={props.handleSubmit}>
                <Field name='username' fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.username'})}*`} />
                <SalutationField required />
                <SpaceDivider none />
                <Field name='first_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.first_name'})}*`} />
                <Field name='last_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.last_name'})}*`} />
                <SpaceDivider none />
                <Field disabled={true} name='email' type='email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.email'})}*`} />
                <Field name='phone_number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.phone'})}*`} />
                <SpaceDivider none />
                <DepartmentField name='department' required />
                <SpaceDivider double />
                <Field name='password' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.password'})}*`}
                       helperText={<FormattedMessage id='invitation.form.fields.password.help' values={{length: 12}} />} />
                <FieldWithIconHolder>
                    <Field withIcon name='password2' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'invitation.form.fields.password2'})}*`} />
                    <FieldIcon onClick={() => setShowPasswords(!showPasswords)}>
                        {showPasswords ? <VisibilityOff /> : <Visibility />}
                    </FieldIcon>
                </FieldWithIconHolder>
                <SpaceDivider />
                <Field name='subscription' fieldType='Checkbox'
                       size='full' className='flip'
                       label={<FormattedMessage id='invitation.form.fields.subs' />} />
                <SpaceDivider none />
                <Field name='tos' fieldType='Checkbox'
                       size='full' className='flip'
                       label={props.portal
                           ? <React.Fragment>
                               <FormattedMessage id='tosfield.portal.description'
                                                 values={{
                                                     toc_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.toc.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                         <FormattedMessage id='tosfield.portal.description.toc' />
                                                     </a>,
                                                     pp_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.pp.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                         <FormattedMessage id='tosfield.portal.description.pp' />
                                                     </a>
                                                 }} />
                               {'*'}
                           </React.Fragment>
                           : <React.Fragment>
                               <FormattedMessage id='tosfield.description'
                                                 values={{link: <a href='#' className='hover-border'
                                                                   onClick={(e) => { e.preventDefault(); setTosDialogOpen(true); }}>
                                                         <FormattedMessage id='tosfield.description.link' />
                                                     </a>}} />
                               {'*'}
                           </React.Fragment>
                       } />
            </Form>}
            <ToSDialog open={tosDialogOpen} handleClose={() => setTosDialogOpen(false)} />
        </CardContent>
        {['posting_register_via_invitation', 'posted_register_via_invitation', 'failed_register_via_invitation', 'logging', 'login_failed'].includes(props.authState)
            ? <CardActionsLoader failure={props.authState === 'failed_register_via_invitation' || (props.authState === 'login_failed' && props.error !== 'not_approved')}
                                 success={(props.authState === 'login_failed' && props.error === 'not_approved')}
                                 postAnimation={success => {
                                     if (success) { // success here means not approved
                                         props.setAuthState('not_approved');
                                         props.history.push(`${props.portal ? '/partner' : ''}/not-approved`);
                                         props.addMessage({intl_id: `invitation${props.portal ? '.portal' : ''}.success.not_approved`, path: `${props.portal ? '/partner' : ''}/not-approved`});
                                     } else {
                                         props.setAuthState(null);
                                     }
                                 }} />
            : <CardActions>
            <Button variant='contained' color='secondary' type='submit'
                    disabled={props.authState !== null}
                    onClick={() => props.handleSubmit()}>
                <SubmitIcon />
                <FormattedMessage id='invitation.form.submit' />
            </Button>
        </CardActions>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'username', data.username);
    validator.isNotNull(null, errors, 'salutation', data.salutation);
    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'phone_number', data.phone_number);
    validator.isNotNull(null, errors, 'department', data.department);

    validator.isLength(
        props.intl.formatMessage({id: 'invitation.error.length'}, {length: 12}), errors, 'password', data.password,
        {min: 12, max: 128}) &&
    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.containsUpperCase(props.intl.formatMessage({id: 'invitation.error.uppercase'}), errors, 'password', data.password) &&
    validator.containsLowerCase(props.intl.formatMessage({id: 'invitation.error.lowercase'}), errors, 'password', data.password) &&
    validator.containsNumber(props.intl.formatMessage({id: 'invitation.error.number'}), errors, 'password', data.password) &&
    validator.containsSpecialCharacter(props.intl.formatMessage({id: 'invitation.error.special'}), errors, 'password', data.password);
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'invitation.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);

    validator.isTrue(
        props.intl.formatMessage({id: `tosfield.${props.portal ? 'portal.' : ''}error.required`}),
        errors, 'tos', data.tos
    );

    return errors;
};

const InvitationForm = reduxForm({
    form: 'invitationForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let {password2, ...rest_of_data} = props.formValues;

        return dispatch(simplePost('register_via_invitation', `${props.invitation.getIn(['links', 'register'])}?token=${props.token}`, rest_of_data, {setState: props.setAuthState})).then(() => {
            // success! Login User
            return dispatch(login({password: props.formValues.password, username: props.formValues.username})).then(() => {
                props.history.push(`${props.portal ? '/partner' : ''}/home`);
                return dispatch(addMessage({intl_id: `invitation${props.portal ? '.portal' : ''}.success.login`, path: `${props.portal ? '/partner' : ''}/home`}));
            });
        });
    }
})(Invitation);

const ConnectedInvitation = connect((state, props) => ({
    portal: props.location.pathname.startsWith('/partner'),
    authState: state.auth.get('state'),
    invitation: state.auth.get('invitation'),
    token: new URLSearchParams(props.location.search).get('token'),
    initialValues: state.auth.get('invitation') ? {
        email: state.auth.get('invitation').get('email')
    } : {},
    formValues: getFormValues('invitationForm')(state)
}), (dispatch) => bindActionCreators({
    setAuthState,
    fetchInvitation,
    setInvitation,
    simplePost,
    login,
    addMessage
}, dispatch))(InvitationForm);

export default injectIntl(withRouter(ConnectedInvitation));
