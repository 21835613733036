import React, {useEffect, useState} from 'react';
import {connect, shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, getFormSyncErrors, SubmissionError} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {Base64} from 'js-base64';
import {useLocalSort} from 'lib/filters';
import {Company, ProvisioningProfile, ProductGroup, SettingGroup, Setting, FeatureFlag} from 'lib/models';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
import {evaluateFeatureFlag} from 'lib/featureFlag';
// Actions
import {setState} from 'actions/app';
import {fetchItems, fetchItem, saveItem, deleteItem, setCollection, markFiltered} from 'actions/shared';
// Components
import SettingsManager from 'components/modules/settings/SettingsManager';
import ProvisioningFiles from 'components/modules/provfiles/List';
import TicketGroups from 'components/modules/tickets/List';
import DeviceManagement from 'components/modules/tickets/DeviceManagement';
import ThemeProvider from 'components/ThemeProvider';
import DMStateDialog from 'components/core/ui/DMStateDialog';
import Form from 'components/core/ui/Form';
import Field, {CompanyField, validateSettingsField, getSettingsInitialValues} from 'components/core/ui/Field';
import Tabs from 'components/core/ui/Tabs';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Tab from 'components/core/ui/mui/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import CopyIcon from '@material-ui/icons/AddToPhotosOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * CardActions for saving, deleting and returning back to List.
 *  - Used multiple times in Detail <Tabs />
 */
function ProvisioningProfileCardActions(props) {
    const {edit, selectedCompanyIdentifier, permission, handleSubmit, setDeleteDialogOpen, formValues, change} = props;
    // redux store
    const dispatch = useDispatch();
    const {state} = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);
    // router
    const history = useHistory();
    const {pathname, hash} = useLocation();

    return [
        'saving_item_provisioning-profiles', `saving_item_provisioning-profiles-${selectedCompanyIdentifier}`,
        'saved_item_provisioning-profiles', `saved_item_provisioning-profiles-${selectedCompanyIdentifier}`,
        'failed_save_item_provisioning-profiles', `failed_save_item_provisioning-profiles-${selectedCompanyIdentifier}`,
        'copy_item_provisioning-profiles'
    ].includes(state)
        ? <CardActionsLoader success={['saved_item_provisioning-profiles', `saved_item_provisioning-profiles-${selectedCompanyIdentifier}`, 'copy_item_provisioning-profiles'].includes(state)}
                             failure={['failed_save_item_provisioning-profiles', `failed_save_item_provisioning-profiles-${selectedCompanyIdentifier}`].includes(state)}
                             postAnimation={() => dispatch(setState(null))} />
        : <ThemeProvider alt>
            <CardActions>
                <Button disabled={state !== null}
                        onClick={() => history.push(
                            `/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`)}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                {permission === 'RW' && <React.Fragment>
                    {(edit && !hash) && <React.Fragment>
                        <ThemeProvider>
                            <Button variant='outlined' color='primary'
                                    disabled={state !== null}
                                    onClick={() => {
                                        dispatch(setState('copy_item_provisioning-profiles'));
                                        history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles/add${selectedCompanyIdentifier === 'my' ? '' : `?company=${selectedCompanyIdentifier}`}`);
                                        setTimeout(() => {
                                            Object.entries(formValues).map(([key, value]) => change(key, value));
                                        }, 0);
                                    }}>
                                <CopyIcon />
                                <FormattedMessage id='actions.copy' />
                            </Button>
                        </ThemeProvider>
                        <Button variant='contained' color='secondary'
                                disabled={state !== null}
                                onClick={() => setDeleteDialogOpen(true)}>
                            <DeleteIcon />
                            <FormattedMessage id='actions.delete' />
                        </Button>
                    </React.Fragment>}
                    <Button variant='contained' color='primary' type='submit' disabled={state !== null}
                            onClick={() => handleSubmit()}>
                        <SaveIcon />
                        <FormattedMessage id='actions.save' />
                    </Button>
                </React.Fragment>}
            </CardActions>
        </ThemeProvider>;
}

/**
 * Renders detail of Provisioning Profile - ADD & EDIT
 */
function Detail(props) {
    const featureFlag_deviceManager = props.featureFlag_deviceManager;
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // sorting
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);
    const [sortedGroups] = useLocalSort(props.groups_items, !props.groups_loaded);
    const [sortedSettings] = useLocalSort(props.settings_items, !props.settings_loaded);

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', props.selectedCompanyIdentifier],
                {success_affect_state: !edit, ignore_403: !props.location.pathname.startsWith('/all-')});
        } else if (edit) {
            if (!props.item) {
                props.fetchItem(ProvisioningProfile,
                    props.selectedCompanyIdentifier === 'my' ? 'provisioning-profiles' : `provisioning-profiles-${props.selectedCompanyIdentifier}`,
                    [
                        props.selectedCompany.getIn(['links', 'provisioning-profiles']),
                        props.match.params.identifier
                    ],
                    {company: props.selectedCompany}
                );
            } else if (['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)) {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany]);
    // related data fetch without affecting state
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            props.fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false});
        }
    }, [props.product_groups_loaded]);
    useEffect(() => {
        if (props.groups_loaded === false) {
            props.fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false});
        }
    }, [props.groups_loaded]);
    useEffect(() => {
        if (props.settings_loaded === false) {
            props.fetchItems(Setting, 'settings', 'settings', null, null, {affect_state: false});
        }
    }, [props.settings_loaded]);
    useEffect(() => {
        if (props.settings_loaded && props.item && props.item.get('settings')) {
            props.setCollection(Setting, 'settings-selected', props.settings_items.filter(
                setting => [...props.item.get('settings').keys()].includes(setting.get(new Setting().getUniqueIdentifier()))
            ));
        } else if (props.settings_selected.size && props.state !== 'copy_item_provisioning-profiles') {
            props.setCollection(Setting, 'settings-selected', []);
        }
    }, [props.settings_loaded, props.item]);
    // unmount cleanup
    useEffect(() => {
        return () => {
            if (props.settings_selected.size) {
                props.setCollection(Setting, 'settings-selected', []);
            }
        };
    }, []);

    /**
     * Set Auto Provisioning 
     */
    const handleSubmit = () => {
        if (props.state === null && props.formValues) {
            if (props.formValues.autoprovisioning_enabled === false && (
                (props.product_group && props.product_group.get('vpn') && props.formValues.vpn === true) ||
                (props.settings_selected.size > 0 && !(props.settings_selected.size === 1 && props.settings_selected.first().get('param_name') === 'setting_server'))
            )) {
                props.change('autoprovisioning_enabled', true);
                // dirty fix, because redux-form change doesn't return promise so autoprovisioning_enabled is still false
                setTimeout(() => props.handleSubmit(), 0);
            } else {
                props.handleSubmit();
            }
        }
    };

    return <div>
        {[
            'fetching_item_companies', 'fetching_item_my-companies',
            'fetching_item_provisioning-profiles', `fetching_item_provisioning-profiles-${props.selectedCompanyIdentifier}`,
            'deleting_item_provisioning-profiles', `deleting_item_provisioning-profiles-${props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='provprofiles.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='provisioning_profile' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='provprofiles.detail.notfound.title' />}
                text={<FormattedMessage id='provprofiles.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <React.Fragment>
                <Tabs
                    tabs={[
                        <Tab key={0} label={<FormattedMessage id='provprofiles.detail.tabs.main' />}
                             error={props.submitFailed && props.error && props.error.includes('main')}
                             softDisabled={props.state !== null} />,
                        <Tab key={1} label={<FormattedMessage id='provprofiles.detail.tabs.settings' />}
                             error={props.submitFailed && props.error && props.error.includes('settings')}
                             softDisabled={props.state !== null} />,
                        <Tab key={2} label={<FormattedMessage id='provprofiles.detail.tabs.files' />}
                             softDisabled={props.state !== null} />,
                        ...(featureFlag_deviceManager ? [
                            <Tab key={3} label={<FormattedMessage id='provprofiles.detail.tabs.tickets' />}
                                 disabled={!edit || !props.initialValues.dm_enabled} softDisabled={props.state !== null} />
                        ] : [])
                    ]}
                    initialSelectedTab={(['#settings', '#files', ...(featureFlag_deviceManager && edit && props.initialValues.dm_enabled ? ['#tickets'] : [])].findIndex(tab_hash => tab_hash === props.location.hash) + 1) || 0}
                    onChange={(tab) => {
                        props.history.push({
                            hash: [null, '#settings', '#files', ...(featureFlag_deviceManager ? ['#tickets'] : [])][tab],
                            // keep Company but clear all other query params (various filters that are individual per tab)
                            search: props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`
                        });
                        // trigger validation hack to have proper validation errors after fields are destroyed
                        setTimeout(() => props.change('_validationHack', Date.now()), 0);
                    }}
                    destroyNotSelected
                    content={[
                        <div key={0}>
                            <Card>
                                <CardHeader subheader={<FormattedMessage id='provprofiles.detail.subheader' />}
                                            title={edit
                                                ? <FormattedMessage id='provprofiles.detail.edit.title' />
                                                : <FormattedMessage id='provprofiles.detail.add.title' />}
                                            action={<ActionButton iconButton disabled>
                                                <ModelIcon model='provisioning_profile' />
                                            </ActionButton>} />
                                <CardContent>
                                    <Form onSubmit={() => handleSubmit()}>
                                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'provprofiles.detail.form.fields.name'})}*`} />
                                        {(props.company.get('company_type') === 'reseller' || props.location.pathname.startsWith('/all-')) && <CompanyField
                                            value={!edit && props.selectedCompanyIdentifier === 'my' ? ''
                                                : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                                            change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                                            disabled={edit} all={false}
                                            fetch_company={false}
                                            my_companies={!props.location.pathname.startsWith('/all-')}
                                        />}
                                        <SpaceDivider />
                                        <Field name='product_group' fieldType='Select' label={`${props.intl.formatMessage({id: 'provprofiles.detail.form.fields.product_group'})}*`}
                                               disabled={!props.product_groups_loaded} loading={!props.product_groups_loaded}
                                               onChange={() => {
                                                   // we must clear provisioning_files that are depending on product_group
                                                   props.change('provisioning_files', []);
                                               }}>
                                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                            <MenuItem fake value={props.formValues.product_group} />
                                            {sortedProductGroups.map((product_group, idx) =>
                                                <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                                            )}
                                        </Field>
                                        {featureFlag_deviceManager &&<React.Fragment>
                                            <Field name='dm_enabled' fieldType='Checkbox' size='regular'
                                                   label={<FormattedMessage id='endpoints.detail.form.fields.dm_enabled' />}
                                                   helperText={<FormattedMessage id='endpoints.detail.form.fields.dm_enabled.help' />}
                                                   disabled={!props.selectedCompany?.get('has_subscription') && !props.initialValues.dm_enabled}
                                            />
                                            <DMStateDialog
                                                state={props.formValues.dm_enabled}
                                                initialState={props.initialValues.dm_enabled}
                                                changeState={state => props.change('dm_enabled', state)}
                                            />
                                        </React.Fragment>}
                                        <SpaceDivider none />
                                        {(props.product_group && props.product_group.get('vpn')) && <Field
                                            name='vpn' fieldType='Checkbox' size='regular'
                                            label={<FormattedMessage id='provprofiles.detail.form.fields.vpn' />}
                                            helperText={<FormattedMessage id='provprofiles.detail.form.fields.vpn.help'
                                                                          values={{link: <a href='https://service.snom.com/display/wiki/Configuring+VPN+on+Snom+Deskphones#ConfiguringVPNonSnomDeskphones-InstalltheVPNpatchontheSnomphone'
                                                                                            target='_blank' rel='noreferrer' className='hover-border'>
                                                                                  <FormattedMessage id='provprofiles.detail.form.fields.vpn.help.link' />
                                                                              </a>}} />} />}
                                        {props.selectedCompany?.get('provisioning_strategy') === '3CX' && <Field
                                            name='custom_fields.patch_3cx' fieldType='Checkbox' size='regular'
                                            label={<FormattedMessage id='provprofiles.detail.form.fields.custom_fields.patch_3cx' />}
                                            helperText={<FormattedMessage id='provprofiles.detail.form.fields.custom_fields.patch_3cx.help' />} />}
                                        <SpaceDivider />
                                        {edit && <React.Fragment>
                                            <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='provprofiles.detail.form.fields.created_at' />}
                                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                                        </React.Fragment>}
                                    </Form>
                                </CardContent>
                                <ProvisioningProfileCardActions
                                    edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                    handleSubmit={handleSubmit} setDeleteDialogOpen={setDeleteDialogOpen} formValues={props.formValues} change={props.change} />
                            </Card>
                            {(featureFlag_deviceManager && edit && props.initialValues.dm_enabled) && <React.Fragment>
                                <SpaceDivider grid />
                                <DeviceManagement
                                    permission={props.permission} item={props.item}
                                    ticket_group
                                />
                            </React.Fragment>}
                        </div>,
                        <div key={1}>
                            <SettingsManager
                                location='provprofiles'
                                namePrefix='settings'
                                permission={props.permission}
                                change={props.change}
                                formValues={props.formValues}
                                handleSubmit={handleSubmit}
                                product_group={props.formValues.product_group}
                                sortedGroups={sortedGroups}
                                sortedSettings={sortedSettings}
                                settings_selected={props.settings_selected}
                                cardActions={<ProvisioningProfileCardActions
                                    edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                    handleSubmit={handleSubmit}
                                />}
                            />
                        </div>,
                        <div key={2}>
                            <ProvisioningFiles
                                location='provisioning_profile' doNotFetchProductGroup
                                filterProductGroups={props.formValues.product_group ? [props.formValues.product_group] : []}
                                initialItemSelectedFileIDs={props.initialValues.provisioning_files || []}
                                itemSelectedFileIDs={props.formValues.provisioning_files || []}
                                itemSelectFileIDs={(ids) => props.change('provisioning_files', ids)}
                                cardActions={<ProvisioningProfileCardActions
                                    edit={edit} selectedCompanyIdentifier={props.selectedCompanyIdentifier} permission={props.permission}
                                    handleSubmit={handleSubmit}
                                />}
                            />
                        </div>,
                        ...(featureFlag_deviceManager && edit && props.initialValues.dm_enabled ? [<div key={3}>
                            <TicketGroups
                                permission={props.permission} item={props.item}
                                ticket_group
                            />
                        </div>] : [])
                    ]}>
                </Tabs>
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'provprofiles.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'provprofiles.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(ProvisioningProfile, `provisioning-profiles${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`, props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'provprofiles.detail.delete.fail'}).then(result => {
                            if (result !== false) {
                                // mark paginated lists for reload
                                props.markFiltered(ProvisioningProfile, 'provisioning-profiles-global');
                                // make sure that Settings will be re-fetched (due to no_profiles)
                                props.dispatch(markFiltered(Setting, 'settings'));
                                props.history.push(
                                    `/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                            }
                        });
                    }}
                />}
            </React.Fragment>}
    </div>;
}

const validate = (data, props) => {
    const errors = {settings: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    const nested_settings = data.settings ? new ImmutableMap(Object.entries(data.settings)) : new ImmutableMap(); // nested fix

    // validate form
    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'product_group', data.product_group);

    if (props.settings_loaded) {
        // validate Selected Settings
        props.settings_selected.forEach((setting) => {
            // make sure to validate only NOT INDEXED fields (they have validation in IndexedSettingField)
            if (setting.get('indexed') && !setting.getIn(['indexed', 'enabled'])) {
                errors['settings'][setting.get(new Setting().getUniqueIdentifier())] = validateSettingsField(
                    nested_settings.get(setting.get(new Setting().getUniqueIdentifier())) || {},
                    setting, props.intl
                );
            } else {
                validator.isNotNull(
                    null, errors, `settings.${setting.get(new Setting().getUniqueIdentifier())}`,
                    nested_settings.get(setting.get(new Setting().getUniqueIdentifier()))
                );
            }
        });
    }

    return errors;
};

/**
 * Convert ProvisioningProfile record toObject(). Used for initial values
 *
 * @param provprofile - ProvisioningProfile model
 */
function toObject(provprofile) {
    let itemObject = provprofile.toJS();
    itemObject.settings = itemObject.settings || {};
    // process settings
    provprofile.settings && provprofile.settings.forEach((values, key) => {
        // certificate process
        if (values.getIn(['attrs', 'type']) === 'base64') {
            const certificates = [];
            values.get('value').forEach(value => {
                certificates.push(Base64.decode(value));
            });
            itemObject.settings[key]['value'] = certificates;
        }
    });
    return itemObject;
}

const DetailForm = reduxForm({
    form: 'provisioningProfileForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation for SettingFields
    onSubmit: (values, dispatch, props) => {
        // extra care for validation in Tabs that are not selected and fields inside are currently destroyed
        const {settings: settings_manager_errors, ...errors} = props.formErrors;
        const submit_errors = [];
        // settings tab
        if (Object.values(settings_manager_errors || {}).filter(error =>
            error.attrs ? !!((error.value && (typeof error.value !== 'object' || Object.values(error.value).filter(Boolean).length)) || error.attrs.perm) : !!error).length) {
            submit_errors.push('settings');
        }
        // main tab
        if (Object.keys(errors || {}).length) {
            submit_errors.push('main');
        }
        if (submit_errors.length) {
            throw new SubmissionError({_error: submit_errors});
        }

        // pop-up some values which we don't want to send
        const rest_of_provprofile_data = new ProvisioningProfile().popUpFields(props.formValues);
        let {_validationHack, ...rest_of_data} = rest_of_provprofile_data;
        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));

        // process VPN (only for some Product Groups)
        if (!props.product_group || !props.product_group.get('vpn')) {
            delete rest_of_data.vpn;
        }
        // process custom_fields (only for some Company)
        if (props.selectedCompany?.get('provisioning_strategy') !== '3CX') {
            delete rest_of_data.custom_fields;
        }
        // device manager
        if (!props.featureFlag_deviceManager) {
            delete rest_of_data.dm_enabled;
        }

        // process settings
        let settings = {}; // we will send only selected settings
        props.settings_selected.forEach(setting => {
            let value = new ImmutableMap(Object.entries(rest_of_data['settings'])).get(setting.get(new Setting().getUniqueIdentifier()));
            settings[setting.get(new Setting().getUniqueIdentifier())] = value;
            // certificate process
            if (setting.get('field_type') === 'certificate') {
                let certificates = [];
                value.value.forEach(certificate => {
                    certificates.push(Base64.encode(certificate));
                });
                settings[setting.get(new Setting().getUniqueIdentifier())]['value'] = certificates;
            }
        });
        rest_of_data['settings'] = settings;

        return dispatch(saveItem(
            ProvisioningProfile,
            props.selectedCompanyIdentifier === 'my' ? 'provisioning-profiles' : `provisioning-profiles-${props.selectedCompanyIdentifier}`,
            props.item
                ? props.item.getIn(['links', 'self'])
                : props.selectedCompany.getIn(['links', 'provisioning-profiles']),
            rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false}
        )).then(create_data => {
            // make sure that Settings will be re-fetched (due to no_profiles). We must re-fetch immediately instead set markFiltered because Selecting Settings would be disabled
            dispatch(fetchItems(Setting, 'settings', 'settings', null, null, {affect_state: false}));
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new ProvisioningProfile().getUniqueIdentifier())) {
                props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}provisioning-profiles/${create_data.get(new ProvisioningProfile().getUniqueIdentifier())}${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
            }
            // mark not updated lists for reload
            return dispatch(markFiltered(ProvisioningProfile, 'provisioning-profiles-global'));
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    const items = selectedCompanyIdentifier === 'my' ? state.shared.getIn(['items', 'provisioning-profiles']) : state.shared.getIn(['items', `provisioning-profiles-${selectedCompanyIdentifier}`]) || ImmutableList();
    const item = items.find(el => el.get(new ProvisioningProfile().getUniqueIdentifier()) === props.match.params.identifier);
    const itemObject = item ? toObject(item) : {};

    const formValues = getFormValues('provisioningProfileForm')(state);

    return {
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        initialValues: props.match.params.identifier
            ? item
                ? {provisioning_files: [], vpn: false, custom_fields: {patch_3cx: false}, dm_enabled: false, ...itemObject,
                    settings: {...getSettingsInitialValues(state), ...itemObject.settings}}
                : undefined
            : {autoprovisioning_enabled: false, provisioning_files: [], vpn: false, custom_fields: {patch_3cx: false}, dm_enabled: false,
                settings: getSettingsInitialValues(state)},
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        product_group: formValues && formValues.product_group && state.shared.getIn(['loaded', 'product-groups']) ? state.shared.getIn(['items', 'product-groups']).find(el => el.get(new ProductGroup().getUniqueIdentifier()) === formValues.product_group) : undefined,
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        groups_items: state.shared.getIn(['items', 'setting-groups']),
        settings_loaded: state.shared.getIn(['loaded', 'settings']),
        settings_items: state.shared.getIn(['items', 'settings']),
        settings_selected: state.shared.getIn(['items', 'settings-selected']),
        company: company,
        user: auth_user,
        formValues: formValues,
        formErrors: getFormSyncErrors('provisioningProfileForm')(state),
        featureFlag_deviceManager: evaluateFeatureFlag(state.shared.getIn(['items', 'feature-flags'])
            ?.find(el => el.get(new FeatureFlag().getUniqueIdentifier()) === 'device_manager'),
            {company: selectedCompany?.get(new Company().getUniqueIdentifier())})
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    fetchItem,
    saveItem,
    deleteItem,
    markFiltered,
    setCollection
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
