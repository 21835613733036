import React from 'react';
// material-ui
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    // timeline container list styles
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& $timelineItem': {
                flexDirection: 'column'
            },
            '& $timelineConnector': {
                display: 'none'
            }
        }
    },
    // timeline list item styles
    timelineItem: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        columnGap: `${theme.spacing(5)}px`,
        rowGap: `${theme.spacing(2)}px`,
        // hide the last connector
        '&:last-of-type $timelineConnector': {
            display: 'none'
        }
    },
    // timeline left side styles (meta data and connector)
    timelineOppositeContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: `${theme.spacing(35)}px`
    },
    // timeline meta data styles (date icon title)
    timelineMeta: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    // timeline date styles
    timelineDate: {
        flexShrink: 0,
        width: `${theme.spacing(14)}px`,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: `${theme.spacing(1)}px`,
        textAlign: 'right'
    },
    // timeline icon styles
    timelineIcon: {
        flexShrink: 0
    },
    // timeline title styles
    timelineTitle: {
        flexShrink: 0,
        width: `${theme.spacing(14)}px`,
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: `${theme.spacing(1)}px`
    },
    // timeline connector(vertical line between items) styles
    timelineConnector: {
        border: `1px solid ${theme.palette.divider}`,
        height: '100%'
    },
    // timeline content styles (right side)
    timelineContent: {
        flex: 1,
        marginBottom: `${theme.spacing(3)}px`
    }
}));

/**
 * Render a timeline of events
 * @param {object} props - The component props
 * @param {[]} props.events - The events to render
 * @param {(event) => JSX.Element} props.renderDate - render the date of the event
 * @param {(event) => JSX.Element} props.renderIcon - render the icon of the event
 * @param {(event) => JSX.Element} props.renderTitle - render the title of the event
 * @param {(event) => JSX.Element} props.children - render the main content of the event
 */
export default function Timeline(props) {
    const {events = [], renderDate, renderIcon, renderTitle, children} = props;
    const classes = useStyles();

    return <div className={classes.root}>
        {events.map((event, index) => <div className={classes.timelineItem} key={index}>
            <div className={classes.timelineOppositeContent}>
                <div className={classes.timelineMeta}>
                    <div className={classes.timelineDate}>
                        {renderDate ? renderDate(event) : event.date}
                    </div>
                    <div className={classes.timelineIcon}>
                        {renderDate ? renderIcon(event) : event.icon}
                    </div>
                    <div className={classes.timelineTitle}>
                        {renderDate ? renderTitle(event) : event.title}
                    </div>
                </div>
                <div className={classes.timelineConnector}><span /></div>
            </div>
            <div className={classes.timelineContent}>
                {children ? children(event) : event.content}
            </div>
        </div>)}
    </div>;
}
