import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
// Components
import Content from 'components/modules/documentation/Content';
import Documentation from 'components/modules/documentation/Documentation';
// material-ui
import Card from 'components/core/ui/mui/Card';


const useStyles = makeStyles(theme => ({
    rootCard: {
        // get rid of 'hidden' overflow to allow sticky doc menu
        overflow: 'visible'
    }
}));

/**
 * Holder of Components to render complete documentation
 */
export default function List() {
    const classes = useStyles();
    // redux store
    const {docSelected} = useSelector(state => ({
        docSelected: state.shared.getIn(['state', 'doc-selected'])
    }), shallowEqual);

    return <Card className={`${classes.rootCard} full-card`}>
        <Content />
        {docSelected.get('url') && <Documentation key={docSelected.get('url')} />}
    </Card>;
}
