import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUIMenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
    root: {
        // fake option to get rid of warning 'value out of range'
        '&.fake': {
            display: 'none !important'
        }
    }
}));

/**
 * Extends MenuItem component with additional props
 */
const MenuItem = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, fake, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.root, root, className,
        fake ? 'fake' : ''
    ].filter(Boolean).join(' ');

    return <MUIMenuItem
        ref={ref}
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props}
    />;
});
export default MenuItem;
