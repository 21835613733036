'use strict';
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl} from 'react-intl';
// Components
import {EnhancedReduxField} from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';


const styles = theme => ({
    fieldHolder: {
        // style taken from Field.js formControl
        width: '320px',
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10'
    },
    // real input
    input: {
        // not visible, but still there!
        opacity: '0'
    },
    // holder of selects outside formControl
    selectsHolder: {
        // move to input position
        position: 'absolute',
        top: `${theme.spacing(2)}px`,
        zIndex: '15',
        display: 'flex'
    },
    // pickers
    select: {
        // half of the field
        width: `${320 / 2}px`,
        // style
        textAlign: 'center',
        // manually handle errors since we are outside the formControl
        '&.error:after': {
            transform: 'scaleX(1)',
            borderBottomColor: theme.palette.danger['main']
        }
    },
    // hours and minutes
    menuItem: {
        // lower size
        paddingTop: '0',
        paddingBottom: '0',
        // style
        justifyContent: 'center'
    }
});


const _renderSelect = ({name, classes, selectedValueFirst, selectedValueSecond, change, divider, fromto, error, first, withIcon: _, ...rest_of_props}) => {
    let values = first || fromto ? [...new Array(24)] : [...new Array(60)];

    return <Select value={first ? selectedValueFirst : selectedValueSecond}
                   className={`${classes.select}${error ? ' error' : ''}`}
                   onChange={(event) => change(name, first ? `${event.target.value}${divider}${selectedValueSecond}` : `${selectedValueFirst}${divider}${event.target.value}`)}
                   {...rest_of_props}>
        {values.map((el, idx) =>
            <MenuItem value={`0${idx}`.slice(-2)} key={idx} className={classes.menuItem}>
                {`0${idx}`.slice(-2)}
            </MenuItem>
        )}
    </Select>;
};

const _renderTimeField = ({input, label, helperText, classes, selectedValue, change, divider, fromto, meta: {touched, error}, ...rest_of_props}) => {
    let selectedValueFirst = selectedValue && selectedValue.substring(0, 2) ? selectedValue.substring(0, 2) : '';
    let selectedValueSecond = selectedValue && selectedValue.substring(3, 5) ? selectedValue.substring(3, 5) : '';

    return <div className={classes.fieldHolder}>
        <FormControl fullWidth error={Boolean(touched && error)}>
            <InputLabel shrink={true}>{label}</InputLabel>
            <Input className={classes.input} {...input} disabled={true} />
            {touched && error
                ? <FormHelperText>{error}</FormHelperText>
                : helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
        <div className={classes.selectsHolder}>
            {_renderSelect({name: input.name, classes: classes, selectedValueFirst: selectedValueFirst, selectedValueSecond: selectedValueSecond, change: change, divider: divider, fromto: fromto, error: Boolean(touched && error), first: true, ...rest_of_props})}
            {_renderSelect({name: input.name, classes: classes, selectedValueFirst: selectedValueFirst, selectedValueSecond: selectedValueSecond, change: change, divider: divider, fromto: fromto, error: Boolean(touched && error), first: false})}
        </div>
    </div>;
};

/**
 * 24h time picker HH:mm
 */
class TimeField extends React.Component {
    render() {
        let {name, label, classes, intl, helperText, selectedValue, change, fieldType, type, divider, fromto, ...rest_of_props} = this.props;

        return <EnhancedReduxField
            component={_renderTimeField}
            name={name}
            label={label}
            helperText={helperText}
            selectedValue={selectedValue}
            change={change}
            classes={classes}
            divider={divider || ':'}
            fromto={fromto}
            {...rest_of_props} />;
    }
}

TimeField = withStyles(styles)(TimeField);
export default injectIntl(TimeField);
