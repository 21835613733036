import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
// icons
import LoggedIcon from '@material-ui/icons/LockOutlined';


/**
 * Already Logged Page covers Pages like 'invitation' and 'forgotten-password' for already logged User
 */
export default function AlreadyLogged() {
    // redux store
    const {user} = useSelector(state => ({
        user: state.auth.get('user')
    }), shallowEqual);

    return <ErrorCard
        title={<FormattedMessage id='errors.alreadylogged.title' />}
        text={<FormattedMessage id='errors.alreadylogged.text'
                                values={{username: <strong>{user.get('username') || ''}</strong>}} />}
        icon={<LoggedIcon />} />;
}
