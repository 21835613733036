import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {SettingGroup} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Setting Groups from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new SettingGroup().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='settinggroups.list.table.name' />},
        {sortName: 'no_settings', label: <FormattedMessage id='settinggroups.list.table.no_settings' />, numeric: true}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new SettingGroup().getPlacement(), tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new SettingGroup().getPlacement());

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(SettingGroup, items_placement, 'setting-groups'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='settinggroups.list.title' />}
            subheader={<FormattedMessage id='settinggroups.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/setting-groups/add')}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='setting_groups' />
            </ActionButton>}
        />
        <Filters>
                <SearchField label={<FormattedMessage id='settinggroups.list.search' />}
                             value={filters.search || ''}
                             search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'settinggroups.list.table.empty'}
                                              values={{link: passed_props.permission === 'RW'
                                                      ? <Link to='/setting-groups/add' className='hover-border'>
                                                          <FormattedMessage id='settinggroups.list.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='settinggroups.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((setting_group, idx) =>
                        <TableRow key={idx} link hover
                                  onClick={() => props.state === null ? history.push(`/setting-groups/${setting_group.get(new SettingGroup().getUniqueIdentifier())}`) : {}}>
                            <TableCell>{setting_group.get('name')}</TableCell>
                            <TableCell numeric>{setting_group.get('no_settings')}</TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>} />
    </Card>;
}
