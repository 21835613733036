import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Product, ProductGroup} from 'lib/models';
import {useLocalFilter, useLocalSort} from 'lib/filters';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import Field, {SearchField} from 'components/core/ui/Field';
import {Link, useHistory} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import MenuItem from 'components/core/ui/mui/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';


/**
 * Renders Products from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Product().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement]),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups'])
    }), shallowEqual);
    // router & style
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='products.list.table.name' />},
        {sortName: 'code', label: <FormattedMessage id='products.list.table.code' />},
        {label: <FormattedMessage id='products.list.table.product_groups' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new Product().getPlacement(), tableHead.map(item => item.sortName).filter(sortName => !!sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing || !props.product_groups_loaded, new Product().getPlacement(), ['search', 'product_group'], ['name', 'code'],  {
            product_group: (obj, value) => obj.get('groups') && obj.get('groups').includes(props.product_groups_items.map(product_group => product_group.get(new ProductGroup().getUniqueIdentifier())).find(product_group_id => product_group_id === value))
        });
    // related sorting
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Product, items_placement, 'products'));
        }
    }, [props.loaded]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='products.list.title' />}
            subheader={<FormattedMessage id='products.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/products/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='products' />
                </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='products.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='products.list.filter.product_group' />}
                   fieldType='NoReduxSelect' value={filters.product_group || ''}
                   onChange={event => filterItems('product_group', event.target.value)}
                   loading={!props.product_groups_loaded}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {sortedProductGroups.map((product_group, idx) =>
                    <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                )}
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                        </TableRow>
                    </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'products.list.table.empty'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && filteredItems.map((product, idx) => {
                                const onClick = () => props.state === null ? history.push(`/products/${product.get(new Product().getUniqueIdentifier())}`) : {};

                                return <TableRow key={idx} hover>
                                    <TableCell link onClick={onClick}>
                                        {product.get('name')}
                                    </TableCell>
                                    <TableCell link onClick={onClick}>
                                        {product.get('code')}
                                    </TableCell>
                                    <TableCell>
                                        {!props.product_groups_loaded
                                            ? <CircularProgress size={36} />
                                            : product.get('groups')
                                            ? product.get('groups').map((product_group_id, idx) => {
                                                const product_group = props.product_groups_items.find(el => el.get(new ProductGroup().getUniqueIdentifier()) === product_group_id);

                                                return product_group
                                                    ? <span key={idx} className='divide-links'>
                                                        <Link className='hover-border' to={`/product-families/${product_group.get(new ProductGroup().getUniqueIdentifier())}`}>
                                                            {product_group.get('name')}
                                                        </Link>
                                                    </span>
                                                    : '';
                                            })
                                            : ''}
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
            </Table>} />
    </Card>;
}
