import React, {useEffect, useState, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useFilter, usePaginatedItems} from 'lib/filters';
import Moment from 'moment';
import {ProvisioningLog, Endpoint, Paginator} from 'lib/models';
import {List as ImmutableList} from 'immutable';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {fetchItems, markOutdated} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Detail from 'components/modules/provlogs/Detail';
import Filters from 'components/core/ui/Filters';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import Chip from '@material-ui/core/Chip';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import InActiveIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    // created_at time
    time: {
        // display as 'monospace' to better readability and same size
        fontFamily: '"Roboto Mono", monospace',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: '1.1875em'
    },
    // response time next to response status
    responseTime: {
        marginLeft: `${theme.spacing(0.5)}px`
    }
}));

/**
 * View of Endpoint Provisioning Logs
 */
export default function List(passed_props) {
    // split stuff from props
    const {item, is_admin, has_subscription} = passed_props;
    const can_view_details = (item.get('dm_enabled') && has_subscription) || is_admin;
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const {items_placement, ...props} = useSelector(state => {
        const items_placement = `${new ProvisioningLog().getPlacement()}-${item.get(new Endpoint().getUniqueIdentifier())}`;

        return {
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList()
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = useMemo(() => [
        {sortName: 'created_at', label: <FormattedMessage id='provlogs.list.table.created_at' />, reduced: true},
        {sortName: 'response_status', label: <FormattedMessage id='provlogs.list.table.response_status' />, reduced: true},
        {sortName: 'url', label: <FormattedMessage id='provlogs.list.table.url' />},
        {sortName: 'setting_server_only', label: <FormattedMessage id='provlogs.list.table.setting_server_only' />, numeric: true}
    ], []);
    // local state
    const [detailDialogItem, setDetailDialogItem] = useState(null);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new ProvisioningLog().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = ['created_at', 'asc'];
    const [, filters, filtersInitializing] = useFilter(
        false, items_placement, [], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(ProvisioningLog, items_placement, item.getIn(['links', 'provisioning-logs']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters)]);
    // silent auto re-fetch with outdated
    useEffect(() => {
        if (props.outdated && props.paginator.get('page') === 1) {
            dispatch(fetchItems(ProvisioningLog, items_placement, item.getIn(['links', 'provisioning-logs']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1, affect_state: false}));
        }
    }, [props.outdated, props.paginator.get('page')]);

    return <React.Fragment>
        <Card>
            <CardHeader
                title={<FormattedMessage id='provlogs.list.title' />}
                subheader={<FormattedMessage id='provlogs.list.subheader' />}
                action={<ActionButton iconButton disabled>
                    <ModelIcon model='provisioning_logs' />
                </ActionButton>}
            />
            <Filters
                refreshDisabled={!props.loaded || props.state !== null}
                refresh={() => dispatch(markOutdated(ProvisioningLog, items_placement))}
            />
            <StickyTable
                header={<Table size={tableHead.length}>
                    <TableHead>
                        <TableRow>
                            {tableHead.map((item, idx) =>
                                <TableCell key={idx} numeric={!!item.numeric} reduced={!!item.reduced}>
                                    {sortName === item.sortName
                                        ? <TableSortLabel active direction={sortDirection}
                                                          disabled={true}>
                                            {item.label}
                                        </TableSortLabel>
                                        : item.label
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                </Table>}
                content={<Table size={tableHead.length}>
                    {[`fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`].includes(props.state) || filtersInitializing ? <PaginationLoading
                            paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                            tableHead={tableHead} paginator={props.paginator}
                        />
                        : !paginatedItems.size
                            ? <TableBody>
                                <TableRow>
                                    <TableCell colSpan={tableHead.length}>
                                        <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'provlogs.list.table.empty'} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            : <TableBody>
                                {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((item, idx) => {
                                    return <TableRow
                                        key={idx} link={can_view_details} hover={can_view_details}
                                        onClick={props.state !== null || !can_view_details ? () => {} : () => {
                                            setDetailDialogItem(item);
                                        }}>
                                        <TableCell reduced>
                                            <span className={classes.time}>
                                                {Moment(item.get('created_at')).format('l')}<br />
                                                <strong>{Moment(item.get('created_at')).format('HH:mm:ss')}</strong>
                                            </span>
                                        </TableCell>
                                        <TableCell reduced>
                                            <ThemeProvider alt>
                                                <Chip
                                                    color={item.get('response_status') >= 400 ? 'secodnary' : item.get('response_status') < 300 ? 'primary' : undefined}
                                                    label={item.get('response_status')}
                                                />
                                            </ThemeProvider>
                                            <span className={classes.responseTime}>
                                                {`${Math.round(item.get('response_time') / 1000)}ms`}
                                            </span>
                                        </TableCell>
                                        <TableCell urlView>{item.get('url')}</TableCell>
                                        <TableCell numeric>
                                            {item.get('setting_server_only') ? <ActiveIcon /> : <InActiveIcon />}
                                        </TableCell>
                                    </TableRow>;
                                })}
                            </TableBody>}
                </Table>}
                footer={<Table size={1}>
                    <Pagination model={ProvisioningLog} placement={items_placement} filters={filters}
                                paginationLoading={true} />
                </Table>} />
        </Card>
        <Detail item={detailDialogItem} onClose={() => setDetailDialogItem(null)} />
    </React.Fragment>;
}
