import React, {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import {makeStyles} from '@material-ui/styles';
// material-ui
import ButtonBase from '@material-ui/core/ButtonBase';


const useStyles = makeStyles((theme) => ({
    // custom labels not to screw canvas ratio
    labels: {
        // labels next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: `${theme.spacing(0.5)}px`,
        // space between labels and graph
        marginTop: `${theme.spacing(2)}px`,
        // match style and proportion of graph labels
        '& > button': {
            // icon and label next to each other
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(0.5)}px`,
            // proportion
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            // style
            fontSize: theme.typography.pxToRem(13),
            color: theme.palette.grey[700],
            // strike through label when disabled
            '&.disabled': {
                '& .text': {
                    textDecoration: 'line-through'
                }
            },
            // icon before label
            '& .icon': {
                // size
                height: '18px',
                width: '18px',
                // style
                flexShrink: '0',
                borderRadius: '100%',
                border: `1px solid ${theme.palette.common.white}`
            }
        }
    }
}));

/**
 * Render pie chart
 * @param {object} props - component props
 * @param {number} props.id - unique id for graph from useId hook
 * @param {string} props.type - graph type
 * @param {object} props.options - graph options
 * @param {object} props.data - raw data from backend that will be processed into chart
 */
export default function Pie(props) {
    const {id, type = 'pie', options = {}, data = {}, ...rest_of_props} = props;
    const intl = useIntl();
    const classes = useStyles();
    const [disabledLabels, setDisabledLabels] = useState(() =>
        options.disabledLabels?.map((label) => intl.formatMessage({id: `${options.base_intl}.${label}`, defaultMessage: label})) || []
    );

    const labels = useMemo(() =>
        options.labels.map((label) => intl.formatMessage({id: `${options.base_intl}.${label}`, defaultMessage: label}))
    , [JSON.stringify(data), options.base_intl, disabledLabels?.length]);

    // check for disabled series
    useEffect(() => {
        const chart = ApexCharts.getChartByID(id);
        chart.updateSeries(options.labels.map((label) =>
            disabledLabels.includes(intl.formatMessage({id: `${options.base_intl}.${label}`, defaultMessage: label})) ? 0 : data ? data[label] : 0
        ), true);
    }, [disabledLabels.length, JSON.stringify(data), options.labels.length, id, options.base_intl]);

    // generate options for apex chart
    const processedOptions = useMemo(() => ({
        dataLabels: {enabled: false},
        tooltip: {followCursor: true},
        ...options,
        legend: {position: 'bottom', show: false, ...options.legend},
        chart: {
            zoom: {enabled: true},
            toolbar: {show: false},
            ...(options.chart),
            id, type
        },
        labels
    }), [id, JSON.stringify(labels)]);

    return labels.length ? <React.Fragment>
        <ReactApexChart type={type} series={[]} options={processedOptions} {...rest_of_props} />
        {labels.length ? <div className={classes.labels}>
            {labels.map((param, index) => <ButtonBase key={param} className={disabledLabels.includes(param) ? 'disabled' : undefined}
                onClick={() => setDisabledLabels(disabledLabels.includes(param) ? disabledLabels.filter(p => p !== param) : [...disabledLabels, param])}>
                <span className='icon' style={{backgroundColor: options.colors?.[index]}} />
                <span className='text'>{param}</span>
            </ButtonBase>)}
        </div> : null}
    </React.Fragment> : null;
}
