import React from 'react';


/**
 * MasterCard logo for payment method cards
 */
export default function MasterCard(props) {
    const {viewBox = '0 0 116.49219 71.999865', ...rest_of_props} = props;

    return <svg viewBox={viewBox} {...rest_of_props}>
        <rect fill='#ff5f00' x='42.496899' y='7.6966968' width='31.5' height='56.6064' />
        <path fill='#eb001b' d='m 44.497097,35.999896 a 35.9375,35.9375 0 0 1 13.7499,-28.3031996 36,36 0 1 0 0,56.6063996 35.938,35.938 0 0 1 -13.7499,-28.3032 z' />
        <path fill='#f79e1b' d='m 116.4922,35.999896 a 35.99867,35.99867 0 0 1 -58.245203,28.3032 36.00518,36.00518 0 0 0 0,-56.6063996 35.99867,35.99867 0 0 1 58.245203,28.3031996 z' />
    </svg>;
}
