import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {useLocalSort} from 'lib/filters';
import {Product, ProductGroup, Setting, SettingGroup} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItems, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {JSONField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import SB327SettingsField from 'components/modules/products/SB327SettingsField';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * Renders detail of Products - ADD & EDIT
 */
function Detail(props) {
    // redux store
    const dispatch = useDispatch();
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // sorting
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);
    const [sortedSettings] = useLocalSort(props.settings_items, !props.settings_loaded);

    /**
     * During initialization fetch ALL items, we need all of them for isUnique validation
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Product, 'products', 'products'));
        }
    }, [props.loaded]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
        if (props.settings_loaded === false) {
            dispatch(fetchItems(Setting, 'settings', 'settings', null, null, {affect_state: false}));
        }
        if (props.setting_groups_loaded === false) {
            dispatch(fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false}));
        }
    }, [props.setting_groups_loaded, props.product_groups_loaded, props.settings_loaded]);


    return <div>
        {['fetching_items_products', 'deleting_item_products'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='products.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='product' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='products.detail.notfound.title' />}
                text={<FormattedMessage id='products.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='products.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='products.detail.edit.title' />
                                : <FormattedMessage id='products.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='product' />
                            </ActionButton>} />
                <CardContent>
                    <Row wrap>
                        <Col width='50%'>
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'products.detail.form.fields.name'})}*`} />
                                <Field name='code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'products.detail.form.fields.code'})}*`}
                                       disabled={edit} />
                                <SpaceDivider />
                                <Field name='groups' fieldType='Select' multiple
                                       label={`${props.intl.formatMessage({id: 'products.detail.form.fields.groups'})}*`}
                                       disabled={!props.product_groups_loaded} loading={!props.product_groups_loaded}>
                                    <MenuItem fake value={props.formValues && props.formValues.groups} />
                                    {sortedProductGroups.map((product_group, idx) =>
                                        <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                                    )}
                                </Field>
                                <Field name='device_manager_compatible' fieldType='Checkbox' label={<FormattedMessage id='products.detail.form.fields.device_manager_compatible' />}
                                       helperText={<FormattedMessage id='products.detail.form.fields.device_manager_compatible.help' />}
                                       size='regular' />
                                <SpaceDivider />
                                <Field name='default_warranty_period' fieldType='TextField' type='number' label={<FormattedMessage id='products.detail.form.fields.default_warranty_period' />}
                                       helperText={<FormattedMessage id='products.detail.form.fields.default_warranty_period.help' />} />
                            </Form>
                        </Col>
                        <Col width='50%' />
                    </Row>
                    <SpaceDivider double />
                    <Row wrap>
                        <Col width='50%'>
                            <hr />
                            <Typography variant='body1'><FormattedMessage id='products.detail.basic.title' /></Typography>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='force_format' fieldType='Select' label={`${props.intl.formatMessage({id: 'products.detail.basic.form.fields.force_format'})}*`}
                                       helperText={<FormattedMessage id='products.detail.basic.form.fields.force_format.help' />}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    <MenuItem value='xml'>xml</MenuItem>
                                    <MenuItem value='cfg-vtech'>cfg-vtech</MenuItem>
                                    <MenuItem value='xml-vtech'>xml-vtech</MenuItem>
                                    <MenuItem value='html'>html</MenuItem>
                                    <MenuItem value='rtx'>rtx</MenuItem>
                                </Field>
                                <Field name='verify_mac' fieldType='Checkbox' label={<FormattedMessage id='products.detail.basic.form.fields.verify_mac' />}
                                       helperText={<FormattedMessage id='products.detail.basic.form.fields.verify_mac.help' />}
                                       size='regular' />
                                <SpaceDivider />
                                <JSONField name='mac_ranges' label={`${props.intl.formatMessage({id: 'products.detail.basic.form.fields.mac_ranges'})}*`}
                                           selectedValue={props.formValues.mac_ranges} />
                                <SpaceDivider />
                                <JSONField name='default_settings' label={`${props.intl.formatMessage({id: 'products.detail.basic.form.fields.default_settings'})}*`}
                                           selectedValue={props.formValues.default_settings} />
                            </Form>
                            <SpaceDivider double />
                        </Col>
                        <Col width='50%'>
                            <hr />
                            <Typography variant='body1'><FormattedMessage id='products.detail.behaviour.title' /></Typography>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='setting_server_strategy' fieldType='Select' label={`${props.intl.formatMessage({id: 'products.detail.behaviour.form.fields.setting_server_strategy'})}*`}
                                       helperText={<FormattedMessage id='products.detail.behaviour.form.fields.setting_server_strategy.help' />}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    <MenuItem value='Default'>Default</MenuItem>
                                    <MenuItem value='MSeries'>MSeries</MenuItem>
                                    <MenuItem value='VTech'>VTech</MenuItem>
                                </Field>
                                <Field name='update_strategy' fieldType='Select' label={`${props.intl.formatMessage({id: 'products.detail.behaviour.form.fields.update_strategy'})}*`}
                                       helperText={<FormattedMessage id='products.detail.behaviour.form.fields.update_strategy.help' />}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    <MenuItem value='default'><FormattedMessage id='products.detail.behaviour.form.fields.update_strategy.choice.default' /></MenuItem>
                                    <MenuItem value='immediate'><FormattedMessage id='products.detail.behaviour.form.fields.update_strategy.choice.immediate' /></MenuItem>
                                </Field>

                                {edit && <SB327SettingsField settings={sortedSettings} setting_groups={props.setting_groups_items} />}
                            </Form>
                            <SpaceDivider double />
                        </Col>
                    </Row>
                    <Row wrap>
                        <Col width='50%'>
                            <hr />
                            <Typography variant='body1'><FormattedMessage id='products.detail.firmware.title' /></Typography>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='firmware_strategy.strategy' fieldType='Select' label={`${props.intl.formatMessage({id: 'products.detail.firmware.form.fields.fw_strategy'})}*`}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    {['DSeries', 'D8xxSeries', 'MSeries', 'MSCSeries'].map(strategy => <MenuItem key={strategy} value={strategy}>{strategy}</MenuItem>)}
                                </Field>
                                <Field name='firmware_strategy.require_url' fieldType='Checkbox' label={<FormattedMessage id='products.detail.firmware.form.fields.fw_strategy.require_url' />}
                                       helperText={<FormattedMessage id='products.detail.firmware.form.fields.fw_strategy.require_url.help' />}
                                       size='regular' />
                                <SpaceDivider />
                                <Field name='minimum_firmware_version' fieldType='TextField' label={<FormattedMessage id='products.detail.firmware.form.fields.minimum_firmware_version' />}
                                       helperText={<FormattedMessage id='products.detail.firmware.form.fields.minimum_firmware_version.help' />} />
                                <Field name='upgrade_enabled' fieldType='Checkbox' label={<FormattedMessage id='products.detail.firmware.form.fields.upgrade_enabled' />}
                                       size='regular' />
                                <SpaceDivider />
                                <JSONField name='upgrade_firmwares' label={`${props.intl.formatMessage({id: 'products.detail.firmware.form.fields.upgrade_firmwares'})}*`}
                                           selectedValue={props.formValues.upgrade_firmwares} />
                            </Form>
                            <SpaceDivider double />
                        </Col>
                        <Col width='50%'>
                            <hr />
                            <Typography variant='body1'><FormattedMessage id='products.detail.accessories.title' /></Typography>
                            <hr />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='handsets' fieldType='TextField' label={<FormattedMessage id='products.detail.accessories.form.fields.handsets' />}
                                       helperText={<FormattedMessage id='products.detail.accessories.form.fields.handsets.help' />} />
                                {(props.formValues.handsets) &&
                                    <Field name='firmware_strategy.handsets_field_type' fieldType='Select' label={`${props.intl.formatMessage({id: 'products.detail.accessories.form.fields.fw_strategy.handsets_field_type'})}*`}
                                           helperText={<FormattedMessage id='products.detail.accessories.form.fields.fw_strategy.handsets_field_type.help' />}>
                                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                        <MenuItem value='url'><FormattedMessage id='products.detail.accessories.form.fields.fw_strategy.handsets_field_type.choice.url' /></MenuItem>
                                        <MenuItem value='version'><FormattedMessage id='products.detail.accessories.form.fields.fw_strategy.handsets_field_type.choice.version' /></MenuItem>
                                    </Field>
                                }
                            </Form>
                        </Col>
                    </Row>
                </CardContent>
                {['saving_item_products', 'saved_item_products', 'failed_save_item_products'].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_products'}
                                         failure={props.state === 'failed_save_item_products'}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/products')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <React.Fragment>
                            {edit &&
                                <Button variant='contained' color='secondary' disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>
                            }
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'products.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'products.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(Product, 'products', props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'products.detail.delete.fail'}).then(result => {
                                if (result !== false) {
                                    props.history.push('/products');
                                }
                            });
                    }}
                />}
            </Card>
        </div>}
    </div>;
}

/**
 * Convert Product record toObject() and also it's JSON fields. Used for initial values
 *
 * @param project - Project model
 */
function toObject(project) {
    project = project.toJS();
    if (project.groups) {
        project.groups = [...project.groups];
    }
    if (project.handsets) {
        project.handsets = project.handsets.join(', ');
    }
    // process json fields
    if (project.mac_ranges) {
        project.mac_ranges = JSON.stringify(project.mac_ranges);
    }
    if (project.default_settings) {
        project.default_settings = JSON.stringify(project.default_settings);
    }
    if (project.upgrade_firmwares) {
        project.upgrade_firmwares = JSON.stringify(project.upgrade_firmwares);
    }
    return project;
}

const validate = (data, props) => {
    const errors = {firmware_strategy: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    const nested_firmware_strategy = data.firmware_strategy || {}; // nested fix
    // get props.items without props.item
    const items_without_self = props.item ? props.items.filter(el => el.get(new Product().getUniqueIdentifier()) !== props.item.get(new Product().getUniqueIdentifier())) : props.items;

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'code', data.code) &&
    validator.isSlug(null, errors, 'code', data.code) &&
    validator.isUnique(
        props.intl.formatMessage({id: 'products.detail.error.code'}),
        errors, 'code', data.code,
        items_without_self, new Product().getUniqueIdentifier());
    validator.isNotNull(null, errors, 'groups', data.groups);
    validator.isInt(null, errors, 'default_warranty_period', data.default_warranty_period, {allow_leading_zeroes: false, min: 0});
    validator.isNotNull(null, errors, 'force_format', data.force_format);
    validator.isNotNull(null, errors, 'setting_server_strategy', data.setting_server_strategy);
    validator.isNotNull(null, errors, 'update_strategy', data.update_strategy);
    validator.isNotNull(null, errors, 'firmware_strategy.strategy', nested_firmware_strategy.strategy);
    if (data.handsets) {
        validator.isNotNull(null, errors, 'firmware_strategy.handsets_field_type', nested_firmware_strategy.handsets_field_type);
    }
    validator.isNotNull(null, errors, 'mac_ranges', data.mac_ranges) &&
    validator.isJSON(null, errors, 'mac_ranges', data.mac_ranges);
    validator.isNotNull(null, errors, 'default_settings', data.default_settings) &&
    validator.isJSON(null, errors, 'default_settings', data.default_settings);
    validator.isNotNull(null, errors, 'upgrade_firmwares', data.upgrade_firmwares) &&
    validator.isJSON(null, errors, 'upgrade_firmwares', data.upgrade_firmwares);

    return errors;
};

const DetailForm = reduxForm({
    form: 'productForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let [rest_of_data, identifier] = new Product().popUpFields(props.formValues, true);
        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));
        // process handsets
        if (rest_of_data['handsets']) {
            // remove spaces and split to array divided by comma
            rest_of_data['handsets'] = [...rest_of_data['handsets'].replace(/\s/g, '').split(',')];
        } else {
            rest_of_data['handsets'] = [];
        }
        // json fields needs to process
        rest_of_data['mac_ranges'] = JSON.parse(rest_of_data['mac_ranges']);
        rest_of_data['default_settings'] = JSON.parse(rest_of_data['default_settings']);
        rest_of_data['upgrade_firmwares'] = JSON.parse(rest_of_data['upgrade_firmwares']);

        return dispatch(saveItem(Product, 'products', props.item ? props.item.getIn(['links', 'self']) : ['products', identifier], rest_of_data, props.item, {update_method: 'put', save_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new Product().getUniqueIdentifier())) {
                props.history.push(`/products/${create_data.get(new Product().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items = state.shared.getIn(['items', 'products']);
    const item = items.find(el => el.get(new Product().getUniqueIdentifier()) === props.match.params.identifier);
    const all_settings = state.shared.getIn(['items', 'settings']);
    const settings_items = all_settings.filter(setting => {
        // We only want the settings that are part of the same product group as the current product.
        return setting.get('product_groups').some(product_group => item?.get('groups').includes(product_group));
    });

    return {
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', 'products']),
        items: items,
        item: item,
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        setting_groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        setting_groups_items: state.shared.getIn(['items', 'setting-groups']),
        settings_loaded: state.shared.getIn(['loaded', 'settings']),
        settings_items,
        initialValues: props.match.params.identifier
            ? item
                ? toObject(item)
                : undefined
            : {
                default_warranty_period: 12,
                verify_mac: true,
                upgrade_enabled: true,
                mac_ranges: '[\n{\n"start":"000000000000",\n"end":"000000000001"\n}\n]',
                default_settings: '{\n}',
                upgrade_firmwares: '{\n}'
            },
        formValues: getFormValues('productForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
