import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {Paginator} from 'lib/models';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {fetchItems, updatePaginator} from 'actions/shared';
// material-ui
import Checkbox from 'components/core/ui/mui/Checkbox';
import Skeleton from 'components/core/ui/mui/Skeleton';
import TableRow from 'components/core/ui/mui/TableRow';
import TableFooter from 'components/core/ui/mui/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
    // holder of < > buttons
    buttonsHolder: {
        position: 'relative',
        // reduce space between
        marginLeft: `${theme.spacing(1)}px`
    },
    // current page
    pageNumber: {
        // position between buttons next & back buttons
        position: 'absolute',
        right: '48px',
        top: '0',
        bottom: '0',
        zIndex: '20',
        // size
        width: `${theme.spacing(3)}px`,
        // style
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        // text itself
        '& span': {
            ...theme.typography.subtitle2
        }
    },
    // < button
    backButton: {
        // space for page number
        marginRight: `${theme.spacing(3)}px`
    }
}));

/**
 * Replaces <LinearProgress /> loading in Table to improve user experience
 *
 * Usage:
 *  <PaginationLoading paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
 *                     colSpan={tableHead.length} />
 */
export function PaginationLoading(props) {
    const {paginationLoading = true, tableHead, paginator = new Paginator(), selectCell = false} = props;

    return <TableBody>
        {paginationLoading
            ? [...Array(paginator.get('paginate_by'))].map((e, ridx) => <TableRow key={ridx}>
                {selectCell && <TableCell checkbox>
                    <Checkbox disabled />
                </TableCell>}
                {tableHead.map((item, idx) => <TableCell key={idx} numeric={item.numeric}>
                    <Skeleton />
                </TableCell>)}
            </TableRow>)
            : <TableRow>
                <TableCell colSpan={selectCell ? tableHead.length + 1 : tableHead.length}><LinearProgress /></TableCell>
            </TableRow>}
    </TableBody>;
}

/**
 * Pagination for List tables
 *
 * Usage:
 *  <Pagination model={User} placement={items_placement} filters={filters} />
 */
export default function Pagination(props) {
    const {model, placement, filters = {}, paginationLoading = false, ...rest_of_props} = props;
    const intl = useIntl();
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const {state, paginator} = useSelector(state => ({
        state: state.app.get('state'),
        paginator: state.shared.getIn(['paginator', placement]) || new Paginator()
    }), shallowEqual);

    return <TableFooter>
        <TableRow>
            <TablePagination
                labelDisplayedRows={({from, to, count, page}) => <span>
                    {`${intl.formatMessage({id: 'pagination.label.page'})}: `}
                    <span className={classes.pageNumber}>
                        <span>{page + 1}</span>
                    </span>
                </span>}
                nextIconButtonText={intl.formatMessage({id: 'pagination.label.next'})}
                nextIconButtonProps={{...(state !== null ? {disabled: true} : {})}}
                backIconButtonProps={{...(state !== null ? {disabled: true} : {}),
                    className: classes.backButton}}
                classes={{actions: classes.buttonsHolder}}
                backIconButtonText={intl.formatMessage({id: 'pagination.label.back'})}
                page={paginator.get('page') - 1}
                count={paginator.get('page') === paginator.get('maxLoadedPage') && !paginator.get('next')
                    ? paginator.get('maxLoadedPage')
                    : paginator.get('maxLoadedPage') + 1}
                rowsPerPage={1}
                rowsPerPageOptions={[]}
                onPageChange={(event, page) => {
                    // ensure that we have clear state (disabled status)
                    if (state !== null) {
                        return;
                    }
                    // correct page for paginator
                    const newPage = page + 1;
                    // check if we are moving forward (where Pages are not fetched)
                    if (newPage > paginator.get('maxLoadedPage')) {
                        // fetch records
                        dispatch(fetchItems(model, placement, paginator.get('next'), null,
                            // filters are already presented in the paginator link, so we don't use them
                            // we send empty {} to make sure we have expected loaded status
                            Object.keys(filters).length ? {} : null,
                            {paginate: true, paginator_page: newPage,
                                state_name: `${paginationLoading ? 'pagination-' : ''}${placement}`
                            }));
                    } else {
                        // we just need to update paginator
                        dispatch(updatePaginator({...paginator.toJS(), page: newPage}, placement));
                    }
                }}
                {...rest_of_props}
            />
        </TableRow>
    </TableFooter>;
}
