import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
// Actions
import {setState as setAuthState} from 'actions/auth';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import NotActivatedIcon from '@material-ui/icons/PanToolOutlined';
import BackIcon from '@material-ui/icons/UndoOutlined';


/**
 * Page for User which is Company is not yet Approved
 */
export default function NotApproved() {
    const intl = useIntl();
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');
    // redux store
    const dispatch = useDispatch();
    const {authState} = useSelector(state => ({
        authState: state.auth.get('state')
    }), shallowEqual);

    /**
     * Check authState and deal with it
     */
    useEffect(() => {
        // This Page is not for everyone, you need authState === 'not-activated'
        if (authState !== 'not_approved') {
            // redirect to login
            history.push(portal ? '/partner' : '/');
        }
        return () => dispatch(setAuthState(null));
    }, [authState]);
    // unmount cleanup, make sure to reset Auth state
    useEffect(() => {
        return () => authState !== null ? dispatch(setAuthState(null)) : {};
    }, []);

    return <Card>
        {authState !== 'not_approved'
            ? <CardContent>
                <SpaceDivider loading />
                <LinearProgress />
                <SpaceDivider loading />
            </CardContent>
            : <React.Fragment>
                <CardHeader
                    title={<FormattedMessage id='not_approved.title' />}
                    subheader={<FormattedMessage id='not_approved.subheader' />}
                    action={<ActionButton iconButton disabled>
                        <NotActivatedIcon />
                    </ActionButton>}
                />
                <CardContent>
                    <p className='notmargin'>
                        <FormattedMessage id='not_approved.description.first' />
                        <br />
                        <FormattedMessage id='not_approved.description.second' />
                    </p>
                    <p><FormattedMessage id='not_approved.description.trouble'
                                         values={{link: <a href={intl.formatMessage({id: 'not_approved.description.trouble.link'})}
                                                           className='hover-border' target='_blank' rel='noreferrer'>
                                                 <FormattedMessage id='not_approved.description.trouble.link' />
                                             </a>}} /></p>
                </CardContent>
                <CardActions>
                    <Button onClick={() => history.push(portal ? '/partner' : '/')}>
                        <BackIcon />
                        <FormattedMessage id='actions.back' />
                    </Button>
                </CardActions>
            </React.Fragment>
        }
    </Card>;
}
