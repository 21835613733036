import React, {useEffect, useMemo, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFormValues, reduxForm} from 'redux-form';
import validator from 'lib/valitator';
import {useLocalSort} from 'lib/filters';
import {Distributor, PBXPartner} from 'lib/models';
import {FormattedMessage, injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {addMessage} from 'actions/app';
import {loginProcedure, setState as setAuthState} from 'actions/auth';
import {fetchItems, simplePost} from 'actions/shared';
// Components
import {withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import ToSDialog from 'components/core/ui/ToSDialog';
import Field, {CompanyTypeField, CountryField, DepartmentField, FieldIcon, FieldWithIconHolder, SalutationField} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import RegisterWave from 'components/core/vectors/waves/Register';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SubmitIcon from '@material-ui/icons/FingerprintOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';


const useStyles = makeStyles(theme => ({
    wave: {
        // move into content
        marginBottom: `-${theme.spacing(12)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            marginBottom: `-${theme.spacing(4)}px`
        }
    }
}));

/**
 * New User Registration
 */
function Register(props) {
    const classes = useStyles();
    // local state
    const [showPasswords, setShowPasswords] = useState(false);
    const [tosDialogOpen, setTosDialogOpen] = useState(false);
    // sorting
    const [locallySortedDistributors] = useLocalSort(props.distributors_items, !props.distributors_loaded);
    const sortedDistributors = useMemo(() => {
        return locallySortedDistributors.filter(distributor => distributor.get('active'));
    }, [locallySortedDistributors]);
    const [locallySortedPBXPartners] = useLocalSort(props.pbx_partners_items, !props.pbx_partners_loaded);
    const sortedPBXPartners = useMemo(() => {
        return locallySortedPBXPartners.filter(pbx_partner => pbx_partner.get('active'));
    }, [locallySortedPBXPartners]);

    const dispatch = useDispatch();

    /**
     * During initialization check for Token
     */
    useEffect(() => {
        // In case we have Token provided, confirm registration
        if (props.token) {
            props.simplePost('confirm_registration', 'verify-email', {token: props.token}, {post_method: 'put', setState: props.setAuthState, submissionError: false}).then((result) => {
                // The API autoapproved the account, let's authenticate the user automatically.
                if (result?.data?.token) {
                    // Continue the loading animation while the succeeding dispatch finishes.
                    props.setAuthState('posting_confirm_registration');

                    return dispatch(loginProcedure(result)).then(() => {
                        props.history.push(`${props.portal ? '/partner' : ''}/home`);
                        return dispatch(addMessage({intl_id: 'register.confirmed.login', path: `${props.portal ? '/partner' : ''}/home`}));
                    });
                }

                // remove token from url
                props.history.push(`${props.portal ? '/partner' : ''}/registration`);
            });
        }
    }, [props.token]);
    useEffect(() => {
        // handle invalid token
        if (props.authState === 'failed_confirm_registration') {
            props.addMessage({intl_id: 'register.error.invalid_token', type: 'error', path: `${props.portal ? '/partner' : ''}/registration`});
        }
    }, [props.authState]);
    // unmount cleanup, make sure to reset Auth state
    useEffect(() => {
        return () => props.authState !== null ? props.setAuthState(null) : {};
    }, []);
    // related data fetch without affecting state for related fields
    useEffect(() => {
        if (props.distributors_loaded === false) {
            props.fetchItems(Distributor, 'distributors', 'distributors', null, null, {affect_state: false});
        }
    }, [props.distributors_loaded]);
    useEffect(() => {
        if (props.pbx_partners_loaded === false) {
            props.fetchItems(PBXPartner, 'pbx-partners', 'pbx-partners', null, null, {affect_state: false});
        }
    }, [props.pbx_partners_loaded]);

    return <Card>
        {props.authState === 'posting_confirm_registration'
            ? <CardContent>
            <SpaceDivider loading />
            <LinearProgress />
            <SpaceDivider loading />
        </CardContent>
            : <React.Fragment>
            <RegisterWave className={classes.wave} />
            {['posted_registration', 'posted_registration_afterAnim'].includes(props.authState)
                ? <CardHeader title={<FormattedMessage id='register.success.title' />}
                              subheader={<FormattedMessage id='register.success.subheader' />} />
                : props.authState === 'posted_confirm_registration'
                ? <CardHeader title={<FormattedMessage id='register.confirmed.title' />}
                              subheader={<FormattedMessage id='register.confirmed.subheader' />} />
                : <CardHeader title={<FormattedMessage id='register.title' />}
                              subheader={<FormattedMessage id='register.subheader' />} />}
            {[null, 'posting_registration', 'failed_registration'].includes(props.authState) && <CardContent>
                <h6 className='nomargin'><FormattedMessage id='register.section.company' /></h6>
                <Form onSubmit={props.handleSubmit}>
                    <Field name='company.name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.company.name'})}*`} />
                    <CompanyTypeField name='company.company_type' required disabled={props.portal}
                                      helperText={props.portal ? <FormattedMessage id='register.form.fields.company.company_type.portal.help' /> : null} />
                    <SpaceDivider none />
                    <CountryField name='company.country' label={`${props.intl.formatMessage({id: 'register.form.fields.company.country'})}*`} />
                    <Field name='company.city' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.company.city'})}*`} />
                    <SpaceDivider none />
                    <Field name='company.street' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.company.street'})}*`} />
                    <Field name='company.postal_code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.company.postal_code'})}*`} />
                </Form>
                {props.portal && <React.Fragment>
                    <h6 className='nobmargin'><FormattedMessage id='register.section.portal' /></h6>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='company.distributor' fieldType='Select'
                               label={`${props.intl.formatMessage({id: 'register.form.fields.distributor'})}*`}
                               disabled={!props.distributors_loaded} loading={!props.distributors_loaded}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            {sortedDistributors.map((distributor, idx) =>
                                <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                            )}
                        </Field>
                        <Field name='company.distributor_2' fieldType='Select'
                               label={<FormattedMessage id='register.form.fields.distributor_2' />}
                               disabled={!props.distributors_loaded} loading={!props.distributors_loaded}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            {sortedDistributors.map((distributor, idx) =>
                                <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                            )}
                        </Field>
                        <SpaceDivider />
                        <Field name='company.pbx_partners' fieldType='Select' multiple
                               label={`${props.intl.formatMessage({id: 'register.form.fields.pbx_partners'})}*`}
                               disabled={!props.pbx_partners_loaded} loading={!props.pbx_partners_loaded}>
                            {sortedPBXPartners.map((pbx_partner, idx) =>
                                <MenuItem key={idx} value={pbx_partner.get(new PBXPartner().getUniqueIdentifier())}>{pbx_partner.get('name')}</MenuItem>
                            )}
                        </Field>
                    </Form>
                </React.Fragment>}
                <h6 className='nobmargin'><FormattedMessage id='register.section.account' /></h6>
                <Form onSubmit={props.handleSubmit}>
                    <Field name='user.username' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.username'})}*`} />
                    <SalutationField name='user.salutation' required />
                    <SpaceDivider none />
                    <Field name='user.first_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.first_name'})}*`} />
                    <Field name='user.last_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.last_name'})}*`} />
                    <SpaceDivider none />
                    <Field name='user.email' type='email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.email'})}*`} />
                    <Field name='user.phone_number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.phone'})}*`} />
                    <SpaceDivider none />
                    <DepartmentField name='user.department' required />
                </Form>
                <h6 className='nobmargin'><FormattedMessage id='register.section.secure' /></h6>
                <Form onSubmit={props.handleSubmit}>
                    <Field name='user.password' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.password'})}*`}
                           helperText={<FormattedMessage id='register.form.fields.password.help' values={{length: 12}} />} />
                    <FieldWithIconHolder>
                        <Field withIcon name='user.password2' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={`${props.intl.formatMessage({id: 'register.form.fields.password2'})}*`} />
                        <FieldIcon onClick={() => setShowPasswords(!showPasswords)}>
                            {showPasswords ? <VisibilityOff /> : <Visibility />}
                        </FieldIcon>
                    </FieldWithIconHolder>
                </Form>
                <SpaceDivider double />
                <Form onSubmit={props.handleSubmit}>
                    <Field name='user.subscription' fieldType='Checkbox'
                           size='full' className='flip'
                           label={<FormattedMessage id='register.form.fields.subs' />} />
                    <SpaceDivider none />
                    <Field name='tos' fieldType='Checkbox'
                           size='full' className='flip'
                           label={props.portal
                               ? <React.Fragment>
                                   <FormattedMessage id='tosfield.portal.description'
                                                     values={{
                                                         toc_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.toc.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.toc' />
                                                         </a>,
                                                         pp_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.pp.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.pp' />
                                                         </a>
                                                     }} />
                                   {'*'}
                               </React.Fragment>
                               : <React.Fragment>
                                   <FormattedMessage id='tosfield.description'
                                                     values={{link: <a href='#' className='hover-border'
                                                                       onClick={(e) => { e.preventDefault(); setTosDialogOpen(true); }}>
                                                             <FormattedMessage id='tosfield.description.link' />
                                                         </a>}} />
                                   {'*'}
                               </React.Fragment>
                           } />
                </Form>
                <ToSDialog open={tosDialogOpen} handleClose={() => setTosDialogOpen(false)} />
            </CardContent>}
            {['posting_registration', 'failed_registration', 'posted_registration'].includes(props.authState)
                ? <CardActionsLoader failure={props.authState === 'failed_registration'}
                                     success={props.authState === 'posted_registration'}
                                     postAnimation={(success) => {
                                         if (success) {
                                             props.setAuthState('posted_registration_afterAnim');
                                         } else {
                                             props.setAuthState(null);
                                         }
                                     }} />
                : <CardActions>
                <Button onClick={() => props.history.push(props.portal ? '/partner' : '/')}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                {props.authState === null && <Button variant='contained' color='secondary' type='submit' onClick={props.handleSubmit}>
                    <SubmitIcon />
                    <FormattedMessage id='register.form.submit' />
                </Button>}
            </CardActions>}
        </React.Fragment>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {user: {}, company: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    const nested_user = data.user || {}; // nested fix
    const nested_company = data.company || {}; // nested fix

    validator.isNotNull(null, errors, 'user.username', nested_user.username);
    validator.isNotNull(null, errors, 'user.salutation', nested_user.salutation);
    validator.isNotNull(null, errors, 'user.first_name', nested_user.first_name);
    validator.isNotNull(null, errors, 'user.last_name', nested_user.last_name);
    validator.isNotNull(null, errors, 'user.email', nested_user.email) &&
    validator.isEmail(null, errors, 'user.email', nested_user.email);
    validator.isNotNull(null, errors, 'user.phone_number', nested_user.phone_number);
    validator.isNotNull(null, errors, 'user.department', nested_user.department);

    validator.isNotNull(null, errors, 'company.name', nested_company.name);
    validator.isNotNull(null, errors, 'company.company_type', nested_company.company_type);
    validator.isNotNull(null, errors, 'company.country', nested_company.country);
    validator.isNotNull(null, errors, 'company.city', nested_company.city);
    validator.isNotNull(null, errors, 'company.street', nested_company.street);
    validator.isNotNull(null, errors, 'company.postal_code', nested_company.postal_code);

    validator.isLength(
        props.intl.formatMessage({id: 'register.error.length'}, {length: 12}), errors, 'user.password', nested_user.password,
        {min: 12, max: 128}) &&
    validator.isNotNull(null, errors, 'user.password', nested_user.password) &&
    validator.containsUpperCase(props.intl.formatMessage({id: 'register.error.uppercase'}), errors, 'user.password', nested_user.password) &&
    validator.containsLowerCase(props.intl.formatMessage({id: 'register.error.lowercase'}), errors, 'user.password', nested_user.password) &&
    validator.containsNumber(props.intl.formatMessage({id: 'register.error.number'}), errors, 'user.password', nested_user.password) &&
    validator.containsSpecialCharacter(props.intl.formatMessage({id: 'register.error.special'}), errors, 'user.password', nested_user.password);
    validator.isNotNull(null, errors, 'user.password2', nested_user.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'register.error.no_match'}),
        errors, 'user.password2',
        nested_user.password, nested_user.password2);

    validator.isTrue(
        props.intl.formatMessage({id: `tosfield.${props.portal ? 'portal.' : ''}error.required`}),
        errors, 'tos', data.tos
    );

    // prevent partner portal form from submit if additional fields are not filled
    if (props.portal) {
        validator.isNotNull(null, errors, 'company.distributor', nested_company.distributor);
        validator.isNotNull(null, errors, 'company.pbx_partners', nested_company.pbx_partners);
    }

    return errors;
};

const RegisterForm = reduxForm({
    form: 'registerForm',
    validate,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send, copy object to not immediately modify it's reference
        const rest_of_data = JSON.parse(JSON.stringify(props.formValues));
        const {password2, ...rest_of_user_data} = rest_of_data.user;
        rest_of_data.user = rest_of_user_data;

        return dispatch(simplePost('registration', 'accounts', rest_of_data, {setState: props.setAuthState}));
    }
})(Register);

const ConnectedRegister = connect((state, props) => {
    const portal = props.location.pathname.startsWith('/partner');

    return {
        portal: portal,
        authState: state.auth.get('state'),
        token: new URLSearchParams(props.location.search).get('token'),
        distributors_loaded: state.shared.getIn(['loaded', 'distributors']),
        distributors_items: state.shared.getIn(['items', 'distributors']),
        pbx_partners_loaded: state.shared.getIn(['loaded', 'pbx-partners']),
        pbx_partners_items: state.shared.getIn(['items', 'pbx-partners']),
        initialValues: {
            user: {}, company: {
                signup_sources: portal ? ['partner_portal'] : ['sraps'],
                company_type: portal ? 'reseller' : null
            }
        },
        formValues: getFormValues('registerForm')(state)
    };
}, (dispatch) => bindActionCreators({
    addMessage,
    simplePost,
    fetchItems,
    setAuthState
}, dispatch))(RegisterForm);

export default injectIntl(withRouter(ConnectedRegister));
