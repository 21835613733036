import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import {alpha} from '@material-ui/core/styles';
import {theme} from 'theme';
// vectors
import UpdateIcon from '@material-ui/icons/UpdateOutlined';
import ReleaseIcon from '@material-ui/icons/Send';
import SpecialUpdateIcon from '@material-ui/icons/NewReleases';


const useStyles = makeStyles((theme) => {
    return ({
        container: (props) => ({
            // make color of background color of container dependent on changelog type
            // release changelog type has a green background color
            backgroundColor: props.filled ? props.color : alpha(props.color, 0.1),
            // color border of container dependent on changelog type
            border: `1px solid ${props.color}`,
            borderRadius: '50%',
            padding: `${theme.spacing(0.7)}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }),
        icon: (props) => ({
            // make color of icon dependent on changelog type
            // release changelog type has a white icon, but icons without container have a own color
            color: props.filled && !props.noContainer ? theme.palette.common.white : props.color,
            fontSize: `${theme.spacing(2.75)}px`,
            '&.releaseIcon': {
                transform: 'rotate(-45deg) translate(2.5px, 0px)',
                ...(!props.noContainer && {fontSize: `${theme.spacing(3.4)}px`})
            }
        })
    });
});

/**
 * Render icon based on changelog type
 * @param {object} props - props object
 * @param {'release'|'special_update'|'regular_update'} props.type - changelog type
 * @param {boolean} props.noContainer - if true, render icon without container. Default: false
 * @param {object} props.iconProps - props for icon
 */
export default function ChangelogTypeIcon(props) {
    const {type, noContainer, ...rest_of_props} = props;
    const isRelease = type === 'release';

    const [Icon, color] = useMemo(() => {
        switch (type) {
            case 'release':
                return [ReleaseIcon, theme.graphs[4]];
            case 'special_update':
                return [SpecialUpdateIcon, theme.palette.danger.main];
            case 'regular_update':
            default:
                return [UpdateIcon, theme.palette.orange.main];
        }
    }, [type]);

    const classes = useStyles({color, filled: isRelease, noContainer});
    const iconClasses = [classes.icon, isRelease && 'releaseIcon', rest_of_props?.iconProps?.className].filter(Boolean).join(' ');

    return !noContainer ? <div className={[classes.container, rest_of_props?.className].filter(Boolean).join(' ')} {...rest_of_props}>
        <Icon className={iconClasses} {...rest_of_props?.iconProps} />
    </div>
    : <Icon className={iconClasses} {...rest_of_props?.iconProps} />;
}
