import React from 'react';
import {theme} from 'theme';


/**
 * Wave vector for subscription plans
 */
export default function TierWave(props) {
    const {color1 = theme.gradients.blue.color1, color2 = theme.gradients.blue.color2} = props;

    return <svg viewBox='0 0 540 327.63351' style={{display: 'block'}} {...props}>
        <path d='m 691.777,340.428 c -0.111,-0.54 -0.208,-1.083 -0.32,-1.638 -2.673,-13.861 -1.935,-27.718 -10.076,-40.189 -7.938,-12.145 -21.629,-19.299 -35.082,-25.074 -27.528,-11.8 -56.355,-16.147 -83.342,-0.607 -55.583,32.036 -57.8,101.215 -88.926,150.74 -52.999,84.334 -169.534,104.483 -260.308,78.181 -41.57,-12.042 -83.236,-32.83 -125.957,-25.51 -66.5552,11.414 -107.2534,79.847 -142.3746,129.827 -23.9598,34.093 -39.7618,81.497 -82.7954,96.98 -31.907,11.49 -67.414,0.498 -98.144,-13.667 -44.295,-20.409 -87.624,-41.963 -137.841,-28.949 -39.089,10.135 -70.654,36.634 -98.587,64.219 -26.201,25.883 -60.345,54.183 -73.565,89.412 -8.447,22.54 -1.66,57.877 28.139,56.92 8.506,-0.272 16.65,-3.648 23.908,-8.078 21.187,-12.957 35.415,-34.291 55.328,-48.844 22.205,-16.226 49.005,-27.98 75.377,-35.241 50.366,-13.865 95.119,-5.87 144.804,4.158 53.16,10.724 103.925,3.029 151.3962,-23.235 31.6929,-17.544 57.8629,-43.908 89.1302,-61.232 35.4253,-19.632 72.1034,-36.165 113.6936,-34.571 29.491,1.133 55.852,12.221 82.79,23.253 57.052,23.358 115.2,38.394 176.478,43.931 48.802,4.43 97.898,4.216 146.801,2.558 37.85,-1.289 76.5,-1.713 114.064,-6.704 17.697,-2.347 35.376,-6.093 51.936,-12.885 8.056,-3.306 15.823,-7.326 23.039,-12.221 5.07,-3.445 16.936,-10.436 19.045,-16.496 7.829,-22.49 -1.104,-46.804 -5.453,-69.359 -6.676,-43.727 -16.811,-87.223 -25.196,-130.651 -9.317,-48.348 -18.646,-96.68 -27.962,-145.028 z'
              fill='url(#paint0_linear_4446_38694)' transform='translate(0,-262.88797)' />
        <path d='m -160.926,766.638 c 32.45,-9.297 33.641,-45.805 52.214,-69.443 24.532,-31.219 58.3473,0.45 89.2092,0.196 40.5122,-0.322 70.6368,-26.918 90.1452,-62.304 16.2053,-29.402 19.1153,-69.417 47.7606,-88.695 44.3,-29.812 103.809,10.18 151.427,15.668 34.757,4.013 71.556,-0.214 98.758,-21.906 38.352,-30.587 48.641,-86.848 59.846,-137.59 11.204,-50.743 30.805,-106.356 76.19,-123.623 51.21,-19.484 106.814,19.06 160.436,37.206 76.355,25.833 157.163,9.702 231.469,-14.069'
              fill='transparent' stroke={color1} strokeWidth='3' strokeMiterlimit='10' transform='translate(0,-262.88797)' />
        <defs>
            <linearGradient id='paint0_linear_4446_38694' x1='-573.85199' y1='674.37201' x2='518.42798' y2='495.38199' gradientUnits='userSpaceOnUse'>
                <stop offset='0.503602' stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
        </defs>
    </svg>;
}
