import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUICardActions from '@material-ui/core/CardActions';


const useStyles = makeStyles(theme => ({
    cardActionsRoot: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
        '&.center': {
            justifyContent: 'center'
        },
        '&.left': {
            justifyContent: 'flex-start'
        },
        '&.spacebetween': {
            justifyContent: 'space-between'
        },
        '&.nospace': {
            padding: '0'
        }
    }
}));

/**
 * Extends CardActions component to change root style (right align by default)
 */
export default function CardActions(props) {
    // Split some stuff from props
    const {_classes, className, left, center, spaceBetween, nospace, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.cardActionsRoot, root, className,
        center ? 'center' : left ? 'left' : '',
        spaceBetween ? 'spacebetween' : '',
        nospace ? 'nospace' : ''
    ].filter(Boolean).join(' ');

    return <MUICardActions
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props}
    />;
}
