import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment/moment';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import Tooltip from 'components/core/ui/mui/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Icons
import InfoIcon from '@material-ui/icons/Info';


const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        fontWeight: '500',
        background: theme.palette.grey[100]
    },
    fields: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
        [theme.breakpoints.up('lg')]: {
            '&.horizontal': {
                gap: `${theme.spacing(3)}px ${theme.spacing(4)}px`
            }
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr'
        }
    },
    fieldWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: `${theme.spacing(1)}px`,
        [theme.breakpoints.up('lg')]: {
            // next to each other
            '&.horizontal': {
                flexDirection: 'row',
                '& > div:last-child': {
                    textAlign: 'right'
                }
            }
        }
    },
    fieldName: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: `${theme.spacing(0.5)}px`,
        fontWeight: theme.typography.fontWeightMedium,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'stretch',
            '& small': {
                display: 'block',
                fontWeight: theme.typography.fontWeightRegular
            }
        }
    },
    tooltip: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.pxToRem(21)
    }
}));

/**
 *  Renders group box with selected parameters
 */
export default function ParametersBox(props) {
    const {horizontal = false, title = '', icon, fields = [], item} = props;
    const classes = useStyles();
    const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return <Card>
        <div className={classes.header}>
            {icon}
            <FormattedMessage id={`voicequality.detail.group.${title}`} />
        </div>
        {(!!fields.length && item) &&
            <CardContent className={`${classes.fields}${horizontal ? ' horizontal' : ''}`}>
                {fields.map(fieldProps => {
                    const {key, type = 'text', helper = true, params = true} = fieldProps;
                    const value = params ? item.getIn(['params', key]) : item.get(key);

                    return <div key={key} className={`${classes.fieldWrapper}${horizontal ? ' horizontal' : ''}`}>
                        <div className={classes.fieldName}>
                            {helper && (
                                mobile
                                    ? <small><FormattedMessage id={`voicequality.params.${key.toLowerCase()}.help`} /></small>
                                    : <Tooltip
                                        className={classes.tooltip}
                                        title={<FormattedMessage id={`voicequality.params.${key.toLowerCase()}.help`} />}>
                                        <InfoIcon />
                                    </Tooltip>
                            )}
                            <FormattedMessage id={`voicequality.params.${key.toLowerCase()}`} />
                        </div>
                        <div>{type === 'date' && value ? Moment(value).format('l, LTS') : value || '-'}</div>
                    </div>;
                })}
            </CardContent>
        }
    </Card>;
}
