import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
import {AssetFile, AssetFolder} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// components
import AssetFileGrid from 'components/core/ui/AssetFileGrid';
import AssetFileGridItem from 'components/core/ui/AssetFileGridItem';
import AssetFileList from 'components/core/ui/AssetFileList';
import AssetFileListItem from 'components/core/ui/AssetFileListItem';
import FolderActionButton from 'components/modules/assets/FolderActionButton';
import FileActionButton from 'components/modules/assets/FileActionButton';
// material-ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';


const useStyles = makeStyles((theme) => ({
    placeholder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(10)}px ${theme.spacing(2)}px`,
        color: theme.palette.snomGrey[300],
        '& .MuiSvgIcon-root': {
            fontSize: `${theme.spacing(10)}px`
        }
    },
    fileDivider: {
        width: '100%',
        backgroundColor: theme.palette.snomGrey[100],
        padding: `${theme.spacing(0.5)}px ${theme.spacing(8)}px`,
        '& > .MuiTypography-root': {
            fontWeight: theme.typography.body1.fontWeight,
            color: theme.palette.snomGrey[800]
        }
    }
}));

/**
 * Asset Manager renders files and folders in a list or grid by provided folderPath
 * @param {object} props - component props
 * @param {string} props.folderPath - current folder path to display its files, null - root
 * @param {boolean} props.editable - allow manage files/folders
 * @param {boolean} props.disableFetchFolders - disable fetching folders, by default - true
 * @param {'grid'|'list'} props.viewType - force set view type
 * @param {boolean} props.flat - flat render flag
 * @param {boolean} props.disablePlaceholder - flat render flag
 * @param {(path) => void} props.onClickFolder - folder click handler, prevents default click handler
 */
export default function AssetManager(props) {
    const {flat, folderPath = '', editable, disableFetchFolders = true, disablePlaceholder} = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const asset_folders_placement = useMemo(() => new AssetFolder().getPlacement(), []);
    const asset_files_placement = useMemo(() => new AssetFile().getPlacement(), []);
    const asset_folders_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);
    const asset_files_identifier = useMemo(() => new AssetFile().getUniqueIdentifier(), []);
    const folderId = useMemo(() => folderPath.split('_').slice(-1).filter(Boolean)[0], [folderPath]);
    // check for root folder to avoid fetching files
    const isRoot = useMemo(() => !folderId, [folderId]);
    const currentFolderPlacement = useMemo(() => 
        isRoot ? asset_folders_placement : `${asset_folders_placement}-${folderId}`, [isRoot, folderId]
    );
    const currentFilePlacement = useMemo(() => folderId && `${asset_files_placement}-${folderId}`, [folderId]);

    // select files of current folder
    const {asset_file_items, asset_file_loaded, asset_file_loading} = useSelector((state) => {
        const items = state.shared.getIn(['items', currentFilePlacement]);
        const items_loaded = state.shared.getIn(['loaded', currentFilePlacement]);

        if (isRoot) {
            return {asset_file_loading: false, items_loaded: true, items: []};
        }

        return {asset_file_loading: !items && !items_loaded, asset_file_loaded: items_loaded, asset_file_items: items};
    }, shallowEqual);
    // select child folders of current folder
    const {asset_folder_items, asset_folders_loading,
        asset_folders_loaded, viewType, rootLink} = useSelector((state) => {
        const items = state.shared.getIn(['items', currentFolderPlacement]);
        const items_loaded = state.shared.getIn(['loaded', currentFolderPlacement]);

        return {asset_folders_loading: !items && !items_loaded, viewType: props.viewType || state.shared.getIn(['state', 'assets-view-type']),
            asset_folders_loaded: items_loaded, asset_folder_items: items, rootLink: state.api?.get('client')?.rootMap.get('folders')};
    }, shallowEqual);

    const isLoading = asset_file_loading || asset_folders_loading;
    // fetch folders files
    useEffect(() => {
        if (!asset_file_loaded && !isRoot && folderId && rootLink) {
            dispatch(fetchItems(AssetFile, currentFilePlacement, `${rootLink}${folderId}/files/`));
        }
    }, [asset_file_loaded, asset_file_loaded, folderId, isRoot, rootLink]);

    // fetch child folders
    useEffect(() => {
        if (!asset_folders_loaded && !disableFetchFolders && rootLink) {
            dispatch(fetchItems(AssetFolder, currentFolderPlacement, folderId ? `${rootLink}${folderId}/folders/` : 'folders'));
        }
    }, [asset_folders_loaded, disableFetchFolders, rootLink]);

    const onClickFolder = useCallback((path) => {
        props.onClickFolder ? props.onClickFolder(path) : history.push(`/asset-manager/${path}`);
    }, [folderPath]);
    const onClickFile = useCallback((fileUrl) => window.open(fileUrl, '_blank'), []);

    if (!isLoading && !asset_file_items?.size && !asset_folder_items?.size) {
        return disablePlaceholder ? null : <Box className={classes.placeholder}>
            <CloudUploadIcon />
            <Typography align='center' variant='h6'>
                <FormattedMessage id='assets.file_manager.list.empty_placeholder' />
            </Typography>
        </Box>;
    }

    if (viewType === 'grid') {
        if (flat) {
            return <React.Fragment>
                {(isLoading || !!asset_file_items?.size) && <AssetFileGrid isLoading={isLoading}>
                    {asset_file_items?.map((file) => <AssetFileGridItem asset={file} key={file.get(asset_files_identifier)} onClick={() => onClickFile(file.get('file_url'))} />)}
                </AssetFileGrid>}
                {!isLoading && <AssetFileGrid flat={flat}>
                    {asset_folder_items?.map((folder) => <React.Fragment key={folder.get(asset_folders_identifier)}>
                        <div className={classes.fileDivider}>
                            <Typography variant='h6'>{folder.get('name')}</Typography>
                        </div>
                        <AssetManager {...props} viewType={viewType} folderPath={folder.get(asset_folders_identifier)} />
                    </React.Fragment>)}
                </AssetFileGrid>}
            </React.Fragment>;
        }

        return <AssetFileGrid isLoading={isLoading}>
            {asset_folder_items?.map((folder) => {
                const currentFolderPath = folderPath ? `${folderPath}_${folder.get(asset_folders_identifier)}` : folder.get(asset_folders_identifier);
                return <AssetFileGridItem key={folder.get(asset_folders_identifier)} onClick={() => onClickFolder(currentFolderPath)}
                    asset={folder} cardHeaderAction={editable && <FolderActionButton folderPath={currentFolderPath} />} />;
            })}
            {asset_file_items?.map((file) => <AssetFileGridItem asset={file} key={file.get(asset_files_identifier)}
                cardHeaderAction={editable && <FileActionButton fileId={file.get(asset_files_identifier)} />} onClick={() => onClickFile(file.get('file_url'))} />)}
        </AssetFileGrid>;
    }

    return <AssetFileList isLoading={isLoading} editable={editable}>
        {asset_folder_items?.map((folder) => {
            const currentFolderPath = folderPath ? `${folderPath}_${folder.get(asset_folders_identifier)}` : folder.get(asset_folders_identifier);

            return <AssetFileListItem key={folder.get(asset_folders_identifier)} onClick={() => onClickFolder(currentFolderPath)}
                asset={folder} actionButton={editable && <FolderActionButton folderPath={currentFolderPath} />}  />;
        })}
        {asset_file_items?.map((file) => {
            return <AssetFileListItem key={file.get(asset_files_identifier)} asset={file} onClick={() => onClickFile(file.get('file_url'))}
                actionButton={editable && <FileActionButton fileId={file.get(asset_files_identifier)} />}  />;
        })}
    </AssetFileList>;
}
