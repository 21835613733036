import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Submission} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import StatusOpenIcon from '@material-ui/icons/NewReleasesOutlined';
import StatusCheckedIcon from '@material-ui/icons/FindInPageOutlined';
import StatusPendingIcon from '@material-ui/icons/MoreHorizOutlined';
import StatusFinishedIcon from '@material-ui/icons/CheckOutlined';
import StatusDeclinedIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    statusIcon: {
        // variants
        '&.finished': {
            color: theme.palette.success[500]
        },
        '&.declined': {
            color: theme.palette.danger[500]
        }
    }
}));

/**
 * Render Submission Status Icon
 *
 * Props:
 *  identifier - Status, e.g. 'open'
 */
export function StatusIcon(props) {
    // Split some stuff from props
    let {_classes, className, identifier, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let {root} = _classes || {};
    let rootClasses = [
        classes.statusIcon, root, className,
        identifier
    ].filter(Boolean).join(' ');

    let IconElement;
    switch (identifier) {
        case 'open':
            IconElement = StatusOpenIcon;
            break;
        case 'checked':
            IconElement = StatusCheckedIcon;
            break;
        case 'pending':
            IconElement = StatusPendingIcon;
            break;
        case 'finished':
            IconElement = StatusFinishedIcon;
            break;
        case 'declined':
            IconElement = StatusDeclinedIcon;
            break;
        default:
            return <span />;
    }

    return <IconElement className={rootClasses} {...rest_of_props} />;
}

/**
 * Select field for Submission status with Icon and Text
 */
export default function SubmissionStatusField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'submissionstatusfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Submission().getStatuses().map((identifier, idx) =>
            <MenuItem value={identifier} key={idx}>
                <ListItemIcon className='icon'><StatusIcon identifier={identifier} /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id={`submissionstatusfield.choice.${identifier}`} /></ListItemText>
            </MenuItem>
        )}
    </Field>;
}
