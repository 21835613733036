import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUICard from '@material-ui/core/Card';


const useStyles = makeStyles(theme => ({
    cardRoot: {
        border: `1px solid ${theme.palette.grey[300]}`
    }
}));

/**
 * Extends Card component to change root style.
 */
const Card = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.cardRoot, root, className
    ].filter(Boolean).join(' ');

    return <MUICard
        ref={ref}
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props}
    />;
});

export default Card;
