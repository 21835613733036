import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDebounce} from 'use-debounce';
import {makeStyles} from '@material-ui/core/styles';
import {Endpoint, Ticket, TicketGroup, ProvisioningProfile} from 'lib/models';
import {theme} from 'theme';
import {formatLastUpdate} from 'lib/util';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
import DMActionButton from 'components/modules/tickets/dm/DMActionButton';
import DMBigDataTable from 'components/modules/tickets/dm/DMBigDataTable';
import DMMemory from 'components/modules/tickets/dm/DMMemory';
import DMUptime from 'components/modules/tickets/dm/DMUptime';
import DMFirmwareVersion from 'components/modules/tickets/dm/DMFirmwareVersion';
import DMPort from 'components/modules/tickets/dm/DMPort';
import DMSIPStatus from 'components/modules/tickets/dm/DMSIPStatus';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import DeviceManagementIcon from '@material-ui/icons/CloudDownloadOutlined';
import UpdateIcon from '@material-ui/icons/SyncOutlined';


const useStyles = makeStyles(theme => ({
    // last update in CardHeader
    cardHeaderInfo: {
        textAlign: 'right',
        '&.highlight': {
            color: theme.palette.primary.main
        }
    },
    // holder of quick actions in header
    cardHeadActions: {
        // move into CardHeader
        marginTop: `-${theme.spacing(3)}px`
    },
    // container
    grid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        // match grid
        gap: `${theme.spacing(2)}px`,
        // responsive
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr',
            '& > div:last-of-type': {
                  gridColumn: 'span 2'
            }
        },
        [theme.breakpoints.down('xs')]: {
            gap: `${theme.spacing(1)}px`,
            gridTemplateColumns: '1fr',
            '& > div:last-of-type': {
                gridColumn: '1'
            }
        }
    },
    // connect two cards together
    cardConnector: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        '& > div': {
            '&:first-of-type': {
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '0',
                borderBottom: '0'
            },
            '&:last-of-type': {
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0'
            }
        }
    },
    // buttons for provisioning profile ticket groups
    quickActionsHolder: {
        // align buttons next to each other
        display: 'flex', flexWrap: 'wrap',
        alignItems: 'center', justifyContent: 'flex-start',
        gap: `${theme.spacing(0.5)}px`,
        '&.right': {
            justifyContent: 'flex-end'
        },
        // not in TableFooter
        '&.standalone': {
            padding: `${theme.spacing(1)}px`,
            // style
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius
        }
    }
}));

/**
 * Latest data and quick actions
 */
export default function DeviceManagement(passed_props) {
    // Split some stuff from props
    const {item, ticket_group = false, permission} = passed_props;
    const classes = useStyles();
    // local state
    const [Model, placement, intl_base] = useMemo(() => {
        if (ticket_group) {
            return [TicketGroup, `${new TicketGroup().getPlacement()}-${item.get(new ProvisioningProfile().getUniqueIdentifier())}`, 'ticketgroups'];
        } else {
            return [Ticket, `${new Ticket().getPlacement()}-${item.get(new Endpoint().getUniqueIdentifier())}`, 'tickets'];
        }
    }, [ticket_group, !!item]);
    // get 'info' lastUpdate from UpTime
    const lastUpdate = useMemo(() => {
        return item?.getIn(['data', 'UpTime', 'updated_at']);
    }, [item?.getIn(['data', 'UpTime']) && JSON.stringify(item.getIn(['data', 'UpTime']))]);
    const [debouncedLastUpdate] = useDebounce(lastUpdate, theme.transitions.duration.standard * 2);

    return <React.Fragment>
        <Card>
            <CardHeader
                title={<FormattedMessage id={`${intl_base}.devicemanagement.title`} />}
                subheader={<FormattedMessage id={`${intl_base}.devicemanagement.subheader`} />}
                action={lastUpdate
                    ? <div className={`${classes.cardHeaderInfo} ${lastUpdate !== debouncedLastUpdate ? ' highlight' : ''}`}>
                        <strong><FormattedMessage id='devicemanagement.table.last_update' /></strong>
                        <div>{formatLastUpdate(lastUpdate) || '-'}</div>
                    </div>
                    : <ActionButton iconButton disabled>
                    <DeviceManagementIcon />
                </ActionButton>}
            />
            {(permission === 'RW' && Model === Ticket) && <CardActions className={classes.cardHeadActions}>
                <DMActionButton
                    action='get_common' Model={Model} placement={placement} item={item}
                    label={<FormattedMessage id={`${intl_base}.devicemanagement.update_info`} />}
                    icon={<UpdateIcon />}
                />
            </CardActions>}
            {Model === TicketGroup && <CardContent>
                <div className={`${classes.quickActionsHolder} standalone`}>
                    {new Ticket().getTypes().map(action => <DMActionButton
                        key={action} action={action} disabled={permission !== 'RW'}
                        Model={Model} placement={placement} item={item}
                    />)}
                </div>
            </CardContent>}
        </Card>
        {Model === Ticket && <React.Fragment>
            <SpaceDivider grid />
            <div className={classes.grid}>
                <div className={classes.cardConnector}>
                    <DMUptime item={item} />
                    <DMFirmwareVersion item={item} />
                </div>
                <DMPort item={item} />
                <div className={classes.cardConnector}>
                    <DMSIPStatus item={item} />
                    <DMMemory item={item} />
                </div>
            </div>
            <SpaceDivider grid />
            <DMBigDataTable
                permission={permission}
                item={item} Model={Model} placement={placement}
                quickActionsHolderClass={classes.quickActionsHolder}
            />
        </React.Fragment>}
    </React.Fragment>;
}
