import React, {useEffect, useState, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useFilter, usePaginatedItems} from 'lib/filters';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment';
import {Ticket, TicketGroup, Endpoint, ProvisioningProfile, Paginator} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Add from 'components/modules/tickets/Add';
import Detail from 'components/modules/tickets/Detail';
import SchedulersList from 'components/modules/ticketschedulers/List';
import {StatusIcon} from 'components/core/ui/fields/TicketStatusField';
import {TypeIcon} from 'components/core/ui/fields/TicketTypeField';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import StickyTable from 'components/core/ui/StickyTable';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
// material-ui
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Dialog from '@material-ui/core/Dialog';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


const useStyles = makeStyles(theme => ({
    // expansion panel with ticket schedulers
    schedulerExpansion: {
        // highlight
        background: theme.palette.snomGrey[100],
        // smooth transition (shortest & margin is default)
        transition: theme.transitions.create(['background', 'margin'],
            {duration: theme.transitions.duration.shortest}),
        // open style
        '&.expanded': {
            // revert to white
            background: theme.palette.common.white
        }
    },
    // title with icon
    schedulerExpansionTitle: {
        // align icon and text
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // icon
        '& svg': {
            // space between
            marginRight: `${theme.spacing(1)}px`
        }
    },
    // holder of content
    schedulerExpansionDetail: {
        // to sides
        padding: '0'
    },
    // cell with the tooltip and latest date the ticket (created / updated / finished)
    lastUpdatedCell: {
        // variants
        '&.finished_at strong': {
            color: theme.palette.success[500]
        },
        '&.updated_at strong': {
            color: theme.palette.primary[500]
        },
        '&.expires_at strong': {
            color: theme.palette.danger[500]
        }
    }
}));

/**
 * Endpoint Tickets or Provisioning Profile Ticket Groups from Device Management
 */
export default function List(passed_props) {
    // split stuff from props
    const {permission, item, ticket_group = false} = passed_props;
    const classes = useStyles();
    const [Model, intl_base] = useMemo(() => {
        return ticket_group ? [TicketGroup, 'ticketgroups'] : [Ticket, 'tickets'];
    }, [ticket_group]);
    // redux store
    const dispatch = useDispatch();
    const {items_placement, ...props} = useSelector(state => {
        const items_placement = `${new Model().getPlacement()}-${ticket_group
            ? item.get(new ProvisioningProfile().getUniqueIdentifier())
            : item.get(new Endpoint().getUniqueIdentifier())}`;

        return {
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList()
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = useMemo(() => [
        {sortName: 'type', label: <FormattedMessage id={`${intl_base}.list.table.type`} />},
        {sortName: 'status', label: <FormattedMessage id={`${intl_base}.list.table.status`} />},
        ...(ticket_group ? [{sortName: 'no_tickets', label: <FormattedMessage id='ticketgroups.list.table.no_tickets' />}] : []),
        {label: <FormattedMessage id={`${intl_base}.list.table.last_update`} />}
    ], [ticket_group, intl_base]);
    // local state
    const [detailDialogItem, setDetailDialogItem] = useState(null);
    const [expanded, setExpanded] = useState(0);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new Model().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = ['created_at', 'asc'];
    const [, filters, filtersInitializing] = useFilter(
        false, items_placement, [], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(Model, items_placement, item.getIn(['links', ticket_group ? 'ticket_groups' : 'tickets']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters)]);
    // silent auto re-fetch with outdated
    useEffect(() => {
        if (props.outdated && props.paginator.get('page') === 1) {
            dispatch(fetchItems(Model, items_placement, item.getIn(['links', ticket_group ? 'ticket_groups' : 'tickets']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1, affect_state: false}));
        }
    }, [props.outdated, props.paginator.get('page')]);

    return <React.Fragment>
        <CardFirst>
            <CardHeader
                title={<FormattedMessage id={`${intl_base}.list.title`} />}
                subheader={<FormattedMessage id={`${intl_base}.list.subheader`} />}
                action={permission === 'RW'
                    ? <ActionButton variant='contained' color='secondary'
                                    onClick={() => setDetailDialogItem('add')}>
                        <AddIcon />
                        <FormattedMessage id='actions.create' />
                    </ActionButton>
                    : <ActionButton iconButton disabled>
                        <ModelIcon model={ticket_group ? 'ticket_groups' : 'tickets'} />
                    </ActionButton>}
            />
        </CardFirst>
        <ExpansionPanel expanded={expanded === 1} onChange={() => setExpanded(expanded === 1 ? 0 : 1)}
                        title={<span className={classes.schedulerExpansionTitle}>
                            <ModelIcon model='ticket_schedulers' />
                            <FormattedMessage id='ticketschedulers.list.title' />
                        </span>}
                        _classes={{root: classes.schedulerExpansion, detail: classes.schedulerExpansionDetail}}>
            <SchedulersList permission={permission} item={item} ticket_group={ticket_group} />
        </ExpansionPanel>
        <CardLast>
            <StickyTable
                header={<Table size={tableHead.length}>
                    <TableHead>
                        <TableRow>
                            {tableHead.map((item, idx) =>
                                <TableCell key={idx}>
                                    {sortName === item.sortName
                                        ? <TableSortLabel active direction={sortDirection}
                                                          disabled={true}>
                                            {item.label}
                                        </TableSortLabel>
                                        : item.label
                                    }
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                </Table>}
                content={<Table size={tableHead.length}>
                    {[`fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                        : !paginatedItems.size
                        ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}>
                                <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : `${intl_base}.list.table.empty`} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                        : <TableBody>
                        {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((item, idx) => {
                            const last_update = item.get('finished_at') ? 'finished_at'
                                : item.get('expires_at') && item.get('status') === 'expired' ? 'expires_at'
                                : item.get('updated_at') ? 'updated_at'
                                : item.get('created_at') ? 'created_at'
                                : null;

                            return <TableRow
                                key={idx} link hover
                                onClick={props.state !== null ? () => {} : () => {
                                    setDetailDialogItem(item);
                                }}>
                                <TableCell name>
                                    <TypeIcon identifier={item.get('type')} className='icon' />
                                    <span className='text'><FormattedMessage id={`tickettypefield.choices.${item.get('type')}`} /></span>
                                </TableCell>
                                <TableCell name>
                                    <StatusIcon identifier={item.get('status')} className='icon' />
                                    <span className='text'><FormattedMessage id={`ticketstatusfield.choices.${item.get('status')}`} /></span>
                                </TableCell>
                                {ticket_group && <TableCell>
                                    {item.get('no_tickets') || '0'}
                                </TableCell>}
                                <TableCell className={`${classes.lastUpdatedCell}${last_update ? ` ${last_update}` : ''}`}>
                                    {last_update && <React.Fragment>
                                        <strong><FormattedMessage id={`tickets.list.table.last_update.${last_update}`} />{': '}</strong>
                                        {`${Moment(item.get(last_update)).fromNow()} [${Moment(item.get(last_update)).format('l, LT')}]`}
                                    </React.Fragment> }
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>}
                </Table>}
            footer={<Table size={1}>
                <Pagination model={Model} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
        </CardLast>
        <Dialog open={!!detailDialogItem} onClose={() => setDetailDialogItem(null)}
                fullWidth maxWidth='md'>
            {detailDialogItem && detailDialogItem !== 'add'
                ? <Detail item={detailDialogItem} ticket_group={ticket_group} />
                : <Add related_item={item} ticket_group={ticket_group}
                       close={() => setDetailDialogItem(null)} />}
        </Dialog>
    </React.Fragment>;
}
