import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues, reset as reduxFormReset} from 'redux-form';
// Actions
import {setState} from 'actions/app';
import {uploadFiles} from 'actions/shared';
// Components
import Form from 'components/core/ui/Form';
import {DropFile} from 'components/core/ui/Field';
// material-ui
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
// icons
import ImageIcon from '@material-ui/icons/ImageOutlined';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    contentHolder: {
        padding: `${theme.spacing(2)}px`
    }
}));

/**
 * Button with dialog to upload image for builder image fields
 */
function ImageUpload(props) {
    // style
    const classes = useStyles();
    // local state
    const [open, setOpen] = useState(false); // open/close status of Delete dialog

    /**
     * Make sure to clear state and close dialog after submit
     */
    useEffect(() => {
        if (['uploaded_images', 'failed_upload_images'].includes(props.state)) {
            setOpen(false);
            props.reduxFormReset('imageUploadForm');
            props.setState(null);
        }
    }, [props.state]);

    return <React.Fragment>
        <IconButton color='primary' onClick={() => setOpen(!open)}>
            <ImageIcon />
        </IconButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <div className={classes.contentHolder}>
                <Form onSubmit={props.handleSubmit}>
                    <DropFile label='Image'
                              loading={props.state === 'uploading_images'}
                              initialFile={props.initialValues ? {id: props.initialValues.image, url: props.initialValues.image_url} : {}}
                              selectedFile={props.formValues ? {id: props.formValues.image, url: props.formValues.image_url} : {}}
                              fieldChange={(id, url) => {
                                  props.change('image', id);
                                  props.change('image_url', url);
                                  props.change('image_to_upload', null);
                              }}
                              onChange={(file) => {
                                  props.change('image', file);
                                  props.change('image_url', null);
                                  props.change('image_to_upload', file);
                                  setTimeout(() => props.handleSubmit(), 0);
                              }}
                              imageOnly />
                </Form>
            </div>
        </Dialog>
    </React.Fragment>;
}


const ImageUploadForm = reduxForm({
    form: 'imageUploadForm',
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        const {image_to_upload} = props.formValues;

        if (!image_to_upload) {
            return;
        }

        return dispatch(uploadFiles('images', 'page-images', [image_to_upload])).then((results) => {
            props.setImage(results[0].data.file_url);
        });
    }
})(ImageUpload);

const ConnectedImageUpload = connect((state) => ({
    state: state.app.get('state'),
    initialValues: {},
    formValues: getFormValues('imageUploadForm')(state)
}), (dispatch) => bindActionCreators({
    setState,
    uploadFiles,
    reduxFormReset
}, dispatch))(ImageUploadForm);

export default ConnectedImageUpload;
