import React, {useMemo, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, FieldArray,
    SubmissionError, getFormSyncErrors} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {SubmissionProduct, Distributor, PBXPartner, Project, Table as ModelTable} from 'lib/models';
import {useLocalSort} from 'lib/filters';
// Actions
import {addMessage, setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem} from 'actions/shared';
// Components
import ErrorMessage from 'components/core/ui/ErrorMessage';
import ThemeProvider from 'components/ThemeProvider';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {InFormContent, CountryField, SubmissionProductVariantField,
    CompanyPartnerLevelField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Tooltip from 'components/core/ui/mui/Tooltip';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SubmitIcon from '@material-ui/icons/SendOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


const useStyles = makeStyles(theme => ({
    // CardContent with conditions text
    conditions: {
        // highlight
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.grey[100]
    },
    fieldArray: {
        // products next to each other
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    fieldArrayNewRowHolder: {
        // 'X' size (we are on new row)
        marginLeft: `${48 + theme.spacing(2)}px`
    }
}));

/**
 * Renders N*SubmissionProductVariants+extra fields in FieldArray
 */
function RenderProductsFieldArray(props) {
    // style
    const classes = useStyles();

    return <div className={classes.fieldArray}>
        {props.fields.map((field, idx) => {
            const product = props.formValues?.products?.[idx];

            return <div key={idx}>
                <Tooltip title={<FormattedMessage id='actions.remove' />}>
                    <InFormContent inline>
                        <IconButton color='primary' disabled={props.disabled} onClick={() => props.fields.remove(idx)}>
                            <DeleteIcon />
                        </IconButton>
                    </InFormContent>
                </Tooltip>
                <Field disabled={props.disabled} name={`${field}.quantity`} fieldType='TextField' type='number' size='half'
                       label={`${props.intl.formatMessage({id: 'projects.detail.form.fields.products.quantity'})}*`} />
                <SpaceDivider none />
                <div className={classes.fieldArrayNewRowHolder}>
                    <Field disabled={props.disabled} name={`${field}.comment`} fieldType='TextArea' size='regular' label={<FormattedMessage id='projects.detail.form.fields.products.comment' />} />
                    <SpaceDivider />
                    <SubmissionProductVariantField
                        disabled={props.disabled}
                        name={`${field}.product`}
                        label={`${props.intl.formatMessage({id: 'projects.detail.form.fields.products.product'})}*`}
                        change={props.change}
                        selectedValue={product?.product}
                        disabledVariants={props.formValues?.products?.map(el => el?.product).filter(el => el) || []}
                        preOpen={props.preOpen}
                        doNotFetchSubmissionProducts
                    />
                    <SpaceDivider />
                </div>
            </div>;})}
        {!props.disabled && <InFormContent>
            <Button variant='outlined' color='primary'
                    onClick={() => props.fields.push(props.initialProductValues)}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </Button>
            <SpaceDivider />
        </InFormContent>}
    </div>;
}

/**
 * Renders detail of Project - ADD & View detail
 */
function Detail(props) {
    const edit = !!props.match.params.identifier;
    // style
    const classes = useStyles();
    // sorting
    const [locallySortedDistributors] = useLocalSort(props.distributors_items, !props.distributors_loaded);
    const sortedDistributors = useMemo(() => {
        return locallySortedDistributors.filter(distributor => distributor.get('active'));
    }, [locallySortedDistributors]);
    const [locallySortedPBXPartners] = useLocalSort(props.pbx_partners_items, !props.pbx_partners_loaded);
    const sortedPBXPartners = useMemo(() => {
        return locallySortedPBXPartners.filter(pbx_partner => pbx_partner.get('active'));
    }, [locallySortedPBXPartners]);

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItem(Project, props.items_placement, [props.company.getIn(['links', 'projects']), props.match.params.identifier], {company: props.company});
        }
    }, [props.match.params.identifier]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.submission_products_loaded === false) {
            props.fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false});
        }
    }, [props.submission_products_loaded]);
    useEffect(() => {
        if (props.distributors_loaded === false) {
            props.fetchItems(Distributor, 'distributors', 'distributors', null, null, {affect_state: false});
        }
    }, [props.distributors_loaded]);
    useEffect(() => {
        if (props.pbx_partners_loaded === false) {
            props.fetchItems(PBXPartner, 'pbx-partners', 'pbx-partners', null, null, {affect_state: false});
        }
    }, [props.pbx_partners_loaded]);
    useEffect(() => {
        if (!props.project_competitors) {
            props.fetchItem(ModelTable, 'tables', 'project-competitors', {expand_item: new ModelTable({uuid: 'project_competitors'}), expand_item_data: true, affect_state: false});
        }
    }, [props.project_competitors]);
    useEffect(() => {
        if (!props.project_stages) {
            props.fetchItem(ModelTable, 'tables', 'project-stages', {expand_item: new ModelTable({uuid: 'project_stages'}), expand_item_data: true, affect_state: false});
        }
    }, [props.project_stages]);

    return <div>
        {props.state === `fetching_item_${props.items_placement}`
            ? <Card>
                <CardHeader title={<FormattedMessage id={`projects.detail.${edit ? 'edit' : 'add'}.title`} />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='product_group' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
                ? <ErrorCard
                    title={<FormattedMessage id='projects.detail.notfound.title' />}
                    text={<FormattedMessage id='projects.detail.notfound.text' />}
                    icon={<WarningIcon color='secondary' />} />
                : props.formValues && <Card>
                <CardHeader title={<FormattedMessage id={`projects.detail.${edit ? 'edit' : 'add'}.title`} />}
                            subheader={<FormattedMessage id='projects.detail.subheader' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='project' />
                            </ActionButton>} />
                <CardContent>
                    <Row wrap>
                        <Col width='50%'>
                            <Typography variant='body1'><FormattedMessage id='projects.detail.reseller.title' /></Typography>
                            <Form onSubmit={!props.disabled && props.handleSubmit} >
                                <Field disabled={props.disabled} name='reseller_company_contact_person' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.reseller.form.fields.contact_person'})}*`} />
                                <Field disabled={props.disabled} name='reseller_job_title' fieldType='TextField' label={<FormattedMessage id='projects.detail.reseller.form.fields.job_title' />} />
                                <SpaceDivider none />
                                <Field disabled name='reseller_company_name' fieldType='TextField' label={<FormattedMessage id='projects.detail.reseller.form.fields.company_name' />} />
                                <CompanyPartnerLevelField
                                    disabled fieldType='NoReduxSelect' value={props.company.get('partner_level')}
                                    highlight hideDisabledArrow />
                                <SpaceDivider />
                                <Field disabled={props.disabled} name='reseller_company_email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.reseller.form.fields.email'})}*`} />
                                <Field disabled={props.disabled} name='reseller_company_phone' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.reseller.form.fields.phone'})}*`} />
                                <SpaceDivider />
                                <CountryField disabled name='reseller_company_country' label={`${props.intl.formatMessage({id: 'projects.detail.reseller.form.fields.country'})}*`} />
                                <Field disabled name='reseller_company_city' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.reseller.form.fields.city'})}*`} />
                                <SpaceDivider />
                            </Form>
                        </Col>
                        <Col width='50%'>
                            <Typography variant='body1'><FormattedMessage id='projects.detail.enduser.title' /></Typography>
                            <Form onSubmit={props.handleSubmit}>
                                <Field disabled={props.disabled} name='enduser_company_contact_person' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.contact_person'})}*`} />
                                <Field disabled={props.disabled} name='enduser_job_title' fieldType='TextField' label={<FormattedMessage id='projects.detail.enduser.form.fields.job_title' />} />
                                <SpaceDivider none />
                                <Field disabled={props.disabled} name='enduser_company_name' fieldType='TextField' label={<FormattedMessage id='projects.detail.enduser.form.fields.company_name' />} />
                                <Field disabled={props.disabled} name='enduser_company_vat' fieldType='TextField' label={<FormattedMessage id='projects.detail.enduser.form.fields.vat' />}
                                       helperText={<FormattedMessage id='projects.detail.enduser.form.fields.vat.help' />} />
                                <SpaceDivider none />
                                <Field disabled={props.disabled} name='enduser_company_email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.email'})}*`} />
                                <Field disabled={props.disabled} name='enduser_company_phone' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.phone'})}*`} />
                                <SpaceDivider />
                                <Field disabled={props.disabled} name='enduser_company_website' fieldType='TextField' label={<FormattedMessage id='projects.detail.enduser.form.fields.website' />} />
                                <SpaceDivider />
                                <CountryField disabled={props.disabled} name='enduser_company_country' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.country'})}*`} />
                                <Field disabled={props.disabled} name='enduser_company_city' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.city'})}*`} />
                                <SpaceDivider none />
                                <Field disabled={props.disabled} name='enduser_company_street' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.street'})}*`} />
                                <Field disabled={props.disabled} name='enduser_company_postal_code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'projects.detail.enduser.form.fields.postal_code'})}*`} />
                            </Form>
                        </Col>
                    </Row>
                </CardContent>
                <hr />
                <CardContent>
                    <Typography variant='body1'><FormattedMessage id='projects.detail.additional.title' /></Typography>
                    <Row>
                        <Col width='50%'>
                            <Form onSubmit={props.handleSubmit}>
                                <Field disabled={props.disabled} name='project_name' fieldType='TextField' label={<FormattedMessage id='projects.detail.additional.form.fields.project_name' />} />
                                <Field disabled={props.disabled} name='order_date' fieldType='DateField' label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.order_date'})}*`}
                                       time={false} />
                                <SpaceDivider none />
                                <Field disabled={props.disabled} name='project_type' fieldType='Select' label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.project_type'})}*`}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    <MenuItem value='Customer project'><FormattedMessage id='projects.detail.additional.form.fields.project_type.choices.customer' /></MenuItem>
                                    <MenuItem value='Tender'><FormattedMessage id='projects.detail.additional.form.fields.project_type.choices.tender' /></MenuItem>
                                    <MenuItem value='Own use'><FormattedMessage id='projects.detail.additional.form.fields.project_type.choices.own' /></MenuItem>
                                </Field>
                                <Field disabled={!props.project_stages || props.disabled} name='project_stage' fieldType={edit ? 'TextField' : 'Select'}
                                       label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.project_stage'})}*`}>
                                    {!edit && [
                                        <MenuItem key='' value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>,
                                        props.project_stages && props.project_stages.get('data').valueSeq().map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)
                                    ]}
                                </Field>
                                <SpaceDivider none />
                                <Field disabled={props.disabled} name='project_start_date' fieldType='DateField' label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.project_start_date'})}*`}
                                       time={false} />
                                <Field disabled={props.disabled} name='project_end_date' fieldType='DateField' label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.project_end_date'})}*`}
                                       time={false} />
                                <SpaceDivider />
                                <Field disabled={props.disabled} name='snom_assistance' fieldType='Select' multiple size='double'
                                       label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.snom_assistance'})}*`}>
                                    <MenuItem value='special_pricing'>{props.intl.formatMessage({id: 'projects.detail.additional.form.fields.snom_assistance.choices.special_pricing'})}</MenuItem>
                                    <MenuItem value='test_products'>{props.intl.formatMessage({id: 'projects.detail.additional.form.fields.snom_assistance.choices.test_products'})}</MenuItem>
                                    <MenuItem value='other'>{props.intl.formatMessage({id: 'projects.detail.additional.form.fields.snom_assistance.choices.other'})}</MenuItem>
                                </Field>
                                <Field disabled={props.disabled} name='comment' fieldType='TextArea' label={<FormattedMessage id='projects.detail.additional.form.fields.comment' />} />
                            </Form>
                        </Col>
                        <Col width='50%'>
                            <Form onSubmit={props.handleSubmit}>
                                <Field disabled={props.disabled} name='number_of_installments' fieldType='TextField' type='number'
                                       label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.number_of_installments'})}*`} />
                                <Field name='distributor' fieldType='Select' label={`${props.intl.formatMessage({id: 'projects.detail.additional.form.fields.distributor'})}*`}
                                       disabled={!props.distributors_loaded || props.disabled} loading={!props.distributors_loaded}>
                                    <MenuItem fake value={props.formValues && props.formValues.distributor} />
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    {sortedDistributors.map((distributor, idx) =>
                                        <MenuItem key={idx} value={distributor.get(new Distributor().getUniqueIdentifier())}>{distributor.get('name')}</MenuItem>
                                    )}
                                </Field>
                                <SpaceDivider none />
                                <Field name='pbx_partner' fieldType='Select' label={<FormattedMessage id='projects.detail.additional.form.fields.pbx_partner' />}
                                       disabled={!props.pbx_partners_loaded || props.disabled} loading={!props.pbx_partners_loaded}>
                                    <MenuItem fake value={props.formValues && props.formValues.pbx_partner} />
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    {sortedPBXPartners.map((pbx_partner, idx) =>
                                        <MenuItem key={idx} value={pbx_partner.get(new PBXPartner().getUniqueIdentifier())}>{pbx_partner.get('name')}</MenuItem>
                                    )}
                                </Field>
                                <Field disabled={!props.project_competitors || props.disabled} name='competitor' fieldType='Select'
                                       label={<FormattedMessage id='projects.detail.additional.form.fields.competitor' />}>
                                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                    {props.project_competitors && props.project_competitors.get('data').valueSeq().map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                                </Field>
                            </Form>
                        </Col>
                    </Row>
                </CardContent>
                <hr />
                <CardContent>
                    <Typography variant='body1'><FormattedMessage id='projects.detail.form.fields.products' /></Typography>
                    <SpaceDivider />
                    {(props.submitFailed && props.error && ['products_required'].includes(props.error)) && <React.Fragment>
                        <ErrorMessage>
                            <FormattedMessage id='projects.detail.form.fields.products.required' />
                        </ErrorMessage>
                        <SpaceDivider />
                    </React.Fragment>}
                    <Form onSubmit={props.handleSubmit}>
                        <FieldArray name='products' component={RenderProductsFieldArray} props={{...props, preOpen: !edit}} />
                    </Form>
                </CardContent>
                <CardContent className={classes.conditions}>
                    <Typography variant='h6'><FormattedMessage id='projects.detail.conditions.title' /></Typography>
                    <p><FormattedMessage id='projects.detail.conditions.text.1' /></p>
                    <ul>
                        <li><FormattedMessage id='projects.detail.conditions.text.1.1' /></li>
                        <li><FormattedMessage id='projects.detail.conditions.text.1.2' /></li>
                        <li><FormattedMessage id='projects.detail.conditions.text.1.3' /></li>
                        <li><FormattedMessage id='projects.detail.conditions.text.1.4' /></li>
                    </ul>
                    <p><FormattedMessage id='projects.detail.conditions.text.2' /></p>
                    <p><FormattedMessage id='projects.detail.conditions.text.3' /></p>
                    <p>
                        <FormattedMessage id='projects.detail.conditions.text.4' /><br />
                        <em><FormattedMessage id='projects.detail.conditions.signature' /></em>
                    </p>
                    <p className='nobmargin'><small><FormattedMessage id='projects.detail.conditions.star.1' /></small></p>
                </CardContent>
                <SpaceDivider />
                {[`saving_item_${props.items_placement}`, `saved_item_${props.items_placement}`, `added_item_${props.items_placement}`, `failed_save_item_${props.items_placement}`].includes(props.state)
                    ? <CardActionsLoader success={[`saved_item_${props.items_placement}`, `added_item_${props.items_placement}`].includes(props.state)}
                                         failure={props.state === `failed_save_item_${props.items_placement}`}
                                         postAnimation={success => {
                                             // in case of add
                                             if (success && props.state === `added_item_${props.items_placement}`) {
                                                 props.setState(null);
                                                 // success message
                                                 props.addMessage({intl_id: 'projects.success', path: '/partner/projects'});
                                                 // redirect to list
                                                 props.history.push('/partner/projects');
                                             } else {
                                                 props.setState(null);
                                             }
                                         }} />
                    : !props.disabled && <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/partner/projects')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                onClick={() => props.state === null ? props.handleSubmit() : {}}>
                            <SubmitIcon />
                            <FormattedMessage id='projects.detail.form.submit' />
                        </Button>
                    </CardActions>
                </ThemeProvider>}
            </Card>}
    </div>;
}

let validate = (data, props) => {
    const errors = {products: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    // reseller
    validator.isNotNull(null, errors, 'reseller_company_contact_person', data.reseller_company_contact_person);
    validator.isNotNull(null, errors, 'reseller_company_email', data.reseller_company_email);
    validator.isNotNull(null, errors, 'reseller_company_phone', data.reseller_company_phone);
    validator.isNotNull(null, errors, 'reseller_company_country', data.reseller_company_country);
    validator.isNotNull(null, errors, 'reseller_company_city', data.reseller_company_city);

    // end user
    validator.isNotNull(null, errors, 'enduser_company_contact_person', data.enduser_company_contact_person);
    validator.isNotNull(null, errors, 'enduser_company_phone', data.enduser_company_phone);
    validator.isNotNull(null, errors, 'enduser_company_email', data.enduser_company_email);
    validator.isNotNull(null, errors, 'enduser_company_country', data.enduser_company_country);
    validator.isNotNull(null, errors, 'enduser_company_city', data.enduser_company_city);
    validator.isNotNull(null, errors, 'enduser_company_street', data.enduser_company_street);
    validator.isNotNull(null, errors, 'enduser_company_postal_code', data.enduser_company_postal_code);

    // additional
    validator.isNotNull(null, errors, 'project_type', data.project_type);
    validator.isNotNull(null, errors, 'project_stage', data.project_stage);
    validator.isNotNull(null, errors, 'project_start_date', data.project_start_date);
    if (data.project_start_date) {
        validator.isDateTime(null, errors, 'project_start_date', data.project_start_date, null);
    }
    validator.isNotNull(null, errors, 'project_end_date', data.project_end_date);
    if (data.project_end_date) {
        validator.isDateTime(null, errors, 'project_end_date', data.project_end_date, null);
    }
    validator.isNotNull(null, errors, 'distributor', data.distributor);
    validator.isNotNull(null, errors, 'order_date', data.order_date);
    if (data.order_date) {
        validator.isDateTime(null, errors, 'order_date', data.order_date, null);
    }
    validator.isNotNull(null, errors, 'number_of_installments', data.number_of_installments);
    if (data.number_of_installments) {
        validator.isInt(null, errors, 'number_of_installments', data.number_of_installments, {allow_leading_zeroes: false, min: 0});
    }
    validator.isNotNull(null, errors, 'snom_assistance', data.snom_assistance);

    // validate products fieldArray
    if (data.products && data.products.length) {
        data.products.forEach((el, idx) => {
            // prepare error array
            if (!errors.products[idx]) {
                errors.products[idx] = {};
            }

            // validation
            validator.isNotNull(null, errors, `products.${idx}.product`, data.products[idx] && data.products[idx].product);
            validator.isNotNull(null, errors, `products.${idx}.quantity`, data.products[idx] && data.products[idx].quantity);
            if (data.products[idx] && data.products[idx].quantity) {
                validator.isInt(null, errors, `products.${idx}.quantity`, data.products[idx].quantity, {allow_leading_zeroes: false, min: 0});
            }
        });
    } else {
        errors['products'] = props.intl.formatMessage({id: 'errors.validator.required'});
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'projectForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation
    onSubmit: (values, dispatch, props) => {
        // extra care for fieldArrays, regular isNotNull validation does not work since field does not exists
        if (props.formErrors.products.length) {
            throw new SubmissionError({_error: 'products_required'});
        }

        // pop-up some values which we don't want to send
        let rest_of_data = new Project().popUpFields(props.formValues);
        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));

        // remove timezone from DateFields
        rest_of_data['order_date'] = Moment(rest_of_data['order_date']).format('YYYY-MM-DD');
        if (rest_of_data['project_start_date']) {
            rest_of_data['project_start_date'] = Moment(rest_of_data['project_start_date']).format('YYYY-MM-DD');
        }
        if (rest_of_data['project_end_date']) {
            rest_of_data['project_end_date'] = Moment(rest_of_data['project_end_date']).format('YYYY-MM-DD');
        }
        // process snom_assistance field
        rest_of_data['snom_assistance'] = rest_of_data['snom_assistance'].join(', ').replace(/_/g, ' ');

        return dispatch(saveItem(
            Project,
            props.items_placement,
            props.item ? props.item.getIn(['links', 'self']) : props.company.getIn(['links', 'projects']),
            rest_of_data, props.item, {add_mark_filtered: false, different_add_state: true}));
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const auth_user = state.auth.get('user');
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));

    const items_placement = new Project().getPlacement();
    const item = state.shared.getIn(['items', items_placement]).find(el => el.get(new Project().getUniqueIdentifier()) === props.match.params.identifier);
    const itemObject = item?.toJS();

    return {
        state: state.app.get('state'),
        item: item,
        items_placement: items_placement,
        disabled: !!item,
        initialValues: props.match.params.identifier
            ? item
                ? {
                    ...itemObject,
                    // process dates
                    order_date: Moment(itemObject.order_date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    project_start_date: Moment(itemObject.project_start_date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    project_end_date: Moment(itemObject.project_end_date).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    // process snom_assistance field
                    snom_assistance: itemObject.snom_assistance.split(', ')
                }
                : undefined
            : {
                reseller_company_contact_person: `${auth_user.get('first_name')} ${auth_user.get('last_name')}`,
                reseller_job_title: auth_user.get('department') || auth_user.get('job_title'),
                reseller_company_name: company.get('name'),
                reseller_company_country: company.get('country'),
                reseller_company_city: company.get('city'),
                reseller_company_email: company.get('email') || auth_user.get('email'),
                reseller_company_phone: company.get('phone_number'),
                order_date: Moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                products: [],
                snom_assistance: []
            },
        initialProductValues: {quantity: 1},
        submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
        distributors_loaded: state.shared.getIn(['loaded', 'distributors']),
        distributors_items: state.shared.getIn(['items', 'distributors']),
        pbx_partners_loaded: state.shared.getIn(['loaded', 'pbx-partners']),
        pbx_partners_items: state.shared.getIn(['items', 'pbx-partners']),
        project_competitors: state.shared.getIn(['items', 'tables']).find(el => el.get(new ModelTable().getUniqueIdentifier()) === 'project_competitors'),
        project_stages: state.shared.getIn(['items', 'tables']).find(el => el.get(new ModelTable().getUniqueIdentifier()) === 'project_stages'),
        company: company,
        user: auth_user,
        formValues: getFormValues('projectForm')(state),
        formErrors: getFormSyncErrors('projectForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    fetchItem,
    addMessage,
    saveItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
