import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {Company, Paginator} from 'lib/models';
import {List as ImmutableList} from 'immutable';
import {useFilter, usePaginatedItems} from 'lib/filters';
// Actions
import {fetchItems, fetchItem, updatePaginator} from 'actions/shared';
// Components
import RequestCSV from 'components/core/ui/RequestCSV';
import Resources from 'components/modules/companies/Resources';
import Filters from 'components/core/ui/Filters';
import {SearchField, CompanyStatusField, CompanySourcesField, CountryField,
    SalesClustersField, CompanyPartnerLevelField, CompanyField} from 'components/core/ui/Field';
import {ApprovalStatusIcon} from 'components/core/ui/fields/CompanyStatusField';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import {Link} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Companies from backend - to view and manage them
 * With Local Pagination
 */
export default function List(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='companies.list.table.name' />},
        {sortName: 'created_at', label: <FormattedMessage id='companies.list.table.created_at' />},
        {sortName: 'company_type', label: <FormattedMessage id='companies.list.table.company_type' />},
        {indicator: 'resources', label: <FormattedMessage id='companies.list.table.resources' />},
        ...(pathname.startsWith('/companies') ? [{sortName: 'partner_level', label: <FormattedMessage id='companies.list.portal.table.partner_level' />}] : []),
        ...(pathname.startsWith('/companies') ? [{sortName: 'status', label: <FormattedMessage id='companies.list.table.status' />, numeric: true}] : [])
    ];
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const company_identifier = pathname.startsWith('/companies')
            ? searchParams.get('company')
                // ensure we don't create duplicate 'my' collection with UUID
                ? searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                    ? 'my'
                    : searchParams.get('company')
                : 'all'
            : 'my';
        const items_placement = company_identifier === 'all' ? 'companies'
            : company_identifier === 'my' ? 'my-companies'
                : `my-companies-${company_identifier}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            user: auth_user,
            company: company,
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined
                : state.shared.getIn(['items', 'companies']).find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company'))
        };
    }, shallowEqual);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new Company().getUniqueIdentifier());
    // sorting & filtering (API)
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, pathname.startsWith('/companies') ? 'companies' : 'my-companies',
        ['search', ...(pathname.startsWith('/companies') ? ['company'] : []),
            ...(company_identifier === 'all' ? ['status', 'postal_code', 'signup_source', 'country', 'sales_cluster', 'partner_level'] : [])
        ],
        props.state !== null);
    const [sortName, sortDirection] = postponedFilters.search || filters.search
        ? [false, false] : ['created_at', 'asc'];

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length) && (company_identifier === 'all' || props.selectedCompany)) {
            dispatch(fetchItems(Company, items_placement,
                company_identifier === 'all' ? 'companies' : props.selectedCompany.getIn(['links', 'child-companies']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), props.selectedCompany]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, 'companies', ['companies', company_identifier], {success_affect_state: false}));
        }
    }, [props.selectedCompany, company_identifier]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/my-companies')
                ? 'companies.list.my_companies.title'
                : 'companies.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/my-companies')
                ? 'companies.list.my_companies.subheader'
                : 'companies.list.subheader'} />}
            action={<React.Fragment>
                <RequestCSV url={company_identifier === 'all' ? 'companies-export' : props.selectedCompany && props.selectedCompany.getIn(['links', 'child-companies-export'])}
                            placement={items_placement} />
                {passed_props.permission === 'RW' && !pathname.startsWith('/companies') && <ActionButton
                    variant='contained' color='secondary' disabled={props.state !== null}
                    onClick={() => history.push('/my-companies/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>}
            </React.Fragment>}
        />
        <Filters disabled={props.state !== null}>
            {pathname.startsWith('/companies') && <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
            />}
            <SearchField label={<FormattedMessage id='companies.list.search' />}
                         value={postponedFilters.search || filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            {company_identifier === 'all' && <React.Fragment>
                <CompanyStatusField fieldType='NoReduxSelect' value={postponedFilters.status || filters.status || ''}
                                    onChange={event => filterItems('status', event.target.value)} />
                <SearchField label={<FormattedMessage id='companies.list.filter.postal_code' />}
                             value={postponedFilters.postal_code || filters.postal_code || ''}
                             search={(search) => filterItems('postal_code', search)} />
                <SpaceDivider none />
                <CompanySourcesField fieldType='NoReduxSelect'
                                     multiple={false} value={postponedFilters.signup_source || filters.signup_source || ''}
                                     onChange={event => filterItems('signup_source', event.target.value)} />
                <CountryField label={<FormattedMessage id='companies.list.filter.country' />}
                              fieldType='NoReduxSelect' value={postponedFilters.country || filters.country || ''}
                              onChange={event => filterItems('country', event.target.value)} />
                <SalesClustersField fieldType='NoReduxSelect' value={postponedFilters.sales_cluster || filters.sales_cluster || ''}
                                    onChange={(event) => filterItems('sales_cluster', event.target.value)} />
                <CompanyPartnerLevelField fieldType='NoReduxSelect' value={postponedFilters.partner_level || filters.partner_level || ''}
                                          onChange={event => filterItems('partner_level', event.target.value)} />
            </React.Fragment>}
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) => {
                            // resources are replaced by postal_code during postal code search
                            if (item.indicator === 'resources' && (postponedFilters.postal_code || filters.postal_code)) {
                                item = {sortName: 'postal_code', label: <FormattedMessage id='companies.list.table.postal_code' />};
                            }

                            return <TableCell key={idx} numeric={item.numeric}>
                                {sortName === item.sortName
                                    ? <TableSortLabel disabled active direction={sortDirection}>
                                    {item.label}
                                </TableSortLabel>
                                    : item.label}
                            </TableCell>;
                        })}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`
                ].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                    : !paginatedItems.size
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : pathname.startsWith('/all') ? 'companies.list.table.empty' : 'companies.list.my_companies.table.empty'}
                                              values={{link: passed_props.permission === 'RW'
                                                      ? <Link to='/my-companies/add' className='hover-border'>
                                                          <FormattedMessage id='companies.list.my_companies.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='companies.list.my_companies.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((company, idx) => {
                        const onClick = () => props.state === null ? history.push(`/${pathname.startsWith('/my-companies') ? 'my-' : ''}companies/${company.get(new Company().getUniqueIdentifier())}`) : {};

                        return <TableRow key={idx} hover>
                            <TableCell link onClick={onClick}>{company.get('name')}</TableCell>
                            <TableCell link onClick={onClick}>{Moment(company.get('created_at')).format('l, LT')}</TableCell>
                            <TableCell link onClick={onClick}><FormattedMessage id={`companytypefield.choice.${company.get('company_type')}`} /></TableCell>
                            <TableCell>
                                {(postponedFilters.postal_code || filters.postal_code)
                                    ? company.get('postal_code')
                                    : <Resources in_list={true} expandable={paginatedItems.size > 10} item={company} />
                                }
                            </TableCell>
                            {pathname.startsWith('/companies') && <React.Fragment>
                                <TableCell link onClick={onClick}><FormattedMessage id={`companypartnerlevelfield.choice.${company.get('partner_level')}`} /></TableCell>
                                <TableCell numeric link onClick={onClick}>
                                    <Tooltip title={<FormattedMessage id={`companystatusfield.choice.${company.get('status')}`} />}>
                                        <span><ApprovalStatusIcon identifier={company.get('status')} /></span>
                                    </Tooltip>
                                </TableCell>
                            </React.Fragment>}
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <Pagination model={Company} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
    </Card>;
}
