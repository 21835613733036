'use strict';
import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        '&.highlighted': {
            '& > div': {
                // match padding on both sides (because of arrow)
                paddingLeft: `${theme.spacing(3)}px`,
                // center
                textAlign: 'center',
                // highlight
                backgroundColor: theme.palette.grey[500],
                '& span': {
                    color: `${theme.palette.common.white}`
                }
            },
            '&.registered > div': {
                backgroundColor: theme.partnerLevel['registered'],
                backgroundImage: theme.partnerLevel.gradients['registered']
            },
            '&.silver > div': {
                backgroundColor: theme.partnerLevel['silver'],
                backgroundImage: theme.partnerLevel.gradients['silver']
            },
            '&.gold > div': {
                backgroundColor: theme.partnerLevel['gold'],
                backgroundImage: theme.partnerLevel.gradients['gold']
            }
        }
    }
});

/**
 * Simple select field for Partner Level
 */
class CompanyPartnerLevelField extends React.Component {
    render() {
        // Split some stuff from props
        let {intl, classes, _classes, className, highlight, name, label, required, ...rest_of_props} = this.props;
        // Merge classes from props and our custom
        let {root} = _classes || {};
        let rootClasses = [
            classes.root, root, className,
            highlight ? 'highlighted' : '',
            this.props.value || this.props.selectedValue
        ].filter(Boolean).join(' ');

        return <Field fieldType='Select' name={name || 'partner_level'}
                      label={label || `${this.props.intl.formatMessage({id: 'companypartnerlevelfield.label'})}${required ? '*' : ''}`}
                      className={rootClasses}
                      {...rest_of_props}>
            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
            <MenuItem value='unverified'><FormattedMessage id='companypartnerlevelfield.choice.unverified' /></MenuItem>
            <MenuItem value='registered'><FormattedMessage id='companypartnerlevelfield.choice.registered' /></MenuItem>
            <MenuItem value='silver'><FormattedMessage id='companypartnerlevelfield.choice.silver' /></MenuItem>
            <MenuItem value='gold'><FormattedMessage id='companypartnerlevelfield.choice.gold' /></MenuItem>
            <MenuItem value='declined'><FormattedMessage id='companypartnerlevelfield.choice.declined' /></MenuItem>
        </Field>;
    }
}

CompanyPartnerLevelField = withStyles(styles)(CompanyPartnerLevelField);
export default injectIntl(CompanyPartnerLevelField);
