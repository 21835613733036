import React, {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
// components
import ThemeProvider from 'components/ThemeProvider';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import Button from 'components/core/ui/mui/Button';
import BackIcon from '@material-ui/icons/ChevronLeftOutlined';
import Typography from '@material-ui/core/Typography';
// icons
import WarningIcon from '@material-ui/icons/WarningOutlined';
import NotFoundIcon from 'components/core/vectors/NotFoundIcon';
import NotFoundWave from 'components/core/vectors/waves/NotFound';
import ForbiddenIcon from 'components/core/vectors/ForbiddenIcon';
import ForbiddenWave from 'components/core/vectors/waves/Forbidden';


const useStyles = makeStyles(theme => ({
    // layout holder
    componentWrapper: {
        // icon and text next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '100%',
        gap: `${theme.spacing(4)}px`,
        // limit size
        width: '800px',
        margin: '0 auto',
        paddingTop: `${theme.spacing(12)}px`,
        // responsive
        [theme.breakpoints.down('sm')]: {
            // icon and text bellow each other
            flexDirection: 'column',
            paddingTop: `${theme.spacing(4)}px`
        }
    },
    // wrapper for heading and icon, aligning them horizontally
    headingWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`
    },
    // wrapper for button for vertical align
    buttonWrapper : {
        textAlign: 'right',
        // responsive
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left'
        }
    },
    icon: {
        width: `${theme.spacing(35)}px`,
        flexShrink: '0',
        // responsive
        [theme.breakpoints.down('sm')]: {
            width: `${theme.spacing(25)}px`
        }
    }
}));

/**
 * Generic Error Page
 * - type prop - can be 'notfound'/'unauthorized'
 */
export default function ErrorPage(props) {
    const {type = 'notfound'} = props;
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    const portal = useMemo(() => pathname.startsWith('/partner'), [pathname]);
    // styles
    const classes = useStyles();
    // get error icon
    const Icon = useMemo(() => type === 'notfound' ? NotFoundIcon : ForbiddenIcon, [type]);

    return <Card>
        <CardContent className={classes.componentWrapper}>
            <Icon className={`${classes.icon} ${type}`} />
            <div>
                <div className={classes.headingWrapper}>
                    <Typography variant='h5' component='h2'>
                        <FormattedMessage id={`errors.${type}.title`} />
                    </Typography>
                    <WarningIcon color='error' />
                </div>
                <SpaceDivider />
                <Typography color='textSecondary'>
                    <FormattedMessage id={`errors.${type}.text`} />
                </Typography>
                <SpaceDivider />
                <div className={classes.buttonWrapper}>
                    <ThemeProvider alt={type !== 'notfound'}>
                        <Button variant='contained' color={type === 'notfound' ? 'primary' : 'secondary'}
                                onClick={() => history.push(portal ? '/partner' : '')}>
                            <BackIcon />
                            <FormattedMessage id='errors.home' />
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </CardContent>
        {type === 'notfound'
            ? <NotFoundWave portal={portal} />
            : <ForbiddenWave portal={portal} />}
    </Card>;
}
