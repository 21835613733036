import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// Components
import Form from 'components/core/ui/Form';


const useStyles = makeStyles(theme => ({
    holder: {
        position: 'relative'
    },
    form: {
        // highlight style
        borderTop: `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
        background: theme.palette.common.white,
        // proportion
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        margin: '0',
        // position before <Filters />
        position: 'absolute',
        zIndex: '50',
        right: '0',
        top: `-${theme.spacing(6)}px`,
        // fields inside
        justifyContent: 'flex-end',
        // responsive
        [theme.breakpoints.down('lg')]: {
            // reposition
            position: 'static',
            // restyle
            borderBottom: '0',
            borderLeft: '0',
            borderRight: '0'
        }
    }
}));

/**
 * Extra Form above <Filters /> for extra actions, like submissions.StatusMassUpdate and endpoints.Search
 */
export default function FiltersExtraForm(props) {
    const classes = useStyles();

    return <div className={classes.holder}>
        <Form className={classes.form} {...props} />
    </div>;
}
