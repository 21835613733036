import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// material-ui
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    referenceItem: {
        backgroundColor: theme.palette.grey[300],
        borderRadius: `${theme.spacing(1.5)}px`,
        padding: `${theme.spacing(0.5)}px`,
        fontSize: theme.typography.pxToRem(10)
    }
}));

/**
 * Renders FeatureFlag status message
 *
 * @param {object} props - provided props
 * @param {object} props.featureFlag - feature flag object
 */
export default function FeatureFlagStatusMessage(props) {
    const {featureFlag} = props;
    const classes = useStyles();

    // BEWARE! duplicated conditions from evaluateFeatureFlag
    // in case we don't have feature flag at all or feature flag is not enabled, disallow
    if (!featureFlag || !featureFlag.enabled) {
        return <FormattedMessage id='featureflags.detail.info.rules.disabled_everyone' />;
    }
    // in case feature flag has no rules, allowed for everyone
    if (featureFlag.rules.length === 0) {
        return <FormattedMessage id='featureflags.detail.info.rules.allowed_everyone' />;
    } else {
        // show message based on rules
        return <React.Fragment>
            <FormattedMessage id='featureflags.detail.info.rules.allowed_for' />{' '}
            {featureFlag.rules.map((rule, id) => <React.Fragment key={id}>
                {rule.context_key && <strong>{rule.context_key}{' '}</strong>}
                {rule.reference?.length ? <React.Fragment>({rule.reference?.map((item, idx) => <React.Fragment key={item}>
                    <Typography variant='caption' component='span' className={classes.referenceItem} noWrap>{item}</Typography>{rule.reference[idx + 1] && ', '}
                </React.Fragment>)}){' '}</React.Fragment> : null}
                {rule.logical_operator && <span>{rule.logical_operator}{' '}</span>}
            </React.Fragment>)}
        </React.Fragment>;
    }
}
