import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITableFooter from '@material-ui/core/TableFooter';
import {alpha, lighten} from '@material-ui/core/styles/colorManipulator';


const useStyles = makeStyles(theme => ({
    tableFooter: {
        background: alpha(theme.palette.common.white, 0.7),
        '& tr:last-child td': {
            borderTop: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
            borderBottom: '0'
        }
    }
}));

/**
 * Basically MUI TableFooter with additional style to support react-sticky-el
 */
export default function TableFooter(props) {
    // Split some stuff from props
    const {_classes, className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        root, className, classes.tableFooter
    ].filter(Boolean).join(' ');

    return <MUITableFooter
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}
