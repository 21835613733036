import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {Company, Message} from 'lib/models';
// Actions
import {addMessage, removeMessage} from 'actions/app';
// Components
import {Link} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Tooltip from 'components/core/ui/mui/Tooltip';
// icons
import CloseIcon from '@material-ui/icons/CloseOutlined';


export const useStyles = makeStyles(theme => ({
    card: {
        background: theme.palette.grey[500],
        // primary card
        '&.success': {
            background: theme.palette.primary[500]
        },
        // orange card
        '&.warning': {
            background: theme.palette.orange[500]
        },
        // red card
        '&.error': {
            background: theme.palette.danger[500]
        }
    },
    cardHeaderRoot: {
        alignItems: 'center', // center with 'X' no matter how many lines text have
        marginBottom: `-${theme.spacing(1)}px` // ensure that top and bottom spaces are the same
    },
    cardHeaderTitle: {
        // lower the size of text to indicate 'message'
        ...theme.typography.subtitle1
    },
    cardText: {
        // readable color (since we have background)
        color: theme.palette.common.white,
        // change style of link
        '& a': {
            fontWeight: theme.typography.fontWeightMedium,
            '&, &:not(.MuiButton-root):hover': {
                color: theme.palette.common.white
            },
            '&.hover-border': {
                '&:after, &:hover:after': {
                    background: theme.palette.common.white
                }
            }
        }
    }
}));

/**
 * Renders informative messages for logged User
 */
export default function Messages() {
    const classes = useStyles();
    const {pathname} = useLocation();
    // check if it's partner portal
    const portal = pathname.startsWith('/partner');
    // redux store
    const dispatch = useDispatch();
    const {messages, company} = useSelector(state => ({
        messages: state.app.get('messages'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);

    /**
     * Watch for router location change to close the message
     *
     * @param prevProps - Props from previous state
     */
    useEffect(() => {
        if (messages.size) {
            // iterate through message
            messages.forEach((message, idx) => {
                // check if message path doesn't match or remove path is reached
                if ((message.get('path') !== null && (message.get('path') === 'on-change' || (message.get('strict') ? message.get('path') !== pathname : !pathname.startsWith(message.get('path'))))
                ) || (message.get('remove_paths') !== null && message.get('remove_paths').filter(path => pathname.startsWith(path)).size)) {
                    dispatch(removeMessage(idx));
                }
            });
        }
    }, [pathname]);

    /**
     * Watch for Partner Portal 'unfilled' fields
     */
    useEffect(() => {
        const missing_bank_message = messages.find(msg => msg.get(new Message().getUniqueIdentifier()) === 'missing_bank_data');
        // check if we should show notification
        if (portal && company && new Company().getPPRequiredFields().some(field => !company[field])) {
            if (!missing_bank_message) {
                dispatch(addMessage({
                    identifier: 'missing_bank_data',
                    intl_id: 'register.error.missing_bank_data',
                    intl_values: {
                        link: <Link to='/partner/company' className='hover-border'>
                            <FormattedMessage id='register.error.missing_bank_data.link' />
                        </Link>
                    },
                    type: 'warning',
                    removable: false
                }));
            }
        } else if (missing_bank_message) {
            dispatch(removeMessage('missing_bank_data'));
        }
    }, [portal, JSON.stringify(company)]);

    return <div>
        {messages.map((message, idx) =>
            <div key={idx}>
                <Card className={`${classes.card} ${message.get('type')}`}>
                    <CardHeader
                        title={<span>{message.get('intl_id')
                            ? <FormattedMessage id={message.get('intl_id')}
                                                values={message.get('intl_values')
                                                    ? message.get('intl_values').toJS()
                                                    : {}} />
                            : message.get('text')}
                        </span>}
                        action={message.get('removable') && <div>
                            <Tooltip title={<FormattedMessage id='actions.close' />}>
                                <ActionButton iconButton onClick={() => dispatch(removeMessage(idx))}>
                                    <CloseIcon className={classes.cardText} />
                                </ActionButton>
                            </Tooltip>
                        </div>}
                        _classes={{
                            root: classes.cardHeaderRoot,
                            title: `${classes.cardHeaderTitle} ${classes.cardText}`
                        }}
                        mobileActions
                    />
                </Card>
                <SpaceDivider grid />
            </div>
        )}
    </div>;
}
