import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * German flag
 */
export default function DeFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <path fill='#000000' d='M0 0h640v160H0z' />
        <path fill='#DD0000' d='M0 160h640v160H0z' />
        <path fill='#ffce00' d='M0 320h640v160.002H0z' />
    </SvgIcon>;
}
