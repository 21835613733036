import React from 'react';
import {theme} from 'theme';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Gradient Home icon used for Partner Portal
 */
export default function GradientHomeIcon(props) {
    return <SvgIcon viewBox='0 0 24 25' {...props}>
        <path fill='url(#home_icon_linear)' d='M10 20.7764V14.7764H14V20.7764H19V12.7764H22L12 3.77637L2 12.7764H5V20.7764H10Z' />
        <defs>
            <linearGradient id='home_icon_linear' x1='2' y1='12.274' x2='21.9929' y2='12.274' gradientUnits='userSpaceOnUse'>
                <stop stopColor={theme.gradients.green.color1} />
                <stop offset='1' stopColor={theme.gradients.green.color2} />
            </linearGradient>
        </defs>
    </SvgIcon>;
}
