import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import {FormattedMessage, useIntl} from 'react-intl';
import {copy} from 'lib/util';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import CopyIcon from '@material-ui/icons/Link';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';


const useStyles = makeStyles(theme => ({
    // code block with credential value
    credential: {
        whiteSpace: 'nowrap', // one-liner
        overflow: 'auto',
        userSelect: 'all', // easier copy
        '&.hidden': {
            userSelect: 'none'
        }
    },
    // holder of 'reval' and 'copy' buttons
    actionButtonHolder: {
        // simulate <CardHeader /> right align
        textAlign: 'right',
        marginRight: `-${theme.spacing(1)}px` // closer to wall
    },
    // 'show' / 'hide' label of reval button
    revalButtonLabel: {
        '& > span': {
            width: '50px' // fixed size for both variants
        }
    }
}));

/**
 * Render specific block with Credential (e.g. access_key_id, access_key_secret)
 */
function Credential(passed_props) {
    const {title_intl, value, secret = false} = passed_props;
    const intl = useIntl();
    const classes = useStyles();
    // local state
    const [showSecret, setShowSecret] = useState(false); // flag to display/hide access_key_secret
    const [copied, setCopied] = useState(false); // flag to indicate successful copy

    return <div>
        <Typography variant='body1'>
            {`${intl.formatMessage({id: title_intl})}:`}
        </Typography>
        <code className={`${classes.credential}${secret && !showSecret ? ' hidden' : ''}`}>
            {secret && !showSecret
                ? '*'.repeat(value && value.length ? value.length : 4)
                : value
            }
        </code>
        <div className={classes.actionButtonHolder}>
            {secret && <ActionButton
                _classes={{label: classes.revalButtonLabel}}
                onClick={() => setShowSecret(!showSecret)}>
                {showSecret
                    ? <React.Fragment>
                        <VisibilityOff />
                        <FormattedMessage id='actions.hide' />
                    </React.Fragment>
                    : <React.Fragment>
                        <Visibility />
                        <FormattedMessage id='actions.show' />
                    </React.Fragment>}
            </ActionButton>}
            <ActionButton
                variant='outlined' color='primary'
                onClick={(event) => {
                    event.preventDefault();
                    copy(value).then(() => {
                        // let user know that it's copied
                        setCopied(true);
                    });
                }}
                loading={copied} success={copied}
                postAnimation={() => setCopied(false)}>
                <CopyIcon />
                <FormattedMessage id='actions.copy' />
            </ActionButton>
        </div>
    </div>;
}

/**
 * Renders detail of API Key with it's token secret - ADD & EDIT
 */
export default function Detail(passed_props) {
    const {access_key_id, access_key_secret} = passed_props;
    // router
    const history = useHistory();
    // redux store
    const props = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);

    return <Card>
        <CardHeader title={<FormattedMessage id='apikeys.detail.credentials.title' />}
                    subheader={<FormattedMessage id='apikeys.detail.credentials.subheader' />} />
        <CardContent>
            <Credential title_intl='apikeys.detail.credentials.access_key_id'
                        value={access_key_id} />
            <Credential title_intl='apikeys.detail.credentials.access_key_secret'
                        value={access_key_secret}
                        secret={true} />
        </CardContent>
        <CardContent className='text-center' notbspace>
            <FormattedMessage id='apikeys.detail.credentials.documentation.description' />
        </CardContent>
        <CardActions center>
            <Button variant='outlined'
                    color='primary' disabled={props.state !== null}
                    onClick={() => history.push('/documentation#tag/Authentication')}>
                <ModelIcon model='documentation' />
                <FormattedMessage id='apikeys.detail.credentials.documentation' />
            </Button>
        </CardActions>
    </Card>;
}
