import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
// Components
import Form from 'components/core/ui/Form';
import {SettingField, validateSettingsField, getSettingInitialValue} from 'components/core/ui/Field';


/**
 * Renders preview of the Setting - used in Setting Detail
 */
function Preview(props) {
    /**
     * Watch for setting changes to trigger _validationHack to ensure correct data in formValues
     *
     * @param prevProps - Props from previous state
     */
    useEffect(() => {
        props.change('_validationHack', Date.now());
    }, [props.setting.get('field_type'), props.setting.get('uses_permissions'), props.setting.get('choices'), props.setting.get('default_value')]);

    return <Form>
        <SettingField
            namePrefix='field_preview'
            setting={props.setting}
            change={props.change}
            selectedValue={props.selectedValue}
            excludePermission={true}
        />
    </Form>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    const nested_field_preview = data.field_preview || {}; // nested fix

    if (props.setting.get('indexed') && !props.setting.getIn(['indexed', 'enabled'])) {
        errors['field_preview'] = validateSettingsField(
            nested_field_preview, props.setting, props.intl);
    }

    return errors;
};

const PreviewForm = reduxForm({
    form: 'previewFieldForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true // necessary to have correct validation for SettingFields
})(Preview);

const ConnectedPreview = connect((state, props) => {
    const indexed = props.setting.get('indexed') && props.setting.getIn(['indexed', 'enabled']);
    const formValues = getFormValues('previewFieldForm')(state);

    return {
        initialValues: indexed ? undefined : {field_preview: getSettingInitialValue(props.setting)},
        selectedValue: formValues
            ? indexed
                ? formValues.field_preview
                : formValues.field_preview
                    ? formValues.field_preview.value : undefined
            : undefined,
        formValues: formValues
    };
})(PreviewForm);

export default injectIntl(ConnectedPreview);
