import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {SettingGroup, Setting} from 'lib/models';
import {useLocalFilter} from 'lib/filters';
// Actions
import {setCollection, removeFromCollection, setLocalState} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField} from 'components/core/ui/Field';
import NewSettingDialog from 'components/modules/settings/NewSettingDialog';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import ExpansionPanelSummary from 'components/core/ui/mui/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import RequestSettingIcon from '@material-ui/icons/PlusOneOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';


const useStyles = makeStyles(theme => ({
    // holder of search
    filters: {
        // better responsive scale
        flexWrap: 'wrap',
        // remove bottom border to connect nicely with ExpansionPanel
        borderBottom: '0'
    },
    // holder of expansion panels
    expansionsHolder: {
        // extra breathing space
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`
    },
    // holder of panel summary and content
    expansionPanel: {
        // remove paper style
        background: 'transparent',
        boxShadow: 'none',
        border: '0',
        // get rid of default top border
        '&:before': {
            content: 'none'
        }
    },
    // shared style between expansionPanelSummary and listItem
    expansionItem: {
        // space for border and between
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        marginLeft: `${theme.spacing(2)}px`,
        // space between (same with expanded)
        '&, &.expanded': {
            marginTop: `${theme.spacing(1)}px`,
            marginBottom: '0'
        },
        // style (matching SettingField from web-ui)
        color: theme.palette.text['primary'],
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[300]}`,
        transition: theme.transitions.create(['background', 'color', 'border'],
            {duration: theme.transitions.duration.short}),
        // | left border
        '&:before': {
            // position
            position: 'absolute',
            content: '""',
            display: 'block',
            left: `-${theme.spacing(2)}px`,
            // connect borders together
            top: '0',
            bottom: '0',
            // MuiInput bottomLineColor (underline)
            borderLeft: `${theme.spacing(0.5)}px solid rgba(0, 0, 0, 0.42)`,
            transition: theme.transitions.create(['border-left-color', 'opacity'], {
                duration: theme.transitions.duration.short
            })
        },
        '&:hover, &.Mui-focused': {
            background: theme.palette.grey[50],
            color: theme.palette.primary['main']
        },
        // setting inside selected
        '&.selected': {
            '&:before': {
                borderLeftColor: theme.palette.primary['main']
            }
        }
    },
    // category name + description that toggles expansion panel
    expansionPanelSummary: {
        overflow: 'visible',
        // toggle arrow button
        '& .expand-button': {
            transition: theme.transitions.create(['color', 'transform'], {
                duration: theme.transitions.duration.short
            })
        },
        '&.Mui-expanded': {
            borderColor: theme.palette.primary[300],
            background: theme.palette.primary[300],
            '&, & .expand-button': {
                color: theme.palette.common.white
            }
        },
        // | left border
        '&:before': {
            // cover borders
            top: '-1px',
            bottom: '-1px'
        }
    },
    // settings holder
    list: {
        padding: '0',
        // to sides
        margin: `-${theme.spacing(1)}px -${theme.spacing(2)}px -${theme.spacing(2)}px`
    },
    // setting item
    listItem: {
        width: 'auto',
        '& .description': {
            color: theme.palette.text['secondary'],
            transition: theme.transitions.create('color',
                {duration: theme.transitions.duration.short})
        },
        // connect together
        marginTop: '-1px',
        marginBottom: '-1px',
        // hover effect
        '&:hover': {
            '& .description': {
                color: theme.palette.primary['main']
            }
        },
        // white top border between items
        '&:after': {
            position: 'absolute',
            content: '""',
            display: 'block',
            top: '-1px',
            left: '-1px',
            right: '-1px',
            height: '1px',
            background: theme.palette.common.white,
            // hide by default
            opacity: '0',
            transition: theme.transitions.create('opacity',
                {duration: theme.transitions.duration.short})
        },
        // selected
        '&.selected': {
            '&, &:hover': {
                borderColor: theme.palette.primary[300],
                background: theme.palette.primary[300],
                '&, & .description': {
                    color: theme.palette.common.white
                },
                '&:after': {
                    opacity: '1'
                }
            }
        }
    }
}));

/**
 * Renders List of Settings in Card and allows selecting them
 */
export default function SelectingSettings(passed_props) {
    const {filterProductGroup, sortedGroups, sortedItems, validationHack, permission, product} = passed_props;
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        settings_loaded: state.shared.getIn(['loaded', 'settings']),
        settings_selected: state.shared.getIn(['items', 'settings-selected'])
    }), shallowEqual);
    // local state
    const [expanded, setExpanded] = useState(0);
    const [newSettingDialogOpen, setNewSettingDialogOpen] = useState(false);
    // extra filter of sortedItems
    const items = useMemo(() => {
        return filterProductGroup ? sortedItems.filter(setting => setting.get('product_groups').includes(filterProductGroup)) : sortedItems;
    }, [filterProductGroup, sortedItems]);
    // filtering
    const supportedFilters = ['search'];
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        items, !props.settings_loaded || !props.groups_loaded || (product !== undefined && (!product || !props.product_groups_loaded)), 'settings-selected',
        supportedFilters, ['name', 'param_name', 'group'], {
            search_group: (obj, searchValue, searchOptimize) => obj.get('group') && sortedGroups.filter(group => searchOptimize(group.get('name')).includes(searchValue)).map(group => group.get(new SettingGroup().getUniqueIdentifier())).includes(obj.get('group'))
        });

    // cleanup
    useEffect(() => {
        // remove newly added setting from state
        return () => {
            dispatch(setLocalState(null, 'setting-added'));
        };
    }, []);

    return <div>
        <CardFirst>
            <CardHeader title={<FormattedMessage id='settings.selecting.title' />}
                        subheader={<FormattedMessage id='settings.selecting.subheader' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='settings' />
                        </ActionButton>} />
            <Filters className={classes.filters}
                     supportedFilters={supportedFilters}>
                <SearchField label={<FormattedMessage id='settings.selecting.search' />}
                             value={filters.search || ''}
                             search={(search) => filterItems('search', search)} />
            </Filters>
        </CardFirst>
        {['fetching_items_settings'].includes(props.state) || filtersInitializing
            ? <React.Fragment>
                <div />
                <ExpansionPanel expanded={true} noLink loading />
                <div />
            </React.Fragment>
            : !filteredItems.size
                ? <React.Fragment>
                    <div />
                    <ExpansionPanel expanded={true} noLink title_intl='settings.selecting.empty' />
                    <div />
                </React.Fragment>
                : <div className={classes.expansionsHolder}>
                    {sortedGroups.map((group, idx) => {
                        const group_settings = filteredItems.filter(setting => setting.get('group') === group.get(new SettingGroup().getUniqueIdentifier()));
                        const group_settings_ids = group_settings.map(el => el.get(new Setting().getUniqueIdentifier()));
                        const group_selected = !!props.settings_selected.find(el => group_settings_ids.includes(el.get(new Setting().getUniqueIdentifier())));

                        return !group_settings.size ? '' : <ExpansionPanel
                            key={idx} className={classes.expansionPanel} destroyNotSelected
                            expanded={expanded === (idx + 1)} onChange={() => setExpanded(expanded === (idx + 1) ? 0 : (idx + 1))}
                            expansionPanelSummary={<ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                _classes={{expandIcon: 'expand-button'}}
                                className={[
                                    classes.expansionItem,
                                    classes.expansionPanelSummary,
                                    group_selected ? 'selected' : ''
                                ].filter(Boolean).join(' ')}>
                                <Typography variant='body1'>{group.get('name')}</Typography>
                                <Typography variant='body2'>{group.get('description')}</Typography>
                            </ExpansionPanelSummary>}>
                            <List className={classes.list}>
                                {group_settings.map((setting, idx) => {
                                    const selected = props.settings_selected.find(el => el.get(new Setting().getUniqueIdentifier()) === setting.get(new Setting().getUniqueIdentifier()));

                                    return <ListItem
                                        key={idx} button
                                        selected={!!selected}
                                        disabled={permission !== 'RW'}
                                        classes={{
                                            root: [
                                                classes.expansionItem,
                                                classes.listItem
                                            ].filter(Boolean).join(' '),
                                            selected: 'selected'
                                        }}
                                        onClick={() => {
                                            if (permission === 'RW') {
                                                if (selected) {
                                                    dispatch(removeFromCollection(Setting, 'settings-selected', setting));
                                                } else {
                                                    dispatch(setLocalState(setting, 'setting-added'));
                                                    dispatch(setCollection(Setting, 'settings-selected', [setting], true));
                                                }
                                                // we must trigger validation hack, so form submit doesn't ignore newly added field
                                                validationHack();
                                            }
                                        }}>
                                        <div>
                                            <Typography variant='body1'>{setting.get('name')}</Typography>
                                            <Typography variant='body2'>{setting.get('param_name')}{setting.get('description')
                                                ? <span className='description'>{` - ${setting.get('description')}`}</span> : ''}</Typography>
                                        </div>
                                    </ListItem>;
                                })}
                            </List>
                        </ExpansionPanel>;
                    })}
                </div>}
        <CardLast>
            <CardActions center>
                <Button variant='outlined' color='primary'
                        onClick={() => setNewSettingDialogOpen(true)}>
                    <RequestSettingIcon />
                    <FormattedMessage id='settings.selecting.request_setting' />
                </Button>
            </CardActions>
        </CardLast>
        <NewSettingDialog
            open={newSettingDialogOpen}
            handleClose={() => setNewSettingDialogOpen(false)} />
    </div>;
}
