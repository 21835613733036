import React, {useMemo} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// components
import AssetManagerLayout from 'components/modules/assets/AssetManagerLayout';
import AssetManager from 'components/modules/assets/AssetManager';
import AssetFoldersMenu from 'components/modules/assets/FolderMenu';
import FolderDetail from 'components/modules/assets/FolderDetail';
import FileDetail from 'components/modules/assets/FileDetail';
import DeleteFolderDialog from 'components/modules/assets/DeleteFolderDialog';
import DeleteFileDialog from 'components/modules/assets/DeleteFileDialog';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';


const useStyles = makeStyles((theme) => ({
    rootCard: {
        padding: 0
    }
}));

/**
 * Render Assets from backend - to view and manage them
 */
export default function List(props) {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch([
        '/asset-manager/add', '/asset-manager/edit', '/asset-manager/move', '/asset-manager/delete',
        '/asset-manager/:identifier/files/:file_identifier/rename', '/asset-manager/:identifier/files/:file_identifier/move',
        '/asset-manager/:identifier/files/:file_identifier/delete', '/asset-manager/:identifier/files/add',
        '/asset-manager/:identifier/add', '/asset-manager/:identifier/edit',
        '/asset-manager/:identifier/move', '/asset-manager/:identifier/delete', '/asset-manager/:identifier'
    ]);
    const rootPath = useMemo(() => '/asset-manager', []);

    return <Card className={classes.rootCard}>
        <CardHeader title={<FormattedMessage id='assets.list.title' />}
            subheader={<FormattedMessage id='assets.list.subheader' />} />
        <AssetManagerLayout permission={props.permission} foldersMenu={
            <AssetFoldersMenu {...props}
                rootPath={rootPath}
                editable={props.permission === 'RW'}
                value={match?.params.identifier}
                onChange={(newValue, {isExactRoute, isRoot}) => {
                    if (!isExactRoute) {
                        history.push(`${rootPath}/${newValue}`);
                    // go home if it's root folder's child
                    } else if (isRoot) {
                        history.push(rootPath);
                    }
                }}
            />
        }>
            <AssetManager editable={props.permission === 'RW'} folderPath={match?.params.identifier} />
        </AssetManagerLayout>
        {props.permission === 'RW' && <React.Fragment>
            <FolderDetail open={['/asset-manager/add', '/asset-manager/:identifier/add'].includes(match?.path)} />
            <FolderDetail edit='rename' open={['/asset-manager/edit', '/asset-manager/:identifier/edit'].includes(match?.path)} />
            <FolderDetail edit='move' open={['/asset-manager/move', '/asset-manager/:identifier/move'].includes(match?.path)} />
            <DeleteFolderDialog open={['/asset-manager/delete', '/asset-manager/:identifier/delete'].includes(match?.path)} />
            <FileDetail open={['/asset-manager/:identifier/files/add'].includes(match?.path)} />
            <FileDetail edit='rename' open={['/asset-manager/:identifier/files/:file_identifier/rename'].includes(match?.path)} />
            <FileDetail edit='move' open={['/asset-manager/:identifier/files/:file_identifier/move'].includes(match?.path)} />
            <DeleteFileDialog open={['/asset-manager/:identifier/files/:file_identifier/delete'].includes(match?.path)} />
        </React.Fragment>}
    </Card>;
}
