import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment/moment';
// components
import DMInfoCard from 'components/modules/tickets/dm/DMInfoCard';
// icons
import UptimeIcon from '@material-ui/icons/AccessTimeOutlined';


/**
 * Render device manager info about uptime
 */
export default function DMUptime(props) {
    const {item} = props;

    const [value, lastUpdate] = useMemo(() => {
        const data = item?.getIn(['data', 'UpTime']);
        const upTimeDate = data?.get('value') && Moment(data?.updated_at).subtract(data.get('value'), 'seconds');

        return [upTimeDate ? `${Moment.duration(Moment().diff(upTimeDate)).humanize()} [${upTimeDate.format('l, LT')}]` : '-', data?.get('updated_at')];
    }, [item.getIn(['data', 'UpTime']) && JSON.stringify(item.getIn(['data', 'UpTime']))]);

    return <DMInfoCard
        title={<FormattedMessage id='devicemanagement.type.uptime' />}
        icon={<UptimeIcon />}
        info={value} lastUpdate={lastUpdate}
    />;
}
