import React, {useCallback, useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// components
import WaveDialog from 'components/core/ui/WaveDialog';
// actions
import {setState} from 'actions/app';
import {setSubscription} from 'actions/auth';
import {simplePost} from 'actions/shared';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import DialogActions from '@material-ui/core/DialogActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import CloseIcon from '@material-ui/icons/CloseOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SubmitIcon from '@material-ui/icons/ArrowForward';


/**
 * Delete Subscription active button with dialog
 */
export default function Delete(provided_props) {
    const {isFreeDm} = provided_props;
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);
    // local state
    const [open, setOpen] = useState(false); // flag for dialog open/close state
    const [variant, setVariant] = useState('info');
    // update variant based on state change to preserve it during close
    useEffect(() => {
        if (props.state === 'posted_fetch_subscription') {
            setVariant('success');
        } else if (['failed_fetch_subscription', 'failed_remove_subscription'].includes(props.state)) {
            setVariant('failed');
        }
    }, [props.state]);

    /**
     * Delete Company Subscription
     */
    const handleSubmit = useCallback(() => {
        // simple delete request
        dispatch(simplePost('remove_subscription', props.company.getIn(['links', 'subscription']), {}, {post_method: 'delete'})).then(() => {
            // re-fetch subscription to get correct canceled date
            dispatch(simplePost('fetch_subscription', props.company.getIn(['links', 'subscription']), {}, {post_method: 'get'})).then(result => {
                dispatch(setSubscription(result.data));
            });
        });
    }, []);

    return <React.Fragment>
        <ActionButton
            variant='outlined'
            onClick={() => {
                setVariant('info'); setOpen(!open);
            }}>
            <CloseIcon />
            <FormattedMessage id={`subscription.${isFreeDm ? 'deactivate_dm' : 'delete'}`} />
        </ActionButton>
        <WaveDialog
            open={open} setOpen={props.state !== null ? () => {} : setOpen}
            variant={variant}
            title={<FormattedMessage id={`subscription.${isFreeDm ? 'deactivate_dm' : 'delete'}.${variant}.title`} />}
            description={<FormattedMessage id={`subscription.${isFreeDm ? 'deactivate_dm' : 'delete'}.${variant}.description`} />}
            actions={['posting_remove_subscription', 'posted_remove_subscription', 'failed_remove_subscription',
                'posting_fetch_subscription', 'posted_fetch_subscription', 'failed_fetch_subscription'].includes(props.state)
                ? <CardActionsLoader
                    dialogActions center
                    success={props.state === 'posted_fetch_subscription'}
                    failure={['failed_fetch_subscription', 'failed_remove_subscription'].includes(props.state)}
                    postAnimation={() => {
                        ['failed_fetch_subscription', 'failed_remove_subscription'].includes(props.state)
                            ? dispatch(setState('failed_finished_subscription'))
                            : dispatch(setState('success_finished_subscription'));
                    }}
                />
                : <DialogActions>
                    {['failed_finished_subscription', 'success_finished_subscription'].includes(props.state)
                        ? <Button startIcon={<CloseIcon />}
                                  onClick={() => {
                                      setOpen(!open);
                                      dispatch(setState(null));
                                  }}>
                            <FormattedMessage id='actions.close' />
                        </Button>
                        : <React.Fragment>
                            <Button startIcon={<CancelIcon />} onClick={() => setOpen(!open)}>
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            <Button endIcon={<SubmitIcon />} onClick={handleSubmit}>
                                <FormattedMessage id='subscription.delete.action.deactivate' />
                            </Button>
                        </React.Fragment>
                    }
                </DialogActions>
            }
        />
    </React.Fragment>;
}
