import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import Moment from 'moment';
import {Company, Paginator, Submission} from 'lib/models';
import {List as ImmutableList} from 'immutable';
import {useLocalFilter, useLocalSort, usePaginatedItems, useSelecting} from 'lib/filters';
// Actions
import {fetchItems, fetchItem, markFiltered, updatePaginator} from 'actions/shared';
import {addMessage} from 'actions/app';
// Components
import StatusMassUpdate from 'components/modules/submissions/StatusMassUpdate';
import RequestCSV from 'components/core/ui/RequestCSV';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {CompanyField, SalesClustersField, SearchField, DateSearchField, SubmissionStatusField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import {StatusIcon} from 'components/core/ui/fields/SubmissionStatusField';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Checkbox from 'components/core/ui/mui/Checkbox';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Submissions from backend, both client and admin - to view and manage them
 */
export default function List(passed_props) {
    const intl = useIntl();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'created_at', label: <FormattedMessage id='submissions.list.table.created_at' />},
        {sortName: 'status', label: <FormattedMessage id='submissions.list.table.status' />},
        ...(pathname.startsWith('/all-') ? [{sortName: 'company', label: <FormattedMessage id='submissions.list.global.table.company' />}] : []),
        {sortName: 'invoices', label: <FormattedMessage id='submissions.list.table.invoices' />},
        {sortName: 'total_rebate', label: <FormattedMessage id='submissions.list.table.total_rebate' />, numeric: true}
    ];
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
        const company_identifier = pathname.startsWith('/all-')
            ? searchParams.get('company')
                // ensure we don't create duplicate 'my' collection with UUID
                ? searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                    ? 'my'
                    : searchParams.get('company')
                : 'all'
            : 'my';
        const items_placement = `${new Submission().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            company: company,
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined
                : state.shared.getIn(['items', 'companies']).find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            sales_clusters: state.shared.getIn(['items', 'sales_clusters'])
        };
    }, shallowEqual);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new Submission().getUniqueIdentifier());
    const items = company_identifier === 'all' ? paginatedItems : props.items;
    // sorting
    const [apiSortName, apiSortDirection] = ['created_at', 'asc'];
    const [sortedItems, sortItems, localSortName, localSortDirection, sortInitializing] = useLocalSort(
        company_identifier === 'all' ? ImmutableList() : items, company_identifier !== 'all' && !props.loaded, items_placement, tableHead.map(item => item.sortName), apiSortName, apiSortDirection);
    const [sortName, sortDirection] = company_identifier === 'all' ? [apiSortName, apiSortDirection] : [localSortName, localSortDirection];
    // filtering
    const [localFilteredItems, filterItems, filters, filtersInitializing, postponedFilters] = useLocalFilter(
        sortedItems,
        sortInitializing || (pathname.startsWith('/all-') && company_identifier !== 'all' && searchParams.get('sales_cluster') && !props.sales_clusters),
        `${new Submission().getPlacement()}${pathname.startsWith('/all-') ? '-global' : ''}`,
        ['status', 'invoice_number', ...(pathname.startsWith('/all-') ? ['company', 'sales_cluster', 'created_at__gte', 'created_at__lte', 'total_rebate__gte', 'total_rebate__lte'] : [])], [], {
            invoice_number: (obj, value) => !!obj.get('invoices').filter(invoice => invoice.get('number').replace(/\s/g, '').toLowerCase().includes(value.replace(/\s/g, '').toLowerCase())).size,
            sales_cluster: (_obj, value) => props.selectedCompany && !!props.sales_clusters?.find((cluster) => cluster.get('uuid') === value && cluster.get('countries').includes(props.selectedCompany.get('country'))),
            total_rebate__gte: (obj, value) => (parseInt(obj.get('total_rebate'), 10) || 0) >= (parseInt(value, 10) || 0),
            total_rebate__lte: (obj, value) => (parseInt(obj.get('total_rebate'), 10) || 0) <= (parseInt(value, 10) || 0),
            created_at__gte: (obj, value) => Moment(obj.get('created_at')).isSameOrAfter(Moment(value)),
            created_at__lte: (obj, value) => Moment(obj.get('created_at')).isSameOrBefore(Moment(value))
        },
        company_identifier === 'all' && props.state !== null);
    const filteredItems = company_identifier === 'all' ? items : localFilteredItems;
    // selecting
    const [selectedItems, selectItem] = useSelecting(filteredItems);

    /**
     * During initialization fetch items
     */
    // all, pagination with API filters
    useEffect(() => {
        if (company_identifier === 'all' && !filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(Submission, items_placement, 'submissions', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), company_identifier]);
    // company, local filters
    useEffect(() => {
        if ((company_identifier === 'my' || (company_identifier !== 'all' && props.selectedCompany)) && props.loaded === false) {
            dispatch(fetchItems(Submission, items_placement,
                props.selectedCompany.getIn(['links', 'submissions'])
            ));
        }
    }, [props.loaded, props.selectedCompany, company_identifier]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, 'companies', ['companies', company_identifier], {success_affect_state: false}));
        }
    }, [props.selectedCompany, company_identifier]);
    // add disabled
    useEffect(() => {
        if (props.company.get('cluster_company_submission_enabled') === false) {
            dispatch(addMessage({intl_id: 'submissions.list.disabled', path: 'on-change', type: 'info'}));
        }
    }, [props.company.get('cluster_company_submission_enabled')]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'submissions.list.global.title'
                : 'submissions.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'submissions.list.global.subheader'
                : passed_props.dashboard
                ? 'submissions.list.dashboard.subheader'
                : 'submissions.list.subheader'} />}
            action={<React.Fragment>
                {(company_identifier !== 'all' && (passed_props.permission !== 'RW' || props.company.get('cluster_company_submission_enabled') === false)) && <ActionButton iconButton disabled>
                    <ModelIcon model='submissions' />
                </ActionButton>}
                {company_identifier === 'all' && <RequestCSV placement={items_placement} url='submissions-export' />}
                {(passed_props.permission === 'RW' && props.company.get('cluster_company_submission_enabled') !== false) && <ActionButton
                    variant='contained' color='secondary' disabled={props.state !== null}
                    onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : 'partner/'}submissions/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`)}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>}
            </React.Fragment>}
        />
        {!passed_props.dashboard && <React.Fragment>
            {pathname.startsWith('/all-') && <StatusMassUpdate
                items={selectedItems}
                placement={items_placement}
                paginator={props.paginator}
                postSubmit={() => {
                    selectItem('clear');
                    // make sure to mark global collection for reload
                    if (company_identifier !== 'all') {
                        dispatch(markFiltered(Submission, 'submissions-global'));
                    }
                }}
                postAnimation={() => {
                    if (company_identifier === 'all' && filters.status) {
                        // clear status filter, this will trigger re-fetch
                        filterItems('status', '');
                    }
                }}
            />}
            <Filters disabled={company_identifier === 'all' && props.state !== null} controlsDown>
                {pathname.startsWith('/all-') && <CompanyField
                    value={postponedFilters.company || filters.company || ''}
                    change={value => {
                        // make sure to move previous paginator to page 1
                        dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                        filterItems('company', value);
                    }}
                />}
                <SubmissionStatusField
                    fieldType='NoReduxSelect' value={postponedFilters.status || filters.status || ''}
                    onChange={(event) => filterItems('status', event.target.value)} />
                <SearchField label={<FormattedMessage id='submissions.list.global.filter.invoice_number' />}
                             value={postponedFilters.invoice_number || filters.invoice_number || ''}
                             search={(search) => filterItems('invoice_number', search)} />
                {pathname.startsWith('/all-') && <React.Fragment>
                    <SpaceDivider none />
                    <SalesClustersField fieldType='NoReduxSelect' value={postponedFilters.sales_cluster || filters.sales_cluster || ''}
                                        onChange={(event) => filterItems('sales_cluster', event.target.value)} />
                    <div>
                        <DateSearchField label={<FormattedMessage id='submissions.list.global.filter.created_at__gte' />}
                                         size='half' value={postponedFilters.created_at__gte || filters.created_at__gte || ''}
                                         search={(search) => filterItems('created_at__gte', search)} />
                        <DateSearchField label={<FormattedMessage id='submissions.list.global.filter.created_at__lte' />}
                                         size='half' value={postponedFilters.created_at__lte || filters.created_at__lte || ''}
                                         search={(search) => filterItems('created_at__lte', search)} />
                    </div>
                    <div>
                        <SearchField label={<FormattedMessage id='submissions.list.global.filter.total_rebate__gte' />}
                                     type='number' size='half' searchIcon={false}
                                     value={postponedFilters.total_rebate__gte || filters.total_rebate__gte || ''}
                                     search={(search) => filterItems('total_rebate__gte', search)} />
                        <SearchField label={<FormattedMessage id='submissions.list.global.filter.total_rebate__lte' />}
                                     type='number' size='half' searchIcon={false}
                                     value={postponedFilters.total_rebate__lte || filters.total_rebate__lte || ''}
                                     search={(search) => filterItems('total_rebate__lte', search)} />
                    </div>
                </React.Fragment>}
            </Filters>
        </React.Fragment>}
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {pathname.startsWith('/all-') && <TableCell checkbox>
                            <Checkbox disabled={props.state !== null}
                                      onClick={() => selectItem('all')}
                                      checked={selectedItems.size > 0 && selectedItems.size === items.size} />
                        </TableCell>}
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {company_identifier === 'all'
                                    ? sortName === item.sortName
                                        ? <TableSortLabel disabled active direction={sortDirection}>
                                            {item.label}
                                        </TableSortLabel>
                                        : item.label
                                    : <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}
                                                      disabled={passed_props.dashboard}>
                                        {item.label}
                                    </TableSortLabel>}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`
                ].includes(props.state) || filtersInitializing ? <PaginationLoading
                    paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                    tableHead={tableHead} selectCell={pathname.startsWith('/all-')} paginator={props.paginator}
                />
                    : !items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={pathname.startsWith('/all-') ? tableHead.length + 1 : tableHead.length}>
                            <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'submissions.list.table.empty'}
                                              values={{link: passed_props.permission === 'RW' && props.company.get('cluster_company_submission_enabled') !== false
                                                      ? <Link className='hover-border' to={`/${pathname.startsWith('/all-') ? 'all-' : 'partner/'}submissions/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`}>
                                                          <FormattedMessage id='submissions.list.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='submissions.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && (passed_props.dashboard ? filteredItems.slice(0, 5) : filteredItems).map((submission, idx) => {
                        const link = pathname.startsWith('/all-')
                            ? `/all-submissions/${submission.get(new Submission().getUniqueIdentifier())}${submission.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${submission.get('company')}` : ''}`
                            : `/partner/submissions/${submission.get(new Submission().getUniqueIdentifier())}`;
                        const onClick = () => props.state === null ? history.push(link) : {};

                        return <TableRow key={idx} hover>
                            {pathname.startsWith('/all-') && <TableCell checkbox>
                                <Checkbox disabled={props.state !== null}
                                          onClick={() => selectItem(submission)}
                                          checked={selectedItems.includes(submission)} />
                            </TableCell>}
                            <TableCell link onClick={onClick}>{Moment(submission.get('created_at')).format('l, LT')}</TableCell>
                            <TableCell name link onClick={onClick}>
                                <StatusIcon identifier={submission.get('status')} className='icon' />
                                <span className='text'>{intl.formatMessage({id: `submissionstatusfield.choice.${submission.get('status')}`})}</span>
                            </TableCell>
                            {pathname.startsWith('/all-') && <TableCell>
                                <Link className='hover-border' to={`/companies/${submission.get('company')}`}>
                                    {submission.get('company_name')}
                                </Link>
                            </TableCell>}
                            <TableCell link onClick={onClick}>{submission.get('invoices').map((invoice, idx) => <React.Fragment key={idx}>
                                    {invoice.get('number')}<br />
                                </React.Fragment>)}
                            </TableCell>
                            <TableCell numeric link onClick={onClick}>{`${submission.get('total_rebate') || 0} €`}</TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={company_identifier !== 'all' ? undefined : <Table size={1}>
                <Pagination model={Submission} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
        {passed_props.dashboard && <React.Fragment>
            <hr className='nomargin' />
            <CardActions center>
                <Button variant='outlined'
                        color='primary' disabled={props.state !== null}
                        onClick={() => history.push('/partner/submissions')}>
                    <FormattedMessage id='submissions.list.dashboard.goto' />
                </Button>
            </CardActions>
        </React.Fragment>}
    </Card>;
}
