import React, {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// components
import WaveDialog from 'components/core/ui/WaveDialog';
// actions
import {setState} from 'actions/app';
// material-ui
import Button from 'components/core/ui/mui/Button';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CloseIcon from '@material-ui/icons/CloseOutlined';


/**
 * Success / Failed dialog informing about Subscription payment
 */
export default function Dialog() {
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        state: state.app.get('state')
    }), shallowEqual);
    // get variant
    const [variant, setVariant] = useState('');
    // update variant based on state change to preserve it during close
    useEffect(() => {
        if (props.state === 'posted_subscription') {
            setVariant('success');
        } else if (props.state === 'failed_subscription') {
            setVariant('failed');
        }
    }, [props.state]);

    return <WaveDialog
        open={!!variant && ['posted_subscription', 'failed_subscription'].includes(props.state)}
        variant={variant}
        title={<FormattedMessage id={`subscription.${variant}.title`} />}
        description={<FormattedMessage id={`subscription.${variant}.description`} />}
        actions={<DialogActions>
            <Button startIcon={<CloseIcon />}
                    onClick={() => dispatch(setState(null))}>
                <FormattedMessage id='actions.close' />
            </Button>
        </DialogActions>}
    />;
}
