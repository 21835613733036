import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Italian flag
 */
export default function ItFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <g fillRule='evenodd' strokeWidth='1pt'>
            <path fill='#ffffff' d='M0 0h640v480H0z' />
            <path fill='#009246' d='M0 0h213.3v480H0z' />
            <path fill='#ce2b37' d='M426.7 0H640v480H426.7z' />
        </g>
    </SvgIcon>;
}
