import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getCurrencySymbol} from 'lib/util';
// components
import TierLabel, {useTierCalculation} from 'components/modules/subscription/TierLabel';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardHeader from 'components/core/ui/mui/CardHeader';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
// icons
import CalculateIcon from '@material-ui/icons/BusinessCenterOutlined';


const useStyles = makeStyles(theme => ({
    cardContent: {
        // move closer to CardHeader
        paddingTop: '0'
    },
    // alert message informing about trial period
    trial: {
        border: `2px solid ${theme.palette.primary[500]}`,
        background: theme.palette.snomGrey[100],
        borderRadius: theme.shape.borderRadius,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    },
    // price calculator, e.g. 'you will pay: 3 €'
    calc: {
        padding: `0 ${theme.spacing(2)}px`
    },
    // slider container
    sliderHolder: {
        padding: `0 ${theme.spacing(2)}px`
    },
    slider: {
        height: '6px'
    },
    sliderThumb: {
        height: '24px',
        width: '24px',
        marginTop: '-8px',
        marginLeft: '-12px'
    },
    sliderTrack: {
        height: '6px',
        borderRadius: '6px'
    },
    sliderRail: {
        height: '6px',
        borderRadius: '6px',
        backgroundColor: theme.palette.grey[500]
    },
    sliderMark: {
        height: '6px'
    },
    sliderMarkLabel: {
        top: '32px'
    },
    sliderValueLabel: {
        left: '-5px'
    },
    // part of table
    table: {
        border: `1px solid ${theme.palette.grey[300]}`
    },
    // cell with number of devices, e.g. '0 - 100'
    calcCell: {
        '& > span': {
            textAlign: 'center',
            display: 'inline-block',
            // fixed size for better readability
            width: '35px',
            '&.separator': {
                width: '20px'
            }
        }
    },
    // cell with price, e.g. '0.25 €'
    priceCell: {
        '& > span': {
            textAlign: 'right',
            display: 'inline-block',
            // fixed size for better readability
            width: '40px'
        }
    }
}));

/**
 * Calculation slider & table for subscription plan
 */
export default function Calculation(props) {
    const {subscription, subscriptionPlan} = props;
    const classes = useStyles();
    // local state
    const [sliderValue, setSliderValue] = useState(subscription?.get('dm_enabled_endpoints') || 0);
    const [calculatedPrice, activeTier] = useTierCalculation(sliderValue, subscriptionPlan?.get('tiers'));
    const formattedCalculatedPrice = calculatedPrice.toFixed(2);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='subscription.calculate.title' />}
            subheader={<FormattedMessage id='subscription.calculate.subheader' />}
            action={<ActionButton iconButton disabled>
                <CalculateIcon />
            </ActionButton>}
        />
        <CardContent className={classes.cardContent}>
            {(!subscription && subscriptionPlan?.get('trial_days')) ? <React.Fragment>
                <div className={classes.trial}>
                    <Typography className='newfont nomargin' variant='h6'>
                        <FormattedMessage id='subscription.calculate.features.trial.title' />
                    </Typography>
                    <p className='newfont nomargin'>
                        <FormattedMessage
                            id='subscription.calculate.features.trial.description'
                            values={{
                                trial_days: subscriptionPlan?.get('trial_days'),
                                trial_limit: subscriptionPlan?.get('trial_limit')
                            }}
                        />
                    </p>
                </div>
                <SpaceDivider />
            </React.Fragment> : null}
            <div className={classes.calc}>
                <Typography className='newfont text-primary' variant='h5'>
                    <FormattedMessage id={`subscription.calculate.calculation${subscriptionPlan?.get('trial_days') ? '.with_trial' : ''}`} />
                </Typography>
                <Typography className='newfont text-primary' variant='h2'>
                    <strong>{`${formattedCalculatedPrice} ${getCurrencySymbol(subscriptionPlan?.get('currency'))}`}</strong>
                </Typography>
                <Typography className='newfont text-primary'>
                    {'/'}<FormattedMessage id={`subscription.list.plans.price.${subscriptionPlan?.get('interval')}`} />
                </Typography>
            </div>
            <SpaceDivider />
            <Typography className='newfont' variant='subtitle1'>
                <FormattedMessage id='subscription.calculate.slider.title' />
            </Typography>
            <SpaceDivider double />
            <SpaceDivider />
            <div className={classes.sliderHolder}>
                <Slider
                    classes={{
                        root: classes.slider,
                        thumb: classes.sliderThumb,
                        track: classes.sliderTrack,
                        rail: classes.sliderRail,
                        mark: classes.sliderMark,
                        markLabel: classes.sliderMarkLabel,
                        valueLabel: classes.sliderValueLabel
                    }}
                    value={sliderValue}
                    onChange={(e, newValue) => setSliderValue(newValue)}
                    min={0} max={1200} step={1}
                    valueLabelDisplay='on'
                    marks={[
                        {value: 0, label: '0'},
                        ...(subscriptionPlan?.get('tiers') || []).filter(tier => !!tier.get('up_to')).map(
                            (tier, idx, tiers) => ({
                                value: tier.get('up_to'),
                                label: `${tier.get('up_to')}${idx === tiers.size - 1 ? '+' : ''}`
                            })
                        )
                    ]}
                />
            </div>
            <p className='newfont'>
                <strong><FormattedMessage id='subscription.calculate.info' /></strong>
            </p>
            <Table size={1} className={classes.table}>
                <TableHead>
                    <TableRow>
                        {['no_devices', 'price_per_device', null].map(thintl => <TableCell key={thintl}>
                            {thintl && <FormattedMessage
                                id={`subscription.calculate.table.${thintl}`}
                                values={{interval: <FormattedMessage id={`subscription.list.plans.price.${subscriptionPlan?.get('interval')}`} />}}
                            />}
                        </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscriptionPlan?.get('tiers').map((tier, idx) => <TableRow
                        key={tier.get('up_to')} hover
                        active={activeTier?.get('up_to') === tier.get('up_to')}>
                        <TableCell className={classes.calcCell}>
                            <span>{idx === 0 ? '0' : subscriptionPlan.get('tiers').get(idx - 1).get('up_to') + 1}</span>
                            <span className='separator'>{tier.get('up_to') ? '-' : '+'}</span>
                            <span>{tier.get('up_to') || ''}</span>
                        </TableCell>
                        <TableCell className={classes.priceCell}>
                            <span>{tier.get('unit_amount').toFixed(2)}{` ${getCurrencySymbol(subscriptionPlan.get('currency'))}`}</span>
                        </TableCell>
                        <TableCell>
                            <TierLabel tierIndex={idx} />
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </CardContent>
    </Card>;
}
