import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {SalesCluster, SubscriptionPlan} from 'lib/models';
// import {useLocalSort} from 'lib/filters';
import {useHistory, useLocation} from 'react-router';
import Moment from 'moment';
// actions
import {setState} from 'actions/app';
import {setSubscription} from 'actions/auth';
import {fetchItems, simplePost} from 'actions/shared';
// components
import SubscriptionDialog from 'components/modules/subscription/Dialog';
import SubscriptionBanner from 'components/modules/subscription/Banner';
import Calculation from 'components/modules/subscription/Calculation';
import TierPlan from 'components/modules/subscription/TierPlan';
// import Plan from 'components/modules/subscription/ListItem';
import Stats from 'components/modules/subscription/Stats';
import Delete from 'components/modules/subscription/Delete';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import CircularProgress from '@material-ui/core/CircularProgress';
// vectors
import ModelIcon from 'components/core/vectors/ModelIcon';
import SubmitIcon from '@material-ui/icons/ArrowForwardOutlined';


const useStyles = makeStyles(theme => ({
    // info about subscription usage and current plan
    stats: {
        // reduce size
        paddingTop: '0'
    },
    // holder of circular progresses
    loading: {
        position: 'relative',
        textAlign: 'center',
        '& > div:first-of-type': {
            position: 'absolute',
            zIndex: '10',
            opacity: '0.2'
        },
        '& > div:last-of-type': {
            position: 'relative',
            zIndex: '20'
        }
    },
    // holds subscription info & calculate cards
    gridHolder: {
        // next to each other
        display: 'grid',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        gridTemplateColumns: '1fr 2fr',
        gap: `${theme.spacing(2)}px`,
        // responsive
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr'
        },
        [theme.breakpoints.down('xs')]: {
            gap: `${theme.spacing(1)}px`
        }
    },
    // grid cardContent
    plansHolder: {
        // next to each other
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: `${theme.spacing(4)}px`,
        // increase spacing
        padding: `${theme.spacing(4)}px`
    }
}));

/**
 * Render view and manage subscription plan, invoices and payment methods
 */
export default function List(passed_props) {
    const classes = useStyles();
    // router
    const history = useHistory();
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const items_placement = useMemo(() => new SubscriptionPlan().getPlacement(), []);
    const uuid = useMemo(() => new SubscriptionPlan().getUniqueIdentifier(), []);
    const props = useSelector(state => {
        const subscription = state.auth.get('subscription');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.getIn(['user', 'links', 'company']));
        const salesClustersPlacement = new SalesCluster().getPlacement();
        const salesClusters = state.shared.getIn(['items', salesClustersPlacement]);
        const salesClustersLoaded = state.shared.getIn(['loaded', salesClustersPlacement]);
        let isFreeDm = false;
        if (company && salesClustersLoaded) {
            const cluster = salesClusters.find(item => item.get('countries').includes(company.get('country')));
            isFreeDm = !!cluster && cluster.get('free_dm');
        }

        return ({
            state: state.app.get('state'),
            company,
            subscription,
            loaded: state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]),
            subscriptionPlan: subscription && state.shared.getIn(['items', items_placement]).find(el => el.get(uuid) === subscription.get('plan_id')),
            isFreeDm
        });
    }, shallowEqual);
    // sorting
    // const [sortedItems,,,, sortInitializing] = useLocalSort(props.items, !props.loaded, null, ['limit'], 'limit');
    // local state
    const [selectedPlan, setSelectedPlan] = useState(null);

    /**
     * During initialization fetch items and handle session token from stripe gateway
     */
    useEffect(() => {
        // fetch, affect state only if we are not dealing with session token from stripe
        if (props.loaded === false) {
            dispatch(fetchItems(SubscriptionPlan, items_placement, props.company.getIn(['links', 'subscription-plans']), null, null, {
                affect_state: !searchParams.get('session_id')
            }));
        } else {
            // auto select first plan
            setSelectedPlan(props.items.first());
        }
    }, [props.loaded]);
    useEffect(() => {
        // handle session token from stripe
        if (searchParams.get('session_id')) {
            dispatch(simplePost('subscription', props.company.getIn(['links', 'subscription']), {
                session_id: searchParams.get('session_id')
            })).then(result => {
                // update subscription in store
                dispatch(setSubscription(result.data));
            }).finally(() => {
                // remove session_id from url
                history.replace({search: ''});
            });
        }
    }, [searchParams.get('session_id')]);

    /**
     * Submit currently selected plan into backend and redirect user to stripe gateway
     */
    const handleSubmit = useCallback(selectedPlan => {
        dispatch(simplePost('payment-session', props.company.getIn(['links', 'payment-session']), {
            success_url: window.location.href,
            cancel_url: window.location.href,
            price_id: selectedPlan.get(uuid)
        })).then(result => {
            // redirect user to stripe gateway
            if (result?.data?.payment_url) {
                window.location.href = result.data.payment_url;
            }
        });
    }, []);

    return <React.Fragment>
        <Card>
            <CardHeader
                title={<FormattedMessage id={`subscription.list.title${props.isFreeDm ? '.enable' : ''}`} />}
                subheader={props.isFreeDm ? null : <FormattedMessage id='subscription.list.subheader' />}
                action={passed_props.permission === 'RW'
                    ? ((props.isFreeDm && props.company.get('has_subscription')) || (props.subscription && (
                        !props.subscription.get('cancel_at_period_end') || ['posted_fetch_subscription', 'success_finished_subscription'].includes(props.state)
                    ))
                    ) ? <Delete isFreeDm={props.isFreeDm} />
                        : <ActionButton
                            variant='contained' color='secondary'
                            disabled={props.state !== null || !selectedPlan || (selectedPlan?.get(uuid) === props.subscriptionPlan?.get(uuid) && !props.subscription?.get('cancel_at_period_end'))}
                            onClick={() => handleSubmit(selectedPlan)}
                            loading={['posting_payment-session', 'posted_payment-session', 'failed_payment-session'].includes(props.state)}
                            success={props.state === 'posted_payment-session'}
                            failure={props.state === 'failed_payment-session'}
                            postAnimation={() => dispatch(setState(null))}>
                            <FormattedMessage id={`subscription.list.plans.${props.isFreeDm ? 'enable' : 'submit'}`} />
                            <SubmitIcon />
                        </ActionButton>
                    : <ActionButton iconButton disabled>
                        <ModelIcon model='subscription_plans' />
                    </ActionButton>}
            />
            <Stats
                isFreeDm={props.isFreeDm}
                subscriptionPlan={props.subscriptionPlan}
                subscription={props.subscription} company={props.company}
                show_progress={false} className={classes.stats}
            />
        </Card>
        {!props.isFreeDm && (!['posting_subscription', 'posted_subscription'].includes(props.state) && (['past_due', 'unpaid'].includes(props.subscription?.get('status')) || props.subscription?.get('cancel_at_period_end')))
            ? <React.Fragment>
                {['past_due', 'unpaid'].includes(props.subscription?.get('status')) && <ErrorMessage>
                    <FormattedMessage
                        id='subscription.list.due'
                        values={{
                            date_into: Moment(props.subscription.get('current_period_end')).fromNow(),
                            date: Moment(props.subscription.get('current_period_end')).format('l, LT')
                        }}
                    />
                </ErrorMessage>}
                {(props.subscription?.get('cancel_at_period_end') && Moment(props.subscription.get('current_period_end')).isAfter()) && <ErrorMessage warn>
                    <FormattedMessage
                        id='subscription.list.canceled'
                        values={{
                            date_into: Moment(props.subscription.get('current_period_end')).fromNow(),
                            date: Moment(props.subscription.get('current_period_end')).format('l, LT')
                        }}
                    />
                </ErrorMessage>}
            </React.Fragment>
            : <SpaceDivider grid />}
            <div className={classes.gridHolder}>
                {!props.company.get('has_subscription')
                    ? <SubscriptionBanner fetch_plans={false} noDeviceBanner />
                    : props.isFreeDm ? null : (props.subscriptionPlan && props.subscription)
                        ? <TierPlan
                            subscription={props.subscription}
                            subscriptionPlan={props.subscriptionPlan}
                            company={props.company}
                        /> : <Card>
                            <CardContent className={classes.loading}>
                                <CircularProgress thickness={0.5} size={300} variant='determinate' value={100} />
                                <CircularProgress thickness={0.5} size={300} />
                            </CardContent>
                        </Card>}
                {!!selectedPlan && !props.isFreeDm
                    ? <Calculation
                        subscription={props.subscription}
                        subscriptionPlan={selectedPlan}
                    />
                    : null}
            </div>
        {/* {false && <Card>
            {[`fetching_items_${items_placement}`, 'posting_subscription'].includes(props.state) || sortInitializing
                ? <CardContent className={classes.loading}>
                    <CircularProgress thickness={0.5} size={300} variant='determinate' value={100} />
                    <CircularProgress thickness={0.5} size={300} />
                </CardContent>
                : props.loaded && <CardContent className={classes.plansHolder}>
                {sortedItems.map(plan => <Plan
                    key={plan.get(uuid)}
                    plan={plan} subscriptionPlan={props.subscriptionPlan}
                    subscription={props.subscription} company={props.company}
                    selectedPlan={selectedPlan} setSelectedPlan={passed_props.permission === 'RW' ? setSelectedPlan : undefined}
                />)}
            </CardContent>}
        </Card>} */}
        <SubscriptionDialog />
    </React.Fragment>;
}
