import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    skeleton: {
        display: 'inline-block',
        verticalAlign: 'middle',
        position: 'relative',
        overflow: 'hidden',
        width: '50%',
        // style
        height: '1.2em',
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.grey[200],
        // wave
        '&:after': {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            content: '""',
            display: 'block',
            // style
            background: `linear-gradient(90deg, transparent, ${theme.palette.grey[100]}, transparent)`,
            // hide
            transform: 'translateX(-100%)',
            // wave animation
            animationName: '$wave',
            animationDuration: '1.6s',
            animationDelay: '0.5s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite'
        }
    },
    '@keyframes wave': {
        '0%': {
            transform: 'translateX(-100%)'
        },
        '60%': {
            transform: 'translateX(100%)'
        },
        '100%': {
            transform: 'translateX(100%)'
        }
    }
}));

/**
 * Placeholder preview serving as soft loader
 * Inspired by @material-ui/lab Skeleton
 */
export default function Skeleton(props) {
    const {className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const rootClasses = [
        classes.skeleton, className
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} {...rest_of_props} />;
}
