import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getCurrencySymbol} from 'lib/util';
// components
import {useTierInfo, useTierCalculation} from 'components/modules/subscription/TierLabel';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Link} from 'react-router-dom';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Typography from '@material-ui/core/Typography';
// vectors
import TierWave from 'components/core/vectors/waves/TierWave';
import Moment from 'moment/moment';


const useStyles = makeStyles(theme => ({
    // root
    card: {
        position: 'relative'
    },
    // container for CardHeader and CardContent
    contentHolder: {
        // above wave
        position: 'relative',
        zIndex: '20',
        // align
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'max-content auto'
    },
    cardContent: {
        textAlign: 'center',
        // v-align of content
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        // space for wave
        marginBottom: `${theme.spacing(16)}px`
    },
    // link to endpoints
    textLink: {
        // recolor to text
        '&.hover-border': {
            color: theme.palette.text.primary,
            '&:after': {
                background: theme.palette.text.primary
            }
        },
        '&.hover-border:hover': {
            color: props => props.tierColor,
            '&:after': {
                background: props => props.tierColor
            }
        }
    },
    // icon in cardHeader
    tierIconButton: {
        // get rid of disabled opacity
        opacity: '1 !important'
    },
    // svg holder
    waveHolder: {
        // at the bottom
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        // bellow content
        zIndex: '10'
    }
}));

/**
 * Renders information about current Tier in SubsciptionPlan
 */
export default function TierPlan(props) {
    const {subscription, subscriptionPlan, isFreeDm = false} = props;
    // locale state
    const [calculatedPrice,, tierIndex] = useTierCalculation(subscription?.get('dm_enabled_endpoints'), subscriptionPlan?.get('tiers'));
    const formattedCalculatedPrice = calculatedPrice.toFixed(2);
    const [TierIcon, color, color1, color2] = useTierInfo(tierIndex);
    const classes = useStyles({tierColor: color});

    return <Card className={classes.card}>
        <div className={classes.contentHolder}>
            <CardHeader
                title={<FormattedMessage id='subscription.tier.overview.title' />}
                subheader={!isFreeDm ? <FormattedMessage
                    id='subscription.tier.overview.subheader'
                    values={{
                        start_date: <span className='nowrap'>{subscription && Moment(subscription.get('current_period_start')).format('ll')}</span>,
                        end_date: <span className='nowrap'>{subscription && Moment(subscription.get('current_period_end')).format('ll')}</span>
                    }}
                /> : null}
                action={<ActionButton iconButton disabled className={classes.tierIconButton}>
                    <TierIcon style={{color: color}} />
                </ActionButton>}
            />
            <CardContent className={classes.cardContent}>
                <div>
                    <Typography className='newfont' variant='h6'>
                        <FormattedMessage id='subscription.tier.overview.devices' />
                    </Typography>
                    {!isFreeDm && <Typography className='newfont' variant='h2'>
                        <Link to='/phones?dm_enabled=true' className={`${classes.textLink} hover-border`}>
                            <strong>{subscription?.get('dm_enabled_endpoints') || 0}</strong>
                            {(subscription?.get('trial_active') && subscriptionPlan?.get('trial_limit')) && <Typography
                                className='newfont' variant='subtitle2' component='span'>
                                {`/${subscriptionPlan?.get('trial_limit')}`}
                            </Typography>}
                        </Link>
                    </Typography>}
                    <SpaceDivider />
                    {!isFreeDm && <Typography className='newfont' variant='h5' style={{color: color}}>
                        <strong><FormattedMessage id={`subscription.tier.label.${subscription?.get('trial_active') ? 'trial' : tierIndex}`} /></strong>
                    </Typography>}
                    <SpaceDivider />
                    {!isFreeDm && subscription?.get('trial_active') && <React.Fragment>
                        <p className='newfont nomargin'><strong><FormattedMessage id='subscription.tier.overview.paying.in_trial' /></strong></p>
                        <Typography className='newfont' variant='h4'>
                            {`0 ${getCurrencySymbol(subscriptionPlan?.get('currency'))}`}
                        </Typography>
                        <SpaceDivider />
                    </React.Fragment>}
                   {!isFreeDm ? <Typography className='newfont' variant='subtitle2'>
                        <FormattedMessage
                            id={`subscription.tier.overview.paying${subscription?.get('trial_active') ? '.trial' : ''}`}
                            values={{
                                interval: <FormattedMessage id={`subscription.list.plans.price.${subscriptionPlan?.get('interval')}`} />,
                                price: formattedCalculatedPrice,
                                currency: getCurrencySymbol(subscriptionPlan?.get('currency'))
                            }}
                        />
                    </Typography> :  null}
                    {!isFreeDm && !subscription?.get('trial_active') && <Typography className='newfont' variant='h5'>
                        {`${formattedCalculatedPrice} ${getCurrencySymbol(subscriptionPlan?.get('currency'))}`}
                    </Typography>}
                </div>
            </CardContent>
        </div>
        <div className={classes.waveHolder}>
            <TierWave className={classes.wave} color1={color1} color2={color2} />
        </div>
    </Card>;
}
