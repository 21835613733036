import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {
        // limit size
        maxWidth: '320px',
        // smooth
        transition: theme.transitions.create(['opacity', 'color', 'background'],
            {duration: theme.transitions.duration.short}),
        // reduce font size
        fontSize: theme.typography.pxToRem(13),
        // variants
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            '&.soft': {
                cursor: 'default',
                // default opacity for not selected tab
                opacity: '0.7',
                '&.Mui-selected': {
                    opacity: '1'
                }
            }
        },
        '&.error': {
            color: theme.palette.common.white,
            background: theme.palette.danger['main'],
            opacity: '1'
        },
        '& .MuiTab-wrapper': {
            '& .subtitle': {
                width: '100%',
                color: theme.palette.text.secondary,
                textTransform: 'none',
                fontSize: theme.typography.pxToRem(11)
            }
        }
    }
}));

/**
 * Extends Tab component with additional props
 */
export default function Tab(props) {
    // Split some stuff from props
    const {_classes, className, disabled, softDisabled, error, label, subtitle, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.root, root, className,
        softDisabled ? 'soft' : '',
        error? 'error' : ''
    ].filter(Boolean).join(' ');

    return <MUITab
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        label={subtitle ? <>{label}<Typography className='subtitle' variant='caption'>{subtitle}</Typography></> : label}
        disabled={disabled || softDisabled}
        {...rest_of_props}
    />;
}
