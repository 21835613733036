import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Finland flag
 */
export default function FiFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <path fill='#ffffff' d='M0 0h640v480H0z' />
        <path fill='#003580' d='M0 174.5h640v131H0z' />
        <path fill='#003580' d='M175.5 0h130.9v480h-131z' />
    </SvgIcon>;
}
