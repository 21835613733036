'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
import DropZone from 'react-dropzone';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
// icons
import UploadIcon from '@material-ui/icons/FindReplace';


const styles = theme => ({
    fieldHolder: {
        // size from Field.js
        padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        width: `${(320 * 2) + (theme.spacing(2))}px`, // double the size
        maxWidth: '100%'
    },
    // field itself
    textArea: {
        '&.textarea': {
            margin: '0'
        },
        // input
        '& textarea': {
            // change font to nicely display certificate
            fontFamily: 'monospace'
        }
    }
});

/**
 * Redux Field for Certificates - TextArea + Dropzone to read file
 */
class CertificateField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            state: null, // local state of component
            // MIME types for certificates
            accept: [
                'application/pkcs8',
                'application/pkcs10',
                'application/pkix-cert',
                'application/pkix-crl',
                'application/pkcs7-mime',
                'application/x-x509-ca-cert',
                'application/x-x509-user-cert',
                'application/x-pkcs7-crl',
                'application/x-pem-file',
                'application/x-pkcs12',
                'application/x-pkcs7-certificates',
                'application/x-pkcs7-certreqresp',
                'text/plain',
                'application/rtf'
            ]
        };
    }

    /**
     * Handle File 'upload' - reads pasted File and Convert it to TextArea
     *
     * @param file - File blop from DropZone
     */
    handleFile(file) {
        this.setState({state: 'reading_file'});

        let reader = new FileReader();
        reader.onabort = () => this.setState({state: 'bad_file'});
        reader.onerror = () => this.setState({state: 'bad_file'});
        reader.onload = () => {
            let result = reader.result;
            // remove last blank line
            result = result.replace(/^\s+|\s+$/g, '');
            // result to textarea field
            this.props.change(this.props.name, result);
            // show success
            this.setState({state: 'good_file'});
        };

        if (this.state.accept.includes(file.type) || !file.type) { // File check could be improved
            reader.readAsText(file);
        } else {
            this.setState({state: 'bad_file'});
        }
    }

    render() {
        // Split some stuff from props
        let {classes, intl, permissions, permission, change, helperText, ...rest_of_props} = this.props;

        return <div className={classes.fieldHolder}>
            <Field fieldType='TextArea' {...rest_of_props}
                   helperText={helperText || <FormattedMessage id='certificatefield.help' values={{button_text: <FormattedMessage id='certificatefield.upload' />}} />}
                   className={classes.textArea} />
            <SpaceDivider />
            {(!rest_of_props.excludePermission && permissions.get(permission) === 'R') || rest_of_props.disabled
                ? <div>
                <Button disabled={true} variant='outlined' color='primary'>
                    <UploadIcon />
                    <FormattedMessage id='certificatefield.upload' />
                </Button>
            </div>
                : <DropZone multiple={false}
                            onDropAccepted={files => this.handleFile(files[0])}
                            onDropRejected={() => this.setState({state: 'bad_file'})}
                            accept={Object.fromEntries(this.state.accept.map(t => [t, []]))}>
                {({getRootProps, getInputProps}) => <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button variant='outlined' color='primary'
                            loading={['reading_file', 'good_file', 'bad_file'].includes(this.state.state)}
                            success={this.state.state === 'good_file'}
                            failure={this.state.state === 'bad_file'}
                            postAnimation={() => this.setState({state: null})}>
                        <UploadIcon />
                        <FormattedMessage id='certificatefield.upload' />
                    </Button>
                </div>}
            </DropZone>}
        </div>;
    }
}

CertificateField = withStyles(styles)(CertificateField);
CertificateField = connect((state, props) => ({
    permissions: state.auth.get('permissions'),
    permission: state.auth.get('permission')
}))(CertificateField);
export default injectIntl(CertificateField);
