import React, {useState} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFormValues, reduxForm} from 'redux-form';
import validator from 'lib/valitator';
import {User, Company} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {saveItem, markFiltered} from 'actions/shared';
// Components
import ToSDialog from 'components/core/ui/ToSDialog';
import {Link, withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CheckIcon from '@material-ui/icons/CheckOutlined';
import BackIcon from '@material-ui/icons/UndoOutlined';


/**
 * Initial welcome dialog when entering SRAPS / Partner Portal for the first time
 */
function InitialDialog(props) {
    // local state
    const [tosDialogOpen, setTosDialogOpen] = useState(false);

    return <React.Fragment>
        <Dialog open={true} onClose={() => {}}
                fullWidth maxWidth='md'>
            <DialogTitle>
                <FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.title`} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText><FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.text.1`} /></DialogContentText>
                <DialogContentText><FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.text.2`} /></DialogContentText>
                <DialogContentText>
                    <FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.text.3`}
                                      values={props.portal ? {} : {link: <a href='https://helpdesk.snom.com/' className='hover-border' target='_blank' rel='noreferrer'>helpdesk.snom.com</a>}} />
                    {!props.portal && <React.Fragment>
                        <br />
                        <FormattedMessage id='initialdialog.text.4'
                                          values={{email: <a href='mailto:redirection@snom.com' className='hover-border'>redirection@snom.com</a>}} />
                    </React.Fragment>}
                </DialogContentText>
            </DialogContent>
            <SpaceDivider />
            <DialogContent>
                <Form onSubmit={props.handleSubmit}>
                    <Field name='tos' fieldType='Checkbox'
                           size='full' className='flip'
                           label={props.portal
                               ? <React.Fragment>
                                   <FormattedMessage id='tosfield.portal.description'
                                                     values={{
                                                         toc_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.toc.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.toc' />
                                                         </a>,
                                                         pp_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.pp.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.pp' />
                                                         </a>
                                                     }} />
                                   {'*'}
                               </React.Fragment>
                               : <React.Fragment>
                                   <FormattedMessage id='tosfield.description'
                                                     values={{link: <a href='#' className='hover-border'
                                                                       onClick={(e) => { e.preventDefault(); setTosDialogOpen(true); }}>
                                                             <FormattedMessage id='tosfield.description.link' />
                                                         </a>}} />
                                   {'*'}
                               </React.Fragment>
                           } />
                </Form>
                {props.submitFailed && <ErrorMessage>
                    <FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.error`}
                                      values={{user_link: <Link to={`${props.portal ? '' : '/partner'}/profile`} className='hover-border white'>
                                              <FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.error.link`} />
                                          </Link>}} />
                </ErrorMessage>}
            </DialogContent>
            {['saving_item_users', 'saved_item_users', 'failed_save_item_users'].includes(props.state)
                ? <CardActionsLoader dialogActions
                                     failure={props.state === 'failed_save_item_users'}
                                     success={props.state === 'saved_item_users'}
                                     postAnimation={(success) => !success ? props.setState(null) : {}} />
                : <DialogActions>
                    {((props.user.get('pp_tos_accepted') && !props.portal) || (props.user.get('sraps_tos_accepted') && props.portal)) && <Button
                        onClick={() => props.history.push(`${props.portal ? '' : '/partner'}/home`)}>
                        <BackIcon />
                        <FormattedMessage id='actions.back' />
                    </Button>}
                    <Button variant='contained' color='primary' type='submit'
                            disabled={props.state !== null || !props.formValues || props.formValues.tos !== true}
                            onClick={props.handleSubmit}>
                        <CheckIcon />
                        <FormattedMessage id={`initialdialog${props.portal ? '.portal' : ''}.submit`} />
                    </Button>
                </DialogActions>
            }
        </Dialog>
        <ToSDialog open={tosDialogOpen} handleClose={() => setTosDialogOpen(false)} />
    </React.Fragment>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isTrue(
        props.intl.formatMessage({id: `tosfield.${props.portal ? 'portal.' : ''}error.required`}),
        errors, 'tos', data.tos
    );

    return errors;
};

const InitialDialogForm = reduxForm({
    form: 'initialDialog',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // set tos into proper value
        const data = props.portal ? {pp_tos_accepted: true} : {sraps_tos_accepted: true};

        // same save as in users.Detail, patch only with updated value
        return dispatch(saveItem(User, 'users', props.user.getIn(['links', 'self']), data, props.user)).then(() => {
            // mark not updated lists for reload
            dispatch(markFiltered(User, `companyUsers-${props.company.get(new Company().getUniqueIdentifier())}`));
            // dialog will close on itself, but we must clear the state from saved (success postAction is too slow)
            dispatch(setState(null));
        });
    }
})(InitialDialog);

const ConnectedInitialDialog = connect((state, props) => ({
    portal: props.location.pathname.startsWith('/partner'),
    state: state.app.get('state'),
    user: state.auth.get('user'),
    company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
    initialValues: {
        tos: false
    },
    formValues: getFormValues('initialDialog')(state)
}), (dispatch) => bindActionCreators({
    saveItem,
    setState,
    markFiltered
}, dispatch))(InitialDialogForm);

export default injectIntl(withRouter(ConnectedInitialDialog));
