'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl} from 'react-intl';
// Components
import {EnhancedReduxField} from 'components/core/ui/Field';
import {Select} from 'redux-form-material-ui/es/index';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
    formControl: {
        // size as iconButton
        width: '48px',
        margin: '0',
        // input root
        '& > div': {
            // as icon-button
            borderRadius: '50%',
            // get rid of above and bellow border
            '&:after, &:before': {
                content: 'none'
            }
        }
    },
    selectRoot: {
        // as icon-button
        borderRadius: '50%'
    },
    select: {
        '&, &:focus': {
            // as icon-button
            borderRadius: '50%',
            padding: '0',
            backgroundColor: 'transparent'
        }
    },
    // dropdown icon
    selectIcon: {
        display: 'none'
    },
    // error and help text
    formHelperText: {
        textAlign: 'center'
    },
    // text label which acts like a icon
    iconButtonLabel: {
        // same as icon
        height: '24px',
        width: '24px',
        lineHeight: '24px',
        // nice fit fot 2 letters
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
        // incorrect value
        '&.error': {
            color: theme.palette.danger[500]
        }
    }
});

const _renderIconField = ({input, classes, meta: {touched, error}, children, name, open, onOpen, onClose, renderValue, helperText, ...rest_of_props}) => {
    return <FormControl className={classes.formControl} error={Boolean(touched && error)}>
        <Select name={name}
                open={open} onOpen={onOpen} onClose={onClose}
                displayEmpty={true}
                renderValue={(value) => renderValue(value, error)}
                classes={{
                    root: classes.selectRoot,
                    select: classes.select,
                    icon: classes.selectIcon
                }}
                {...rest_of_props}
                input={input}>
            {children}
        </Select>
        {touched && error
            ? <FormHelperText classes={{root: classes.formHelperText}}>{error}</FormHelperText>
            : helperText && <FormHelperText classes={{root: classes.formHelperText}}>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Redux Field Select with Icon Button instead of classic field
 */
class IconButtonSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    };

    render() {
        // Split some stuff from props
        let {classes, intl, permissions, permission, intlPath, label, emptyIcon, ...rest_of_props} = this.props;

        return <EnhancedReduxField
            open={this.state.open} onOpen={() => this.setState({open: true})} onClose={() => this.setState({open: false})}
            renderValue={(value, error) => (!rest_of_props.excludePermission && permissions.get(permission) === 'R') || rest_of_props.disabled
                ? <IconButton disabled={true} classes={{label: `${classes.iconButtonLabel}${error ? ' error' : ''}`}}>
                {value ? intl.formatMessage({id: `${intlPath}.${value.toLowerCase()}`}) : emptyIcon}
            </IconButton>
                : <Tooltip title={label}>
                <IconButton classes={{label: `${classes.iconButtonLabel}${error ? ' error' : ''}`}}>
                    {value ? intl.formatMessage({id: `${intlPath}.${value.toLowerCase()}`}) : emptyIcon}
                </IconButton>
            </Tooltip>}
            classes={classes}
            component={_renderIconField}
            {...rest_of_props} />;
    }
}

IconButtonSelect = withStyles(styles)(IconButtonSelect);
IconButtonSelect = connect((state, props) => ({
    permissions: state.auth.get('permissions'),
    permission: state.auth.get('permission')
}))(IconButtonSelect);
export default injectIntl(IconButtonSelect);
