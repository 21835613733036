import React, {useEffect, useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Company, SalesCluster, Subscription, SubscriptionPlan} from 'lib/models';
import Moment from 'moment/moment';
// actions
import {fetchItems, fetchItem} from 'actions/shared';
// components
import Stats from 'components/modules/subscription/Stats';
import TierPlan from 'components/modules/subscription/TierPlan';
// material-ui
import {makeStyles} from '@material-ui/core/styles';
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import Button from 'components/core/ui/mui/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import SubscriptionBannerWave from 'components/core/vectors/waves/SubscriptionBanner';
import DeviceBannerWave from 'components/core/vectors/waves/DeviceBanner';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import useFeatureFlag from 'lib/featureFlag';


const useStyles = makeStyles(theme => ({
    card: {
        position: 'relative'
    },
    contentHolder: {
        // above wave
        position: 'relative',
        zIndex: '20'
    },
    cardContent: {
        // 'no plan' description
        '&.subscription.simple .description': {
            marginBottom: `${theme.spacing(24)}px`,
            [theme.breakpoints.down('sm')]: {
                marginBottom: `${theme.spacing(14)}px`
            }
        },
        // device banner
        '&.device': {
            display: 'grid',
            justifyContent: 'start',
            alignItems: 'center',
            gridTemplateColumns: `${theme.spacing(53)}px auto`,
            [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: '1fr'
            },
            // img holder
            '& > div:first-of-type': {
                textAlign: 'center'
            }
        },
        // change title proportion
        '& h4': {
            margin: `0 0 ${theme.spacing(2)}px`
        }
    },
    // banner's current plan
    plan: {
        // reduce card content bottom padding
        '& > div:last-child': {
            paddingBottom: `${theme.spacing(1)}px`
        },
        // move button bellow into border
        '& ~ $buttonHolder': {
            marginTop: `-${theme.spacing(2.5)}px`
        }
    },
    bannerTitle: {
        '& > span': {
            fontWeight: 'bold',
            // special gradient text
            backgroundImage: `linear-gradient(135deg, ${theme.palette.primary[500]} 0%, ${theme.gradients.wild.color2} 50%, ${theme.gradients.wild.color3} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        }
    },
    // feature list with icons
    list: {
        '& 	.MuiListItemIcon-root': {
            minWidth: `${theme.spacing(4)}px`
        },
        '& 	.MuiListItemText-root': {
            '& > .MuiTypography-root': {
                fontSize: 'inherit',
                fontWeight: '500'
            }
        }
    },
    // button with link to subscription page
    buttonHolder: {
        // above wave
        position: 'relative',
        zIndex: '20',
        // position
        textAlign: 'right',
        marginTop: `${theme.spacing(2)}px`,
        marginRight: `${theme.spacing(2)}px`
    },
    // banner wave at the bottom
    waveHolder: {
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        // bellow content
        zIndex: '10'
    }
}));

/**
 * Render banner with plan information or promotion of D8xx series
 */
export default function Banner(passed_props) {
    const {company, simple = false, fetch_plans = true, noDeviceBanner = false, cardheader, cardContentClassName} = passed_props;
    const classes = useStyles();
    const intl = useIntl();
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const items_placement = useMemo(() => `${new SubscriptionPlan().getPlacement()}${company ? `-${company.get(new Company().getUniqueIdentifier())}` : ''}`, []);
    const props = useSelector(state => {
        const subscription = company
            ? state.shared.getIn(['items', 'subscriptions-global']).find(el => el.getIn(['links', 'self']) === company.getIn(['links', 'subscription']))
            : state.auth.get('subscription');

        const currentCompany = company ? company : state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

        const salesClustersPlacement = new SalesCluster().getPlacement();
        const salesClusters = state.shared.getIn(['items', salesClustersPlacement]);
        const salesClustersLoaded = state.shared.getIn(['loaded', salesClustersPlacement]);
        let isFreeDm = false;
        if (currentCompany && salesClustersLoaded) {
            const cluster = salesClusters.find(item => item.get('countries').includes(currentCompany.get('country')));
            isFreeDm = !!cluster && cluster.get('free_dm');
        }
    
        return {
            user: state.auth.get('user'),
            company: currentCompany,
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            subscription: subscription,
            subscriptionPlan: subscription && state.shared.getIn(['items', items_placement])?.find(el => el.get(new SubscriptionPlan().getUniqueIdentifier()) === subscription.get('plan_id')),
            isFreeDm
        };
    }, shallowEqual);
    // feature flags
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company: (company || props.company).get(new Company().getUniqueIdentifier())});
    // banner variant decision
    const variant = useMemo(() => {
        return noDeviceBanner || (featureFlag_deviceManager && (props.company.get('dm_compatible_endpoints') || props.company.get('has_subscription') || simple))
            ? 'subscription' : 'device';
    }, [simple, props.company.get('dm_compatible_endpoints'), props.company.get('has_subscription'), props.user.isAdmin()]);

    /**
     * During initialization fetch SubscriptionPlans without affecting state
     */
    useEffect(() => {
        if (variant === 'subscription' && props.company && props.loaded === false && featureFlag_deviceManager && fetch_plans) {
            dispatch(fetchItems(SubscriptionPlan, items_placement, props.company.getIn(['links', 'subscription-plans']), null, null, {affect_state: false}));
        }
    }, [variant, props.company.get(new Company().getUniqueIdentifier()), props.loaded, fetch_plans]);
    // check for subscription
    useEffect(() => {
        if (variant === 'subscription' && props.company && props.company.get('has_subscription') && !props.subscription && featureFlag_deviceManager) {
            dispatch(fetchItem(Subscription, 'subscriptions-global', props.company.getIn(['links', 'subscription']), {affect_state: null}));
        }
    }, [variant, props.company.get(new Company().getUniqueIdentifier())]);

    return <Card className={classes.card}>
        <div className={classes.contentHolder}>
            {cardheader && cardheader}
            <CardContent className={`${classes.cardContent} ${cardContentClassName} ${variant}${simple ? ' simple' : ''}`}>
                {variant === 'device' && <div>
                    <img src={require('img/sraps/snom_d865_device.png')} alt={intl.formatMessage({id: 'subscription.device_banner.image.alt'})} />
                </div>}
                {variant === 'subscription' && !props.loaded
                    ? <CircularProgress thickness={0.5} size={200} />
                    : <div>
                        {!cardheader && <h4 className={`newfont ${!props.subscriptionPlan ? classes.bannerTitle : ''}`}>
                            <FormattedMessage id={`subscription.${variant === 'device' ? 'device_' : ''}banner.title${!props.subscriptionPlan && variant !== 'device' ? '.no' : ''}`} />
                        </h4>}
                        {(variant === 'subscription' && props.subscriptionPlan) && (simple
                            ? <TierPlan
                                subscriptionPlan={props.subscriptionPlan}
                                subscription={props.subscription}
                            />
                            : <Card className={classes.plan}>
                                <Stats
                                    subscriptionPlan={props.subscriptionPlan}
                                    subscription={props.subscription} company={props.company}
                                />
                            </Card>)}
                        {(variant === 'device' || !props.subscriptionPlan) && <React.Fragment>
                            <p className='description newfont'>
                                <FormattedMessage
                                    id={`subscription.${variant === 'device' ? 'device_' : ''}banner.description${simple ? '.no' : ''}`}
                                    values={{
                                        link: <Link to='/subscription' className='hover-border'><FormattedMessage id='subscription.banner.description.link' /></Link>
                                    }}
                                />
                            </p>
                            {!simple && <List className={`${classes.list} newfont`}>
                                {[...Array(3)].map((_, idx) => (
                                    <ListItem key={idx}>
                                        <ListItemIcon>
                                            <CheckIcon color='secondary' />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <FormattedMessage id={`subscription.${variant === 'device' ? 'device_' : ''}banner.list.feature_${idx + 1}`} />
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>}
                        </React.Fragment>}
                        {props.isFreeDm && props.company.get('has_subscription') ? null : (
                            ((!simple && pathname !== '/subscription')) && <div className={classes.buttonHolder}>
                            <Button
                                variant='outlined' color='primary' endIcon={<ArrowForwardIcon />}
                                {...(variant === 'device'
                                    ? {
                                        component: 'a', target: '_blank', rel: 'noreferrer',
                                        href: `https://snom.com/${Moment.locale() === 'de' ? 'produkte/tischtelefone/d8xx/' : 'en/products/desk-phones/d8xx/'}`
                                    } : {
                                        onClick: () => history.push('/subscription')
                                    })
                                }
                            >
                                {props.isFreeDm ? <FormattedMessage id='subscription.banner.enable.button' /> : <FormattedMessage id={variant === 'device' ? 'subscription.device_banner.link' : `subscription.banner.${props.subscriptionPlan ? 'current.' : ''}button`} />}
                            </Button>
                            </div>
                        )}
                    </div>}
            </CardContent>
        </div>
        <div className={classes.waveHolder}>
            {variant === 'subscription'
                ? (!simple || !props.subscriptionPlan) && <SubscriptionBannerWave />
                : <DeviceBannerWave />}
        </div>
    </Card>;
}
