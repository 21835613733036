import {FormattedMessage} from 'react-intl';
// Material UI
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from 'components/core/ui/mui/Button';
// Icons
import CancelIcon from '@material-ui/icons/UndoOutlined';

/**
 * Dialog for showing PhoneLink migration errors.
 */
function PhoneLinkMigrationErrors(props) {
    const errors = Object.fromEntries(props.errors);
    const endpointUUIDs = Object.keys(errors);

    return <>
        <DialogTitle>
            <FormattedMessage id='phone_link.list.dialog.migration_errors'/>
        </DialogTitle>
        <DialogContent>
            {/* TODO: Replace this with a proper table. */}
            {endpointUUIDs.map((uuid, idx) => <div key={idx}>
                <p><strong>{uuid}</strong></p>
                <p>
                    <code>{errors[uuid]}</code>
                </p>
                <hr/>
            </div>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>
                <CancelIcon/>
                <FormattedMessage id='actions.close'/>
            </Button>
        </DialogActions>
    </>;
}

export default PhoneLinkMigrationErrors;
