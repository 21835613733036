import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';


const useStyles = makeStyles(theme => ({
    // simple 'flag' on the right side
    flag: {
        display: 'block', // allow placing in SubHeaders and such with <span /> instead of <div />
        // position
        position: 'absolute',
        right: '0',
        top: '0',
        // proportion
        padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
        // style
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        ...theme.typography.button,
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.white,
        userSelect: 'none',
        '&.registered': {
            backgroundColor: theme.partnerLevel['registered'],
            backgroundImage: theme.partnerLevel.gradients['registered']
        },
        '&.silver': {
            backgroundColor: theme.partnerLevel['silver'],
            backgroundImage: theme.partnerLevel.gradients['silver']
        },
        '&.gold': {
            backgroundColor: theme.partnerLevel['gold'],
            backgroundImage: theme.partnerLevel.gradients['gold']
        }
    },
    // holder of colorful image 'box' with Partner Level
    imageBlockHolder: {
        // limit size (max image size + padding + imageBlockBorder)
        width: `${250 + (theme.spacing(4) * 2) + 2}px`,
        maxWidth: '100%',
        padding: `${theme.spacing(4)}px`,
        // center
        margin: '0 auto'
    },
    imageBlockBorder: {
        border: `1px solid ${theme.palette.divider}`
    },
    imageBlock: {
        // 1:1 ratio
        paddingTop: '100%',
        width: '100%',
        position: 'relative',
        background: theme.palette.grey[500],
        // image itself
        '& img': {
            display: 'block',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            width: '100%',
            height: '100%'
        }
    }
}));

/**
 * Small indicator informing user about his company Partner Level
 */
export default function PartnerLevelIndicator(props) {
    const intl = useIntl();
    // Split some stuff from props
    const {_classes, className, partnerLevel, noYear, variant, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let {root} = _classes || {};
    let rootClasses = [
        variant === 'block' ? classes.imageBlockHolder : classes.flag,
        root, className,
        partnerLevel
    ].filter(Boolean).join(' ');

    switch (variant) {
        case 'block':
            return <div className={rootClasses}>
                <div className={classes.imageBlockBorder}>
                    <div className={classes.imageBlock}>
                        {['registered', 'silver', 'gold'].includes(partnerLevel) && <img
                            alt={intl.formatMessage({id: `companypartnerlevelfield.choice.${partnerLevel}`})}
                            src={partnerLevel === 'registered' ? noYear ? require('img/portal/partners/registered.jpg') : require('img/portal/partners/registered_year.jpg')
                                : partnerLevel === 'silver' ? noYear ? require('img/portal/partners/silver.jpg') : require('img/portal/partners/silver_year.jpg')
                                : partnerLevel === 'gold' ? noYear ? require('img/portal/partners/gold.jpg') : require('img/portal/partners/gold_year.jpg')
                                : ''}
                        />}
                    </div>
                </div>
            </div>;
        default:
            return <Tooltip title={<FormattedMessage id='companypartnerlevelfield.label' />}>
                <span className={rootClasses} {...rest_of_props}>
                    <FormattedMessage id={`companypartnerlevelfield.choice.${partnerLevel}`} />
                </span>
            </Tooltip>;
    }
}
