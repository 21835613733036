import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {makeStyles} from '@material-ui/core/styles';
import {List as ImmutableList} from 'immutable';
import {Endpoint, ProvisioningProfile, TicketScheduler} from 'lib/models';
// Actions
import {deleteItem, fetchItems} from 'actions/shared';
// Components
import Detail from 'components/modules/ticketschedulers/Detail';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import Filters from 'components/core/ui/Filters';
import {TypeIcon} from 'components/core/ui/fields/TicketTypeField';
import {SearchField} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import Tooltip from 'components/core/ui/mui/Tooltip';
import ThemeProvider from 'components/ThemeProvider';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
// icons
import MenuIcon from '@material-ui/icons/MoreVertOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import ActiveIcon from '@material-ui/icons/PlayArrowOutlined';
import InActiveIcon from '@material-ui/icons/PauseOutlined';



const useStyles = makeStyles(theme => ({
    detailDialog: {
        // increase size for nice fit of two buttons (+ scrollbar)
        width: '720px',
        maxWidth: '100%'
    }
}));

/**
 * Renders Ticket Schedulers from backend - to view and manage them
 */
export default function List(passed_props) {
    // split some stuff from props
    const {permission, item, ticket_group = false} = passed_props;
    const classes = useStyles();
    const intl = useIntl();
    // redux store
    const dispatch = useDispatch();
    const items_placement = `${new TicketScheduler().getPlacement()}-${ticket_group
        ? item.get(new ProvisioningProfile().getUniqueIdentifier())
        : item.get(new Endpoint().getUniqueIdentifier())}`;
    const props = useSelector(state => ({
        state: state.app.get('state'),
        state_identifier: state.app.get('identifier'),
        loaded: !!state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement]) || ImmutableList()
    }), shallowEqual);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='ticketschedulers.list.table.name' />},
        {sortName: 'type', label: <FormattedMessage id='ticketschedulers.list.table.type' />},
        {sortName: 'status', label: <FormattedMessage id='ticketschedulers.list.table.status' />},
        {label: <FormattedMessage id='provfiles.list.table.actions' />, numeric: true}
    ];
    // local state
    const [advancedMenuItem, setAdvancedMenuItem] = useState(null);
    const [advancedMenuAnchor, setAdvancedMenuAnchor] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailDialogItem, setDetailDialogItem] = useState(null);
    const [deleteDialogItem, setDeleteDialogItem] = useState(null);
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new TicketScheduler().getPlacement(), tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new TicketScheduler().getPlacement());

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(TicketScheduler, items_placement, item.getIn(['links', ticket_group ? 'ticket_group-schedulers' : 'ticket-scheduler']),
                null, null, {affect_state: false}));
        }
    }, [props.loaded]);

    return <React.Fragment>
        {permission === 'RW' && <React.Fragment>
            <div className='text-right'>
                <ActionButton variant='contained' color='secondary'
                              onClick={() => {
                                  setDetailDialogItem(null);
                                  setDetailDialogOpen(true);
                              }}>
                    <AddIcon />
                    <FormattedMessage id='ticketschedulers.list.create' />
                </ActionButton>
            </div>
            <SpaceDivider />
        </React.Fragment>}
        <Filters>
            <SearchField label={<FormattedMessage id='ticketschedulers.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
        </Filters>
        <Table size={tableHead.length}>
            <TableHead>
                <TableRow>
                    {tableHead.map((item, idx) =>
                        <TableCell numeric={item.numeric} key={idx}>
                            {item.sortName
                                ? <TableSortLabel active={sortName === item.sortName}
                                                  direction={sortDirection}
                                                  onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                                : item.label}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                <TableRow>
                    <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                </TableRow>
            </TableBody>
                : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                ? <TableBody>
                <TableRow>
                    <TableCell colSpan={tableHead.length}>
                        <FormattedMessage id={props.items.size ? 'filters.empty' : 'ticketschedulers.list.table.empty'} />
                    </TableCell>
                </TableRow>
            </TableBody>
                : <TableBody>
                {props.loaded && filteredItems.map((scheduler, idx) => {
                    const deleting = props.state_identifier === scheduler.get(new TicketScheduler().getUniqueIdentifier()) && props.state === `deleting_item_${items_placement}`;
                    const onClick = props.state === null && !deleting ? () => {
                        setDetailDialogItem(scheduler);
                        setDetailDialogOpen(true);
                    } : () => {};

                    return <TableRow key={idx} hover={!deleting}>
                        <TableCell link={!deleting} onClick={onClick}>
                            {scheduler.get('name') || '---'}
                        </TableCell>
                        <TableCell name link={!deleting} onClick={onClick}>
                            <TypeIcon identifier={scheduler.get('type')} className='icon' />
                            <span className='text'><FormattedMessage id={`tickettypefield.choices.${scheduler.get('type')}`} /></span>
                        </TableCell>
                        <TableCell name link={!deleting} onClick={onClick}>
                            <ThemeProvider alt>
                                {scheduler.get('status') === 'active' ? <ActiveIcon color='primary' className='icon' /> : <InActiveIcon color='secondary' className='icon' />}
                                <strong className='text'><FormattedMessage id={`ticketschedulers.list.table.status.${scheduler.get('status')}`} /></strong>
                            </ThemeProvider>
                        </TableCell>
                        <TableCell numeric>
                            <Tooltip title={<FormattedMessage id='actions.more' />} disabled={permission !== 'RW' || props.state !== null}>
                                <IconButton disabled={permission !== 'RW' || props.state !== null}
                                            onClick={(event) => {
                                                setAdvancedMenuAnchor(event.currentTarget);
                                                setAdvancedMenuItem(scheduler);
                                            }}>
                                    <MenuIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>}
        </Table>
        {permission === 'RW' && <React.Fragment>
            <ThemeProvider alt>
                <Menu open={!!advancedMenuItem} anchorEl={advancedMenuAnchor}
                      onClose={() => setAdvancedMenuItem(null)}>
                    <MenuItem onClick={() => {
                        setDeleteDialogItem(advancedMenuItem);
                        setAdvancedMenuItem(null);
                    }}>
                        <ListItemIcon className='icon'><DeleteIcon color='secondary' /></ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id='actions.delete' /></ListItemText>
                    </MenuItem>
                </Menu>
            </ThemeProvider>
            <DeleteDialog
                item={intl.formatMessage({id: 'ticketschedulers.list.deletedialog.item'})}
                items={intl.formatMessage({id: 'ticketschedulers.list.deletedialog.items'})}
                open={!!deleteDialogItem}
                handleClose={() => setDeleteDialogItem(null)}
                handleConfirm={() => {
                    setDeleteDialogItem(null);
                    dispatch(deleteItem(TicketScheduler, items_placement, deleteDialogItem.getIn(['links', 'self']), deleteDialogItem));
                }}
            />
        </React.Fragment>}
        <Dialog open={detailDialogOpen} onClose={() => setDetailDialogOpen(false)}
                fullWidth maxWidth='md' classes={{paper: classes.detailDialog}}>
            <Detail item={detailDialogItem} related_item={item} ticket_group={ticket_group}
                    close={() => setDetailDialogOpen(false)} />
        </Dialog>
    </React.Fragment>;
}
