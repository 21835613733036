import {Map} from 'immutable';
import keymirror from 'keymirror';
import {asModel, User, Role, Invitation, Subscription} from 'lib/models';


export const constants = keymirror({
    AUTH_STATE: null,
    AUTH_STORAGE: null,
    AUTH_USER: null,
    AUTH_PERMISSIONS: null,
    AUTH_PERMISSION: null,
    AUTH_SUBSCRIPTION: null,
    AUTH_INVITATION: null
});

const defaults = Map({
    state: null,
    user: new User(), // logged user
    permissions: new Map({}), // all user permissions
    permission: null, // current active permission
    subscription: null, // company subscription
    invitation: null // null states indicates 'not loaded yet'
});

const reducer = (state = defaults, action) => {
    switch (action.type) {
        // sets AUTH state
        case constants.AUTH_STATE:
            return state.set('state', action.state);

        // sets / remove User
        case constants.AUTH_USER:
            return state.set('user', action.user ? asModel(User, action.user) : defaults.get('user'));

        // sets / remove User Role (Permission)
        case constants.AUTH_PERMISSIONS:
            return state.set('permissions', action.role ? asModel(Role, action.role).get('permissions') : defaults.get('permissions'));

        // sets / remove current used Permission
        case constants.AUTH_PERMISSION:
            return state.set('permission', action.permission || defaults.get('permission'));

        // sets / remove User
        case constants.AUTH_SUBSCRIPTION:
            return state.set('subscription', action.subscription ? asModel(Subscription, action.subscription) : defaults.get('subscription'));

        // sets / remove Invitation
        case constants.AUTH_INVITATION:
            return state.set('invitation', action.invitation ? asModel(Invitation, action.invitation) : defaults.get('invitation'));

        default:
            return state;
    }
};
export default reducer;
