import React, {useMemo} from 'react';
// material-ui
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// icons
import WaveDialogSuccess from 'components/core/vectors/subscription/WaveDialogSuccess';
import WaveDialogFailed from 'components/core/vectors/subscription/WaveDialogFailed';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import CheckIcon from '@material-ui/icons/CheckOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';


const useStyles = makeStyles(theme => ({
    // holder of everything
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'stretch',
        textAlign: 'center',
        '& .dialogactions': {
            width: `${theme.spacing(40)}px`
        }
    },
    // holder of icon
    stateIcon: {
        marginTop: `${theme.spacing(3)}px`,
        color: theme.palette.primary[500],
        // icon itself
        '& svg': {
            fontSize: `${theme.spacing(7)}px`,
            display: 'block'
        },
        '&.success, &.failed': {
            color: theme.palette.common.white,
            padding: `${theme.spacing(1)}px`,
            borderRadius: '100%',
            '& svg': {
                fontSize: `${theme.spacing(4)}px`
            }
        },
        '&.success': {background: theme.palette.secondary.main},
        '&.failed': {background: theme.palette.danger[400]}
    },
    // main title
    title: {
        // boost size
        '& > h2': {
            fontSize: theme.typography.pxToRem(24)
        }
    }
}));

/**
 * MUI Dialog with Wave vectors based on variant
 *  'open'          - boolean value for opening/closing dialog
 *  'setOpen'       - function for opening/closing dialog
 *  'variant'       - changing waves and icons based on variant (success/failed/info)
 */
export default function WaveDialog(props) {
    const {open = false, setOpen = () => {}, variant = 'info', title, description, actions} = props;
    const classes = useStyles();

    // get title icon component
    const Icon = useMemo(() => {
        switch (variant) {
            case 'success':
                return CheckIcon;
            case 'failed':
                return CloseIcon;
            default:
                return InfoIcon;
        }
    }, [variant]);
    // get wave component
    const Wave = useMemo(() => {
        switch (variant) {
            case 'failed':
                return WaveDialogFailed;
            default:
                return WaveDialogSuccess;
        }
    }, [variant]);

    return <Dialog
        open={open} onClose={() => setOpen(false)}
        scroll='body' fullWidth maxWidth='xs'>
        <div className={classes.contentWrapper}>
            <div className={`${classes.stateIcon} ${variant}`}>
                <Icon />
            </div>
            {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
            {description && <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>}
            {actions && actions}
            <Wave variant={variant} />
        </div>
    </Dialog>;
}
