import React from 'react';


/**
 * Snom Logo
 */
export default function Logo(props) {
    const {viewBox = '0 0 144 35', color = '#4A5D68', ...rest_of_props} = props;

    return <svg viewBox={viewBox} {...rest_of_props}>
        <g fill={color}>
            <path d='M24.23,18.1c-0.6-0.87-1.46-1.62-2.58-2.24c-1.12-0.62-2.58-1.01-4.38-1.18l-6.96-0.7 c-0.96-0.08-1.73-0.23-2.31-0.45c-0.58-0.21-1.02-0.47-1.32-0.77c-0.3-0.3-0.5-0.66-0.6-1.09c-0.1-0.43-0.15-1.02-0.15-1.57 c0-1.36,0.4-2.31,1.2-2.85c0.8-0.53,2.22-0.8,4.26-0.8c0.64,0,1.35,0,2.13,0c0.78,0,1.66,0,2.64,0c0.98,0,2.11,0.01,3.39,0.03 c0.64,0.01,1.33,0.02,2.08,0.04c1.36,0.03,2.55-0.93,2.81-2.26v0c0.33-1.71-0.96-3.3-2.7-3.33C20.43,0.91,19.2,0.9,18.05,0.9 c-2.08,0-4.08,0-6,0c-4.28,0-7.36,0.82-9.24,2.46C0.94,5,0,7.27,0,10.17v1.19c0,1.02,0.15,2.02,0.45,3 c0.3,0.98,0.77,1.87,1.41,2.65C2.5,17.8,3.34,18.46,4.38,19c1.04,0.53,2.3,0.86,3.78,0.99l7.2,0.7c0.96,0.09,1.72,0.25,2.28,0.48 c0.56,0.23,0.99,0.52,1.29,0.86c0.3,0.34,0.5,0.73,0.6,1.18c0.1,0.45,0.15,0.93,0.15,1.44c0,1.62-0.43,2.81-1.29,3.36 c-0.86,0.55-2.45,0.83-4.77,0.83c-1.8,0-3.82-0.01-6.06-0.03c-1.47-0.01-2.92-0.03-4.35-0.06c-1.3-0.02-2.43,0.89-2.68,2.17 L0.52,31c-0.33,1.71,0.97,3.31,2.7,3.33c1.28,0.02,2.56,0.03,3.83,0.04c2.38,0.02,4.47,0.03,6.27,0.03c4.24,0,7.38-0.84,9.42-2.53 c2.04-1.68,3.06-4.04,3.06-7.07v-1c0-0.94-0.11-1.91-0.33-2.91C25.24,19.91,24.83,18.97,24.23,18.1z' />
            <path d='M55.57,2.49C53.35,0.83,49.88,0,45.16,0c-4.72,0-8.19,0.83-10.41,2.49c-2.22,1.66-3.33,4.41-3.33,8.25v20.6 c0,1.74,1.41,3.15,3.15,3.15h0c1.74,0,3.15-1.41,3.15-3.15V12.08c0-1.06,0.06-2,0.18-2.81c0.12-0.81,0.42-1.49,0.9-2.05 c0.48-0.55,1.22-0.97,2.22-1.25c1-0.28,2.38-0.42,4.14-0.42c1.76,0,3.14,0.14,4.14,0.42c1,0.28,1.74,0.69,2.22,1.25 c0.48,0.55,0.78,1.24,0.9,2.05c0.12,0.81,0.18,1.75,0.18,2.81v19.25c0,1.74,1.41,3.15,3.15,3.15h0c1.74,0,3.15-1.41,3.15-3.15 v-20.6C58.9,6.91,57.79,4.16,55.57,2.49z' />
            <path d='M75.51,29.25c-1.04-0.23-1.85-0.71-2.43-1.44c-0.58-0.72-0.98-1.76-1.2-3.1c-0.22-1.34-0.33-3.14-0.33-5.4 v-3.52c0-2.26,0.11-4.06,0.33-5.4c0.22-1.34,0.62-2.38,1.2-3.1c0.58-0.72,1.39-1.2,2.43-1.44c0.07-0.02,0.14-0.03,0.22-0.05 c1.25-0.25,2.17-1.34,2.17-2.62V3.11c0-1.75-1.57-3.03-3.28-2.74c-0.58,0.1-1.13,0.22-1.65,0.36c-1.82,0.49-3.31,1.34-4.47,2.56 c-1.16,1.21-2.01,2.84-2.55,4.86c-0.54,2.02-0.81,4.57-0.81,7.64v3.52c0,3.07,0.27,5.62,0.81,7.64c0.54,2.02,1.39,3.64,2.55,4.86 c1.16,1.22,2.65,2.07,4.47,2.56c0.52,0.14,1.07,0.26,1.65,0.36c1.71,0.29,3.28-0.99,3.28-2.72v0c0-1.32-0.95-2.45-2.24-2.72 C75.6,29.27,75.55,29.26,75.51,29.25z' />
            <path d='M90.68,3.29c-1.16-1.22-2.65-2.07-4.47-2.56c-0.67-0.18-1.4-0.33-2.18-0.44c-1.65-0.24-3.13,1.07-3.13,2.74 v0c0,1.32,0.94,2.49,2.24,2.71c0.18,0.03,0.35,0.06,0.52,0.1c1.04,0.24,1.85,0.71,2.43,1.44c0.58,0.73,0.98,1.76,1.2,3.1 c0.22,1.34,0.33,3.14,0.33,5.4v3.52c0,2.26-0.11,4.06-0.33,5.4c-0.22,1.34-0.62,2.38-1.2,3.1c-0.58,0.73-1.39,1.2-2.43,1.44 c-0.16,0.04-0.34,0.07-0.52,0.1c-1.3,0.22-2.24,1.39-2.24,2.71v0c0,1.67,1.48,2.98,3.13,2.74c0.78-0.11,1.51-0.26,2.18-0.44 c1.82-0.49,3.31-1.34,4.47-2.56c1.16-1.21,2.01-2.83,2.55-4.86c0.54-2.02,0.81-4.57,0.81-7.64v-3.52c0-3.07-0.27-5.62-0.81-7.64 C92.69,6.13,91.84,4.51,90.68,3.29z' />
            <path d='M140.82,2.43C138.7,0.81,135.58,0,131.46,0c-2.08,0-3.96,0.33-5.64,0.99c-1.68,0.66-2.92,1.57-3.72,2.72 c-0.8-1.15-2.04-2.06-3.72-2.72C116.71,0.33,114.83,0,112.75,0c-4.12,0-7.23,0.81-9.33,2.43c-2.1,1.62-3.15,4.39-3.15,8.31v20.6 c0,1.74,1.41,3.15,3.15,3.15h0c1.74,0,3.15-1.41,3.15-3.15V12.08c0-1.06,0.05-2,0.15-2.81c0.1-0.81,0.35-1.49,0.75-2.05 c0.4-0.55,1.02-0.97,1.86-1.25c0.84-0.28,1.98-0.42,3.42-0.42c1.48,0,2.63,0.14,3.45,0.42c0.82,0.28,1.44,0.69,1.86,1.25 c0.42,0.55,0.68,1.24,0.78,2.05c0.1,0.81,0.15,1.75,0.15,2.81v19.25c0,1.74,1.41,3.15,3.15,3.15h0c1.74,0,3.15-1.41,3.15-3.15 V11.19c0-0.9,0.07-1.69,0.21-2.4c0.14-0.7,0.43-1.3,0.87-1.79c0.44-0.49,1.06-0.85,1.86-1.09c0.8-0.23,1.88-0.35,3.24-0.35 c1.48,0,2.63,0.14,3.45,0.42c0.82,0.28,1.44,0.69,1.86,1.25c0.42,0.55,0.68,1.24,0.78,2.05c0.1,0.81,0.15,1.75,0.15,2.81v19.25 c0,1.74,1.41,3.15,3.15,3.15h0c1.74,0,3.15-1.41,3.15-3.15v-20.6C144,6.82,142.94,4.05,140.82,2.43z' />
        </g>
        <defs>
            <linearGradient id={'sraps_snom_logo_linear'} gradientUnits='userSpaceOnUse'>
                <stop stopColor='#33bae2' offset='0' />
                <stop stopColor='#51cae2' offset='1' />
            </linearGradient>
        </defs>
    </svg>;
}
