import React from 'react';


/**
 * Partner Portal Logo
 */
export default function Logo(props) {
    const {viewBox = '0 0 370.7 42.002', id, ...rest_of_props} = props;

    return <svg viewBox={viewBox} {...rest_of_props}>
        <g transform='matrix(1.3333 0 0 -1.3333 -188 659.5)'>
            <g transform='translate(145.69 479.87)'>
                <path fill='#4A5D68' d='m0 0h6.773c3.3 0 5.471 1.91 5.471 5.167 0 3.321-2.171 5.21-5.471 5.21h-6.773zm-4.689-13.351v25.03c0 0.695 0 1.52 0.673 2.193s1.498 0.673 2.193 0.673h8.857c6.035 0 9.899-3.994 9.899-9.378s-3.864-9.335-9.899-9.335h-7.034v-9.183c0-1.15-0.022-1.519-0.347-2.127-0.347-0.63-1.064-1.064-1.998-1.064-0.933 0-1.649 0.434-1.996 1.064-0.326 0.608-0.348 0.977-0.348 2.127' />
            </g>
            <g transform='translate(174.11 466.93)'>
                <path fill='#4A5D68' d='m0 0c1.584 0 2.887 0.174 4.016 1.259 0.695 0.651 0.977 1.65 0.977 3.278v1.737h-5.21c-2.605 0-3.929-1.129-3.929-3.17 0-1.997 1.346-3.104 4.146-3.104m-0.847 9.422h5.84v1.367c0 2.887-1.368 4.298-4.602 4.298-1.759 0-2.931-0.499-4.212-1.476-0.564-0.434-1.194-0.586-1.606-0.586-0.456 0-0.955 0.196-1.281 0.586-0.369 0.413-0.521 0.782-0.521 1.368 0 0.998 0.933 1.888 2.062 2.54 1.542 0.89 3.322 1.324 5.601 1.324 5.905 0 9.009-2.583 9.009-7.576v-11.853c0-1.086-0.043-1.498-0.326-1.997-0.325-0.608-1.042-1.02-1.888-1.02-0.716 0-1.259 0.26-1.606 0.629-0.261 0.304-0.456 0.651-0.5 1.585-1.736-1.693-3.213-2.28-5.904-2.28-2.758 0-4.603 0.63-5.927 1.997-1.129 1.151-1.759 2.844-1.759 4.711 0 3.691 2.627 6.383 7.62 6.383' />
            </g>
            <g transform='translate(200.39 485.77)'>
                <path fill='#4A5D68' d='m0 0c1.628 0 2.8-0.304 3.864-1.02 0.76-0.5 1.324-1.151 1.324-1.997 0-0.63-0.26-1.216-0.651-1.607-0.369-0.369-0.955-0.608-1.52-0.608-0.456 0-0.955 0.152-1.715 0.586-0.737 0.413-1.519 0.652-2.388 0.652-2.517 0-4.493-1.867-4.493-4.928v-10.507c0-1.064-0.065-1.455-0.326-1.976-0.304-0.586-0.933-1.042-1.888-1.042s-1.585 0.456-1.889 1.042c-0.261 0.521-0.326 0.912-0.326 1.976v16.303c0 1.216 0.087 1.606 0.391 2.106 0.369 0.586 1.085 0.933 1.824 0.933 0.716 0 1.389-0.282 1.801-0.868 0.239-0.348 0.348-0.673 0.391-1.607 1.237 1.542 3.3 2.562 5.601 2.562' />
            </g>
            <g transform='translate(210.57 482.12)'>
                <path fill='#4A5D68' d='m0 0h-1.02c-0.825 0-1.151 0.043-1.541 0.26-0.5 0.282-0.782 0.782-0.782 1.433s0.282 1.151 0.782 1.433c0.39 0.217 0.716 0.26 1.541 0.26h1.02v3.452c0 1.064 0.065 1.455 0.326 1.975 0.304 0.587 0.934 1.042 1.889 1.042s1.584-0.455 1.889-1.042c0.26-0.52 0.325-0.911 0.325-1.975v-3.452h2.388c0.825 0 1.15-0.043 1.541-0.26 0.499-0.282 0.782-0.782 0.782-1.433s-0.283-1.151-0.782-1.433c-0.391-0.217-0.716-0.26-1.541-0.26h-2.388v-12.331c0-1.519 0.608-2.496 2.236-2.539 0.717-0.022 1.215-0.087 1.607-0.304 0.52-0.283 0.89-0.825 0.89-1.563s-0.37-1.26-0.89-1.542c-0.392-0.217-0.977-0.347-1.867-0.347h-0.435c-4.146 0-5.97 2.866-5.97 6.1z' />
            </g>
            <g transform='translate(229.35 466.34)'>
                <path fill='#4A5D68' d='m0 0c0-1.064-0.065-1.455-0.325-1.976-0.305-0.586-0.934-1.041-1.889-1.041s-1.585 0.455-1.889 1.041c-0.261 0.521-0.326 0.912-0.326 1.976v16.303c0 1.216 0.087 1.606 0.391 2.106 0.369 0.586 1.085 0.933 1.824 0.933 0.716 0 1.389-0.282 1.802-0.868 0.216-0.326 0.347-0.651 0.39-1.455 1.52 1.607 3.604 2.41 5.796 2.41 2.258 0 4.125-0.738 5.428-1.997 1.693-1.65 2.214-3.734 2.214-6.209v-11.223c0-1.064-0.065-1.455-0.326-1.976-0.304-0.586-0.933-1.041-1.888-1.041s-1.585 0.455-1.889 1.041c-0.261 0.521-0.326 0.912-0.326 1.976v10.572c0 3.43-1.953 4.863-4.428 4.863s-4.559-1.477-4.559-4.863z' />
            </g>
            <g transform='translate(262.14 479.3)'>
                <path fill='#4A5D68' d='m0 0c-0.738 1.65-2.258 2.779-4.341 2.779-2.128 0-3.647-1.129-4.386-2.779-0.434-0.955-0.521-1.65-0.564-2.996h9.899c-0.087 1.346-0.174 2.041-0.608 2.996m-4.341 6.469c5.752 0 9.182-4.19 9.313-10.008 0.021-0.651-0.065-1.432-0.608-1.975-0.565-0.564-1.303-0.564-1.954-0.564h-11.701c0-3.756 1.975-6.209 5.644-6.209 1.542 0 3.04 0.434 4.233 1.389 0.674 0.543 1.237 0.803 1.802 0.803 0.89 0 1.824-0.868 1.824-1.888s-1.129-1.976-2.215-2.67c-1.389-0.912-3.386-1.411-5.753-1.411-5.492 0-9.92 2.887-9.92 11.266 0 7.251 3.712 11.267 9.335 11.267' />
            </g>
            <g transform='translate(282.94 485.77)'>
                <path fill='#4A5D68' d='m0 0c1.628 0 2.8-0.304 3.864-1.02 0.76-0.5 1.324-1.151 1.324-1.997 0-0.63-0.26-1.216-0.651-1.607-0.369-0.369-0.955-0.608-1.52-0.608-0.456 0-0.955 0.152-1.715 0.586-0.737 0.413-1.519 0.652-2.388 0.652-2.517 0-4.493-1.867-4.493-4.928v-10.507c0-1.064-0.065-1.455-0.326-1.976-0.304-0.586-0.933-1.042-1.888-1.042s-1.585 0.456-1.889 1.042c-0.261 0.521-0.326 0.912-0.326 1.976v16.303c0 1.216 0.087 1.606 0.391 2.106 0.369 0.586 1.085 0.933 1.824 0.933 0.716 0 1.389-0.282 1.802-0.868 0.238-0.348 0.347-0.673 0.39-1.607 1.237 1.542 3.3 2.562 5.601 2.562' />
            </g>
            <g transform='translate(307.65 479.87)'>
                <path fill='#4A5D68' d='m0 0h6.773c3.3 0 5.471 1.91 5.471 5.167 0 3.321-2.171 5.21-5.471 5.21h-6.773zm-4.689-13.351v25.03c0 0.695 0 1.52 0.673 2.193s1.498 0.673 2.193 0.673h8.857c6.035 0 9.899-3.994 9.899-9.378s-3.864-9.335-9.899-9.335h-7.034v-9.183c0-1.15-0.022-1.519-0.347-2.127-0.347-0.63-1.064-1.064-1.998-1.064-0.933 0-1.649 0.434-1.996 1.064-0.326 0.608-0.348 0.977-0.348 2.127' />
            </g>
            <g transform='translate(334.78 466.86)'>
                <path fill='#4dc3bb' d='m0 0c-0.688 0.155-1.222 0.471-1.605 0.951-0.384 0.478-0.648 1.162-0.793 2.049-0.146 0.888-0.218 2.077-0.218 3.571v2.324c0 1.493 0.072 2.683 0.218 3.57 0.145 0.888 0.409 1.57 0.793 2.05 0.383 0.478 0.917 0.795 1.605 0.951 0.047 0.01 0.095 0.02 0.144 0.03 0.829 0.165 1.432 0.886 1.432 1.731v0.046c0 1.154-1.039 2.001-2.168 1.809-0.386-0.065-0.75-0.144-1.093-0.236-1.202-0.325-2.186-0.887-2.952-1.69-0.767-0.803-1.328-1.874-1.685-3.212s-0.535-3.021-0.535-5.049v-2.324c0-2.029 0.178-3.712 0.535-5.05s0.918-2.408 1.685-3.211c0.766-0.803 1.75-1.367 2.952-1.69 0.343-0.093 0.707-0.171 1.093-0.237 1.129-0.192 2.168 0.655 2.168 1.801v1e-3c0 0.875-0.625 1.616-1.482 1.795-0.032 6e-3 -0.063 0.013-0.094 0.02' />
            </g>
            <g transform='translate(344.81 483.97)'>
                <path fill='#4A5D68' d='m0 0c-0.767 0.803-1.751 1.366-2.953 1.69-0.444 0.12-0.925 0.216-1.441 0.29-1.09 0.156-2.066-0.709-2.066-1.811v-2e-3c0-0.874 0.62-1.642 1.482-1.79 0.119-0.02 0.232-0.043 0.341-0.067 0.686-0.155 1.221-0.472 1.605-0.951 0.382-0.479 0.647-1.162 0.792-2.049 0.146-0.888 0.218-2.078 0.218-3.571v-2.324c0-1.493-0.072-2.683-0.218-3.57-0.145-0.888-0.41-1.571-0.792-2.05-0.384-0.479-0.919-0.796-1.605-0.951-0.109-0.024-0.223-0.047-0.342-0.067-0.862-0.148-1.481-0.916-1.481-1.79v-1e-3c0-1.102 0.976-1.968 2.066-1.812 0.516 0.074 0.997 0.171 1.441 0.29 1.202 0.323 2.186 0.887 2.953 1.69 0.766 0.803 1.328 1.873 1.684 3.212 0.357 1.337 0.535 3.021 0.535 5.049v2.324c0 2.029-0.178 3.711-0.535 5.05-0.356 1.337-0.918 2.408-1.684 3.211' />
            </g>
            <g transform='translate(362.64 485.77)'>
                <path fill='#4A5D68' d='m0 0c1.628 0 2.8-0.304 3.864-1.02 0.76-0.5 1.324-1.151 1.324-1.997 0-0.63-0.26-1.216-0.651-1.607-0.369-0.369-0.955-0.608-1.52-0.608-0.456 0-0.955 0.152-1.715 0.586-0.737 0.413-1.519 0.652-2.388 0.652-2.517 0-4.493-1.867-4.493-4.928v-10.507c0-1.064-0.065-1.455-0.326-1.976-0.304-0.586-0.933-1.042-1.888-1.042s-1.585 0.456-1.889 1.042c-0.261 0.521-0.326 0.912-0.326 1.976v16.303c0 1.216 0.087 1.606 0.391 2.106 0.369 0.586 1.085 0.933 1.824 0.933 0.716 0 1.389-0.282 1.801-0.868 0.239-0.348 0.348-0.673 0.391-1.607 1.237 1.542 3.3 2.562 5.601 2.562' />
            </g>
            <g transform='translate(372.83 482.12)'>
                <path fill='#4A5D68' d='m0 0h-1.02c-0.825 0-1.151 0.043-1.541 0.26-0.5 0.282-0.782 0.782-0.782 1.433s0.282 1.151 0.782 1.433c0.39 0.217 0.716 0.26 1.541 0.26h1.02v3.452c0 1.064 0.065 1.455 0.326 1.975 0.304 0.587 0.934 1.042 1.889 1.042s1.584-0.455 1.889-1.042c0.26-0.52 0.325-0.911 0.325-1.975v-3.452h2.388c0.825 0 1.15-0.043 1.541-0.26 0.499-0.282 0.782-0.782 0.782-1.433s-0.283-1.151-0.782-1.433c-0.391-0.217-0.716-0.26-1.541-0.26h-2.388v-12.331c0-1.519 0.608-2.496 2.236-2.539 0.717-0.022 1.215-0.087 1.607-0.304 0.52-0.283 0.89-0.825 0.89-1.563s-0.37-1.26-0.89-1.542c-0.392-0.217-0.977-0.347-1.867-0.347h-0.435c-4.146 0-5.97 2.866-5.97 6.1z' />
            </g>
            <g transform='translate(393.71 466.93)'>
                <path fill='#4A5D68' d='m0 0c1.584 0 2.887 0.174 4.016 1.259 0.695 0.651 0.977 1.65 0.977 3.278v1.737h-5.21c-2.605 0-3.929-1.129-3.929-3.17 0-1.997 1.346-3.104 4.146-3.104m-0.846 9.422h5.839v1.367c0 2.887-1.368 4.298-4.602 4.298-1.759 0-2.931-0.499-4.212-1.476-0.564-0.434-1.194-0.586-1.606-0.586-0.456 0-0.955 0.196-1.281 0.586-0.369 0.413-0.521 0.782-0.521 1.368 0 0.998 0.933 1.888 2.062 2.54 1.542 0.89 3.322 1.324 5.601 1.324 5.905 0 9.009-2.583 9.009-7.576v-11.853c0-1.086-0.043-1.498-0.326-1.997-0.325-0.608-1.042-1.02-1.888-1.02-0.716 0-1.259 0.26-1.606 0.629-0.261 0.304-0.456 0.651-0.5 1.585-1.736-1.693-3.213-2.28-5.904-2.28-2.758 0-4.603 0.63-5.927 1.997-1.129 1.151-1.758 2.844-1.758 4.711 0 3.691 2.626 6.383 7.62 6.383' />
            </g>
            <g transform='translate(409.87 491.61)'>
                <path fill='#4A5D68' d='m0 0c0 1.064 0.065 1.454 0.325 1.975 0.304 0.587 0.934 1.042 1.889 1.042s1.584-0.455 1.889-1.042c0.26-0.521 0.325-0.911 0.325-1.975v-21.817c0-1.52 0.608-2.497 2.236-2.54 0.717-0.022 1.216-0.087 1.607-0.304 0.521-0.282 0.89-0.825 0.89-1.563s-0.369-1.259-0.89-1.541c-0.391-0.218-0.977-0.348-1.867-0.348h-0.434c-4.147 0-5.97 2.866-5.97 6.101z' />
            </g>
        </g>
    </svg>;
}
