import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
// icons
import HomeWave from 'components/core/vectors/waves/SRAPSHome';
import LogoSNOM from 'components/core/vectors/LogoSnom';


const useStyles = makeStyles(theme => ({
    // banner card
    banner: {
        // limit size (responsive)
        maxWidth: '450px',
        width: '100%'
    },
    // snom logo holder
    bannerLogoHolder: {
        // double CardContent spacing
        padding: `${theme.spacing(4)}px`,
        // logo itself
        '& > svg': {
            display: 'block',
            width: '90px',
            height: '22px'
        }
    },
    // image in wave
    bannerImageHolder: {
        // 1:1 ratio
        paddingTop: '100%',
        width: '100%',
        position: 'relative',
        zIndex: '10',
        background: theme.palette.grey[50],
        marginBottom: `${theme.spacing(3)}px`,
        // image itself
        '& img': {
            display: 'block',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            width: '100%',
            height: '100%'
        }
    },
    // new ci wave
    bannerWave: {
        position: 'relative',
        zIndex: '20',
        // move into content
        margin: '-50% 0'
    },
    // content bellow wave - SRAPS and slogan
    bannerCardContent: {
        position: 'relative',
        zIndex: '30',
        // double CardContent spacing
        '&, &:last-child': {
            padding: `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
        },
        marginBottom: `${theme.spacing(8)}px`,
        // SRAPS
        '& > h2': {
            margin: '0',
            fontWeight: 'normal',
            // gradient
            backgroundImage: theme.gradients.blue.color,
            backgroundSize: theme.gradients.blue.backgroundSize,
            backgroundPosition: theme.gradients.blue.position,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            // fallback
            color: theme.palette.primary[500]
        },
        // slogan
        '& > h5': {
            margin: `${theme.spacing(1)}px 0`
        }
    }
}));

/**
 * SRAPS banner is small design highlight that inform user about meaning of the shortcut
 */
export default function SRAPSBanner() {
    const classes = useStyles();
    const intl = useIntl();

    return <Card className={classes.banner}>
        <CardContent className={classes.bannerLogoHolder}>
            <LogoSNOM color='url(#sraps_snom_logo_linear)' />
        </CardContent>
        <div className={classes.bannerImageHolder}>
            <img src={require('img/sraps/home.jpg')} alt={intl.formatMessage({id: 'home.image.alt'})} />
        </div>
        <HomeWave className={classes.bannerWave} />
        <CardContent className={classes.bannerCardContent}>
            <h2 className='newfont'>{'SRAPS'}</h2>
            <h5 className='newfont'><FormattedMessage id='home.slogan' /></h5>
        </CardContent>
    </Card>;
}
