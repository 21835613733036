import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment';
import {shallowEqual, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
// Components
import ToSDialog from 'components/core/ui/ToSDialog';
import {Container} from 'components/core/ui/Grid';


const useStyles = makeStyles(theme => ({
    root: {
        // position
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0',
        // fixed size of footer
        height: '92px',
        [theme.breakpoints.up('md')]: {
            height: '52px'
        },
        // text
        '& p': {
            // proportion
            margin: '0',
            padding: `${theme.spacing(2)}px`,
            // style
            textAlign: 'center',
            color: theme.palette.snomGrey[800],
            // smooth transition
            transition: theme.transitions.create('color',
                {duration: theme.transitions.duration.short}),
            // change style of link (muted)
            '& a': {
                '&, &:not(.MuiButton-root):hover': {
                    color: theme.palette.snomGrey[800]
                },
                '&.hover-border': {
                    '&:after, &:hover:after': {
                        background: theme.palette.snomGrey[800]
                    }
                }
            },
            // responsive large
            [theme.breakpoints.up('md')]: {
                '& br': {
                    display: 'none'
                }
            }
        }
    }
}));

/**
 * Application Footer
 */
export default function Footer() {
    const classes = useStyles();
    const intl = useIntl();
    // react router
    const {pathname} = useLocation();
    // redux store
    const props = useSelector(state => ({
        portal: pathname.startsWith('/partner'),
        backend_version: state.api.get('version')
    }), shallowEqual);
    // local state
    const [tosDialogOpen, setTosDialogOpen] = useState(false);

    return <div className={`${classes.root} mui-fixed`}>
        <Container full>
            <p>
                <span>&copy;&nbsp;{Moment().year()}{' Snom Technology GmbH. '}</span>
                <br />
                <span><FormattedMessage id='footer.rights' />{'. '}</span>
                <span><a href={props.portal ? intl.formatMessage({id: 'footer.portal.tos.link'}) : '#'}
                         target={props.portal ? '_blank' : undefined} rel='noreferrer'
                         onClick={props.portal ? undefined : (e) => { e.preventDefault(); setTosDialogOpen(!tosDialogOpen); }}
                         className='hover-border'>
                    <FormattedMessage id={`footer${props.portal ? '.portal' : ''}.tos`} />
                </a>{'. '}</span>
                {process.env.REACT_APP_SRAPS_VERSION && <React.Fragment>
                    <br />
                    <span>{`${process.env.REACT_APP_SRAPS_VERSION}${props.backend_version && props.backend_version.split('.').length === 3 ? `-${props.backend_version.split('.')[2]}` : ''}`}</span>
                </React.Fragment>}
            </p>
        </Container>
        <ToSDialog open={tosDialogOpen} handleClose={() => setTosDialogOpen(false)} />
    </div>;
}
