import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
import {FormattedMessage} from 'react-intl';
import {useDebounce} from 'use-debounce';
import {List as ImmutableList} from 'immutable';
// Components
import {SearchField} from 'components/core/ui/Field';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// material-ui
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
    // in order to make ClickAwayListener work, we need to wrap functional component in simple div
    rootHolder: {
        // make sure not to overflow and behave like field
        display: 'inline-flex',
        maxWidth: '100%'
    },
    // suggestion tooltip
    suggestionTooltip: {
        zIndex: theme.zIndex.tooltip,
        // card like
        background: theme.palette.common.white, // visible over elements
        boxShadow: theme.shadows[1], // highlight effect
        borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`, // same as card
        overflow: 'hidden' // borderRadius needs this to work
    },
    // holder of suggestions
    suggestionList: {
        width: '320px',
        maxHeight: '320px',
        overflow: 'auto'
    },
    // loader at the bottom of the list
    suggestionLoader: {
        // position at the bottom of list (always)
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        // hide by default
        opacity: '0',
        transition: theme.transitions.create('opacity',
            {duration: theme.transitions.duration.short}),
        '&.show': {
            opacity: '1'
        }
    }
}));

/**
 * Enhanced Search Field - you start typing, it starts suggesting
 *
 * Suggestion item:
 *  new ImmutableMap({value: "value_to_set", name: "Value to Read"});
 *
 * Example local state implementation:
 *  const [suggestions, setSuggestions] = useState(new ImmutableList());
 *  const [value, setValue] = useState('');
 *
 *  <AutoCompleteField
 *      label={<FormattedMessage id='INTL_KEY' />}
 *      value={value}
 *      change={value => setValue(value)}
 *      search={search => {
 *          setValue(search);
 *          setSuggestions(!search ? new ImmutableList() : items
 *              .filter(el => el.get('name').includes(search))
 *              .map(el => new ImmutableMap({name: el.get('name'), value: el.get('name')}))
 *          )}
 *      }}
 *      items={suggestions}
 *  />
 */
export default function AutoCompleteField(props) {
    const {change, search = (value) => change(value), value, loading, suggestionLoading, items = ImmutableList(), initialItems = ImmutableList(),
        postChildren = (searchValue, setSearchValue, loader) => {}, onFocus = (e) => {}, onScroll = (e, searchValue, setSearchValue, loader) => {},
        ...rest_of_props} = props;
    const classes = useStyles();
    // local state
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [debouncedSuggestionLoading] = useDebounce(suggestionLoading, theme.transitions.duration.short);

    /**
     * Sets suggestion and closes dialog
     *
     * @param value - suggestion.get('value')
     */
    const suggestionSet = (value) => {
        change(value);
        setOpen(false);
    };

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className={classes.rootHolder}>
            <SearchField
                onFocus={(e) => {
                    onFocus(e);
                    setAnchorEl(e.currentTarget);
                    setOpen(true);
                }}
                postChildren={(searchValue, setSearchValue, loader) => {
                    // get items to render based on filled searchValue
                    const suggestions = searchValue ? items : initialItems;

                    return <React.Fragment>
                        {postChildren(searchValue, setSearchValue, loader)}
                        <Popper open={open && !loader && (!!searchValue || !!initialItems.size)} className={classes.suggestionTooltip}
                                anchorEl={anchorEl} placement='bottom-start'
                                modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}, hide: {enabled: false}}}>
                            <List className={classes.suggestionList} onScroll={(e) => onScroll(e, searchValue, setSearchValue, loader)}>
                                {suggestions.size
                                    ? suggestions.map(suggestion => <ListItem
                                        key={suggestion.get('value')} button
                                        onClick={() => suggestionSet(suggestion.get('value'))}>
                                        <ListItemText>{suggestion.get('name') || suggestion.get('value')}</ListItemText>
                                    </ListItem>)
                                    : <ListItem>
                                        <ListItemText><FormattedMessage id='filters.empty' /></ListItemText>
                                    </ListItem>
                                }
                                {(!!suggestions.size && (suggestionLoading || debouncedSuggestionLoading)) && <LinearProgress
                                    className={`${classes.suggestionLoader}${suggestionLoading ? ' show' : ''}`}
                                />}
                            </List>
                        </Popper>
                    </React.Fragment>;
                }}
                value={value}
                search={search}
                loading={loading}
                {...rest_of_props}
            />
        </div>
    </ClickAwayListener>;
}
