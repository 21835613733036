import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import Button from 'components/core/ui/mui/Button';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme => ({
    buttonRoot: {
        // space between
        marginLeft: `${theme.spacing(1)}px`,
        '&:first-child': {
            marginLeft: '0'
        },
        // button and not iconButton
        '&.button': {
            // change size
            padding: `7px ${theme.spacing(2)}px`,
            '&.outlined': {
                // lower padding due to border
                padding: `${7 - 1}px ${(theme.spacing(2)) - 1}px`
            },
            // fix position of loader
            '& .loader': {
                top: '2px'
            },
            // ensure that button is same size as icon button
            marginTop: '5px',
            marginBottom: '5px',
            '&:last-child': {
                // larger space between button and wall
                marginRight: `${theme.spacing(1)}px`
            }
        }
    }
}));

/**
 * Extends Button and IconButton from material-ui to better fit into CardHeader
 */
const ActionButton = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {iconButton, _classes, className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.buttonRoot, root, className,
        rest_of_props.variant === 'outlined' ? ' outlined' : '',
        iconButton ? 'iconButton' : 'button'
    ].filter(Boolean).join(' ');

    if (iconButton) {
        return <IconButton
            ref={ref}
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />;
    } else {
        return <Button
            ref={ref}
            _classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />;
    }
});
export default ActionButton;
