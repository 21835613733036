import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import storage from 'store';
import Moment from 'moment';
import {getINTLMessages} from 'lib/localize';
// Components
import {getLanguageName, LanguageIcon} from 'components/core/ui/fields/LanguageField';
// Actions
import {updateIntl} from 'react-intl-redux';
import {backupForms} from 'actions/app';
// material-ui
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


/**
 * Menu which gives ability to switch language
 */
export default function LanguageSwitcherMenu(passed_props) {
    const {open, onClose, anchorEl, ...rest_of_props} = passed_props;
    const intl = useIntl();
    // redux store
    const dispatch = useDispatch();
    const {state, forms} = useSelector(state => ({
        state: state.app.get('state'),
        forms: state.form
    }), shallowEqual);

    return <Menu open={open}
                 anchorEl={anchorEl}
                 onClose={() => onClose()}
                 {...rest_of_props}>
        {['en', 'de', 'es', 'it', 'fr', 'ru', ...(
            process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING ? ['keys'] : []
        )].map(code => <MenuItem
            value={code} key={code}
            disabled={state !== null}
            selected={intl.locale === code || (code === 'keys' && intl.locale === 'en-gb')}
            onClick={() => {
                // save choice to storage
                storage.set(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-lang`, code);
                // get messages and new locale
                let [messages, processedLocale] = getINTLMessages(code);
                // localize other libs
                Moment.locale(processedLocale);
                // close menu
                onClose();
                // backup forms so we can set their values back after language switch
                dispatch(backupForms(forms));
                // change INTL of the application
                dispatch(updateIntl({
                    locale: processedLocale,
                    messages: messages
                }));
            }}>
            <ListItemIcon className='icon'><LanguageIcon code={code} /></ListItemIcon>
            <ListItemText className='text'>{getLanguageName(code)}</ListItemText>
        </MenuItem>)}
    </Menu>;
}
