import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {AssetFolder} from 'lib/models';
import {makeStyles} from '@material-ui/styles';
// actions
import {fetchItems} from 'actions/shared';
// components
import FolderMenuItem, {AddFolderMenuItem} from 'components/modules/assets/FolderMenuItem';
// material-ui
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';


const useStyles = makeStyles(() => ({
    rootList: {
        width: '100%'
    }
}));

/**
 * Render folders menu list, affecting to route
 */
export default function AssetFoldersMenu(props) {
    const {rootFolder, rootPath = '', disabled, selectWithRoot = true} = props;
    const isActive = !!useRouteMatch({exact: true, path: [rootPath, `${rootPath}/add`]});
    const dispatch = useDispatch();
    const classes = useStyles();

    const asset_folders_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);
    const asset_folders_placement = useMemo(() => new AssetFolder().getPlacement(), []);
    const editable = useMemo(() => !props.select && !disabled && props.permission === 'RW', [props.permission, props.select, disabled]);

    const {asset_folders_items, asset_folders_loaded} = useSelector(state => {
        const placement = rootFolder ? `${asset_folders_placement}-${rootFolder}` : asset_folders_placement;
        const items = state.shared.getIn(['items', placement]);

        return {
            asset_folders_loaded: state.shared.getIn(['loaded', placement]),
            asset_folders_items: items
        };
    }, shallowEqual);

    useEffect(() => {
        if (!asset_folders_loaded) {
            dispatch(fetchItems(AssetFolder, asset_folders_placement, 'folders'));
        }
    }, [asset_folders_loaded]);

    return <List className={classes.rootList} disablePadding>
        {props.select && selectWithRoot ? <FolderMenuItem {...props} /> : <React.Fragment>
            {asset_folders_items?.map((folder) => (
                <FolderMenuItem {...props} key={folder.get(asset_folders_identifier)} folder={folder} isRoot  />
            ))}
            {editable && <Collapse in={isActive}>
                <AddFolderMenuItem />
            </Collapse>}
        </React.Fragment>}
    </List>;
}
