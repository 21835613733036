import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
// Actions
import {setErrorState as setErrorApiState} from 'actions/api';
// Components
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Tooltip from 'components/core/ui/mui/Tooltip';
// icons
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    card: {
        // red card
        background: theme.palette.danger['main'],
        borderColor: theme.palette.danger['main'],
        // less aggressive red card
        '&.warning': {
            background: theme.palette.common.white,
            '& $cardText': {
                color: theme.palette.danger['main']
            }
        }
    },
    // shared between cardHeader title, cardHeader subheaderText and button icon
    cardText: {
        // readable color
        color: theme.palette.common.white
    }
}));

/**
 * Error card displays unhandled API errors, #500, etc.
 */
export default function Error() {
    // router
    const {pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const {error_identifier, error_code, error_message} = useSelector(state => ({
        error_identifier: state.api.get('error_identifier'),
        error_code: state.api.get('error_code'),
        error_message: state.api.get('error_message')
    }), shallowEqual);
    // style
    const classes = useStyles();
    const rootClasses = [
        classes.card,
        [408, 429].includes(error_code) ? 'warning' : ''
    ].filter(Boolean).join(' ');

    /**
     * Watch for router location change to close the error (User continues to use the app)
     */
    useEffect(() => {
        if (error_identifier) {
            // clear error
            dispatch(setErrorApiState(null, null, null));
        }
    }, [pathname]);

    return <div>
        {error_identifier && <React.Fragment>
            <Card className={rootClasses}>
                <CardHeader
                    _classes={{
                        title: classes.cardText,
                        subheaderText: classes.cardText
                    }}
                    mobileActions
                    title={error_code === 408
                        ? <FormattedMessage id='errors.timeout.title' />
                        : error_code === 429
                        ? <FormattedMessage id='errors.toomanyrequests.title' />
                        : <div>{!isNaN(error_code) && `#${error_code} `}<FormattedMessage id='errors.inapp.title' /></div>
                    }
                    subheader={error_code === 408
                        ? <FormattedMessage id='errors.timeout.text' />
                        : error_code === 429
                        ? error_message
                        ? <>
                            <span>
                                <em>{error_message}</em>
                                <br />
                            </span>
                        </>
                        : <FormattedMessage id='errors.toomanyrequests.text' />
                        : <React.Fragment>
                        <FormattedMessage id='errors.inapp.description'
                                          values={{error_identifier: error_identifier}} />
                        <br />
                        {error_message && <span>
                            <em>{error_message}</em>
                            <br />
                        </span>}
                        <FormattedMessage id='errors.inapp.action' />
                    </React.Fragment>}
                    action={<div>
                        <Tooltip title={<FormattedMessage id='actions.close' />}>
                            <ActionButton iconButton onClick={() => dispatch(setErrorApiState(null, null, null))}>
                                <CloseIcon className={classes.cardText} />
                            </ActionButton>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='errors.refresh' />}>
                            <ActionButton iconButton onClick={() => window.location.reload()}>
                                <RefreshIcon className={classes.cardText} />
                            </ActionButton>
                        </Tooltip>
                    </div>}
                />
            </Card>
            <SpaceDivider grid />
        </React.Fragment>}
    </div>;
}
