import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {SalesCluster} from 'lib/models';
import {countries} from 'lib/countrieslist';
// Actions
import {setState} from 'actions/app';
import {fetchItems, saveItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import {CountryField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import {Row} from 'components/core/ui/Grid';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * Renders detail of Sales Cluster - ADD & EDIT
 */
function Detail(props) {
    const edit = !!props.match.params.identifier;

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.sales_clusters_loaded) {
            props.fetchItems(SalesCluster, 'sales_clusters', 'sales-clusters');
        }
    }, [props.sales_clusters_loaded]);

    return <div>
        {['fetching_item_sales_clusters', 'deleting_item_sales_clusters'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='salesclusters.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='sales_cluster' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='salesclusters.detail.notfound.title' />}
                text={<FormattedMessage id='salesclusters.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='salesclusters.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='salesclusters.detail.edit.title' />
                                : <FormattedMessage id='salesclusters.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='sales_cluster' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'salesclusters.detail.form.fields.name'})}*`} />
                        <CountryField name='countries' multiple label={`${props.intl.formatMessage({id: 'salesclusters.detail.form.fields.countries'})}*`} />
                        <SpaceDivider />
                        <Row nospace vCenter>
                            <Field
                                name='submission_enabled' fieldType='Checkbox'
                                label={<FormattedMessage id='salesclusters.detail.form.fields.submission_enabled' />}
                                helperText={<FormattedMessage id='salesclusters.detail.form.fields.submission_enabled.help' />}
                            />
                            <Field
                                name='free_dm' fieldType='Checkbox'
                                label={<FormattedMessage id='salesclusters.detail.form.fields.free_dm' />}
                                helperText={<FormattedMessage id='salesclusters.detail.form.fields.free_dm.help' />}
                            />
                        </Row>
                    </Form>
                    <SpaceDivider double />
                    <hr />
                    <Typography variant='body1'><FormattedMessage id='salesclusters.detail.section.stripe' /></Typography>
                    <hr />
                    <Form onSubmit={props.handleSubmit}>
                        <FieldWithIconHolder>
                            <Field
                                name='key' fieldType='TextField'
                                label={<FormattedMessage id='salesclusters.detail.form.fields.key' />}
                                helperText={<FormattedMessage id='salesclusters.detail.form.fields.key.help' />}
                                type={props.formValues.key === '__hidden__' ? 'password' : 'text'}
                                disabled={props.formValues.key === '__hidden__'}
                                withIcon={props.formValues.key === '__hidden__'}
                            />
                            {props.formValues.key === '__hidden__' && <FieldIcon onClick={() => props.change('key', '')}>
                                <DeleteIcon />
                            </FieldIcon>}
                        </FieldWithIconHolder>
                        <FieldWithIconHolder>
                            <Field
                                name='endpoint_secret' fieldType='TextField'
                                label={<FormattedMessage id='salesclusters.detail.form.fields.endpoint_secret' />}
                                helperText={<FormattedMessage id='salesclusters.detail.form.fields.endpoint_secret.help' />}
                                type={props.formValues.endpoint_secret === '__hidden__' ? 'password' : 'text'}
                                disabled={props.formValues.endpoint_secret === '__hidden__'}
                                withIcon={props.formValues.endpoint_secret === '__hidden__'}
                            />
                            {props.formValues.endpoint_secret === '__hidden__' && <FieldIcon onClick={() => props.change('endpoint_secret', '')}>
                                <DeleteIcon />
                            </FieldIcon>}
                        </FieldWithIconHolder>
                    </Form>
                </CardContent>
                {['saving_item_sales_clusters', 'saved_item_sales_clusters', 'failed_save_item_sales_clusters'].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_sales_clusters'}
                                         failure={props.state === 'failed_save_item_sales_clusters'}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/sales-clusters')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <Button
                            variant='contained' color='primary' type='submit' disabled={props.state !== null}
                            onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>}
                    </CardActions>
                </ThemeProvider>}
            </Card>
        </div>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'countries', data.countries);
    // both keys must be empty or filled
    if (data.key) {
        validator.isNotNull(null, errors, 'endpoint_secret', data.endpoint_secret);
    } else if (data.endpoint_secret) {
        validator.isNotNull(null, errors, 'key', data.key);
    }

    // check for countries already included in other clusters
    let targetClusterName;
    let foundCountry = data.countries?.find(dataCountryId => props.sales_clusters?.find(cluster => {
        // exclude current cluster
        return cluster !== props.item && cluster.get('countries')?.find(clusterCountryId => {
            if (clusterCountryId === dataCountryId) {
                targetClusterName = cluster.get('name');
                return true;
            } else {
                return false;
            }
        });
    }));
    if (targetClusterName && foundCountry) {
        errors['countries'] = props.intl.formatMessage({id: 'salesclusters.detail.form.fields.countries.error'}, {
            country: countries.find(country => country.get('id') === foundCountry)?.get('name'),
            cluster: targetClusterName
        });
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'salesClusterForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new SalesCluster().popUpFields(props.formValues);
        const identifier = new SalesCluster().getUniqueIdentifier();

        return dispatch(saveItem(SalesCluster, 'sales_clusters', props.item ? props.item.getIn(['links', 'self']) : 'sales-clusters', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(identifier)) {
                props.history.push(`/sales-clusters/${create_data.get(identifier)}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const identifier = new SalesCluster().getUniqueIdentifier();
    const sales_clusters = state.shared.getIn(['items', 'sales_clusters']);
    const item = sales_clusters?.find(el => el.get(identifier) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        item: item,
        sales_clusters,
        sales_clusters_loaded: state.shared.getIn(['loaded', 'sales_clusters']),
        initialValues: props.match.params.identifier
            ? item
                ? item.toJS()
                : undefined
            : {submission_enabled: true},
        formValues: getFormValues('salesClusterForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
