import React from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// components
import WaveDialog from 'components/core/ui/WaveDialog';
// actions
import {setState as setApiState} from 'actions/api';
// material-ui
import Button from 'components/core/ui/mui/Button';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CloseIcon from '@material-ui/icons/CloseOutlined';


/**
 * Failed dialog informing about limit reached and required subscription
 */
export default function GlobalDialog() {
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        apiState: state.api.get('state')
    }), shallowEqual);

    return <WaveDialog
        open={props.apiState === 'payment_required'} variant='failed'
        title={<FormattedMessage id='subscription.required.title' />}
        description={<FormattedMessage id='subscription.required.subheader' />}
        actions={<DialogActions>
            <Button startIcon={<CloseIcon />} onClick={() => dispatch(setApiState('ready'))}>
                <FormattedMessage id='actions.close' />
            </Button>
        </DialogActions>}
    />;
}
