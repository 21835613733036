import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {ProductGroup} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Product Groups from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new ProductGroup().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='productgroups.list.table.name' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(ProductGroup, items_placement, 'product-groups'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='productgroups.list.title' />}
            subheader={<FormattedMessage id='productgroups.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/product-families/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='product_groups' />
                </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='productgroups.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                        </TableRow>
                    </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'productgroups.list.table.empty'}
                                                      values={{link: passed_props.permission === 'RW'
                                                              ? <Link to='/product-families/add' className='hover-border'>
                                                                  <FormattedMessage id='productgroups.list.table.empty.link' />
                                                              </Link>
                                                              : <FormattedMessage id='productgroups.list.table.empty.link' />}} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && filteredItems.map((product_group, idx) =>
                                <TableRow key={idx} link hover
                                          onClick={() => props.state === null ? history.push(`/product-families/${product_group.get(new ProductGroup().getUniqueIdentifier())}`) : {}}>
                                    <TableCell>{product_group.get('name')}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>}
            </Table>} />
    </Card>;
}
