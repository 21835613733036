import React from 'react';
import {alpha, makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
// icons
import ImageIcon from '@material-ui/icons/Image';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import TextIcon from '@material-ui/icons/Description';
import CommonFileIcon from '@material-ui/icons/InsertDriveFile';


const useStyles = makeStyles(() => {
    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(1.5)}px`,
        borderRadius: '50%'
    };

    return {
        pdf: ({roundedContainer}) => ({
            color: theme.palette.orange.main,
            ...(roundedContainer && {
                ...containerStyle,
                backgroundColor: alpha(theme.palette.orange.main, 0.2)
            })
        }),
        commonFile: ({roundedContainer}) => ({
            color: theme.palette.primary.main,
            ...(roundedContainer && {
                ...containerStyle,
                backgroundColor: alpha(theme.palette.primary.main, 0.2)
            })
        }),
        image: ({roundedContainer}) => ({
            color: theme.graphs[4],
            ...(roundedContainer && {
                ...containerStyle,
                backgroundColor: alpha(theme.graphs[4], 0.2)
            })
        })
    };
});

/**
 * Render file icon by provided mimetype
 * @param {object} props - props object
 * @param {string} props.mimetype - mime type
 * @param {boolean} props.roundedContainer - render with circle background
 */
export default function FileIcon(props) {
    const {mimetype, roundedContainer, ...rest_of_props} = props;
    const classes = useStyles({roundedContainer});

    switch(mimetype) {
        case 'application/pdf':
            return <div className={classes.pdf}>
                <PdfIcon {...rest_of_props} />
            </div>;
        case 'image/jpeg':
        case 'image/svg+xml':
            return <div className={classes.image}>
                <ImageIcon {...rest_of_props} />
            </div>;
        case 'text/plain':
            return <div className={classes.commonFile}>
                <TextIcon {...rest_of_props} />
            </div>;
        default:
            return <div className={classes.commonFile}>
                <CommonFileIcon {...rest_of_props} />
            </div>;
    }
}
