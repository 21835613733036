import React, {useEffect, useMemo, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {usePaginatedItems} from 'lib/filters';
import {Company, DMNotification} from 'lib/models';
import Moment from 'moment';
import {useHistory} from 'react-router';
import {List as ImmutableList} from 'immutable';
// Actions
import {removeMessage, toggleNotificationsDrawer} from 'actions/app';
import {fetchItems, saveItem} from 'actions/shared';
// Components
import ScrollCheck from 'components/core/ui/ScrollCheck';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
// icons
import NotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ActiveNotificationsIcon
    from '@material-ui/icons/NotificationsActiveOutlined';
import NoNotificationsIcon from '@material-ui/icons/NotificationsOff';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import SeenIcon from '@material-ui/icons/VisibilityOffOutlined';
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import OfflineIcon from '@material-ui/icons/OfflineBoltOutlined';


const useStyles = makeStyles(theme => ({
    // colored badge by importance with number of notifications
    notificationBadge: {
        color: theme.palette.common.white,
        background: theme.palette.grey[500],
        // smooth transition
        transition: theme.transitions.create(['transform', 'background'],
            {duration: theme.transitions.duration.short}),
        // variants
        '&.success': {
            background: theme.palette.success[500]
        },
        '&.error': {
            background: theme.palette.danger[500]
        },
        '&.warning': {
            background: theme.palette.orange[500]
        },
        '&.info': {
            background: theme.palette.primary[500]
        }
    },
    drawer: {
        // bellow app bar (header)
        zIndex: `${theme.zIndex.appBar - 10} !important`
    },
    // paper in drawer and also holder of notifications
    notifications: {
        padding: `${theme.spacing(2)}px 0`,
        // size of header
        top: '64px',
        // use bottom instead of height: 100% to prevent overflowing
        bottom: '0',
        height: 'auto'
    },
    // subheader date
    notificationDate: {
        // position
        position: 'relative',
        zIndex: '10',
        // proportion
        padding: `${theme.spacing(0.5)}px`,
        // extra dividing space
        marginTop: `${theme.spacing(2)}px`,
        '&:first-child': {
            marginTop: '0'
        },
        // connect borders
        marginBottom: '-1px',
        // style
        fontFamily: '"Roboto Mono", monospace',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: '1.1875em',
        background: theme.palette.grey[100],
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        textAlign: 'center'
    },
    // seen all / load more buttons
    notificationButton: {
        textAlign: 'center',
        padding: `${theme.spacing(1.5)}px`,
        // style (info variant)
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    // root of notification
    notification: {
        // connect borders
        marginBottom: '-1px',
        '&:last-child': {
            marginBottom: '0'
        },
        // align icon and content next to each other
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        position: 'relative',
        zIndex: '20',
        // style (info variant)
        borderTop: `1px solid ${theme.palette.primary[300]}`,
        borderBottom: `1px solid ${theme.palette.primary[300]}`,
        background: theme.palette.snomGrey[100],
        '& $notificationTime, & $notificationIcon': {
            color: theme.palette.text.primary
        },
        // smooth transitions
        transition: theme.transitions.create(['background', 'border'],
            {duration: theme.transitions.duration.short}),
        // already seen notification
        '&.muted': {
            zIndex: '10',
            background: theme.palette.common.white,
            borderColor: theme.palette.grey[300],
            '& $notificationIcon, & $notificationTime, & $notificationText': {
                color: theme.palette.grey[500]
            }
        },
        // variants
        '&.success:not(.muted)': { // green
            zIndex: '30',
            background: alpha(theme.palette.success[500], 0.1),
            borderColor: theme.palette.success[500],
            '& $notificationTime, & $notificationIcon': {
                color: theme.palette.success[500]
            }
        },
        '&.warning:not(.muted)': { // orange
            zIndex: '40',
            background: alpha(theme.palette.orange[500], 0.1),
            borderColor: theme.palette.orange[500],
            '& $notificationTime, & $notificationIcon': {
                color: theme.palette.orange[500]
            }
        },
        '&.error:not(.muted)': { // red
            zIndex: '50',
            background: alpha(theme.palette.danger[500], 0.1),
            borderColor: theme.palette.danger[500],
            '& $notificationTime, & $notificationIcon': {
                color: theme.palette.danger[500]
            }
        }
    },
    // holder of icon on the left side
    notificationIcon: {
        // style
        color: theme.palette.grey[500],
        transition: theme.transitions.create('color',
            {duration: theme.transitions.duration.short}),
        // align icon
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // proportion
        flexShrink: '0',
        padding: `${theme.spacing(2)}px`,
        // animations
        '&.animation-success': {
            '& svg': {
                // rotate animation
                animationName: '$rotate',
                animationDuration: `${theme.transitions.duration.complex * 2}ms`,
                animationTimingFunction: 'ease-out',
                animationIterationCount: '1'
            }
        },
        '&.animation-grow': {
            '& svg': {
                // shake animation
                animationName: '$grow',
                animationDuration: `${theme.transitions.duration.complex * 2}ms`,
                animationTimingFunction: 'ease-in-out',
                animationIterationCount: '2'
            }
        },
        '&.animation-pulse': {
            '& svg': {
                // pulse animation
                animationName: '$pulse',
                animationDuration: `${theme.transitions.duration.complex * 4}ms`,
                animationTimingFunction: 'ease-in-out',
                animationIterationCount: 'infinite'
            }
        }
    },
    // holder of text and possibly with close icon
    notificationContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexGrow: '1',
        position: 'relative',
        width: '340px',
        padding: `${theme.spacing(1.5)}px 0`,
        // contain icon
        '&.icon': {
            // extra space for icon to fit
            paddingRight: `${theme.spacing(7)}px`
        }
    },
    // tooltip with additional time information
    notificationTimeTooltip: {
        // move closer
        margin: `${theme.spacing(0.5)}px 0`
    },
    // time
    notificationTime: {
        // highlight date
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        // display as 'monospace' to better readability and same size
        fontFamily: '"Roboto Mono", monospace',
        // smooth
        transition: theme.transitions.create('color',
            {duration: theme.transitions.duration.short})
    },
    // message
    notificationText: {},
    // close icon to hide message (seen)
    notificationClose: {
        position: 'absolute',
        top: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`
    },
    // success "spinning" animation
    '@keyframes rotate': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(720deg)'
        }
    },
    // error "grow" animation
    '@keyframes grow': {
        '0%': {
            transform: 'scale(1)'
        },
        '50%': {
            transform: 'scale(0.8)'
        },
        '100': {
            transform: 'scale(1)'
        }
    },
    // warning "pulse" animation
    '@keyframes pulse': {
        '0%, 20%': {
            opacity: '1'
        },
        '50%': {
            opacity: '0.1'
        },
        '80%, 100%': {
            opacity: '1'
        }
    }
}));

/**
 * IconButton in Header to display app Notifications and DeviceManagementNotifications
 */
export default function Notifications() {
    const classes = useStyles();
    // router
    const history = useHistory();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => {
        const dm_notifications_placement = new DMNotification().getPlacement();

        return {
            state: state.app.get('state'),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            notifications: state.app.get('notifications'),
            dm_notifications: state.shared.getIn(['items', dm_notifications_placement]),
            dm_notifications_loaded: state.shared.getIn(['loaded', dm_notifications_placement]),
            dm_notifications_outdated: state.shared.getIn(['outdated', dm_notifications_placement]),
            dm_notifications_paginator: state.shared.getIn(['paginator', dm_notifications_placement]),
            dm_notifications_page_references: state.shared.getIn(['page_references', dm_notifications_placement]) || ImmutableList(),
            dm_notifications_placement: dm_notifications_placement,
            permissions: state.auth.get('permissions'),
            openNotifications: state.app.get('openNotifications')
        };
    }, shallowEqual);
    // local state
    const [seeingAll, setSeeingAll] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const notificationsRef = useRef(null);
    // process notifications
    const [paginatedDMNotifications] = usePaginatedItems(props.dm_notifications, props.dm_notifications_paginator, props.dm_notifications_page_references, new DMNotification().getUniqueIdentifier(), false);
    // add dm_notifications bellow (app) notifications
    const notifications = useMemo(() => {
        return props.notifications.concat(paginatedDMNotifications);
    }, [props.notifications, paginatedDMNotifications]);
    const unseenNotifications = useMemo(() => notifications.filter(n => !n.get('seen')), [notifications]);
    const importance = useMemo(() =>
        unseenNotifications.some(n => n.get('type') === 'error') ? 'error'
        : unseenNotifications.some(n => n.get('type') === 'warning') ? 'warning'
        : unseenNotifications.some(n => n.get('type') === 'success') ? 'success'
        : 'info', [unseenNotifications]);

    /**
     * During initialization fetch device management notifications
     */
    useEffect(() => {
        if (props.company.get('has_subscription') && props.permissions.get('notifications') !== 'X' && (props.dm_notifications_loaded === false || props.dm_notifications_outdated)) {
            dispatch(fetchItems(DMNotification, props.dm_notifications_placement, props.company.getIn(['links', 'notifications']), null,
                null, {affect_state: false, paginate: true, paginator_page: 1}));
        }
    }, [props.dm_notifications_loaded, props.dm_notifications_outdated]);

    /**
     * Load more pages
     */
    const loadMore = () => {
        // do we have proper permission and page to load?
        if (props.company.get('has_subscription') && props.permissions.get('notifications') !== 'X' && props.dm_notifications_loaded && !loadingMore && props.dm_notifications_paginator.get('next')) {
            // fetch another page
            setLoadingMore(true);
            dispatch(fetchItems(DMNotification, props.dm_notifications_placement, props.dm_notifications_paginator.get('next'), null,
                null, {affect_state: false, paginate: true, paginator_page: (props.dm_notifications_paginator.get('page') + 1)})
            ).finally(() => {
                setLoadingMore(false);
            });
        }
    };

    /**
     * Mark all loaded dm notifications as seen
     */
    const seenAll = () => {
        setSeeingAll(true);
        Promise.all(unseenNotifications.map(notification =>
            dispatch(saveItem(DMNotification, props.dm_notifications_placement, notification.getIn(['links', 'self']),
                {seen: true}, notification, {affect_state: false})))).finally(() => {
            setSeeingAll(false);
        });
    };

    return <React.Fragment>
        <Tooltip title={<FormattedMessage id='header.notifications' />}>
            <IconButton color='primary'
                        onClick={() => dispatch(toggleNotificationsDrawer(!props.openNotifications))}>
                <Badge invisible={!unseenNotifications.size}
                       badgeContent={unseenNotifications.size <= 9 ? unseenNotifications.size : '9+'}
                       classes={{badge: `${classes.notificationBadge} ${importance}`}}
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right'
                       }}>
                    {props.openNotifications ? <ActiveNotificationsIcon /> : <NotificationsIcon />}
                </Badge>
            </IconButton>
        </Tooltip>
        <Drawer open={props.openNotifications} onClose={() => dispatch(toggleNotificationsDrawer(false))} anchor='right'
                ModalProps={{scroll: 'body', disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true}}
                PaperProps={{
                    ref: notificationsRef,
                    onScroll: (e) => e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
                        ? loadMore() : {}
                }}
                classes={{root: classes.drawer, paper: classes.notifications}}>
            {props.openNotifications && <ScrollCheck
                componentRef={notificationsRef}
                action={loadMore}
                extraDep={`${props.dm_notifications_loaded}-${props.dm_notifications_outdated}`}
            />}
            {notifications.size
                ? notifications.map((notification, idx) => {
                    const dm_notification = notification.get('notification_id');

                    // get link
                    const link = dm_notification
                        ? notification.get('mac')
                            ? `/phones/${notification.get('mac')}${props.company.get(new Company().getUniqueIdentifier()) !== notification.get('company_id') ? `?company=${notification.get('company_id')}` : ''}`
                            : notification.get('provisioning_profile_id')
                                ? `/provisioning-profile/${notification.get('provisioning_profile_id')}${props.company.get(new Company().getUniqueIdentifier()) !== notification.get('company_id') ? `?company=${notification.get('company_id')}` : ''}`
                                : null
                        : notification.get('link');

                    // prepare remove function
                    const remove = (dm_notification && props.permissions.get('notifications').toUpperCase() === 'RW' && !notification.get('seen')) || notification.get('removable')
                        ? () => dm_notification
                            ? dispatch(saveItem(DMNotification, props.dm_notifications_placement, notification.getIn(['links', 'self']),
                                {seen: true}, notification, {affect_state: false}))
                            : dispatch(removeMessage(idx, true))
                        : false;

                    // prepare onClick function
                    const onClick = !link ? undefined : () => {
                        // remove
                        remove && remove();
                        // redirect
                        history.push(link);
                        // close drawer
                        dispatch(toggleNotificationsDrawer(false));
                    };

                    // classes
                    const notificationClasses = [
                        classes.notification, notification.get('type'),
                        notification.get('seen') ? 'muted' : '',
                        link ? 'link' : ''
                    ].filter(Boolean).join(' ');
                    const iconClasses = [
                        classes.notificationIcon,
                        notification.get('animation') && !notification.get('seen') ? `animation-${notification.get('animation')}` : ''
                    ].filter(Boolean).join(' ');
                    const contentClasses = [
                        classes.notificationContent,
                        remove ? 'icon' : ''
                    ].filter(Boolean).join(' ');

                    // proper icon
                    let Icon;
                    const icon = notification.get('icon') || notification.get('type');
                    switch (icon) {
                        case 'success':
                            Icon = SuccessIcon;
                            break;
                        case 'warning':
                            Icon = WarningIcon;
                            break;
                        case 'error':
                            Icon = ErrorIcon;
                            break;
                        case 'offline':
                            Icon = OfflineIcon;
                            break;
                        default:
                            Icon = InfoIcon;
                            break;
                    }

                    const NotificationComponent = link ? ButtonBase : 'div';

                    return <React.Fragment key={idx} >
                        {(idx === 0 && !!unseenNotifications.size && false) && <ButtonBase
                            onClick={seenAll} className={classes.notificationButton}
                            disabled={seeingAll}>
                            <Typography variant='subtitle2'>
                                <FormattedMessage id='notifications.seen_all' />
                            </Typography>
                        </ButtonBase>}
                        {Moment(Date.now()).format('l') !== Moment(notification.get('created_at') || Date.now()).format('l') && (idx === 0 || Moment(notifications.get(idx - 1).get('created_at') || Date.now()).format('l') !== Moment(notification.get('created_at') || Date.now()).format('l')) && <div
                            className={classes.notificationDate}>
                            {Moment(notification.get('created_at')).format('l')}
                        </div>}
                        <NotificationComponent className={notificationClasses} component={link && 'div'} onClick={onClick}>
                            <div className={iconClasses}>
                                <Icon />
                            </div>
                            <div className={contentClasses}>
                                {remove && <IconButton
                                    disabled={dm_notification && (props.state !== null || notification.get('seen'))}
                                    className={classes.notificationClose}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onTouchStart={(e) => e.stopPropagation()}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        remove();
                                    }}>
                                    {dm_notification
                                        ? <SeenIcon />
                                        : <CloseIcon />}
                                </IconButton>}
                                <div className={classes.notificationTime}>
                                    <Tooltip title={Moment(notification.get('created_at')).fromNow()}
                                             classes={{tooltip: classes.notificationTimeTooltip}}>
                                        <span>{Moment(notification.get('created_at')).format('LT')}</span>
                                    </Tooltip>
                                </div>
                                <div className={classes.notificationText}>
                                    {notification.get('intl_id')
                                        ? <FormattedMessage id={notification.get('intl_id')}
                                                            values={notification.get('intl_values')
                                                                ? notification.get('intl_values').toJS()
                                                                : {}} />
                                        : notification.get('text') || notification.get('message')}
                                </div>
                            </div>
                        </NotificationComponent>
                        {(idx + 1 === notifications.size && !loadingMore && props.dm_notifications_paginator.get('next')) && <ButtonBase
                            onClick={loadMore} className={classes.notificationButton}>
                            <Typography variant='subtitle2'>
                                <FormattedMessage id='notifications.load_more' />
                            </Typography>
                        </ButtonBase>}
                    </React.Fragment>;
                })
                : <div className={classes.notification}>
                    <div className={classes.notificationIcon}>
                        <NoNotificationsIcon />
                    </div>
                    <div className={classes.notificationContent}>
                        <div className={classes.notificationText}>
                            <FormattedMessage id='notifications.none' />
                        </div>
                    </div>
                </div>}
        </Drawer>
    </React.Fragment>;
}
