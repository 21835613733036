import React from 'react';
import {FormattedMessage} from 'react-intl';
// components
import DMActionButton from 'components/modules/tickets/dm/DMActionButton';
import DMTableRow from 'components/modules/tickets/dm/DMTableRow';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from 'components/core/ui/mui/TableFooter';
// icons
import SysLogIcon from '@material-ui/icons/VpnKey';
import SipLogIcon from '@material-ui/icons/DialerSip';
import SettingsIcon from '@material-ui/icons/DeveloperMode';


/**
 * Render device manager's data in table with action buttons (SysLog, SIPTrace, SettingsHTM)
 */
export default function DMBigDataTable(props) {
    const {permission, item, Model, placement, quickActionsHolderClass} = props;

    return <Card>
        <Table size={4}>
            <TableHead>
                <TableRow>
                    {['type', 'info', 'last_update', null].map(label => <TableCell key={label || 'empty'}>
                        {label && <FormattedMessage id={`devicemanagement.table.${label}`} />}
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {[
                    {
                        identifier: 'Syslog',
                        Icon: SysLogIcon,
                        action: 'get_syslog'
                    },
                    {
                        identifier: 'SIPTrace',
                        Icon: SipLogIcon,
                        action: 'get_sip_trace'
                    },
                    {
                        identifier: 'SettingsHTM',
                        Icon: SettingsIcon,
                        action: 'get_settings'
                    },
                    {
                        identifier: 'SettingsHTMModified',
                        Icon: SettingsIcon,
                        action: 'get_settings_modified'
                    }
                ].map(data => <DMTableRow
                    key={data.identifier} item={item} Model={Model} placement={placement} permission={permission}
                    {...data}
                />)}
            </TableBody>
            {permission === 'RW' && <TableFooter>
                <TableRow>
                    <TableCell colSpan='100%'>
                        <SpaceDivider />
                        <div className={quickActionsHolderClass}>
                            {['operate_reboot', 'operate_factory_reset', 'resync', 'upgrade'].map(action => <DMActionButton
                                key={action} action={action} Model={Model} placement={placement} item={item}
                            />)}
                        </div>
                        <SpaceDivider />
                    </TableCell>
                </TableRow>
            </TableFooter>}
        </Table>
    </Card>;
}
