import React, {useCallback, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// components
import Field, {EnhancedReduxField} from 'components/core/ui/Field';
import Form from 'components/core/ui/Form';
// material-ui
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from 'components/core/ui/mui/Button';
// vectors
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/UndoOutlined';


/**
 * Renders a form to add a new reference to the list
 */
function DialogContentForm(props) {
    const {rules, onClose, onCreate} = props;
    const intl = useIntl();
    const [referenceValue, setReferenceValue] = useState('');
    const [fieldTouched, setFieldTouched] = useState();

    const error = useMemo(() =>
        referenceValue
            ? rules.includes(referenceValue) && intl.formatMessage({id: 'featureflags.detail.form.rules.reference.error.exists'})
            : fieldTouched && intl.formatMessage({id: 'featureflags.detail.form.rules.reference.error.required'}),
    [referenceValue, fieldTouched]);

    const onSubmit = useCallback(() => {
        if (referenceValue && !error) {
            onCreate(referenceValue);
            onClose();
        }
    }, [referenceValue, error]);

    return <React.Fragment>
        <DialogContent>
            <Form onSubmit={onSubmit}>
                <Field autoFocus value={referenceValue} onChange={(e) => setReferenceValue(e.target.value)} onBlur={() => setFieldTouched(true)}
                    error={!!error} helperText={error} label={intl.formatMessage({id: 'featureflags.detail.form.rules.reference.dialog.new.label'})} fieldType='NoReduxTextField' />
            </Form>
        </DialogContent>
        <DialogActions>
            <Button startIcon={<CancelIcon />} onClick={() => onClose()}>
                <FormattedMessage id='actions.cancel' />
            </Button>
            <Button disabled={!referenceValue || !!error} startIcon={<AddIcon />}
                variant='contained' color='secondary' onClick={onSubmit}>
                <FormattedMessage id='actions.create' />
            </Button>
        </DialogActions>
    </React.Fragment>;
};

const useStyles = makeStyles((theme) => ({
    referenceFormControl: {
        width: '100%',
        margin: theme.spacing(0, 1, 1),
        '& .stack': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: `${theme.spacing(1)}px`,
            padding: theme.spacing(1, 0),
            marginTop: `${theme.spacing(2)}px`
        }
    }
}));

/**
 * Specific input field for reference
 * @param {object} props - component props 
 */
export default function ReferenceField(props) {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);

    return <EnhancedReduxField {...props} component={({input, meta, disabled}) => {
        const error = meta.touched && meta.error;

        return <React.Fragment>
            <FormControl required={props.required} error={!!error} className={classes.referenceFormControl}>
                <InputLabel shrink><FormattedMessage id='featureflags.detail.form.rules.reference.label' /></InputLabel>
                <div className='stack'>
                    {input.value?.map((item) => <Chip key={item} size='medium' label={item} onDelete={!props.disabled ? (() => {
                        input.onChange(input.value.filter(_item => _item !== item));
                        input.onBlur();
                    }) : undefined} />)}
                    {!props.disabled && <Button startIcon={<AddIcon />} variant='outlined' size='small' onClick={() => {
                        setShowDialog(true);
                        input.onFocus();
                    }}>
                        <FormattedMessage id='actions.create' />
                    </Button>}
                </div>
                <FormHelperText>{error || <FormattedMessage id='featureflags.detail.form.rules.reference.helperText' />}</FormHelperText>
            </FormControl>
            {!props.disabled && <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                <DialogTitle><FormattedMessage id='featureflags.detail.form.rules.reference.dialog.title' /></DialogTitle>
                <DialogContentForm rules={input.value} onClose={() => setShowDialog(false)} onCreate={(newReference) => {
                    input.onChange([...input.value, newReference]);
                    input.onBlur();
                }} />
            </Dialog>}
        </React.Fragment>;
    }} />;
}
