import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Multi select field for Company sources (SRAPS / Partner Portal)
 */
export default function CompanyTypeField(props) {
    // Split some stuff from props
    const {name, label, required, multiple = true, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' multiple={multiple} name={name || 'signup_sources'}
                  label={label || `${intl.formatMessage({id: 'companysourcesfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        {!multiple && <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>}
        <MenuItem value='sraps'>{intl.formatMessage({id: 'companysourcesfield.choice.sraps'})}</MenuItem>
        <MenuItem value='partner_portal'>{intl.formatMessage({id: 'companysourcesfield.choice.partner_portal'})}</MenuItem>
    </Field>;
}
