import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ReactDatePicker from 'react-datepicker';


const useStyles = makeStyles(theme => ({
    // react-datepicker.css converted to jss and slightly tweaked
    '@global': {
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow': {
            marginLeft: '-8px',
            position: 'absolute'
        },
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before': {
            boxSizing: 'content-box',
            position: 'absolute',
            border: '8px solid transparent',
            height: '0',
            width: '1px'
        },
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before': {
            content: '""',
            zIndex: -1,
            borderWidth: '8px',
            left: '-8px',
            borderBottomColor: '#f0f0f0'
        },
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle': {
            top: '0',
            marginTop: '-8px'
        },
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before': {
            borderTop: 'none',
            borderBottomColor: '#f0f0f0'
        },
        '.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before': {
            top: '-1px',
            borderBottomColor: '#f0f0f0'
        },
        '.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow': {
            bottom: '0',
            marginBottom: '-8px'
        },
        '.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before': {
            borderBottom: 'none',
            borderTopColor: '#fff'
        },
        '.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before': {
            bottom: '-1px',
            borderTopColor: '#f0f0f0'
        },
        '.react-datepicker-wrapper': {
            display: 'block'
        },
        '.react-datepicker': {
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
            fontSize: '0.8rem',
            display: 'inline-block',
            position: 'relative',
            background: theme.palette.common.white,
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius
        },
        '.react-datepicker--time-only .react-datepicker__triangle': {
            left: '35px'
        },
        '.react-datepicker--time-only .react-datepicker__time-container': {
            borderLeft: '0'
        },
        '.react-datepicker--time-only .react-datepicker__time': {
            borderRadius: '0.3rem'
        },
        '.react-datepicker--time-only .react-datepicker__time-box': {
            borderRadius: '0.3rem'
        },
        '.react-datepicker__triangle': {
            position: 'absolute',
            left: '50px'
        },
        '.react-datepicker-popper': {
            zIndex: 1
        },
        '.react-datepicker-popper[data-placement^="bottom"]': {
            marginTop: '10px'
        },
        '.react-datepicker-popper[data-placement^="top"]': {
            marginBottom: '10px'
        },
        '.react-datepicker-popper[data-placement^="right"]': {
            marginLeft: '8px'
        },
        '.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle': {
            left: 'auto',
            right: '42px'
        },
        '.react-datepicker-popper[data-placement^="left"]': {
            marginRight: '8px'
        },
        '.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle': {
            left: '42px',
            right: 'auto'
        },
        '.react-datepicker__header': {
            textAlign: 'center',
            backgroundColor: '#f0f0f0',
            borderBottom: '1px solid #aeaeae',
            paddingTop: '8px',
            position: 'relative'
        },
        '.react-datepicker__header--time': {
            paddingBottom: '8px',
            paddingLeft: '5px',
            paddingRight: '5px'
        },
        '.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll': {
            display: 'inline-block',
            margin: '0 2px'
        },
        '.react-datepicker__current-month, .react-datepicker-time__header': {
            marginTop: '0',
            color: '#000',
            fontSize: '0.944rem'
        },
        '.react-datepicker-time__header': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        '.react-datepicker__navigation': {
            background: 'none',
            lineHeight: '1.7rem',
            textAlign: 'center',
            cursor: 'pointer',
            position: 'absolute',
            top: '10px',
            width: ['0', '10px'],
            padding: '0',
            border: '0.45rem solid transparent',
            zIndex: 1,
            height: '10px',
            textIndent: '-999em',
            overflow: 'hidden'
        },
        '.react-datepicker__navigation--previous': {
            left: '10px',
            borderRightColor: '#ccc'
        },
        '.react-datepicker__navigation--previous:hover': {
            borderRightColor: '#b3b3b3'
        },
        '.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover': {
            borderRightColor: '#e6e6e6',
            cursor: 'default'
        },
        '.react-datepicker__navigation--next': {
            right: '10px',
            borderLeftColor: '#ccc'
        },
        '.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)': {
            right: '80px'
        },
        '.react-datepicker__navigation--next:hover': {
            borderLeftColor: '#b3b3b3'
        },
        '.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover': {
            borderLeftColor: '#e6e6e6',
            cursor: 'default'
        },
        '.react-datepicker__navigation--years': {
            position: 'relative',
            top: '0',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '.react-datepicker__navigation--years-previous': {
            top: '4px',
            borderTopColor: '#ccc'
        },
        '.react-datepicker__navigation--years-previous:hover': {
            borderTopColor: '#b3b3b3'
        },
        '.react-datepicker__navigation--years-upcoming': {
            top: '-4px',
            borderBottomColor: '#ccc'
        },
        '.react-datepicker__navigation--years-upcoming:hover': {
            borderBottomColor: '#b3b3b3'
        },
        '.react-datepicker__month-container': {
            float: 'left'
        },
        '.react-datepicker__month': {
            margin: '0.4rem', textAlign: 'center'
        },
        '.react-datepicker__time-container': {
            float: 'right',
            borderLeft: '1px solid #aeaeae',
            width: '70px'
        },
        '.react-datepicker__time-container--with-today-button': {
            display: 'inline',
            border: '1px solid #aeaeae',
            borderRadius: '0.3rem',
            position: 'absolute',
            right: '-72px',
            top: '0'
        },
        '.react-datepicker__time-container .react-datepicker__time': {
            position: 'relative',
            background: 'white'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
            width: '70px',
            overflowX: 'hidden',
            margin: '0 auto',
            textAlign: 'center'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list': {
            listStyle: 'none',
            margin: '0',
            height: '230px',
            padding: '0',
            overflowY: 'scroll',
            width: '100%',
            boxSizing: 'content-box'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item': {
            padding: '5px 10px'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover': {
            cursor: 'pointer',
            backgroundColor: '#f0f0f0'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected': {
            backgroundColor: theme.palette.primary[500],
            color: 'white',
            fontWeight: 'bold'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover': {
            backgroundColor: theme.palette.primary[500]
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled': {
            color: '#ccc'
        },
        '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover': {
            cursor: 'default',
            backgroundColor: 'transparent'
        },
        '.react-datepicker__week-number': {
            color: '#ccc',
            display: 'inline-block',
            width: '1.7rem',
            lineHeight: '1.7rem',
            textAlign: 'center',
            margin: '0.166rem'
        },
        '.react-datepicker__week-number.react-datepicker__week-number--clickable': {
            cursor: 'pointer'
        },
        '.react-datepicker__week-number.react-datepicker__week-number--clickable:hover': {
            borderRadius: '0.3rem',
            backgroundColor: '#f0f0f0'
        },
        '.react-datepicker__day-names, .react-datepicker__week': {
            whiteSpace: 'nowrap'
        },
        '.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
            color: '#000',
            display: 'inline-block',
            width: '1.7rem',
            lineHeight: '1.7rem',
            textAlign: 'center',
            margin: '0.166rem'
        },
        '.react-datepicker__day': {
            cursor: 'pointer'
        },
        '.react-datepicker__day:hover': {
            borderRadius: theme.shape.borderRadius,
            backgroundColor: '#f0f0f0'
        },
        '.react-datepicker__day--today': {
            fontWeight: 'bold'
        },
        '.react-datepicker__day--highlighted': {
            borderRadius: '0.3rem',
            backgroundColor: '#3dcc4a',
            color: '#fff'
        },
        '.react-datepicker__day--highlighted:hover': {
            backgroundColor: '#32be3f'
        },
        '.react-datepicker__day--highlighted-custom-1': {
            color: 'magenta'
        },
        '.react-datepicker__day--highlighted-custom-2': {
            color: 'green'
        },
        '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range': {
            borderRadius: '0.3rem',
            backgroundColor: theme.palette.primary[500],
            color: '#fff'
        },
        '.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover': {
            backgroundColor: theme.palette.primary[500]
        },
        '.react-datepicker__day--keyboard-selected': {
            borderRadius: '0.3rem',
            backgroundColor: theme.palette.primary[500],
            color: '#fff'
        },
        '.react-datepicker__day--keyboard-selected:hover': {
            backgroundColor: theme.palette.primary[500]
        },
        '.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range)': {
            backgroundColor: theme.palette.primary[500]
        },
        '.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range)': {
            backgroundColor: '#f0f0f0',
            color: '#000'
        },
        '.react-datepicker__day--disabled': {
            cursor: 'default', color: '#ccc'
        },
        '.react-datepicker__day--disabled:hover': {
            backgroundColor: 'transparent'
        },
        '.react-datepicker__input-container': {
            position: 'relative',
            display: 'block'
        },
        '.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view': {
            border: '1px solid transparent',
            borderRadius: '0.3rem'
        },
        '.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover': {
            cursor: 'pointer'
        },
        '.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow': {
            borderTopColor: '#b3b3b3'
        },
        '.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow': {
            borderTopColor: '#ccc',
            float: 'right',
            marginLeft: '20px',
            top: '8px',
            position: 'relative',
            borderWidth: '0.45rem'
        },
        '.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown': {
            backgroundColor: '#f0f0f0',
            position: 'absolute',
            width: '50%',
            left: '25%',
            top: '30px',
            zIndex: 1,
            textAlign: 'center',
            borderRadius: '0.3rem',
            border: '1px solid #aeaeae'
        },
        '.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover': {
            cursor: 'pointer'
        },
        '.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable': {
            height: '150px',
            overflowY: 'scroll'
        },
        '.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option': {
            lineHeight: '20px',
            width: '100%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        '.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type': {
            borderTopLeftRadius: '0.3rem',
            borderTopRightRadius: '0.3rem'
        },
        '.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type': {
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
            borderBottomLeftRadius: '0.3rem',
            borderBottomRightRadius: '0.3rem'
        },
        '.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover': {
            backgroundColor: '#ccc'
        },
        '.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming': {
            borderBottomColor: '#b3b3b3'
        },
        '.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous': {
            borderTopColor: '#b3b3b3'
        },
        '.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected': {
            position: 'absolute',
            left: '15px'
        },
        '.react-datepicker__close-icon': {
            backgroundColor: 'transparent',
            border: '0',
            cursor: 'pointer',
            outline: '0',
            padding: '0',
            verticalAlign: 'middle',
            position: 'absolute',
            height: '16px',
            width: '16px',
            top: '25%',
            right: '7px'
        },
        '.react-datepicker__close-icon::after': {
            backgroundColor: theme.palette.primary[500],
            borderRadius: '50%',
            bottom: '0',
            boxSizing: 'border-box',
            color: '#fff',
            content: '"\\00d7"',
            cursor: 'pointer',
            fontSize: '12px',
            height: '16px',
            width: '16px',
            lineHeight: 1,
            margin: '-8px auto 0',
            padding: '2px',
            position: 'absolute',
            right: '0px',
            textAlign: 'center'
        },
        '.react-datepicker__today-button': {
            background: '#f0f0f0',
            borderTop: '1px solid #aeaeae',
            cursor: 'pointer',
            textAlign: 'center',
            fontWeight: 'bold',
            padding: '5px 0',
            clear: 'left'
        },
        '.react-datepicker__portal': {
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            left: '0',
            top: '0',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            zIndex: 2147483647
        },
        '.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name': {
            width: '3rem',
            lineHeight: '3rem'
        },
        '@media (max-width: 400px), (max-height: 550px)': {
            '.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name': {
                width: '2rem',
                lineHeight: '2rem'
            }
        },
        '.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header': {
            fontSize: '1.44rem'
        },
        '.react-datepicker__portal .react-datepicker__navigation': {
            border: '0.81rem solid transparent'
        },
        '.react-datepicker__portal .react-datepicker__navigation--previous': {
            borderRightColor: '#ccc'
        },
        '.react-datepicker__portal .react-datepicker__navigation--previous:hover': {
            borderRightColor: '#b3b3b3'
        },
        '.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover': {
            borderRightColor: '#e6e6e6',
            cursor: 'default'
        },
        '.react-datepicker__portal .react-datepicker__navigation--next': {
            borderLeftColor: '#ccc'
        },
        '.react-datepicker__portal .react-datepicker__navigation--next:hover': {
            borderLeftColor: '#b3b3b3'
        },
        '.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover': {
            borderLeftColor: '#e6e6e6',
            cursor: 'default'
        }
    }
}));

/**
 * Integration of react-datepicker
 */
export default function DatePicker(props) {
    useStyles();

    return <ReactDatePicker {...props} />;
}
