import React from 'react';
import {darken, makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
// Components
import PartnerLevelIndicator from 'components/core/ui/PartnerLevelIndicator';
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Typography from '@material-ui/core/Typography';
// Icons
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    // block with information about Partner Levels
    partnerBlock: {
        // allow position of Partner Level flag
        position: 'relative',
        '&.highlight': {
            background: theme.palette.grey[100]
        },
        // level variants
        '&.registered a': {
            '&, &:not(.MuiButton-root):hover': {
                color: theme.partnerLevel['registered']
            },
            '&.hover-border:hover:after': {
                background: theme.partnerLevel['registered']
            }
        },
        '&.silver a': {
            '&, &:not(.MuiButton-root):hover': {
                color: darken(theme.partnerLevel['silver'], 0.2)
            },
            '&.hover-border:hover:after': {
                background: darken(theme.partnerLevel['silver'], 0.2)
            }
        },
        '&.gold a': {
            '&, &:not(.MuiButton-root):hover': {
                color: theme.partnerLevel['gold']
            },
            '&.hover-border:hover:after': {
                background: theme.partnerLevel['gold']
            }
        },
        // lower size of 'Benefits' & 'Requirements' titles
        '& h6': {
            fontSize: '1rem'
        }
    },
    // small hr line under partner titles
    partnerTitleDivider: {
        // change size
        height: `${theme.spacing(0.5)}px`,
        width: `${theme.spacing(20)}px`,
        // align
        marginLeft: '0',
        // style
        background: theme.palette.primary[500],
        // level variants
        '&.registered': {
            backgroundColor: theme.partnerLevel['registered'],
            backgroundImage: theme.partnerLevel.gradients['registered']
        },
        '&.silver': {
            backgroundColor: theme.partnerLevel['silver'],
            backgroundImage: theme.partnerLevel.gradients['silver']
        },
        '&.gold': {
            backgroundColor: theme.partnerLevel['gold'],
            backgroundImage: theme.partnerLevel.gradients['gold']
        }
    },
    // flag on the right side
    partnerLevelFlag: {
        // align with partnerLevelBlock
        top: `${(theme.spacing(6))}px`,
        // responsive
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

/**
 * Informative page about Snom Partner Portal program
 */
export default function AboutPortal() {
    const classes = useStyles();

    return <Card>
        <CardHeader
            title={<FormattedMessage id='about.portal.title' />}
            subheader={<FormattedMessage id='about.portal.subheader' />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='about' />
            </ActionButton>} />
        <CardContent>
            <p className='notmargin'><FormattedMessage id='about.portal.text.1' /></p>
            <p><FormattedMessage id='about.portal.text.2' /></p>
            <p><FormattedMessage id='about.portal.text.3' /></p>
            <p><FormattedMessage id='about.portal.text.4' /></p>
            <p><FormattedMessage id='about.portal.text.5' /></p>
            <p><FormattedMessage id='about.portal.text.6' /></p>
            <SpaceDivider />
        </CardContent>
        <CardContent>
            <Typography variant='h5' className='text-center'>
                <FormattedMessage id='about.portal.partners.title' />
            </Typography>
            <SpaceDivider />
        </CardContent>
        <CardContent className={`${classes.partnerBlock} registered highlight`}>
            <PartnerLevelIndicator partnerLevel='registered' className={classes.partnerLevelFlag} />
            <Row wrap>
                <Col width='350px'>
                    <PartnerLevelIndicator partnerLevel='registered' variant='block' noYear />
                </Col>
                <Col>
                    <SpaceDivider />
                    <Typography variant='h4'><FormattedMessage id='about.portal.partners.registered.title' /></Typography>
                    <hr className={`${classes.partnerTitleDivider} registered`} />
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.registered.text.1' /></Typography>
                    <SpaceDivider />
                    <Typography variant='h6'><FormattedMessage id='about.portal.partners.benefits' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.benefits.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.2'
                                              values={{partner_portal_link: <a href='https://partner.snom.com/'
                                                                               target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.2.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.3'
                                              values={{academy_link: <a href='https://academy.snom.com/'
                                                                        target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.3.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.4'
                                              values={{helpdesk_link: <a href='https://helpdesk.snom.com/'
                                                                         target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.4.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.5' /></li>
                        <li>
                            <FormattedMessage id='about.portal.partners.benefits.6' />
                            <ul>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.1' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.2' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.3' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.4' /></li>
                            </ul>
                        </li>
                    </ul>
                    <SpaceDivider />
                    <Typography variant='subtitle2'><FormattedMessage id='about.portal.partners.requirements' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.registered.requirements.1' /></li>
                    </ul>
                    <SpaceDivider />
                </Col>
            </Row>
        </CardContent>
        <CardContent className={`${classes.partnerBlock} silver`}>
            <PartnerLevelIndicator partnerLevel='silver' className={classes.partnerLevelFlag} />
            <Row wrap>
                <Col width='350px'>
                    <PartnerLevelIndicator partnerLevel='silver' variant='block' noYear />
                </Col>
                <Col>
                    <SpaceDivider />
                    <Typography variant='h4'><FormattedMessage id='about.portal.partners.silver.title' /></Typography>
                    <hr className={`${classes.partnerTitleDivider} silver`} />
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.silver.text.1' /></Typography>
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.silver.text.2' /></Typography><br />
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.silver.text.3' /></Typography>
                    <SpaceDivider />
                    <Typography variant='h6'><FormattedMessage id='about.portal.partners.benefits' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.benefits.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.2'
                                              values={{partner_portal_link: <a href='https://partner.snom.com/'
                                                                               target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.2.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.3'
                                              values={{academy_link: <a href='https://academy.snom.com/'
                                                                        target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.3.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.4'
                                              values={{helpdesk_link: <a href='https://helpdesk.snom.com/'
                                                                         target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.4.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.5' /></li>
                        <li>
                            <FormattedMessage id='about.portal.partners.benefits.6' />
                            <ul>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.1' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.2' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.3' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.4' /></li>
                            </ul>
                        </li>
                        <li><FormattedMessage id='about.portal.partners.silver.benefits.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.silver.benefits.2' /></li>
                    </ul>
                    <SpaceDivider />
                    <Typography variant='subtitle2'><FormattedMessage id='about.portal.partners.requirements' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.silver.requirements.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.silver.requirements.2' /></li>
                        <li><FormattedMessage id='about.portal.partners.silver.requirements.3' /></li>
                    </ul>
                    <SpaceDivider />
                </Col>
            </Row>
        </CardContent>
        <CardContent className={`${classes.partnerBlock} gold highlight`}>
            <PartnerLevelIndicator partnerLevel='gold' className={classes.partnerLevelFlag} />
            <Row wrap>
                <Col width='350px'>
                    <PartnerLevelIndicator partnerLevel='gold' variant='block' noYear />
                </Col>
                <Col>
                    <SpaceDivider />
                    <Typography variant='h4'><FormattedMessage id='about.portal.partners.gold.title' /></Typography>
                    <hr className={`${classes.partnerTitleDivider} gold`} />
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.gold.text.1' /></Typography>
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.gold.text.2' /></Typography><br />
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.gold.text.3' /></Typography>
                    <Typography variant='body1'><FormattedMessage id='about.portal.partners.gold.text.4' /></Typography>
                    <SpaceDivider />
                    <Typography variant='h6'><FormattedMessage id='about.portal.partners.benefits' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.benefits.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.2'
                                              values={{partner_portal_link: <a href='https://partner.snom.com/'
                                                                               target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.2.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.3'
                                              values={{academy_link: <a href='https://academy.snom.com/'
                                                                        target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.3.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.4'
                                              values={{helpdesk_link: <a href='https://helpdesk.snom.com/'
                                                                         target='_blank' rel='noreferrer' className='hover-border'>
                                                      <FormattedMessage id='about.portal.partners.benefits.4.link' />
                                                  </a>}} /></li>
                        <li><FormattedMessage id='about.portal.partners.benefits.5' /></li>
                        <li>
                            <FormattedMessage id='about.portal.partners.benefits.6' />
                            <ul>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.1' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.2' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.3' /></li>
                                <li><FormattedMessage id='about.portal.partners.benefits.6.4' /></li>
                            </ul>
                        </li>
                        <li><FormattedMessage id='about.portal.partners.gold.benefits.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.gold.benefits.2' /></li>
                        <li><FormattedMessage id='about.portal.partners.gold.benefits.3' /></li>
                    </ul>
                    <SpaceDivider />
                    <Typography variant='subtitle2'><FormattedMessage id='about.portal.partners.requirements' />{': '}</Typography>
                    <ul>
                        <li><FormattedMessage id='about.portal.partners.gold.requirements.1' /></li>
                        <li><FormattedMessage id='about.portal.partners.gold.requirements.2' /></li>
                        <li><FormattedMessage id='about.portal.partners.gold.requirements.3' /></li>
                        <li><FormattedMessage id='about.portal.partners.gold.requirements.4' /></li>
                    </ul>
                    <SpaceDivider />
                </Col>
            </Row>
        </CardContent>
        <CardContent>
            <p><small>
                <FormattedMessage id='about.portal.partners.stars.1' /><br />
                <FormattedMessage id='about.portal.partners.stars.2' /><br />
                <FormattedMessage id='about.portal.partners.stars.3' /><br />
                <FormattedMessage id='about.portal.partners.stars.4' />
            </small></p>
        </CardContent>
    </Card>;
}
