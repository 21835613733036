import {alpha, createTheme} from '@material-ui/core/styles';

// new SNOM identity (WAVES)
const modern_primary = {
    light: '#32B1E7', 50: '#32B1E7', 100: '#32B1E7', 200: '#32B1E7', 300: '#32B1E7',
    main: '#009EE2', 400: '#009EE2', 500: '#009EE2',
    dark: '#007EB4', 600: '#007EB4', 700: '#007EB4', 800: '#007EB4', 900: '#007EB4',
    contrastText: '#fff'
};
const modern_green = {
    light: '#70CFC8', 50: '#70CFC8', 100: '#70CFC8', 200: '#70CFC8', 300: '#70CFC8',
    main: '#4dc3bb', 400: '#4dc3bb', 500: '#4dc3bb',
    dark: '#3D9C95', 600: '#3D9C95', 700: '#3D9C95', 800: '#3D9C95', 900: '#3D9C95',
    contrastText: '#fff'
};
const modern_red = {
    light: '#ED6880', 50: '#ED6880', 100: '#ED6880', 200: '#ED6880', 300: '#ED6880',
    main: '#E94361', 400: '#E94361', 500: '#E94361',
    dark: '#BA354D', 600: '#BA354D', 700: '#BA354D', 800: '#BA354D', 900: '#BA354D',
    contrastText: '#fff'
};
const modern_yellow = {
    light: '#F1DE6C', 50: '#F1DE6C', 100: '#F1DE6C', 200: '#F1DE6C', 300: '#F1DE6C',
    main: '#EED648', 400: '#EED648', 500: '#EED648',
    dark: '#BEAB39', 600: '#BEAB39', 700: '#BEAB39', 800: '#BEAB39', 900: '#BEAB39',
    contrastText: '#fff'
};
const modern_orange = {
    light: '#F19970', 50: '#F19970', 100: '#F19970', 200: '#F19970', 300: '#F19970',
    main: '#EE804D', 400: '#EE804D', 500: '#EE804D',
    dark: '#BE663D', 600: '#BE663D', 700: '#BE663D', 800: '#BE663D', 900: '#BE663D',
    contrastText: '#fff'
};
const noneShadow = '0px 0px 0px 0px transparent, 0px 0px 0px 0px transparent, 0px 0px 0px 0px transparent';
const innerShadow = `0px 1px 5px ${alpha('#000', 0.1)} inset`;

const defaultOptions = {
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl'],
        values: {
            xs: 0,
            sm: 576, // 600
            md: 992, // 960
            lg: 1200, // 1280
            xl: 1900 // 1920
        }
    },
    partnerLevel: {
        registered: '#1387ca',
        silver: '#9b999c',
        gold: '#cf9f2a',
        gradients: {
            registered: 'linear-gradient(135deg, #0069b4 0%, #82d0f5 100%)',
            silver: 'linear-gradient(135deg, #888787 0%, #d9d9d9 100%)',
            gold: 'linear-gradient(135deg, #ca9618 0%, #fff9c7 100%)'
        }
    },
    graphs: {
        1: '#009EE2', // modern_primary (blue)
        2: '#31B7BC', // cyan
        3: '#1A6E99', // blue-green
        4: '#78D49D', // green
        5: '#EED648', // yellow
        'danger': '#E94361' // modern_red
    },
    noneShadow: noneShadow,
    innerShadow,
    softShadow: `0px 1px 20px ${alpha('#000', 0.15)}`,
    gradients: {
        blue: {
            color1: '#009EE2',
            color2: '#83E5E2',
            color: 'linear-gradient(135deg, #009EE2 50%, #83E5E2 100%)',
            backgroundSize: '200% 200%',
            position: '100% 100%',
            hoverPosition: '0% 0%'
        },
        green: {
            color1: '#31B7BC',
            color2: '#78D49D',
            color: 'linear-gradient(90deg, #31B7BC 0%, #78D49D 50%)',
            backgroundSize: '200% 100%',
            position: '0% 0%',
            hoverPosition: '100% 0%'
        },
        red: {
            color1: '#b63e53',
            color2: '#E94361',
            color: 'linear-gradient(135deg, #b63e53 0%, #E94361 50%)',
            backgroundSize: '200% 200%',
            position: '0% 0%',
            hoverPosition: '100% 100%'
        },
        orange: {
            color1: '#EED648',
            color2: '#EE804D',
            color: 'linear-gradient(135deg, #EED648 0%, #EE804D 50%)',
            backgroundSize: '200% 200%',
            position: '0% 0%',
            hoverPosition: '100% 100%'
        },
        gray: {
            color1: '#AEB9C0',
            color2: '#4A5D68',
            color: 'linear-gradient(145deg, #AEB9C0 30%, #4A5D68 140%)'
        },
        wild: {
            color1: '#31B7BC',
            color2: '#1A6E99',
            color3: '#F08046',
            color: 'linear-gradient(135deg, #31B7BC 0%, #1A6E99 10%, #F08046 20%, ' +
                '#1A6E99 30%, #31B7BC 40%, #F08046 50%, #31B7BC 60%, #1A6E99 70%, ' +
                '#F08046 80%, #1A6E99 90%, #31B7BC 100%)',
            backgroundSize: '500% 100%',
            position: '0% 0%',
            hoverPosition: '100% 0%'
        }
    }
}; // common theme used across all templates
const default_theme = createTheme(defaultOptions);

const modernOptions = {
    ...defaultOptions,
    palette: {
        primary: modern_primary,
        secondary: modern_green,
        snomGrey: {
            100: '#f7fcfe',
            200: '#f7f9fa',
            400: '#AEB9C0',
            800: '#326d9b',
            900: '#185173',
            dark: '#4A5D68'
        },
        success: modern_green,
        green: modern_green,
        yellow: modern_yellow,
        orange: modern_orange,
        danger: modern_red
    },
    shape: {
        borderRadius: 8
    },
    fileDrop: {
        background: 'rgba(0, 0, 0, 0.6)'
    },
    overrides: {
        MuiPaper: {
            root: {
                boxShadow: 'none'
            },
            elevation1: {
                boxShadow: 'none'
            },
            elevation4: {
                boxShadow: 'none'
            }
        },
        MuiSvgIcon: {
            root: {
                transition: default_theme.transitions.create('fill',
                    {duration: default_theme.transitions.duration.short})
            }
        },
        MuiLinearProgress: {
            root: {
                // prepare ground for gradients
                '&:before': {
                    content: '""',
                    display: 'block',
                    // full-size
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    // style
                    backgroundRepeat: 'no-repeat',
                    opacity: '0.5'
                }
            },
            colorPrimary: {
                backgroundColor: 'transparent',
                '&:before': {
                    backgroundImage: default_theme.gradients.blue.color,
                    backgroundSize: default_theme.gradients.blue.backgroundSize,
                    backgroundPosition: default_theme.gradients.blue.position
                }
            },
            colorSecondary: {
                backgroundColor: 'transparent',
                '&:before': {
                    backgroundImage: default_theme.gradients.green.color,
                    backgroundSize: default_theme.gradients.green.backgroundSize,
                    backgroundPosition: default_theme.gradients.green.position
                }
            }
        },
        MuiIconButton: {
            root: {
                // more smooth transitions
                transition: default_theme.transitions.create(['opacity', 'background', 'border', 'box-shadow', 'color'],
                    {duration: default_theme.transitions.duration.short}),
                '&$disabled': {
                    color: undefined, // overwrite default MUI color change
                    opacity: default_theme.palette.action.disabledOpacity
                }
            }
        },
        MuiButton: {
            // note: custom ActionButton is styled in its own component
            root: {
                // proportion
                padding: `${default_theme.spacing(1)}px ${default_theme.spacing(3)}px`,
                // style
                borderRadius: '100px', // all rounded
                // prepare ground for gradients
                backgroundRepeat: 'no-repeat',
                // more smooth transitions
                transition: default_theme.transitions.create(['opacity', 'background', 'border', 'box-shadow', 'color'],
                    {duration: default_theme.transitions.duration.short}),
                '&$disabled': {
                    color: undefined, // overwrite default MUI color change
                    opacity: default_theme.palette.action.disabledOpacity
                }
            },
            text: {
                // proportion
                padding: `${default_theme.spacing(1)}px ${default_theme.spacing(2)}px`
            },
            label: {
                fontWeight: default_theme.typography.fontWeightMedium,
                textTransform: 'none'
            },
            contained: {
                boxShadow: default_theme.shadows[2],
                '&:hover': {
                    boxShadow: default_theme.shadows[4]
                },
                '&$disabled': {
                    color: undefined // overwrite default MUI color change
                }
            },
            containedPrimary: {
                backgroundImage: default_theme.gradients.blue.color,
                backgroundSize: default_theme.gradients.blue.backgroundSize,
                backgroundPosition: default_theme.gradients.blue.position,
                '&:hover': {
                    backgroundPosition: default_theme.gradients.blue.hoverPosition
                }
            },
            containedSecondary: {
                backgroundImage: default_theme.gradients.green.color,
                backgroundSize: default_theme.gradients.green.backgroundSize,
                backgroundPosition: default_theme.gradients.green.position,
                '&:hover': {
                    backgroundPosition: default_theme.gradients.green.hoverPosition
                }
            },
            outlined: {
                // lower padding due to border
                padding: `${default_theme.spacing(1) - 1}px ${default_theme.spacing(3) - 1}px`,
                // border gradient
                boxShadow: `${noneShadow}, 0px 1000px 0 ${default_theme.palette.common.white} inset`,
                backgroundOrigin: 'border-box',
                // label
                '& > .MuiButton-label': {
                    transition: default_theme.transitions.create('background',
                        {duration: default_theme.transitions.duration.short}),
                    // prepare ground for gradients
                    backgroundRepeat: 'no-repeat'
                },
                '&:hover': {
                    boxShadow: `${default_theme.shadows[2]}, 0px 1000px 0 ${default_theme.palette.common.white} inset`
                },
                '&$disabled': {
                    color: undefined // overwrite default MUI color change
                }
            },
            outlinedPrimary: {
                backgroundImage: default_theme.gradients.blue.color,
                backgroundSize: default_theme.gradients.blue.backgroundSize,
                backgroundPosition: default_theme.gradients.blue.position,
                // label
                '& > .MuiButton-label': {
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: default_theme.gradients.blue.color,
                    backgroundSize: default_theme.gradients.blue.backgroundSize,
                    backgroundPosition: default_theme.gradients.blue.position
                },
                '&, &:hover': {
                    border: '1px solid transparent'
                },
                '&:hover': {
                    backgroundPosition: default_theme.gradients.blue.hoverPosition,
                    '& > .MuiButton-label': {
                        backgroundPosition: default_theme.gradients.blue.hoverPosition
                    }
                }
            },
            outlinedSecondary: {
                backgroundImage: default_theme.gradients.green.color,
                backgroundSize: default_theme.gradients.green.backgroundSize,
                backgroundPosition: default_theme.gradients.green.position,
                // label
                '& > .MuiButton-label': {
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: default_theme.gradients.green.color,
                    backgroundSize: default_theme.gradients.green.backgroundSize,
                    backgroundPosition: default_theme.gradients.green.position
                },
                '&, &:hover': {
                    border: '1px solid transparent'
                },
                '&:hover': {
                    backgroundPosition: default_theme.gradients.green.hoverPosition,
                    '& > .MuiButton-label': {
                        backgroundPosition: default_theme.gradients.green.hoverPosition
                    }
                }
            }
        },
        // toggle switch
        MuiSwitch: {
            root: {
                // tweak size
                padding: `${default_theme.spacing(0.75)}px`
            },
            thumb: {
                color: default_theme.palette.common.white
            },
            track: {
                borderRadius: `${default_theme.spacing(5)}px`
            },
            switchBase: {
                '&$checked + $track': {
                    opacity: '1'
                }
            }
        }
    }
};
const modern = createTheme(modernOptions);
const modern_portal = createTheme({
    ...modernOptions,
    palette: {
        ...modernOptions.palette,
        primary: modern_green,
        snomGrey: {
            100: '#F7FCFE',
            200: '#F1F1F1',
            300: '#D3D9DD',
            400: '#AEB9C0',
            800: '#4A5D68',
            dark: '#4A5D68'
        }
    },
    overrides: {
        ...modernOptions.overrides,
        MuiLinearProgress: {
            ...modernOptions.overrides.MuiLinearProgress,
            colorPrimary: {
                ...modernOptions.overrides.MuiLinearProgress.colorSecondary
            }
        },
        MuiButton: {
            ...modernOptions.overrides.MuiButton,
            containedPrimary: {
                ...modernOptions.overrides.MuiButton.containedSecondary
            },
            outlinedPrimary: {
                ...modernOptions.overrides.MuiButton.outlinedSecondary
            }
        }
    }
});
const modernOptions_altTheme = {
    ...modernOptions,
    palette: {
        ...modernOptions.palette,
        primary: modern_green, // success
        secondary: modern_red // danger
    },
    overrides: {
        ...modernOptions.overrides,
        MuiButton: {
            ...modernOptions.overrides.MuiButton,
            containedPrimary: {
                ...modernOptions.overrides.MuiButton.containedSecondary
            },
            containedSecondary: {
                backgroundImage: default_theme.gradients.red.color,
                backgroundSize: default_theme.gradients.red.backgroundSize,
                backgroundPosition: default_theme.gradients.red.position,
                '&:hover': {
                    backgroundPosition: default_theme.gradients.red.hoverPosition
                }
            },
            outlinedPrimary: {
                ...modernOptions.overrides.MuiButton.outlinedSecondary
            },
            outlinedSecondary: {
                backgroundImage: default_theme.gradients.red.color,
                backgroundSize: default_theme.gradients.red.backgroundSize,
                backgroundPosition: default_theme.gradients.red.position,
                // label
                '& > .MuiButton-label': {
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundImage: default_theme.gradients.red.color,
                    backgroundSize: default_theme.gradients.red.backgroundSize,
                    backgroundPosition: default_theme.gradients.red.position,
                    '&:hover': {
                        backgroundPosition: default_theme.gradients.red.hoverPosition
                    }
                },
                '&, &:hover': {
                    border: '1px solid transparent'
                },
                '&:hover': {
                    backgroundPosition: default_theme.gradients.red.hoverPosition
                }
            }
        },
        MuiLinearProgress: {
            ...modernOptions.overrides.MuiLinearProgress,
            colorPrimary: {
                ...modernOptions.overrides.colorPrimary,
                '&:before': {
                    backgroundImage: default_theme.gradients.green.color,
                    backgroundSize: default_theme.gradients.green.backgroundSize,
                    backgroundPosition: default_theme.gradients.green.position
                }
            },
            colorSecondary: {
                ...modernOptions.overrides.colorSecondary,
                '&:before': {
                    backgroundImage: default_theme.gradients.red.color,
                    backgroundSize: default_theme.gradients.red.backgroundSize,
                    backgroundPosition: default_theme.gradients.red.position
                }
            }
        }
    }
};
const modern_altTheme = createTheme(modernOptions_altTheme);
const modern_portal_altTheme = modern_altTheme;
const wild_theme = createTheme({
    ...modernOptions,
    overrides: {
        ...modernOptions.overrides,
        palette: {
            ...modernOptions.palette,
            primary: modern_orange
        },
        MuiButton: {
            ...modernOptions.overrides.MuiButton,
            // colorful
            containedPrimary: {
                backgroundImage: default_theme.gradients.wild.color,
                backgroundSize: default_theme.gradients.wild.backgroundSize,
                backgroundPosition: default_theme.gradients.wild.position,
                transition: default_theme.transitions.create(['opacity', 'background', 'box-shadow', 'color'],
                    {duration: 1500}),
                '&:hover': {
                    backgroundPosition: default_theme.gradients.wild.hoverPosition
                }
            },
            // orange
            containedSecondary: {
                backgroundImage: default_theme.gradients.orange.color,
                backgroundSize: default_theme.gradients.orange.backgroundSize,
                backgroundPosition: default_theme.gradients.orange.position,
                '&:hover': {
                    backgroundPosition: default_theme.gradients.orange.hoverPosition
                }
            }
        },
        MuiLinearProgress: {
            ...modernOptions.overrides.MuiLinearProgress,
            colorPrimary: {
                backgroundColor: 'transparent',
                '&:before': {
                    opacity: '1',
                    backgroundImage: default_theme.gradients.wild.color,
                    backgroundSize: default_theme.gradients.wild.backgroundSize,
                    backgroundPosition: default_theme.gradients.blue.position
                }
            },
            barColorPrimary: {
                backgroundColor: modern_orange['main']
            }
        }
    }
});

// beware of direct 'import {theme} from 'theme/index';'
//  - it imports sraps theme and not current one
export const defaultTheme = default_theme;
// Primary application theme
export const theme = modern;
export const portalTheme = modern_portal;
// Alt-theme for Success and Danger
export const altTheme = modern_altTheme;
export const portalAltTheme = modern_portal_altTheme;
// Wild experimental theme
export const wildTheme = wild_theme;
