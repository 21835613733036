import React, {useMemo, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {Distributor} from 'lib/models';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {countries} from 'lib/countrieslist';
import {Map as ImmutableMap} from 'immutable';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {CountryField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    // holder of all distributors
    distributorsHolder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        // to sides
        margin: `-${theme.spacing(1)}px`
    },
    // holder of distributor
    distributorHolder: {
        width: `${100 / 7}%`,
        // limits shrink and grow
        minWidth: `${theme.spacing(25)}px`,
        maxWidth: `${theme.spacing(29)}px`,
        // space between items
        padding: `${theme.spacing(1)}px`
    },
    // button itself
    distributorButton: {
        // 1:1 ratio
        paddingTop: '100%',
        width: '100%',
        // style
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        // hide shine
        overflow: 'hidden',
        // fix cursor for no-link button
        '&.no-link': {
            cursor: 'auto'
        },
        // link hover effect
        '&.link:hover img': {
            // enlarge logo
            transform: 'scale(1)'
        },
        // hover effect (both no-link and link)
        '&:hover': {
            '& .country': {
                transform: 'translateX(0)'
            },
            '& $shine': {
                opacity: '1',
                // trigger shine animation
                animationName: '$shine',
                animationDuration: '1s',
                animationDelay: theme.transitions.duration.short,
                animationTimingFunction: 'ease-in-out',
                animationIterationCount: '1'
            }
        }
    },
    // text, logo, country
    distributorContent: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        // above shine
        zIndex: '20',
        // extra space for text
        padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(8)}px`,
        // align logo
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // logo itself
        '& img': {
            maxHeight: '100%',
            transform: 'scale(0.9)',
            transition: theme.transitions.create('transform',
                {duration: theme.transitions.duration.short})
        },
        // text
        '& .name': {
            // move between logo and border
            position: 'absolute',
            bottom: `${theme.spacing(4)}px`,
            left: '0',
            right: '0',
            padding: `0 ${theme.spacing(0.5)}px`,
            // ensure text is not recolored with hover link
            color: theme.palette.text.primary,
            // horizontally and vertically center
            height: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        // country badge
        '& .country': {
            // move to the right side
            position: 'absolute',
            top: `${theme.spacing(2)}px`,
            right: '0',
            // proportion
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            // style
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            borderLeft: `1px solid ${theme.palette.divider}`,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            ...theme.typography.body2,
            fontSize: theme.typography.pxToRem(12),
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.text['primary'],
            // color: theme.palette.common.white,
            userSelect: 'none',
            transition: theme.transitions.create('transform',
                {duration: theme.transitions.duration.short}),
            // hide by default
            transform: 'translateX(100%)'
        }
    },
    shine: {
        position: 'absolute',
        top: '-110%',
        right: '-50%',
        width: '50%',
        height: '200%',
        // bellow content
        zIndex: '10',
        // style
        background: `linear-gradient(90deg, transparent, ${theme.palette.grey[200]}, transparent)`,
        transform: 'rotateZ(-45deg)',
        // hide by default
        opacity: '0',
        transition: theme.transitions.create('opacity',
            {duration: theme.transitions.duration.short})
    },
    '@keyframes shine': {
        '0%': {
            top: '-110%',
            right: '-50%'
        },
        '100%': {
            top: '0%',
            right: '110%'
        }
    }
}));

/**
 * Renders Distributors from backend for customers - to view only
 */
export default function CustomerList() {
    const classes = useStyles();
    // redux store
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', 'distributors']),
        items: state.shared.getIn(['items', 'distributors'])
    }), shallowEqual);
    const dispatch = useDispatch();
    // sorting & filtering
    const [sortedItems,,,, sortInitializing] = useLocalSort(props.items, !props.loaded);
    const [locallyFilteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, `${new Distributor().getPlacement()}-customer`, ['country']);
    const filteredItems = useMemo(() => {
        return locallyFilteredItems.filter(distributor => distributor.get('active'));
    }, [locallyFilteredItems]);
    // get list of countries with distributors in them
    const used_countries = useMemo(() => {
        if (props.loaded) {
            const items_countries = props.items.map(item => item.get('country')).toSet().toList();
            return countries.filter(country => items_countries.includes(country.get('id')));
        } else {
            return [];
        }
    }, [props.loaded]);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Distributor, 'distributors', 'distributors'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='distributors.customerlist.title' />}
            subheader={<FormattedMessage id='distributors.customerlist.subheader' />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='distributors' />
            </ActionButton>}
        />
        <Filters>
            <CountryField
                label={<FormattedMessage id='distributors.customerlist.filter.country' />}
                fieldType='NoReduxSelect' value={filters.country || ''}
                onChange={event => filterItems('country', event.target.value)}
                countries={used_countries}
                disabled={props.state === 'fetching_items_distributors' || filtersInitializing}
            />
        </Filters>
        {props.state === 'fetching_items_distributors' || filtersInitializing
            ? <CardContent>
                <SpaceDivider half />
                <SpaceDivider loading />
                <LinearProgress />
                <SpaceDivider loading />
            </CardContent>
            : (!!Object.keys(filters).length && !filteredItems.size) ? <CardContent className='text-center'>
                <SpaceDivider half />
                <SpaceDivider loading />
                <Typography variant='subtitle2'>
                    <FormattedMessage id='filters.empty' />
                </Typography>
                <SpaceDivider loading />
            </CardContent>
            : props.loaded && <CardContent>
                <div className={classes.distributorsHolder}>
                    {filteredItems.map((distributor) => <div className={classes.distributorHolder} key={distributor.get(new Distributor().getUniqueIdentifier())}>
                        <ButtonBase className={`${classes.distributorButton} ${distributor.get('url') ? 'link' : 'no-link'}`}
                                    component={distributor.get('url') ? 'a' : 'div'}
                                    disableRipple={!distributor.get('url')}
                                    href={distributor.get('url') || undefined}
                                    target={distributor.get('url') ? '_blank' : undefined} rel='noreferrer'>
                            <div className={classes.shine} />
                            <div className={classes.distributorContent}>
                                <img src={distributor.get('logo_url') ? distributor.get('logo_url') : require('img/portal/distributor_preview.png')}
                                     alt={distributor.get('name')} />
                                <Typography variant='subtitle2' className='name'>{distributor.get('name')}</Typography>
                                {distributor.get('country') && <div className='country'>{(countries.find(country => country.get('id') === distributor.get('country')) || new ImmutableMap({name: distributor.get('country')})).get('name')}</div>}
                            </div>
                        </ButtonBase>
                    </div>)}
                </div>
            </CardContent>}
    </Card>;
};
