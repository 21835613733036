import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {theme} from 'theme';
// material-ui
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
    list: ({inline}) => ({
        ...(inline && {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap'
        })
    }),
    listItem: ({inline}) => ({
        color: theme.palette.text['primary'],
        ...(inline && {
            flexDirection: 'row-reverse',
            flex: '1 1 33%'
        }),
        '&:hover': {color: theme.palette.text['primary']},
        '& .MuiListItemText-root': {lineHeight: '1'},
        '& .MuiListItemAvatar-root': {
            minWidth: '0',
            ...(inline ? {marginRight: `${theme.spacing(2)}px`} : {marginLeft: `${theme.spacing(2)}px`})
        },
        '& .MuiAvatar-root': {
            width: `${theme.spacing(9)}px`,
            fontSize: theme.typography.pxToRem(16),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.primary[500]}`,
            color: theme.palette.primary[500]
        }
    })
}));

/**
 * Render data in list
 * @param {object} props - component props
 * @param {'inline-list' | 'list'} props.type - list type
 * @param {import('react-apexcharts').Props['options']} props.options - config based on apexchart
 * @param {object} props.data - data to display
 * @param {(label: string) => string} props.formatLabel - format label
 * @param {<T>(value: T) => T} props.formatValue - format data
 */
export default function List(props) {
    const {type = 'list', formatLabel, formatValue, options = {}} = props;
    const intl = useIntl();
    const classes = useStyles({inline: type === 'inline-list'});

    // process data
    const data = useMemo(() => {
        if (props.data) {
            let dataToSet = props.data;
            // translate object to array
            if (!(dataToSet instanceof Array)) {
                dataToSet = Object.entries(props.data).map(([label, value]) => ({label, value}));
            }
            // set link to each item
            const {link, link_id, base_intl, value_key = 'value', label_key = 'label', labels} = options;
            if (link || base_intl || value_key || label_key) {
                dataToSet = dataToSet.map((item) => {
                    const label = item[label_key];
                    const value = item[value_key];

                    return {
                        ...item,
                        label: formatLabel ? formatLabel(label) : label,
                        value: formatValue ? formatValue(value) : value,
                        link: link ? `${link}${link_id ? item[link_id] : ''}` : undefined
                    };
                });
            }
            // override and filter by lables
            if (labels) {
                dataToSet = dataToSet.map((item, index) => labels[index] ? {
                    ...item,
                    label: base_intl ? intl.formatMessage({id: `${base_intl}.${labels[index]}`}) : labels[index]
                } : null).filter(Boolean);
            }

            return dataToSet;
        }

        return [];
    }, [JSON.stringify(props.data), JSON.stringify(options), !formatLabel, !formatValue]);

    return <MuiList disablePadding className={classes.list}>
        {data?.map((item, index) => {
            const {colors, links = [], link, link_id} = options;
            const color = colors?.[index];
            const linkTo = links[index] || link ? `${links[index] || link}${link_id ? item[link_id] : ''}` : '';

            return <ListItem key={index} className={classes.listItem} component={linkTo ? Link : undefined} button={!!linkTo} to={linkTo}>
                <ListItemText>{item.label}</ListItemText>
                <ListItemAvatar>
                    <Avatar style={color && {backgroundColor: color, borderColor: color, color: theme.palette.common.white}}>{item.value}</Avatar>
                </ListItemAvatar>
            </ListItem>;
        })}
    </MuiList>;
}
