import React from 'react';
// material-ui
import MUICheckbox from '@material-ui/core/Checkbox';


/**
 * Extends Checkbox component, changed default props
 */
export default function Checkbox(props) {
    // Split some stuff from props
    const {color = 'primary', ...rest_of_props} = props;

    return <MUICheckbox
        color={color}
        {...rest_of_props}
    />;
}
