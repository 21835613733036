import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import JSONPretty from 'lib/JSONPretty';
// components
import {JSONFieldTheme} from 'components/core/ui/fields/JSONField';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// icons
import OpenIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/UndoOutlined';


/**
 * Show RAW data button with dialog
 */
export default function RawDialog(props) {
    const {data} = props;
    // local state
    const [open, setOpen] = useState(false); // flag for dialog open/close state

    return <React.Fragment>
        <ActionButton variant='outlined' onClick={() => setOpen(!open)}>
            <OpenIcon />
            <FormattedMessage id='voicequality.rawdialog.show' />
        </ActionButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <FormattedMessage id='voicequality.rawdialog.title' />
            </DialogTitle>
            <DialogContent>
                <JSONPretty json={data ? data.toJS() : null} theme={JSONFieldTheme} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>
                    <CancelIcon />
                    <FormattedMessage id='actions.back' />
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}
