import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormattedMessage} from 'react-intl';
import {reduxForm} from 'redux-form';
// Actions
import {addMessage, setState} from 'actions/app';
import {simplePost} from 'actions/shared';
import {PhoneLinkMigrationReport, PhoneLinkSource} from 'lib/models';
// Components
import Button from 'components/core/ui/mui/Button';
import Form from 'components/core/ui/Form';
// MUI
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// Icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import PlayIcon from '@material-ui/icons/PlayArrow';

// Dialog content for starting a new PhoneLink migration.
function PhoneLinkMigrationDialog(props) {
    return <Dialog open={props.open}
                   classes={props.classes}
                   onClose={props.handleClose}>
        <DialogTitle>
            <FormattedMessage id='phonelink.detail.dialog.options'/>
        </DialogTitle>
        <DialogContent>
            <hr/>
            <Form onSubmit={props.handleSubmit}>

            <Typography>{props.phonelink_source.get('strategy_description')}</Typography>
            </Form>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>
                <CancelIcon/>
                <FormattedMessage id='actions.cancel'/>
            </Button>

            <Button
                variant='contained'
                color='primary'
                disabled={props.state === `posting_${props.phonelink_source_placement}`}
                onClick={props.handleSubmit}>
                <PlayIcon/>
                <FormattedMessage id='phonelink.detail.dialog.options.start'/>
            </Button>
        </DialogActions>
    </Dialog>;
}

const validate = (data, props) => {
    // TODO: Implement proper validation.
    const errors = {};
    return errors;
};

const PhoneLinkMigrationForm = reduxForm({
    form: 'phoneLinkMigrationForm',
    validate,
    enableReinitialize: true,
    onSubmit(values, dispatch, props) {
        props.setState(null);

        props.simplePost(
            `saving_item_${props.phonelink_migration_report_placement}`,
            props.phonelink_source.getIn(['links', 'migration-reports'])
        ).then(() => {
            props.addMessage({
                intl_id: 'phonelink.detail.migration_completed',
                type: 'success',
                path: 'on-change'
            });
            props.setState(null);
        }).catch(error => {
            props.addMessage({
                text: error.errors,
                type: 'error',
                path: 'on-change'
            });
        });

        props.handleClose();
    }
})(PhoneLinkMigrationDialog);

const ConnectedPhoneLinkMigrationDialog = connect((state, props) => {
    const phonelink_source_placement = new PhoneLinkSource().getPlacement();
    const phonelink_migration_report_placement = new PhoneLinkMigrationReport().getPlacement();

    return {
        phonelink_source_placement,
        phonelink_migration_report_placement
    };
}, dispatch => bindActionCreators({
    setState,
    addMessage,
    simplePost
}, dispatch))(PhoneLinkMigrationForm);

export default ConnectedPhoneLinkMigrationDialog;
