import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {marked} from 'marked';
// material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';


/**
 * Dialog with Terms of Services
 */
export default function ToSDialog(props) {
    const {open, handleClose} = props;
    const intl = useIntl();

    /**
     * Render section of text
     *
     * @param idx - ID of Section
     * @param paragraphs - Number of paragraphs
     * @param ol - Number of List items under paragraphs
     * @param link - Marks paragraph a link
     */
    const renderSection = (idx, paragraphs, ol, link) => {
        let markedRenderer = new marked.Renderer();
        // remove wrapping text in additional <p>
        markedRenderer.paragraph = text => text;

        return <div key={idx + 1}>
            <h5 className={idx + 1 === 1 ? 'nomargin' : 'nobmargin'}>{`${idx + 1}. `}<FormattedMessage id={`tos.section.${idx + 1}.title`} /></h5>
            {[...new Array(paragraphs)].map((el, pidx) =>
                link === pidx + 1
                    ? <p key={pidx + 1}>
                        <a href={intl.formatMessage({id: `tos.section.${idx + 1}.paragraph.${pidx + 1}`})} target='_blank' rel='noreferrer' className='hover-border'>
                            <FormattedMessage id={`tos.section.${idx + 1}.paragraph.${pidx + 1}`} />
                        </a>
                    </p>
                    : <p key={pidx + 1} className='text-justify' dangerouslySetInnerHTML={{__html:
                            marked(intl.formatMessage({id: `tos.section.${idx + 1}.paragraph.${pidx + 1}`}), {breaks: true, renderer: markedRenderer})
                    }} />
            )}
            {ol && <ol type='I'>
                {[...new Array(ol)].map((el, olidx) =>
                    <li key={olidx + 1} dangerouslySetInnerHTML={{__html:
                            marked(intl.formatMessage({id: `tos.section.${idx + 1}.list.${olidx + 1}`}), {breaks: true, renderer: markedRenderer})
                    }} />
                )}
            </ol>}
        </div>;
    };

    return <Dialog open={open} onClose={handleClose}>
        <DialogTitle className='text-center'>
            <FormattedMessage id='tos.title' /><br />
            <FormattedMessage id='tos.subheader' />
        </DialogTitle>
        <DialogContent>
            {[
                {p: 4, link: 3}, // 1
                {p: 2}, // 2
                {p: 1}, // 3
                {p: 2}, // 4
                {p: 1}, // 5
                {p: 3, link: 2}, // 6
                {p: 1}, // 7
                {p: 2, ol: 3}, // 8
                {p: 1, ol: 3}, // 9
                {p: 1}, // 10
                {p: 1}, // 11
                {p: 2}, // 12
                {p: 1}, // 13
                {p: 1} // 14
            ].map((section, idx) =>
                renderSection(idx, section.p, section.ol, section.link)
            )}
        </DialogContent>
    </Dialog>;
}
