import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {Endpoint, Company} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {setState} from 'actions/app';
import {fetchItem} from 'actions/shared';
// Components
import Form from 'components/core/ui/Form';
import Field, {FieldWithIconHolder, FieldIcon} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    form: {
        // better fit next to other Filters fields
        marginLeft: '0',
        marginRight: '0'
    },
    fieldIcon: {
        // match <Filters /> background
        background: theme.palette.snomGrey[200]
    }
}));

/**
 * Search for specific Endpoint, expected to be in <Filters /> component
 * props.selectedCompany must be provided if we are not searching in all endpoints
 */
function Search(props) {
    const classes = useStyles();

    return <Form onSubmit={props.handleSubmit} className={classes.form} formLike>
        <FieldWithIconHolder>
            <Field name='mac' fieldType='TextField' disabled={props.state !== null}
                   excludePermission label={<FormattedMessage id='endpoints.detail.form.fields.mac' />}
                   helperText={<FormattedMessage id={props.location.pathname.startsWith('/all-')
                       ? 'endpoints.search.global.form.fields.mac.help'
                       : 'endpoints.search.form.fields.mac.help'} />}
                   withIcon iconWidth={120} />
            <FieldIcon button iconWidth={120} className={classes.fieldIcon}>
                <ActionButton
                    disabled={props.state !== null || (!props.location.pathname.startsWith('/all-') && !props.selectedCompany)}
                    onClick={() => props.handleSubmit()}
                    variant='outlined' color='primary' type='submit'
                    loading={[
                        'fetching_item_endpoints-search', 'failed_fetch_item_endpoints-search', 'endpoints-search_success'
                    ].includes(props.state)}
                    success={props.state === 'endpoints-search_success'}
                    failure={['failed_fetch_item_endpoints-search'].includes(props.state)}
                    postAnimation={success => {
                        props.setState(null);
                        if (success) {
                            props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}phones/${props.item.get(new Endpoint().getUniqueIdentifier())}${props.item.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${props.item.get('company')}` : ''}`);
                        }
                    }}>
                    <SearchIcon />
                    <FormattedMessage id='actions.search' />
                </ActionButton>
            </FieldIcon>
        </FieldWithIconHolder>
    </Form>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    if (data.mac) {
        validator.isHexadecimal(props.intl.formatMessage({id: 'errors.validator.mac'}), errors, 'mac', data.mac) &&
        validator.isLength(props.intl.formatMessage({id: 'errors.validator.mac'}), errors, 'mac', data.mac, {min: 12, max: 12});
    }

    return errors;
};

const SearchForm = reduxForm({
    form: 'endpointSearch',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        if (!props.formValues || !props.formValues.mac || (!props.location.pathname.startsWith('/all-') && !props.selectedCompany)) {
            return;
        }

        // make search for Endpoint
        return dispatch(fetchItem(Endpoint, props.items_placement, [
                props.location.pathname.startsWith('/all-')
                    ? 'endpoints'
                    : props.selectedCompany.getIn(['links', 'endpoints']),
                props.formValues.mac
            ],
            {
                success_state: 'endpoints-search_success',
                failure_state: 'failed_fetch_item_endpoints-search',
                state_name: 'endpoints-search'
            }
        ));
    }
})(Search);

const ConnectedSearch = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
    // ensure we don't create duplicate 'my' collection with UUID
    const company_identifier = searchParams.get('company') && searchParams.get('company') !== company.get(new Company().getUniqueIdentifier())
        ? searchParams.get('company')
        : 'my';

    const formValues = getFormValues('endpointSearch')(state);
    const value = formValues && formValues.mac ? formValues.mac.toLowerCase() : 'unknown';

    const items_placement = `${new Endpoint().getPlacement()}${props.location.pathname.startsWith('/all-') ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;
    const item = (state.shared.getIn(['items', items_placement]) || ImmutableList()).find(el => el.get(new Endpoint().getUniqueIdentifier()) === value);

    return {
        state: state.app.get('state'),
        company_identifier: company_identifier,
        item: item,
        items_placement: items_placement,
        company: company,
        formValues: formValues
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem
}, dispatch))(SearchForm);

export default injectIntl(withRouter(ConnectedSearch));
