import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {SubmissionProduct, SubmissionProductVariant} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// material-ui
import Typography from '@material-ui/core/Typography';
// components
import Card from 'components/core/ui/mui/Card';
import CircularProgress from 'components/core/ui/mui/CircularProgress';
// vectors
import ClaimedOpenWave from 'components/core/vectors/waves/claimingStatus/Open';
import ClaimedInProgressWave from 'components/core/vectors/waves/claimingStatus/InProgress';
import ClaimedFinishedWave from 'components/core/vectors/waves/claimingStatus/Finished';
import ClaimedDeclinedWave from 'components/core/vectors/waves/claimingStatus/Declined';


const useStyles = makeStyles((theme) => ({
    card: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(28),
        color: theme.palette.snomGrey[800]
    },
    productImageContainer: {
        width: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textHolder: {
        width: '420px',
        textAlign: 'center',
        maxWidth: '100%',
        // above wave
        position: 'relative',
        zIndex: 20,
        marginTop: theme.spacing(4.75)
    },
    status: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        '&.open': {color: theme.palette.snomGrey[400]},
        '&.in_progress': {color: theme.palette.orange[400]},
        '&.finished': {color: theme.graphs[4]},
        '&.declined': {color: theme.palette.danger[400]}
    },
    waveContainer: {
        // bellow text
        position: 'absolute',
        zIndex: 10,
        // bottom
        left: 0,
        right: 0,
        bottom: 0
    }
}));

/**
 * Claimed phone status card
 */
export default function ClaimedProductStatusCard(props) {
    const {claimproduct} = props;
    const classes = useStyles();
    // redux
    const dispatch = useDispatch();
    const {submission_products_loaded, submission_products_items} = useSelector(state => ({
        submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
        submission_products_items: state.shared.getIn(['items', 'submission-products'])
    }));

    /**
     * During initialization fetch all Submission Products, so we can find chosen variant
     */
    useEffect(() => {
        if (submission_products_loaded === false) {
            dispatch(fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false}));
        }
    }, [submission_products_loaded]);

    // find SubmissionProductVariant
    const spvariant = useMemo(() => {
        return submission_products_items.flatMap(submission_product => submission_product.get('variants')).find(variant => variant.get(new SubmissionProductVariant().getUniqueIdentifier()) === claimproduct.get('product'));
    }, [submission_products_loaded, claimproduct.get('product')]);

    // get wave vector corresponding to claiming status
    const WaveComponent = useMemo(() => {
        switch (claimproduct.get('status')) {
            case 'open':
                return ClaimedOpenWave;
            case 'in_progress':
                return ClaimedInProgressWave;
            case 'finished':
                return ClaimedFinishedWave;
            case 'declined':
                return ClaimedDeclinedWave;
            default:
                return null;
        }
    }, [claimproduct.get('status')]);

    return <Card className={classes.card}>
        <div className={classes.productImageContainer}>
            {!submission_products_loaded || !spvariant ? <CircularProgress /> : <React.Fragment>
                <img src={spvariant?.get('image_url') || require('img/portal/product_preview.png')} alt={spvariant?.get('name')} />
                <Typography className='title' align='center'><strong>{spvariant?.get('name')}</strong></Typography>
            </React.Fragment>}
        </div>
        <div className={classes.textHolder}>
            <Typography><FormattedMessage id='claims.detail.status.requested_status' /></Typography>
            <Typography variant='h3' className={`${classes.status} ${claimproduct.get('status')}`}>
                <strong><FormattedMessage id={`claims.detail.status.${claimproduct.get('status')}`} /></strong>
            </Typography>
            <Typography>
                <FormattedMessage id={`claims.detail.status.${claimproduct.get('status')}.description`} />
            </Typography>
        </div>
        <div className={classes.waveContainer}><WaveComponent /></div>
    </Card>;
}
