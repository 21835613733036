import React, {useCallback, useMemo, useEffect, useState} from 'react';
import Moment from 'moment';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Page} from 'lib/models';
import {useHistory} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {marked, Renderer} from 'marked';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {Row, Col} from 'components/core/ui/Grid';
import NotFound from 'components/modules/NotFound';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import CustomerAssetManager from 'components/modules/assets/CustomerAssetManager';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    cardImage: {
        background: theme.palette.grey[300],
        '&, &:first-child, &:last-child': {
            padding: '0'
        },
        // img itself
        '& img': {
            display: 'block',
            // center align
            margin: '0 auto'
        }
    }
}));

// set-up markdown renderer to support internal links and open external links in new window
const mrenderer = new Renderer();
mrenderer.link = (href, title, text) => `<a href='${href}' target='_blank' rel='noreferrer' class='hover-border${href.startsWith('/') ? ' internal' : ''}'>${text}</a>`;

/**
 * Renders supported builder Component
 */
function Component(props) {
    // split some stuff from props
    const {
        idx, identifier, component_data = {},
        localState = {}, setLocalState = () => {}
    } = props;
    const history = useHistory();
    const classes = useStyles();
    // handle clicking link inside content
    const contentClickHandler = useCallback(event => {
        // get link
        const link = event.target.closest('a');
        // check if we are dealing with internal link
        if (link && link.classList.contains('internal')) {
            // prevent default and use react-router
            event.preventDefault();
            history.push(link.getAttribute('href'));
        }
    }, []);

    /**
     * Get Component to render and its props
     */
    const [RComponent, rcomponent_props] = useMemo(() => {
        switch (component_data.type) {
            case 'card':
                const {variant, ...card_data} = component_data;
                return [
                    variant === 'first' ? CardFirst : variant === 'last' ? CardLast : Card,
                    card_data
                ];
            case 'cardheader':
                const {children, icon, ...cardheader_data} = component_data;
                return [
                    CardHeader,
                    {
                        ...cardheader_data,
                        children: null, // prevent children from rendering
                        action: children
                            ? children.map((children_data, cidx) => <Component
                                {...props} key={`${idx}-${cidx}`} idx={`${idx}-${cidx}`} component_data={children_data}
                            />)
                            : icon === 'true' && <ActionButton iconButton disabled>
                            <ModelIcon model={identifier} />
                        </ActionButton>
                    }
                ];
            case 'cardcontent':
                const {content, ...cardcontent_data} = component_data;
                return [
                    CardContent,
                    {
                        ...cardcontent_data,
                        altChildren: <div
                            dangerouslySetInnerHTML={{__html: marked(content || '', {breaks: true, renderer: mrenderer})}}
                            onClick={contentClickHandler}
                        />
                    }
                ];
            case 'cardimage':
                const {image, ...cardimage_data} = component_data;
                return [
                    CardContent,
                    {
                        ...cardimage_data,
                        className: classes.cardImage,
                        altChildren: <img src={image} alt={identifier} />
                    }
                ];
            case 'cardactions':
                const {center, ...cardactions_data} = component_data;
                return [
                    CardActions,
                    {
                        ...cardactions_data,
                        center: center === 'true'
                    }
                ];
            case 'actionbutton':
            case 'button':
                const {link, text, ...button_data} = component_data;
                return [
                    Button,
                    {
                        ...button_data,
                        altChildren: text,
                        // check if internal link
                        ...(link.startsWith('/') ? {
                            onClick: () => history.push(link)
                        } : {
                            component: 'a',
                            target: '_blank',
                            rel: 'noreferrer',
                            href: link
                        })
                    }
                ];
            case 'spacedivider':
                const {size, ...spacedivider_data} = component_data;
                return [
                    SpaceDivider,
                    {
                        ...spacedivider_data,
                        ...(size === 'grid' ? {grid: true} : size === 'double' ?  {double: true} : {})
                    }
                ];
            case 'expansionpanel':
                const {content: exp_content, connect, ...expansionpanel_data} = component_data;
                return [
                    ExpansionPanel,
                    {
                        ...expansionpanel_data,
                        expanded: (localState.expansion || {})[connect] === idx,
                        onChange: () => setLocalState({
                            ...localState,
                            expansion: {
                                ...(localState.expansion || {}),
                                [connect]: (localState.expansion || {})[connect] !== idx ? idx : false
                            }
                        }),
                        preChildren: <div
                            dangerouslySetInnerHTML={{__html: marked(exp_content || '', {breaks: true, renderer: mrenderer})}}
                            onClick={contentClickHandler}
                        />
                    }
                ];
            case 'row':
                const {wrap, ...row_data} = component_data;
                return [
                    Row,
                    {
                        ...row_data,
                        ...(wrap === 'wrap' ? {wrap: true} : wrap === 'wrapSwitch' ?  {wrapSwitch: true} : wrap === 'nowrap' ?  {nowrap: true} : {})
                    }
                ];
            case 'col':
                return [
                    Col,
                    component_data
                ];
            case 'assets':
                return [
                    CustomerAssetManager,
                    component_data
                ];
            default:
                throw new Error(`Not supported dynamic component: ${component_data.type}!`);
        }
    }, [component_data.type, JSON.stringify(localState)]);

    // render component and its children
    const {preChildren, postChildren, children, altChildren, ...rest_of_rcomponentprops} = rcomponent_props;
    return <RComponent {...rest_of_rcomponentprops}>
        {preChildren && preChildren}
        {altChildren ? altChildren : children && children.map((children_data, cidx) => <Component
            {...props} key={`${idx}-${cidx}`} idx={`${idx}-${cidx}`} component_data={children_data}
        />)}
        {postChildren && postChildren}
    </RComponent>;
}

/**
 * Renders Dynamic Page from provided page data
 */
export function RenderDynamicPage(props) {
    const {data, identifier} = props;
    // local state
    const [localState, setLocalState] = useState({});

    return data.map((component_data, idx) => <Component
        key={idx} idx={idx} identifier={identifier}
        localState={localState} setLocalState={setLocalState}
        component_data={component_data}
    />);
}

/**
 * Fetches Page model with provided identifier and renders it
 */
export default function DynamicPage(passed_props) {
    const {identifier} = passed_props;
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Page().getPlacement();
    const props = useSelector(state => {
        let page = state.shared.getIn(['items', items_placement]).find(
            page => page.get(new Page().getUniqueIdentifier()) === `${identifier}-${Moment.locale()}`);
        // fallback to English when locale version not found
        if (!page && Moment.locale() !== 'en') {
            page = state.shared.getIn(['items', items_placement]).find(
                page => page.get(new Page().getUniqueIdentifier()) === `${identifier}-en`);
        }

        return {
            state: state.app.get('state'),
            loaded: state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]),
            item: page
        };
    }, shallowEqual);
    // convert data to plain JS
    const data = useMemo(() => props.item?.get('content').toJS() || [], [props.loaded, !!props.item]);

    /**
     * During initialization fetch ALL items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Page, items_placement, 'pages'));
        }
    }, [props.loaded]);

    return (props.loaded && !props.item) ? <NotFound /> : !props.loaded
        ? <Card>
            <CardContent>
                <SpaceDivider loading />
                <LinearProgress />
                <SpaceDivider loading />
            </CardContent>
        </Card>
        : <RenderDynamicPage data={data} identifier={identifier} />;
}
