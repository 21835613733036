import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITableCell from '@material-ui/core/TableCell';
// icons
import DragHandleIcon from '@material-ui/icons/DragHandleOutlined';


const useStyles = makeStyles(theme => ({
    // all cells
    cell: {
        // reduce font size
        fontSize: theme.typography.pxToRem(13),
        // remove top and bottom padding
        padding: `0 ${theme.spacing(2)}px`,
        // use height
        height: `${theme.spacing(6.5)}px`,
        // icons inside
        '& svg': {
            verticalAlign: 'middle'
        },
        // responsive
        [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.spacing(1)}px`
        }
    },
    // smaller cell for numbers, icon, checkboxes, etc.
    fixedCell: {
        // fixed width
        width: '150px !important',
        minWidth: '0 !important'
    },
    fixedCellMedium: {
        // fixed width
        width: '200px !important',
        minWidth: '0 !important'
    },
    // acts like link
    linkCell: {
        cursor: 'pointer' // correct cursor for clickable cell
    },
    // name and icon support
    nameCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .icon, & .text': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& .icon': {
            fontSize: theme.typography.pxToRem(24),
            marginRight: `${theme.spacing(1)}px`
        },
        '& .text': {
            width: `calc(100% - ${(theme.spacing(1)) + 24}px)`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    nameAvatar: {
        '& .avatar-holder, & .text': {
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& .avatar-holder': {
            marginRight: `${theme.spacing(1)}px`
        },
        '& .text': {
            width: `calc(100% - ${(theme.spacing(1)) + 42}px)`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    // cell with reduced opacity
    mutedCell: {
        opacity: theme.palette.action.disabledOpacity
    },
    // cell with long text like URL / Email
    urlViewCell: {
        whiteSpace: 'nowrap',
        overflow: 'auto',
        userSelect: 'all'
    },
    // cell with checkbox and nothing else
    checkboxCell: {
        width: '72px !important', // fixed width. We don't need extra space
        minWidth: '0 !important',
        textAlign: 'center'
    },
    // table cell with drag handle
    dragCell: {
        // basically same size, as checkbox
        width: '72px !important', // fixed width. We don't need extra space
        minWidth: '0 !important',
        textAlign: 'center',
        // icon itself
        '& svg': {
            cursor: 'row-resize' // correct mouse
        }
    }
}));

/**
 * Basically MUI TableRow with optional styles
 *  'link'      - correct cursor for clickable row
 *  'name'      - ellipsis and icon support
 *  'checkbox'  - fixed checkbox size without extra space
 *  'drag'      - drag handle
 */
export default function TableCell(props) {
    // Split some stuff from props
    const {children, padding, _classes, className,
        numeric, reduced, link, name, nameAvatar, muted, urlView, checkbox,
        drag, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        root, className,
        classes.cell,
        numeric ? `${classes.fixedCell} text-right` : '',
        reduced ? reduced === 'm' ? classes.fixedCellMedium : classes.fixedCell : '',
        link ? classes.linkCell : '',
        name ? classes.nameCell : '',
        nameAvatar ? classes.nameAvatar : '',
        muted ? classes.mutedCell : '',
        urlView ? classes.urlViewCell : '',
        checkbox ? classes.checkboxCell : '',
        drag ? classes.dragCell : ''
    ].filter(Boolean).join(' ');

    return <MUITableCell
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        padding={drag || checkbox ? 'checkbox' : padding}
        {...rest_of_props}>
        {drag && !children ? <DragHandleIcon /> : children}
    </MUITableCell>;
}
