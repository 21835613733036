'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
// Components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
// material-ui
import Button from 'components/core/ui/mui/Button';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import ChipDeleteIcon from '@material-ui/icons/Cancel';


const styles = theme => ({
    // select dialog menu
    selectMenu: {
        // we use modal, so just hide it
        display: 'none'
    },
    // selected chips in select
    chipsHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        // to sides
        margin: `-${theme.spacing(0.75)}px -${theme.spacing(0.25)}px`
    },
    chip: {
        margin: `${theme.spacing(0.25)}px`,
        // limit size
        maxWidth: '150px',
        height: '27px',
        // text overflow
        '& span': {
            display: 'block',
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
});

/**
 * Add/Edit Form for Dialog manage Handsets
 */
class HandsetForm extends React.Component {
    render() {
        return <React.Fragment>
            <DialogTitle>
                <FormattedMessage id={this.props.editHanddset !== null
                    ? 'productdefaults.detail.firmware.form.fields.handsets.dialog.title.edit'
                    : 'productdefaults.detail.firmware.form.fields.handsets.dialog.title.add'} />
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={this.props.handleSubmit}>
                    <Field name='handset' fieldType='Select' label={`${this.props.intl.formatMessage({id: 'productdefaults.detail.firmware.form.fields.handsets.dialog.form.fields.handset'})}*`}>
                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                        {this.props.product.get('handsets').map((handset, idx) => <MenuItem key={idx} value={handset}>{handset}</MenuItem>)}
                    </Field>
                    <Field name='value' fieldType={this.props.product.getIn(['firmware_strategy', 'handsets_field_type']) === 'url' ? 'URLField' : 'TextField'}
                           label={`${this.props.intl.formatMessage({id: `productdefaults.detail.firmware.form.fields.handsets.dialog.form.fields.${
                               this.props.product.getIn(['firmware_strategy', 'handsets_field_type']) === 'url' ? 'url' : 'version'}`})}*`}
                           helperText={<FormattedMessage id={`productdefaults.detail.firmware.form.fields.handsets.dialog.form.fields.${
                               this.props.product.getIn(['firmware_strategy', 'handsets_field_type']) === 'url' ? 'url' : 'version'}.help`} />}
                           change={this.props.change} selectedValue={this.props.formValues ? this.props.formValues.value : ''}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handleClose}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                {this.props.editHandset !== null
                    ? <Button variant='contained' color='primary'
                              onClick={this.props.handleSubmit}>
                    <EditIcon />
                    <FormattedMessage id='actions.edit' />
                </Button>
                    : <Button variant='contained' color='primary'
                              onClick={this.props.handleSubmit}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </Button>}
            </DialogActions>
        </React.Fragment>;
    }
}

let validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'handset', data.handset);
    validator.isUnique(
        props.intl.formatMessage({id: 'productdefaults.detail.firmware.form.fields.handsets.dialog.error.handset'}),
        errors, 'handset', data.handset,
        Object.keys(props.selectedValue || {}).filter(el => el !== props.editHandset), null);
    validator.isNotNull(null, errors, 'value', data.value);

    return errors;
};

HandsetForm = reduxForm({
    form: 'handsetsFieldForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // get Select values from props, copy object to not immediately modify it's reference
        let selectValues = props.selectedValue ? JSON.parse(JSON.stringify(props.selectedValue)) : {};
        // ensure to remove previous values in case we are editing
        if (props.editHandset !== null) {
            delete selectValues[props.editHandset];
        }
        // add new/updated record
        selectValues[props.formValues.handset] = props.formValues.value;
        // change value in select
        props.changeHandsets(selectValues);
        // close dialog
        props.handleClose();
    }
})(HandsetForm);

HandsetForm = connect((state, props) => ({
    formValues: getFormValues('handsetsFieldForm')(state),
    initialValues: props.editHandset ? {handset: props.editHandset, value: props.selectedValue[props.editHandset]} : {}
}))(HandsetForm);

HandsetForm = injectIntl(HandsetForm);

/**
 * Handsets field, multiple object field
 */
class HandsetsField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false, // open/close status of add Choice dialog
            editHandset: null // editing handset
        };
    }

    render() {
        let {name, label, classes, helperText, selectedValue, change, product, ...rest_of_props} = this.props;

        return <React.Fragment>
            <Field fieldType='Select'
                   name={name}
                   label={label}
                   helperText={helperText}
                   IconComponent={AddIcon}
                   open={this.state.dialogOpen}
                   onOpen={() => this.setState({dialogOpen: true})}
                   inputProps={{value: selectedValue && Object.keys(selectedValue).length ? 'filled' : ''}}
                   value={selectedValue}
                   MenuProps={{className: classes.selectMenu}}
                   renderValue={selected => <div className={classes.chipsHolder}>
                       {Object.keys(selectedValue || {}).sort().map((handset) =>
                           <Chip key={handset}
                                 className={classes.chip}
                                 label={handset}
                                 onClick={() => {}} onDelete={() => {}}
                                 onMouseDown={(e) => {
                                     e.stopPropagation();
                                     this.setState({dialogOpen: true, editHandset: handset});
                                 }}
                                 deleteIcon={<ChipDeleteIcon onMouseDown={(e) => {
                                     e.stopPropagation();
                                     let selectValues = JSON.parse(JSON.stringify(selectedValue));
                                     delete selectValues[handset];
                                     change(name, Object.keys(selectValues).length ? selectValues : '');
                                 }} />}
                           />
                       )}
                   </div>}
                   {...rest_of_props} />
            <Dialog open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen: false, editHandset: null})}
                    maxWidth='md'>
                {this.state.dialogOpen && <HandsetForm
                    handleClose={() => this.setState({dialogOpen: false, editHandset: null})}
                    editHandset={this.state.editHandset}
                    selectedValue={selectedValue}
                    changeHandsets={(value) => change(name, value)}
                    product={product}
                />}
            </Dialog>
        </React.Fragment>;
    }
}

HandsetsField = withStyles(styles)(HandsetsField);
export default HandsetsField;
