import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {Role} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import Field, {SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import MenuItem from 'components/core/ui/mui/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';
import NoIcon from '@material-ui/icons/CloseOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


/**
 * Renders Permission Roles from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Role().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'for_supergroup', label: <FormattedMessage id='permissions.list.table.for_supergroup' />, numeric: true},
        {sortName: 'name', label: <FormattedMessage id='permissions.list.table.name' />},
        {sortName: 'description', label: <FormattedMessage id='permissions.list.table.description' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new Role().getPlacement(), tableHead.map(item => item.sortName).filter(sortName => sortName !== 'for_supergroup'));
    const [sortedRolesByAdmin,,,, sortByAdminInitializing] = useLocalSort(
        sortedItems, sortInitializing, null, ['for_supergroup'], 'for_supergroup', 'asc');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedRolesByAdmin, sortByAdminInitializing, new Role().getPlacement(),
        ['search', 'for_supergroup']);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Role, items_placement, 'roles'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='permissions.list.title' />}
            subheader={<FormattedMessage id='permissions.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/permissions/add')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='roles' />
                </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='permissions.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='permissions.list.filter.for_supergroup' />}
                   fieldType='NoReduxSelect' value={filters.for_supergroup || ''}
                   onChange={(event) => filterItems('for_supergroup', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'>
                    <ListItemIcon className='icon'><AdminIcon color='primary' /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='permissions.list.filter.for_supergroup.choices.yes' /></ListItemText>
                </MenuItem>
                <MenuItem value='false'>
                    <ListItemIcon className='icon'><NoIcon /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='permissions.list.filter.for_supergroup.choices.no' /></ListItemText>
                </MenuItem>
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                {item.sortName === 'for_supergroup'
                                    ? <TableSortLabel active={true} direction='asc' disabled>
                                        {item.label}
                                    </TableSortLabel>
                                    : <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                        </TableRow>
                    </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'permissions.list.table.empty'}
                                                      values={{link: passed_props.permission === 'RW'
                                                              ? <Link to='/permissions/add' className='hover-border'>
                                                                  <FormattedMessage id='permissions.list.table.empty.link' />
                                                              </Link>
                                                              : <FormattedMessage id='permissions.list.table.empty.link' />}} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && filteredItems.map((role, idx) =>
                                <TableRow key={idx} link hover
                                          onClick={() => props.state === null ? history.push(`/permissions/${role.get(new Role().getUniqueIdentifier())}`) : {}}>
                                    <TableCell numeric>
                                        {role.get('for_supergroup') && <AdminIcon color='primary' />}
                                    </TableCell>
                                    <TableCell>{role.get('name')}</TableCell>
                                    <TableCell>{role.get('description')}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>}
            </Table>} />
    </Card>;
}
