import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment/moment';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {Invoice} from 'lib/models';
import {makeStyles} from '@material-ui/core/styles';
import {getCurrencySymbol} from 'lib/util';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {SearchField} from 'components/core/ui/Field';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import StatusDraftIcon from '@material-ui/icons/FindInPageOutlined';
import StatusOpenIcon from '@material-ui/icons/NewReleasesOutlined';
import StatusPaidIcon from '@material-ui/icons/CheckOutlined';
import StatusVoidIcon from '@material-ui/icons/CloseOutlined';



const useStyles = makeStyles(theme => ({
    dueDate: {
        color: theme.palette.danger[500]
    },
    statusIcon: {
        // variants
        '&.open': {
            color: theme.palette.primary[500]
        },
        '&.paid': {
            color: theme.palette.success[500]
        },
        '&.uncollectible, &.void': {
            color: theme.palette.danger[500]
        }
    }
}));

/**
 * Invoice status icon
 */
export function StatusIcon(props) {
    // Split some stuff from props
    let {_classes, className, identifier, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let {root} = _classes || {};
    let rootClasses = [
        classes.statusIcon, root, className,
        identifier
    ].filter(Boolean).join(' ');

    const IconElement = useMemo(() => {
        switch (identifier) {
            case 'draft':
                return StatusDraftIcon;
            case 'open':
                return StatusOpenIcon;
            case 'paid':
                return StatusPaidIcon;
            case 'uncollectible':
            case 'void':
                return StatusVoidIcon;
            default:
                return StatusDraftIcon;
        }
    }, [identifier]);

    return <IconElement className={rootClasses} {...rest_of_props} />;
}

/**
 * Renders Stripe invoices from backend - to view and download them
 */
export default function List() {
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Invoice().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'created', label: <FormattedMessage id='submissions.list.table.created_at' />},
        {sortName: 'id', label: <FormattedMessage id='invoices.list.table.id' />},
        {sortName: 'customer_email', label: <FormattedMessage id='invoices.list.table.customer_email' />},
        {sortName: 'status', label: <FormattedMessage id='invoices.list.table.status' />},
        {sortName: 'amount', label: <FormattedMessage id='invoices.list.table.amount' />, numeric: true}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName), 'created', 'asc');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['search'], ['id', 'email', 'amount']);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Invoice, items_placement, props.company.getIn(['links', 'invoices'])));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='invoices.list.title' />}
            subheader={<FormattedMessage id='invoices.list.subheader' />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='invoices' />
            </ActionButton>}
        />
        <Filters>
                <SearchField label={<FormattedMessage id='invoices.list.search' />}
                             value={filters.search || ''}
                             search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'invoices.list.table.empty'} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((invoice, idx) =>
                        <TableRow
                            key={idx} link={!!invoice.get('pdf_link')} hover={!!invoice.get('pdf_link')}
                            onClick={invoice.get('pdf_link') ? () => {
                                // simulate link download to prevent pop-up block with window.open
                                const link = document.createElement('a');
                                link.href = invoice.get('pdf_link');
                                link.target = '_blank';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            } : undefined}>
                            <TableCell>{Moment(invoice.get('created')).format('l, LT')}</TableCell>
                            <TableCell>{invoice.get('id')}</TableCell>
                            <TableCell urlView>{invoice.get('customer_email')}</TableCell>
                            <TableCell name>
                                <StatusIcon identifier={invoice.get('status')} className='icon' />
                                <span className='text'>
                                    <FormattedMessage id={`invoices.list.table.status.${invoice.get('status')}`} />
                                    {invoice.get('due_date') && <strong className={classes.dueDate}>
                                        {' ('}<FormattedMessage id='invoices.list.table.status.open.due' values={{time: Moment(invoice.get('due_date')).fromNow()}} />{')'}
                                    </strong>}
                                </span>
                            </TableCell>
                            <TableCell numeric>{`${`${invoice.get('amount') || ''}`.slice(0, -2) || '0'}.${`${invoice.get('amount') || ''}`.slice(-2) || '00'} ${getCurrencySymbol(invoice.get('currency'))}`}</TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>} />
    </Card>;
}
