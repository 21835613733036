'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
// Components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import ChipDeleteIcon from '@material-ui/icons/Cancel';


const styles = theme => ({
    // select dialog menu
    selectMenu: {
        // we use modal, so just hide it
        display: 'none'
    },
    // selected chips in select
    chipsHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        // to sides
        margin: `-${theme.spacing(0.75)}px -${theme.spacing(0.25)}px`
    },
    chip: {
        margin: `${theme.spacing(0.25)}px`,
        // limit size
        maxWidth: '150px',
        height: '27px',
        // text overflow
        '& span': {
            display: 'block',
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    }
});

/**
 * Add/Edit Form for Dialog manage Choices
 */
class ChoiceForm extends React.Component {
    render() {
        return <React.Fragment>
            <DialogTitle>
                <FormattedMessage id={this.props.edit !== null
                    ? 'settings.detail.form.fields.choices.dialog.title.edit'
                    : 'settings.detail.form.fields.choices.dialog.title.add'} />
            </DialogTitle>
            <DialogContent>
                <Form onSubmit={this.props.handleSubmit}>
                    <Field name='value' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'settings.detail.form.fields.choices.dialog.form.fields.value'})}*`}
                           helperText={<FormattedMessage id='settings.detail.form.fields.choices.dialog.form.fields.value.help' />} />
                    <Field name='label' fieldType='TextField' label={`${this.props.intl.formatMessage({id: 'settings.detail.form.fields.choices.dialog.form.fields.label'})}*`}
                           helperText={<FormattedMessage id='settings.detail.form.fields.choices.dialog.form.fields.label.help' />} />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handleClose}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                {this.props.edit !== null
                    ? <Button variant='contained' color='primary'
                              onClick={this.props.handleSubmit}>
                    <EditIcon />
                    <FormattedMessage id='actions.edit' />
                </Button>
                    : <Button variant='contained' color='primary'
                              onClick={this.props.handleSubmit}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </Button>}
            </DialogActions>
        </React.Fragment>;
    }
}

let validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'value', data.value);
    validator.isUnique(
        props.intl.formatMessage({id: 'settings.detail.form.fields.choices.dialog.error.value'}),
        errors, 'value', data.value,
        (props.selectedValue || []).filter((el, idx) => idx !== props.edit), 'value');
    validator.isNotNull(null, errors, 'label', data.label);

    return errors;
};

ChoiceForm = reduxForm({
    form: 'choiceFieldForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // get array of values without our new one
        let selectValues = [...(props.selectedValue || [])].filter((choice, idx) => props.edit === null || idx !== props.edit);
        // add new/updated choice
        selectValues.push(props.formValues);
        // sort by name
        selectValues.sort((a, b) => a.label.localeCompare(b.label, undefined, {numeric: true, sensitivity: 'base'}));
        // change value in select
        props.changeChoices(selectValues);
        // close dialog
        props.handleClose();
    }
})(ChoiceForm);

ChoiceForm = connect((state, props) => ({
    initialValues: props.edit !== null ? props.selectedValue[props.edit] : {},
    formValues: getFormValues('choiceFieldForm')(state)
}))(ChoiceForm);

ChoiceForm = injectIntl(ChoiceForm);

/**
 * Choices field, multiple object field
 */
class ChoicesField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false, // open/close status of add Choice dialog
            edit: null // editing choice
        };
    }

    render() {
        let {name, label, classes, intl, helperText, selectedValue, change, ...rest_of_props} = this.props;

        return <React.Fragment>
            <Field fieldType='Select'
                   name={name}
                   label={label}
                   helperText={helperText}
                   IconComponent={AddIcon}
                   open={this.state.dialogOpen}
                   onOpen={() => this.setState({dialogOpen: true})}
                   inputProps={{value: selectedValue && selectedValue.length ? 'filled' : ''}}
                   value={selectedValue}
                   MenuProps={{className: classes.selectMenu}}
                   multiple
                   renderValue={selected => <div className={classes.chipsHolder}>
                       {selectedValue.map((choice, idx) =>
                           <Chip key={idx}
                                 className={classes.chip}
                                 label={choice.label}
                                 onClick={() => {}} onDelete={() => {}}
                                 onMouseDown={(e) => {
                                     e.stopPropagation();
                                     this.setState({dialogOpen: true, edit: idx});
                                 }}
                                 deleteIcon={<ChipDeleteIcon onMouseDown={(e) => {
                                     e.stopPropagation();
                                     change(name, selectedValue.filter(el => el.value !== choice.value));
                                 }} />}
                           />
                       )}
                   </div>}
                   {...rest_of_props} />
            <Dialog open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen: false, edit: null})}
                    maxWidth='md'>
                {this.state.dialogOpen && <ChoiceForm
                    handleClose={() => this.setState({dialogOpen: false, edit: null})}
                    edit={this.state.edit}
                    selectedValue={selectedValue}
                    changeChoices={(value) => change(name, value)}
                />}
            </Dialog>
        </React.Fragment>;
    }
}

ChoicesField = withStyles(styles)(ChoicesField);
export default ChoicesField;
