import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Ticket} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import RebootIcon from '@material-ui/icons/ReplayOutlined';
import FactoryResetIcon from '@material-ui/icons/UpdateOutlined';
import ResyncIcon from '@material-ui/icons/SyncOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import GuiStateIcon from '@material-ui/icons/AssessmentOutlined';
import SysLogIcon from '@material-ui/icons/TrackChangesOutlined';
import SipLogIcon from '@material-ui/icons/SettingsEthernetOutlined';
import XMLMiniBrowserIcon from '@material-ui/icons/PersonalVideoOutlined';
import SettingsIcon from '@material-ui/icons/DescriptionOutlined';
import UpgradeIcon from '@material-ui/icons/Publish';


/**
 * Render Ticket Type Icon
 *
 * Props:
 *  identifier - Type, e.g. 'get_common'
 */
export function TypeIcon(props) {
    // Split some stuff from props
    const {_classes, className, identifier, ...rest_of_props} = props;
    // Merge classes from props and our custom
    const {root} = _classes || {};
    const rootClasses = [
        root, className,
        identifier
    ].filter(Boolean).join(' ');

    let IconElement;
    switch (identifier) {
        case 'operate_reboot':
            IconElement = RebootIcon;
            break;
        case 'operate_factory_reset':
            IconElement = FactoryResetIcon;
            break;
        case 'resync':
            IconElement = ResyncIcon;
            break;
        case 'get_common':
            IconElement = InfoIcon;
            break;
        case 'get_gui_state':
            IconElement = GuiStateIcon;
            break;
        case 'get_syslog':
            IconElement = SysLogIcon;
            break;
        case 'get_sip_trace':
            IconElement = SipLogIcon;
            break;
        case 'get_xml_minibrowser':
            IconElement = XMLMiniBrowserIcon;
            break;
        case 'get_settings':
        case 'get_settings_modified':
                IconElement = SettingsIcon;
            break;
        case 'upgrade':
            IconElement = UpgradeIcon;
            break;
        default:
            return <span />;
    }

    return <IconElement className={rootClasses} {...rest_of_props} />;
}

/**
 * Select field for Ticket type with Icon and Text
 */
export default function TicketTypeField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'tickettypefield.label'})}${required ? '*' : ''}`}
                {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Ticket().getTypes().map((action, idx) =>
            <MenuItem value={action} key={idx}>
                <ListItemIcon className='icon'><TypeIcon identifier={action} /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id={`tickettypefield.choices.${action}`} /></ListItemText>
            </MenuItem>
        )}
    </Field>;
}
