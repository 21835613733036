import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Simple select field for Salutation
 */
export default function SalutationField(props) {
    // Split some stuff from props
    const {name, label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' name={name || 'salutation'}
                  label={label || `${intl.formatMessage({id: 'salutationfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        <MenuItem value='Mr.'><FormattedMessage id='salutationfield.choice.male' /></MenuItem>
        <MenuItem value='Mrs.'><FormattedMessage id='salutationfield.choice.female' /></MenuItem>
    </Field>;
}
