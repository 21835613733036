import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {useFilter, useLocalSort, usePaginatedItems} from 'lib/filters';
import Moment from 'moment';
import {Endpoint, Product, ProductGroup, Company, ProvisioningProfile, Paginator} from 'lib/models';
import {List as ImmutableList} from 'immutable';
import useFeatureFlag from 'lib/featureFlag';
// Actions
import {fetchItem, fetchItems, updatePaginator} from 'actions/shared';
// Components
import EndpointSearch from 'components/modules/endpoints/Search';
import RequestCSV from 'components/core/ui/RequestCSV';
import Filters from 'components/core/ui/Filters';
import Field, {SearchField, CompanyField} from 'components/core/ui/Field';
import {StatusIcon} from 'components/core/ui/fields/EndpointStatusField';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Tooltip from 'components/core/ui/mui/Tooltip';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DMActiveIcon from '@material-ui/icons/CheckOutlined';
import DMInActiveIcon from '@material-ui/icons/HighlightOffOutlined';
import SpaceDivider from 'components/core/ui/SpaceDivider';


/**
 * Renders Endpoints for specific Company of User from backend - to view and manage them
 */
export default function List(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = searchParams.get('company')
            // ensure we don't create duplicate 'my' collection with UUID
            ? !pathname.startsWith('/all-') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                ? 'my'
                : searchParams.get('company')
            : pathname.startsWith('/all-') ? 'all' : 'my';

        const items_placement = `${new Endpoint().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;
        const provprofile_placement = `${new ProvisioningProfile().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            products_loaded: state.shared.getIn(['loaded', 'products']),
            products_items: state.shared.getIn(['items', 'products']),
            product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
            product_groups_items: state.shared.getIn(['items', 'product-groups']),
            provisioning_profiles_loaded: !!state.shared.getIn(['loaded', provprofile_placement]),
            provisioning_profiles_items: state.shared.getIn(['items', provprofile_placement]) || ImmutableList(),
            provisioning_profiles_placement: provprofile_placement,
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = useMemo(() => [
        {sortName: ['name', 'mac'], label: <FormattedMessage id='endpoints.list.table.name_and_mac' />},
        {sortName: 'code', label: <FormattedMessage id='endpoints.list.table.code' />},
        ...(company_identifier !== 'all'
            ? [{sortName: 'provisioning_profile', label: <FormattedMessage id='endpoints.list.table.provprofile' />}]
            : [{sortName: 'company', label: <FormattedMessage id='endpoints.list.global.table.company' />}]),
        {sortName: 'created_at', label: <FormattedMessage id='endpoints.list.table.created_at' />},
        ...(company_identifier !== 'all' ? [{sortName: 'setting_server', label: <FormattedMessage id='endpoints.list.table.setting_server' />}] : [])
    ], [company_identifier === 'all']);
    // check if any registered device is dm compatible
    const featureFlag_customerEndpointsSearch = useFeatureFlag('customer_endpoints_search', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});
    const dm_compatible = useMemo(() => {
        return featureFlag_deviceManager && (pathname.startsWith('/all-') || props.items.some(endpoint => {
            const product = props.products_loaded && props.products_items.find(el => el.getIn(['links', 'self']) === endpoint.getIn(['links', 'product']));
            return !!endpoint.get('status') || (product && product.get('device_manager_compatible'));
        }));
    }, [JSON.stringify(props.items), props.products_loaded, props.company_identifier, featureFlag_deviceManager, pathname.startsWith('/all-')]);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new Endpoint().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = [false, false];
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, `${new Endpoint().getPlacement()}${pathname.startsWith('/all-') ? '-global' : ''}`, ['company', 'search', 'product_group', 'dm_enabled', 'include_customer_endpoints'], props.state !== null);
    // related sorting
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length) && (company_identifier === 'all' || props.selectedCompany)) {
            dispatch(fetchItems(Endpoint, items_placement, company_identifier === 'all' ? 'endpoints' : props.selectedCompany.getIn(['links', 'endpoints']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), props.selectedCompany]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.provisioning_profiles_loaded === false && company_identifier !== 'all' && props.selectedCompany) {
            dispatch(fetchItems(ProvisioningProfile, props.provisioning_profiles_placement, props.selectedCompany.getIn(['links', 'provisioning-profiles']), null, null, {affect_state: false}));
        }
    }, [props.selectedCompany, props.provisioning_profiles_loaded]);
    useEffect(() => {
        if (props.products_loaded === false) {
            dispatch(fetchItems(Product, 'products', 'products', null, null, {affect_state: false}));
        }
    }, [props.products_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            dispatch(fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'endpoints.list.global.title'
                : 'endpoints.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'endpoints.list.global.subheader'
                : 'endpoints.list.subheader'} />}
            action={<React.Fragment>
                <RequestCSV placement={items_placement} url={company_identifier !== 'all'
                    ? props.selectedCompany && props.selectedCompany.getIn(['links', 'endpoints-export'])
                    : 'endpoints-export'} />
                {passed_props.permission === 'RW' && <ActionButton
                    variant='contained' color='secondary' disabled={props.state !== null}
                    onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}phones/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`)}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>}
            </React.Fragment>}
        />
        <Filters disabled={props.state !== null}>
            {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
                all={pathname.startsWith('/all-')} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            <SearchField label={<FormattedMessage id='endpoints.list.search' />}
                         value={postponedFilters.search || filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='endpoints.list.filter.product_group' />}
                   fieldType='NoReduxSelect' value={postponedFilters.product_group || filters.product_group || ''}
                   onChange={event => filterItems('product_group', event.target.value)}
                   loading={!props.product_groups_loaded}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {sortedProductGroups.map((product_group, idx) =>
                    <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                )}
            </Field>
            {featureFlag_deviceManager && <React.Fragment>
                <SpaceDivider none />
                <Field
                    label={<FormattedMessage id='endpoints.list.filter.dm_enabled' />}
                    fieldType='NoReduxSelect' value={filters.dm_enabled || ''}
                    onChange={event => filterItems('dm_enabled', event.target.value)}>
                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                    <MenuItem value='true'>
                        <ListItemIcon className='icon'><DMActiveIcon color='primary' /></ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id='endpoints.list.filter.dm_enabled.enabled' /></ListItemText>
                    </MenuItem>
                    <MenuItem value='false'>
                        <ListItemIcon className='icon'><DMInActiveIcon /></ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id='endpoints.list.filter.dm_enabled.disabled' /></ListItemText>
                    </MenuItem>
                </Field>
            </React.Fragment>}
            <EndpointSearch selectedCompany={props.selectedCompany} />
            { featureFlag_customerEndpointsSearch && !pathname.startsWith('/all-') && <React.Fragment>
                <Field
                    label={<FormattedMessage id='endpoints.list.filter.include_customer_endpoints' />}
                    fieldType='NoReduxSelect' value={filters.include_customer_endpoints || ''}
                    onChange={event => filterItems('include_customer_endpoints', event.target.value)}>
                    <MenuItem value='true'>
                        <ListItemIcon className='icon'><DMActiveIcon color='primary' /></ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id='endpoints.list.filter.include_customer_endpoints.yes' /></ListItemText>
                    </MenuItem>
                    <MenuItem value='false'>
                        <ListItemIcon className='icon'><DMInActiveIcon /></ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id='endpoints.list.filter.include_customer_endpoints.no' /></ListItemText>
                    </MenuItem>
                </Field>
            </React.Fragment>
            }
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx}>
                                {sortName === item.sortName
                                    ? <TableSortLabel active direction={sortDirection}
                                                      disabled={true}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`
                ].includes(props.state) || filtersInitializing ? <PaginationLoading
                    paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                    tableHead={tableHead} paginator={props.paginator}
                />
                    : !paginatedItems.size
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'endpoints.list.table.empty'}
                                              values={{link: passed_props.permission === 'RW'
                                                ? <Link className='hover-border' to={`/${pathname.startsWith('/all-') ? 'all-' : ''}phones/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`}>
                                                  <FormattedMessage id='endpoints.list.table.empty.link' />
                                              </Link>
                                                : <FormattedMessage id='endpoints.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((endpoint, idx) => {
                        const link = pathname.startsWith('/all-')
                            ? `/all-phones/${endpoint.get(new Endpoint().getUniqueIdentifier())}${endpoint.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${endpoint.get('company')}` : ''}`
                            : `/phones/${endpoint.get(new Endpoint().getUniqueIdentifier())}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`;
                        const onClick = () => props.state === null ? history.push(link) : {};

                        // related
                        const product = props.products_loaded && props.products_items.find(el => el.getIn(['links', 'self']) === endpoint.getIn(['links', 'product']));
                        const provisioning_profile = props.provisioning_profiles_loaded && endpoint.get('provisioning_profile') && props.provisioning_profiles_items.find(el => el.get(new ProvisioningProfile().getUniqueIdentifier()) === endpoint.get('provisioning_profile'));
                        const dm_status = endpoint.get('status')?.toLowerCase() || (product && !product.get('device_manager_compatible') ? 'unsupported' : null);

                        return <TableRow key={idx} hover>
                            <TableCell name={dm_compatible} link onClick={onClick}>
                                {dm_compatible && <Tooltip title={<div className='text-center'>
                                    <FormattedMessage id='endpointstatusfield.label.about' /><br />
                                    <FormattedMessage id={`endpointstatusfield.choice.${dm_status}`} />
                                </div>}>
                                    <StatusIcon identifier={dm_status} className='icon' />
                                </Tooltip>}
                                <span className='text'>
                                    {endpoint.get('name') && <React.Fragment>
                                        <strong>{endpoint.get('name')}</strong><br />
                                    </React.Fragment>}
                                    <span>{endpoint.get('mac')}</span>
                                </span>
                            </TableCell>
                            <TableCell link={!props.user.isAdmin()} onClick={props.user.isAdmin() ? undefined : () => onClick()}>
                                {!props.products_loaded
                                    ? <CircularProgress size={36} />
                                    : product
                                        ? props.user.isAdmin()
                                            ? <Link className='hover-border' to={`/products/${product.get(new Product().getUniqueIdentifier())}`}>
                                                {product.get('name')}
                                            </Link>
                                            : product.get('name')
                                        : ''}
                            </TableCell>
                            {company_identifier === 'all'
                                ? <TableCell>
                                <Link className='hover-border' to={`/companies/${endpoint.get('company')}`}>
                                    {endpoint.get('company_name')}
                                </Link>
                            </TableCell>
                                : <TableCell link={!endpoint.get('provisioning_profile')} onClick={!endpoint.get('provisioning_profile') ? onClick : null}>
                                {!props.provisioning_profiles_loaded
                                    ? <CircularProgress size={36} />
                                    : endpoint.get('provisioning_profile')
                                    ? <Link className='hover-border' to={`/provisioning-profiles/${endpoint.get('provisioning_profile')}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`}>
                                    {provisioning_profile ? provisioning_profile.get('name') : ''}
                                </Link>
                                    : <FormattedMessage id='filters.none' />}
                            </TableCell>}
                            <TableCell link onClick={onClick}>
                                {Moment(endpoint.get('created_at')).format('l, LT')}
                            </TableCell>
                            {company_identifier !== 'all' && <TableCell
                                urlView={endpoint.get('setting_server')}
                                link={!endpoint.get('setting_server')}
                                onClick={!endpoint.get('setting_server') ? onClick : null}>
                                {endpoint.get('setting_server') || <FormattedMessage id='filters.none' />}
                            </TableCell>}
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
        footer={<Table size={1}>
            <Pagination model={Endpoint} placement={items_placement} filters={filters}
                        paginationLoading={true} />
        </Table>} />
    </Card>;
}
