import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import Card from 'components/core/ui/mui/Card';


const useStyles = makeStyles(theme => ({
    firstCard: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        // ensure that Card connects nicely with ExpansionPanel
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        // <FirstCard /> and <LastCard /> are next to each other
        '& + $lastCard': {
            // connect them so they seems to be regular <Card />
            marginTop: '-1px'
        }
    },
    lastCard: {
        paddingTop: `${theme.spacing(2)}px`, // increase space so it looks more like CardHeader
        // ensure that expansionPanel fit nicely with Card
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0'
    }
}));

/**
 * First Card before ExpansionPanel
 */
function CardFirst(props) {
    // Split some stuff from props
    const {className, _classes, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.firstCard, root, className
    ].filter(Boolean).join(' ');

    return <Card
        _classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}

/**
 * Last Card after ExpansionPanel
 */
function CardLast(props) {
    // Split some stuff from props
    const {className, _classes, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [classes.lastCard, root, className].filter(Boolean).join(' ');

    return <Card
        _classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}

export {CardFirst, CardLast};
