import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, FieldArray} from 'redux-form';
import validator from 'lib/valitator';
import {SubmissionProduct, SubmissionProductVariant, Product, SalesCluster} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {setState} from 'actions/app';
import {fetchItems, saveItem, deleteItem, uploadFiles} from 'actions/shared';
// Components
import ErrorMessage from 'components/core/ui/ErrorMessage';
import ThemeProvider from 'components/ThemeProvider';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {DropFile, FieldIcon, FieldWithIconHolder, SalesClustersField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import EuroIcon from '@material-ui/icons/EuroSymbolOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';
import MenuItem from 'components/core/ui/mui/MenuItem';


const useStyles = makeStyles(theme => ({
    // holder for each variant with rebates
    variantHolder: {
        position: 'relative',
        width: '100%',
        // space for 'X' button
        paddingLeft: `${48 + theme.spacing(2)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0'
        }
    },
    // 'X' IconButton
    variantRemoveButton: {
        position: 'absolute',
        top: '0',
        left: '0',
        // responsive
        [theme.breakpoints.down('xs')]: {
            position: 'static'
        }
    },
    // holder of rebate fields
    rebatesHolder: {
        // next to image
        display: 'inline-flex',
        flexWrap: 'wrap'
    },
    // € icon in the field
    rebateIcon: {
        // reduce size
        padding: '0',
        marginLeft: `-${theme.spacing(1) + 19}px`,
        // icon itself
        '& svg': {
            fontSize: theme.typography.pxToRem(19) // lower size
        }
    },
    // 'nordics' / 'uk' / ... title for rebates
    rebatesTitle: {
        flexBasis: '100%', // force wrap
        '& > div': {
            display: 'inline-block',
            // proportion
            padding: `0 ${theme.spacing(1)}px`,
            // style
            ...theme.typography.button,
            color: theme.palette.primary[500],
            border: `1px solid ${theme.palette.primary[500]}`,
            textTransform: 'none'
        }
    },
    // holder of 'add' rebates field with button
    addRebatesForm: {
        marginLeft: 'auto'
    }
}));

/**
 * Renders N*Fields for Submissions Products variants
 */
function RenderVariantsFieldArray(props) {
    // style
    const classes = useStyles();
    // component state
    const [variantID, setVariantID] = useState(0);
    const disabled = props.permission === 'R';
    // redux store
    const sales_clusters = useSelector(state => state.shared.getIn(['items', new SalesCluster().getPlacement()]));

    return <React.Fragment>
        {props.fields.map((field, idx) => <div
            className={classes.variantHolder}
            key={(props.formValues.variants && props.initialValues.variants[idx]) && props.formValues.variants[idx].uuid ? props.formValues.variants[idx].uuid : idx}>
            <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={disabled}>
                <IconButton className={classes.variantRemoveButton}
                            color='primary' onClick={() => props.fields.remove(idx)}
                            disabled={disabled}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Row wrap>
                <Col width='672px' className='nobmargin'>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name={`${field}.name`} fieldType='TextField'
                               label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.variants.name'})}*`} />
                        <Field name={`${field}.active`} fieldType='Checkbox' size='regular'
                               label={<FormattedMessage id='submissionproducts.detail.form.fields.variants.active' />} />
                        <SpaceDivider none />
                        <Field name={`${field}.code`} fieldType='TextField'
                               label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.variants.code'})}*`} />
                        <SpaceDivider />
                        <Field name={`${field}.claimable`} fieldType='Select'
                               label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.variants.claimable'})}*`}
                               helperText={<FormattedMessage id='submissionproducts.detail.form.fields.variants.claimable.help' />}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            {new SubmissionProductVariant().getClaimable().map(claimable => <MenuItem
                                key={claimable} value={claimable}>
                                <FormattedMessage id={`submissionproducts.detail.form.fields.variants.claimable.choices.${claimable}`} />
                            </MenuItem>)}
                        </Field>
                        <SpaceDivider none />
                        <Field name={`${field}.salesforce_id`} fieldType='TextField'
                               label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.variants.salesforce_id'})}*`}
                               helperText={<FormattedMessage id='submissionproducts.detail.form.fields.variants.salesforce_id.help' />} />
                        <SpaceDivider />
                        <DropFile label={<FormattedMessage id='submissionproducts.detail.form.fields.variants.image' />}
                                  helperText={<FormattedMessage id='submissionproducts.detail.form.fields.variants.image.help' />}
                                  initialFile={props.initialValues && props.initialValues.variants && props.initialValues.variants[idx]
                                      ? {id: props.initialValues.variants[idx].image, url: props.initialValues.variants[idx].image_url}
                                      : {}}
                                  selectedFile={props.formValues.variants && props.formValues.variants[idx]
                                      ? {id: props.formValues.variants[idx].image, url: props.formValues.variants[idx].image_url}
                                      : {}}
                                  fieldChange={(id, url) => {
                                      props.change(`${field}.image`, id);
                                      props.change(`${field}.image_url`, url);
                                      props.change(`${field}.image_to_upload`, null);
                                  }}
                                  onChange={(file) => {
                                      props.change(`${field}.image`, file);
                                      props.change(`${field}.image_url`, null);
                                      props.change(`${field}.image_to_upload`, file);
                                  }}
                                  imageOnly />
                    </Form>
                    <SpaceDivider />
                </Col>
                <Col width='552px'>
                    <div className={classes.rebatesHolder}>
                        <SpaceDivider />
                        <Typography variant='body1'>
                            <FormattedMessage id='submissionproducts.detail.form.fields.variants.rebates' />
                        </Typography>
                        <SpaceDivider />
                        <Form onSubmit={props.handleSubmit}>
                            {(props.formValues.variants && props.formValues.variants[idx] && props.formValues.variants[idx].rebates) &&
                            Object.keys(props.formValues.variants[idx].rebates).sort((a, b) => a === b ? 0 : a === 'default' ? -1 : b === 'default' ? 1 : a < b ? -1 : 1).map((rebates, rsidx) => <React.Fragment key={rsidx}>
                                {rebates !== 'default' && <React.Fragment>
                                    <div className={classes.rebatesTitle}>
                                        <div>{sales_clusters.find(s => s.get(new SalesCluster().getUniqueIdentifier()) === rebates)?.get('name') || rebates}</div>
                                    </div>
                                    <SpaceDivider half />
                                </React.Fragment>}
                                {['registered', 'silver', 'gold'].map((rebate, ridx) => <FieldWithIconHolder key={ridx}>
                                    <Field name={`${field}.rebates.${rebates}.${rebate}`} fieldType='TextField' type='number' size='half'
                                           withIcon iconWidth={19}
                                           label={`${props.intl.formatMessage({id: `submissionproducts.detail.form.fields.variants.rebates.${rebate}`})}*`} />
                                    <FieldIcon disabled={true} className={classes.rebateIcon}>
                                        <EuroIcon />
                                    </FieldIcon>
                                </FieldWithIconHolder>)}
                                {rebates !== 'default' && <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={disabled}>
                                    <IconButton color='primary' disabled={disabled}
                                                onClick={() => {
                                                    let rebates_value = JSON.parse(JSON.stringify(props.formValues.variants[idx].rebates));
                                                    delete rebates_value[rebates];
                                                    props.change(`${field}.rebates`, rebates_value);
                                                }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>}
                            </React.Fragment>)}
                        </Form>
                        <hr />
                        <Form className={classes.addRebatesForm}>
                            <FieldWithIconHolder>
                                <SalesClustersField name={`rebates_sales_clusters.${idx}`} disabled={disabled}
                                                    withIcon iconWidth={120}
                                                    excludedSalesClusters={(props.formValues.variants && props.formValues.variants[idx] && props.formValues.variants[idx].rebates) &&
                                                    Object.keys(props.formValues.variants[idx].rebates)} />
                                <FieldIcon button iconWidth={120}>
                                    <ActionButton
                                        disabled={disabled || !props.formValues.rebates_sales_clusters || !props.formValues.rebates_sales_clusters[idx]}
                                        onClick={disabled || !props.formValues.rebates_sales_clusters || !props.formValues.rebates_sales_clusters[idx] ? () => {} : () => {
                                            props.change(`${field}.rebates.${props.formValues.rebates_sales_clusters[idx]}`, props.initialVariantValues.rebates.default);
                                            props.change(`rebates_sales_clusters.${idx}`, null);
                                        }}
                                        variant='outlined' color='primary'>
                                        <AddIcon />
                                        <FormattedMessage id='actions.create' />
                                    </ActionButton>
                                </FieldIcon>
                            </FieldWithIconHolder>
                        </Form>
                    </div>
                    <SpaceDivider />
                </Col>
            </Row>
            {props.fields.length !== (idx + 1) && <hr />}
            <SpaceDivider />
        </div>)}
        <Button variant='outlined'
                disabled={disabled} color='primary'
                onClick={() => {
                    props.fields.push({uuid: `newone-${variantID}`, ...props.initialVariantValues});
                    setVariantID(variantID + 1);
                }}>
            <AddIcon />
            <FormattedMessage id='actions.create' />
        </Button>
        <SpaceDivider half />
    </React.Fragment>;
}

/**
 * Renders detail of Submission Products with variants - ADD & EDIT
 */
function Detail(props) {
    // redux store
    const dispatch = useDispatch();
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    /**
     * During initialization fetch ALL items, we need all of them for isUnique validation
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(SubmissionProduct, 'submission-products', 'submission-products'));
        }
    }, [props.loaded]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.products_loaded === false) {
            dispatch(fetchItems(Product, 'products', 'products', null, null, {affect_state: false}));
        }
    }, [props.products_loaded]);

    return <div>
        {['fetching_items_submission-products', 'deleting_item_submission-products'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='submissionproducts.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='submission_product' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='submissionproducts.detail.notfound.title' />}
                text={<FormattedMessage id='submissionproducts.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='submissionproducts.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='submissionproducts.detail.edit.title' />
                                : <FormattedMessage id='submissionproducts.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='submission_product' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.name'})}*`} />
                        <FieldWithIconHolder>
                            <Field withIcon={props.item_product} name='code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'submissionproducts.detail.form.fields.code'})}*`}
                                   disabled={edit} helperText={<FormattedMessage id='submissionproducts.detail.form.fields.code.help' />} />
                            {props.item_product && <FieldIcon
                                disabled={props.state !== null}
                                onClick={() => props.state !== null ? {} : props.history.push(`/products/${props.item_product.get(new Product().getUniqueIdentifier())}`)}>
                                <LinkToIcon />
                            </FieldIcon>}
                        </FieldWithIconHolder>
                    </Form>
                </CardContent>
                <CardContent>
                    <hr />
                    <Typography variant='body1'><FormattedMessage id='submissionproducts.detail.form.fields.variants' /></Typography>
                    <SpaceDivider />
                    {(props.submitFailed && props.error && ['file_bad_type', 'file_too_large'].includes(props.error)) && <React.Fragment>
                        <ErrorMessage>
                            <FormattedMessage id={props.error === 'file_too_large' ? 'upload.error.size' : 'upload.error.type'} />
                        </ErrorMessage>
                        <SpaceDivider />
                    </React.Fragment>}
                    <FieldArray name='variants' component={RenderVariantsFieldArray} props={props} />
                    <hr />
                </CardContent>
                {[
                    'uploading_submission-products', 'uploaded_submission-products', 'failed_upload_submission-products',
                    'saving_item_submission-products', 'saved_item_submission-products', 'failed_save_item_submission-products'
                ].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_submission-products'}
                                         failure={['failed_upload_submission-products', 'failed_save_item_submission-products'].includes(props.state)}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/submission-products')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <React.Fragment>
                            {edit && <Button
                                variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => setDeleteDialogOpen(true)}>
                                <DeleteIcon />
                                <FormattedMessage id='actions.delete' />
                            </Button>}
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'submissionproducts.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'submissionproducts.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(SubmissionProduct, 'submission-products', props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'submissionproducts.detail.delete.fail'}).then(result => {
                                if (result !== false) {
                                    props.history.push('/products');
                                }
                            });
                    }}
                />}
            </Card>
        </div>}
    </div>;
}


const validate = (data, props) => {
    const errors = {variants: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    // get props.items without props.item
    let items_without_self = props.item ? props.items.filter(el => el.get(new SubmissionProduct().getUniqueIdentifier()) !== props.item.get(new SubmissionProduct().getUniqueIdentifier())) : props.items;

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'code', data.code) &&
    validator.isSlug(null, errors, 'code', data.code) &&
    validator.isUnique(
        props.intl.formatMessage({id: 'submissionproducts.detail.error.code'}),
        errors, 'code', data.code,
        items_without_self, new SubmissionProduct().getUniqueIdentifier());

    // validate variants fieldArray
    if (data.variants && data.variants.length) {
        data.variants.forEach((el, idx) => {
            // prepare error array
            if (!errors.variants[idx]) {
                errors.variants[idx] = {rebates: {}};
            }
            // get variants without current variant
            let variants_without_self = [];
            items_without_self.map(el => el.get('variants').map(variant => variants_without_self.push(variant)));
            variants_without_self = ImmutableList(variants_without_self);

            // validation
            validator.isNotNull(null, errors, `variants.${idx}.name`, data.variants[idx] && data.variants[idx].name);
            validator.isNotNull(null, errors, `variants.${idx}.code`, data.variants[idx] && data.variants[idx].code) &&
            validator.isSlug(null, errors, `variants.${idx}.code`, data.variants[idx] && data.variants[idx].code) &&
            validator.isUnique(
                props.intl.formatMessage({id: 'submissionproducts.detail.error.variants.code'}),
                errors, `variants.${idx}.code`, data.variants[idx] && data.variants[idx].code,
                variants_without_self, new SubmissionProductVariant().getUniqueIdentifier()) &&
            validator.isUnique(
                props.intl.formatMessage({id: 'submissionproducts.detail.error.variants.code'}),
                errors, `variants.${idx}.code`, data.variants[idx] && data.variants[idx].code,
                data.variants.map((variant, idy) => idy !== idx && variant && variant.code), null);
            validator.isNotNull(null, errors, `variants.${idx}.claimable`, data.variants[idx] && data.variants[idx].claimable);
            validator.isNotNull(null, errors, `variants.${idx}.salesforce_id`, data.variants[idx] && data.variants[idx].salesforce_id);
            // rebates validation
            Object.keys(data.variants[idx] && data.variants[idx].rebates ? data.variants[idx].rebates : []).forEach(rebates => {
                // prepare error array
                errors.variants[idx].rebates[rebates] = {};
                // validation of all types
                ['registered', 'silver', 'gold'].forEach(rebate => {
                    let rebate_value = (data.variants[idx] && data.variants[idx].rebates && data.variants[idx].rebates[rebates]) && data.variants[idx].rebates[rebates][rebate];

                    validator.isNotNull(null, errors, `variants.${idx}.rebates.${rebates}.${rebate}`, rebate_value);
                    if (rebate_value) {
                        validator.isInt(null, errors, `variants.${idx}.rebates.${rebates}.${rebate}`, rebate_value, {allow_leading_zeroes: false, min: 0});
                    }
                });
            });
        });
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'submissionProductForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let [rest_of_sproduct_data, identifier] = new SubmissionProduct().popUpFields(props.formValues, true);
        // remove 'rebates_sales_clusters' which we use to add new sales_clusters rebates
        let {rebates_sales_clusters, ...rest_of_data} = rest_of_sproduct_data;

        // get files to upload for each variant
        let files_to_upload = [];
        rest_of_data.variants.forEach(variant => {
            if (variant.image_to_upload) {
                files_to_upload.push(variant.image_to_upload);
            }
        });

        // copy object to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));

        return dispatch(uploadFiles('submission-products', 'product-images', files_to_upload)).then(results => {
            let id = 0;
            // process variants
            rest_of_data.variants.forEach((variant, idx) => {
                // do we have upload result to this variant?
                if (variant.image_to_upload) {
                    // add image (ID) from newly uploaded images (replaces file blop)
                    rest_of_data.variants[idx].image = results[id].data.file_id;
                    id++;
                }
                // remove data which we don't want to send
                delete rest_of_data.variants[idx].image_url;
                delete rest_of_data.variants[idx].image_to_upload;
                delete rest_of_data.variants[idx].uuid;
            });
            // save object
            return dispatch(saveItem(SubmissionProduct, 'submission-products', props.item ? props.item.getIn(['links', 'self']) : ['submission-products', identifier], rest_of_data, props.item, {update_method: 'put', save_method: 'put', add_mark_filtered: false})).then(create_data => {
                // in case of add, redirect to detail
                if (!props.item && create_data.get(new SubmissionProduct().getUniqueIdentifier())) {
                    props.history.push(`/submission-products/${create_data.get(new SubmissionProduct().getUniqueIdentifier())}`);
                }
            });
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items = state.shared.getIn(['items', 'submission-products']);
    const item = items.find(el => el.get(new SubmissionProduct().getUniqueIdentifier()) === props.match.params.identifier);
    const products = state.shared.getIn(['items', 'products']);

    // process invoices (we need extra UUID to keep proper state value in <DropFile /> after changing field index
    let processedVariants = [];
    item && item.toJS().variants.forEach((invoice, idx) => {
        processedVariants.push({uuid: `initialvalue-${idx}`, ...invoice});
    });

    return {
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', 'submission-products']),
        items: items,
        item: item,
        item_product: item ? products.find(product => product.get('code') === item.get('code')) : undefined,
        products_loaded: state.shared.getIn(['loaded', 'products']),
        products_items: products,
        initialValues: props.match.params.identifier
            ? item ? {...item.toJS(), variants: processedVariants} : undefined
            : {variants: []},
        initialVariantValues: {
            active: true, claimable: new SubmissionProductVariant().getClaimable()[0],
            rebates: {default: {registered: 0, silver: 0, gold: 0}}
        },
        formValues: getFormValues('submissionProductForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItems,
    saveItem,
    deleteItem,
    uploadFiles
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
