import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {theme} from 'theme';
// components
import VoiceQualityWave from 'components/core/vectors/waves/VoiceQuality';
// material-ui
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        overflow: 'hidden',
        // style
        background: theme.palette.common.white,
        border: ({color2}) => `2px solid ${color2}`,
        borderRadius: theme.shape.borderRadius,
        // align text inside
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        // size
        minHeight: `${theme.spacing(40)}px`,
        minWidth: `${theme.spacing(30)}px`,
        height: '100%',
        // wave
        '& svg': {
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            // bellow text
            zIndex: '10'
        },
        [theme.breakpoints.down('lg')]: {
            display: 'block',
            minHeight: '0',
            '& svg': {
                position: 'static'
            },
            '& $textWrapper': {
                marginBottom: '0'
            }
        }
    },
    textWrapper: {
        padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        // off-center
        marginBottom: `${theme.spacing(5)}px`,
        // above wave
        position: 'relative',
        zIndex: '20'
    },
    title: {
        color: ({color2}) => color2,
        fontWeight: theme.typography.fontWeightMedium
    }
}));

/**
 *  Renders Voice Quality banner with changing colors, title based on variant
 */
export default function QualityBanner(props) {
    const {variant} = props;

    const [color1, color2] = useMemo(() => {
        switch (variant) {
            case 'perfect':
                return [theme.gradients.blue.color2, theme.gradients.blue.color1];
            case 'fair':
                return [theme.gradients.green.color2, theme.gradients.green.color1];
            case 'annoying':
                return [theme.gradients.gray.color1, theme.gradients.gray.color2];
            case 'very_annoying':
                return [theme.gradients.orange.color1, theme.gradients.orange.color2];
            case 'impossible':
                return [theme.gradients.orange.color2, theme.gradients.red.color2];
            default:
                return [theme.palette.grey[300], theme.palette.grey[500]];
        }
    }, [variant]);
    const classes = useStyles({color1, color2});

    return <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
            <Typography variant='h4' className={`${classes.title} newfont`}>
                <FormattedMessage id={`voicequality.data.${variant}`} />
            </Typography>
            <Typography color='textSecondary' className='newfont'>
                <FormattedMessage id='voicequality.banner.subheader' />
            </Typography>
        </div>
        <VoiceQualityWave color1={color1} color2={color2} />
    </div>;
}
