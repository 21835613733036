import React from 'react';
// components
import {EnhancedReduxField} from 'components/core/ui/Field';
import AssetFoldersMenu from 'components/modules/assets/FolderMenu';
// material-ui
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';


/**
 * Redux Field for Asset Folder Select - AssetFolderMenu to select folder's path
 */
export default function AssetFolderSelect(props) {
    return <EnhancedReduxField {...props} component={(field) =>
        <FormControl fullWidth>
            <AssetFoldersMenu targetFolder={field.targetFolder} initialValue={field.meta.initial}
                select selectWithRoot={field.selectWithRoot} value={field.input.value} onChange={field.input.onChange} />
            {field.meta.touched && field.meta.error
                ? <FormHelperText error>{field.meta.error}</FormHelperText>
                : field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
        </FormControl>
    } />
    ;
}
