import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import {makeStyles} from '@material-ui/core/styles';
import validator from 'lib/valitator';
import {Base64} from 'js-base64';
import {Company, ProductDefaults, Product, ProductGroup, Firmware, SettingGroup, Setting} from 'lib/models';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
import {useLocalSort} from 'lib/filters';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem, setCollection} from 'actions/shared';
// Components
import ProvisioningFiles from 'components/modules/provfiles/List';
import ThemeProvider from 'components/ThemeProvider';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import Field, {HandsetsField, SettingField, validateSettingsField, getSettingsInitialValues, CompanyField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import CopyIcon from '@material-ui/icons/AddToPhotosOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


const useStyles = makeStyles(theme => ({
    filesHolder: {
        // to sides and connect borders with expansion panel
        margin: `-${theme.spacing(1)}px -${theme.spacing(2) + 1}px  -${theme.spacing(2) + 1}px -${theme.spacing(2) + 1}px`,
        // better style fit for <ExpansionPanel /> and <LastCard />
        '& > div:first-child, & > div:last-child': {
            borderRadius: '0'
        }
    }
}));

/**
 * Renders detail of Product Settings Defaults - ADD & EDIT
 */
function Detail(props) {
    // style
    const classes = useStyles();
    // local state
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [expanded, setExpanded] = useState(1);
    // sorting
    const [sortedProducts] = useLocalSort(props.products_items, !props.products_loaded);
    const [sortedGroups] = useLocalSort(props.groups_items, !props.groups_loaded);

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', props.selectedCompanyIdentifier],
                {success_affect_state: !edit, ignore_403: !props.location.pathname.startsWith('/all-')});
        } else if (props.firmware_loaded === false) {
            props.fetchItems(Firmware,
                props.selectedCompanyIdentifier === 'my'
                    ? 'firmware'
                    : `firmware-${props.selectedCompanyIdentifier}`,
                props.selectedCompany.getIn(['links', 'firmware']), null, null, {success_affect_state: !edit && !props.item});
        } else if (edit) {
            if (!props.item) {
                props.fetchItem(ProductDefaults,
                    props.selectedCompanyIdentifier === 'my' ? 'product-defaults' : `product-defaults-${props.selectedCompanyIdentifier}`,
                    [
                        props.selectedCompany.getIn(['links', 'product-defaults']),
                        props.match.params.identifier
                    ],
                    {company: props.selectedCompany}
                );
            } else if (['fetching_item_companies', 'fetching_item_my-companies', 'fetching_items_firmware', `fetching_items_firmware-${props.selectedCompanyIdentifier}`].includes(props.state)) {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany, props.firmware_loaded]);
    // related data fetch without affecting state
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            props.fetchItems(ProductGroup, 'product-groups', 'product-groups', null, null, {affect_state: false});
        }
    }, [props.product_groups_loaded]);
    useEffect(() => {
        if (props.products_loaded === false) {
            props.fetchItems(Product, 'products', 'products', null, null, {affect_state: false});
        }
    }, [props.products_loaded]);
    useEffect(() => {
        if (props.groups_loaded === false) {
            props.fetchItems(SettingGroup, 'setting-groups', 'setting-groups', null, null, {affect_state: false});
        }
    }, [props.groups_loaded]);
    useEffect(() => {
        if (props.settings_loaded === false) {
            props.fetchItems(Setting, 'settings', 'settings', null, null, {affect_state: false});
        }
    }, [props.settings_loaded]);
    useEffect(() => {
        if (props.settings_loaded && (props.formValues || {}).code) {
            const setting_groups_ids = props.groups_items.filter(group => [...group.get('product_defaults_products')].includes(props.formValues.code)).map(group => group.get(new SettingGroup().getUniqueIdentifier()));

            props.setCollection(Setting, 'settings-selected', props.settings_items.filter(
                setting => setting_groups_ids.includes(setting.get('group'))
            ));
        } else if (props.settings_selected.size && props.state !== 'copy_item_product-defaults') {
            props.setCollection(Setting, 'settings-selected', []);
        }
    }, [props.settings_loaded, props.item, (props.formValues || {}).code]);
    // unmount cleanup
    useEffect(() => {
        return () => {
            if (props.settings_selected.size) {
                props.setCollection(Setting, 'settings-selected', []);
            }
        };
    }, []);

    return <div>
        {[
            'fetching_item_companies', 'fetching_item_my-companies',
            'fetching_items_firmware', `fetching_item_firmware-${props.selectedCompanyIdentifier}`,
            'deleting_item_firmware', `deleting_item_firmware-${props.selectedCompanyIdentifier}`,
            'fetching_item_product-defaults', `fetching_item_product-defaults-${props.selectedCompanyIdentifier}`,
            'deleting_item_product-defaults', `deleting_item_product-defaults-${props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='productdefaults.detail.unknown.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='product_default' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='productdefaults.detail.notfound.title' />}
                text={<FormattedMessage id='productdefaults.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <CardFirst>
                <CardHeader subheader={<FormattedMessage id='productdefaults.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='productdefaults.detail.edit.title' />
                                : <FormattedMessage id='productdefaults.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='product_default' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='code' fieldType='Select' label={`${props.intl.formatMessage({id: 'productdefaults.detail.form.fields.code'})}*`}
                               helperText={<FormattedMessage id='productdefaults.detail.form.fields.code.help' />}
                               disabled={!props.products_loaded || edit} loading={!props.products_loaded}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            <MenuItem fake value={props.formValues && props.formValues.code} />
                            {sortedProducts.map((product, idx) =>
                                <MenuItem key={idx} value={product.get(new Product().getUniqueIdentifier())}>{product.get('name')}</MenuItem>
                            )}
                        </Field>
                        {(props.company.get('company_type') === 'reseller' || props.location.pathname.startsWith('/all-')) && <CompanyField
                            value={!edit && props.selectedCompanyIdentifier === 'my' ? ''
                                : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                            change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                            disabled={edit} all={false}
                            fetch_company={false}
                            my_companies={!props.location.pathname.startsWith('/all-')}
                        />}
                    </Form>
                    <SpaceDivider />
                </CardContent>
            </CardFirst>
            <ExpansionPanel expanded={expanded === 1} onChange={() => setExpanded(expanded === 1 ? 0 : 1)}
                            title_intl='productdefaults.detail.firmware.title'>
                <Form onSubmit={props.handleSubmit}>
                    <Field name='firmware.version' disabled={edit && !props.firmware_loaded} fieldType='TextField'
                           label={<FormattedMessage id='productdefaults.detail.firmware.form.fields.version' />}
                           helperText={<FormattedMessage id='productdefaults.detail.firmware.form.fields.version.help' />} />
                    {(props.product_groups && props.product_groups.find(product_group => product_group.get('vpn'))) && <React.Fragment>
                        <Field name='firmware.vpn' fieldType='Checkbox' size='regular'
                               label={<FormattedMessage id='productdefaults.detail.firmware.form.fields.vpn' />}
                               helperText={<FormattedMessage id='productdefaults.detail.firmware.form.fields.vpn.help'
                                                             values={{link: <a href='https://service.snom.com/display/wiki/Configuring+VPN+on+Snom+Deskphones#ConfiguringVPNonSnomDeskphones-InstalltheVPNpatchontheSnomphone'
                                                                               target='_blank' rel='noreferrer' className='hover-border'>
                                                                     <FormattedMessage id='productdefaults.detail.firmware.form.fields.vpn.help.link' />
                                                                 </a>}} />} />
                        <SpaceDivider none />
                    </React.Fragment>}
                    {(props.product && props.formValues && props.formValues.firmware && props.formValues.firmware.version) && <React.Fragment>
                        {props.product.getIn(['firmware_strategy', 'require_url']) &&
                            <Field name='firmware.url' disabled={edit && !props.firmware_loaded} fieldType='URLField'
                                   label={`${props.intl.formatMessage({id: 'productdefaults.detail.firmware.form.fields.url'})}*`}
                                   helperText={<FormattedMessage id='productdefaults.detail.firmware.form.fields.url.help' />}
                                   change={props.change} selectedValue={props.formValues.firmware.url} />
                        }
                        {(props.product.get('handsets') && !!props.product.get('handsets').size) && <React.Fragment>
                            <SpaceDivider />
                            <HandsetsField name='firmware.handsets' disabled={!props.firmware_loaded}
                                           label={<FormattedMessage id='productdefaults.detail.firmware.form.fields.handsets' />}
                                           helperText={<FormattedMessage id='productdefaults.detail.firmware.form.fields.handsets.help' />}
                                           selectedValue={props.formValues.firmware.handsets}
                                           change={props.change}
                                           product={props.product}
                            />
                        </React.Fragment>}
                    </React.Fragment>}
                </Form>
            </ExpansionPanel>
            {edit && <ExpansionPanel
                expanded={expanded === 2} onChange={() => setExpanded(expanded === 2 ? 0 : 2)}
                title_intl='productdefaults.detail.provfiles.title'>
                <div className={classes.filesHolder}>
                    <ProvisioningFiles
                        location='product_default' doNotFetchProductGroup noHeader
                        filterProductGroups={props.product ? props.product.get('groups').toJS() || [] : []}
                        initialItemSelectedFileIDs={props.initialValues.provisioning_files || []}
                        itemSelectedFileIDs={props.formValues.provisioning_files || []}
                        itemSelectFileIDs={(ids) => props.change('provisioning_files', ids)}
                    />
                </div>
            </ExpansionPanel>}
            {edit && (!props.groups_loaded || !props.settings_loaded)
                ? <ExpansionPanel expanded={false} noLink loading />
                : !!props.settings_selected.size && sortedGroups.map((group, idx) => !!props.settings_selected.filter(setting => setting.get('group') === group.get(new SettingGroup().getUniqueIdentifier())).size && <ExpansionPanel
                key={idx} expanded={expanded === (idx + 3)} onChange={() => setExpanded(expanded === (idx + 3) ? 0 : (idx + 3))}
                title={group.get('name')} subtitle={group.get('description')}>
                {group.get('name').toLowerCase().includes('customisation') && <React.Fragment>
                    <img src={require('img/sraps/gui_customization.png')} alt={props.intl.formatMessage({id: 'productdefaults.detail.gui.form.color.help_image'})} />
                    <SpaceDivider double />
                </React.Fragment>}
                <Form onSubmit={props.handleSubmit}>
                    <AutoCompleteOff />
                    {props.formValues && props.settings_selected
                        .filter(setting => setting.get('group') === group.get(new SettingGroup().getUniqueIdentifier()))
                        .sort((a, b) => a.get('name').toLowerCase() === b.get('name').toLowerCase() ? 0 : a.get('name').toLowerCase() < b.get('name').toLowerCase() ? -1 : 1)
                        .map((setting, idx) => <React.Fragment key={idx}>
                            <SettingField
                                namePrefix={`settings.${setting.get(new Setting().getUniqueIdentifier())}`}
                                setting={setting} required={false}
                                change={props.change}
                                selectedValue={new ImmutableMap(Object.entries(props.formValues.settings)).get(setting.get(new Setting().getUniqueIdentifier()))
                                    ? setting.get('indexed') && setting.getIn(['indexed', 'enabled'])
                                    ? new ImmutableMap(Object.entries(props.formValues.settings)).get(setting.get(new Setting().getUniqueIdentifier()))
                                    : new ImmutableMap(Object.entries(props.formValues.settings)).get(setting.get(new Setting().getUniqueIdentifier())).value
                                    : undefined
                                }
                            />
                            {!!(idx % 2) && <SpaceDivider />}
                        </React.Fragment>)}
                </Form>
            </ExpansionPanel>)}
            <CardLast>
                {[
                    'saving_item_product-defaults', `saving_item_product-defaults-${props.selectedCompanyIdentifier}`,
                    'saving_item_firmware', `saving_item_firmware-${props.selectedCompanyIdentifier}`,
                    'saved_item_product-defaults', `saved_item_product-defaults-${props.selectedCompanyIdentifier}`,
                    'saved_item_firmware', `saved_item_firmware-${props.selectedCompanyIdentifier}`,
                    'failed_save_item_product-defaults', `failed_save_item_product-defaults-${props.selectedCompanyIdentifier}`,
                    'failed_save_item_firmware', `failed_save_item_firmware-${props.selectedCompanyIdentifier}`,
                    'deleting_item_firmware_save', 'saved_item_product-defaults_with_firmware',
                    'copy_item_product-defaults'
                ].includes(props.state)
                    ? <CardActionsLoader success={['saved_item_product-defaults_with_firmware', 'copy_item_product-defaults'].includes(props.state)}
                                         failure={[
                                             'failed_save_item_product-defaults', `failed_save_item_product-defaults-${props.selectedCompanyIdentifier}`,
                                             'failed_save_item_firmware', `failed_save_item_firmware-${props.selectedCompanyIdentifier}`
                                         ].includes(props.state)}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push(
                                    `/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}product-defaults${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`)}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <React.Fragment>
                            {edit && <React.Fragment>
                                <ThemeProvider>
                                    <Button variant='outlined' color='primary'
                                            disabled={props.state !== null}
                                            onClick={() => {
                                                props.setState('copy_item_product-defaults');
                                                props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}product-defaults/add${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                                                setTimeout(() => {
                                                    Object.entries(props.formValues).map(([key, value]) => props.change(key, value));
                                                }, 0);
                                            }}>
                                        <CopyIcon />
                                        <FormattedMessage id='actions.copy' />
                                    </Button>
                                </ThemeProvider>
                                <Button variant='contained' color='secondary'
                                        disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>
                            </React.Fragment>}
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.state === null ? props.handleSubmit() : {}}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'productdefaults.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'productdefaults.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        let deleteItem = () => {
                            props.deleteItem(ProductDefaults,
                                `product-defaults${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`,
                                props.item.getIn(['links', 'self']),
                                props.item
                            ).then(() => {
                                props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}product-defaults${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`);
                            });
                        };
                        // check if we also need to delete firmware
                        if (props.firmware) {
                            props.deleteItem(Firmware,
                                `firmware${props.selectedCompanyIdentifier === 'my' ? '' : `-${props.selectedCompanyIdentifier}`}`,
                                props.firmware.getIn(['links', 'self']),
                                props.firmware, {success_affect_state: false}
                            ).then(() => {
                                deleteItem();
                            });
                        } else {
                            deleteItem();
                        }
                    }}
                />}
            </CardLast>
        </div>}
    </div>;
}

const validate = (data, props) => {
    const errors = {settings: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    const nested_firmware = data.firmware || {}; // nested fix
    const nested_settings = data.settings ? new ImmutableMap(Object.entries(data.settings)) : new ImmutableMap(); // nested fix

    // validate form
    validator.isNotNull(null, errors, 'code', data.code);

    // validate firmware
    if (nested_firmware.version) {
        if (props.product && props.product.getIn(['firmware_strategy', 'require_url'])) {
            validator.isNotNull(null, errors, 'firmware.url', nested_firmware.url);
        }
    }

    if (props.settings_loaded) {
        // validate dynamic Settings
        props.settings_selected.forEach((setting) => {
            // make sure to validate only NOT INDEXED fields (they have validation in IndexedSettingField)
            if (setting.get('indexed') && !setting.getIn(['indexed', 'enabled'])) {
                errors['settings'][setting.get(new Setting().getUniqueIdentifier())] = validateSettingsField(
                    nested_settings.get(setting.get(new Setting().getUniqueIdentifier())) || {},
                    setting, props.intl, false
                );
            }
        });
    }

    return errors;
};

/**
 * Convert Product Defaults record toObject(). Used for initial values
 *
 * @param proddefaults - Product Defaults model
 */
function toObject(proddefaults) {
    let itemObject = proddefaults.toJS();
    itemObject.settings = itemObject.settings || {};
    // process settings
    proddefaults.settings && proddefaults.settings.forEach((values, key) => {
        // certificate process
        if (values.getIn(['attrs', 'type']) === 'base64') {
            let certificates = [];
            values.get('value').forEach(value => {
                certificates.push(Base64.decode(value));
            });
            itemObject.settings[key]['value'] = certificates;
        }
    });
    return itemObject;
}

const DetailForm = reduxForm({
    form: 'productDefaultsForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation for SettingFields
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data_with_fw = new ProductDefaults().popUpFields(props.formValues);
        // split firmware values
        let {firmware, ...rest_of_data} = rest_of_data_with_fw;
        let rest_of_firmware_data = new Firmware().popUpFields(firmware);
        // copy objects to not immediately modify it's reference
        rest_of_data = JSON.parse(JSON.stringify(rest_of_data));
        rest_of_firmware_data = JSON.parse(JSON.stringify(rest_of_firmware_data));

        // process VPN (only for some Product Groups)
        if (!props.product_groups || !props.product_groups.find(product_group => product_group.get('vpn'))) {
            delete rest_of_firmware_data.vpn;
        }

        // process settings
        let settings = {}; // we will send only selected settings with value
        props.settings_selected.forEach(setting => {
            let value = new ImmutableMap(Object.entries(rest_of_data['settings'])).get(setting.get(new Setting().getUniqueIdentifier()));
            // check if we have value
            if (setting.getIn(['indexed', 'enabled']) ? value : (value.value && value.value.length !== 0)) {
                settings[setting.get(new Setting().getUniqueIdentifier())] = value;
                // certificate process
                if (setting.get('field_type') === 'certificate') {
                    let certificates = [];
                    value.value.forEach(certificate => {
                        certificates.push(Base64.encode(certificate));
                    });
                    settings[setting.get(new Setting().getUniqueIdentifier())]['value'] = certificates;
                }
            }
        });
        rest_of_data['settings'] = settings;

        return dispatch(saveItem(
            ProductDefaults,
            props.selectedCompanyIdentifier === 'my' ? 'product-defaults' : `product-defaults-${props.selectedCompanyIdentifier}`,
            props.item
                ? props.item.getIn(['links', 'self'])
                : [props.selectedCompany.getIn(['links', 'product-defaults']), props.formValues.code],
            rest_of_data, props.item, {update_method: 'put', save_method: 'put', add_mark_filtered: false}
        )).then(create_data => {
            const finish = () => {
                // in case of add, redirect to detail
                if (!props.item && create_data.get(new ProductDefaults().getUniqueIdentifier())) {
                    props.history.push(`/${props.location.pathname.startsWith('/all-') ? 'all-' : ''}product-defaults/${create_data.get(new ProductDefaults().getUniqueIdentifier())}`);
                }
                dispatch(setState('saved_item_product-defaults_with_firmware'));
            };

            // handle firmware version
            if (props.firmware && !rest_of_firmware_data.version && !rest_of_firmware_data.vpn) {
                // delete firmware
                return dispatch(deleteItem(
                    Firmware,
                    props.selectedCompanyIdentifier === 'my' ? 'firmware' : `firmware-${props.selectedCompanyIdentifier}`,
                    props.firmware.getIn(['links', 'self']),
                    props.firmware, {state_name: 'firmware_save'}
                )).then(() => {
                    finish();
                });
            } else if (rest_of_firmware_data.version || rest_of_firmware_data.vpn) {
                // add / update firmware (same PUT method so it doesn't matter)
                return dispatch(saveItem(
                    Firmware,
                    props.selectedCompanyIdentifier === 'my' ? 'firmware' : `firmware-${props.selectedCompanyIdentifier}`,
                    props.firmware
                        ? props.firmware.getIn(['links', 'self'])
                        : [props.selectedCompany.getIn(['links', 'firmware']), props.formValues.code],
                    rest_of_firmware_data, props.firmware, {add_mark_filtered: false, save_method: 'put', update_method: 'put', error_field_prefix: 'firmware'}
                )).then(() => {
                    finish();
                });
            } else {
                finish();
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    const items = selectedCompanyIdentifier === 'my' ? state.shared.getIn(['items', 'product-defaults']) : state.shared.getIn(['items', `product-defaults-${selectedCompanyIdentifier}`]) || ImmutableList();
    const item = items.find(el => el.get(new ProductDefaults().getUniqueIdentifier()) === props.match.params.identifier);
    const itemObject = item ? toObject(item) : {};

    const formValues = getFormValues('productDefaultsForm')(state);
    const firmware_location = selectedCompanyIdentifier === 'my' ? 'firmware' : `firmware-${selectedCompanyIdentifier}`;
    const firmware_items = state.shared.getIn(['items', firmware_location]) || ImmutableList();
    const firmware = (item || formValues) ? firmware_items.find(el => el.get('code') === (item ? item.get('code') : formValues.code)) : null;

    const products_loaded = state.shared.getIn(['loaded', 'products']);
    const products_items = state.shared.getIn(['items', 'products']);
    const product = (item || formValues) && products_loaded ? products_items.find(el => el.get(new Product().getUniqueIdentifier()) === (item ? item.get('code') : formValues.code)) : null;

    return {
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        initialValues: props.match.params.identifier
            ? item
                ? {firmware: firmware ? {vpn: false, ...firmware.toJS(), handsets: firmware.get('handsets') ? firmware.get('handsets').toObject() : {}} : {vpn: false},
                    provisioning_files: [], ...itemObject, settings: {...getSettingsInitialValues(state, {use_default: false}), ...itemObject.settings}}
                : undefined
            : {firmware: {vpn: false}, provisioning_files: [], settings: getSettingsInitialValues(state)},
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        product_groups: product && state.shared.getIn(['loaded', 'product-groups']) ? state.shared.getIn(['items', 'product-groups']).filter(el => product.get('groups').includes(el.get(new ProductGroup().getUniqueIdentifier()))) : undefined,
        products_loaded: products_loaded,
        products_items: products_items,
        product: product,
        firmware_loaded: !!state.shared.getIn(['loaded', firmware_location]),
        firmware_items: firmware_items,
        firmware: firmware,
        groups_loaded: state.shared.getIn(['loaded', 'setting-groups']),
        groups_items: state.shared.getIn(['items', 'setting-groups']),
        settings_loaded: state.shared.getIn(['loaded', 'settings']),
        settings_items: state.shared.getIn(['items', 'settings']),
        settings_selected: state.shared.getIn(['items', 'settings-selected']),
        company: company,
        user: auth_user,
        formValues: formValues
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem,
    setCollection
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
