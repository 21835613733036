import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router';
import {intlReducer} from 'react-intl-redux';
// Reducers
import api from 'reducers/api';
import app from 'reducers/app';
import auth from 'reducers/auth';
import shared from 'reducers/shared';


const reducers = (history) => combineReducers({
    api,
    app,
    auth,
    shared,
    form: formReducer,
    router: connectRouter(history),
    intl: intlReducer
});
export default reducers;
