import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import CardContent from 'components/core/ui/mui/CardContent';
import MUICardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    // cardHeader + cardContent (subheader) close together
    cardHeaderRoot: {
        paddingBottom: '0',
        alignItems: 'flex-start',
        minHeight: '64px',
        '&.center': {
            textAlign: 'center'
        },
        // responsive (2 rows)
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '&.mobileactions': {
                // responsive (keep 1 row)
                [theme.breakpoints.down('xs')]: {
                    display: 'flex'
                }
            }
        }
    },
    cardHeaderTitle: {
        '&.newfont': {
            // more matching size to 'Roboto' variant
            fontSize: theme.typography.pxToRem(26),
            // recolor
            color: theme.palette.snomGrey[800],
            transition: theme.transitions.create('color',
                {duration: theme.transitions.duration.short})
        }
    },
    cardHeaderAction: {
        // responsive (hide)
        [theme.breakpoints.down('xs')]: {
            marginTop: '0',
            '&.hideicons': {
                // hide icons and display just buttons (add)
                '& .iconButton': {
                    display: 'none'
                }
            }
        }
    },
    cardHeaderSubheader: {
        paddingTop: '0',
        '&.center': {
            textAlign: 'center'
        }
    },
    cardHeaderSubheaderText: {
        '&.newfont': {
            // more matching size to 'Roboto' variant
            fontSize: theme.typography.pxToRem(17)
        }
    }
}));

/**
 * Extends CardHeader from material-ui to rewrite some of the styles
 */
export default function CardHeader(props) {
    // Split some stuff from props
    const {subheader, className, _classes, center, newfont, mobileActions = false, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, action, title, subhead, subheaderText, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.cardHeaderRoot, root, className,
        mobileActions ? 'mobileactions' : '',
        center ? 'center' : ''
    ].filter(Boolean).join(' ');
    const titleClasses = [
        classes.cardHeaderTitle, title,
        newfont ? 'newfont' : ''
    ].filter(Boolean).join(' ');
    const subheaderClasses = [
        classes.cardHeaderSubheader, subhead,
        center ? 'center' : ''
    ].filter(Boolean).join(' ');
    const subheaderTextClasses = [
        classes.cardHeaderSubheaderText, subheaderText,
        newfont ? 'newfont' : ''
    ].filter(Boolean).join(' ');
    const actionClasses = [
        classes.cardHeaderAction, action,
        mobileActions ? '' : 'hideicons'
    ].filter(Boolean).join(' ');

    return <div>
        <MUICardHeader
            classes={{
                root: rootClasses,
                title: titleClasses,
                action: actionClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />
        <CardContent className={subheaderClasses}>
            <Typography color='textSecondary' className={subheaderTextClasses}>{subheader}</Typography>
        </CardContent>
    </div>;
}
