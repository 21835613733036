import React, {useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// components
import DMInfoCard from 'components/modules/tickets/dm/DMInfoCard';
// material-ui
import IconButton from '@material-ui/core/IconButton';
// icons
import SipIcon from '@material-ui/icons/DialerSipOutlined';
import ConnectedIcon from '@material-ui/icons/CloudDoneOutlined';
import OfflineBoltIcon from '@material-ui/icons/OfflineBoltOutlined';
import NotConnectedIcon from '@material-ui/icons/NotInterestedOutlined';
import LeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import RightIcon from '@material-ui/icons/ChevronRightOutlined';


const useStyles = makeStyles(theme => ({
    cardContent: {
        // move pagination to bottom
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },
    pagination: {
        // move into padding
        marginBottom: `-${theme.spacing(2)}px`,
        // icons and text next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: `${theme.spacing(1)}px`,
        '& .text': {
            // fixed size to prevent jumping
            width: '60px'
        }
    }
}));

/**
 * Render device manager sip status list
 */
export default function DMSIPStatus(props) {
    const {item} = props;
    const classes = useStyles();
    // local state
    const [page, setPage] = useState(1);
    // data
    const [status, value, lastUpdate, pages] = useMemo(() => {
        const status = item.getIn(['data', `SIPStatus.${page}`, 'value']);
        const color = status && (status.startsWith('Up') ? 'success' : status.startsWith('Error') ? 'danger' : undefined);

        return [
            <React.Fragment>
                {color ? color === 'success' ? <ConnectedIcon className='text-success' /> : <OfflineBoltIcon className='text-danger' /> : <NotConnectedIcon className='text-grey' />}
                <FormattedMessage id='devicemanagement.type.sipstatus.identity' values={{number: page}} />
            </React.Fragment>,
            item.getIn(['data', `SIPEnable.${page}`, 'value']),
            item.getIn(['data', 'SIPStatus.1', 'updated_at']),
            item.get('data')?.keySeq().filter(key => key.startsWith('SIPStatus')).reduce((val, key) => {
                const curVal = parseInt(key.split('.').slice(1), 10);
                return val < curVal ? curVal : val;
            }, 1) || 12
        ];
    }, [page, item.getIn(['data', `SIPStatus.${page}`]) && JSON.stringify(item.getIn(['data', `SIPStatus.${page}`]))]);

    return <DMInfoCard
        title={<React.Fragment>
            <FormattedMessage id='devicemanagement.type.sipstatus' />
            {` (${page}/${pages})`}
        </React.Fragment>}
        icon={<SipIcon />}
        info={[status, value]} lastUpdate={lastUpdate}
        className={classes.cardContent}>
        {({info}) => <React.Fragment>
            <div>
                {info}
            </div>
            <div className={classes.pagination}>
                <IconButton onClick={() => setPage(page !== 1 ? page - 1 : pages)}>
                    <LeftIcon />
                </IconButton>
                <span className='text'>
                    <FormattedMessage
                        id='pagination.label'
                        values={{page: page, pages: pages}}
                    />
                </span>
                <IconButton onClick={() => setPage(page !== pages ? page + 1 : 1)}>
                    <RightIcon />
                </IconButton>
            </div>
        </React.Fragment>}
    </DMInfoCard>;
}
