import React, {useMemo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {User, Company, Role} from 'lib/models';
import {useLocalSort} from 'lib/filters';
import {List as ImmutableList} from 'immutable';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem, markFiltered} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import PasswordChange from 'components/modules/users/PasswordChange';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {CompanyField, SalutationField, DepartmentField} from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';


/**
 * Renders detail of a User account (e.g. My Profile, /customers/Nick)
 */
function Detail(props) {
    // local state
    const edit = !!props.match.params.identifier || props.location.pathname.replace('/partner', '').startsWith('/profile');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    // sorting
    const [locallySortedRolesByName,,,, sortInitializing] = useLocalSort(props.roles_items, !props.roles_loaded);
    const [locallySortedRoles] = useLocalSort(locallySortedRolesByName, sortInitializing, null, ['for_supergroup'], 'for_supergroup', 'asc');
    const sortedRoles = useMemo(() => {
        return props.item_is_admin ? locallySortedRoles : locallySortedRoles.filter(role => !role.get('for_supergroup'));
    }, [locallySortedRoles, props.item_is_admin]);

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        if (!props.selectedCompany) {
            props.fetchItem(Company, props.user.isAdmin() ? 'companies' : 'my-companies', ['companies', props.selectedCompanyIdentifier],
                {success_affect_state: !edit, ignore_403: !props.user.isAdmin()});
        } else if (edit) {
            if (!props.item) {
                if (props.user.isAdmin()) {
                    props.fetchItem(User, 'users', ['users', props.match.params.identifier], {company: props.selectedCompany});
                } else {
                    props.fetchItems(User, `companyUsers-${props.selectedCompany.get(new Company().getUniqueIdentifier())}`, props.selectedCompany.getIn(['links', 'users']));
                }
            } else if (['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)) {
                props.setState(null);
            }
        }
    }, [props.match.params.identifier, props.selectedCompany]);
    // related data fetch without affecting state for related fields
    useEffect(() => {
        if (props.roles_loaded === false) {
            props.fetchItems(Role, 'roles', 'roles', null, null, {affect_state: false});
        }
    }, [props.roles_loaded]);

    return <div>
        {[
            'fetching_item_companies', 'fetching_item_my-companies',
            'fetching_item_users', `fetching_items_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`,
            'deleting_item_users', `deleting_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`
        ].includes(props.state)
            ? <Card>
            <CardHeader title={<FormattedMessage id='users.detail.title' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='user' />
                        </ActionButton>} />
            <CardContent>
                <LinearProgress />
            </CardContent>
        </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='users.detail.notfound.title' />}
                text={<FormattedMessage id='users.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <Row wrap>
            <Col width={props.editing_self ? '66.6667%' : '100%'}>
                <Card>
                    <CardHeader
                            title={<FormattedMessage id={props.location.pathname.replace('/partner', '').startsWith('/profile')
                                ? 'users.detail.my.title'
                                : 'users.detail.title'}
                            />}
                            subheader={<FormattedMessage id={props.location.pathname.replace('/partner', '').startsWith('/profile')
                                ? 'users.detail.my.subheader'
                                : 'users.detail.subheader'} />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='user' />
                            </ActionButton>} />
                    <CardContent>
                        <Form onSubmit={props.handleSubmit}>
                            <AutoCompleteOff />
                            <Field disabled={edit} name='username' fieldType='TextField' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.username'})}*`} />
                            <SalutationField required />
                            <SpaceDivider none />
                            <Field name='first_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.first_name'})}*`} />
                            <Field name='last_name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.last_name'})}*`} />
                            <SpaceDivider none />
                            <Field name='email' type='email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.email'})}*`} />
                            <Field name='phone_number' fieldType='TextField' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.phone'})}*`} />
                            <SpaceDivider none />
                            <Field name='mobile_number' fieldType='TextField' label={<FormattedMessage id='users.detail.form.fields.mobile' />} />
                            <DepartmentField required />
                            <SpaceDivider none />
                            <Field name='role' fieldType='Select' label={`${props.intl.formatMessage({id: 'users.detail.form.fields.role'})}*`}
                                   helperText={<FormattedMessage id='users.detail.form.fields.role.help' />}
                                   disabled={props.state !== null || !props.roles_loaded || !props.permissions.get('assign_roles')}
                                   loading={!props.roles_loaded}>
                                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                <MenuItem fake value={props.formValues && props.formValues.role} />
                                {sortedRoles.map((role, idx) =>
                                    <MenuItem key={idx} value={role.get(new Role().getUniqueIdentifier())}>
                                        {role.get('for_supergroup') ? <React.Fragment>
                                            <ListItemIcon className='icon'><AdminIcon color='primary' /></ListItemIcon>
                                            <ListItemText className='text'>{role.get('name')}</ListItemText>
                                        </React.Fragment> : role.get('name')}
                                    </MenuItem>
                                )}
                            </Field>
                            {props.user.isAdmin() && <Field
                                name='salesforce_id' fieldType='TextField' label={<FormattedMessage id='users.detail.form.fields.salesforce_id' />}
                                helperText={<FormattedMessage id='users.detail.form.fields.salesforce_id.help' />}
                            />}
                            <SpaceDivider double />
                            <CompanyField
                                value={!edit && props.selectedCompanyIdentifier === 'my' ? ''
                                    : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                                change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                                disabled={edit} all={false}
                                fetch_company={false}
                                my_companies={!props.user.isAdmin()}
                            />
                            {edit && <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='users.detail.form.fields.created_at' />}
                                                       format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />}
                            <SpaceDivider />
                            <Field name='subscription' fieldType='Checkbox' size='full'
                                   label={<FormattedMessage id='users.detail.form.fields.subscription' />} />
                        </Form>
                        {props.user.isAdmin() && <React.Fragment>
                            <SpaceDivider />
                            <SpaceDivider double />
                            <Typography variant='body1'><FormattedMessage id='users.detail.certificates.title' /></Typography>
                            <SpaceDivider />
                            <Form onSubmit={props.handleSubmit}>
                                <Field name='online_training_at' fieldType='DateField'
                                       label={<FormattedMessage id='users.detail.certificates.form.fields.online_training_at' />} />
                                <Field name='certified_engineer_at' fieldType='DateField'
                                       label={<FormattedMessage id='users.detail.certificates.form.fields.certified_engineer_at' />} />
                            </Form>
                            <SpaceDivider />
                        </React.Fragment>}
                    </CardContent>
                    {[
                        'saving_item_users', `saving_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`,
                        'saved_item_users', `saved_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`,
                        'failed_save_item_users', `failed_save_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`
                    ].includes(props.state)
                    ? <CardActionsLoader success={['saved_item_users', `saved_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`].includes(props.state)}
                                         failure={['failed_save_item_users', `failed_save_item_companyUsers-${!props.selectedCompany ? 'unknown' : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`].includes(props.state)}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                        <CardActions>
                            {!props.location.pathname.replace('/partner', '').startsWith('/profile') &&
                                <Button disabled={props.state !== null}
                                        onClick={() => props.history.push(props.user.isAdmin() ? '/customers' : `${props.portal ? '/partner' : ''}${props.selectedCompanyIdentifier === 'my' ? '/company#employees' : `/my-companies/${props.selectedCompanyIdentifier}#employees`}`)}>
                                    <CancelIcon />
                                    <FormattedMessage id='actions.cancel' />
                                </Button>
                            }
                            {(props.permission === 'RW' || props.editing_self) && <React.Fragment>
                                {edit && !props.editing_self &&
                                    <Button variant='contained' color='secondary' disabled={props.state !== null}
                                            onClick={() => setDeleteDialogOpen(true)}>
                                        <DeleteIcon />
                                        <FormattedMessage id='actions.delete' />
                                    </Button>
                                }
                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                        onClick={() => props.handleSubmit()}>
                                    <SaveIcon />
                                    <FormattedMessage id='actions.save' />
                                </Button>
                            </React.Fragment>}
                        </CardActions>
                    </ThemeProvider>}
                    {(edit && !props.editing_self && props.permission === 'RW') && <DeleteDialog
                        item={props.intl.formatMessage({id: 'users.detail.deletedialog.item'})}
                        items={props.intl.formatMessage({id: 'users.detail.deletedialog.items'})}
                        open={deleteDialogOpen}
                        handleClose={() => setDeleteDialogOpen(false)}
                        handleConfirm={() => {
                            setDeleteDialogOpen(false);
                            props.deleteItem(User, props.user.isAdmin() ? 'users' : `companyUsers-${props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`, props.item.getIn(['links', 'self']), props.item).then(() => {
                                // mark not updated lists for reload
                                props.markFiltered(User, this.props.user.isAdmin() ? `companyUsers-${props.selectedCompany.get(new Company().getUniqueIdentifier())}` : 'users');
                                props.history.push(props.user.isAdmin() ? '/customers' : `${props.portal ? '/partner' : ''}${props.selectedCompanyIdentifier === 'my' ? '/company#employees' : `/my-companies/${props.selectedCompanyIdentifier}#employees`}`);
                            });
                        }}
                    />}
                </Card>
            </Col>
            {props.editing_self && <Col width='33.3333%'>
                <PasswordChange item={props.item} />
            </Col>}
        </Row>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'username', data.username);
    validator.isNotNull(null, errors, 'salutation', data.salutation);
    validator.isNotNull(null, errors, 'first_name', data.first_name);
    validator.isNotNull(null, errors, 'last_name', data.last_name);
    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);
    validator.isNotNull(null, errors, 'phone_number', data.phone_number);
    validator.isNotNull(null, errors, 'department', data.department);
    validator.isNotNull(null, errors, 'role', data.role);

    if (data.online_training_at) {
        validator.isDateTime(null, errors, 'online_training_at', data.online_training_at, null);
    }
    if (data.certified_engineer_at) {
        validator.isDateTime(null, errors, 'certified_engineer_at', data.certified_engineer_at, null);
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'userForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new User().popUpFields(props.formValues, props.user.isAdmin());

        return dispatch(saveItem(User,
            props.user.isAdmin() ? 'users' : `companyUsers-${props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}`,
            props.item.getIn(['links', 'self']), rest_of_data, props.item, {add_mark_filtered: !props.user.isAdmin()}
        )).then(() => {
            // mark not updated lists for reload
            return dispatch(markFiltered(User, props.user.isAdmin() ? `companyUsers-${props.selectedCompany.get(new Company().getUniqueIdentifier())}` : 'users'));
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', auth_user.isAdmin() ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    const items = auth_user.isAdmin() ? state.shared.getIn(['items', 'users']) : state.shared.getIn(['items', `companyUsers-${selectedCompanyIdentifier === 'my' ? company.get(new Company().getUniqueIdentifier()) : selectedCompanyIdentifier}`]) || ImmutableList();
    const item = props.location.pathname.replace('/partner', '').startsWith('/profile') ? auth_user : items.find(el => el.get(new User().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        portal: props.location.pathname.startsWith('/partner'),
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        item: item,
        item_is_admin: item && auth_user.isAdmin() && auth_user.getIn(['links', 'company']) === item.getIn(['links', 'company']),
        initialValues: props.match.params.identifier || props.location.pathname.replace('/partner', '').startsWith('/profile')
            ? item
                ? item.toJS()
                : undefined
            : {},
        user: auth_user,
        company: company,
        editing_self: item ? item.get(new User().getUniqueIdentifier()) === auth_user.get(new User().getUniqueIdentifier()) : false,
        roles_loaded: state.shared.getIn(['loaded', 'roles']),
        roles_items: state.shared.getIn(['items', 'roles']),
        permissions: state.auth.get('permissions'),
        formValues: getFormValues('userForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem,
    markFiltered
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
