import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment/moment';
// components
import ThemeProvider from 'components/ThemeProvider';
import JSONPretty from 'lib/JSONPretty';
import {JSONFieldTheme} from 'components/core/ui/fields/JSONField';
// material-ui
import Table from 'components/core/ui/mui/Table';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import {alpha, lighten} from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
// icons
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import InActiveIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    contentHolder: {
        padding: '0',
        borderBottom: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        '&:last-of-type': {
            borderBottom: '0'
        }
    },
    tableWrapper: {
        height: '100%'
    },
    table: {
        height: '100%',
        borderTop: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        '&.second': {
            borderTop: '0'
        },
        '&.right': {
            borderLeft: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            '&.right': {
                borderTop: 'none',
                borderLeft: 'none'
            }
        }
    },
    // left cells working as headline
    headlineCell: {
        textAlign: 'right',
        // limit size
        width: '180px',
        // divider between (taken from mui tableCell)
        borderRight: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        borderLeft: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.88)}`,
        '&:first-of-type': {
            borderLeft: '0'
        },
        // prevent changeo of size with right cell expanding
        '&.top': {
            // manual v-align
            verticalAlign: 'top',
            paddingTop: '17px'
        }
    },
    // cell containing JSONPretty
    jsonCell: {
        // to sides
        padding: '0',
        // match json background
        background: JSONFieldTheme.background,
        // holder of json
        '& pre': {
            // extra breathing space
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            // allow wrap
            whiteSpace: 'break-spaces'
        }
    },
    xmlCell: {
        // to sides
        padding: '0',
        // match json background
        background: JSONFieldTheme.background,
        // match font with JSON preview
        fontFamily: '"Roboto Mono", monospace',
        lineHeight: '21px',
        color: JSONFieldTheme.key,
        '& pre': {
            // fix how it renders
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            // extra breathing space
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
        }
    },
    // created_at time
    time: {
        // display as 'monospace' to better readability and same size
        fontFamily: '"Roboto Mono", monospace',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: '1.1875em'
    }
}));

/**
 * Renders title for table
 */
function HeadlineCell(props) {
    const {intl_field, cls} = props;
    const classes = useStyles();

    return <TableCell className={`${classes.headlineCell}${cls ? ` ${cls}` : ''}`}>
        <Typography variant='subtitle2'>
            <FormattedMessage id={`provlogs.detail.form.fields.${intl_field}`} />{': '}
        </Typography>
    </TableCell>;
}

/**
 * Detail of Endpoint's ProvisioningLog
 */
export default function Detail(passed_props) {
    const {item, onClose = () => {}} = passed_props;
    const classes = useStyles();

    return <Dialog open={!!item} onClose={onClose} fullWidth maxWidth='lg'>
        <DialogTitle>
            <FormattedMessage id='provlogs.detail.title' />
        </DialogTitle>
        <DialogTitle className={classes.contentHolder}>
            <Table size={4} _classes={{root: classes.table, wrapper: classes.tableWrapper}}>
                <TableBody>
                    <TableRow>
                        <HeadlineCell intl_field='created_at' />
                        <TableCell>
                            {item && <span className={classes.time}>
                                    {Moment(item.get('created_at')).format('l')}<br />
                                    <strong>{Moment(item.get('created_at')).format('HH:mm:SS')}</strong>
                                </span>}
                        </TableCell>
                        <HeadlineCell intl_field='setting_server_only' />
                        <TableCell>
                            {item && (item.get('setting_server_only') ? <ActiveIcon /> : <InActiveIcon />)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <HeadlineCell intl_field='response_status' />
                        <TableCell>
                            {item && <ThemeProvider alt>
                                <Chip
                                    color={item.get('response_status') >= 400 ? 'secodnary' : item.get('response_status') < 300 ? 'primary' : undefined}
                                    label={item.get('response_status')}
                                />
                            </ThemeProvider>}
                        </TableCell>
                        <HeadlineCell intl_field='response_time' />
                        <TableCell>
                            {item && `${Math.round(item.get('response_time') / 1000)}ms`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <HeadlineCell intl_field='url' />
                        <TableCell urlView colSpan='3'>{item?.get('url')}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </DialogTitle>
        <DialogContent className={classes.contentHolder}>
            <Table size={4} _classes={{root: `${classes.table} second`, wrapper: classes.tableWrapper}}>
                <TableBody>
                    <TableRow>
                        <HeadlineCell intl_field='request_headers' cls='top' />
                        <TableCell className={classes.jsonCell}>
                            {item && <JSONPretty json={item.get('request_headers').toJS() || ''} theme={JSONFieldTheme} />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <HeadlineCell intl_field='response_headers' cls='top' />
                        <TableCell className={classes.jsonCell}>
                            {item && <JSONPretty json={item.get('response_headers').toJS() || ''} theme={JSONFieldTheme} />}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <HeadlineCell intl_field='response_body' cls='top' />
                        <TableCell className={classes.xmlCell}>
                            {item && <pre>{item.get('response_body')}</pre>}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </DialogContent>
    </Dialog>;
}
