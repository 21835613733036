import React, {useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {useFilter, usePaginatedItems} from 'lib/filters';
import {Company, Endpoint, Paginator, VoiceQuality} from 'lib/models';
import Moment from 'moment/moment';
import {makeStyles} from '@material-ui/core/styles';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItem, fetchItems, updatePaginator} from 'actions/shared';
// Components
import Detail from 'components/modules/voicequality/Detail';
import Filters from 'components/core/ui/Filters';
import Field, {CompanyField} from 'components/core/ui/Field';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import StickyTable from 'components/core/ui/StickyTable';
import {Link} from 'react-router-dom';
import ActionButton from 'components/core/ui/mui/ActionButton';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
// vectors
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    voiceQuality: {
        '&.perfect': {
            color: theme.gradients.blue.color1
        },
        '&.fair': {
            color: theme.gradients.green.color1
        },
        '&.annoying': {
            color: theme.gradients.gray.color2
        },
        '&.very_annoying': {
            color: theme.gradients.orange.color1
        },
        '&.impossible': {
            color: theme.gradients.red.color2
        },
        '&.unknown': {
            color: theme.palette.grey[500]
        }
    }
}));

/**
 * Renders Voice Quality Reports from backend - to view them
 */
export default function List(passed_props) {
    const {item} = passed_props;
    // styles
    const classes = useStyles();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const uuid = useMemo(() => new VoiceQuality().getUniqueIdentifier(), []);
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = searchParams.get('company')
            // ensure we don't create duplicate 'my' collection with UUID
            ? !pathname.startsWith('/all-') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                ? 'my'
                : searchParams.get('company')
            : pathname.startsWith('/all-') ? 'all' : 'my';
        const items_placement = `${new VoiceQuality().getPlacement()}${item ? `-${item.get(new Endpoint().getUniqueIdentifier())}` : company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            company: company,
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company'))
        };
    }, shallowEqual);
    // define table columns
    const tableHead = useMemo(() => [
        {label: <FormattedMessage id='voicequality.list.table.quality' />},
        {sortName: 'created_at', label: <FormattedMessage id='voicequality.list.table.created_at' />},
        {label: <FormattedMessage id='voicequality.list.table.duration' />},
        ...(item ? [] : [{label: <FormattedMessage id='voicequality.list.table.mac' />}])
    ], [item]);
    // local state
    const [detailDialogItem, setDetailDialogItem] = useState(null);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, uuid);
    // sorting & filtering (API)
    const [sortName, sortDirection] = ['created_at', 'asc'];
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, `${new Endpoint().getPlacement()}${pathname.startsWith('/all-') ? '-global' : ''}`, [...(item ? [] : ['company']), 'moscq'], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length) && (company_identifier === 'all' || props.selectedCompany)) {
            dispatch(fetchItems(VoiceQuality, items_placement, item ? item.getIn(['links', 'voice-quality-endpoint-history']) : company_identifier === 'all' ? 'voice-quality-history' : props.selectedCompany.getIn(['links', 'voice-quality-history']), null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), props.selectedCompany, item]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany && !item) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier, item]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='voicequality.list.title' />}
            subheader={<FormattedMessage id='voicequality.list.subheader' />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='voice-quality' />
            </ActionButton>}
        />
        <Filters disabled={props.state !== null}>
            {((pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && !item) && <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
                all={pathname.startsWith('/all-')} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            <Field label={<FormattedMessage id='voicequality.list.filter.moscq' />}
                   fieldType='NoReduxSelect' value={postponedFilters.moscq || filters.moscq || ''}
                   onChange={event => filterItems('moscq', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {[...Array(5)].map((_, idx) => {
                    const qnumber = idx + 1;
                    const quality = new VoiceQuality().getQuality(idx + 1);
                    return <MenuItem key={qnumber} value={qnumber} className={`${classes.voiceQuality} ${quality}`}>
                        <FormattedMessage id={`voicequality.data.${quality}`} />
                    </MenuItem>;
                }).reverse()}
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {sortName === item.sortName
                                    ? <TableSortLabel active direction={sortDirection}
                                                      disabled={true}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[`fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                    : !paginatedItems.size
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}>
                                <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'voicequality.list.table.empty'} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : <TableBody>
                        {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map(voice_quality => {
                            // beware, all not supported
                            const link = item ? null : `/phones/voice-quality/${voice_quality.get(uuid)}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`;
                            const onClick = () => props.state === null
                                ? item
                                    ? setDetailDialogItem(voice_quality)
                                    : history.push(link)
                                : {};
                            const quality = voice_quality.getQuality(voice_quality.getIn(['params', 'QualityEst_MOSCQ']));

                            return <TableRow key={voice_quality.get(uuid)} hover>
                                <TableCell link onClick={onClick} className={`${classes.voiceQuality} ${quality}`}>
                                    <FormattedMessage id={`voicequality.data.${quality}`} />
                                </TableCell>
                                <TableCell link onClick={onClick}>{Moment(voice_quality.get('created_at')).format('l, LT')}</TableCell>
                                <TableCell link onClick={onClick}>
                                    {(voice_quality.getIn(['params', 'Timestamps_START']) && voice_quality.getIn(['params', 'Timestamps_STOP'])) &&
                                        Moment.utc(Moment.duration(Moment(voice_quality.getIn(['params', 'Timestamps_STOP'])).diff(Moment(voice_quality.getIn(['params', 'Timestamps_START'])))).as('milliseconds')).format('HH:mm:ss')}
                                </TableCell>
                                {!item && <TableCell><Link className='hover-border' to={`/phones/${voice_quality.get('mac')}`}>{voice_quality.get('mac')}</Link></TableCell>}
                            </TableRow>;
                        })}
                    </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <Pagination model={VoiceQuality} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
        {item && <Dialog
            open={!!detailDialogItem} onClose={() => setDetailDialogItem(null)}
            fullWidth maxWidth='lg'>
            <Detail item={detailDialogItem || 'loading'} />
        </Dialog>}
    </Card>;
}
