import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Switzerland flag
 */
export default function ChFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <g fillRule='evenodd' strokeWidth='1pt'>
            <path fill='#d52b1e' d='M0 0h640v480H0z' />
            <g fill='#fff'>
                <path d='M170 195h300v90H170z' />
                <path d='M275 90h90v300h-90z' />
            </g>
        </g>
    </SvgIcon>;
}
