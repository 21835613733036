import {FormattedMessage, useIntl} from 'react-intl';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Setting, SettingGroup} from 'lib/models';
// Components
import MenuItem from 'components/core/ui/mui/MenuItem';
import ThemeProvider from 'components/ThemeProvider';
import Field from 'components/core/ui/Field';
import Button from 'components/core/ui/mui/Button';
import {indexedSettingStylesFunction} from 'components/core/ui/fields/IndexedSettingField';
// material-ui
import AddIcon from '@material-ui/icons/AddOutlined';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import DialogActions from '@material-ui/core/DialogActions';


const useStyles = makeStyles(indexedSettingStylesFunction);

function SB327SettingsField({settings, setting_groups}) {
    const intl = useIntl();
    const classes = useStyles();
    const state = useSelector(state => state.app.get('state'));

    // Field-level state
    const [sb327SettingsFieldOpen, setSb327SettingsFieldOpen] = useState(false);

    // Modal-level state
    const [selectedSettingGroups, setSelectedSettingGroups] = useState([]);
    const modalSettings = selectedSettingGroups.length ?
        settings?.filter(setting => selectedSettingGroups.includes(setting.get('group'))) :
        settings;
    const handleClose = () => setSb327SettingsFieldOpen(false);

    return <>
        <Field fieldType='Select'
               name='sb327_settings'
               label={`${intl.formatMessage({id: 'products.detail.behaviour.form.fields.sb327_settings'})}`}
               helperText={<FormattedMessage id='products.detail.behaviour.form.fields.sb327_settings.help'/>}
               IconComponent={AddIcon}
               open={sb327SettingsFieldOpen}
               multiple
               onOpen={() => setSb327SettingsFieldOpen(true)}
               MenuProps={{className: classes.selectMenu}}
               renderValue={settingUuids => <div className={classes.chipsHolder}>
                   {settingUuids.map(uuid => {
                       const setting = settings.filter(s => s.get('uuid') === uuid).get(0);

                       return <Chip disabled={true}
                                    key={uuid}
                                    classes={{root: classes.chip, label: classes.chipLabel}}
                                    label={setting?.get('param_name')}/>;
                   })}
               </div>}/>

        <Dialog open={sb327SettingsFieldOpen} onClose={handleClose} classes={{paper: classes.dialog}}>
            <DialogTitle>
                <FormattedMessage id='products.detail.behaviour.form.fields.sb327_settings'/>
            </DialogTitle>

            <DialogContent>
                <Field fieldType='NoReduxSelect'
                       label={`${intl.formatMessage({id: 'products.detail.behaviour.form.fields.sb327_settings.setting_groups'})}`}
                       helperText={<FormattedMessage
                           id='products.detail.behaviour.form.fields.sb327_settings.setting_groups.help'/>}
                       value={selectedSettingGroups}
                       onChange={e => setSelectedSettingGroups(e.target.value)}
                       multiple>
                    {setting_groups?.map((setting_group, idx) =>
                        <MenuItem key={idx} value={setting_group.get(new SettingGroup().getUniqueIdentifier())}>
                            {setting_group.get('name')}
                        </MenuItem>
                    )}
                </Field>

                <hr/>

                <Field name='sb327_settings' fieldType='Select'
                       label={`${intl.formatMessage({id: 'products.detail.behaviour.form.fields.sb327_settings'})}`}
                       helperText={<FormattedMessage id='products.detail.behaviour.form.fields.sb327_settings.help'/>}
                       displayEmpty={true}
                       multiple>
                    {modalSettings?.size ?
                        modalSettings.map((setting, idx) => <MenuItem key={idx} value={setting.get(new Setting().getUniqueIdentifier())}>
                            {setting.get('param_name')}
                        </MenuItem>) :
                        <MenuItem disabled><em><FormattedMessage id='filters.empty'/></em></MenuItem>
                    }
                </Field>
            </DialogContent>

            <ThemeProvider alt>
                <DialogActions>
                    <Button disabled={state !== null}
                            onClick={handleClose}>
                        <CloseIcon/>
                        <FormattedMessage id='actions.close'/>
                    </Button>
                </DialogActions>
            </ThemeProvider>
        </Dialog>
    </>;
}

export default SB327SettingsField;
