import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * DACH (Germany (D), Austria (A), Switzerland (CH)) flag
 */
export default function DACHFlag(props) {
    const {viewBox = '0 0 400 300', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <rect fill='#de1800' x='-25' width='450' height='300' />
        <rect fill='#000000' x='-25' width='225' height='100' />
        <rect fill='#ffce00' x='-25' y='200' width='225' height='100' />
        <rect fill='#ffffff' x='200' y='100' width='225' height='100' />
        <rect fill='#de1800' x='125' y='75' width='150' height='150' />
        <rect fill='#ffffff' x='185' y='100' width='30' height='100' />
        <rect fill='#ffffff' x='150' y='135' width='100' height='30' />
    </SvgIcon>;
}
