import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Route as RouterRoute} from 'react-router';


/**
 * Initialize Sentry for error logging
 */
export function initializeSentry(history) {
    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: process.env.NODE_ENV === 'development' ? 'development'
                : process.env.REACT_APP_STAGING ? 'staging' : 'production',
            tracesSampleRate: 1.0,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
                })
            ]
        });
        Sentry.setTag('application', 'frontend');
    }
}

// SentryRoute for react-router v5
const Route = process.env.REACT_APP_SENTRY_DSN
    ? Sentry.withSentryRouting(RouterRoute)
    : RouterRoute;
// Enhancer for redux
const sentryReduxEnhancer = process.env.REACT_APP_SENTRY_DSN
    ? Sentry.createReduxEnhancer({
        stateTransformer: state => {
            // make sure to not send possible sensitive data (e.g. passwords) in forms
            return {...state, form: null};
        }
    })
    : f => f;

export {Route, sentryReduxEnhancer};
