import React from 'react';
import {theme} from 'theme';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {setLocalState} from 'actions/shared';
// Components
import {RedocStandalone} from 'redoc';
// material-ui
import {alpha} from '@material-ui/core/styles/colorManipulator';


const useStyles = makeStyles(theme => ({
    documentationHolder: {
        // all holder
        '& .redoc-wrap': {},
        // SHARED, between all sections
        // --------------------------------------------------------------------
        // GET / POST / ...; both in Subsection MenuItems and URL preview
        '& .menu-content > div:last-child .operation-type, & .menu-content > div:first-child .operation-type, & .api-content .http-verb': {
            // position
            display: 'inline-block',
            verticalAlign: 'middle',
            // style (similar to activities action)
            textAlign: 'center',
            textTransform: 'uppercase',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: theme.shape.borderRadius,
            // proportion
            width: '55px', // fixed height for better readability
            height: '20px',
            padding: '0',
            lineHeight: '20px', // text v-align
            // keep proportion
            flexGrow: '0',
            flexShrink: '0'
        },
        // Toggle arrow icon; in Main Section MenuItem, URL preview and Responses expansion panels
        '& .menu-content div:last-child svg, & .api-content .http-verb ~ svg, & h2 ~ div:last-of-type > div > button svg': {
            // matching icon proportion
            height: '24px',
            width: '24px',
            // keep proportion
            flexShrink: '0',
            // matching animation smooth
            transition: theme.transitions.create('transform',
                {duration: theme.transitions.duration.shortest})
        },
        '& .menu-content div:last-child svg, & .api-content .http-verb ~ svg': {
            // arrow render
            '& polygon': {
                fill: theme.palette.action.active
            }
        },
        // SIDEBAR, menu on the left
        // --------------------------------------------------------------------
        '& .menu-content': {
            // desktop only
            [theme.breakpoints.up('md')]: {
                borderRight: `1px solid ${theme.palette.grey[300]}`,
                // include extra menu to header offset
                top: `${64 + 116}px !important`, // header + additional menu
                height: `calc(100vh - ${64 + 116}px) !important` // header + additional menu
            },
            // search
            '& > div:first-child': {
                // divider matching with extra menu items
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
                // search input
                '& > input': {
                    // style taken from MUI InputBase
                    ...theme.typography.body1,
                    color: theme.palette.text.primary,
                    position: 'relative',
                    cursor: 'text',
                    padding: `${8 - 2}px 0 ${8 - 3}px`, // changed bottom padding from '-1' because we use regular border
                    margin: '0',
                    border: 0,
                    // style taken from Field.js input
                    letterSpacing: 'normal',
                    lineHeight: theme.typography.body2.lineHeight,
                    // rest of style to simulate MUI Input
                    width: '100%',
                    // simulate MUI input border; it is not possible to use pseudo :before/:after elements in input
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    '&:hover': {
                        borderBottomColor: theme.palette.text.primary,
                        // increase size
                        borderBottomWidth: '2px',
                        // prevent content move
                        paddingBottom: `${8 - 4}px`
                    },
                    '&:focus, &:not(:placeholder-shown)': {
                        borderBottomColor: theme.palette.primary.main,
                        // increase size
                        borderBottomWidth: '2px',
                        // prevent content move
                        paddingBottom: `${8 - 4}px`
                    }
                },
                // clear X icon and magnifying glass icon
                '& > i, & > svg': {
                    display: 'none'
                },
                // search results
                '& > div:last-child': {
                    // absolute position
                    position: 'absolute',
                    zIndex: '100',
                    left: '0',
                    right: '0',
                    top: `${theme.spacing(2) + 34 + 1}px`, // space + size of input + border
                    bottom: '0', // cover whole menu
                    // style
                    background: theme.palette.grey[200],
                    // menu itself (it used div instead of UL)
                    '& > div': {
                        padding: `${theme.spacing(1)}px 0`,
                        // tweak style
                        margin: '0',
                        border: '0',
                        minHeight: '0',
                        maxHeight: '100%',
                        background: 'transparent'
                    }
                }
            },
            // menu
            '& > div:last-child': {
                overflowY: 'scroll !important', // always visible scroll to prevent content jump
                height: '100%', // keep scrollbar at 100% height
                // white-label
                '& > ul ~ div': {
                    display: 'none'
                },
                // menu itself
                '& > ul': {
                    padding: `${theme.spacing(1)}px 0`
                }
            },
            // shared style between menu and search search results
            '& > div:first-child > div:last-child, & > div:last-child': {
                // MenuItems
                '& > div > li > label, & > ul > li > label, & > ul > li > ul > li > label': {
                    // tweak proportions (44px height)
                    padding: `6px ${theme.spacing(4)}px`,
                    // match style with MUI MenuItem
                    ...theme.typography.body1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    transition: theme.transitions.create('background-color',
                        {duration: theme.transitions.duration.shortest}),
                    // text itself
                    '& span': {
                        // make sure to be 100% (- icons)
                        flexGrow: '1',
                        width: 'auto',
                        // match style with MUI ListText and space between text and icon
                        margin: `${theme.spacing(0.5)}px 0`
                    },
                    // hover style
                    '&:hover': {
                        // match MUI ListItem
                        backgroundColor: theme.palette.action.hover,
                        color: theme.palette.text['primary']
                    },
                    // selected (active) style
                    '&.active': {
                        background: 'transparent',
                        // keep hover background
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover
                        },
                        color: theme.palette.primary[500],
                        '& svg polygon': {
                            fill: theme.palette.primary[500]
                        }
                    },
                    // GET / POST / ...; style is also bellow shared with .http-verb
                    '& .operation-type': {
                        // overwrite '& span (text itself)' style above with style from shared
                        width: '55px', // fixed height for better readability
                        flexGrow: '0',
                        // space between icon and text
                        margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`
                    },
                    '& .operation-type ~ span': {
                        // reduce font (dense)
                        fontSize: theme.typography.body2.fontSize
                    }
                },
                // Main Section MenuItem (e.g. Provisioning Profile)
                '& > ul > li > label': {
                    paddingRight: `${theme.spacing(4) - 24}px`, // move icon into padding space
                    // text itself
                    '& span': {
                        // space between text and icon
                        marginRight: `${theme.spacing(3)}px !important`
                    }
                }
            }
        },
        // content holder
        '& .api-content': {
            // CONTENT, in the middle)
            // ----------------------------------------------------------------
            // api information at the top
            '& .api-info': {},
            // headings
            '& h1, & h2, & h3': {
                // link button
                '& > a': {
                    // move from left to right
                    float: 'right',
                    display: 'block',
                    width: 'auto',
                    // breating space and v-align
                    margin: `7px ${theme.spacing(1)}px 7px ${theme.spacing(1)}px`,
                    // link icon
                    '&:before': {
                        // position
                        display: 'block',
                        // always visible
                        visibility: 'visible',
                        opacity: '0.5',
                        // smooth for hover effect
                        transition: theme.transitions.create('opacity',
                            {duration: theme.transitions.duration.shortest}),
                        // tweak size to match icon proportion
                        height: '18px',
                        width: '18px'
                    },
                    // hover effect
                    '&:hover:before': {
                        opacity: '1' // full visible black
                    }
                }
            },
            // main section headings
            '& h1': {
                color: theme.palette.text['primary'],
                ...theme.typography.h4
            },
            // sub-section headings
            '& h2': {
                ...theme.typography.h5
            },
            '& h3': {
                // big 'Request samples' and 'Response samples'
                ...theme.typography.h3
            },
            '& .redoc-markdown h3, & h1 ~ h3, & h2 ~ h3, h1 ~ div h3, h2 ~ div h3': {
                ...theme.typography.h6
            },
            // URL preview (next to http-verb)
            '& .http-verb ~ span': {
                // space between http-verb and URL
                marginLeft: `${theme.spacing(1)}px`
            },
            // URL preview title of expansion panel
            '& h2 ~ div:first-of-type > button:first-child': {
                boxShadow: 'none'
            },
            // URL preview expansion panel
            '& h2 ~ div:first-of-type > div:last-child': {
                // matching animation smooth
                transition: theme.transitions.create('transform',
                    {duration: theme.transitions.duration.shortest}),
                // style
                boxShadow: 'none',
                borderRadius: '0',
                borderBottom: `1px solid ${theme.palette.divider}`,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
                background: theme.palette.grey[100] // same background as menu
            },
            // Responses expansion panels ("> 200 Create")
            '& h2 ~ div:last-of-type > div > button:first-child': {
                // common text style
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
                lineHeight: theme.typography.body2.lineHeight,
                // empty "-"
                '&:before': {
                    // match with svg
                    width: '24px'
                },
                // v-align arrow and text inside
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                // content
                '& svg, & span, & strong': {
                    // next to each other correctly
                    display: 'inline-block',
                    verticalAlign: 'middle'
                },
                // status code
                '& strong': {
                    // space between arrow and call
                    margin: `0 ${theme.spacing(1)}px`
                }
            },
            // code block in text
            '& code': {
                // inline (in text)
                display: 'inline-block',
                verticalAlign: 'baseline',
                // style
                background: theme.palette.grey[200],
                border: `1px solid ${theme.palette.grey[300]}`
            },
            // code examples
            '& pre': {
                // language example itself
                '& code': {
                    // remove 'code block in text' style
                    background: 'transparent',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '0',
                    display: 'block',
                    // tweak default style
                    textShadow: 'none',
                    // tweak proportion
                    padding: `${theme.spacing(1)}px 0`
                }
            },
            // Schemas (always in table)
            '& table': {
                // expand buttons
                '& button': {
                    '&, &:hover, &:focus': {
                        fontWeight: theme.typography.fontWeightRegular
                    }
                }
            },
            // Links in text
            '& p a, & .api-info a': {
                // basically 'hover-border' variant
                lineHeight: '1', // for better v-align
                display: 'inline-block',
                verticalAlign: 'baseline',
                color: theme.palette.primary[500],
                textDecoration: 'none',
                transition: theme.transitions.create('color',
                    {duration: theme.transitions.duration.short}),
                '&:after': {
                    content: '""',
                    display: 'block',
                    width: '0',
                    height: '1px',
                    background: 'transparent',
                    margin: '0 auto',
                    transition: theme.transitions.create(['background', 'width'],
                        {duration: theme.transitions.duration.short})
                },
                '&:hover': {
                    color: theme.palette.primary[700],
                    '&:after': {
                        background: theme.palette.primary[700],
                        width: '100%'
                    }
                }
            },
            // RIGHTPANEL, responses on the right
            // ----------------------------------------------------------------
            // '200' / '400' response status button tabs
            '& .react-tabs__tab-list': {
                // to sides
                margin: `0 -${theme.spacing(0.5)}px`,
                // tab (button) itself
                '& > li': {
                    // space between
                    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
                    // proportion
                    minWidth: '80px',
                    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
                    // style
                    borderRadius: '0',
                    border: '0',
                    color: theme.palette.primary[500],
                    background: theme.palette.common.white,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.pxToRem(12),
                    fontWeight: theme.typography.fontWeightBold,
                    // smooth transition for hover and active (selected) state
                    transition: theme.transitions.create(['background', 'color'],
                        {duration: theme.transitions.duration.shortest}),
                    // visual variants
                    '&.tab-success': {
                        color: theme.palette.green[500]
                    },
                    '&.tab-error': {
                        color: theme.palette.danger[500]
                    },
                    '&.tab-redirect': {
                        color: theme.palette.orange[500]
                    },
                    '&.tab-info': {
                        color: theme.palette.primary[500]
                    },
                    // hover effect
                    '&:hover': {
                        background: theme.palette.grey[300]
                    },
                    // selected
                    '&.react-tabs__tab--selected': {
                        color: theme.palette.common.white,
                        background: theme.palette.primary[500],
                        // visual variants
                        '&.tab-success': {
                            background: theme.palette.green[500]
                        },
                        '&.tab-error': {
                            background: theme.palette.danger[500]
                        },
                        '&.tab-redirect': {
                            background: theme.palette.orange[500]
                        },
                        '&.tab-info': {
                            background: theme.palette.primary[500]
                        }
                    }
                }
            },
            // holder of 'Copy', 'Expand all' and 'Collapse all'
            '& .react-tabs__tab-panel > div > div + div > div > div:first-child': {
                // to sides
                margin: `0 -${theme.spacing(0.5)}px`
            },
            // button itself 'Copy', 'Expand All' and 'Collapse all' or standalone 'Copy'
            '& .react-tabs__tab-panel > div > div + div > div > div:first-child button, & .react-tabs__tab-panel > div > div > button': {
                // style and proportion
                ...theme.typography.button,
                transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
                    duration: theme.transitions.duration.short
                }),
                // reduce size
                fontSize: theme.typography.pxToRem(13),
                padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
                // space between
                margin: `${theme.spacing(0.5)}px`,
                // hover effect
                '&:hover': {
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            },
            // Content type (application/json), Example (set, get, ...)
            '& .react-tabs__tab-panel > div > div:first-child, & .react-tabs__tab-panel > div > div:last-child > div:not(:last-child)': {
                // heading (make sure to exclude standalone 'Copy' button)
                '& > span:not(:last-child)': {
                    // no longer 'in the middle' of panel
                    position: 'static',
                    display: 'inline-block',
                    // proportion
                    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
                    // style
                    color: theme.palette.common.white,
                    // matching background
                    background: `${alpha(theme.palette.snomGrey[800], 0.4)}`
                },
                // type (application/json)
                '& > div': {
                    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
                },
                // example set/get/... dropdown
                '& .dropdown-selector-content': {
                    // correct position
                    left: '0',
                    marginTop: '0',
                    padding: '0',
                    // items itself
                    '& > div': {
                        background: theme.palette.common.white,
                        // matching animation smooth
                        transition: theme.transitions.create('background-color',
                            {duration: theme.transitions.duration.short}),
                        '&:hover': {
                            background: theme.palette.action.hover
                        },
                        '&.selected': {
                            background: theme.palette.action.selected
                        }
                    }
                }
            },
            // rest of content in tab
            '& .react-tabs__tab-panel': {
                // code blocks
                '& code': {
                    // override of default code from Application.js
                    background: 'transparent',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '0',
                    display: 'block'
                },
                // language examples
                '& pre': {
                    border: `1px solid ${theme.palette.divider}`,
                    padding: `${theme.spacing(1)}px`
                }
            }
        }
    }
}));

/**
 * Renders OpenAPI documentation
 */
export default function Documentation() {
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const {docSelected} = useSelector(state => ({
        docSelected: state.shared.getIn(['state', 'doc-selected'])
    }), shallowEqual);

    return <div className={classes.documentationHolder}>
        <RedocStandalone
            specUrl={docSelected.get('url')}
            onLoaded={(error) => dispatch(setLocalState(error ? 'error' : null, 'documentation'))}
            options={{
                theme: {
                    spacing: {
                        unit: theme.spacing(1),
                        sectionHorizontal: ({spacing}) => spacing.unit * 2,
                        sectionVertical: ({spacing}) => spacing.unit * 2
                    },
                    breakpoints: {
                        small: `${theme.breakpoints.values['md'] - 1}px`,
                        medium: `${theme.breakpoints.values['lg'] - 1}px`,
                        large: `${theme.breakpoints.values['xl'] - 1}px`
                    },
                    colors: {
                        primary: {
                            main: theme.palette.primary[500],
                            light: theme.palette.primary[300],
                            dark: theme.palette.primary[800],
                            contrastText: theme.palette.common.white
                        },
                        success: {
                            main: theme.palette.green[500],
                            light: theme.palette.green[300],
                            dark: theme.palette.green[800],
                            contrastText: theme.palette.common.white
                        },
                        error: {
                            main: theme.palette.danger[500],
                            light: theme.palette.danger[300],
                            dark: theme.palette.danger[800],
                            contrastText: theme.palette.common.white
                        },
                        gray: {
                            50: theme.palette.grey[50],
                            100: theme.palette.grey[100]
                        },
                        text: {
                            primary: theme.palette.text['primary'],
                            secondary: theme.palette.text['secondary']
                        },
                        border: {
                            dark: theme.palette.divider
                        },
                        http: {
                            get: theme.palette.primary[500],
                            post: theme.palette.green[500],
                            put: theme.palette.yellow[500],
                            options: theme.palette.primary[300],
                            patch: theme.palette.orange[500],
                            delete: theme.palette.danger[500],
                            basic: theme.palette.grey[500],
                            link: theme.palette.primary[500],
                            head: theme.palette.primary[500]
                        }
                    },
                    typography: {
                        fontFamily: theme.typography.fontFamily,
                        fontSize: `${theme.typography.fontSize}px`,
                        fontWeightRegular: theme.typography.fontWeightRegular,
                        fontWeightBold: theme.typography.fontWeightBold,
                        fontWeightLight: theme.typography.fontWeightLight,
                        lineHeight: theme.typography.body2.lineHeight,
                        headings: {
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: theme.typography.fontWeightRegular,
                            lineHeight: theme.typography.body2.lineHeight
                        },
                        code: {
                            fontFamily: '"Roboto Mono", monospace',
                            fontSize: `${theme.typography.fontSize}px`
                        }
                    },
                    sidebar: { // menu
                        width: `${280 + theme.spacing(1.5)}px`, // same as menu with + scrollbar size (12px guess)
                        backgroundColor: theme.palette.grey[100],
                        textColor: theme.palette.text['primary'],
                        activeTextColor: theme.palette.primary[500]
                    },
                    rightPanel: { // api examples
                        backgroundColor: theme.palette.snomGrey[800],
                        textColor: theme.palette.common.white
                    }
                },
                disableSearch: false,
                hideDownloadButton: true,
                hideLoading: true,
                expandSingleSchemaField: true,
                jsonSampleExpandLevel: 3,
                nativeScrollbars: true,
                pathInMiddlePanel: true,
                scrollYOffset: 64, // size of header
                suppressWarnings: true
            }}
        />
    </div>;
}
