import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {useLocation} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {Export, Company, User} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItem, fetchItems, markFiltered} from 'actions/shared';
// Components
import {SearchField, CompanyField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


/**
 * Renders CSV Exports from backend - to download them
 */
export default function List() {
    // router
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'model_name', label: <FormattedMessage id='exports.list.table.model_name' />},
        {sortName: 'created_at', label: <FormattedMessage id='exports.list.table.created_at' />},
        {sortName: 'parent_company_name', label: <FormattedMessage id='exports.list.table.parent_company' />},
        {sortName: 'user', label: <FormattedMessage id='exports.list.table.user' />},
        {sortName: 'size', label: <FormattedMessage id='exports.list.table.file_size' />, numeric: true}
    ];
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        // ensure we don't create duplicate 'my' collection with UUID
        // const company_identifier = searchParams.get('company') && searchParams.get('company') !== company.get(new Company().getUniqueIdentifier())
        //     ? searchParams.get('company')
        //     : 'my';
        const company_identifier = 'my';
        const items_placement = `${new Export().getPlacement()}${company_identifier !== 'my' ? `-${company_identifier}` : ''}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            user: auth_user,
            company: company,
            selectedCompany: company_identifier === 'my' ? company : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            permissions: state.auth.get('permissions')
        };
    }, shallowEqual);
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, new Export().getPlacement(), tableHead.map(item => item.sortName), 'created_at', 'asc');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new Export().getPlacement(), ['company', 'search'], ['model_name', 'user'], {
            company: () => true
        });

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.selectedCompany && (props.loaded === false || props.outdated === true)) {
            dispatch(fetchItems(Export, items_placement,
                props.selectedCompany.getIn(['links', 'exports']), null, null, {affect_state: !props.loaded}
            ));
        }
    }, [props.loaded, props.outdated, props.selectedCompany, company_identifier]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (!props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'exports.list.global.title'
                : 'exports.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'exports.list.global.subheader'
                : 'exports.list.subheader'} />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='exports' />
            </ActionButton>}
        />
        <Filters refreshDisabled={!props.loaded || props.state !== null}
                 refresh={() => dispatch(markFiltered(Export, items_placement))}>
            {false && (props.permissions.get('company') !== 'X' && (pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller')) && <CompanyField
                value={filters.company || ''}
                change={value => filterItems('company', value)}
                all={false} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            <SearchField label={<FormattedMessage id='exports.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`
                ].includes(props.state) || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'exports.list.table.empty'}
                                              values={{button_text: <FormattedMessage id='actions.request_csv' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((item, idx) => {
                        // get link to action creator - User
                        const user_link = item.get('user') && props.permissions.get('user') !== 'X'
                            ? item.get('user') === props.user.get(new User().getUniqueIdentifier())
                                ? '/profile'
                                : `/customers/${item.get('user')}${company_identifier !== 'my' ? `?company=${company_identifier}` : ''}`
                            : '';

                        // get Company details
                        const parent_company_link = item.get('parent_company') && props.permissions.get('company') !== 'X'
                            ? item.get('parent_company') === props.company.get(new Company().getUniqueIdentifier())
                                ? '/company'
                                : `/${props.user.isAdmin() ? '' : 'my-'}companies/${item.get('parent_company')}`
                            : '';

                        // prepare file download props for tableCells which doesn't have related link inside
                        const downloadLinkProps = {
                            link: true,
                            onClick: () => {
                                // simulate link download to prevent pop-up block with window.open
                                const link = document.createElement('a');
                                link.href = item.get('url');
                                link.target = '_blank';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }
                        };

                        return <TableRow key={idx} hover>
                            <TableCell {...downloadLinkProps} name>
                                <ModelIcon className='icon' model={item.getCorrectModelName()} />
                                <span className='text'><FormattedMessage id={`exports.export.model.${item.getCorrectModelName()}`} /></span>
                            </TableCell>
                            <TableCell {...downloadLinkProps}>{Moment(item.get('created_at')).format('l, LT')}</TableCell>
                            {parent_company_link
                                ? <TableCell><Link className='hover-border' to={parent_company_link}>{item.get('parent_company_name')}</Link></TableCell>
                                : <TableCell {...downloadLinkProps}>{item.get('parent_company')
                                    ? (item.get('parent_company_name') || '')
                                    : <FormattedMessage id='exports.list.table.parent_company.all' />}</TableCell>
                            }
                            {user_link
                                ? <TableCell><Link className='hover-border' to={user_link}>{item.get('user')}</Link></TableCell>
                                : <TableCell {...downloadLinkProps}>{item.get('user') || ''}</TableCell>
                            }
                            <TableCell {...downloadLinkProps} numeric={true}>{item.getSize()}</TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>} />
    </Card>;
}
