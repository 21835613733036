import React, {useState, useMemo} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
// Actions
import {toggleMenu} from 'actions/app';
import {logout} from 'actions/auth';
// Components
import Notifications from 'components/core/ui/Notifications';
import Development from 'components/core/ui/Development';
import AppHeadline from 'components/core/ui/AppHeadline';
import LanguageSwitcherMenu from 'components/core/ui/LanguageSwitcherMenu';
import {Link} from 'react-router-dom';
// material-ui
import Button from 'components/core/ui/mui/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/core/ui/mui/Tooltip';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles(theme => ({
    headerSpace: {
        height: '64px'
    },
    appbar: {
        background: theme.palette.common.white,
        boxShadow: theme.shadows[4],
        height: '64px',
        whiteSpace: 'nowrap',
        transform: 'none',
        position: 'relative',
        // moving header in Documentation
        '&.mui-fixed': {
            top: '0',
            right: '0',
            left: '0',
            position: 'fixed',
            '& $logoHolder': {
                top: '0'
            },
            '& $actionButtonsHolder.notlogged': {
                top: '0'
            }
        }
    },
    toolbar: {
        minHeight: '64px', // disable lowering size of toolbar in responsive
        position: 'static'
    },
    // mobile button
    menuButton: {
        marginLeft: `-${theme.spacing(1.5)}px`,
        marginRight: `${theme.spacing(1.5)}px`,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    // holder of Title and Logo
    logoHolder: {
        display: 'block',
        flexGrow: '1', // full size (menu on the left, rest on the right)
        '&.logged': {
            // hide logo on mobile when we have menu button (logged user only)
            '& h1': {
                [theme.breakpoints.down('xs')]: {
                    display: 'none'
                }
            }
        }
    },
    // holder of actions buttons
    actionButtonsHolder: {
        // fix position (same as with menuButton)
        marginRight: '-12px'
    },
    // actions buttons like logout
    actionButton: {
        marginLeft: `${theme.spacing(0.5)}px` // space between
    }
}));

/**
 * Application Header
 */
export default function Header(passed_props) {
    const classes = useStyles();
    // react router
    const history = useHistory();
    const {pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        portal: pathname.startsWith('/partner'),
        state: state.app.get('state'),
        apiState: state.api.get('state'),
        authState: state.auth.get('state'),
        docState: state.shared.getIn(['state', 'documentation']),
        openMobileMenu: state.app.get('openMobileMenu'),
        openNotifications: state.app.get('openNotifications'),
        user: state.auth.get('user')
    }), shallowEqual);
    // local state
    const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
    const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
    const fixed = useMemo(() => {
        return !!(pathname.startsWith('/documentation') && !['loading', 'error'].includes(props.docState) && props.state !== 'fetching_items_doc-versions') || props.openNotifications;
    }, [pathname, props.docState, props.state === 'fetching_items_doc-versions', props.openNotifications]);
    // breakpoint
    const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const ProfileButton = mobile ? IconButton : Button;

    return <React.Fragment>
        {fixed && <div className={classes.headerSpace} />}
        <AppBar position='static' color='default' className={`${classes.appbar}${fixed ? ' mui-fixed' : ' '}`}>
            <Toolbar className={classes.toolbar}>
                {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING) && <Development />}
                {props.authState === 'logged' && <IconButton
                    className={classes.menuButton}
                    onClick={() => dispatch(toggleMenu(!props.openMobileMenu))}>
                    <MenuIcon />
                </IconButton>}
                <div className={`${classes.logoHolder}${props.authState === 'logged' ? ' logged' : ' notlogged'}`}>
                    <Link to={props.authState === 'logged'
                        ? `${(props.portal && passed_props.portalAccess) || !passed_props.srapsAccess ? '/partner' : ''}/home`
                        : `${props.portal ? '/partner' : ''}/`}>
                        <AppHeadline id='header' />
                    </Link>
                </div>
                <div className={classes.actionButtonsHolder}>
                    {props.authState === 'logged' && <Tooltip title={<FormattedMessage id='header.profile' />} disabled={props.state !== null}>
                        <ProfileButton className={classes.actionButton} color='primary'
                                       disabled={props.state !== null}
                                       onClick={() => history.push(`${props.portal ? '/partner' : ''}/profile`)}>
                            <ModelIcon model='user' />
                            {!mobile && <span>{props.user.get('username')}</span>}
                        </ProfileButton>
                    </Tooltip>}
                    {(props.authState === 'logged' && ['ready', 'payment_required'].includes(props.apiState)) && <Notifications
                        className={classes.actionButton}
                    />}
                    <Tooltip title={<FormattedMessage id='header.language' />} disabled={props.state !== null}>
                        <IconButton className={classes.actionButton} color='primary'
                                    disabled={props.state !== null}
                                    onClick={(event) => {
                                        setLanguageMenuAnchor(event.currentTarget);
                                        setLanguageMenuOpen(!languageMenuOpen);
                                    }}>
                            <LanguageIcon />
                        </IconButton>
                    </Tooltip>
                    <LanguageSwitcherMenu open={languageMenuOpen} anchorEl={languageMenuAnchor}
                                          onClose={() => setLanguageMenuOpen(false)} />
                    {(props.authState === 'logged' && ['ready', 'payment_required'].includes(props.apiState)) && <Tooltip
                        title={<FormattedMessage id='actions.logout' />} disabled={props.state !== null}>
                        <IconButton className={classes.actionButton} color='primary'
                                    disabled={props.state !== null}
                                    onClick={() => dispatch(logout(props.portal))}>
                            <LogoutIcon />
                        </IconButton>
                    </Tooltip>}
                </div>
            </Toolbar>
        </AppBar>
    </React.Fragment>;
}
