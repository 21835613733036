import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {useIntl, FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {Claim, Paginator, SubmissionProduct, SubmissionProductVariant} from 'lib/models';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
import {useLocalSort, useFilter, usePaginatedItems} from 'lib/filters';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import RequestCSV from 'components/core/ui/RequestCSV';
import {StatusIcon} from 'components/core/ui/fields/ClaimStatusField';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import Field, {CompanyField, ClaimStatusField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Renders Claims from backend, admin only! - to view and manage them
 */
export default function List() {
    const intl = useIntl();
    // redux store
    const dispatch = useDispatch();
    const items_placement = `${new Claim().getPlacement()}-global`;
    const props = useSelector(state => {
        return {
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
            submission_products_items: state.shared.getIn(['items', 'submission-products'])
        };
    }, shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'created_at', label: <FormattedMessage id='claims.list.table.created_at' />},
        {sortName: 'status', label: <FormattedMessage id='claims.list.table.status' />},
        {sortName: 'company', label: <FormattedMessage id='claims.list.table.company' />},
        {sortName: 'product', label: <FormattedMessage id='claims.list.table.product' />}
    ];
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new Claim().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = ['created_at', 'asc'];
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, items_placement, ['company', 'status', 'product'], props.state !== null);
    // related sorting
    const variants = useMemo(() => {
        return props.submission_products_items.flatMap(sproduct => sproduct.get('variants').map(variant => variant.get('active')
            ? new ImmutableMap({...variant.toJS(), submission_product: sproduct}) : null)).filter(variant => variant);
    }, [props.submission_products_items]);
    const [sortedVariants] = useLocalSort(variants, !props.submission_products_loaded);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(Claim, items_placement, 'free-phone-orders',
                null, !Object.keys(filters).length ? null : filters, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters)]);
    // related data fetch without affecting state, SubmissionsProducts and Companies for filters
    useEffect(() => {
        if (props.submission_products_loaded === false) {
            dispatch(fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false}));
        }
    }, [props.submission_products_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='claims.list.title' />}
            subheader={<FormattedMessage id='claims.list.subheader' />}
            action={<RequestCSV placement={items_placement} url='free-phone-orders-export' />}
        />
        <Filters disabled={props.state !== null}>
            <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => filterItems('company', value)} />
            <ClaimStatusField
                fieldType='NoReduxSelect' value={postponedFilters.status || filters.status || ''}
                onChange={event => filterItems('status', event.target.value)} />
            <Field label={<FormattedMessage id='claims.list.filter.product' />}
                   fieldType='NoReduxSelect' value={postponedFilters.product || filters.product || ''}
                   onChange={event => filterItems('product', event.target.value)}
                   loading={!props.submission_products_loaded}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {sortedVariants.map((variant, idx) =>
                    <MenuItem key={idx} value={variant.get(new SubmissionProductVariant().getUniqueIdentifier())}>{variant.get('name')}</MenuItem>
                )}
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {sortName === item.sortName
                                    ? <TableSortLabel active direction={sortDirection}
                                                      disabled={true}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[`fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                    : !paginatedItems.size
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'claims.list.table.empty'} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((claim, idx) => {
                        const link = `/all-claims/${claim.get(new Claim().getUniqueIdentifier())}`;
                        const onClick = () => props.state === null ? history.push(link) : {};
                        const variant = sortedVariants.find(el => el.get(new SubmissionProductVariant().getUniqueIdentifier()) === claim.get('product'));

                        return <TableRow key={idx} hover>
                            <TableCell link onClick={onClick}>{Moment(claim.get('created_at')).format('l, LT')}</TableCell>
                            <TableCell name link onClick={onClick}>
                                <StatusIcon identifier={claim.get('status')} className='icon' />
                                <span className='text'>{intl.formatMessage({id: `claimstatusfield.choice.${claim.get('status')}`})}</span>
                            </TableCell>
                            <TableCell>
                                <Link className='hover-border' to={`/companies/${claim.get('company')}`}>
                                    {claim.get('company_name')}
                                </Link>
                            </TableCell>
                            <TableCell link={!variant} onClick={variant ? undefined : () => onClick()}>
                                {!props.submission_products_loaded
                                    ? <CircularProgress size={36} />
                                    : variant && <Link className='hover-border' to={`/submission-products/${variant.get('submission_product').get(new SubmissionProduct().getUniqueIdentifier())}`}>
                                    {variant.get('name')}
                                </Link>}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <Pagination model={Claim} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
    </Card>;
}
