import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {SalesCluster, SubmissionProduct} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import PartnerLevelIndicator from 'components/core/ui/PartnerLevelIndicator';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableFooter from 'components/core/ui/mui/TableFooter';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import RebateIcon from '@material-ui/icons/AccountBalanceWalletOutlined';


const useStyles = makeStyles(theme => ({
    cardSubheader: {
        position: 'relative'
    }
}));

/**
 * Render card Rebate calculation of Submission
 */
export default function Rebate(passed_props) {
    const {data, total_rebate, selectedCompany, editable} = passed_props;
    // style
    const classes = useStyles();
    // local state
    const tableHead = [
        {label: <FormattedMessage id='submissions.detail.rebate.table.product_code' />},
        {label: <FormattedMessage id='submissions.detail.rebate.table.quantity' />, numeric: true},
        {label: <FormattedMessage id='submissions.detail.rebate.table.rebate' />, numeric: true}
    ];
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => ({
        submission_products_loaded: state.shared.getIn(['loaded', 'submission-products']),
        submission_products_items: state.shared.getIn(['items', 'submission-products']),
        sales_clusters: state.shared.getIn(['items', 'sales_clusters'])
    }), shallowEqual);

    /**
     * Fetch related items without affecting state
     */
    useEffect(() => {
        if (props.submission_products_loaded === false) {
            dispatch(fetchItems(SubmissionProduct, 'submission-products', 'submission-products', null, null, {affect_state: false}));
        }
    }, [props.submission_products_loaded]);
    useEffect(() => {
        if (!props.sales_clusters) {
            dispatch(fetchItems(SalesCluster, 'sales_clusters', 'sales-clusters', {affect_state: false}));
        }
    }, [props.sales_clusters]);

    let total_rebate_calc = 0;
    return <Card>
        <CardHeader title={<FormattedMessage id='submissions.detail.rebate.title' />}
                    subheader={<React.Fragment>
                        <FormattedMessage id='submissions.detail.rebate.subheader' />
                        {selectedCompany && selectedCompany.get('partner_level') && <PartnerLevelIndicator partnerLevel={selectedCompany.get('partner_level')} />}
                    </React.Fragment>}
                    _classes={{subhead: classes.cardSubheader}}
                    action={<ActionButton iconButton disabled>
                        <RebateIcon />
                    </ActionButton>} />
        <StickyTable
            header={<Table size={1}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>{item.label}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={1}>
                {!props.submission_products_loaded || !selectedCompany
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}>
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : !data || !data.length
                    ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={tableHead.length}>
                                <FormattedMessage id='submissions.detail.rebate.table.empty' />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    : <TableBody>
                        {data.map((data, idx) => {
                            // process quantity
                            const quantity = isNaN(data.quantity) || !data.quantity || data.quantity < 0 ? 0 : data.quantity;
                            // get variant
                            let selectedVariant = null;
                            props.submission_products_items.map(submission_product => submission_product.get('variants').forEach(variant => {
                                if (variant.get(new SubmissionProduct().getUniqueIdentifier()) === data.product_code) {
                                    selectedVariant = variant;
                                }
                            }));
                            // get rebate & calculate
                            const sales_cluster_name = props.sales_clusters?.find(cluster => cluster.get('countries').includes(selectedCompany.get('country')))?.get('name');
                            const rebate = !isNaN(data.product_rebate) && !editable
                                ? data.product_rebate
                                : selectedVariant
                                    ? sales_cluster_name && !isNaN(selectedVariant.getIn(['rebates', sales_cluster_name, selectedCompany.get('partner_level')]))
                                        ? selectedVariant.getIn(['rebates', sales_cluster_name, selectedCompany.get('partner_level')])
                                        : !isNaN(selectedVariant.getIn(['rebates', 'default', selectedCompany.get('partner_level')]))
                                            ? selectedVariant.getIn(['rebates', 'default', selectedCompany.get('partner_level')])
                                            : 0
                                    : 0;
                            const rebate_calc = quantity * rebate;
                            // add to total
                            total_rebate_calc += rebate_calc;

                            return <TableRow key={idx}>
                                <TableCell>{selectedVariant
                                    ? selectedVariant.get('name')
                                    : <em><FormattedMessage id='submissions.detail.rebate.table.product_code.empty' /></em>}
                                </TableCell>
                                <TableCell numeric>{`x${quantity}`}</TableCell>
                                <TableCell numeric>{`${rebate_calc} €`}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                }
            </Table>}
            footer={<Table size={1}>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={tableHead.length - 1}>
                            <FormattedMessage id='submissions.detail.rebate.table.total' />
                        </TableCell>
                        <TableCell numeric>
                            <Typography variant='h6' color='primary'>
                                {`${!isNaN(total_rebate) && !editable
                                    ? total_rebate : total_rebate_calc} €`}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>}
        />
    </Card>;
}
