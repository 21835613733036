import React, {useEffect} from 'react';
import {connect, useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues, isInvalid} from 'redux-form';
import {Submission} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {saveItems} from 'actions/shared';
// Components
import FiltersExtraForm from 'components/core/ui/FiltersExtraForm';
import {FieldWithIconHolder, FieldIcon, SubmissionStatusField} from 'components/core/ui/Field';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import SaveIcon from '@material-ui/icons/SaveOutlined';


/**
 * Mass Update 'status' of selected submissions
 */
function StatusMassUpdate(props) {
    // redux store
    const dispatch = useDispatch();

    /**
     * Ensure correct validate when selected items changes
     */
    useEffect(() => {
        props.change('_validationHack', Date.now());
    }, [props.items.size]);

    return <FiltersExtraForm onSubmit={props.handleSubmit}>
        <FieldWithIconHolder>
            <SubmissionStatusField
                excludePermission name='status' disabled={props.state !== null}
                label={<FormattedMessage id='submissions.massupdate.form.fields.status' />}
                helperText={<FormattedMessage id='submissions.massupdate.form.fields.status.help' />}
                withIcon iconWidth={160} />
            <FieldIcon button iconWidth={160}>
                <ActionButton
                    disabled={(props.state !== null && ![
                        `saving_items_${props.placement}`, `saved_items_${props.placement}`, `failed_save_item_${props.placement}`
                    ].includes(props.state)) || !props.formValues?.status || !props.items.size || props.isInvalid}
                    onClick={() => props.handleSubmit()}
                    variant='contained' color='primary' type='submit'
                    loading={[
                        `saving_items_${props.placement}`, `saved_items_${props.placement}`, `failed_save_item_${props.placement}`
                    ].includes(props.state)}
                    success={props.state === `saved_items_${props.placement}`}
                    failure={props.state === `failed_save_item_${props.placement}`}
                    postAnimation={success => {
                        dispatch(setState(null));
                        props.postAnimation(success);
                    }}>
                    <SaveIcon />
                    <FormattedMessage id='submissions.massupdate.form.submit' />
                </ActionButton>
            </FieldIcon>
        </FieldWithIconHolder>
    </FiltersExtraForm>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    // check if all items are editable
    if (data.status && props.items.size && props.items.some(item => !['open', 'pending'].includes(item.get('status')) && item.get('salesforce_id'))) {
        errors['status'] = props.intl.formatMessage({id: 'submissions.massupdate.form.error.invalid'});
    }

    return errors;
};

const StatusMassUpdateForm = reduxForm({
    form: 'submissionMassUpdate',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        if (!props.formValues || !props.formValues.status || !props.items.size) {
            return;
        }
        // pop-up some values which we don't want to send
        let {_validationHack, ...rest_of_data} = props.formValues;
        return dispatch(saveItems(Submission, props.placement, rest_of_data, props.items,
            {popUpFieldsAttrs: [props.user.isAdmin()], companyRelated: true, containsCompany: true,
                update_method: 'put', add_mark_filtered: false}
        )).then(() => {
            props.postSubmit();
        });
    }
})(StatusMassUpdate);

const ConnectedStatusMassUpdate = connect((state) => ({
    state: state.app.get('state'),
    user: state.auth.get('user'),
    formValues: getFormValues('submissionMassUpdate')(state),
    isInvalid: isInvalid('submissionMassUpdate')(state)
}), (dispatch) => bindActionCreators({
    saveItems
}, dispatch))(StatusMassUpdateForm);

export default injectIntl(ConnectedStatusMassUpdate);
