import React, {useCallback, useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import {FieldArray, reduxForm, SubmissionError} from 'redux-form';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {makeStyles} from '@material-ui/styles';
import validator from 'lib/valitator';
import {AssetFile, AssetFolder} from 'lib/models';
// actions
import {fetchItems, saveItem, uploadFiles} from 'actions/shared';
import {setState} from 'actions/app';
// components
import Field, {DropFile} from 'components/core/ui/Field';
import Form from 'components/core/ui/Form';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import AssetFolderSelect from 'components/core/ui/fields/AssetFolderSelectField';
// material ui
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import CloudUpload from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import ImageIcon from '@material-ui/icons/Image';
import CloseIcon from '@material-ui/icons/Close';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';


const useFormStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        margin: 0,
        justifyContent: 'flex-start'
    },
    dropzoneContainer: {
        width: '100%',
        margin: 0
    },
    contentHolder: {
        overflow: 'hidden',
        borderRadius: `${theme.spacing(1.5)}px`,
        borderStyle: 'dashed'
    },
    dropzoneContent: {
        flexDirection: 'column',
        fontSize: theme.typography.h6.fontSize,
        '& .MuiSvgIcon-root': {
            fontSize: `${theme.spacing(8)}px`
        }
    },
    fileList: {
        width: '100%',
        '& > .MuiListItem-container': {
            backgroundColor: theme.palette.snomGrey[100],
            borderRadius: `${theme.spacing(0.7)}px`,
            margin: `${theme.spacing(0.5)}px 0`,
            '& .MuiListItem-root': {
                paddingLeft: `${theme.spacing(2)}px`
            },
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: `${theme.spacing(1)}px`
            },
            '& .MuiListItemIcon-root': {
                minWidth: 0,
                marginRight: `${theme.spacing(2)}px`
            }
        }
    }
}));


function DetailForm(props) {
    const classes = useFormStyles();

    const formState = useSelector((state) => {
        const form = state.form[props.form];
        return {
            name: {value: form?.values?.name},
            file: {visited: form?.fields?.file?.visited, error: form?.syncErrors?.file || form?.submitErrors?.file},
            files: {value: form?.values?.files, errors: form?.syncErrors || form?.submitErrors || {}}
        };
    }, shallowEqual);
    const [fileTouched, setFileTouched] = useState(false);

    const onSubmit = () => {
        if (formState.file.error) {
            setFileTouched(true);
        } else {
            props.handleSubmit();
        }
    };

    return <React.Fragment>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id={`assets.list.${props.edit || 'upload'}_file.dialog.subtitle`} values={{fileName: props.folder_item?.get('name')}} />
            </DialogContentText>
            <Form className={classes.form} onSubmit={onSubmit}>
                {props.edit !== 'move' && <Field name='name' fieldType='TextField'
                    label={props.intl.formatMessage({id: 'assets.list.upload_file.dialog.form.name.label'})} />}
                    {props.edit === 'move' && <AssetFolderSelect name='parent_uuid' selectWithRoot={false} />}
                    {!props.edit && (
                        <React.Fragment>
                            <DropFile disablePreview className={classes.dropzoneContainer} placeholderIcon={<CloudUpload />}
                                _classes={{content: classes.dropzoneContent, contentHolder: classes.contentHolder}}
                                placeholderText={props.intl.formatMessage({id: 'assets.list.upload_file.dialog.form.file.placeholder'})}
                                initialFile={{}} selectedFile={{}}
                                helperText={fileTouched && formState.file.error}
                                error={fileTouched && Boolean(formState.file.error)}
                                onChange={(file) => {
                                    if (file) {
                                        const newValue = {
                                            name: formState.name.value || file.name,
                                            data: file
                                        };
                                        props.change('files', formState.files.value ? [...formState.files.value, newValue] : [newValue]);
                                        props.change('name', '');
                                    }
                                    props.clearSubmitErrors();
                                    setFileTouched(true);
                                }}
                            />
                            <FieldArray name='files' component={({fields}) => {
                                return <List className={classes.fileList} disablePadding>
                                    {fields.map((fieldName, index) => {
                                        return <React.Fragment key={fieldName}>
                                            <ListItem disableGutters>
                                                <ListItemIcon>
                                                    <ImageIcon />
                                                </ListItemIcon>
                                                <ListItemText disableTypography>
                                                    <Typography noWrap>
                                                        {fields.get(index).name}
                                                        {props.submitSucceeded && <CheckIcon color='primary' />}
                                                        {formState.files.errors[fieldName] && (<CloseIcon color='error' />)}
                                                    </Typography>
                                                </ListItemText>
                                                <ListItemSecondaryAction>
                                                    <IconButton size='small' edge='end' onClick={() => {
                                                        fields.remove(index);
                                                        props.clearSubmitErrors();
                                                    }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            {formState.files.errors[fieldName] && <FormHelperText error>{formState.files.errors[fieldName]}</FormHelperText>}
                                        </React.Fragment>;
                                    })}
                                </List>;
                            }} />
                        </React.Fragment>
                    )}
            </Form>
        </DialogContent>
        {['saving_item_asset_files', 'failed_save_item_asset_files', 'saved_item_asset_files',
        'fetching_items_asset_files', 'failed_upload_asset_files', 'uploading_asset_files']
            .some((state) => props.state?.match(state))  || (props.edit && !props.folder_item)
            ? <CardActionsLoader dialogActions
                    failure={props.state?.match(/(failed_save_item_asset_files|failed_upload_asset_files)/)}
                    success={props.state?.match('saved_item_asset_files')}
                    postAnimation={(success) => !success ? props.state && props.setState(null) : {}} />
            : <DialogActions>
                <Button onClick={() => props.onClose?.()}  onMouseDown={(e) => e.preventDefault()} startIcon={<CancelIcon />}>
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button variant='contained' color='primary' type='submit' onClick={onSubmit} startIcon={props.edit ? <SaveIcon /> : <CloudUpload />}>
                    <FormattedMessage id={props.edit ? 'actions.save' : 'actions.upload'} />
                </Button>
            </DialogActions>}
    </React.Fragment>;
}

const validate = (data, props) => {
    const {edit} = props;
    const errors = {};

    if (edit) {
        edit === 'move' && validator.isNotNull(null, errors, 'parent_uuid', data.parent_uuid);
    } else if (!data.files?.length) {
        validator.isNotNull(null, errors, 'file', data.file);
    }

    return errors;
};

const DetailReduxForm = reduxForm({
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate,
    onSubmit: (values, dispatch, props) => {
        const {currentFilePlacement, edit} = props;
        let errors = {};
        if (edit) {
            const formattedData = new AssetFile().popUpFields({...values});

            if (edit === 'rename') {
                const hasExtention = props.file_item.get('name').includes('.');
                const fileExtension = props.file_item.get('name').split('.').pop();
                formattedData.name = !values.name.match(new RegExp(`\\.${fileExtension}$`)) && hasExtention
                    ? `${values.name}.${fileExtension}` : values.name;
            }
            formattedData.parent_uuid = values.parent_uuid?.split('_').pop();

            return props.saveItem(AssetFile, currentFilePlacement,
                props.file_item.getIn(['links', 'self']), formattedData, undefined, {save_method: 'put'})
                .then(() => props.fetchItems(AssetFile, `${props.asset_files_placement}-${formattedData.parent_uuid}`,
                    `${props.folder_item.getIn(['links', 'root-folders'])}${formattedData.parent_uuid}/files/`)
                ).then(() => props.onClose(values.parent_uuid));
        } else {
            return Promise.all(values.files.map(({data}, index) => {
                return props.uploadFiles(currentFilePlacement, `${props.folder_item.getIn(['links', 'files'])}file/`, [data])
                    .then(([{data}]) => {
                        const hasExtention = data.asset_file_id.includes('.');
                        const fileExtension = data.asset_file_id.split('.').pop();
                        let name = values.files[index].name;
                        if (!name.match(new RegExp(`\\.${fileExtension}$`)) && hasExtention) {
                            name = `${name}.${fileExtension}`;
                        }
                        const formattedData = new AssetFile().popUpFields({
                            ...values.files[index], name, asset_file_id: data.asset_file_id
                        });

                        return props.saveItem(AssetFile, currentFilePlacement, props.folder_item.getIn(['links', 'files']), formattedData)
                            .catch((error) => {
                                errors = {...errors, [`files[${index}]`]: error.errors.name};
                            });
                    })
                    .catch((error) => {
                        if (['file_bad_type', 'file_too_large'].includes(error.errors?._error)) {
                            errors[`files[${index}]`] = props.intl.formatMessage({id: `assets.list.upload_file.dialog.form.file.error.${error.errors._error}`});
                        } else {
                            throw error;
                        }
                    });
            })).then(() => {
                if (Object.values(errors).length > 0) {
                    throw new SubmissionError(errors);
                } else {
                    props.onClose();
                }
            });
        }
    }
})(DetailForm);

const ConnectedDetail = connect((state, props) => {
    const {match, edit} = props;
    const folder_path = match.params.identifier;
    const target_file_id = match.params.file_identifier;
    const folderIds = folder_path?.split('_').slice(-2);
    const [parentId, folderId] = folderIds ? folderIds.length === 1 ? [undefined, folderIds[0]] : folderIds : [];
    const asset_folders_placement = new AssetFolder().getPlacement();
    const asset_folders_identifier = new AssetFolder().getUniqueIdentifier();
    const asset_files_placement = new AssetFile().getPlacement();
    const asset_files_identifier = new AssetFile().getUniqueIdentifier();
    
    const parentsFolders = state.shared.get('items').get(`${asset_folders_placement}${parentId ? `-${parentId}` : ''}`);
    const folder_item = parentsFolders?.find((childFolder) => childFolder.get(asset_folders_identifier) === folderId);
    const file_item = state.shared.getIn(['items', `${asset_files_placement}-${folderId}`])?.find((file) => file.get(asset_files_identifier) === target_file_id);
    const currentFilePlacement = `${asset_files_placement}-${folderId}`;

    return {
        form: `${edit || 'add'}_file${edit ? `_${target_file_id}` : ''}`,
        folder_item, file_item, folderId, currentFilePlacement, asset_files_placement,
        initialValues: edit
            ? {...new AssetFolder().popUpFields(file_item?.toJS() || {}), parent_uuid: folder_path}
            : {files: []}, state: state.app.get('state')
    };
}, (dispatch) => bindActionCreators({
    saveItem, fetchItems, setState, uploadFiles
}, dispatch))(DetailReduxForm);


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        // set default
        display: 'block',
        width: '570px',
        padding: `${theme.spacing(6)}px ${theme.spacing(9)}px ${theme.spacing(6)}px`,

        '& .MuiDialogTitle-root': {
            padding: 0,
            marginBottom: `${theme.spacing(1)}px`
        },
        '& .MuiDialogContent-root': {
            padding: 0,
            marginBottom: `${theme.spacing(5)}px`
        },
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`
        }
    }
}));

/**
 * Detail form to upload/edit files
 */
function Detail(props) { 
    const {open, history, match} = props;
    const classes = useStyles();

    const onClose = useCallback((newPath) => {
        history.push(`/asset-manager/${newPath || match.params.identifier}`);
    }, [history.location.pathname]);

    return <Dialog maxWidth='sm' open={open} onClose={() => onClose()} classes={{paper: classes.dialogPaper}}>
        <DialogTitle>
            <FormattedMessage id={`assets.list.${props.edit || 'upload'}_file.dialog.title`} />
        </DialogTitle>
        <ConnectedDetail {...props} onClose={onClose} />
    </Dialog>;
}

export default withRouter(injectIntl(Detail));
