import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useLocation} from 'react-router';
// material-ui
import Typography from '@material-ui/core/Typography';
// icons
import LogoSRAPS from 'components/core/vectors/LogoSRAPS';
import LogoPP from 'components/core/vectors/LogoPP';


const useStyles = makeStyles(theme => ({
    logo: {
        position: 'relative',
        fontSize: '31px',
        // SRAPS logo
        '& svg': {
            height: '35px',
            width: '106px',
            // off center (due to "p")
            position: 'relative',
            top: '8px'
        },
        // SNOM Partner Portal variant
        '&.portal': {
            '& svg': {
                height: '24px',
                width: '212px',
                // small off center
                top: '2px'
            }
        }
    }
}));

/**
 * Application Headline -> Logo
 */
export default function AppHeadline(props) {
    const {id} = props;
    const classes = useStyles();
    // router
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');

    const Logo = portal ? LogoPP : LogoSRAPS;
    return <Typography variant='h1' className={`${classes.logo} logo${portal ? ' portal' : ''}`}>
        <Logo id={id} />
    </Typography>;
}
