import React, {useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
import {useDebounce} from 'use-debounce';
// Components
import {EnhancedReduxField} from 'components/core/ui/Field';
import JSONPretty from 'lib/JSONPretty';
// material-ui
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';


const useStyles = makeStyles(theme => ({
    formControl: {
        // style taken from Field.js formControl
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10',
        // textArea
        width: props => props.size === 'full'
            ? '100%'
            : `${(320 * 2) + (theme.spacing(2))}px` // double the side
    },
    jsonInput: {
        // match font with JSON preview
        fontFamily: '"Roboto Mono", monospace',
        lineHeight: '21px'
    },
    jsonPrettyHolder: {
        // formControl proportion
        display: 'inline-flex',
        width: '100%',
        padding: `${theme.spacing(0.75)}px`, // TextArea proportion
        marginTop: `${theme.spacing(2)}px`, // space for label
        // matching font-size with input
        fontSize: theme.typography.htmlFontSize,
        lineHeight: '21px',
        // visual
        background: 'rgb(248, 248, 248)',
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        '&.error': {
            borderColor: theme.palette.danger[500],
            borderWidth: '2px'
        }
    }
}));

export const JSONFieldTheme = {
    background: 'rgb(248, 248, 248)',
    brace: 'rgb(51, 51, 51)',
    keyQuotes: 'rgb(51, 51, 51)',
    valueQuotes: 'rgb(221, 17, 68)',
    colon: 'rgb(51, 51, 51)',
    comma: 'rgb(51, 51, 51)',
    key: 'rgb(51, 51, 51)',
    value: {
        string: 'rgb(221, 17, 68)',
        null: 'rgb(0, 128, 128)',
        number: 'rgb(0, 128, 128)',
        boolean: 'rgb(0, 128, 128)'
    },
    bracket: 'rgb(51, 51, 51)'
};

const _renderJSONField = ({input, name, label, helperText, classes, show, selectedValue, json, focusFun, disabled, meta: {touched, error}}) => {
    return <FormControl fullWidth error={Boolean(touched && error)} className={classes.formControl}>
        <InputLabel shrink={!!selectedValue || !show}>{label}</InputLabel>
        {show
            ? <Input name={name} autoFocus={!!selectedValue}
                     multiline minRows={2} maxRows={15}
                     className={classes.jsonInput}
                     {...input} value={selectedValue} />
            : <div className={`${classes.jsonPrettyHolder}${touched && error ? ' error' : ''}`}
                   onClick={() => !disabled ? focusFun() : {}}>
            <JSONPretty json={json} theme={JSONFieldTheme} />
        </div>}
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Field with pretty JSON preview when not focused
 */
export default function JSONField(props) {
    const {formName, disabled, selectedValue, ...rest_of_props} = props;
    const classes = useStyles(props);
    // local state
    const [show, setShow] = useState(false); // show input instead of json preview
    const [debouncedShow] = useDebounce(show, theme.transitions.duration.shorter); // match line transition of input underline

    const json = useMemo(() => {
        try {
            return JSON.parse(selectedValue);
        } catch (err) {
            return null;
        }
    }, [selectedValue]);

    return <EnhancedReduxField
        component={_renderJSONField}
        show={show || debouncedShow}
        json={json}
        onBlur={() => json ? setShow(false) : {}}
        focusFun={() => setShow(true)}
        disabled={disabled !== undefined ? disabled : false}
        selectedValue={selectedValue}
        classes={classes}
        {...rest_of_props}
    />;
}
