import React, {useCallback, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router';
import {AssetFile} from 'lib/models';
// actions
import {deleteItem, setCollection} from 'actions/shared';
// components
import DeleteDialog from 'components/core/ui/DeleteDialog';


/**
 * Render deleting files dialog
 * @param {object} props - component props
 * @param {boolean} props.open - open dialog flag
 */
export default function DeleteFileDialog(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();
    const folder_path = match.params.identifier;
    const target_file_id = match.params.file_identifier;
    
    const asset_files_placement = useMemo(() => new AssetFile().getPlacement(), []);
    const asset_files_identifier = useMemo(() => new AssetFile().getUniqueIdentifier(), []);
    const folder_id = useMemo(() => folder_path?.split('_').slice(-1)[0], [folder_path]);
    const files_placement = useMemo(() => `${asset_files_placement}-${folder_id}`);

    const {file_item, file_items} = useSelector((state) => {
        const files = state.shared.getIn(['items', files_placement]);
        const file = files?.find((childFile) => childFile.get(asset_files_identifier) === target_file_id);

        return {file_items: files, file_item: file};

    }, shallowEqual);

    const onClose = useCallback(() => {
        history.push(`/asset-manager/${folder_path}`);
    }, [folder_path]);

    return <DeleteDialog open={props.open} handleClose={() => onClose()}
        item={intl.formatMessage({id: 'assets.list.deletedialog.delete_file'})}
        handleConfirm={() => {
            dispatch(deleteItem(AssetFile, files_placement, file_item?.getIn(['links', 'self']), file_item))
                .then(() => {
                    dispatch(setCollection(AssetFile,  files_placement, file_items.filter(file => file.get(asset_files_identifier) !== target_file_id)));
                    onClose();
                });
        }} />;
} 
