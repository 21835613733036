import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {ApiKey, Company, Role} from 'lib/models';
import {useHistory, useLocation} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
// Components
import {CompanyField, SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Filters from 'components/core/ui/Filters';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders API Keys - to view and manage them
 */
export default function List(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, firmware_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        // ensure we don't create duplicate 'my' collection with UUID
        const company_identifier = searchParams.get('company') && searchParams.get('company') !== company.get(new Company().getUniqueIdentifier())
            ? searchParams.get('company')
            : 'my';

        const items_placement = `${new ApiKey().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            roles_loaded: state.shared.getIn(['loaded', 'roles']),
            roles_items: state.shared.getIn(['items', 'roles']),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            selectedCompany: company_identifier === 'my' ? company : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='apikeys.list.table.name' />},
        {sortName: 'role', label: <FormattedMessage id='apikeys.list.table.role' />},
        {sortName: 'access_key_id', label: <FormattedMessage id='apikeys.list.table.access_key_id' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded || !props.roles_loaded,
        new ApiKey().getPlacement(), tableHead.map(item => item.sortName), 'name', 'desc', {
            role: (obj) => (props.roles_items.find(el => el.get(new Role().getUniqueIdentifier()) === obj.get('role')) || new ImmutableMap({name: ''})).get('name')
        });
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new ApiKey().getPlacement(),
        ['company', 'search'], ['name', 'access_key_id']);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false && props.selectedCompany) {
            dispatch(fetchItems(ApiKey, items_placement, props.selectedCompany.getIn(['links', 'tokens'])));
        }
    }, [filtersInitializing, JSON.stringify(filters), props.selectedCompany]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (!props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.roles_loaded === false) {
            dispatch(fetchItems(Role, 'roles', 'roles', null, null, {affect_state: false}));
        }
    }, [props.roles_loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'apikeys.list.global.title'
                : 'apikeys.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'apikeys.list.global.subheader'
                : 'apikeys.list.subheader'} />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}api-keys/add${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`)}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='api_keys' />
            </ActionButton>}
        />
        <Filters>
            {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <CompanyField
                value={filters.company || ''}
                change={value => filterItems('company', value)}
                all={false} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            <SearchField
                label={<FormattedMessage id='apikeys.list.search' />}
                value={filters.search || ''}
                search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    'fetching_items_api-keys', `fetching_items_api-keys-${company_identifier}`
                ].includes(props.state) ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'productdefaults.list.table.empty'}
                                              values={{link: passed_props.permissions === 'RW'
                                                      ? <Link to={`/${pathname.startsWith('/all-') ? 'all-' : ''}api-keys/add${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`} className='hover-border'>
                                                          <FormattedMessage id='apikeys.list.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='apikeys.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((item, idx) => {
                        const link = `/${pathname.startsWith('/all-') ? 'all-' : ''}api-keys/${item.get(new ApiKey().getUniqueIdentifier())}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`;
                        const onClick = () => props.state === null ? history.push(link) : {};

                        const role = props.roles_items.find(el => el.get(new Role().getUniqueIdentifier()) === item.get('role'));

                        return <TableRow key={idx} hover>
                            <TableCell link onClick={onClick}>
                                {item.get('name')}
                            </TableCell>
                            <TableCell link={!props.user.isAdmin()} onClick={() => !props.user.isAdmin ? onClick() : {}}>
                                {!props.roles_loaded
                                    ? <CircularProgress size={36} />
                                    : role
                                        ? props.user.isAdmin()
                                            ? <Link className='hover-border' to={`/permissions/${role.get(new Role().getUniqueIdentifier())}`}>
                                                    {role.get('name')}
                                                </Link>
                                            : role.get('name')
                                        : <div />}
                            </TableCell>
                            <TableCell link onClick={onClick}>
                                {item.get('access_key_id')}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>} />
    </Card>;
}
