import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Company, VoiceQuality} from 'lib/models';
import {useLocation, useParams} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
// Actions
import {setState} from 'actions/app';
import {fetchItem} from 'actions/shared';
// Components
import ParametersBox from 'components/modules/voicequality/ParametersBox';
import QualityBanner from 'components/modules/voicequality/QualityBanner';
import RawDialog from 'components/modules/voicequality/RawDialog';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import DeviceIcon from '@material-ui/icons/PhoneOutlined';
import CallIcon from '@material-ui/icons/PhoneInTalk';
import AudioIcon from '@material-ui/icons/VolumeUp';
import {List as ImmutableList} from 'immutable';
// vectors
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    boxWrapper: {
        display: 'grid',
        gridTemplateColumns: '2fr 2fr 1fr',
        gridTemplateRows: '1fr 1fr',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        gap: `${theme.spacing(2)}px`,
        [theme.breakpoints.up('xl')]: {
            // first box takes 2 rows
            '& > div:first-of-type': {
                gridRowStart: '1',
                gridRowEnd: '3'
            },
            // last box takes 2 columns
            '& > div:last-of-type': {
                gridColumnStart: '2',
                gridColumnEnd: '4'
            }
        },
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr'
        }
    }
}));

/**
 * Renders detail of Voice Quality
 */
export default function Detail(passed_props) {
    const {item} = passed_props;
    // styles
    const classes = useStyles();
    // router
    const history = useHistory();
    const {identifier} = useParams();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
            ? 'my' : searchParams.get('company');
        const items_placement = `${new VoiceQuality().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;
        const items = state.shared.getIn(['items', items_placement]) || ImmutableList();

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            item: item ? item : items.find(el => el.get(new VoiceQuality().getUniqueIdentifier()) === identifier),
            company: company,
            selectedCompany: company_identifier === 'my' ? company : companies.find(el => el.get(new Company().getUniqueIdentifier()) === company_identifier)
        };
    }, shallowEqual);
    // locale state
    const MainCard = useMemo(() => item ? React.Fragment : Card, [item]);

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        if (!item) {
            if (!props.selectedCompany) {
                dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                    {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
            } else {
                if (!props.item) {
                    dispatch(fetchItem(VoiceQuality,
                        items_placement,
                        [
                            props.selectedCompany.getIn(['links', 'voice-quality-history']),
                            identifier
                        ]
                        // voice quality does not have company link, so we cannot verify
                        // {company: props.selectedCompany}
                    ));
                } else if (['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)) {
                    dispatch(setState(null));
                }
            }
        }
    }, [identifier, props.selectedCompany, item]);

    return <React.Fragment>
        {['fetching_item_companies', 'fetching_item_my-companies', `fetching_item_${items_placement}`].includes(props.state) || item === 'loading'
            ? <MainCard>
                <CardHeader
                    title={<FormattedMessage id='voicequality.detail.title' />}
                    subheader={<FormattedMessage id='voicequality.detail.subheader' />}
                    action={<ActionButton iconButton disabled>
                        <ModelIcon model='voice-quality' />
                    </ActionButton>}
                />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </MainCard>
            : !props.item
                ? <ErrorCard
                    title={<FormattedMessage id='voicequality.detail.notfound.title' />}
                    text={<FormattedMessage id='voicequality.detail.notfound.text' />}
                    icon={<WarningIcon color='secondary' />} />
                : <MainCard>
                    <CardHeader
                        title={<FormattedMessage id='voicequality.detail.title' />}
                        subheader={<FormattedMessage id='voicequality.detail.subheader' />}
                        action={<RawDialog data={props.item.get('params')} />}
                    />
                    <CardContent className={classes.boxWrapper}>
                        <ParametersBox
                            title='device'
                            icon={<DeviceIcon />}
                            fields={[
                                {key: 'mac', params: false},
                                {key: 'LocalAddr_IP'},
                                {key: 'RemoteAddr_IP'},
                                {key: 'LocalAddr_PORT'},
                                {key: 'RemoteAddr_PORT'},
                                {key: 'LocalAddr_SSRC'},
                                {key: 'RemoteAddr_SSRC'},
                                {key: 'LocalID'},
                                {key: 'RemoteID'},
                                {key: 'OrigID'}
                            ]}
                            item={props.item}
                        />
                        <ParametersBox
                            title='call'
                            icon={<CallIcon />}
                            horizontal
                            fields={[
                                {key: 'Timestamps_START', type: 'date'},
                                {key: 'Timestamps_STOP', type: 'date'},
                                {key: 'QualityEst_MOSLQ'},
                                {key: 'QualityEst_MOSCQ'},
                                {key: 'Delay_RTD'},
                                {key: 'Delay_ESD'},
                                {key: 'BurstGapLoss_BLD'},
                                {key: 'BurstGapLoss_GD'},
                                {key: 'BurstGapLoss_BD'},
                                {key: 'BurstGapLoss_GLD'},
                                {key: 'BurstGapLoss_GMIN'},
                                {key: 'Delay_IAJ'}
                            ]}
                            item={props.item}
                        />
                        <QualityBanner variant={props.item.getQuality(props.item.getIn(['params', 'QualityEst_MOSCQ']))} />
                        <ParametersBox
                            title='audio'
                            icon={<AudioIcon />}
                            fields={[
                                {key: 'JitterBuffer_JBA'},
                                {key: 'JitterBuffer_JBR'},
                                {key: 'JitterBuffer_JBN'},
                                {key: 'JitterBuffer_JBM'},
                                {key: 'JitterBuffer_JBX'}
                            ]}
                            item={props.item}
                        />
                    </CardContent>
                    {!item && <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}phones${company_identifier === 'my' ? '' : `?company=${company_identifier}`}#voice-quality`)}>
                            <CancelIcon />
                            <FormattedMessage id='actions.back' />
                        </Button>
                    </CardActions>}
                </MainCard>}
    </React.Fragment>;
}
