import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import Moment from 'moment';
import {ProvisioningFileType} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem, uploadFiles} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * Renders detail of ProvisioningFileTypes - ADD & EDIT
 */
function Detail(props) {
    const items_placement = props.items_placement;
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog

    /**
     * During initialization fetch item if needed (Edit)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItem(ProvisioningFileType, items_placement, ['provisioning-file-types', props.match.params.identifier]);
        }
    }, [props.match.params.identifier]);

    return <div>
        {[`fetching_item_${items_placement}`, `deleting_item_${items_placement}`].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='provfiletypes.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='provisioning_file_type' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='provfiletypes.detail.notfound.title' />}
                text={<FormattedMessage id='provfiletypes.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='provfiletypes.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='provfiletypes.detail.edit.title' />
                                : <FormattedMessage id='provfiletypes.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='provisioning_file_type' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'provfiletypes.detail.form.fields.name'})}*`}
                               helperText={<FormattedMessage id='provfiletypes.detail.form.fields.name.help' />} />
                        <Field name='code' fieldType='TextField' label={`${props.intl.formatMessage({id: 'provfiletypes.detail.form.fields.code'})}*`}
                               helperText={<FormattedMessage id='provfiletypes.detail.form.fields.code.help' />}
                               disabled={edit} />
                        <Field name='allowed_mimetypes' fieldType='TextField' label={`${props.intl.formatMessage({id: 'provfiletypes.detail.form.fields.allowed_mimetypes'})}`}
                               helperText={<FormattedMessage id='provfiletypes.detail.form.fields.allowed_mimetypes.help' />}/>
                        {edit && <React.Fragment>
                            <SpaceDivider />
                            <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='provfiletypes.detail.form.fields.created_at' />}
                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                            <Field name='updated_at' fieldType='TextField' label={<FormattedMessage id='provfiletypes.detail.form.fields.updated_at' />}
                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                        </React.Fragment>}
                    </Form>
                </CardContent>
                {[`saving_item_${items_placement}`, `saved_item_${items_placement}`, `failed_save_item_${items_placement}`].includes(props.state)
                    ? <CardActionsLoader success={props.state === `saved_item_${items_placement}`}
                                         failure={props.state === `failed_save_item_${items_placement}`}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                        <CardActions>
                            <Button disabled={props.state !== null}
                                    onClick={() => props.history.push('/provisioning-file-types')}>
                                <CancelIcon />
                                <FormattedMessage id='actions.cancel' />
                            </Button>
                            {props.permission === 'RW' && <React.Fragment>
                                {edit && <Button
                                    variant='contained' color='secondary' disabled={props.state !== null}
                                    onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>}
                                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                        onClick={() => props.handleSubmit()}>
                                    <SaveIcon />
                                    <FormattedMessage id='actions.save' />
                                </Button>
                            </React.Fragment>}
                        </CardActions>
                    </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'provfiletypes.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'provfiletypes.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(ProvisioningFileType, items_placement, props.item.getIn(['links', 'self']), props.item,
                            {error_message_intl: 'provfiletypes.detail.delete.fail'}).then((result) => {
                                if (result !== false) {
                                    props.history.push('/provisioning-file-types');
                                }
                        });
                    }}
                />}
            </Card>
        </div>}
    </div>;
}

function toObject(provFileType) {
    provFileType = provFileType.toJS();

    if (provFileType.allowed_mimetypes) {
        provFileType.allowed_mimetypes = provFileType.allowed_mimetypes.join(', ');
    }
    return provFileType;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'code', data.code) &&
    validator.isSlug(null, errors, 'code', data.code);
    if (data.allowed_mimetypes) {
        validator.areValidMimeTypes(null, errors, 'allowed_mimetypes', data.allowed_mimetypes);
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'provisioningFileTypeForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let {...rest_of_data} = new ProvisioningFileType().popUpFields(props.formValues, !props.item);

        if (rest_of_data['allowed_mimetypes']) {
            rest_of_data['allowed_mimetypes'] = [...rest_of_data['allowed_mimetypes'].replace(/\s/g, '').split(',').filter(e => e)];
        } else {
            rest_of_data['allowed_mimetypes'] = [];
        }

        // save object
        return dispatch(saveItem(ProvisioningFileType, props.items_placement, props.item ? props.item.getIn(['links', 'self']) : 'provisioning-file-types', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new ProvisioningFileType().getUniqueIdentifier())) {
                props.history.push(`/provisioning-file-types/${create_data.get(new ProvisioningFileType().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const items_placement = new ProvisioningFileType().getPlacement();
    const item = state.shared.getIn(['items', items_placement]).find(el => el.get(new ProvisioningFileType().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        items_placement: items_placement,
        item: item,
        initialValues: props.match.params.identifier
            ? item
                ? toObject(item)
                : undefined
            : {},
        formValues: getFormValues('provisioningFileTypeForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem,
    uploadFiles
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
