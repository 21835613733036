import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import Moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {ReleaseNote} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// components
import Tabs from 'components/core/ui/Tabs';
import Filters from 'components/core/ui/Filters';
import Field, {DateSearchField, SearchField} from 'components/core/ui/Field';
import StickyTable from 'components/core/ui/StickyTable';
import DevChangelogCard from 'components/modules/changelog/DevChangelogCard';
// import {getLanguageName, LanguageIcon} from 'components/core/ui/fields/LanguageField';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tab from 'components/core/ui/mui/Tab';
// icons
import ChangelogTypeIcon from 'components/core/vectors/ChangelogTypeIcon';
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


const useStyles = makeStyles((theme) => ({
    tableTypeCell: {
        '& .MuiSvgIcon-root': {
            marginRight: `${theme.spacing(1)}px`
        }
    },
    tableStatusCell: {
        '&.draft': {
            color: theme.palette.text.secondary
        },
        '&.published': {
            color: theme.palette.success.main
        }
    }
}));

/**
 * Render ReleaseNote list from backend for admins
 */
export default function List(props) {
    const {permission} = props;
    const classes = useStyles();

    const dispatch = useDispatch();
    const intl = useIntl();
    // router
    const history = useHistory();
    const items_placement = useMemo(() => new ReleaseNote().getPlacement(), []);
    const items_identifier = useMemo(() => new ReleaseNote().getUniqueIdentifier(), []);
    const changelog_types = useMemo(() => new ReleaseNote().getChangelogTypes(), []);
    // redux store
    const {items, state, items_loaded, dev_changelog_permission} = useSelector((state) => ({
        state: state.app.get('state'),
        items: state.shared.getIn(['items', items_placement]),
        items_loaded: state.shared.getIn(['loaded', items_placement]),
        dev_changelog_permission: ['R', 'RW'].includes(state.auth.getIn(['permissions', 'dev_changelog']))
    }), shallowEqual);
    // define table columns with sorting
    const tableHead = useMemo(() => [
        {sortName: 'version', label: <FormattedMessage id='changelogs.list.table.version' />},
        // {sortName: 'language', label: <FormattedMessage id='changelogs.list.table.language' />},
        {sortName: 'type', label: <FormattedMessage id='changelogs.list.table.type' />},
        {sortName: 'created_at', label: <FormattedMessage id='changelogs.list.table.created_at' />},
        {sortName: 'changes', label: <FormattedMessage id='changelogs.list.table.changes' />},
        {sortName: 'status', numeric: true, label: <FormattedMessage id='changelogs.list.table.status' />}
    ], []);
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        items, !items_loaded, items_placement, tableHead.map(item => item.sortName), 'created_at', 'asc', {
            status: (item) => Moment(item.get('created_at')).isAfter()
        }
    );
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['search', 'type', 'created_at'], ['version'], {
            created_at: (item, value) => Moment(item.get('created_at')).isSame(value, 'date')
        }
    );

    // fetch release notes
    useEffect(() => {
        if (!items_loaded) {
            dispatch(fetchItems(ReleaseNote, items_placement, 'release-notes'));
        }
    }, [items_loaded]);

    return <Tabs destroyNotSelected
        initialSelectedTab={['#changelog', '#dev_changelog'].findIndex((hash) => hash === history.location.hash) > 0 ? 1 : 0}
        onChange={(tab) => history.push({hash: tab === 1 ? '#dev_changelog' : null})}
        tabs={dev_changelog_permission ? [
            <Tab key={0} label={intl.formatMessage({id: 'changelogs.list.tab.changelog'})} />,
            <Tab key={1} label={intl.formatMessage({id: 'changelogs.list.tab.dev_changelog'})} />
        ] : null}
        content={[
            <Card key={0}>
                <CardHeader title={intl.formatMessage({id: 'changelogs.list.title'})}
                    subheader={intl.formatMessage({id: 'changelogs.list.subheader'})}
                    action={permission === 'RW'
                        ? <ActionButton onClick={() => history.push('/all-changelogs/add')} variant='contained'
                            color='secondary' disabled={state !== null} startIcon={<AddIcon />}>
                            <FormattedMessage id='actions.create' />
                        </ActionButton>
                        : <ActionButton iconButton disabled>
                            <ModelIcon model={items_placement} />
                        </ActionButton>} />
                <Filters>
                    <SearchField label={<FormattedMessage id='changelogs.list.filter.search' />} value={filters.search || ''}
                                search={(search) => filterItems('search', search)} />
                    <Field label={<FormattedMessage id='changelogs.list.filter.type' />} fieldType='NoReduxSelect'
                            value={filters.type || ''} onChange={(event) => filterItems('type', event.target.value)}>
                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                        {['release', 'regular_update', 'special_update'].map((item, index) => <MenuItem key={index} value={item}>
                            <FormattedMessage id={`changelog.type.${item}`} />
                        </MenuItem>)}
                    </Field>
                    <DateSearchField label={<FormattedMessage id='changelogs.list.filter.created_at' />} value={filters.created_at || ''}
                        search={(search) => filterItems('created_at', search)} />
                    {/* <LanguageField fieldType='NoReduxSelect' value={filters.language || ''} */}
                    {/*     onChange={(event) => filterItems('language', event.target.value)} /> */}
                </Filters>
                <StickyTable
                    header={<Table size={tableHead.length}>
                        <TableHead>
                            <TableRow>
                                {tableHead.map((item, idx) => <TableCell numeric={item.numeric} key={idx}>
                                    <TableSortLabel active={sortName === item.sortName} direction={sortDirection}
                                        onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                    </Table>}
                    content={<Table size={tableHead.length}>
                        {!items_loaded && filtersInitializing ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                            </TableRow>
                        </TableBody> : !filteredItems?.size ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <em>
                                        <FormattedMessage id={items.size ? 'filters.empty' : 'changelogs.list.table.empty'}
                                            values={{link: permission === 'RW'
                                                    ? <Link to='/all-changelogs/add' className='hover-border'>
                                                        <FormattedMessage id='changelogs.list.table.empty.link' />
                                                    </Link>
                                                    : <FormattedMessage id='changelogs.list.table.empty.link' />}} />
                                    </em>
                                </TableCell>
                            </TableRow>
                        </TableBody> : <TableBody>
                            {filteredItems.map((releaseNote) => {
                                const status = Moment(releaseNote.get('created_at')).isAfter() ? 'draft' : 'published';

                                return <TableRow key={releaseNote.get(items_identifier)} link hover
                                    onClick={() => state === null ? history.push(`/all-changelogs/${releaseNote.get(items_identifier)}`) : {}}>
                                    <TableCell name>{releaseNote.get('version')}</TableCell>
                                    {/* <TableCell name> */}
                                    {/*     <LanguageIcon code={releaseNote.get('language')} className='icon' /> */}
                                    {/*     <span className='text'>{getLanguageName(releaseNote.get('language'))}</span> */}
                                    {/* </TableCell> */}
                                    <TableCell className={classes.tableTypeCell}>
                                        <ChangelogTypeIcon noContainer type={releaseNote.get('type')} />
                                        <FormattedMessage id={`changelog.type.${releaseNote.get('type')}`} />
                                    </TableCell>
                                    <TableCell>{Moment(releaseNote.get('created_at')).format('L')}</TableCell>
                                    <TableCell>
                                        {changelog_types.map((item) => {
                                            const amount = releaseNote.get(item)?.size;

                                            return amount ? intl.formatMessage({id: `changelogs.list.table.${item}`}, {amount}) : null;
                                        }).filter(Boolean).join(', ')}
                                    </TableCell>
                                    <TableCell numeric className={`${classes.tableStatusCell} ${status}`}>
                                        <FormattedMessage id={`changelogs.list.table.status.${status}`} />
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
                    </Table>}/>
            </Card>,
            dev_changelog_permission ? <DevChangelogCard key={1} /> : null
        ]}
    />;
}
