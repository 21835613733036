import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// Components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import CardContent from 'components/core/ui/mui/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import FailureIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    root: {
        // same size as CardAction
        paddingTop: '26.5px',
        '&, &:last-child': {
            paddingBottom: '26px'
        },
        '&.dialogactions': {
            // same size as DialogActions with buttons
            paddingLeft: `${(theme.spacing(1))}px`,
            paddingRight: `${(theme.spacing(1))}px`
            // top and bottom matches CardActions (56px)
        }
    },
    // progress bar itself
    progress: {
        width: '100%',
        // allow animation
        transition: props => theme.transitions.create(['width', 'margin', 'background'],
            {duration: props.animationLength}),
        '&.anim': {
            // hide
            width: '0',
            // hide it to right side
            marginLeft: 'auto',
            // move into icon
            marginRight: '14px',
            // make it full loaded
            background: theme.palette.primary[500]
        },
        '&.center.anim': {
            // center
            marginRight: 'auto'
        }
    },
    iconHolder: {
        display: 'block',
        position: 'relative',
        marginTop: '-21px',
        marginBottom: '21px'
    },
    // success or failure icon
    icon: {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        // enlarge
        fontSize: theme.typography.pxToRem(34),
        // hide by default
        opacity: '0',
        // allow animation
        transition: theme.transitions.create(['opacity', 'color'],
            {duration: theme.transitions.duration.shortest}),
        transitionDelay: props => `${props.animationLength - theme.transitions.duration.shortest}ms`,
        // loader color
        color: theme.palette.primary[500],
        '&.anim': {
            // display icon
            opacity: '1',
            // recolor
            '&.success': {
                color: theme.palette.success[500]
            },
            '&.failure': {
                color: theme.palette.danger[500]
            }
        },
        '&.center': {
            left: '0',
            margin: '0 auto'
        }
    }
}));

/**
 * Loader to replace buttons in <CardAction />
 */
export default function CardActionsLoader(props) {
    // Split some stuff from props
    const {_classes = {}, className, success = false, failure = false, postAnimation, dialogActions = false, center = false, ...rest_of_props} = props;
    // local state
    const animationLength = 500; // duration of success animation in MS
    const [successState, setSuccess] = useState(false);
    const [failureState, setFailure] = useState(false);
    const [firstMount, setFirstMount] = useState(true);
    // Merge classes from props and our custom
    const classes = useStyles({animationLength: animationLength});
    const {root, progress, icon} = _classes;
    const rootClasses = [
        classes.root, root,
        dialogActions ? 'dialogactions' : ''
    ].filter(Boolean).join(' ');
    const iconClasses = [
        classes.icon, icon,
        center ? 'center' : ''
    ].filter(Boolean).join(' ');
    let progressClasses = [
        classes.progress, progress, className,
        center ? 'center' : ''
    ].filter(Boolean).join(' ');
    // success and failure with animation
    let successIconClasses = iconClasses;
    let failureIconClasses = iconClasses;
    if (successState) {
        progressClasses = `${progressClasses} anim`;
        successIconClasses = `${successIconClasses} anim success`;
    } else if (failureState) {
        progressClasses = `${progressClasses} anim`;
        failureIconClasses = `${failureIconClasses} anim failure`;
    }

    useEffect(() => {
        if (success || failure) {
            setTimeout(() => {
                if (success) {
                    setSuccess(true);
                    setTimeout(() => {
                        postAnimation(true);
                    }, (animationLength + 1000));
                } else if (failure) {
                    setFailure(true);
                    setTimeout(() => {
                        postAnimation(false);
                    }, (animationLength + 1000));
                }
            }, firstMount ? 300 : 0);
        }
        setFirstMount(false);
    }, [success, failure]);

    return <CardContent className={rootClasses}>
        <LinearProgress className={progressClasses} {...rest_of_props} />
        {postAnimation && <div className={classes.iconHolder}>
            <ThemeProvider alt>
                <SuccessIcon className={successIconClasses} />
                <FailureIcon className={failureIconClasses} />
            </ThemeProvider>
        </div>}
    </CardContent>;
}
