import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%' // due to expansion panel flexbox, fix it to 100% size
    }
}));

/**
 * Make sure that content in ExpansionPanelDetails is 100% size
 */
export default function ExpansionPanelChild(props) {
    const classes = useStyles();

    return <div className={classes.root} {...props} />;
}
