import React, {useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
// Actions
import {setState, addMessage} from 'actions/app';
import {simplePost} from 'actions/shared';
// Components
import {Link} from 'react-router-dom';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import RequestCSVIcon from '@material-ui/icons/GetAppOutlined';


/**
 * ActionButton which triggers request for CSV file. CSV will appear in /exports
 */
export default function RequestCSV(props) {
    const {placement, url, filters = {}, filtersOptions = null, disabled = false} = props;
    // local state
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // redux store
    const dispatch = useDispatch();
    const {state, user} = useSelector(state => ({
        state: state.app.get('state'),
        user: state.auth.get('user')
    }), shallowEqual);

    /**
     * Make simple post to request CSV export
     *
     * @param extraFilters - additional filters for request
     */
    const requestExport = (extraFilters = {}) => {
        dispatch(simplePost(`export_request_${props.placement}`, props.url, null, {qparams: {...filters, ...extraFilters}}));
    };

    return <React.Fragment>
        <ActionButton
            variant='outlined' color='primary'
            disabled={disabled || state !== null || !url}
            onClick={(event) => {
                if (filtersOptions) {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                } else {
                    requestExport();
                }
            }}
            loading={[`posting_export_request_${placement}`, `posted_export_request_${placement}`, `failed_export_request_${placement}`].includes(state)}
            success={state === `posted_export_request_${placement}`}
            failure={state === `failed_export_request_${placement}`}
            postAnimation={success => {
                dispatch(setState(null));
                if (success) {
                    // success message
                    dispatch(addMessage({
                        intl_id: 'exports.request.success',
                        intl_values: {
                            link: <Link to={`/${user.isAdmin() ? 'all-' : ''}exports`} className='hover-border'>
                                <FormattedMessage id='exports.request.success.link' />
                            </Link>
                        },
                        path: 'on-change',
                        type: 'default'
                    }));
                }
            }}>
            <RequestCSVIcon />
            <FormattedMessage id='actions.request_csv' />
        </ActionButton>
        {!!filtersOptions && <Menu open={open} anchorEl={anchorEl} onClose={() => setOpen(false)}>
            {filtersOptions.map((filter) => <MenuItem
                key={filter.intl} onClick={() => {
                    setOpen(false);
                    requestExport(filter.filters);
                }}>
                {filter.icon
                    ? <React.Fragment>
                        <ListItemIcon className='icon'>{filter.icon}</ListItemIcon>
                        <ListItemText className='text'><FormattedMessage id={filter.intl} /></ListItemText>
                    </React.Fragment>
                    : <FormattedMessage id={filter.intl} />}
            </MenuItem>)}
        </Menu>}
    </React.Fragment>;
}
