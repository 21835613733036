import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {List as ImmutableList} from 'immutable';
import useFeatureFlag from 'lib/featureFlag';
import {Company} from 'lib/models';
// Components
import EndpointsList from 'components/modules/endpoints/List';
import EndpointImports from 'components/modules/endpointsimports/List';
import VoiceQualityReports from 'components/modules/voicequality/List';
import Tabs from 'components/core/ui/Tabs';
// material-ui
import Tab from 'components/core/ui/mui/Tab';


/**
 * Main holder of Endpoints page with Tabs (phones, voice quality reports)
 */
export default function Endpoints(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname, hash} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux
    const props = useSelector(state => {
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
            ? 'my' : searchParams.get('company');
        const selectedCompany = company_identifier === 'my' ? company : companies.find(el => el.get(new Company().getUniqueIdentifier()) === company_identifier);
        const companyHasSubscription = selectedCompany?.get('has_subscription');

        return {
            company,
            user: state.auth.get('user'),
            selectedCompany,
            companyHasSubscription
        };
    }, shallowEqual);
    // feature flags
    const featureFlag_endpointImport = useFeatureFlag('endpoint_bulk_import', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});
    const featureFlag_deviceManager = useFeatureFlag('device_manager', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});
    // tabs
    const tabsHashes = useMemo(() => [null, '#voice-quality', '#imports'], []);
    const selectedTabIndex = useMemo(() => {
        const foundIndex = tabsHashes.findIndex(tab_hash => tab_hash === hash);
        // set main tab
        if ((foundIndex === 1 && !props.companyHasSubscription) || (foundIndex === 2 && !featureFlag_endpointImport) || foundIndex === -1) {
            return 0;
        }

        return foundIndex;
    }, [tabsHashes.length, props.companyHasSubscription, featureFlag_endpointImport, hash]);

    return !pathname.startsWith('/all-') && (featureFlag_endpointImport || (featureFlag_deviceManager && props.companyHasSubscription))
        ? <Tabs
            tabs={[
                <Tab key={0} value={0} label={<FormattedMessage id='endpoints.tabs.phones' />} />,
                <Tab key={1} value={1} label={<FormattedMessage id='endpoints.tabs.voice-quality' />}
                     disabled={!props.companyHasSubscription || !featureFlag_deviceManager} />,
                 <Tab key={2} value={2} disabled={!featureFlag_endpointImport} label={<FormattedMessage id='endpoints.tabs.imports' />} />
            ]}
            initialSelectedTab={selectedTabIndex}
            onChange={tab => history.push({
                hash: tabsHashes[tab],
                ...(searchParams.get('company') ? {search: `?company=${searchParams.get('company')}`} : {})
            })}
            destroyNotSelected
            content={[
                <div key={0}><EndpointsList {...passed_props} /></div>,
                featureFlag_deviceManager && props.selectedCompany && props.companyHasSubscription ? <div key={1}>
                    <VoiceQualityReports {...passed_props} />
                </div> : null,
                featureFlag_endpointImport ? <div key={2}><EndpointImports {...passed_props} /></div> : null
            ]}
        />
        : <EndpointsList {...passed_props} />;
}
