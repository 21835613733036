import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useLocation} from 'react-router';


const useStyles = makeStyles(theme => ({
    root: {
        '&.hidden': {
            display: 'none'
        }
    },
    // header orange highlight
    developmentBorder: {
        position: 'absolute',
        zIndex: 5,
        left: '0',
        right: '0',
        bottom: '0',
        // style
        height: '2px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(135deg, ${theme.gradients.wild.color3} 0%, ${theme.gradients.wild.color2} 50%, ${theme.palette.primary[500]} 100%)`,
        '&.staging': {
            backgroundSize: '200% 200%',
            backgroundImage: theme.gradients.blue.color,
            backgroundPosition: theme.gradients.blue.position,
            '&.portal': {
                backgroundImage: theme.gradients.green.color,
                backgroundPosition: theme.gradients.green.position
            }
        }
    },
    // badge with 'development' text in it
    developmentBadge: {
        // position bellow logo
        position: 'absolute',
        zIndex: 10,
        bottom: '-9px',
        padding: `0 ${theme.spacing(2)}px`,
        left: `${theme.spacing(3)}px`,
        textAlign: 'center',
        // style
        ...theme.typography.button,
        fontSize: theme.typography.pxToRem(11),
        cursor: 'pointer',
        background: theme.palette.common.white,
        boxShadow: theme.shadows[4],
        border: `1px solid ${theme.palette.primary[500]}`,
        // smooth
        transition: theme.transitions.create(['border'],
            {duration: theme.transitions.duration.short}),
        // text itself
        '&.development span': {
            // gradient text
            backgroundImage: `linear-gradient(135deg, ${theme.palette.primary[500]} 0%, ${theme.gradients.wild.color2} 50%, ${theme.gradients.wild.color3} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
        }
    }
}));

/**
 * Component to help distinct development and production
 */
export default function Development() {
    const classes = useStyles();
    const staging = process.env.REACT_APP_STAGING;
    // router
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');
    // local state
    const [hidden, setHidden] = useState(false);
    // set up classes
    const rootClasses = [
        classes.root,
        hidden ? 'hidden' : ''
    ].filter(Boolean).join(' ');
    const divClasses = [
        portal ? 'portal' : '',
        staging ? 'staging' : 'development'
    ].filter(Boolean).join(' ');

    return <div className={rootClasses}>
        <div className={`${classes.developmentBorder} ${divClasses}`} />
        <div className={`${classes.developmentBadge} ${divClasses}`}
             onClick={() => setHidden(true)}>
            <span>{staging ? 'staging' : 'development'}</span>
        </div>
    </div>;
}
