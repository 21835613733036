import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITable from '@material-ui/core/Table';


const useStyles = makeStyles(theme => ({
    // wraps the whole table
    tableWrapper: {
        overflowX: 'auto', // responsive scrollbar at the bottom
        // get rid of last bottom border of content (because Card have one)
        '& table tbody tr:last-child td': {
            borderBottom: '0'
        }
    },
    table: {
        tableLayout: 'fixed',
        minWidth: (props) => props.size ? `${props.size * 180}px` : '720px',
        '& th': {
            width: '100%',
            minWidth: '180px'
        }
    },
    sortable: {
        borderCollapse: 'separate' // separate border for visibility during drag
    }
}));

/**
 * Extends Table component to change style
 */
export default function Table(props) {
    // Split some stuff from props
    const {size, sortable, _classes, className, ...rest_of_props} = props;
    const classes = useStyles({size: size});
    // Merge classes from props and our custom
    const {root, wrapper, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.table, root, className, sortable ? classes.sortable : ''
    ].filter(Boolean).join(' ');
    const wrapperClasses = [
        classes.tableWrapper, wrapper
    ].filter(Boolean).join(' ');

    return <div className={wrapperClasses}>
        <MUITable
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props} />
    </div>;
}
