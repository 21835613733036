import {List as ImmutableList} from 'immutable';
import {Company} from 'lib/models';


/**
 * Selects company from redux state
 * @param {object} state - redux state
 * @param {string} search - search string 
 * @param {string} pathname - pathname 
 * @returns 
 */
export function companySelector(state, search, pathname) {
    const searchParams = new URLSearchParams(search);
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));
    const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company_identifier = searchParams.get('company')
        // ensure we don't create duplicate 'my' collection with UUID
        ? !pathname.startsWith('/all-') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
            ? 'my' : searchParams.get('company')
        : pathname.startsWith('/all-') ? 'all' : 'my';

    return {
        company,
        companies,
        company_identifier,
        company_loaded: !!state.shared.getIn(['loaded', company_identifier === 'all' ? 'companies' : 'my-companies']),
        selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company'))
    };
}
