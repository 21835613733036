import Client from 'lib/client';
import {constants} from 'reducers/api';
import {FeatureFlag} from 'lib/models';
// actions
import {handleErrors, getErrorCode} from 'actions/errors';
import {loginFromStore} from 'actions/auth';
import {fetchItems} from 'actions/shared';


/**
 * Simple action creator to modify api state
 *
 * @param {string|null} state - e.g. 'ready', 'fetching', 'error'
 */
export function setState(state) {
    return {
        type: constants.API_STATE,
        state
    };
}

/**
 * Simple action creator to add Client to store
 *
 * @param client - API Client (client.js)
 * @param server - 'server' header with information about version
 */
function setClient(client, server) {
    return {
        type: constants.API_CLIENT,
        client,
        server
    };
}

/**
 * Update Client links map
 *
 * @param root - path of placement e.g. 'tables'
 * @param payload - links in the path
 */
export function updateClientLinks(root, payload) {
    return {
        type: constants.API_UPDATE_LINKS,
        root,
        payload
    };
}

/**
 * Simple action creator to set api state to error
 *
 * @param {string|null} error_identifier - name of method where request failed
 * @param {int|null} error_code - response code e.g. 'unknown', 500, 403, etc.
 * @param {string|null} error_message - technical message what happen (error.message)
 */
export function setErrorState(error_identifier, error_code, error_message) {
    return {
        type: constants.API_ERROR_STATE,
        error_identifier,
        error_code,
        error_message
    };
}

/**
 * Initializes the API by obtaining the root
 */
export function initializeApi(apiRoot) {
    return (dispatch, getState) => {
        dispatch(setState('fetching'));

        const client = new Client(apiRoot);
        return client.initClient().then((result) => {
            dispatch(setClient(client, result.headers.server));
            // fetch feature flags
            return dispatch(fetchItems(FeatureFlag, new FeatureFlag().getPlacement(), 'feature-flags', null, null, {affect_state: false}));
        })
        .then(() => dispatch(loginFromStore())) // try to login user from store
        .then(() => dispatch(setState('ready')))
        .catch((error) => {
            const error_code = getErrorCode(error);
            if (![504, 502, 503].includes(error_code)) {
                dispatch(setState('error'));
            }
            return handleErrors('initializeApi', dispatch, getState, error, error_code);
        });
    };
}
