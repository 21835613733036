import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {User, Company, Role} from 'lib/models';
import {useHistory, useLocation} from 'react-router';
import {useLocalSort} from 'lib/filters';
import {Map as ImmutableMap, List as ImmutableList} from 'immutable';
// Components
import {Link} from 'react-router-dom';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


/**
 * Renders Users which belongs to Company
 * passed_props.item_company is provided via parent in companies/Detail.js
 */
export default function DetailUsers(passed_props) {
    const {item_company} = passed_props;
    // define table columns with sorting
    const tableHead = [
        {sortName: 'username', label: <FormattedMessage id='companies.detail.users.table.username' />, reduced: true},
        {sortName: 'first_name', label: <FormattedMessage id='companies.detail.users.table.name' />},
        {sortName: 'email', label: <FormattedMessage id='companies.detail.users.table.email' />},
        {sortName: 'created_at', label: <FormattedMessage id='companies.detail.users.table.created_at' />, reduced: true},
        {sortName: 'role', label: <FormattedMessage id='companies.detail.users.table.role' />, reduced: true}
    ];
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    // redux store
    const props = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

        const selectedCompanyIdentifier = !item_company ? undefined : item_company.get(new Company().getUniqueIdentifier()) === company.get(new Company().getUniqueIdentifier())
            ? 'my' : item_company.get(new Company().getUniqueIdentifier());
        const items_placement = item_company ? `companyUsers-${item_company.get(new Company().getUniqueIdentifier())}` : 'companyUsers-unknown';

        return {
            items_placement: items_placement,
            portal: pathname.startsWith('/partner'),
            state: state.app.get('state'),
            selectedCompanyIdentifier: selectedCompanyIdentifier,
            stateIdentifier: state.app.get('identifier'),
            loaded: !!state.shared.getIn(['items', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            user: auth_user,
            roles_loaded: state.shared.getIn(['loaded', 'roles']),
            roles_items: state.shared.getIn(['items', 'roles']),
            permissions: state.auth.get('permissions')
        };
    }, shallowEqual);
    // sorting
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded || !props.roles_loaded, props.items_placement, [...tableHead.map(item => item.sortName), 'last_name'],
        'username', 'desc', {
            role: (obj) => (props.roles_items.find(el => el.get(new Role().getUniqueIdentifier()) === obj.get('role')) || ImmutableMap({name: ''})).get('name')
        });

    return <Card>
        <CardHeader title={<FormattedMessage id='companies.detail.users.title' />}
                    subheader={<FormattedMessage id='companies.detail.users.subheader' />}
                    action={<ActionButton iconButton disabled>
                        <ModelIcon model='users' />
                    </ActionButton>} />
        <Table size={tableHead.length}>
            <TableHead>
                <TableRow>
                    {tableHead.map((item, idx) => <TableCell key={idx} numeric={item.numeric} reduced={item.reduced}>
                        {item.sortName === 'first_name' && ['first_name', 'last_name'].includes(sortName)
                            ? <React.Fragment>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    <FormattedMessage id='companies.detail.users.table.first_name' />
                                </TableSortLabel>
                                <TableSortLabel active={sortName === 'last_name'}
                                                direction={sortDirection}
                                                onClick={() => sortItems('last_name')}>
                                    <FormattedMessage id='companies.detail.users.table.last_name' />
                                </TableSortLabel>
                            </React.Fragment>
                            : <TableSortLabel active={sortName === item.sortName}
                                              direction={sortDirection}
                                              onClick={() => sortItems(item.sortName)}>
                                {item.label}
                            </TableSortLabel>
                        }
                    </TableCell>)}
                </TableRow>
            </TableHead>
            {`fetching_items_companyUsers-${item_company ? item_company.get(new Company().getUniqueIdentifier()) : 'unknown'}` === props.state || sortInitializing
                ? <TableBody>
                <TableRow>
                    <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                </TableRow>
            </TableBody>
                : !props.items.size
                ? <TableBody>
                <TableRow>
                    <TableCell colSpan={tableHead.length}><FormattedMessage id='companies.detail.users.table.empty' /></TableCell>
                </TableRow>
            </TableBody>
                : <TableBody>
                {sortedItems.map((user, idx) => {
                    const onClick = () => props.state === null
                        ? history.push(`${props.portal ? '/partner' : ''}${user.get(new User().getUniqueIdentifier()) === props.user.get(new User().getUniqueIdentifier()) ? '/profile' : `/customers/${user.get(new User().getUniqueIdentifier())}${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`}`)
                        : {};
                    const role = props.roles_items.find(el => el.get(new Role().getUniqueIdentifier()) === user.get('role'));

                    return <TableRow key={idx} hover>
                        <TableCell link onClick={onClick} urlView reduced>
                            {user.get('username')}
                        </TableCell>
                        <TableCell link onClick={onClick}>
                            {sortName === 'first_name'
                                ? `${user.get('first_name')} ${user.get('last_name')}`
                                : `${user.get('last_name')} ${user.get('first_name')}`
                            }
                        </TableCell>
                        <TableCell link onClick={onClick} urlView>
                            {user.get('email')}
                        </TableCell>
                        <TableCell link onClick={onClick} reduced>
                            {Moment(user.get('created_at')).format('l')}
                        </TableCell>
                        <TableCell reduced link={!props.user.isAdmin()} onClick={() => !props.user.isAdmin ? onClick() : {}}>
                            {!props.roles_loaded
                                ? <CircularProgress size={36} />
                                : role
                                    ? props.user.isAdmin()
                                        ? <Link className='hover-border' to={`/permissions/${role.get(new Role().getUniqueIdentifier())}`}>
                                            {role.get('name')}
                                        </Link>
                                        : role.get('name')
                                    : <div />}
                        </TableCell>
                    </TableRow>;
                })}
            </TableBody>}
        </Table>
    </Card>;
}
