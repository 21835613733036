import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation, useRouteMatch} from 'react-router';
import {List as ImmutableList} from 'immutable';
import {makeStyles} from '@material-ui/core/styles';
import {useLocalPagination, useLocalSort} from 'lib/filters';
import {findProductByMac} from 'lib/endpoint';
import {companySelector} from 'lib/company';
import {Paginator, EndpointsImport, EndpointsImportError, ProvisioningProfile, Company, Product} from 'lib/models';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
// Components
import LocalPagination from 'components/core/ui/LocalPagination';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardActions from 'components/core/ui/mui/CardActions';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// vectors
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';


const useStyles = makeStyles(theme => ({
    tableRow: {
        position: 'relative',
        // status border indicator
        '& > *:first-of-type::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${theme.spacing(0.5)}px`,
            height: '100%',
            backgroundColor: theme.palette.orange.main
        }
    },
    // error icon and messages next to each other
    status: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`,
        paddingTop: `${theme.spacing(0.25)}px`,
        paddingBottom: `${theme.spacing(0.25)}px`,
        // highlight icon and error key
        '& svg, & strong': {
            color: theme.palette.error.main
        }
    }
}));

export default function Detail() {
    const intl = useIntl();
    // styles
    const classes = useStyles();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const match = useRouteMatch();
    const targetTaskId = match.params.identifier;
    // redux store
    const dispatch = useDispatch();
    const {items_placement, tasks_placement, ...props} = useSelector(state => {
        const {company_identifier, selectedCompany, company_loaded} = companySelector(state, search, pathname);
        const items_placement = `${new EndpointsImportError().getPlacement()}-${targetTaskId}`;
        const provprofile_placement = `${new ProvisioningProfile().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items_placement,
            task_item: state.shared.getIn(['items', new EndpointsImport().getPlacement()]).find(task => task.get(new EndpointsImport().getUniqueIdentifier()) === targetTaskId),
            tasks_loaded: state.shared.getIn(['loaded', new EndpointsImport().getPlacement()]),
            tasks_placement: new EndpointsImport().getPlacement(),
            state: state.app.get('state'),
            products_items: state.shared.getIn(['items', 'products']),
            products_loaded: state.shared.getIn(['loaded', 'products']),
            provisioning_profiles_loaded: !!state.shared.getIn(['loaded', provprofile_placement]),
            provisioning_profiles_items: state.shared.getIn(['items', provprofile_placement]) || ImmutableList(),
            provisioning_profiles_placement: provprofile_placement,
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            selectedCompany,
            company_identifier,
            company_loaded
        };
    }, shallowEqual);
    // define table columns
    const tableHead = useMemo(() => [
        {sortName: 'mac', label: <FormattedMessage id='endpoints.imports_errors.list.table.header.mac' />, reduced: true},
        {sortName: 'product', label: <FormattedMessage id='endpoints.imports_errors.list.table.header.product' />, reduced: true},
        {label: <FormattedMessage id='endpoints.imports.list.table.header.status' />}
    ], []);
    // local sorting
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(props.items, !props.loaded || !props.products_loaded, items_placement, tableHead.map(item => item.sortName), 'mac', 'asc', {
        product: (obj) => findProductByMac(obj.get('mac'), props.products_items)?.get('name')
    });
    // pagination
    const [paginatedItems, page, pages, setPage] = useLocalPagination(sortedItems);

    // fetch loaded task errors
    useEffect(() => {
        if (!props.loaded && !!props.task_item) {
            dispatch(fetchItems(EndpointsImportError, items_placement, props.task_item.getIn(['links', 'bulk-load-task-errors'])));
        }
    }, [props.loaded, !props.task_item]);
    // fetch related data without affecting state
    useEffect(() => {
        if (!props.provisioning_profiles_loaded && props.selectedCompany?.getIn(['links', 'provisioning-profiles'])) {
            dispatch(fetchItems(ProvisioningProfile, props.provisioning_profiles_placement, props.selectedCompany.getIn(['links', 'provisioning-profiles']), null, null, {affect_state: false}));
        }
    }, [props.provisioning_profiles_loaded, props.provisioning_profiles_placement, props.selectedCompany?.getIn(['links', 'provisioning-profiles'])]);
    useEffect(() => {
        if (props.products_loaded === false) {
            dispatch(fetchItems(Product, 'products', 'products', null, null, {affect_state: false}));
        }
    }, [props.products_loaded]);
    useEffect(() => {
        if (!props.tasks_loaded && props.selectedCompany?.getIn(['links', 'endpoint-bulk-load-tasks'])) {
            dispatch(fetchItems(EndpointsImport, tasks_placement, props.selectedCompany.getIn(['links', 'endpoint-bulk-load-tasks']), null, null, {affect_state: false}));
        }
    }, [props.selectedCompany?.getIn(['links', 'endpoint-bulk-load-tasks']), props.tasks_loaded, tasks_placement]);
    useEffect(() => {
        if (props.company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, 'my-companies', ['companies', props.company_identifier], {success_affect_state: false, ignore_403: true}));
        }
    }, [props.company_identifier, props.selectedCompany]);

    return <Card>
        <CardHeader title={<FormattedMessage id='endpoints.imports_errors.list.title' values={{filename: '' /* add value when we have implemented filename */}} />}
            subheader={<FormattedMessage
                id='endpoints.imports_errors.list.subheader'
                values={{provprofile: !props.provisioning_profiles_loaded ? '' : !props.task_item.get('provisioning_profile') ? intl.formatMessage({id: 'filters.none'}) : props.provisioning_profiles_items.find(el => el.get(new ProvisioningProfile().getUniqueIdentifier()) === props.task_item.get('provisioning_profile'))?.name || ''}}
            />}
        />
        <CardContent>
            <Typography color='error'>
                <FormattedMessage id='endpoints.imports_errors.list.errors' values={{amount: props.items.size || '-'}} />
            </Typography>
        </CardContent>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric} reduced={!!item.reduced}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName} direction={sortDirection} onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || sortInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'endpoints.imports_errors.list.table.empty'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && paginatedItems.map((item) => {
                                return <TableRow key={item.get('mac')} hover className={classes.tableRow}>
                                    <TableCell reduced>{item.get('mac')}</TableCell>
                                    <TableCell reduced>{findProductByMac(item.get('mac'), props.products_items)?.get('name')}</TableCell>
                                    <TableCell>
                                        <div className={classes.status}>
                                            <ErrorIcon />
                                            <div>
                                                {item.get('details')?.entrySeq().map(([error_key, errors]) => <div key={error_key}>
                                                    <strong>{`${error_key}: `}</strong>{Array.isArray(errors) ? errors.join(', ') : errors}
                                                </div>)}
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <LocalPagination page={page} pages={pages}
                                 onChange={(page) => setPage(page)} />
            </Table>} />
        <CardActions>
            <Button disabled={props.state !== null}
                    onClick={() => history.push('/phones#imports')}>
                <CancelIcon />
                <FormattedMessage id='actions.cancel' />
            </Button>
        </CardActions>
    </Card>;
}
