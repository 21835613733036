import React, {useState} from 'react';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {snomRGBAtoHex} from 'lib/util';
import {Map as ImmutableMap} from 'immutable';
// Components
import Form from 'components/core/ui/Form';
import Field, {SettingField, validateSettingsField, getSettingInitialValue} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import ChipDeleteIcon from '@material-ui/icons/Cancel';


export const indexedSettingStylesFunction = theme => ({
    // select dialog menu
    selectMenu: {
        // we use modal, so just hide it
        display: 'none'
    },
    // selected chips in select
    chipsHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        // to sides
        margin: `-${theme.spacing(0.75)}px -${theme.spacing(0.25)}px`
    },
    chip: {
        margin: `${theme.spacing(0.25)}px`,
        // limit size
        maxWidth: '100%',
        height: '27px'
    },
    chipLabel: {
        display: 'block',
        // don't overflow
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '& span, & svg': {
            // next to each other
            display: 'inline-flex',
            verticalAlign: 'middle',
            // space between
            marginRight: `${theme.spacing(0.25)}px`,
            '&:last-child': {
                marginRight: '0'
            }
        }
    },
    // indexed dialog
    dialog: {
        // increase size for nice fit of two buttons (+ scrollbar)
        width: '720px',
        maxWidth: '100%'
    }
});

const useStyles = makeStyles(indexedSettingStylesFunction);

/**
 * Add/Edit Form for Dialog to manage IDx
 */
function IDXDialogContentComponent(props) {
    // Select either fkey or context key, depending on which is selected for this dialog.
    const activeKeys = props.fkeys.size ? props.fkeys : props.context_keys;
    const activeKeysString = props.fkeys.size ? 'fkey' : 'context_key';
    const hasNoActiveKeys = props.fkeys.size === 0 && props.context_keys.size === 0;

    return <React.Fragment>
        <DialogTitle>
            <FormattedMessage id={props.editIDX
                ? 'settings.detail.indexed.idxdialog.title.edit'
                : 'settings.detail.indexed.idxdialog.title.add'} />
        </DialogTitle>
        <DialogContent>
            <Form onSubmit={props.handleSubmit}>
                <Field name='idx' fieldType='TextField' type='number' label={`${props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.form.fields.idx'})}*`}
                       helperText={<FormattedMessage id='settings.detail.indexed.idxdialog.form.fields.idx.help'
                                                     values={{
                                                         min: props.setting.getIn(['indexed', 'start']),
                                                         max: props.setting.getIn(['indexed', 'max_size']) === 0
                                                             ? props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.form.fields.idx.help.max.unlimited'})
                                                             : props.setting.getIn(['indexed', 'max_size'])
                                                     }} />} />
                {props.setting.getIn(['indexed', 'nested', 'enabled']) && <Field
                    name='idy' fieldType='TextField' type='number' label={`${props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.form.fields.idy'})}*`}
                    helperText={<FormattedMessage id='settings.detail.indexed.idxdialog.form.fields.idx.help'
                                                  values={{
                                                      min: props.setting.getIn(['indexed', 'nested', 'start']),
                                                      max: props.setting.getIn(['indexed', 'nested', 'max_size']) === 0
                                                          ? props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.form.fields.idx.help.max.unlimited'})
                                                          : props.setting.getIn(['indexed', 'nested', 'max_size'])
                                                  }} />} />}
            </Form>
            <SpaceDivider />
            {props.formValues && <React.Fragment>
                <Form onSubmit={props.handleSubmit}>
                    <SettingField
                        indexed_inside={true}
                        label={props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.form.fields.value'})}
                        setting={props.setting}
                        change={props.change}
                        selectedValue={props.formValues.value}
                    />
                </Form>
                {((props.setting.get('fkey') && props.fkeys) || (props.setting.get('context_key') && props.context_keys)) && <React.Fragment>
                    <SpaceDivider double />
                    <Typography variant='h6'><FormattedMessage id={`settings.detail.indexed.idxdialog.${activeKeysString}`} /></Typography>
                    <SpaceDivider />
                    {hasNoActiveKeys && props.namePrefix === 'field_preview'
                        ? <FormattedMessage id={`settings.detail.indexed.idxdialog.${activeKeysString}.preview`} />
                        : <Form onSubmit={props.handleSubmit}>
                            {activeKeys.map((attr, idx) => <React.Fragment key={idx}>
                                <SettingField
                                    namePrefix='attrs'
                                    name={attr.get('attr_name')}
                                    label={attr.get('name')}
                                    setting={attr} required={attr.get('required')}
                                    change={props.change}
                                    selectedValue={new ImmutableMap(Object.entries(props.formValues).attrs).get(attr.get('attr_name'))}
                                />
                                {!!(idx % 2) && <SpaceDivider />}
                            </React.Fragment>)}
                        </Form>}
                </React.Fragment>}
            </React.Fragment>}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>
                <CancelIcon />
                <FormattedMessage id='actions.cancel' />
            </Button>
            {props.editIDX
                ? <Button variant='contained' color='primary'
                          onClick={props.handleSubmit}>
                    <EditIcon />
                    <FormattedMessage id='actions.edit' />
                </Button>
                : <Button variant='contained' color='primary'
                          onClick={props.handleSubmit}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </Button>}
        </DialogActions>
    </React.Fragment>;
}

const validate = (data, props) => {
    let errors = {attrs: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }
    let nested_attrs = data.attrs ? new ImmutableMap(Object.entries(data.attrs)) : new ImmutableMap(); // nested fix

    // validate value (settingField)
    errors = {...errors, ...validateSettingsField(data, props.setting, props.intl)};

    // validate identity IDX field
    validator.isNotNull(null, errors, 'idx', data.idx);
    if (data.idx) {
        validator.isInt(
            props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.error.idx.range'}),
            errors, 'idx', data.idx,
            {
                allow_leading_zeroes: false,
                min: props.setting.getIn(['indexed', 'start']),
                ...(props.setting.getIn(['indexed', 'max_size']) !== 0
                    ? {max: props.setting.getIn(['indexed', 'max_size'])}
                    : {})
            }
        );
    }
    // validate double index
    if (props.setting.getIn(['indexed', 'nested', 'enabled'])) {
        validator.isNotNull(null, errors, 'idy', data.idy);
        if (data.idy) {
            validator.isInt(
                props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.error.idx.range'}),
                errors, 'idy', data.idy,
                {
                    allow_leading_zeroes: false,
                    min: props.setting.getIn(['indexed', 'nested', 'start']),
                    ...(props.setting.getIn(['indexed', 'nested', 'max_size']) !== 0
                        ? {max: props.setting.getIn(['indexed', 'nested', 'max_size'])}
                        : {})
                }
            );
        }
        // validate unique together
        validator.isUnique(
            props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.error.idx.unique'}),
            errors, 'idy', `idx${data.idx}_idy${data.idy}`,
            Object.keys(props.selectedValue || {}).filter(el => el !== props.editIDX), null);
    } else {
        // validate unique
        validator.isUnique(
            props.intl.formatMessage({id: 'settings.detail.indexed.idxdialog.error.idx.unique'}),
            errors, 'idx', `idx${data.idx}`,
            Object.keys(props.selectedValue || {}).filter(el => el !== props.editIDX), null);
    }

    function validateAttrs(key) {
        if (props.setting.get(key) === true && props[key]) {
            props[key].forEach((attr) => {
                let settingFieldErrors = validateSettingsField(
                    nested_attrs.get(attr.get('attr_name'))
                        ? {value: nested_attrs.get(attr.get('attr_name'))}
                        : {},
                    attr, props.intl, attr.get('required')
                );
                if (settingFieldErrors.value) {
                    errors['attrs'][attr.get('attr_name')] = settingFieldErrors.value;
                }
            });
        }
    }

    validateAttrs('fkey');
    validateAttrs('context_key');

    return errors;
};

const IDXDialogForm = reduxForm({
    form: 'indexedFieldForm',
    validate,
    enableReinitialize: true,
    touchOnChange: true, // necessary to have correct validation for SettingFields
    onSubmit: (values, dispatch, props) => {
        // split idx & idy from values
        let {idx, idy, ...rest_of_values} = props.formValues;
        // get Select values from props, copy object to not immediately modify it's reference
        let selectValues = props.selectedValue ? JSON.parse(JSON.stringify(props.selectedValue)) : {};
        // ensure to remove previous values in case we are editing
        if (props.editIDX) {
            delete selectValues[props.editIDX];
        }
        // add new/updated record (with IDX prefix)
        if (props.setting.getIn(['indexed', 'nested', 'enabled'])) {
            selectValues[`idx${idx}_idy${idy}`] = {...rest_of_values};
        } else {
            selectValues[`idx${idx}`] = {...rest_of_values};
        }
        // change value in select
        props.changeIndexes(selectValues);
        // close dialog
        props.handleClose();
    }
})(IDXDialogContentComponent);

const ConnectedIDXDialogForm = connect((state, props) => {
    const settingInitialValues = getSettingInitialValue(props.setting);
    // make sure we have default values for fkeys or context_keys
    const attrs = props.setting.get('attrs');
    const hasFkey = props.setting.get('fkey');
    const hasContextKey = props.setting.get('context_key');

    if (attrs && (hasFkey || hasContextKey)) {
        const keyAttrs = {};
        attrs.forEach((attr) => {
            if (attr.get('default_value')) {
                keyAttrs[attr.get('attr_name')] = attr.get('default_value');
            } else if (attr.get('field_type') === 'checkbox') {
                keyAttrs[attr.get('attr_name')] = false;
            }
        });
        settingInitialValues['attrs'] = {...keyAttrs, ...settingInitialValues['attrs']};
    }

    return {
        formValues: getFormValues('indexedFieldForm')(state),
        initialValues: props.editIDX
            ? {idx: props.editIDX.split('_')[0].slice(3), idy: props.editIDX.split('_')[1]?.slice(3), ...props.selectedValue[props.editIDX]}
            : settingInitialValues,
        fkeys: hasFkey ? attrs : [],
        context_keys: hasContextKey ? attrs : []
    };
})(IDXDialogForm);

const IDXDialogContent = injectIntl(ConnectedIDXDialogForm);

/**
 * Indexed Setting Field allows adding multiple values from Setting configuration
 */
export default function IndexedSettingField(props) {
    const {namePrefix, selectedValue, change, setting, disabled: rawDisabled, required, ...rest_of_props} = props;
    const classes = useStyles();
    const {permission, permissions} = useSelector(state => ({
        permissions: state.auth.get('permissions'),
        permission: state.auth.get('permission')
    }), shallowEqual);
    const [idxDialogOpen, setIdxDialogOpen] = useState(false);
    const [editIDX, setEditIDX] = useState(null);

    let disabled = rawDisabled;
    // disable hidden password field
    if ((setting.field_type === 'password' && selectedValue)) {
        // check values
        Object.values(selectedValue).forEach(value => {
            if (value.value === '__hidden__') {
                disabled = true;
            }
        });
    }

    return <React.Fragment>
        <Field fieldType='Select'
               name={namePrefix}
               label={`${setting.get('name')}${required !== false ? '*' : ''}`}
               helperText={setting.get('description')
                   ? typeof setting.get('description') === 'string'
                       ? <span dangerouslySetInnerHTML={{__html: setting.get('description')}} />
                       : setting.get('description')
                   : undefined}
               IconComponent={AddIcon}
               open={idxDialogOpen}
               onOpen={() => {
                   setIdxDialogOpen(true);
                   setEditIDX(null);
               }}
               inputProps={{value: selectedValue && Object.keys(selectedValue).length ? 'filled' : ''}}
               value={selectedValue}
               MenuProps={{className: classes.selectMenu}}
               renderValue={selected => {
                   let chipDisabled = (!rest_of_props.excludePermission && permissions.get(permission) === 'R') || disabled;

                   return <div className={classes.chipsHolder}>
                       {Object.keys(selectedValue || {}).sort().map((idx) => {
                           // render idx value based on field_type
                           let label;
                           switch (setting.get('field_type')) {
                               case 'password':
                                   label = `${[idx]}: ${'*'.repeat(selectedValue[idx].value ? selectedValue[idx].value.toString().length : 4)}`;
                                   break;
                               case 'select':
                                   if (setting.get('multiple')) {
                                       let choices = setting.get('choices') && setting.get('choices').filter(choice => selectedValue[idx].value && selectedValue[idx].value.includes(choice.get('value')));
                                       if (choices && setting.get('sortable')) {
                                           choices = choices.sort((a, b) => selectedValue[idx].value.indexOf(a.get('value')) - selectedValue[idx].value.indexOf(b.get('value')));
                                       }
                                       label = `${[idx]}: ${choices ? choices.map(choice => choice.get('label')).join(', ') : ''}`;
                                   } else {
                                       const choice = setting.get('choices') && setting.get('choices').find(choice => choice.get('value') === selectedValue[idx].value);
                                       label = `${[idx]}: ${choice ? choice.get('label') : ''}`;
                                   }
                                   break;
                               case 'color':
                                   label = <React.Fragment>
                                       <span>{`${[idx]}:`}</span>
                                       <span style={{color: snomRGBAtoHex(selectedValue[idx].value)}}>{(selectedValue[idx].value || '---')}</span>
                                   </React.Fragment>;
                                   break;
                               case 'checkbox':
                                   label = selectedValue[idx].value
                                       ? <React.Fragment>
                                           <span>{`${[idx]}:`}</span>
                                           <CheckIcon color='primary' />
                                       </React.Fragment>
                                       : <React.Fragment>
                                           <span>{`${[idx]}:`}</span>
                                           <UnCheckIcon color='primary' />
                                       </React.Fragment>;
                                   break;
                               default:
                                   label = `${[idx]}: ${selectedValue[idx].value || '---'}`;
                                   break;
                           }

                           return <Chip
                               key={idx}
                               disabled={chipDisabled}
                               classes={{root: classes.chip, label: classes.chipLabel}}
                               label={label}
                               onClick={chipDisabled ? undefined : () => {}} onDelete={() => {}}
                               onMouseDown={chipDisabled ? undefined : (e) => {
                                   e.stopPropagation();
                                   setIdxDialogOpen(true);
                                   setEditIDX(idx);
                               }}
                               deleteIcon={<ChipDeleteIcon onMouseDown={chipDisabled ? undefined : (e) => {
                                   e.stopPropagation();
                                   let selectValues = JSON.parse(JSON.stringify(selectedValue));
                                   delete selectValues[idx];
                                   change(namePrefix, Object.keys(selectValues).length ? selectValues : '');
                               }} />}
                           />;
                       })}
                   </div>;
               }}
               disabled={disabled}
               {...rest_of_props} />
        <Dialog open={idxDialogOpen}
                onClose={() => setIdxDialogOpen(false)}
                classes={{paper: classes.dialog}}>
            {idxDialogOpen && <IDXDialogContent
                handleClose={() => setIdxDialogOpen(false)}
                editIDX={editIDX}
                selectedValue={selectedValue}
                setting={setting}
                namePrefix={namePrefix}
                changeIndexes={(value) => change(namePrefix, value)}
            />}
        </Dialog>
    </React.Fragment>;
}
