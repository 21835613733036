import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
// components
import DMInfoCard from 'components/modules/tickets/dm/DMInfoCard';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// icons
import PortIcon from '@material-ui/icons/WifiOutlined';
import ConnectedIcon from '@material-ui/icons/CloudDoneOutlined';
import NotConnectedIcon from '@material-ui/icons/NotInterestedOutlined';
import SpeedIcon from '@material-ui/icons/SpeedOutlined';


/**
 * Render device manager info about PC and Net port
 */
export default function DMPort(props) {
    const {item, types = ['Net', 'PC']} = props;

    const data = useMemo(() => {
        return types.map(type => {
            const data = item?.getIn(['data', `${type}PortState`]);
            const color = data?.get('value') && (data.get('value').startsWith('connected') ? 'success' : data.get('value').startsWith('not') ? 'grey' : undefined);
            return {
                state: <React.Fragment>{color && (color === 'success' ? <ConnectedIcon className={`text-${color}`} /> : <NotConnectedIcon className={`text-${color}`} />)}{data?.get('value') || '-'}</React.Fragment>,
                value: <React.Fragment><SpeedIcon className={`text-${color === 'success' ? 'primary' : color}`} />{item?.getIn(['data', `${type}PortSpeed`, 'value']) || '-'}</React.Fragment>,
                lastUpdate: data?.get('updated_at')
            };
        });
    }, types.flatMap(type => [
        item.getIn(['data', `${type}PortState`]) && JSON.stringify(item.getIn(['data', `${type}PortState`])),
        item.getIn(['data', `${type}PortSpeed`]) && JSON.stringify(item?.getIn(['data', `${type}PortSpeed`]))
    ]));

    return <DMInfoCard
        title={<FormattedMessage id='devicemanagement.type.portstate' />}
        icon={<PortIcon />} lastUpdate={data[0]?.lastUpdate}
    >
        {({className}) => <React.Fragment>
            {types.map((type, idx) => <React.Fragment key={type}>
                <strong><FormattedMessage id={`devicemanagement.type.${type.toLowerCase()}portstate`} /></strong>
                <SpaceDivider half />
                <div className={className}>{data[idx].state}</div>
                <div className={className}>{data[idx].value}</div>
                {types.length !== idx - 1 && <SpaceDivider double />}
            </React.Fragment>)}
        </React.Fragment>}
    </DMInfoCard>;
}
