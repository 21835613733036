import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import Moment from 'moment';
import {AssetFolder} from 'lib/models';
import {humanFileSize} from 'lib/util';
// components
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
// icons
import FileIcon from 'components/core/vectors/asset_manager/FileIcon';
import GradientFolder from 'components/core/vectors/asset_manager/GradientFolder';


const useStyles = makeStyles((theme) => ({
    tableRow: {
        '& .MuiTableCell-root': {
            color: theme.palette.snomGrey[800],
            padding: `0 ${theme.spacing(1)}px`,
            '&.fileType': {
                minWidth: 0,
                width: `${theme.spacing(20)}px`,
                paddingLeft: `${theme.spacing(8)}px`
            },
            '&.date': {
                minWidth: 0,
                width: `${theme.spacing(12.5)}px`
            }
        }
    }
}));

/**
 * Render asset file list item
 * @param {object} props - props object
 * @param {object} props.asset - folder/file asset item
 * @param {() => void} props.onClick - on click card
 * @param {JSX.Element} props.actionButton - card header action component
 */

export default function AssetFileListItem(props) {
    const {asset, onClick, actionButton} = props;
    const classes = useStyles();
    const isFolder = useMemo(() => asset.getPlacement() === new AssetFolder().getPlacement(), [asset.getPlacement()]);

    return <TableRow className={classes.tableRow} link hover onClick={onClick}>
        <TableCell className='fileType'>{isFolder ? <GradientFolder /> : <FileIcon mimetype={asset.get('mimetype')} />}</TableCell>
        <TableCell name>{asset.get('name')}</TableCell>
        <TableCell>{!isFolder && humanFileSize(asset.get('file_size'))}</TableCell>
        <TableCell>{Moment(asset.get('created_at')).format('l')}</TableCell>
        {actionButton && <TableCell align='right'>
            {actionButton}
        </TableCell>}
    </TableRow>;
}
