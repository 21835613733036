import React, {useMemo} from 'react';
// icons
import EndpointIcon from '@material-ui/icons/PhoneOutlined';
import PhoneLinkIcon from '@material-ui/icons/DeviceHub';
import EndpointsImportIcon from '@material-ui/icons/ImportContactsOutlined';
import TicketIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import ProvisioningProfileIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import TicketGroupIcon from '@material-ui/icons/ListAltOutlined';
import TicketSchedulerIcon from '@material-ui/icons/ScheduleOutlined';
import ProvisioningFileIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import ProvisioningFilesIcon from '@material-ui/icons/PermMediaOutlined';
import ProvisioningFileTypeIcon from '@material-ui/icons/WallpaperOutlined';
import ProvisioningLogsIcon from '@material-ui/icons/ViewHeadlineOutlined';
import ProductDefaultIcon from '@material-ui/icons/PhonelinkSetupOutlined';
import FirmwareIcon from '@material-ui/icons/SystemUpdateOutlined';
import CompanyIcon from '@material-ui/icons/BusinessOutlined';
import CompaniesIcon from '@material-ui/icons/LocationCityOutlined';
import BillingIcon from '@material-ui/icons/AccountBalanceOutlined';
import UserIcon from '@material-ui/icons/PersonOutlined';
import UsersIcon from '@material-ui/icons/PeopleOutlined';
import SubscriptionIcon from '@material-ui/icons/Beenhere';
import ProductGroupIcon from '@material-ui/icons/DevicesOutlined';
import ProductIcon from '@material-ui/icons/SmartphoneOutlined';
import SubmissionProductIcon from '@material-ui/icons/PhoneIphoneOutlined';
import PermissionIcon from '@material-ui/icons/LockOutlined';
import SubscriptionPlanIcon from '@material-ui/icons/LoyaltyOutlined';
import InvoiceIcon from '@material-ui/icons/ReceiptOutlined';
import PaymentMethodIcon from '@material-ui/icons/CreditCardOutlined';
import SettingGroupIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import SettingIcon from '@material-ui/icons/SettingsOutlined';
import InvitationIcon from '@material-ui/icons/AssignmentIndOutlined';
import ActivitiesIcon from '@material-ui/icons/ListOutlined';
import FeatureFlagsIcon from '@material-ui/icons/SettingsInputComponent';
import APIKeysIcon from '@material-ui/icons/VpnKeyOutlined';
import DocumentationIcon from '@material-ui/icons/MenuBookOutlined';
import DistributorIcon from '@material-ui/icons/StoreOutlined';
import PBXPartnerIcon from '@material-ui/icons/RouterOutlined';
import ClaimIcon from '@material-ui/icons/PhoneInTalkOutlined';
import SubmissionIcon from '@material-ui/icons/AssignmentOutlined';
import AssetsIcon from '@material-ui/icons/PhotoLibraryOutlined';
import AssetManagerIcon from '@material-ui/icons/PermMediaOutlined';
import ProjectsIcon from '@material-ui/icons/PostAddOutlined';
import PageIcon from '@material-ui/icons/DescriptionOutlined';
import PagesIcon from '@material-ui/icons/WebOutlined';
import SalesClusterIcon from '@material-ui/icons/PublicOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import AboutIcon from '@material-ui/icons/InfoOutlined';
import FAQIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ServerSettingsIcon from '@material-ui/icons/StorageOutlined';
import StatisticsIcon from '@material-ui/icons/TimelineOutlined';
import PerformanceStatisticsIcon from '@material-ui/icons/DeveloperBoardOutlined';
import UsersStatisticsIcon from '@material-ui/icons/AccountBox';
import DeadLettersIcon from '@material-ui/icons/EmailOutlined';
import DMNotificationsIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ExportsIcon from '@material-ui/icons/GetAppOutlined';
import UnknownIcon from '@material-ui/icons/BubbleChartOutlined';
import VoiceQualityIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import SipIcon from '@material-ui/icons/DialerSipOutlined';
import ReleaseNoteIcon from '@material-ui/icons/UpdateOutlined';


/**
 * Material-ui Icon for specific model or page, e.g. Provisioning Profile
 */
export default function ModelIcon(props) {
    // Split some stuff from props
    const {model, ...rest_of_props} = props;

    const IconComponent = useMemo(() => {
        switch (model) {
            case 'endpoint':
            case 'endpoints':
                return EndpointIcon;
            case 'endpoints_import':
            case 'endpoints_imports':
                return EndpointsImportIcon;
            case 'phonelink':
                return PhoneLinkIcon;
            case 'voice-quality':
                return VoiceQualityIcon;
            case 'sip':
                return SipIcon;
            case 'ticket':
            case 'tickets':
                return TicketIcon;
            case 'provisioning_profile':
            case 'provisioning_profiles':
                return ProvisioningProfileIcon;
            case 'ticket_group':
            case 'ticket_groups':
                return TicketGroupIcon;
            case 'ticket_scheduler':
            case 'ticket_schedulers':
                return TicketSchedulerIcon;
            case 'provisioning_file':
                return ProvisioningFileIcon;
            case 'provisioning_files':
                return ProvisioningFilesIcon;
            case 'provisioning_file_type':
            case 'provisioning_file_types':
                return ProvisioningFileTypeIcon;
            case 'provisioning_log':
            case 'provisioning_logs':
                return ProvisioningLogsIcon;
            case 'product_default':
            case 'product_defaults':
                return ProductDefaultIcon;
            case 'firmware_update':
            case 'firmware_updates':
                return FirmwareIcon;
            case 'company':
                return CompanyIcon;
            case 'billing':
                return BillingIcon;
            case 'companies':
                return CompaniesIcon;
            case 'user':
                return UserIcon;
            case 'users':
                return UsersIcon;
            case 'subscription':
            case 'subscriptions':
                return SubscriptionIcon;
            case 'product_group':
            case 'product_groups':
                return ProductGroupIcon;
            case 'product':
            case 'products':
                return ProductIcon;
            case 'submission_product':
            case 'submission_products':
                return SubmissionProductIcon;
            case 'role':
            case 'roles':
                return PermissionIcon;
            case 'subscription_plan':
            case 'subscription_plans':
                return SubscriptionPlanIcon;
            case 'invoice':
            case 'invoices':
                return InvoiceIcon;
            case 'payment_method':
            case 'payment_methods':
                return PaymentMethodIcon;
            case 'setting_group':
            case 'setting_groups':
                return SettingGroupIcon;
            case 'setting':
            case 'settings':
                return SettingIcon;
            case 'server_settings':
                return ServerSettingsIcon;
            case 'invitation':
            case 'invitations':
                return InvitationIcon;
            case 'export':
            case 'exports':
                return ExportsIcon;
            case 'activity':
            case 'activities':
                return ActivitiesIcon;
            case 'feature_flag':
            case 'feature_flags':
                return FeatureFlagsIcon;
            case 'api_key':
            case 'api_keys':
                return APIKeysIcon;
            case 'documentation':
                return DocumentationIcon;
            case 'distributor':
            case 'distributors':
                return DistributorIcon;
            case 'pbx_partner':
            case 'pbx_partners':
                return PBXPartnerIcon;
            case 'submission':
            case 'submissions':
                return SubmissionIcon;
            case 'claim':
            case 'claims':
                return ClaimIcon;
            case 'asset':
            case 'assets':
                return AssetsIcon;
            case 'asset_manager':
                return AssetManagerIcon;
            case 'project':
            case 'projects':
                return ProjectsIcon;
            case 'page':
                return PageIcon;
            case 'pages':
                return PagesIcon;
            case 'sales_cluster':
            case 'sales_clusters':
                return SalesClusterIcon;
            case 'home':
                return HomeIcon;
            case 'dashboard':
                return DashboardIcon;
            case 'about':
                return AboutIcon;
            case 'faq':
                return FAQIcon;
            case 'statistics':
                return StatisticsIcon;
            case 'statistics_performance':
                return PerformanceStatisticsIcon;
            case 'statistics_users':
                return UsersStatisticsIcon;
            case 'dead_letter':
            case 'dead_letters':
                return DeadLettersIcon;
            case 'dm_notification':
            case 'dm_notifications':
                return DMNotificationsIcon;
            case 'changelog':
            case 'dev_changelog':
            case 'dev_changelogs':
            case 'release_note':
            case 'release_notes':
                return ReleaseNoteIcon;
            default:
                return UnknownIcon;
        }
    }, [model]);

    return <IconComponent {...rest_of_props} />;
}
