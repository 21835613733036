import React, {useEffect, useMemo, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';
import {FormattedMessage, injectIntl} from 'react-intl';
import Moment from 'moment';
import {makeStyles} from '@material-ui/styles';
import {ReleaseNote} from 'lib/models';
import validator from 'lib/valitator';
// actions
import {deleteItem, fetchItems, saveItem} from 'actions/shared';
import {setState} from 'actions/app';
import {portalTheme} from 'theme';
// components
import SpaceDivider from 'components/core/ui/SpaceDivider';
import Form from 'components/core/ui/Form';
import Field, {LanguageField} from 'components/core/ui/Field';
import SortableContainer from 'components/core/ui/Sortable';
import DraggableCard from 'components/core/ui/DraggableCard';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import {Col, Row} from 'components/core/ui/Grid';
import DevChangelogCard from 'components/modules/changelog/DevChangelogCard';
// material-ui
import ThemeProvider from 'components/ThemeProvider';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Button from 'components/core/ui/mui/Button';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Tooltip from 'components/core/ui/mui/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// vectors
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddedIcon from '@material-ui/icons/AddOutlined';
import FixedIcon from '@material-ui/icons/DoneOutlined';
import ChangedIcon from '@material-ui/icons/Edit';
import RemovedIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SaveIcon from '@material-ui/icons/SaveAltOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import DoneStatusIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import CrossIcon from '@material-ui/icons/CloseOutlined';


const DraggableChangelogItem = React.forwardRef((props, ref) => {
    const {type, index, createNextBullet, hasNextSibling, autoFocus, ...rest_of_props} = props;

    return <DraggableCard {...rest_of_props} ref={ref} editable={!props['aria-disabled']}>
        <Field fieldType='TextField' autoFocus={autoFocus} id={`${type}${index}`} name={`${type}.${index}.value`} required className='full nomargin'
            label={<FormattedMessage id='changelogs.detail.form.list.bulletPoint.label' />} helperText=' ' disabled={props['aria-disabled']}
            onKeyDown={(e) => {
                // focus next bullet point or create new one if enter is pressed
                if (e.key === 'Enter') {
                    e.preventDefault();
                    hasNextSibling
                        ? document.querySelector(`#${type}${index + 1}`)?.focus()
                        : createNextBullet();
                // delete bullet point if empty and backspace is pressed
                } else if (e.key === 'Backspace' && e.target.value === '') {
                    e.preventDefault();
                    props.onRemove();
                    document.querySelector(`#${type}${index - 1}`)?.focus();
                }
            }} />
    </DraggableCard>;
});

const useStyles = makeStyles(theme => ({
    row: {
        margin: '0px',
        gap: `${theme.spacing(2)}px`
    },
    devChangelogColumn: {
        position: 'sticky',
        top: `${theme.spacing(2)}px`,
        width: '100%',
        maxWidth: `${theme.spacing(57)}px`,
        maxHeight: `${theme.spacing(100)}px`,
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            maxHeight: 'unset'
        }
    },
    languageField: {
        // hide language field till supporting multi language
        display: 'none'
    },
    accordion: {
        overflow: 'hidden',
        width: '100%',
        marginBottom: `${theme.spacing(3)}px`,
        boxShadow: `0px 0px 0px 0px transparent, 0px 0px 0px 1px ${theme.palette.snomGrey[400]} inset`,
        borderRadius: `${theme.shape.borderRadius}px`,
        // set same transition as mui's accordion
        '&, & $changelogListStatus': {
            transition: theme.transitions.create(['opacity', 'box-shadow'], {
                duration: theme.transitions.duration.shortest,
                easing: theme.transitions.easing.easeInOut
            })
        },
        // hide line between accordions
        '&:before': {
            display: 'none'
        },
        // restyle expanded accordion
        '&.Mui-expanded': {
            marginBottom: `${theme.spacing(3)}px`,
            boxShadow: `${theme.softShadow}, 0px 0px 0px 1px ${portalTheme.palette.snomGrey[200]} inset`,
            '& .MuiAccordionSummary-root': {
                borderColor: theme.palette.snomGrey[400],
                minHeight: `${theme.spacing(6.5)}px`,
                '& .MuiAccordionSummary-content': {
                    margin: '0'
                }
            },
            // hide status on expanded accordion
            '& $changelogListStatus': {
                opacity: '0'
            }
        },
        '& .MuiAccordionSummary-root': {
            minHeight: `${theme.spacing(6.5)}px`,
            borderBottom: '1px solid transparent',
            '&.added': {
                color: theme.palette.primary.main
            },
            '&.fixed': {
                color: theme.graphs[4]
            },
            '&.changed': {
                color: theme.palette.orange.main
            },
            '&.removed': {
                color: theme.palette.snomGrey.dark
            },
            '&.deprecated': {
                color: theme.palette.snomGrey[400]
            },
            '& .MuiAccordionSummary-content': {
                margin: '0',
                alignItems: 'center',
                '&>.MuiSvgIcon-root': {
                    marginRight: `${theme.spacing(2)}px`
                }
            },
            '& .MuiAccordionSummary-expandIcon': {
                color: theme.palette.snomGrey[400]
            }
        },
        '& .MuiAccordionDetails-root': {
            padding: theme.spacing(3, 2, 2),
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            rowGap: `${theme.spacing(1)}px`
        }
    },
    changelogListStatus: {
        marginLeft: 'auto',
        opacity: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: `${theme.spacing(1)}px`,
        color: theme.palette.snomGrey[400],
        '&.complete': {
            color: theme.palette.text.primary,
            '& .MuiSvgIcon-root': {
                color: theme.palette.success.main
            },
            '&.incomplete .MuiSvgIcon-root': {
                color: theme.palette.error.main
            }
        }
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.softShadow,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`,
        padding: theme.spacing(1.75, 1),
        '& .MuiTooltip-arrow': {
            fontSize: `${theme.spacing(4.5)}px`,
            color: theme.palette.background.paper
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.snomGrey[800]
        },
        '& .closeButton': {
            alignSelf: 'flex-start'
        }
    }
}));

/**
 * Render changelog detail view
 * @param {object} props - props object
 */
function Detail(props) {
    const {intl, permission, state, edit, formValues, initialValues, item, items_loaded, items_placement,
        fetchItems, errors, changelogTypes} = props;
    const noPermission = permission !== 'RW';

    const classes = useStyles();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [expandedTypeList, setExpandedTypeList] = useState([]);
    const [openDateTooltip, setOpenDateTooltip] = useState(false);

    // fetch release notes
    useEffect(() => {
        if (!items_loaded) {
            fetchItems(ReleaseNote, items_placement, 'release-notes');
        }
    }, [items_loaded]);
    // set initial expanded accordions
    useEffect(() => {
        if (initialValues) {
            setExpandedTypeList(changelogTypes.filter(type => !!initialValues[type]?.length));
        }
    }, [JSON.stringify(initialValues)]);

    // show warning message on set future date
    useEffect(() => {
        setOpenDateTooltip(!noPermission && Moment(formValues.created_at).isAfter());
    }, [Moment(formValues.created_at).isAfter(), noPermission]);

    const changelogListIcons = useMemo(() => ({
        added: <AddedIcon />, fixed: <FixedIcon />, changed: <ChangedIcon />,
        removed: <RemovedIcon />, deprecated: <CrossIcon />
    }), []);

    return <div>
        {!items_loaded
            ? <Card>
                <CardHeader title={intl.formatMessage({id: 'changelogs.detail.title'})}
                        action={<ActionButton iconButton disabled><ModelIcon model='release_notes' /></ActionButton>} />
                <CardContent><LinearProgress /></CardContent>
            </Card>
            : edit && !item ? <ErrorCard icon={<WarningIcon color='secondary' />} title={intl.formatMessage({id: 'changelogs.detail.notfound.title'})}
                text={intl.formatMessage({id: 'changelogs.detail.notfound.text'})}/>
                : <React.Fragment>
                    <Row className={classes.row} nospace>
                        <Card component={Col} maxWidth='100%' minWidth='50%'>
                            <CardHeader title={intl.formatMessage({id: `changelogs.detail${noPermission ? '' : edit ? '.edit' : '.add'}.title`})}
                                subheader={noPermission ? '' : intl.formatMessage({id: `changelogs.detail.${edit ? 'edit' : 'add'}.subtitle`})} />
                            <CardContent>
                                <Form onSubmit={props.handleSubmit}>
                                    <Field disabled={noPermission} name='version' required fieldType='TextField'
                                            label={intl.formatMessage({id: 'changelogs.detail.form.version.label'})}
                                            helperText={intl.formatMessage({id: 'changelogs.detail.form.version.helperText'})} />
                                    <Tooltip open={openDateTooltip} classes={{tooltip: classes.tooltip}} placement='top' arrow interactive
                                            title={<React.Fragment>
                                                <WarningIcon />
                                                <Typography variant='body2'><FormattedMessage id='changelogs.detail.form.created_at.tooltip'/></Typography>
                                                <IconButton className='closeButton' edge='end' size='small' onClick={() => setOpenDateTooltip(false)}>
                                                    <CrossIcon />
                                                </IconButton>
                                            </React.Fragment>}>
                                        <Field disabled={noPermission} name='created_at' required time={false} fieldType='DateField'
                                                label={intl.formatMessage({id: 'changelogs.detail.form.created_at.label'})}
                                                helperText={intl.formatMessage({id: 'changelogs.detail.form.created_at.helperText'})} />
                                    </Tooltip>
                                    <Field disabled={noPermission} name='type' required fieldType='Select'
                                            label={intl.formatMessage({id: 'changelogs.detail.form.type.label'})}
                                            helperText={intl.formatMessage({id: 'changelogs.detail.form.type.helperText'})}>
                                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                                        {['release', 'regular_update', 'special_update'].map((item, index) =>
                                            <MenuItem key={index} value={item}>
                                                <FormattedMessage id={`changelog.type.${item}`} />
                                            </MenuItem>)}
                                    </Field>
                                    {/** enable and unhide when language will be supported */}
                                    <LanguageField required disabled name='language' className={classes.languageField} />
                                    <Field disabled={noPermission} name='description' fullWidth className='full' multiline fieldType='TextField'
                                        label={intl.formatMessage({id: 'changelogs.detail.form.description.label'})}
                                        helperText={intl.formatMessage({id: 'changelogs.detail.form.description.helperText'})} />
                                    <SpaceDivider />
                                    <Box width='100%'>
                                        {changelogTypes.map((type) => {
                                            const isCompleteStatus = !!formValues[type].length;
                                            const isIncomplete = !!errors?.[type]?.some(item => item?.value);
                                            const expanded = expandedTypeList.includes(type);
                                            const addBullet = () => props.array.push(type, {value: '', keepMounted: true, autoFocus: true});

                                            return noPermission && !formValues[type]?.length ? null :
                                                <Accordion key={type} square className={`${classes.accordion}`} expanded={expanded}
                                                        onChange={(_, value) => setExpandedTypeList(value ? [...expandedTypeList, type] : expandedTypeList.filter((_type) => type !== _type))}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={type}>
                                                        {changelogListIcons[type]}
                                                        <Typography><FormattedMessage id={`changelog.list.${type}.title`} /></Typography>
                                                        {noPermission ? null : <Tooltip classes={{tooltip: classes.tooltip}} disabled={expanded || isCompleteStatus} placement='top' arrow
                                                            title={<React.Fragment>
                                                                <HelpIcon />
                                                                <Typography variant='body2'><FormattedMessage id='changelogs.detail.form.list.empty.tooltip'/></Typography>
                                                            </React.Fragment>}>
                                                            <div className={`${classes.changelogListStatus} ${isCompleteStatus ? 'complete' : ''} ${isIncomplete ? 'incomplete' : ''}`}>
                                                                {isIncomplete ? <WarningIcon /> : isCompleteStatus ? <DoneStatusIcon /> : <ChangedIcon/>}
                                                                <Typography>
                                                                    <FormattedMessage id={`changelogs.detail.form.list.${isIncomplete ? 'incomplete' : isCompleteStatus ? 'complete' : 'empty'}.title`} />
                                                                </Typography>
                                                            </div>
                                                        </Tooltip>}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <SortableContainer disabled={noPermission} items={formValues[type].map((_, index) => index + 1)} Item={DraggableChangelogItem}
                                                            getItemProps={(sortableId) => {
                                                                const index = sortableId - 1;

                                                                return ({
                                                                    type, index, createNextBullet: addBullet, hasNextSibling: !!formValues[type][index + 1],
                                                                    onRemove: () => props.array.remove(type, index), autoFocus: formValues[type][index]?.autoFocus
                                                                });
                                                            }}
                                                            onDragEnd={({active, over}) => props.array.move(type, active.id - 1, over.id - 1)} />
                                                        {noPermission ? null
                                                            : <Button variant='outlined' color='primary' startIcon={<AddIcon />} onClick={addBullet}>
                                                                <FormattedMessage id='changelogs.detail.form.list.addBulletPoint' />
                                                            </Button>}
                                                    </AccordionDetails>
                                                </Accordion>;
                                        })}
                                    </Box>
                                </Form>
                                <SpaceDivider />
                                <ThemeProvider alt>
                                    {['fetching_items_release_notes', 'saved_item_release_notes', 'failed_save_item_release_notes'].includes(state)
                                        ? <CardActionsLoader success={['saved_item_release_notes'].includes(state)}
                                            failure={['failed_save_item_release_notes'].includes(state)} postAnimation={() => props.setState(null)} />
                                        : <CardActions>
                                            <Button startIcon={<CancelIcon />} onClick={() => props.history.push('/all-changelogs')}>
                                                <FormattedMessage id='actions.cancel' />
                                            </Button>
                                            {noPermission ? null : <React.Fragment>
                                                {edit && <Button variant='contained' color='secondary' startIcon={<CrossIcon />} onClick={() => setOpenDeleteDialog(true)}>
                                                    <FormattedMessage id='actions.delete' />
                                                </Button>}
                                                <Button startIcon={<SaveIcon />} variant='contained' color='primary' type='submit' onClick={() => props.handleSubmit()}>
                                                    <FormattedMessage id='actions.save' />
                                                </Button>
                                                <DeleteDialog open={openDeleteDialog} handleClose={() => setOpenDeleteDialog(false)}
                                                    item={intl.formatMessage({id: 'changelogs.detail.delete.item'})}
                                                    handleConfirm={() => props.deleteItem(ReleaseNote, items_placement, item.getIn(['links', 'self']), item)
                                                                            .then(() => props.history.push('/all-changelogs'))}
                                                />
                                            </React.Fragment>}
                                        </CardActions>}
                                </ThemeProvider>
                            </CardContent>
                        </Card>
                        <DevChangelogCard component={Col} className={classes.devChangelogColumn} />
                    </Row>
                </React.Fragment>}
    </div>;
}

const validate = (values, props) => {
    const errors = {};

    validator.isNotNull(null, errors, 'version', values.version);
    validator.isNotNull(null, errors, 'created_at', values.created_at);
    validator.isNotNull(null, errors, 'type', values.type);
    validator.isNotNull(null, errors, 'language', values.language);
    // get props.items without props.item for unique validation
    const items_without_self = props.item ? props.items.filter(el => el.get('version') !== props.item.get('version') && el.get('language') !== props.item.get('language')) : props.items;
    validator.isUniqueTogether(
        props.intl.formatMessage({id: 'changelogs.detail.error.identifier'}),
        errors, 'version', [values.version, values.language].join(';'),
        items_without_self, ['version', 'language']);

    props.changelogTypes.forEach((listType) => {
        if (values[listType]?.length) {
            errors[listType] = [];

            values[listType].forEach((item, index) => {
                errors[listType][index] = {};
                validator.isNotNull(null, errors, `${listType}.${index}.value`, item.value);
            });
        }
    });

    return errors;
};

const DetailForm = reduxForm({
    form: 'changelogForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        const {edit, items_placement, item, changelogTypes} = props;

        const data = new ReleaseNote().popUpFields(values);
        // set changelog list values back to array of strings
        changelogTypes.forEach((listType) => {
            if (values[listType]?.length) {
                data[listType] = values[listType].map(item => item.value);
            }
        });

        return props.saveItem(ReleaseNote, items_placement, edit ? item.getIn(['links', 'self']) : 'release-notes', data, item, {update_method: 'put', add_mark_filtered: false}).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new ReleaseNote().getUniqueIdentifier())) {
                props.history.push(`/all-changelogs/${create_data.get(new ReleaseNote().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

export default withRouter(injectIntl(connect((state, props) => {
    const  {match} = props;
    const edit = !!match.params.identifier;
    const items_placement = new ReleaseNote().getPlacement();
    const items_identifier = new ReleaseNote().getUniqueIdentifier();
    const changelogTypes = new ReleaseNote().getChangelogTypes();
    const items = state.shared.getIn(['items', items_placement]);
    const item = items.find((item) => item.get(items_identifier) === match.params.identifier);

    let initialValues;
    if (edit) {
        if (item) {
            initialValues = item.toJS();
            // Convert list of strings to list of objects with value property to keep empty string values in redux form
            changelogTypes.forEach((listType) => {
                if (initialValues[listType]?.length) {
                    initialValues[listType] = initialValues[listType].map(item => ({value: item, keepMounted: true}));
                }
            });
        }
    } else {
        initialValues = {type: 'regular_update', language: 'en'};
    }

    return {
        changelogTypes, state: state.app.get('state'), items_placement, edit, initialValues,
        items, item, items_loaded: state.shared.getIn(['loaded', items_placement]),
        errors: state.form.changelogForm?.syncErrors,
        formValues: {
            added: state.form.changelogForm?.values?.added || [],
            fixed: state.form.changelogForm?.values?.fixed || [],
            changed: state.form.changelogForm?.values?.changed || [],
            removed: state.form.changelogForm?.values?.removed || [],
            deprecated: state.form.changelogForm?.values?.deprecated || [],
            created_at: state.form.changelogForm?.values?.created_at || ''
        }
    };
}, (dispatch) => bindActionCreators({setState, saveItem, deleteItem, fetchItems}, dispatch))(DetailForm)));
