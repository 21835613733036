import React from 'react';
import {useDebounce} from 'use-debounce';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from 'theme';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import {alpha} from '@material-ui/core/styles/colorManipulator';


const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        color: theme.palette.snomGrey['dark'],
        transition: theme.transitions.create('background',
            {duration: theme.transitions.duration.short}),
        '&.highlight': {
            background: alpha(theme.palette.primary.light, 0.1)
        }
    },
    cardContent: {
        flexGrow: '1'
    },
    cardHeader: {
        // title and icon next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`,
        // icon
        '& > svg': {
            fontSize: `${theme.spacing(3)}px`
        },
        '& .action': {
            marginLeft: 'auto'
        }
    },
    // holder of value (that could be with icon)
    info: {
        // text and icon next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`
    }
}));

/**
 * Render common retrieved data from phone
 */
export default function DMInfoCard(props) {
    const {icon, title, info, action, lastUpdate, children, className} = props;
    const classes = useStyles();

    const [debouncedLastUpdate] = useDebounce(lastUpdate, theme.transitions.duration.standard * 2);

    return <Card className={`${classes.card} ${lastUpdate !== debouncedLastUpdate ? ' highlight' : ''}`}>
        <CardContent className={classes.cardHeader}>
            {icon}
            <strong>{title}</strong>
            {action && <div className='action'>
                {action}
            </div>}
        </CardContent>
        <CardContent className={[classes.cardContent, className].filter(Boolean).join(' ')}>
            {children
                ? children({className: classes.info, info: <RenderInfo info={info} className={classes.info} />})
                : <RenderInfo info={info} className={classes.info} />}
        </CardContent>
    </Card>;
};

/**
 * Renders info values from DMInfoCard
 */
function RenderInfo({info, className}) {
    return info && <div>
        {Array.isArray(info) ? info.map((item, idx) => <div key={idx} className={className}>{item || '-'}</div>) : <div className={className}>{info || '-'}</div>}
    </div>;
}
