import React, {useState, useMemo, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import SortableContainer from 'components/core/ui/Sortable';
import {arrayMove} from '@dnd-kit/sortable';
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
import ImageUpload from 'components/modules/pages/ImageUpload';
import FolderSelectButton from 'components/core/ui/FolderSelectButton';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Tooltip from 'components/core/ui/mui/Tooltip';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import AddIcon from '@material-ui/icons/AddOutlined';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/UndoOutlined';


const useStyles = makeStyles(theme => ({

    componentHeader: {
        // proportion (to sides)
        margin: `-${theme.spacing(1)}px -${theme.spacing(1)}px ${theme.spacing(1)}px`,
        padding: `0 ${theme.spacing(1)}px`,
        // style
        background: theme.palette.grey[100],
        color: theme.palette.text['secondary'],
        // holder of title and X remove button
        '& .title': {
            ...theme.typography.subtitle2,
            minHeight: `${theme.spacing(3.75)}px`, // size of <IconButton size='small' />
            // align title and remove
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        // string identifier
        '& .str': {
            ...theme.typography.caption,
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    },
    componentsCore: {
        // proportion
        position: 'relative',
        zIndex: '5',
        padding: `${theme.spacing(1)}px`,
        marginBottom: `${theme.spacing(0.5)}px`,
        overflow: 'hidden',
        // style
        border: `1px dotted ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        // sorting in process
        '&.dragging': {
            cursor: 'move', // correct mouse
            background: theme.palette.grey[100] // highlight
        },
        // per component style
        '&.core': {
            border: '0',
            margin: '0',
            padding: '0'
        },
        '&.row': {
            // align children cols like <Row /> would do
            '& > .children-holder': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start'
            },
            // responsive behaviour
            [theme.breakpoints.down('sm')]: {
                '&.wrap > .children-holder, &.wrapSwitch > .children-holder': {
                    '& > .col': {
                        flexBasis: '100%',
                        width: '100% !important',
                        maxWidth: '100% !important',
                        minWidth: '100% !important',
                        marginBottom: `${theme.spacing(2)}px`
                    }
                },
                '&.wrapSwitch > .children-holder': {
                    '& > .col': {
                        '&:nth-last-child(2), &.nobmargin': {
                            marginBottom: '0'
                        },
                        '&:last-child': {
                            marginBottom: `${theme.spacing(2)}px`,
                            order: '-1'
                        }
                    }
                }
            },
            // acts like actual <Row /> in simple without extra visible space
            '&.simple': {
                border: '0',
                margin: `0 -${theme.spacing(1)}px`,
                padding: '0',
                '& > $componentHeader': {
                    display: 'none'
                }
            }
        },
        '&.col': {
            // actually resize content like <Col /> would do
            width: (props) => props.width || '100%',
            // acts like actual <Col /> in simple
            '&.simple': {
                border: '0',
                margin: '0',
                padding: `0 ${theme.spacing(1)}px`,
                '& > $componentHeader': {
                    display: 'none'
                }
            },
            '&.dragging': {
                width: '100%'
            }
        },
        '&.spacedivider': {
            // height as SpaceDivider would have
            minHeight: (props) => props.size === 'double' ? `${theme.spacing(4)}px` : `${theme.spacing(2)}px`,
            [theme.breakpoints.down('xs')]: {
                minHeight: (props) => props.size === 'grid' ? `${theme.spacing(1)}px` : props.size === 'double' ? `${theme.spacing(4)}px` : `${theme.spacing(2)}px`
            }
        },
        '&.card': {
            // highlight <Card />
            border: `2px solid ${theme.palette.grey[500]}`,
            background: theme.palette.common.white
        },
        '&.expansionpanel': {
            // highlight <ExpansionPanel />
            border: `2px solid ${theme.palette.grey[500]}`,
            background: theme.palette.common.white
        }
    }
}));

/**
 * Configuration of components used in builder
 */
const componentsConfiguration = [
    {
        type: 'core',
        supportedChildren: ['card', 'spacedivider', 'expansionpanel', 'row']
    },
    {
        type: 'card',
        supportedChildren: ['cardheader', 'cardcontent', 'cardimage', 'cardactions', 'assets'],
        fields: {
            variant: ['normal', 'first', 'last']
        },
        str: 'variant'
    },
    {
        type: 'cardheader',
        supportedChildren: ['actionbutton'],
        fields: {
            title: '',
            subheader: '',
            icon: ['true', 'false']
        },
        str: 'title',
        simpleFields: ['title', 'subheader']
    },
    {
        type: 'cardcontent',
        fields: {
            content: ''
        },
        str: 'content',
        simpleFields: ['content']
    },
    {
        type: 'cardimage',
        fields: {
            image: ''
        },
        str: 'image',
        simpleFields: ['image']
    },
    {
        type: 'cardactions',
        supportedChildren: ['button'],
        fields: {
            center: ['true', 'false']
        }
    },
    {
        type: 'button',
        fields: {
            text: '',
            link: '',
            color: ['primary', 'secondary'],
            variant: ['contained', 'outlined']
        },
        str: 'text',
        simpleFields: ['text', 'link']
    },
    {
        type: 'actionbutton',
        fields: {
            text: '',
            link: '',
            color: ['primary', 'secondary'],
            variant: ['contained', 'outlined']
        },
        str: 'text',
        simpleFields: ['text', 'link']
    },
    {
        type: 'spacedivider',
        fields: {
            size: ['grid', 'normal', 'double']
        }
    },
    {
        type: 'expansionpanel',
        fields: {
            connect: 'mainexpansion',
            title: '',
            subtitle: '',
            content: ''
        },
        str: 'title',
        simpleFields: ['title', 'subtitle', 'content']
    },
    {
        type: 'row',
        supportedChildren: ['col'],
        fields: {
            wrap: ['wrap', 'wrapSwitch', 'nowrap']
        }
    },
    {
        type: 'col',
        supportedChildren: ['card', 'spacedivider', 'expansionpanel'],
        fields: {
            width: ['100%', '50%', '66.6667%', '33.3333%', '25%']
        }
    },
    {
        type: 'assets',
        fields: {
            folder: '',
            variant: ['default', 'flat']
        }
    }
];

/**
 * Select with button to add Components into builder or children
 */
function AddComponent(props) {
    const {component, setData = () => {}, data = [], disabled = false} = props;
    const intl = useIntl();
    const components = useMemo(() => componentsConfiguration.filter(c => component.supportedChildren.includes(c.type)), [component.type]) ;
    const add = useCallback((component, data, setData) => setData({
        ...data, children: [...(data.children || []), {
            type: component.type,
            ...(component.supportedChildren ? {children: []} : {}),
            ...(component.fields ? Object.entries(component.fields).reduce((newObj, [key, values]) => {
                newObj[key] = Array.isArray(values) ? values[0] : values;
                return newObj;
            }, {}) : {})
        }]}), []);
    // local state
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    return <React.Fragment>
        <ActionButton
            color='primary' disabled={disabled}
            onClick={() => setDialogOpen(!dialogOpen)}>
            <AddIcon />
            <FormattedMessage
                id='pages.builder.add.label'
                values={{component_name: intl.formatMessage({id: `pages.builder.component.${component.type}`})}}
            />
        </ActionButton>
        {!disabled && <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullWidth maxWidth='xs'>
            <DialogTitle>
                <FormattedMessage
                    id='pages.builder.add.label'
                    values={{component_name: intl.formatMessage({id: `pages.builder.component.${component.type}`})}}
                />
            </DialogTitle>
            <DialogContent>
                <Form center>
                    <Field
                        fieldType='NoReduxSelect'
                        label={<FormattedMessage id='pages.builder.component' />}
                        renderValue={type => <FormattedMessage id={`pages.builder.component.${type}`} />}
                        value={selectedComponent ? selectedComponent.type : ''}
                        onChange={event => setSelectedComponent(components.find(component => component.type === event.target.value) || null)}>
                        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                        {components.map(component => <MenuItem value={component.type} key={component.type}>
                            <ListItemText
                                primary={<FormattedMessage id={`pages.builder.component.${component.type}`} />}
                                secondary={<FormattedMessage id={`pages.builder.component.${component.type}.help`} />}
                            />
                        </MenuItem>)}
                    </Field>
                </Form>
            </DialogContent>
            <SpaceDivider double />
            <DialogActions>
                <Button onClick={() => setDialogOpen(false)}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button
                    variant='contained' color='primary'
                    disabled={!selectedComponent}
                    onClick={() => {
                        add(selectedComponent, data, setData);
                        setDialogOpen(false);
                    }}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </Button>
            </DialogActions>
        </Dialog>}
    </React.Fragment>;
}

/**
 * Core for Component in builder
 */
const Component = React.forwardRef((props, ref) => {
    const {disabled, structure, data, setData, className, nestedID = 'B', ...rest_of_props} = props;
    const classes = useStyles(data);
    const component = useMemo(() => componentsConfiguration.find(component => component.type === data.type), [data.type]);
    const fields = useMemo(() => {
        return !component.fields ? {} : Object.fromEntries(Object.entries(component.fields).filter(([key]) => {
            const simple = component.simpleFields?.includes(key);
            return structure ? !simple : !!simple;
        }));
    }, [component.type, structure]);
    const sortableChildren = useMemo(() => {
        return data.children ? data.children.map((child, idx) => `${nestedID}-${idx}`) : [];
    }, [JSON.stringify(data.children || [])]);

    return <div
        ref={ref} {...rest_of_props}
        className={[
            classes.componentsCore,
            component.type,
            component.type === 'row' ? data.wrap || '' : '',
            structure ? 'structure' : 'simple',
            className || ''
        ].filter(Boolean).join(' ')}>
        {component.type !== 'core' && <div className={classes.componentHeader}>
            <div className='title'>
                <Tooltip title={<FormattedMessage id={`pages.builder.component.${component.type}.help`} />}>
                    <span><FormattedMessage id={`pages.builder.component.${component.type}`} /></span>
                </Tooltip>
                {structure && <ThemeProvider alt>
                    <Tooltip title={<FormattedMessage id='actions.remove' />} disabled={disabled}>
                        <IconButton color='secondary' size='small'
                                    onClick={() => setData(undefined)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </ThemeProvider>}
            </div>
            {(structure && component.str && data[component.str]) && <div className='str'>{data[component.str]}</div>}
        </div>}
        {!!Object.keys(fields).length && <Form className='nobmargin'>
            {!!Object.keys(fields).length && Object.entries(fields).map(([name, values]) => name === 'folder'
                ? <FolderSelectButton key={name} value={data[name]} onChange={(v) => setData({...data, [name]: v.split('_').pop()})} />
                : <React.Fragment key={name}>
                    {name === 'content' && <SpaceDivider none />}
                    <Field label={`${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                        fieldType={Array.isArray(values) ? 'NoReduxSelect' : 'NoReduxTextField'}
                        multiline={name === 'content'} size={name === 'content' ? 'double' : undefined}
                        value={data[name]} onChange={event => setData({...data, [name]: event.target.value})}>
                        {Array.isArray(values) && values.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
                    </Field>
                    {name === 'image' && <ImageUpload setImage={image => setData({...data, [name]: image})} />}
                </React.Fragment>)}
        </Form>}
        {data.children && <div className='children-holder'>
            <SortableContainer
                vertical={!['row'].includes(component.type)}
                items={sortableChildren}
                Item={Component}
                disabled={!structure || disabled || data.children.length <= 1}
                getItemProps={(nidx) => {
                    const idx = parseInt(nidx.split('-')[nidx.split('-').length - 1], 10);

                    return {
                        data: data.children[idx],
                        nestedID: `${nestedID}-${idx}`,
                        disabled: disabled,
                        structure: structure,
                        setData: (dataToSet) => {
                            const newChildrenData = [...data.children];
                            if (dataToSet) {
                                newChildrenData[idx] = dataToSet;
                            } else {
                                newChildrenData.splice(idx, 1);
                            }
                            setData({...data, children: newChildrenData});
                        }
                    };
                }}
                onDragEnd={({active, over}) => {
                    setData({...data, children: arrayMove(data.children,
                            sortableChildren.findIndex(nidx => nidx === active.id),
                            sortableChildren.findIndex(nidx => nidx === over.id)
                        )});
                }}
                delay
            />
        </div>}
        {(structure && component.supportedChildren) && <AddComponent
            disabled={disabled} component={component} setData={setData} data={data}
        />}
    </div>;
});

/**
 * Page Builder itself
 */
export default function Builder(props) {
    const {disabled = false, structure = false, data: rawData = [], setData = () => {}} = props;
    const data = useMemo(() => rawData, [JSON.stringify(rawData)]);

    return <Component
        disabled={disabled} structure={structure}
        data={{type: 'core', children: data}}
        setData={dataToSet => setData(dataToSet.children)}
    />;
}
