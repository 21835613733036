import React, {useMemo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Moment from 'moment';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {FeatureFlag} from 'lib/models';
import {makeStyles} from '@material-ui/styles';
// components
import Filters from 'components/core/ui/Filters';
import Field, {SearchField} from 'components/core/ui/Field';
import StickyTable from 'components/core/ui/StickyTable';
import FeatureFlagStatusMessage from 'components/modules/featureFlags/FeatureFlagStatusMessage';
// material-ui
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';
import EnabledIcon from '@material-ui/icons/CheckOutlined';
import DisabledIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles((theme) => ({
    status: {
        '& .MuiSvgIcon-root': {
            marginRight: `${theme.spacing(0.5)}px`
        }
    }
}));

/**
 * Renders FeatureFlag from backend - to view and manage them
 * @param {object} props - provided props
 */
export default function List(props) {
    // feature flag is rendered only for admins, and they have RW
    const {permission = 'RW'} = props;
    const intl = useIntl();
    const classes = useStyles();
    // router
    const history = useHistory();
    // redux store
    const items_placement = useMemo(() => new FeatureFlag().getPlacement(), []);
    const items_identifier = useMemo(() => new FeatureFlag().getUniqueIdentifier(), []);
    const {featureFlags, state, loaded} = useSelector((state) => ({
        featureFlags: state.shared.getIn(['items', 'feature-flags']),
        loaded: state.shared.getIn(['loaded', 'feature-flags']),
        state: state.app.get('state')
    }), shallowEqual);
    const loading = useMemo(() => ![null, 'ready'].includes(state) && !loaded, [state, loaded]);
    // define table columns with sorting
    const tableHead = useMemo(() => [
        {sortName: 'name', label: <FormattedMessage id='featureflags.list.table.name' />, reduced: 'm'},
        {sortName: 'created_at', label: <FormattedMessage id='featureflags.list.table.created_at' />, reduced: true},
        {sortName: 'rules', label: <FormattedMessage id='featureflags.list.table.rules' />, reduced: true},
        {sortName: 'enabled', label: <FormattedMessage id='featureflags.list.table.enabled' />}
    ], []);
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        featureFlags, false, items_placement, tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['search', 'enabled']);

    return <Card>
        <CardHeader title={intl.formatMessage({id: 'featureflags.list.title'})}
            subheader={intl.formatMessage({id: 'featureflags.list.subheader'})}
            action={permission === 'RW' && false
                ? <ActionButton onClick={() => history.push('/feature-flags/add')} variant='contained'
                    color='secondary' disabled={state !== null} startIcon={<AddIcon />}>
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='feature_flags' />
                </ActionButton>} />
        <Filters>
            <SearchField label={<FormattedMessage id='featureflags.list.search' />}
                value={filters.search || ''} search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='featureflags.list.filter.enabled' />}
                fieldType='NoReduxSelect' value={filters.enabled || ''}
                onChange={(event) => filterItems('enabled', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'>
                    <ListItemIcon className='icon'><EnabledIcon color='primary' /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='featureflags.list.filter.enabled.choices.enabled' /></ListItemText>
                </MenuItem>
                <MenuItem value='false'>
                    <ListItemIcon className='icon'><DisabledIcon /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='featureflags.list.filter.enabled.choices.disabled' /></ListItemText>
                </MenuItem>
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) => <TableCell reduced={item.reduced || false} key={idx}>
                            <TableSortLabel active={sortName === item.sortName} direction={sortDirection}
                                onClick={() => sortItems(item.sortName)}>
                                {item.label}
                            </TableSortLabel>
                        </TableCell>)}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {loading && filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody> : !filteredItems?.size ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <em>
                                <FormattedMessage id={featureFlags.size ? 'filters.empty' : 'featureflags.list.table.empty'}
                                    values={{link: permission === 'RW'
                                            ? <Link to='/feature-flags/add' className='hover-border'>
                                                <FormattedMessage id='featureflags.list.table.empty.link' />
                                            </Link>
                                            : <FormattedMessage id='featureflags.list.table.empty.link' />}} />
                            </em>
                        </TableCell>
                    </TableRow>
                </TableBody> : <TableBody>
                    {filteredItems.map((featureFlag) =>
                        <TableRow key={featureFlag.get(items_identifier)} link hover
                            onClick={() => state === null ? history.push(`/feature-flags/${featureFlag.get(items_identifier)}`) : {}}>
                            <TableCell reduced='m'>{featureFlag.get('name')}</TableCell>
                            <TableCell reduced>{Moment(featureFlag.get('created_at')).format('l')}</TableCell>
                            <TableCell reduced>{featureFlag.get('rules').map(rule => rule.get('context_key')).join(', ')}</TableCell>
                            <TableCell className={classes.status}>
                                {featureFlag.get('enabled') ? <EnabledIcon color='primary' /> : <DisabledIcon />}
                                <FeatureFlagStatusMessage featureFlag={featureFlag?.toJS()} />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>}/>
    </Card>;
}
