import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUITableHead from '@material-ui/core/TableHead';


const useStyles = makeStyles(theme => ({
    tableHead: {
        background: theme.palette.common.white
    }
}));

/**
 * Basically MUI TableHead with additional style to support react-sticky-el
 */
export default function TableHead(props) {
    // Split some stuff from props
    const {_classes, className, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        root, className, classes.tableHead
    ].filter(Boolean).join(' ');

    return <MUITableHead
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}
