import React, {useMemo, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import Moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {Company, ProvisioningFile, ProvisioningFileType, ProductGroup, Paginator} from 'lib/models';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
import {useLocalFilter, useLocalSort, usePaginatedItems, useSelecting} from 'lib/filters';
// Actions
import {fetchItems, fetchItem, markFiltered, markOutdated, deleteItem, updatePaginator} from 'actions/shared';
// Components
import {Detail} from 'components/modules/provfiles/Detail';
import ThemeProvider from 'components/ThemeProvider';
import Field, {CompanyField, SearchField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
import DeleteDialog from 'components/core/ui/DeleteDialog';
// material-ui
import Checkbox from 'components/core/ui/mui/Checkbox';
import {CardFirst, CardLast} from 'components/core/ui/mui/ExpansionCards';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ExpansionPanel from 'components/core/ui/mui/ExpansionPanel';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Tooltip from 'components/core/ui/mui/Tooltip';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import Menu from 'components/core/ui/mui/Menu';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import MenuIcon from '@material-ui/icons/MoreVertOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const useStyles = makeStyles(theme => ({
    filters: {
        // connect with <CardLast />
        marginTop: `-${theme.spacing(2) + 1}px`
    },
    detailDialog: {
        maxWidth: '688px' // perfect fit
    },
    tableRow: {
        '&.highlighted': {
            background: theme.palette.snomGrey[100]
        }
    }
}));

/**
 * Renders ProvisioningFiles from backend, both client and admin - to view and manage them
 */
export default function List(passed_props) {
    const {location = null, cardActions, noHeader = false,
        doNotFetchProductGroup = false, filterProductGroups = null,
        initialItemSelectedFileIDs = [], itemSelectedFileIDs = [], itemSelectFileIDs = (ids) => {}
    } = passed_props;
    const intl = useIntl();
    const classes = useStyles();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, permission, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = searchParams.get('company')
            // ensure we don't create duplicate 'my' collection with UUID
            ? !pathname.startsWith('/all-provisioning-files') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                ? 'my'
                : searchParams.get('company')
            : pathname.startsWith('/all-provisioning-files') ? 'all' : 'my';
        const items_placement = `${new ProvisioningFile().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            state_identifier: state.app.get('identifier'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            has_company_default: (state.shared.getIn(['items', items_placement]) || ImmutableList()).some(item => item.get('company_default')),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            provisioning_file_types_loaded: state.shared.getIn(['loaded', 'provisioning-file-types']),
            provisioning_file_types_items: state.shared.getIn(['items', 'provisioning-file-types']),
            product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
            product_groups_items: state.shared.getIn(['items', 'product-groups']),
            company: company,
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user,
            permission: state.auth.get('permissions').get('provisioning_files')
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'created_at', label: <FormattedMessage id='provfiles.list.table.created_at' />},
        {sortName: 'name', label: <FormattedMessage id='provfiles.list.table.name' />},
        {sortName: 'type', label: <FormattedMessage id='provfiles.list.table.type' />},
        {label: <FormattedMessage id='provfiles.list.table.product_groups' />},
        ...(pathname.startsWith('/all-provisioning-files') ? [{sortName: 'company', label: <FormattedMessage id='provfiles.list.global.table.company' />}] : []),
        {label: <FormattedMessage id='provfiles.list.table.actions' />, numeric: true}
    ];
    // local state
    const [advancedMenuItem, setAdvancedMenuItem] = useState(null);
    const [advancedMenuAnchor, setAdvancedMenuAnchor] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailDialogItem, setDetailDialogItem] = useState(null);
    const [deleteDialogItem, setDeleteDialogItem] = useState(null);
    const [expanded, setExpanded] = useState(0);
    // extra filter of props.items
    const product_filter_items = useMemo(() => {
        return filterProductGroups ? props.items.filter(item => !!filterProductGroups.find(product_group_id => item.get('product_groups').includes(product_group_id))) : props.items;
    }, [props.items, filterProductGroups]);
    // pagination
    const [paginatedItems] = usePaginatedItems(product_filter_items, props.paginator, props.page_references, new ProvisioningFile().getUniqueIdentifier());
    const items = company_identifier === 'all' ? paginatedItems : product_filter_items;
    // sorting
    const [apiSortName, apiSortDirection] = ['created_at', 'asc'];
    const [sortedItems, sortItems, localSortName, localSortDirection, sortInitializing] = useLocalSort(
        company_identifier === 'all' ? ImmutableList() : items, company_identifier !== 'all' && (!props.loaded || !props.provisioning_file_types_loaded), items_placement, tableHead.map(item => item.sortName).filter(sortName => !!sortName), apiSortName, apiSortDirection,{
            type: (obj) => obj.get('type') && (props.provisioning_file_types_items.find(type => type.get(new ProvisioningFileType().getUniqueIdentifier()) === obj.get('type')) || new ImmutableMap({name: ''})).get('name')
        });
    const [sortName, sortDirection] = company_identifier === 'all' ? [apiSortName, apiSortDirection] : [localSortName, localSortDirection];
    // filtering
    const supportedFilters = [...(location ? [] : ['company']), ...(company_identifier !== 'all' ? ['search'] : []), 'type', ...(company_identifier !== 'all' && !filterProductGroups ? ['product_group'] : [])];
    const [localFilteredItems, filterItems, filters, filtersInitializing, postponedFilters] = useLocalFilter(
        sortedItems, sortInitializing || !props.product_groups_loaded,
        `${new ProvisioningFile().getPlacement()}${pathname.startsWith('/all-provisioning-files') ? '-global' : ''}`,
        supportedFilters, ['name'], {
            product_group: (obj, value) => obj.get('product_groups') && obj.get('product_groups').includes(props.product_groups_items.map(product_group => product_group.get(new ProductGroup().getUniqueIdentifier())).find(product_group_id => product_group_id === value)),
            type: (obj, value) => obj.get('type') && obj.get('type') === props.provisioning_file_types_items.map(type => type.get(new ProvisioningFileType().getUniqueIdentifier())).find(type_id => type_id === value)
        },
        company_identifier === 'all' && props.state !== null);
    const filteredItems = company_identifier === 'all' ? items : localFilteredItems;
    // related sorting
    const [sortedProvisioningFileTypes] = useLocalSort(props.provisioning_file_types_items, !props.provisioning_file_types_loaded);
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);
    // selecting
    const itemSelectedItems = useMemo(() => {
        return items.filter(item => itemSelectedFileIDs.includes(item.get(new ProvisioningFile().getUniqueIdentifier())));
    }, [items]);
    const selectable = !!location;
    const [selectedItems, selectItem] = useSelecting(items, false, itemSelectedItems,
        (items) => itemSelectFileIDs(items.map(item => item.get(new ProvisioningFile().getUniqueIdentifier()))));

    /**
     * During initialization fetch items
     */
    // all, pagination with API filters
    useEffect(() => {
        if (company_identifier === 'all' && !filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(ProvisioningFile, items_placement, 'provisioning-files', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), company_identifier]);
    // company, local filters
    useEffect(() => {
        if ((company_identifier === 'my' || (company_identifier !== 'all' && props.selectedCompany)) && props.loaded === false) {
            dispatch(fetchItems(ProvisioningFile, items_placement,
                props.selectedCompany.getIn(['links', 'provisioning-files'])
            ));
        }
    }, [props.loaded, props.selectedCompany, company_identifier]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);
    // silent auto re-fetch with outdated
    useEffect(() => {
        if (props.outdated && company_identifier === 'all') {
            dispatch(fetchItems(ProvisioningFile, items_placement, 'provisioning-files', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1, affect_state: false}));
        }
    }, [props.outdated]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.provisioning_file_types_loaded === false) {
            dispatch(fetchItems(ProvisioningFileType, new ProvisioningFileType().getPlacement(), 'provisioning-file-types', null, null, {affect_state: false}));
        }
    }, [props.provisioning_file_types_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false && !doNotFetchProductGroup) {
            dispatch(fetchItems(ProductGroup, new ProductGroup().getPlacement(), 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    return <React.Fragment>
        {!noHeader && <CardFirst>
            <CardHeader
                title={<FormattedMessage id={pathname.startsWith('/all-provisioning-files')
                    ? 'provfiles.list.global.title'
                    : 'provfiles.list.title'} />}
                subheader={<FormattedMessage id={pathname.startsWith('/all-provisioning-files')
                    ? 'provfiles.list.global.subheader'
                    : `provfiles.list.subheader${location ? `.${location}` : ''}`} />}
                action={passed_props.permission === 'RW' && !location
                    ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                    onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-files/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`)}>
                        <AddIcon />
                        <FormattedMessage id='actions.create' />
                    </ActionButton>
                    : <ActionButton iconButton disabled>
                    <ModelIcon model='provisioning_files' />
                </ActionButton>}
            />
        </CardFirst>}
        {(location && company_identifier !== 'all' && permission === 'RW') && <ExpansionPanel
            expanded={expanded === 1} onChange={() => setExpanded(expanded === 1 ? 0 : 1)}
            title={<FormattedMessage id='provfiles.detail.inline.add.title'
                                     values={{company_name: company_identifier === 'my'
                                             ? intl.formatMessage({id: 'companyfield.choices.my'}).toLowerCase()
                                             : props.selectedCompany && props.selectedCompany.get('name')
                                     }} />}>
                <Detail permission={permission}
                        sortedProvisioningFileTypes={sortedProvisioningFileTypes}
                        sortedProductGroups={sortedProductGroups}
                        filterProductGroups={filterProductGroups}
                        postSave={(edit) => {
                            if (!edit) {
                                setExpanded(0);
                            }
                        }} />
        </ExpansionPanel>}
        <CardLast>
            <Filters className={classes.filters}
                     disabled={company_identifier === 'all' && props.state !== null}
                     supportedFilters={['order_by', ...supportedFilters]}>
                {((pathname.startsWith('/all-provisioning-files') || props.company.get('company_type') === 'reseller') && !location) && <CompanyField
                    value={postponedFilters.company || filters.company || ''}
                    change={value => {
                        // make sure to move previous paginator to page 1
                        dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                        filterItems('company', value);
                    }}
                    all={pathname.startsWith('/all-')} fetch_company={false}
                    loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                    my_companies={!pathname.startsWith('/all-')}
                />}
                {(company_identifier !== 'all') && <SearchField
                    label={<FormattedMessage id='provfiles.list.search' />}
                    value={postponedFilters.search || filters.search || ''}
                    search={(search) => filterItems('search', search)} />}
                <Field label={<FormattedMessage id='provfiles.list.filter.type' />}
                       fieldType='NoReduxSelect' value={postponedFilters.type || filters.type || ''}
                       onChange={event => filterItems('type', event.target.value)}
                       loading={!props.provisioning_file_types_loaded}>
                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                    {sortedProvisioningFileTypes.map((type, idx) =>
                        <MenuItem key={idx} value={type.get(new ProvisioningFileType().getUniqueIdentifier())}>{type.get('name')}</MenuItem>
                    )}
                </Field>
                {(company_identifier !== 'all') && <Field
                    label={<FormattedMessage id={`provfiles.list.filter.product_group${filterProductGroups ? 's' : ''}`} />}
                    fieldType='NoReduxSelect' value={filterProductGroups || postponedFilters.product_group || filters.product_group || ''}
                    multiple={!!filterProductGroups} disabled={!!filterProductGroups}
                    onChange={event => filterItems('product_group', event.target.value)}
                    loading={!props.product_groups_loaded}>
                    <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                    {sortedProductGroups.map((product_group, idx) =>
                        <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                    )}
                </Field>}
            </Filters>
            <StickyTable
                header={<Table size={tableHead.length}>
                    <TableHead>
                        <TableRow>
                            {(selectable || props.has_company_default) && <TableCell checkbox>
                                {selectable && <Checkbox
                                    disabled={props.state !== null || !!Object.keys(filters).length}
                                    onClick={() => selectItem('all')}
                                    checked={selectedItems.size > 0 && selectedItems.size === items.size}
                                />}
                            </TableCell>}
                            {tableHead.map((item, idx) =>
                                <TableCell key={idx} numeric={item.numeric}>
                                    {company_identifier === 'all'
                                        ? sortName === item.sortName
                                            ? <TableSortLabel disabled active direction={sortDirection}>
                                                {item.label}
                                            </TableSortLabel>
                                            : item.label
                                        : item.sortName
                                        ? <TableSortLabel active={sortName === item.sortName}
                                                          direction={sortDirection}
                                                          onClick={() => sortItems(item.sortName)}>
                                            {item.label}
                                        </TableSortLabel>
                                        : item.label}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                </Table>}
                content={<Table size={tableHead.length}>
                    {[
                        'fetching_item_my-companies', 'fetching_item_companies',
                        `fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`
                    ].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} selectCell={selectable} paginator={props.paginator}
                    />
                        : !items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                        <TableRow>
                            <TableCell colSpan={selectable ? tableHead.length + 1 : tableHead.length}>
                                <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'provfiles.list.table.empty'}
                                                  values={{link: passed_props.permission === 'RW' && !location
                                                          ? <Link className='hover-border' to={`/${pathname.startsWith('/all-') ? 'all-' : ''}provisioning-files/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`}>
                                                              <FormattedMessage id='provfiles.list.table.empty.link' />
                                                          </Link>
                                                          : <FormattedMessage id='provfiles.list.table.empty.link' />}} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                        : <TableBody>
                        {(props.loaded || !!Object.keys(filters).length) && filteredItems.map((file, idx) => {
                            const deleting = props.state_identifier === file.get(new ProvisioningFile().getUniqueIdentifier()) && props.state === `deleting_item_${new ProvisioningFile().getPlacement()}${file.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `-${file.get('company')}` : ''}`;
                            const onClick = props.state === null && !deleting ? () => {
                                setDetailDialogItem(file);
                                setDetailDialogOpen(true);
                            } : () => {};

                            const type = file.get('type') && props.provisioning_file_types_items.find(el => el.get(new ProvisioningFileType().getUniqueIdentifier()) === file.get('type'));

                            return <TableRow key={idx} hover={!deleting} loading={deleting}
                                             className={`${classes.tableRow}${initialItemSelectedFileIDs.includes(file.get(new ProvisioningFile().getUniqueIdentifier())) ? ' highlighted' : ''}`}>
                                {(selectable || props.has_company_default) && <TableCell checkbox>
                                    <Tooltip disabled={!file.get('company_default')} title={<FormattedMessage id='provfiles.list.table.company_default' />}>
                                        <div>
                                            {selectable
                                                ? <Checkbox
                                                    disabled={props.state !== null}
                                                    color={file.get('company_default') && !selectedItems.includes(file) ? 'secondary' : 'primary'}
                                                    onClick={() => selectItem(file)}
                                                    checked={selectedItems.includes(file) || file.get('company_default')}
                                                /> : file.get('company_default') && <CheckBoxIcon color='secondary' />}
                                        </div>
                                    </Tooltip>
                                </TableCell>}
                                <TableCell link={!deleting} onClick={onClick}>{Moment(file.get('created_at')).format('l, LT')}</TableCell>
                                <TableCell link={!deleting} onClick={onClick}>{file.get('name')}</TableCell>
                                <TableCell link={!props.user.isAdmin() && !deleting} onClick={props.user.isAdmin() ? undefined : onClick}>
                                    {!props.provisioning_file_types_loaded
                                        ? <CircularProgress size={36} />
                                        : type
                                            ? props.user.isAdmin()
                                                ? <Link className='hover-border' to={`/provisioning-file-types/${type.get(new ProvisioningFileType().getUniqueIdentifier())}`}>
                                                    {type.get('name')}
                                                </Link>
                                                : type.get('name')
                                            : ''}
                                </TableCell>
                                <TableCell link={!props.user.isAdmin() && !deleting} onClick={props.user.isAdmin() ? undefined : onClick}>
                                    {!props.product_groups_loaded
                                        ? <CircularProgress size={36} />
                                        : file.get('product_groups')
                                            ? file.get('product_groups').map((product_group_id, idx) => {
                                                const product_group = props.product_groups_items.find(el => el.get(new ProductGroup().getUniqueIdentifier()) === product_group_id);

                                                return product_group
                                                    ? <span key={idx} className='divide-links'>{props.user.isAdmin()
                                                        ? <Link className='hover-border' to={`/product-families/${product_group.get(new ProductGroup().getUniqueIdentifier())}`}>
                                                            {product_group.get('name')}
                                                        </Link>
                                                        : product_group.get('name')}
                                                    </span>
                                                    : '';
                                            })
                                            : ''}
                                </TableCell>
                                {pathname.startsWith('/all-provisioning-files') && <TableCell>
                                    <Link className='hover-border' to={`/companies/${file.get('company')}`}>
                                        {file.get('company_name')}
                                    </Link>
                                </TableCell>}
                                <TableCell numeric>
                                    <Tooltip title={<FormattedMessage id='actions.more' />} disabled={permission !== 'RW' || props.state !== null}>
                                        <IconButton disabled={permission !== 'RW' || props.state !== null}
                                                    onClick={(event) => {
                                                        setAdvancedMenuAnchor(event.currentTarget);
                                                        setAdvancedMenuItem(file);
                                                    }}>
                                            <MenuIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>;
                        })}
                    </TableBody>}
                </Table>}
                footer={company_identifier !== 'all' ? undefined : <Table size={1}>
                    <Pagination model={ProvisioningFile} placement={items_placement} filters={filters}
                                paginationLoading={true} />
                </Table>} />
            {permission === 'RW' && <React.Fragment>
                <ThemeProvider alt>
                    <Menu open={!!advancedMenuItem} anchorEl={advancedMenuAnchor}
                          onClose={() => setAdvancedMenuItem(null)}>
                        <MenuItem onClick={() => {
                            setDeleteDialogItem(advancedMenuItem);
                            setAdvancedMenuItem(null);
                        }}>
                            <ListItemIcon className='icon'><DeleteIcon color='secondary' /></ListItemIcon>
                            <ListItemText className='text'><FormattedMessage id='actions.delete' /></ListItemText>
                        </MenuItem>
                    </Menu>
                </ThemeProvider>
                <DeleteDialog
                    item={intl.formatMessage({id: 'provfiles.list.deletedialog.item'})}
                    items={intl.formatMessage({id: 'provfiles.list.deletedialog.items'})}
                    open={!!deleteDialogItem}
                    handleClose={() => setDeleteDialogItem(null)}
                    handleConfirm={() => {
                        setDetailDialogOpen(false);
                        setDeleteDialogItem(null);
                        dispatch(deleteItem(ProvisioningFile, `${new ProvisioningFile().getPlacement()}${deleteDialogItem.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `-${deleteDialogItem.get('company')}` : ''}`, deleteDialogItem.getIn(['links', 'self']), deleteDialogItem,
                            {error_message_intl: 'provfiles.list.delete.fail'})).then(result => {
                                if (result !== false) {
                                    if (company_identifier !== 'all') {
                                        dispatch(markFiltered(ProvisioningFile, `${new ProvisioningFile().getPlacement()}-global`));
                                    } else {
                                        dispatch(markOutdated(ProvisioningFile, `${new ProvisioningFile().getPlacement()}-global`));
                                    }
                                }
                        });
                    }}
                />
            </React.Fragment>}
            <Dialog fullWidth classes={{paper: classes.detailDialog}}
                    open={detailDialogOpen}
                    onClose={() => setDetailDialogOpen(false)}>
                <DialogTitle>
                    <FormattedMessage id='provfiles.detail.inline.edit.title' />
                </DialogTitle>
                <DialogContent>
                    <Detail permission={permission}
                            sortedProvisioningFileTypes={sortedProvisioningFileTypes}
                            sortedProductGroups={sortedProductGroups}
                            item={detailDialogItem}
                            deleteItem={() => setDeleteDialogItem(detailDialogItem)}
                            cancel={() => setDetailDialogOpen(false)}
                            postSave={() => {
                                setDetailDialogOpen(false);
                            }}
                    />
                </DialogContent>
            </Dialog>
            {cardActions && <React.Fragment>
                <hr className='none' />
                {cardActions}
            </React.Fragment>}
        </CardLast>
    </React.Fragment>;
}
