import {Map} from 'immutable';
import keymirror from 'keymirror';


export const constants = keymirror({
    API_STATE: null,
    API_ERROR_STATE: null,
    API_CLIENT: null,
    API_UPDATE_LINKS: null
});

const defaults = Map({
    state: null,
    error_identifier: null,
    error_code: null,
    error_message: null,
    client: null,
    version: null,
    loaded_links: Map({
        reports: false
    })
});

const reducer = (state = defaults, action) => {
    switch (action.type) {
        // sets API state
        case constants.API_STATE:
            return state.set('state', action.state);

        // sets Error API state
        case constants.API_ERROR_STATE:
            return state.set('error_identifier', action.error_identifier).set('error_code', action.error_code).set('error_message', action.error_message);

        // sets Client
        case constants.API_CLIENT:
            return state.set('client', action.client).set('version', action.server ? action.server.replace('SRAPS', '').replace(/\s/g, '').toLowerCase() : null);

        // updates API links in client
        case constants.API_UPDATE_LINKS:
            state.get('client').updateLinks(action.root, action.payload);
            return state.setIn(['loaded_links', action.root], true);

        default:
            return state;
    }
};
export default reducer;
