import React from 'react';
import {FormattedMessage} from 'react-intl';
import {countries as countrieslist} from 'lib/countrieslist';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Field for list of countries
 */
export default function CountryField(props) {
    const {countries = countrieslist, multiple = false, ...rest_of_props} = props;

    return <Field fieldType='Select' multiple={multiple} {...rest_of_props}>
        {!multiple && <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>}
        {countries.map(country => <MenuItem key={country.get('id')} value={country.get('id')}>
            {country.get('name')}
        </MenuItem>)}
    </Field>;
}
