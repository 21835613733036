import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {ReleaseNote} from 'lib/models';
// actions
import {fetchItems} from 'actions/shared';
// components
import Timeline from 'components/core/ui/Timeline';
import ChangelogList from 'components/modules/changelog/ChangelogList';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
// vectors
import ChangelogReleaseWave from 'components/core/vectors/waves/ChangelogReleaseWave';
import ChangelogTypeIcon from 'components/core/vectors/ChangelogTypeIcon';


const useStyles = makeStyles((theme) => ({
    rootCardTitle: {
        margin: theme.spacing(0, 0, 5),
        color: theme.palette.snomGrey[800]
    },
    changelogCard: {
        position: 'relative',
        '&.release': {
            borderColor: theme.graphs[4],
            borderWidth: '2px'
        }
    },
    contentTitle: {
        color: theme.palette.snomGrey.dark,
        fontWeight: theme.typography.fontWeightMedium,
        '&.release': {
            fontSize: theme.typography.h6.fontSize
        }
    },
    contentDate: {
        color: theme.palette.snomGrey.dark,
        fontWeight: theme.typography.fontWeightRegular
    },
    changelogVersion: {
        color: theme.palette.snomGrey.dark,
        marginBottom: `${theme.spacing(3)}px`
    },
    changelogDescription: {
        color: theme.palette.snomGrey.dark,
        marginBottom: `${theme.spacing(3)}px`,
        // allow line breaks
        whiteSpace: 'pre-line'
    },
    changelogCardContent: {
        padding: theme.spacing(2, 3, 2),
        // allow selection of text
        position: 'relative',
        zIndex: `${theme.zIndex.mobileStepper - 10}`
    },
    changeListTitle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: `${theme.spacing(2)}px`,
        '& .MuiTypography-h6': {
            lineHeight: theme.typography.pxToRem(22)
        },
        '&.added': {
            color: theme.palette.primary.main
        },
        '&.fixed': {
            color: theme.graphs[4]
        },
        '&.changed': {
            color: theme.palette.orange.main
        },
        '&.removed': {
            color: theme.palette.snomGrey.dark
        },
        '&.deprecated': {
            color: theme.palette.snomGrey[400]
        }
    },
    changeList: {
        margin: 0,
        paddingLeft: `${theme.spacing(7)}px`,
        '&:not(:last-of-type)': {
            marginBottom: `${theme.spacing(1)}px`
        },
        '& .MuiTypography-h6': {
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: theme.typography.h5.lineHeight,
            color: theme.palette.snomGrey.dark
        }
    },
    releaseWave: {
        position: 'absolute',
        bottom: 0,
        right: 0
    }
}));

/**
 * Render the changelog list for customers
 */
export default function CustomerChangelog() {
    const classes = useStyles();
    const placement = useMemo(() => new ReleaseNote().getPlacement(), []);

    const dispatch = useDispatch();
    const {items, items_loaded, locale} = useSelector((state) => ({
        // set default english language till supporting multi language
        locale: 'en',
        items: state.shared.getIn(['items', placement]),
        items_loaded: state.shared.getIn(['loaded', placement])
    }), shallowEqual);

    useEffect(() => {
        if (!items_loaded) {
            dispatch(fetchItems(ReleaseNote, placement, 'release-notes'));
        }
    }, [items_loaded]);

    return (
        <Card>
            <CardContent>
                <h4 className={`${classes.rootCardTitle} newfont`}>
                    <strong><FormattedMessage id='changelog.title' /></strong>
                </h4>
                {!items_loaded ? <LinearProgress />
                    : <Timeline events={items?.filter(item => !Moment(item.get('created_at')).isAfter() && locale === item.get('language'))}
                        renderDate={(releaseNote) => <Typography variant='h6' className={`${classes.contentDate} newfont`}>
                            {Moment(releaseNote.get('created_at')).format('l')}
                        </Typography>}
                        renderIcon={(releaseNote) => <ChangelogTypeIcon type={releaseNote.get('type')} />}
                        renderTitle={(releaseNote) => <Typography noWrap className={`${classes.contentTitle} newfont ${releaseNote.get('type')}`}>
                            <FormattedMessage id={`changelog.type.${releaseNote.get('type')}`} />
                        </Typography>}
                    >
                        {(releaseNote) => <Card elevation={releaseNote.get('type') === 'release' ? 3 : 0} className={`${classes.changelogCard} ${releaseNote.get('type')}`}>
                            <CardContent className={classes.changelogCardContent}>
                                <Typography variant='h5' className={`${classes.changelogVersion} newfont`}>
                                    <strong>{releaseNote.get('version')}</strong>
                                </Typography>
                                <Typography className={`${classes.changelogDescription} newfont`}>{releaseNote.get('description')}</Typography>
                                <ChangelogList item={releaseNote} newfont  />
                            </CardContent>
                            {releaseNote.get('type') === 'release' ? <ChangelogReleaseWave className={classes.releaseWave} /> : null}
                        </Card>}
                    </Timeline>}
            </CardContent>
        </Card>
    );
}
