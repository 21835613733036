import axios from 'axios';
import HawkClient from 'hawk';
import {Map} from 'immutable';
import buildURL from 'axios/lib/helpers/buildURL';
import Moment from 'moment';


/**
 * Client for communication with API via Axios
 */
export default class Client {
    static ACCEPT_HEADER = 'application/json';
    static CONTENT_TYPE = 'application/json';

    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.rootMap = null;
        this.dateOffset = null;
        this.currentConfig = {};
        this.key = null;
        this.secret = null;
    }

    /**
     * Determine, whether the parameter is a URL or a route name
     *
     * @param path_or_url - PATH of URL we are trying to reach.
     *                      Optional array with PATH & TOKEN/S.
     *                      e.g. ['offers.detail', '1488'] = /api/offers/1488
     *                           ['offers.detail', '1488', '8814'] = /api/offers/1488/8814
     * @returns string - found url or null
     * @private
     */
    _correctUrl(path_or_url) {
        // prepare variables
        let url = path_or_url;
        let token = null;
        let token2 = null;

        // check if we received array
        if (Array.isArray(path_or_url)) {
            // check if we have correct length (path_or_url & token)
            if (path_or_url.length !== 2 && path_or_url.length !== 3) {
                throw new Error('Bad size of URL Array. Array should contain PATH_OR_URL and TOKEN/S.');
            }
            url = path_or_url[0];
            token = path_or_url[1];
            if (path_or_url.length === 3) {
                token2 = path_or_url[2];
            }
        }

        // check if we have path_or_url
        if (!url) {
            return null;
        }

        // Is this a URL?
        if (url.startsWith('http') || url.startsWith('https')) {
            // add token to url
            if (token) {
                if (url.endsWith('/')) {
                    url = `${url}${token}`;
                } else {
                    url = `${url}/${token}`;
                }
            }
            if (token2) {
                url = `${url}/${token2}`;
            }
            return url;
        }

        // Nope, find a route
        let current = this.rootMap;
        url.split('.').forEach((val) => {
            if (current === null) {
                return;
            }
            current = current.get(val, null);
        });

        if (current === null) {
            return null;
        }

        // add token to url
        if (token) {
            if (current.endsWith('/')) {
                current = `${current}${token}`;
            } else {
                current = `${current}/${token}`;
            }
        }
        if (token2) {
            current = `${current}/${token2}`;
        }
        return current;
    }

    /**
     * Sign request with HAWK
     *
     * @param url - Call URL
     * @param method - Call method
     * @param data - Body data (payload)
     * @returns {string[]}
     * @private
     */
    _signRequest(url, method, data) {
        let hawkOptions = {
            credentials: {
                id: this.key,
                key: this.secret,
                algorithm: 'sha256'
            },
            timestamp: Math.floor(Date.now() / 1000) + (Math.floor(this.dateOffset / 1000) || 0)
        };
        if (method !== 'get') {
            if (data) {
                hawkOptions['payload'] = JSON.stringify(data);
            }
            hawkOptions['contentType'] = Client.CONTENT_TYPE;
        }

        return HawkClient.client.header(url, method, hawkOptions).header;
    }

    /**
     * Build request - add proper Headers, cookies, etc.
     *
     * @param url - URL of request
     * @param method - Method of Request
     * @param params - additional params
     * @param headers - additional headers
     * @param data - body data
     * @returns object
     * @private
     */
    _buildRequestConfig(url, method, params = null, headers = null, data = null) {
        let built = this.currentConfig;
        this.currentConfig = {};

        let defaultHeaders = {
            'Accept': Client.ACCEPT_HEADER,
            'Accept-Language': Moment.locale()
        };
        if (method !== 'get') {
            defaultHeaders['Content-Type'] = Client.CONTENT_TYPE;
        }
        if (!built.hasOwnProperty('headers')) {
            built.headers = {};
        }
        built.headers = {
            ...defaultHeaders,
            ...built.headers
        };
        if (headers !== null) {
            built.headers = {
                ...built.headers,
                ...headers
            };
        }

        if (this.secret !== null && this.key !== null) {
            let signature = this._signRequest(buildURL(url, params), method, data);

            built = {
                headers: {
                    'Authorization': signature,
                    ...built.headers
                }
            };
        }

        return built;
    }

    /**
     * Make sure to update dateOffset after each request
     * @private
     */
    _postRequestAction(result) {
        // Store the clock drift, in milliseconds
        this.dateOffset = new Date(result.headers['date']) - new Date();

        return result;
    }

    /**
     * Convert empty strings to nulls
     * @private
     */
    _processPostData(data) {
        const processValue = value => {
            // convert empty string to null
            if (value === '') {
                return null;
            } else if (value && typeof value === 'object') {
                // recursive call for object
                return processObj(value);
            } else {
                // just return
                return value;
            }
        };
        const processObj = obj => Object.fromEntries(Object.entries(obj).map(([key, value]) => {
            // check if we are dealing with array
            if (Array.isArray(value)) {
                return [key, value.map(aValue => processValue(aValue))];
            } else {
                return [key, processValue(value)];
            }
        }));

        return data && typeof data === 'object' ? processObj(data) : data;
    }

    /**
     * First call to API (root). Set server time, differences, api map and other stuff to client
     *
     * @returns {Promise}
     */
    initClient() {
        return axios({
            method: 'get',
            url: this.baseUrl,
            headers: {
                'Accept': Client.ACCEPT_HEADER,
                'Accept-Language': Moment.locale()
            }
        }).then((result) => {
            // Store the clock drift, in milliseconds
            this.dateOffset = new Date(result.headers['date']) - new Date();
            // Store those loaded paths
            this.rootMap = new Map(result.data.links);
            // return result
            return result;
        });
    }

    /**
     * Set authorization
     */
    authorize(key, secret) {
        this.key = key;
        this.secret = secret;
    }

    /**
     * Clear authorization
     */
    deauthorize() {
        this.key = null;
        this.secret = null;
    }

    /**
     * Updates the internal link tree
     *
     * @param root - path of placement e.g. 'tables'
     * @param payload - links in the path
     */
    updateLinks(root, payload) {
        this.rootMap = this.rootMap.set(root, new Map(payload));
    }

    /**
     * GET request
     *
     * @param url_or_path - URL or Path we are trying to reach
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    get(url_or_path, params = null, headers = null) {
        let url = this._correctUrl(url_or_path);
        if (url === null) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        return axios({
            method: 'get',
            url,
            params,
            ...this._buildRequestConfig(url, 'get', params, headers)
        }).then((result) => this._postRequestAction(result));
    }

    /**
     * POST request
     *
     * @param url_or_path - URL or Path we are trying to reach
     * @param data - sending data
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    post(url_or_path, data, params = null, headers = null) {
        const url = this._correctUrl(url_or_path);
        if (!url) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        const processedData = this._processPostData(data);

        return axios({
            method: 'post',
            url,
            data: processedData,
            params,
            ...this._buildRequestConfig(url, 'post', params, headers, processedData)
        }).then((result) => this._postRequestAction(result));
    }

    /**
     * PUT request
     *
     * @param url_or_path - URL or Path we are trying to reach
     * @param data - sending data
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    put(url_or_path, data, params = null, headers = null) {
        const url = this._correctUrl(url_or_path);
        if (!url) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        const processedData = this._processPostData(data);

        return axios({
            method: 'put',
            url,
            data: processedData,
            params,
            ...this._buildRequestConfig(url, 'put', params, headers, processedData)
        }).then((result) => this._postRequestAction(result));
    }

    /**
     * PATCH request
     *
     * @param url_or_path - URL or Path we are trying to reach
     * @param data - sending data
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    patch(url_or_path, data, params = null, headers = null) {
        const url = this._correctUrl(url_or_path);
        if (!url) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        const processedData = this._processPostData(data);

        return axios({
            method: 'patch',
            url,
            data: processedData,
            params,
            ...this._buildRequestConfig(url, 'patch', params, headers, processedData)
        }).then((result) => this._postRequestAction(result));
    }

    /**
     * DELETE request
     *
     * @param url_or_path - URL or Path we are trying to reach
     * @param data - sending data
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    delete(url_or_path, data, params = null, headers = null) {
        const url = this._correctUrl(url_or_path);
        if (!url) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        const processedData = this._processPostData(data);

        return axios({
            method: 'delete',
            url,
            data: processedData,
            params,
            ...this._buildRequestConfig(url, 'delete', params, headers, processedData)
        }).then((result) => this._postRequestAction(result));
    }

    /**
     * Uploads a file in a single pass
     *
     * @param url_or_path - URL where to upload File, e.g. 'files'
     * @param file - File blop
     * @param data - additional data
     * @param params - Query params
     * @param headers - Additional Headers for request
     * @returns {Promise}
     */
    upload(url_or_path, file, data = {}, params = null, headers = null) {
        let url = this._correctUrl(url_or_path);
        if (url === null) {
            console.error(`Invalid URL '${url_or_path}'`);
            return new Promise((resolve, reject) => reject(false));
        }

        // Construct the file upload
        let form_data = new FormData();
        form_data.append('file', file);
        Object.keys(data).forEach(key => {
            data[key] && form_data.append(key, data[key]);
        });

        return axios({
            method: 'post',
            url,
            data: form_data,
            params,
            ...this._buildRequestConfig(url, 'post', params, headers)
        }).then((result) => this._postRequestAction(result));
    }
}
