import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
// components
import Field from 'components/core/ui/Field';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Tabs from 'components/core/ui/Tabs';
import Tab from 'components/core/ui/mui/Tab';
// material ui
import Grid from '@material-ui/core/Grid';


/**
 * Convert array of numbers to cron type value
 */
const convertToCronValue = (values = []) => {
    const sortedValues = values.sort((a, b) => +a - +b);
    // group values
    return sortedValues.reduce((acc, value) => {
        if (!acc.length) {
            return [[value]];
        } else {
            const lastArray = acc[acc.length - 1];
            const lastElement = lastArray[lastArray.length - 1];

            if (+value - +lastElement === 1) {
                acc[acc.length - 1] = [...lastArray, value];
            } else {
                acc.push([value]);
            }

            return acc;
        }
    }, []).map((valueArray) => valueArray.length > 1 ?
        `${valueArray[0]}-${valueArray[valueArray.length - 1]}` : valueArray[0]).join(',');
};

/**
 * Convert cron to array of numbers
 */
const convertFromCronValue = (cronValue = '') => {
    const rangeValues = cronValue.match(/\d+-\d+/gm)?.reduce((acc, value) => {
        const [start, end] = value.split('-');
        return [...acc, ...[...Array(+end - +start + 1)].map((_, i) => +start + i)];
    }, []) || [];

    const restOfValue = cronValue.replace(/\d+-\d+/gm, '').split(',')
        .filter(v => v !== '' && v !== undefined && v !== null).map(v => window.isNaN(v) ? v : +v);

    return [...rangeValues, ...restOfValue];
};

const useInputStyles = makeStyles((theme) => ({
    select: {
        width: '100%'
    }
}));

/**
 * Render cron's select input
 * @param {object} props - component props
 * @param {string} props.value - input value
 * @param {(v: string) => void} props.onChange - handle change value
 * @param {string} props.label - input label
 * @param {boolean} props.multiple - select multiple flag
 * @param {boolean} props.fullWidth - make input's width full
 * @param {string} props.placeholder - input placeholder
 * @param {boolean} props.error - error flag
 * @param {(v: any) => React.ReactNode} props.renderValue - custom value display
 */
const SelectInput = (props) => {
    const {value, onChange, onClick, label, multiple, children, placeholder, renderValue, fullWidth = true, error} = props;
    const classes = useInputStyles();

    return <Grid item xs={fullWidth ? 12 : 6}><Field className={classes.select} error={error} fieldType='NoReduxSelect' label={label}
        renderValue={renderValue} value={value} onClick={onClick} onChange={(e) => {
            if (multiple) {
                const lastItem = e.target.value[e.target.value.length - 1];
                let newValue = e.target.value.filter(v => !window.isNaN(v));
                if (lastItem === '*' || !e.target.value.length) {
                    newValue = ['*'];
                } else if (['?', 'L', 'W'].includes(lastItem)) {
                    newValue = [lastItem];
                }
                onChange(newValue);
            } else {
                onChange(e.target.value);
            }
        }} multiple={multiple} placeholder={placeholder}>{children}</Field></Grid>;
};

const useStyles = makeStyles((theme) => ({
    tab: {
        maxWidth: 'none',
        flex: 1
    },
    selectInputsContainer: {
        paddingRight: `${theme.spacing(1)}px`
    }
}));

/**
 * Redux Field to manage Cron type shedule
 */
export default function CronInput(props) {
    const {formName, change, touch, helperText, ...rest_of_props} = props;

    const intl = useIntl();
    const classes = useStyles();

    const {value, error} = useSelector((state) => {
        const form = state.form?.[formName];
        return {value: form?.values?.[props.name] || '* * * * ? *', error: form?.syncErrors?.[props.name]};
    }, shallowEqual);
    const [minute, hour, dayMonth, month, dayWeek] = value.split(' ');
    const [repeatType, setRepeatType] = useState('month');
    const [selectedTab, setSelectedTab] = useState(0);

    const repeatTypes = useMemo(() => ['year', 'month', 'week', 'day', 'hour', 'minute'], []);
    const [months,daysMonth, daysWeek, hours, minutes] = useMemo(() => [
        Array(12).fill(1).map((value, index) => value + index),
        Array(31).fill(1).map((value, index) => value + index),
        Array(7).fill(1).map((value, index) => value + index),
        Array(24).fill().map((_, index) => index),
        Array(60).fill().map((_, index) => index)
    ], []);

    // set initial repeat type based on value
    useEffect(() => {
        if (selectedTab === 0) {
            let initialRepeatType = 'year';
            if (['* * * * ? *', '* * ? * * *'].includes(value)) {
                initialRepeatType = 'minute';
            } else if ([hour, dayMonth, month, dayWeek].every(v => v === '*')) {
                initialRepeatType = 'hour';
            } else if (month === '*' && ['*', '?'].includes(dayMonth) && ['*', '?'].includes(dayWeek)) {
                initialRepeatType = 'day';
            } else if (month === '*' && ['*', '?'].includes(dayMonth)) {
                initialRepeatType = 'week';
            } else if (month === '*') {
                initialRepeatType = 'month';
            }
            setRepeatType(initialRepeatType);
        }
    }, [selectedTab]);

    const handleChangeRepeatType = useCallback((newRepeatType) => {
        setRepeatType(newRepeatType);
        let newValue = '';
        switch (newRepeatType) {
            case 'month':
                newValue = `${minute} ${hour} ${dayMonth} * ${dayWeek} *`;
                break;
            case 'week':
                newValue = `${minute} ${hour} ${dayWeek === '?' ? '*' : '?'} * ${dayWeek} *`;
                break;
            case 'day':
                newValue = `${minute} ${hour} * * ? *`;
                break;
            case 'hour':
                newValue = `${minute} * * * ? *`;
                break;
            case 'minute':
                newValue = '* * * * ? *';
                break;
            default:
                newValue = value;
        }
        change(props.name, newValue);
    }, [value, minute, hour, dayMonth, month, dayWeek]);

    const renderValue = useCallback((values, field) => {
        let displayValue = '';

        switch (values[0]) {
            case '*': {
                displayValue = intl.formatMessage({id: `ticketschedulers.detail.form.fields.${field}.placeholder`});
                break;
            }
            case 'L': {
                displayValue = intl.formatMessage({id: `ticketschedulers.detail.form.fields.${field}.lastDay`});
                break;
            }
            case 'W': {
                displayValue = intl.formatMessage({id: `ticketschedulers.detail.form.fields.${field}.weekday`});
                break;
            }
            case '?': {
                displayValue = intl.formatMessage({id: 'ticketschedulers.detail.form.fields.schedule.unspecific'});
                break;
            }
            default: {
                const cronValue = convertToCronValue(values);

                if (['month', 'dayWeek'].includes(field)) {
                    displayValue = cronValue.match(/(\d+|\D)/gm).map((value) =>
                        window.isNaN(value) ? value : intl.formatMessage({id: `ticketschedulers.detail.form.fields.${field}.${value}`})
                    ).join('');
                } else if (field === 'minute') {
                    displayValue = cronValue.match(/(\d+|\D)/gm).map((value) => window.isNaN(value) ? value : Moment().minutes(value).format('mm')).join('');
                } else if (field === 'hour') {
                    displayValue = cronValue.match(/(\d+|\D)/gm).map((value) => window.isNaN(value) ? value : Moment().hours(value).format('HH')).join('');
                } else {
                    displayValue = cronValue;
                }
            }
        }

        return displayValue;
    }, []);

    const handleChangeValue = useCallback((field, newValue) => {
        const values = value.split(' ');
        const cronValue = convertToCronValue(newValue);
        switch (field) {
            case 'minute':
                values[0] = cronValue;
                break;
            case 'hour':
                values[1] = cronValue;
                break;
            case 'dayMonth':
                values[2] = cronValue;
                if (cronValue !== '?') {
                    values[4] = '?';
                }
                break;
            case 'month':
                values[3] = cronValue;
                break;
            case 'dayWeek':
                values[4] = cronValue;
                if (cronValue !== '?') {
                    values[2] = '?';
                }
                break;
        }
        props.change(props.name, values.join(' '));
    }, [value]);

    return <Tabs tabsProps={{scrollButtons: 'off'}} tabs={[<Tab key={0} className={classes.tab} label={<FormattedMessage id='ticketschedulers.detail.form.fields.tabs.assistant' />} />,
        <Tab key={1} className={classes.tab} label={<FormattedMessage id='ticketschedulers.detail.form.fields.tabs.raw' />} />]} initialSelectedTab={0} destroyNotSelected content={[
        <Grid onMouseDown={() => touch(formName)} container spacing={1} className={classes.selectInputsContainer}>
            <SelectInput label={intl.formatMessage({id: 'ticketschedulers.detail.form.fields.repeat_type.label'})}
                value={repeatType} onChange={handleChangeRepeatType}>
                {repeatTypes.map((type) => <MenuItem key={type} value={type}><FormattedMessage id={`ticketschedulers.detail.form.fields.repeat_type.${type}`} /></MenuItem>)}
            </SelectInput>
            {/** month */}
            {repeatType === 'year' && <SelectInput error={!!error} renderValue={(v) => renderValue(v, 'month')} multiple
                label={intl.formatMessage({id: 'ticketschedulers.detail.form.fields.month.label.in'})}
                value={convertFromCronValue(month)} onChange={(value) => handleChangeValue('month', value)}>
                <MenuItem value='*'><FormattedMessage id='ticketschedulers.detail.form.fields.month.placeholder'/></MenuItem>
                {months.map((month) => <MenuItem key={month} value={month}><FormattedMessage id={`ticketschedulers.detail.form.fields.month.${month}`} /></MenuItem>)}
            </SelectInput>}
            {/** day of the month */}
            {['year', 'month'].includes(repeatType) && <SelectInput error={!!error} renderValue={(v) => renderValue(v, 'dayMonth')} multiple
                label={intl.formatMessage({id: 'ticketschedulers.detail.form.fields.dayMonth.label.on'})}
                value={convertFromCronValue(dayMonth)} onChange={(value) => handleChangeValue('dayMonth', value)}>
                <MenuItem value='*'><FormattedMessage id='ticketschedulers.detail.form.fields.dayMonth.placeholder'/></MenuItem>
                <MenuItem value='L'><FormattedMessage id='ticketschedulers.detail.form.fields.dayMonth.lastDay'/></MenuItem>
                <MenuItem value='W'><FormattedMessage id='ticketschedulers.detail.form.fields.dayMonth.weekday'/></MenuItem>
                <MenuItem value='?'><FormattedMessage id='ticketschedulers.detail.form.fields.schedule.unspecific'/></MenuItem>
                {daysMonth.map((day) => <MenuItem key={day} value={day}>{day}</MenuItem>)}
            </SelectInput>}
            {/** day of the week */}
            {['year', 'month', 'week'].includes(repeatType) && <SelectInput error={!!error} renderValue={(v) => renderValue(v, 'dayWeek')} multiple
                label={intl.formatMessage({id: `ticketschedulers.detail.form.fields.dayWeek.label.${repeatType === 'week' ? 'on' : 'and'}`})}
                value={convertFromCronValue(dayWeek)} onChange={(value) => handleChangeValue('dayWeek', value)}>
                <MenuItem value='*'><FormattedMessage id='ticketschedulers.detail.form.fields.dayWeek.placeholder'/></MenuItem>
                <MenuItem value='L'><FormattedMessage id='ticketschedulers.detail.form.fields.dayWeek.lastDay'/></MenuItem>
                <MenuItem value='?'><FormattedMessage id='ticketschedulers.detail.form.fields.schedule.unspecific'/></MenuItem>
                {daysWeek.map((day) => <MenuItem key={day} value={day}><FormattedMessage id={`ticketschedulers.detail.form.fields.dayWeek.${day}`} /></MenuItem>)}
            </SelectInput>}
            {/** hour */}
            {['year', 'month', 'week', 'day'].includes(repeatType) && <SelectInput error={!!error} renderValue={(v) => renderValue(v, 'hour')} multiple
                label={intl.formatMessage({id: 'ticketschedulers.detail.form.fields.hour.label.at'})}
                value={convertFromCronValue(hour)} onChange={(value) => handleChangeValue('hour', value)} fullWidth={false}>
                <MenuItem value='*'><FormattedMessage id='ticketschedulers.detail.form.fields.hour.placeholder'/></MenuItem>
                {hours.map((_hour) => <MenuItem key={_hour} value={_hour} >{Moment().hours(_hour).format('HH')}</MenuItem>)}
            </SelectInput>}
            {/** minute */}
            {['year', 'month', 'week', 'day', 'hour'].includes(repeatType) && <SelectInput error={!!error} renderValue={(v) => renderValue(v, 'minute')} multiple
                label={repeatType === 'hour' ? intl.formatMessage({id: 'ticketschedulers.detail.form.fields.minute.label.at'}) : ''}
                value={convertFromCronValue(minute)} onChange={(value) => handleChangeValue('minute', value)} fullWidth={repeatType === 'hour'}>
                <MenuItem value='*'><FormattedMessage id='ticketschedulers.detail.form.fields.minute.placeholder'/></MenuItem>
                {minutes.map((_minute) => <MenuItem key={_minute} value={_minute}>{Moment().minutes(_minute).format('mm')}</MenuItem>)}
            </SelectInput>}
            <Grid item container>
                <Field {...rest_of_props} fieldType='ErrorField' />
            </Grid>
        </Grid>,
        <Field fieldType='TextField' size='full' helperText={helperText} {...rest_of_props} />
    ]} onChange={tab => setSelectedTab(tab)} />;
}
