import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router';
import {connect, useDispatch} from 'react-redux';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {reduxForm} from 'redux-form';
import {arrayMove} from '@dnd-kit/sortable';
import {FeatureFlag, FeatureFlagDefinition} from 'lib/models';
import validator from 'lib/valitator';
import {makeStyles} from '@material-ui/core/styles';
// actions
import {fetchItem, deleteItem, saveItem} from 'actions/shared';
import {setState} from 'actions/app';
// components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import ReferenceField from 'components/core/ui/fields/ReferenceField';
import SortableContainer from 'components/core/ui/Sortable';
import DraggableCard from 'components/core/ui/DraggableCard';
import FeatureFlagStatusMessage from 'components/modules/featureFlags/FeatureFlagStatusMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ThemeProvider from 'components/ThemeProvider';
import Button from 'components/core/ui/mui/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import DeleteDialog from 'components/core/ui/DeleteDialog';
// vectors
import ModelIcon from 'components/core/vectors/ModelIcon';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import RestrictionIcon from '@material-ui/icons/HttpsOutlined';


/**
 * Feature flag's rule detail
 */
const FeatureFlagRuleDetail = React.forwardRef((props, ref) => {
    const {rule, index, permission, hasNextSibling, definition, ...rest_of_props} = props;
    const intl = useIntl();

    return <DraggableCard ref={ref} editable={permission === 'RW'} {...rest_of_props}>
        <Field
            disabled={permission !== 'RW'} name={`rules.${index}.context_key`} className='dynamic full' required
            label={intl.formatMessage({id: 'featureflags.detail.form.rules.context_key.label'})}
            helperText={intl.formatMessage({id: 'featureflags.detail.form.rules.context_key.helperText'})}
            fieldType={definition ? 'Select' : 'TextField'}
        >
            {definition ? [
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>,
                ...(definition.get('allowed_context_values').map(c => <MenuItem key={c} value={c}>
                    {c}
                </MenuItem>))
            ] : []}
        </Field>
        <ReferenceField disabled={permission !== 'RW'} required name={`rules.${index}.reference`} />
        <Field disabled={permission !== 'RW'} name={`rules.${index}.logical_operator`} fieldType='Select' className='dynamic full' InputLabelProps={{shrink: true}}
            label={intl.formatMessage({id: 'featureflags.detail.form.rules.logical_operator.label'})} displayEmpty required={hasNextSibling}
            renderValue={(value) => <FormattedMessage id={`featureflags.detail.form.rules.logical_operator.choice.${value || 'none'}`} />}>
            <MenuItem value=''>
                <ListItemText>
                    <FormattedMessage id='featureflags.detail.form.rules.logical_operator.choice.none' />
                </ListItemText>
            </MenuItem>
            <MenuItem value='and'>
                <ListItemText>
                    <FormattedMessage id='featureflags.detail.form.rules.logical_operator.choice.and' />
                </ListItemText>
            </MenuItem>
            <MenuItem value='or'>
                <ListItemText>
                    <FormattedMessage id='featureflags.detail.form.rules.logical_operator.choice.or' />
                </ListItemText>
            </MenuItem>
        </Field>
    </DraggableCard>;
});

const useStyles = makeStyles((theme) => ({
    form: {
        margin: '0px'
    },
    rulesContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        rowGap: `${theme.spacing(1)}px`,
        '& + .MuiButton-root': {
            marginTop: `${theme.spacing(1)}px`
        }
    },
    infoCard: {
        width: '100%',
        '& .MuiCardContent-root': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: `${theme.spacing(1)}px`,
            padding: `${theme.spacing(1)}px`
        },
        '& .referenceItem': {
            backgroundColor: theme.palette.grey[300],
            borderRadius: `${theme.spacing(1.5)}px`,
            padding: `${theme.spacing(0.5)}px`
        }
    }
}));

/**
 * Render detail page of FeatureFlag
 */
function Detail(props) {
    // feature flag is rendered only for admins, and they have RW
    const {permission = 'RW', edit, state, intl, formValues, initializingFeatureFlags} = props;
    const {rules, enabled} = formValues;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    /**
     * During initialization fetch definition if needed
     */
    useEffect(() => {
        if (props.loaded && edit && props.featureFlag && !props.definition) {
            dispatch(fetchItem(FeatureFlagDefinition, new FeatureFlagDefinition().getPlacement(), props.featureFlag.getIn(['links', 'definition']), {affect_state: false}));
        }
    }, [props.loaded]);

    return <div>
        {initializingFeatureFlags
            ? <Card>
                <CardHeader title={<FormattedMessage id='featureflags.detail.title' />}
                            action={<ActionButton iconButton disabled><ModelIcon model='feature_flags' /></ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.initialValues
                ? <ErrorCard title={<FormattedMessage id='featureflags.detail.notfound.title' />}
                            text={<FormattedMessage id='featureflags.detail.notfound.text' />}
                            icon={<WarningIcon color='secondary' />} />
                : <Card>
                    <CardHeader title={<FormattedMessage id={`featureflags.detail.${edit ? 'edit' : 'add'}.title`} />}
                        subheader={<FormattedMessage id={`featureflags.detail.${edit ? 'edit' : 'add'}.subtitle`} />}
                        action={<ActionButton iconButton disabled><ModelIcon model='feature_flag' /></ActionButton>}/>
                    <CardContent>
                        <Form className={classes.form} onSubmit={props.handleSubmit}>
                            <Field fieldType='TextField' name='name' required disabled={edit || permission !== 'RW'}
                                label={intl.formatMessage({id: 'featureflags.detail.form.name.label'})}
                                helperText={intl.formatMessage({id: 'featureflags.detail.form.name.helperText'})} />
                            <Field fieldType='Checkbox' name='enabled' disabled={permission !== 'RW'}
                                label={intl.formatMessage({id: 'featureflags.detail.form.enabled.label'})}
                                helperText={intl.formatMessage({id: 'featureflags.detail.form.enabled.helperText'})} />
                            {!!props.definition?.get('description') && <React.Fragment>
                                <Card className={classes.infoCard}>
                                    <CardContent>
                                        <InfoIcon />
                                        <Typography variant='body2'>{props.definition.get('description')}</Typography>
                                    </CardContent>
                                </Card>
                                <SpaceDivider half />
                            </React.Fragment>}
                            <Card className={classes.infoCard}>
                                <CardContent>
                                    <RestrictionIcon color='primary' />
                                    <Typography variant='body2'><FeatureFlagStatusMessage featureFlag={{enabled, rules}} /></Typography>
                                </CardContent>
                            </Card>
                            {((permission === 'RW' && !!props.definition?.get('allowed_context_values').size) || !!rules?.length) && <React.Fragment>
                                <hr /><Typography><FormattedMessage id='featureflags.detail.rules.title' /></Typography><hr />
                            </React.Fragment>}
                            {!!rules?.length && <div className={classes.rulesContainer}>
                                <SortableContainer items={rules?.map((_, idx) => idx + 1)} Item={FeatureFlagRuleDetail}
                                    getItemProps={(ruleIndex) => {
                                        const index = ruleIndex - 1;

                                        return ({rule: rules[index], index, hasNextSibling: !!rules[ruleIndex], permission, definition: props.definition,
                                            onRemove: () => props.change('rules', rules.filter((_, idx) => idx !== index))});
                                    }}
                                    onDragEnd={({active, over}) => props.change('rules', arrayMove(rules, active.id - 1, over.id - 1))} />
                            </div>}
                            {(permission === 'RW' && !!props.definition?.get('allowed_context_values').size) && <Button variant='outlined' startIcon={<AddIcon />}
                                onClick={() => props.change('rules', [...rules, {operator: 'in', context_key: '', reference: [], logical_operator: ''}])}>
                                <FormattedMessage id='featureflags.detail.rules.add' />
                            </Button>}
                        </Form>
                    </CardContent>
                    <ThemeProvider alt>
                        {['fetching_items_feature-flags', 'saved_item_feature-flags', 'failed_save_item_feature-flags'].includes(state)
                            ? <CardActionsLoader success={['saved_item_feature-flags'].includes(state)}
                                failure={['failed_save_item_feature-flags'].includes(state)} postAnimation={() => props.setState(null)} />
                            : <CardActions>
                                <Button startIcon={<CancelIcon />} onClick={() => props.history.push('/feature-flags')}>
                                    <FormattedMessage id='actions.cancel' />
                                </Button>
                                {permission === 'RW' && <React.Fragment>
                                    {(edit && false) && <Button startIcon={<DeleteIcon />} variant='contained' color='secondary' onClick={() => setOpenDeleteDialog(true)}>
                                        <FormattedMessage id='actions.delete' />
                                    </Button>}
                                    <Button startIcon={<SaveIcon />} variant='contained' color='primary' type='submit'
                                            onClick={() => props.handleSubmit()}>
                                        <FormattedMessage id='actions.save' />
                                    </Button>
                                    <DeleteDialog open={openDeleteDialog} handleClose={() => setOpenDeleteDialog(false)}
                                        item={intl.formatMessage({id: 'featureflags.detail.delete.item'})}
                                        handleConfirm={() => {
                                            const placement = new FeatureFlag().getPlacement();
                                            props.deleteItem(FeatureFlag, placement, props.featureFlag.getIn(['links', 'self']), props.featureFlag)
                                                .then(() => props.history.push('/feature-flags'));
                                        }}
                                    />
                                </React.Fragment>}
                            </CardActions>
                        }
                    </ThemeProvider>
                </Card>}
    </div>;
}

const validate = (data, props) => {
    const errors = {};

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'rules', data.rules);

    if (data.rules) {
        errors.rules = [];

        data.rules.forEach((rule, index) => {
            errors.rules[index] = {};

            validator.isNotNull(null, errors, `rules.${index}.reference`, rule.reference);
            validator.isNotNull(null, errors, `rules.${index}.context_key`, rule.context_key);
            validator.isNotNull(null, errors, `rules.${index}.operator`, rule.operator);
            if (data.rules[index + 1]) {
                validator.isNotNull(props.intl.formatMessage({id: 'featureflags.detail.form.rules.logical_operator.error.remove_next_rule'}),
                    errors, `rules.${index}.logical_operator`, rule.logical_operator);
            } else {
                validator.isEmpty(props.intl.formatMessage({id: 'featureflags.detail.form.rules.logical_operator.error.create_next_rule'}),
                    errors, `rules.${index}.logical_operator`, rule.logical_operator);
            }
        });
    }

    return errors;
};

const DetailForm = reduxForm({
    form: 'featureFlagForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const rest_of_data = new FeatureFlag().popUpFields(values);
        const placement = new FeatureFlag().getPlacement();

        // save object
        return props.saveItem(
            FeatureFlag, placement, ['feature-flags', values.name], rest_of_data, props.featureFlag,
            {save_method: 'put', update_method: 'put', add_mark_filtered: false}
        ).then(create_data => {
            // in case of add, redirect to detail
            if (!props.featureFlag && create_data.get(new FeatureFlag().getUniqueIdentifier())) {
                props.history.push(`/feature-flags/${create_data.get(new FeatureFlag().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

export default withRouter(injectIntl(connect((state, props) => {
    const {match} = props;
    const edit = !!match.params.identifier;

    const featureFlag = state.shared.getIn(['items', new FeatureFlag().getPlacement()])
        .find(item => item.get(new FeatureFlag().getUniqueIdentifier()) === match.params.identifier);

    return {
        state: state.app.get('state'),
        initializingFeatureFlags: ![null, 'ready'].includes(state.api.get('state')),
        loaded: state.shared.getIn(['loaded', 'feature-flags']),
        featureFlag, edit,
        definition: edit && state.shared.getIn(['items', new FeatureFlagDefinition().getPlacement()]).find(def => def.get('name') === match.params.identifier),
        initialValues: edit ? featureFlag?.toJS() : {enabled: true, rules: []},
        formValues: {
            enabled: state.form.featureFlagForm?.values?.enabled,
            rules: state.form.featureFlagForm?.values?.rules || []
        }
    };
}, (dispatch) => bindActionCreators({setState, saveItem, deleteItem}, dispatch))(DetailForm)));
