import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUIExpansionPanelSummary from '@material-ui/core/AccordionSummary';


const useStyles = makeStyles(theme => ({
    expansionPanelSummary: {
        overflow: 'hidden' // fix appearing of bottom slider during opening
    },
    expansionPanelSummaryContent: {
        flexDirection: 'column' // support for second heading
    },
    noLink: {
        cursor: 'default !important', // default cursor instead of 'link' like
        '& > div > :last-child': {
            paddingRight: '0' // we don't have button, so get rid of mui padding
        }
    }
}));

/**
 * MUI ExpansionPanelSummary with optional styles
 *
 * 'noLink' - Get rid of link cursor and additional Icon space
 */
function ExpansionPanelSummary(props) {
    // Split some stuff from props
    const {_classes, className, noLink, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, content, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        root, className,
        classes.expansionPanelSummary,
        noLink ? classes.noLink : ''
    ].filter(Boolean).join(' ');
    const contentClasses = [
        content,
        classes.expansionPanelSummaryContent
    ].filter(Boolean).join(' ');

    return <MUIExpansionPanelSummary
        classes={{
            root: rootClasses,
            content: contentClasses,
            ...rest_of_classes
        }}
        {...rest_of_props} />;
}
ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

export default ExpansionPanelSummary;
