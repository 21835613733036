import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {ProvisioningFileType} from 'lib/models';
import {useLocalSort, useLocalFilter} from 'lib/filters';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {SearchField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders PBXPartners from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new ProvisioningFileType().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'name', label: <FormattedMessage id='provfiletypes.list.table.name' />},
        {sortName: 'code', label: <FormattedMessage id='provfiletypes.list.table.code' />},
        {sortName: 'created_at', label: <FormattedMessage id='provfiletypes.list.table.created_at' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName));
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new ProvisioningFileType().getPlacement(),
        ['search'], ['name', 'code']);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(ProvisioningFileType, items_placement, 'provisioning-file-types'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='provfiletypes.list.title' />}
            subheader={<FormattedMessage id='provfiletypes.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/provisioning-file-types/add')}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='provisioning_file_types' />
            </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='provfiletypes.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <em>
                                <FormattedMessage id={props.items.size ? 'filters.empty' : 'provfiletypes.list.table.empty'}
                                                  values={{link: passed_props.permission === 'RW'
                                                          ? <Link to='/provisioning-file-types/add' className='hover-border'>
                                                              <FormattedMessage id='provfiletypes.list.table.empty.link' />
                                                          </Link>
                                                          : <FormattedMessage id='provfiletypes.list.table.empty.link' />}} />
                            </em>
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map(type =>
                        <TableRow key={type.get(new ProvisioningFileType().getUniqueIdentifier())} link hover
                                  onClick={() => props.state === null ? history.push(`/provisioning-file-types/${type.get(new ProvisioningFileType().getUniqueIdentifier())}`) : {}}>
                            <TableCell>{type.get('name')}</TableCell>
                            <TableCell>{type.get('code')}</TableCell>
                            <TableCell>{Moment(type.get('created_at')).format('l, LT')}</TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>} />
    </Card>;
}
