import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router';
import {FormattedMessage} from 'react-intl';
import {Page} from 'lib/models';
import {useLocalSort, useLocalFilter} from 'lib/filters';
import Moment from 'moment';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import {SearchField, LanguageField, DynamicPageSlug} from 'components/core/ui/Field';
import {getLanguageName, LanguageIcon} from 'components/core/ui/fields/LanguageField';
import Filters from 'components/core/ui/Filters';
import StickyTable from 'components/core/ui/StickyTable';
import {Link} from 'react-router-dom';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Pages from backend - to view and manage them
 */
export default function List(passed_props) {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new Page().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'page_slug', label: <FormattedMessage id='pages.list.table.identifier' />},
        {sortName: 'language', label: <FormattedMessage id='pages.list.table.language' />},
        {sortName: 'created_at', label: <FormattedMessage id='pages.list.table.created_at' />},
        {sortName: 'updated_at', label: <FormattedMessage id='pages.list.table.updated_at' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName), 'page_slug');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new Page().getPlacement(), ['search', 'page_slug', 'language'], ['page_slug']);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(Page, items_placement, 'pages'));
        }
    }, [props.loaded]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='pages.list.title' />}
            subheader={<FormattedMessage id='pages.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push('/pages/add/?edit_advanced')}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='pages' />
            </ActionButton>}
        />
        <Filters>
            <SearchField label={<FormattedMessage id='pages.list.search' />}
                         value={filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <DynamicPageSlug
                fieldType='NoReduxSelect' value={filters.page_slug || ''}
                onChange={event => filterItems('page_slug', event.target.value)} />
            <LanguageField fieldType='NoReduxSelect' value={filters.language || ''}
                           onChange={(event) => filterItems('language', event.target.value)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                <TableSortLabel active={sortName === item.sortName}
                                                direction={sortDirection}
                                                onClick={() => sortItems(item.sortName)}>
                                    {item.label}
                                </TableSortLabel>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <em>
                                <FormattedMessage id={props.items.size ? 'filters.empty' : 'pages.list.table.empty'}
                                                  values={{link: passed_props.permission === 'RW'
                                                          ? <Link to='/pages/add/?edit_advanced' className='hover-border'>
                                                              <FormattedMessage id='pages.list.table.empty.link' />
                                                          </Link>
                                                          : <FormattedMessage id='pages.list.table.empty.link' />}} />
                            </em>
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map(page =>
                        <TableRow key={page.get(new Page().getUniqueIdentifier())} link hover
                                  onClick={() => props.state === null ? history.push(`/pages/${page.get(new Page().getUniqueIdentifier())}`) : {}}>
                            <TableCell name>
                                <ModelIcon model={page.get('page_slug')} className='icon' />
                                <span className='text'><FormattedMessage id={`menu.customer.portal.${page.get('page_slug')}`} /></span>
                            </TableCell>
                            <TableCell name>
                                <LanguageIcon code={page.get('language')} className='icon' />
                                <span className='text'>{getLanguageName(page.get('language'))}</span>
                            </TableCell>
                            <TableCell>{Moment(page.get('created_at')).format('l, LT')}</TableCell>
                            <TableCell>{page.get('updated_at') && Moment(page.get('updated_at')).format('l, LT')}</TableCell>
                        </TableRow>
                    )}
                </TableBody>}
            </Table>} />
    </Card>;
}
