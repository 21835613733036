import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    root: {
        // red background, white text
        background: theme.palette.danger['main'],
        color: theme.palette.common.white,
        // card like
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
        // proportion
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        margin: `${theme.spacing(1)}px auto`,
        textAlign: 'center',
        '&.center': {
            width: '320px' // limit to Field size
        },
        // variants
        '&.warn': {
            backgroundColor: theme.palette.orange[500]
        },
        // change style of link
        '& a': {
            fontWeight: theme.typography.fontWeightMedium,
            '&, &:not(.MuiButton-root):hover': {
                color: theme.palette.common.white
            },
            '&.hover-border': {
                '&:after, &:hover:after': {
                    background: theme.palette.common.white
                }
            }
        }
    }
}));

/**
 * Error message used to display non_field_errors in redux-form
 */
export default function ErrorMessage(props) {
    // Split some stuff from props
    const {className, center = false, warn = false, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        classes.root, className,
        center ? 'center' : '',
        warn ? 'warn' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} {...rest_of_props} />;
}
