import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment';
import {useTierCalculation} from 'components/modules/subscription/TierLabel';
import {getCurrencySymbol} from 'lib/util';
// material ui
import CardContent from 'components/core/ui/mui/CardContent';


const useStyles = makeStyles(theme => ({
    // root grid
    statsHolder: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(2)}px`
    },
    // right side texts
    texts: {
        '& .text': {
            // change proportion
            margin: `0 0 ${theme.spacing(1)}px`
        }
    }
}));

/**
 * Statistics about usage
 */
export default function Stats(props) {
    const {company, subscription, subscriptionPlan, className, isFreeDm} = props;
    const classes = useStyles();
    // local state
    const [calculatedPrice] = useTierCalculation(subscription?.get('dm_enabled_endpoints'), subscriptionPlan?.get('tiers'));
    const formattedCalculatedPrice = calculatedPrice.toFixed(2);

    return <CardContent
        className={[
            classes.statsHolder, className
        ].filter(Boolean).join(' ')}>
        <div className={classes.texts}>
            {subscription && !isFreeDm && <p className='text newfont'>
                <FormattedMessage
                    id='subscription.stats.period.text'
                    values={{
                        start_date: <strong className='nowrap'>{subscription && Moment(subscription.get('current_period_start')).format('ll')}</strong>,
                        end_date: <strong className='nowrap'>{subscription && Moment(subscription.get('current_period_end')).format('ll')}</strong>
                    }}
                />
                {subscription?.get('trial_active') && <strong>{' '}<FormattedMessage id='subscription.stats.period.trial' /></strong>}
            </p>}
            {(!isFreeDm && subscription && subscriptionPlan) && <p className='text newfont'>
                <FormattedMessage
                    id='subscription.stats.usage.text'
                    values={{
                        used: <React.Fragment><strong>{subscription.get('dm_enabled_endpoints')}{(subscription?.get('trial_active') && subscriptionPlan?.get('trial_limit')) && `/${subscriptionPlan?.get('trial_limit')}`}</strong></React.Fragment>,
                        price: <strong>{`${subscription?.get('trial_active') ? '0' : formattedCalculatedPrice} ${getCurrencySymbol(subscriptionPlan?.get('currency'))}`}</strong>
                    }}
                />
            </p>}
            <p className='text newfont'>
                {company.get('dm_compatible_endpoints')
                    ? <FormattedMessage
                        id={`subscription.stats.compatible.text${!company.get('dm_compatible_endpoints') ? '.no' : ''}`}
                        values={{amount: <strong>{company.get('dm_compatible_endpoints')}</strong>}}
                    />
                    : <strong>
                        <FormattedMessage id='subscription.stats.compatible.text.no' />
                    </strong>}
            </p>
        </div>
    </CardContent>;
}
