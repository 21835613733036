import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage, useIntl} from 'react-intl';
import validator from 'lib/valitator';
import {snomRGBAtoHex} from 'lib/util';
import {Setting} from 'lib/models';
// Components
import SortableContainer from 'components/core/ui/Sortable';
import {arrayMove} from '@dnd-kit/sortable';
import Field, {FieldIcon, FieldWithIconHolder, TimeField, ColorField, CertificateField, IconButtonSelect} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import Chip from '@material-ui/core/Chip';
// icons
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import PermissionIcon from '@material-ui/icons/LockOutlined';
import DragHandleIcon from '@material-ui/icons/SwapVertOutlined';


const useStyles = makeStyles(theme => ({
    // sortable multi select
    sortableChoices: {
        marginBottom: `-${theme.spacing(1)}px`
    },
    sortableChoice: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'row-resize', // correct mouse
        background: theme.palette.common.white,
        overflow: 'hidden',
        marginBottom: `${theme.spacing(1)}px`,
        position: 'relative'
    },
    // preview of background image
    backgroundImageHolder: {
        // field proportion
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
        // limit size
        width: '320px',
        height: '240px',
        // style
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        // image
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain'
    }
}));


/**
 * Renders Field based on provided Setting with attrs
 */
export default function SettingField(props) {
    const classes = useStyles();
    const intl = useIntl();
    // local state
    const [showPassword, setShowPassword] = useState(false); // password visibility
    const [selectOpen, setSelectOpen] = useState(false); // controlled select
    // shortcut to make code slightly more readable
    const field_type = props.setting.get('field_type');
    const field_name = `${props.namePrefix ? `${props.namePrefix}.` : ''}${props.name || 'value'}`;
    // additional props for some field types
    const additional_props = {};
    let noneChoice;

    if (field_type === 'select') {
        if (props.setting.get('multiple')) {
            if (props.setting.get('sortable')) {
                // change select into controlled
                additional_props['open'] = selectOpen === true; // might be 'prevent'
                additional_props['onOpen'] = () => selectOpen === false ? setSelectOpen(true) : {};
                additional_props['onClose'] = () => setSelectOpen(false);
            } else {
                // make sure we have correct order for no-sortable multiple select
                additional_props['onChange'] = (event, newValue, previousValue, name) =>
                    props.change(name, [...newValue.sort((a, b) => a.toLowerCase() === b.toLowerCase() ? 0 : a.toLowerCase() < b.toLowerCase() ? -1 : 1)]);
            }
            // render correctly
            additional_props['renderValue'] = (values) => {
                let selectedChoices = props.setting.get('choices').filter(el => values.includes(el.get('value'))) // get Choice(s) instead of just values
                    .sort((a, b) => values.indexOf(a.get('value')) - values.indexOf(b.get('value'))); // keep order of values
                if (props.setting.get('sortable')) {
                    return <div className={classes.sortableChoices}>
                        <SortableContainer
                            items={selectedChoices.map(choice => choice.get('value'))}
                            Item={Chip}
                            getItemProps={(item_value) => {
                                const item = selectedChoices.find(choice => choice.get('value') === item_value);
                                return {
                                    variant: 'outlined',
                                    icon: <DragHandleIcon />,
                                    className: classes.sortableChoice,
                                    label: item.get('label')
                                };
                            }}
                            onDragStart={() => setSelectOpen('prevent')}
                            onDragEnd={({active, over}) => {
                                props.change(field_name, arrayMove(values,
                                    values.findIndex(choice_value => choice_value === active.id),
                                    values.findIndex(choice_value => choice_value === over.id)
                                ));
                                setTimeout(() => setSelectOpen(false), 0);
                            }} />
                    </div>;
                } else {
                    return selectedChoices.map(el => el.get('label')).join(', '); // get labels and join them with ,
                }
            };
        } else {
            // check if we have empty choice provided
            if (props.setting.get('choices').find(el => !el.get('value'))) {
                // tweak select to display provided 'None' value
                additional_props['displayEmpty'] = true;
                additional_props['InputLabelProps'] = {shrink: true};
            } else {
                // assign default none choice
                noneChoice = <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>;
            }
        }
    } else if (field_type === 'password' && props.selectedValue === '__hidden__') {
        additional_props['disabled'] = true;
    }
    // get correct Field component
    let FieldComponent = Field;
    switch (field_type) {
        case 'color':
            FieldComponent = ColorField;
            break;
        case 'time':
            FieldComponent = TimeField;
            break;
        case 'certificate':
            FieldComponent = CertificateField;
            break;
    }
    // decide correct component for attrs.perm field
    const PermField = props.indexed_inside ? Field : IconButtonSelect;
    const permFieldProps = props.indexed_inside
        ? {fieldType: 'Select'}
        : {intlPath: 'permfield.attrs.perm', emptyIcon: <PermissionIcon />};

    return <React.Fragment>
        <FieldWithIconHolder>
            <FieldComponent
                name={field_name}
                fieldType={['textarea', 'certificate'].includes(field_type)
                    ? 'TextArea'
                    : ['url', 'url_image'].includes(field_type)
                    ? 'URLField'
                    : field_type === 'checkbox'
                    ? 'Checkbox'
                    : field_type === 'select'
                    ? 'Select'
                    : 'TextField'}
                type={field_type === 'password'
                    ? !showPassword || additional_props.disabled ? 'password' : 'text'
                    : field_type === 'number'
                    ? 'number'
                    : field_type === 'checkbox'
                    ? 'checkbox'
                    : 'text'}
                multiple={field_type === 'select' && props.setting.get('multiple') ? true : undefined}
                withIcon={field_type === 'password' ? true : undefined}
                label={`${props.label || props.setting.get('name') || intl.formatMessage({id: 'settings.detail.preview.label'})}${!props.setting.allow_null && field_type !== 'checkbox' && props.required !== false ? '*' : ''}`}
                helperText={props.setting.get('description')
                    ? typeof props.setting.get('description') === 'string'
                        ? <span dangerouslySetInnerHTML={{__html: props.setting.get('description')}} />
                        : props.setting.get('description')
                    : undefined}
                selectedValue={props.selectedValue}
                change={props.change}
                excludePermission={props.excludePermission}
                autoFocus={props.autoFocus}
                {...additional_props}>
                {noneChoice}
                {field_type === 'select' && props.setting.get('choices') && props.setting.get('choices').map((choice, idx) =>
                    <MenuItem key={idx} value={choice.get('value') || ''}>{choice.get('label')}</MenuItem>
                )}
            </FieldComponent>
            {field_type === 'password' &&
                <FieldIcon disabled={!!additional_props.disabled}
                           onClick={() => !additional_props.disabled ? setShowPassword(!showPassword) : {}}>
                    {!showPassword || additional_props.disabled ? <Visibility /> : <VisibilityOff />}
                </FieldIcon>
            }
        </FieldWithIconHolder>
        {props.setting.get('uses_permissions') && <React.Fragment>
            {(field_type === 'checkbox' && props.indexed_inside) && <SpaceDivider none />}
            <PermField name={props.namePrefix ? `${props.namePrefix}.attrs.perm` : 'attrs.perm'}
                       label={`${intl.formatMessage({id: 'permfield.attrs.perm'})}*`}
                       excludePermission={props.excludePermission}
                       {...permFieldProps}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='RW'><FormattedMessage id='permfield.attrs.perm.rw.long' /></MenuItem>
                <MenuItem value='!'><FormattedMessage id='permfield.attrs.perm.!.long' /></MenuItem>
                <MenuItem value='R'><FormattedMessage id='permfield.attrs.perm.r.long' /></MenuItem>
                <MenuItem value='V'><FormattedMessage id='permfield.attrs.perm.v.long' /></MenuItem>
            </PermField>
        </React.Fragment>}
        {(field_type === 'url_image' && props.selectedValue) && <React.Fragment>
            <SpaceDivider none />
            <div className={classes.backgroundImageHolder}
                 style={{backgroundImage: `url("${props.selectedValue}")`}} />
        </React.Fragment>}
    </React.Fragment>;
}

/**
 * Validate SettingField. Ensure that field is correct type and optionally required
 *
 * @param values - Field values, e.g. {value: 'something', attr: {perm: 'rw'}}
 * @param setting - Setting Model
 * @param intl - intl for formattedMessage errors
 * @param required - notNull t/f
 */
export const validateSettingsField = (values, setting, intl, required = null) => {
    const errors = {attrs: {}};
    const nested_attrs = values.attrs || {}; // nested fix

    // validate attrs
    if (setting.get('uses_permissions')) {
        validator.isNotNull(null, errors, 'attrs.perm', nested_attrs.perm);
    }

    // validate Value
    if (values.value) {
        const field_type = setting.get('field_type');

        // validation of FieldArray
        if (['certificate'].includes(field_type)) {
            if (values.value && values.value.length) {
                values.value.forEach((el, idx) => {
                    // prepare error array
                    if (!errors['value']) {
                        errors['value'] = {};
                    }
                    // make validation
                    errors['value'][idx] = validateSettingsField({value: values.value[idx]}, new Setting({field_type: 'certificate_inside', allow_null: setting.allow_null}), intl).value;
                });
            }
        } else if (field_type === 'number') {
            // number must be obviously number
            validator.isInt(null, errors, 'value', values.value);
        } else if (field_type === 'url_image') {
            // http / https url
            validator.isURL(null, errors, 'value', values.value,
                {protocols: ['http', 'https'], require_protocol: true});
        } else if (field_type === 'ip') {
            // ipv4 and ipv6 validation is separate (validator doesn't support both at one)
            const ip_4 = {};
            const ip_6 = {};
            validator.isIP(null, ip_4, 'value', values.value, 4);
            validator.isIP(null, ip_6, 'value', values.value, 6);
            // check if both (ip v4 or v6) are invalid
            if (!!ip_4['value'] && !!ip_6['value']) {
                // add generic error
                errors['value'] = intl.formatMessage({id: 'errors.validator.ipaddress'});
            }
        } else if (field_type === 'color') {
            // color in Snom RGB, validated as HEX
            validator.isHexColor(intl.formatMessage({id: 'errors.validator.snomcolor'}), errors, 'value', snomRGBAtoHex(values.value));
        } else if (field_type === 'time') {
            // HH:mm time
            validator.isDateTime(intl.formatMessage({id: 'errors.validator.generic'}), errors, 'value', values.value, 'HH:mm');
        } else if (field_type === 'certificate_inside') {
            // check if certificate is base64 encoded
            if (values.value.match(/^[0-9a-zA-Z]+[=]{0,3}$/)) {
                errors['value'] = intl.formatMessage({id: 'certificatefield.error'});
            }
        }
    } else if (required === true || (required !== false && !setting.allow_null)) {
        // required
        validator.isNotNull(null, errors, 'value', values.value);
    }

    return errors;
};

/**
 * Get Initial Value for specific Setting
 *
 * @param setting - Setting model
 * @param options - {} contains optional options
 *  use_default     - default: true; Should we use Setting 'default_value' - we don't want to use it for already existing items
 */
export const getSettingInitialValue = (setting, options = {}) => {
    // process options and it's defaults
    const use_default = options.use_default !== undefined ? options.use_default : true;

    const values = {};
    if (setting.get('uses_permissions')) {
        values['attrs'] = {perm: 'RW'};
    }

    if (setting.get('field_type') === 'certificate') {
        values['attrs'] = {...values['attrs'], type: 'base64'};
        values['value'] = [];
    }

    if (use_default) {
        if (setting.get('default_value')) {
            if (setting.get('field_type') === 'checkbox') {
                // on / true -> 'true', everything else 'false'
                values['value'] = ['on', 'true', true].includes(setting.get('default_value').toLowerCase());
            } else {
                values['value'] = setting.get('default_value');
            }
        } else if (setting.get('field_type') === 'checkbox') {
            values['value'] = false;
        } else if (setting.get('field_type') === 'time') {
            values['value'] = '12:00';
        } else {
            values['value'] = '';
        }
    }

    return values;
};

/**
 * Get Redux-Form InitialValues for Settings
 *
 * @param state - state from connect
 * @param options - {} contains optional options
 *  settings_path   - default: ['items', 'settings']; where are Settings placed
 *  use_default     - default: true; Should we use Setting 'default_value' - we don't want to use it for already existing items
 */
export const getSettingsInitialValues = (state, options = {}) => {
    // process options and it's defaults
    const settings_path = options.settings_path !== undefined ? options.settings_path : ['items', 'settings'];
    const use_default = options.use_default !== undefined ? options.use_default : true;

    const initialValues = {};
    state.shared.getIn(settings_path).forEach((setting) => {
        // setting that are not indexed, default values for indexed fields are handled in IndexedSettingField
        if (setting.get('indexed') && !setting.getIn(['indexed', 'enabled'])) {
            initialValues[setting.get(new Setting().getUniqueIdentifier())] = getSettingInitialValue(setting, {use_default: use_default});
        }
    });

    return initialValues;
};
