import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// Actions
import {login, setState as setAuthState} from 'actions/auth';
// Components
import PlatformSwitcher from 'components/core/ui/PlatformSwitcher';
import {Link, withRouter} from 'react-router-dom';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import ErrorMessage from 'components/core/ui/ErrorMessage';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import SRAPSLoginWave from 'components/core/vectors/waves/SRAPSLogin';
import PPLoginWave from 'components/core/vectors/waves/PPLogin';
import SubmitIcon from '@material-ui/icons/LockOpenOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import RegisterIcon from '@material-ui/icons/PersonAddOutlined';


const useStyles = makeStyles(theme => ({
    button: {
        width: '260px',
        maxWidth: '100%'
    },
    // size limiter to match size of button
    cardActionsLoaderHolder: {
        width: `${theme.spacing(4) + 260}px`,
        maxWidth: '100%',
        margin: '0 auto'
    },
    // holder of link and platform switcher
    links: {
        position: 'relative',
        zIndex: '20', // above waves
        textAlign: 'center'
    },
    // holder of SRAPS and Partner Portal waves
    wavesHolder: {
        position: 'relative',
        zIndex: '10', // bellow content
        // move into content
        marginTop: `-${theme.spacing(4)}px`,
        // actual space taken by waves
        '&:after': {
            display: 'block',
            content: '""',
            // maintain ratio
            paddingBottom: '29.46%' // wave size 277/940 => 0.2946..
        },
        // waves itself
        '& svg': {
            // position
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            // smooth transition
            transition: theme.transitions.create('opacity',
                {duration: theme.transitions.duration.short}),
            // default state
            '&.sraps': {
                opacity: '1'
            },
            '&.pp': {
                opacity: '0'
            }
        },
        // waves transition
        '&.portal': {
            '& svg': {
                '&.sraps': {
                    opacity: '0'
                },
                '&.pp': {
                    opacity: '1'
                }
            }
        }
    }
}));

/**
 * Simple Login page with form
 */
function Login(props) {
    const classes = useStyles();
    // local state
    const [showPassword, setShowPassword] = useState(false);

    return <Card>
        <SpaceDivider />
        <CardHeader title={<FormattedMessage id={props.portal ? 'login.portal.title' : 'login.title'} />}
                    subheader={<FormattedMessage id='login.subheader' />}
                    center newfont />
        <CardContent>
            <Form onSubmit={props.handleSubmit} center>
                <Field name='username' fieldType='TextField' label={<FormattedMessage id='login.form.fields.username' />} />
                <SpaceDivider none />
                <FieldWithIconHolder>
                    <Field withIcon name='password' type={showPassword ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='login.form.fields.password' />} />
                    <FieldIcon onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </FieldIcon>
                </FieldWithIconHolder>
            </Form>
            {(props.submitFailed && props.error && props.error === 'invalid_credentials') &&
                <ErrorMessage center><FormattedMessage id='login.error.invalid_credentials' /></ErrorMessage>
            }
        </CardContent>
        {['logging', 'login_failed'].includes(props.authState)
            ? <div className={classes.cardActionsLoaderHolder}>
            <CardActionsLoader failure={props.authState === 'login_failed' && props.error !== 'not_approved'}
                               success={props.authState === 'login_failed' && props.error === 'not_approved'}
                               postAnimation={success => {
                                   if (success) { // success here means not approved
                                       props.setAuthState('not_approved');
                                       props.history.push(`${props.portal ? '/partner' : ''}/not-approved`);
                                   } else {
                                       props.setAuthState(null);
                                   }
                               }} center />
        </div>
            : <CardActions center>
            <Button variant='contained' color='primary' type='submit'
                    onClick={props.handleSubmit}
                    className={classes.button}>
                <SubmitIcon />
                <FormattedMessage id='login.form.submit' />
            </Button>
        </CardActions>}
        <CardActions center>
            <Button variant='outlined' color='primary'
                    onClick={() => props.history.push(`${props.portal ? '/partner' : ''}/registration`)}
                    className={classes.button}>
                <RegisterIcon />
                <FormattedMessage id='login.register' />
            </Button>
        </CardActions>
        <div className={classes.links}>
            <SpaceDivider />
            <Link className='hover-border' to={`${props.portal ? '/partner' : ''}/forgotten-password`}><FormattedMessage id='login.forgottenpassword' /></Link>
            <SpaceDivider double />
            <PlatformSwitcher />
        </div>
        <div className={`${classes.wavesHolder}${props.portal ? ' portal' : ''}`}>
            <SRAPSLoginWave className='sraps' />
            <PPLoginWave className='pp' />
        </div>
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'username', data.username);
    validator.isNotNull(null, errors, 'password', data.password);

    return errors;
};

const LoginForm = reduxForm({
    form: 'loginForm',
    initialValues: process.env.NODE_ENV === 'development' ? {
        username: 'admin',
        password: 'adminadmin'
    } : {},
    validate,
    onSubmit: (values, dispatch, props) => (
        dispatch(login(props.formValues))
    )
})(Login);

const ConnectedLogin = connect((state, props) => ({
    portal: props.location.pathname.startsWith('/partner'),
    authState: state.auth.get('state'),
    formValues: getFormValues('loginForm')(state)
}), (dispatch) => bindActionCreators({
    login,
    setAuthState
}, dispatch))(LoginForm);

export default withRouter(ConnectedLogin);
