import React from 'react';
import {createRoot} from 'react-dom/client';
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import reduxThunk from 'redux-thunk';
import applicationStore from 'reducers';
import {initializeApi} from 'actions/api';
import {createBrowserHistory} from 'history';
import {routerMiddleware, ConnectedRouter} from 'connected-react-router';
import ThemeProvider from 'components/ThemeProvider';
import storage from 'store';
import Moment from 'moment';
import {initializeSentry, sentryReduxEnhancer} from 'lib/sentry';
// Locale
import {getINTLMessages} from 'lib/localize';
import {IntlProvider} from 'react-intl-redux';


const history = createBrowserHistory({});
// startup Sentry to log errors
initializeSentry(history);

function getInitialState() {
    // get language from storage
    let locale = storage.get(`__datastore-${process.env.REACT_APP_STORAGE_PREFIX}-lang`);
    // we don't have lang, guess it
    if (!locale) {
        locale = window.navigator.userLanguage || window.navigator.language;
    }
    // make sure that we have translated lang
    if (!['en', 'de', 'es', 'it', 'fr', 'ru', 'keys'].includes(locale)) { // 'cs'
        locale = 'en';
    }

    // get messages and new locale
    const [messages, processedLocale] = getINTLMessages(locale);

    // localize other libs
    Moment.locale(processedLocale);
    // set initial INTL state
    return {
        intl: {
            locale: processedLocale,
            messages: messages
        }
    };
}

const store = createStore(
    applicationStore(history), getInitialState(), compose(
        // Add redux thunk
        applyMiddleware(reduxThunk, routerMiddleware(history)),
        // Add sentry
        sentryReduxEnhancer,
        // Enable redux dev tools, if installed in chrome and not built for production
        process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

store.dispatch(initializeApi(process.env.REACT_APP_API_URL));

const root = createRoot(document.getElementById('react-container'));
root.render(<Provider store={store}>
    <IntlProvider textComponent='span'>
        <ConnectedRouter history={history}>
            <ThemeProvider />
        </ConnectedRouter>
    </IntlProvider>
</Provider>);
