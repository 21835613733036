import validator from 'validator';
import Moment from 'moment';

/**
 * The wrapper of all validator functions (overwriting them)
 *
 * @param validatorFunction - validation function e.g. isEmpty()
 * @param functionName - name of given function
 * @param defaultMessages - localized errors
 */
const wrappedFunction = (validatorFunction, functionName, defaultMessages) => {
    return (errorMessage = null, errorDict, key, value, ...args) => {
        // Coerce to string, always...
        if (typeof (value) === 'undefined' || value === null) {
            value = '';
        } else {
            value = value + '';
        }

        // Allow custom validators to set their own errors. This is actually not really pretty, to be hones
        let validationMetrics = {_error: null};

        if (!validatorFunction(value, ...args, validationMetrics)) {
            // Default error values
            if (errorMessage === null) {
                if (validationMetrics._error !== null) {
                    errorMessage = validationMetrics._error;
                } else {
                    if (functionName in defaultMessages) {
                        errorMessage = defaultMessages[functionName];
                    } else {
                        errorMessage = defaultMessages.__generic__;
                    }
                }
            }

            // Keys may be nested, so, figure that out
            let path = key.split('.');
            for (var i = 0, current = errorDict; i < path.length; i++) {
                if (!current.hasOwnProperty(path[i])) {
                    current[path[i]] = '';
                    break;
                }

                // Is it nested key?
                if (typeof current[path[i]] === 'object') {
                    current = current[path[i]];
                } else {
                    break;
                }
            }

            current[path[i]] = errorMessage;
            return false;
        }
        return true;
    };
};

// The validator object to use the wrapped API
let reduxFormValidator = {};

/**
 * Initialize validator for Form validations. Also adding custom functions.
 *
 * @param initializingValidator - redux validator
 */
export function initValidator(initializingValidator) {
    let messages = initializingValidator.defaultMessages;

    // Wrap all validator functions to work more nicely with redux-form
    for (let validatorFunction in validator) {
        if (!validator.hasOwnProperty(validatorFunction)) {
            continue;
        }

        // Copy the interface
        if (!validatorFunction.startsWith('is') && validatorFunction !== 'equals' && validatorFunction !== 'contains') {
            initializingValidator[validator] = validator[validatorFunction];
            continue;
        }

        // Use the wrapper for the validator
        initializingValidator[validatorFunction] = wrappedFunction(validator[validatorFunction], validatorFunction, messages);
    }

    /**
     * Custom validators
     */
    // Inversion of isEmpty
    initializingValidator.isNotNull = wrappedFunction((value) => {
        return !validator.isEmpty(value);
    }, 'isNotNull', messages);

    // Inversion of equals
    initializingValidator.equalsNot = wrappedFunction((value, comparision) => {
        return !validator.equals(value, comparision);
    }, 'equalsNot', messages);

    // Required boolean
    initializingValidator.isTrue = wrappedFunction((value) => {
        return validator.toBoolean(value) === true;
    }, null, messages);

    // isSlug
    initializingValidator.isSlug = wrappedFunction((value) => {
        return /^[-a-zA-Z0-9_]+$/.test(value);
    }, 'isSlug', messages);

    // isUnique
    initializingValidator.isUnique = wrappedFunction((value, items, identifier) => {
        return !items ? true : !items.find(el => identifier ? (el[identifier] || el.get(identifier)) === value : el === value);
    }, null, messages);

    // isUniqueTogether
    initializingValidator.isUniqueTogether = wrappedFunction((value, items, identifier = []) => {
        return !items ? true : !items.find(el => (el[identifier[0]] || el.get(identifier[0])) === value.split(';')[0] && (el[identifier[1]] || el.get(identifier[1])) === value.split(';')[1]);
    }, null, messages);

    // includes
    initializingValidator.includes = wrappedFunction((value, list) => {
        return list.includes(value);
    }, null, messages);

    // is valid date time from <DateField />
    initializingValidator.isDateTime = wrappedFunction((value, format = 'YYYY-MM-DDTHH:mm:ssZ') => {
        return Moment(value, format, true).isValid();
    }, 'isDateTime', messages);

    // date is in future
    initializingValidator.isInFuture = wrappedFunction((value, format = 'YYYY-MM-DDTHH:mm:ssZ') => {
        return Moment(value, format, true).isAfter();
    }, 'isInFuture', messages);

    // isURL applicable to lists, not only single value
    initializingValidator.isURLList = wrappedFunction((value, options, validationMetrics) => {
        // Split to list by newline...
        let urls = value.split('\n');

        // Figure out actual number of the domains, by stripping whitespaced rows
        let actualURLs = [];
        for (let d of urls) {
            // Strip whitespaces
            if (!d.replace(/ /g, '').length) {
                continue;
            }

            actualURLs.push(d);
        }

        // Set default errors
        validationMetrics._error = actualURLs.length === 1 ? messages.isURLList[0] : messages.isURLList[1];

        for (let url of actualURLs) {
            if (!validator.isURL(url, options)) {
                return false;
            }
        }

        return true;
    }, 'isURLList', messages);

    // isEmail applicable to lists, not only single value
    initializingValidator.isEmailList = wrappedFunction((value, options) => {
        // Split to list by newline...
        let emails = value.split(',');

        // Figure out actual number of the emails, by stripping whitespaced rows
        let actualEmails = [];
        for (let d of emails) {
            actualEmails.push(d.trim());
        }

        for (let url of actualEmails) {
            if (!validator.isEmail(url, options)) {
                return false;
            }
        }

        return true;
    }, 'isEmailList', messages);

    // is value match to cron expression
    initializingValidator.isCron = wrappedFunction(value => {
        const [minute, hour, dayMonth, month, dayWeek, year] = value.split(' ');

        const validateRangeAndInclude = (target, regexp) => target?.split(',').every((value) => {
            const isRange = value.split('-').length === 2;
            if (isRange) {
                const [first, last] = value.split('-');
                return first.match(new RegExp(`^${regexp}$`)) && last.match(new RegExp(`^${regexp}$`)) && +first < +last;
            }

            return value.match(new RegExp(`^${regexp}$`));
        });

        return [
            !!minute && (minute.match(/^(\*|[1-5]?[0-9])$/) || validateRangeAndInclude(minute, '[1-5]?[0-9]')),
            !!hour && (hour.match(/^(\*|2[0-3]|1[0-9]|[0-9])$/) || validateRangeAndInclude(hour, '2[0-3]|1[0-9]|[0-9]')),
            !!dayMonth && (dayMonth === '?' ? dayWeek !== '?' : dayMonth.match(/^([*?LW]|3[01]|[12][0-9]|[1-9])$/) || validateRangeAndInclude(dayMonth, '3[01]|[12][0-9]|[1-9]')),
            !!month && (month.match(/^(\*|1[0-2]|[1-9])$/) || validateRangeAndInclude(month, '1[0-2]|[1-9]')),
            !!dayWeek && (dayWeek === '?' ? dayMonth !== '?' : dayWeek.match(/^([*?L]|[1-7])$/) || validateRangeAndInclude(dayWeek, '[1-7]')),
            !!year && (year.match(/^(\*|19[7-9][0-9]|20[0-9][0-9])$/) || validateRangeAndInclude(year, '19[7-9][0-9]|20[0-9][0-9]'))
        ].every(result => result);
    }, 'isCron', messages);

    initializingValidator.containsLowerCase = wrappedFunction(value => {
        return /[a-z]/.test(value);
    }, 'containsLowerCase', messages);

    initializingValidator.containsUpperCase = wrappedFunction(value => {
        return /[A-Z]/.test(value);
    }, 'containsUpperCase', messages);

    initializingValidator.containsNumber = wrappedFunction(value => {
        return /[0-9]/.test(value);
    }, 'containsNumber', messages);

    initializingValidator.containsSpecialCharacter = wrappedFunction(value => {
        return /[!@#$%^&*(),.?":{}|<>\]]/.test(value);
    }, 'containsSpecialCharacter', messages);

    initializingValidator.areValidMimeTypes = wrappedFunction(value => {
        const allowedMimeTypes = value.replace(/\s/g, '').split(',').filter(e => e);
        const invalidTypes = allowedMimeTypes.filter(extension => !/.+\/.+/.test(extension));

        return invalidTypes.length === 0;
    }, 'areValidMimeTypes', messages);
}

export default reduxFormValidator;
