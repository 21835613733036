import React from 'react';
// https://github.com/GuillaumeCisco/react-json-prettify/blob/master/src/index.js


const RecursiveKeyValue = ({parent, value, theme, padding, deep}) => {
    // easy types
    if (value === null) {
        return <span style={{color: theme.value.null}}>
            {'null'}
        </span>;
    } else if (typeof value === 'string') {
        return <span style={{color: theme.valueQuotes}}>
            {'"'}
            <span style={{color: theme.value.string}}>
                {value}
            </span>
            {'"'}
        </span>;
    } else if (typeof value === 'number') {
        return <span style={{color: theme.value.number}}>
            {value}
        </span>;
    } else if (typeof value === 'boolean') {
        return <span style={{color: theme.value.boolean}}>
            {value ? 'true' : 'false'}
        </span>;
    }

    // complex nested
    deep += 1;

    // array
    if (Array.isArray(value)) {
        return <React.Fragment>
            <span style={{color: theme.bracket}}>
                {'\u005B'}
            </span>
            <div>
                {value.map((o, i) => {
                    return <div style={{color: theme.valueQuotes}}
                                key={`${parent}-${o}-${i}`}>
                        {new Array(deep * padding + 1).join('\u00A0')}
                        <RecursiveKeyValue parent={parent} value={o} theme={theme} padding={padding} deep={deep}/>
                        {i === value.length - 1 ? '' : (
                            <span style={{color: theme.comma}}>
                                ,
                                </span>
                        )}
                    </div>;
                })}
            </div>
            <span style={{color: theme.bracket}}>
                {new Array((deep - 1) * padding + 1).join('\u00A0')}
                {'\u005D'}
            </span>
        </React.Fragment>;
    }

    // object
    if (typeof value === 'object') {
        const keys = Object.keys(value),
            l = keys.length;

        return <React.Fragment>
            <span style={{color: theme.brace}}>
                {'\u007B'}
            </span>
            <div>
                {keys.map((o, i) => {
                    return <div key={`${parent}-${o}-${i}-${deep}`}>
                        <span>
                            {new Array(deep * padding + 1).join('\u00A0')}
                        </span>
                        <span style={{color: theme.keyQuotes}}>
                                {'"'}
                                <span style={{color: theme.key}}>
                                    {o}
                                </span>
                                {'"'}
                                <span style={{color: theme.colon}}>
                                    {':'}
                                </span>
                            {'\u00A0'}
                            </span>
                        <RecursiveKeyValue parent={o} value={value[o]} theme={theme} padding={padding} deep={deep}/>
                        {i === l - 1
                            ? ''
                            : <span style={{color: theme.comma}}>{','}</span>
                        }
                    </div>;
                })}
            </div>
            <span style={{color: theme.brace}}>
                {new Array((deep - 1) * padding + 1).join('\u00A0')}
                {'\u007D'}
            </span>
        </React.Fragment>;
    }

    return value;
};

RecursiveKeyValue.defaultProps = {
    json: null,
    theme: null,
    padding: 2,
    deep: 0
};


const JSONPretty = ({json, theme, padding}) => {
    // recursive Component
    return <pre style={{overflow: 'auto', backgroundColor: theme.background}}>
        <RecursiveKeyValue value={json} theme={theme} padding={padding} parent='root' />
    </pre>;
};

JSONPretty.defaultProps = {
    json: null,
    theme: null,
    padding: 2
};

export default JSONPretty;
