import React from 'react';


/**
 * SRAPS Logo
 */
export default function Logo(props) {
    const {viewBox = '0 0 199.9 65.551', id, ...rest_of_props} = props;

    return <svg viewBox={viewBox} {...rest_of_props}>
        <g transform='matrix(1.3333 0 0 -1.3333 -544.58 368.06)'>
            <g transform='translate(410.17 246.08)'>
                <path fill='#326d9b' d='m0 0c-0.989 0.68-1.729 1.544-1.729 2.625 0 0.803 0.37 1.637 1.018 2.162 0.494 0.432 1.05 0.648 1.73 0.648 0.648 0 1.389-0.278 2.346-0.896 2.193-1.42 4.385-2.1 7.565-2.1 4.014 0 6.885 1.421 6.885 4.416 0 2.1-1.204 3.428-4.23 3.705l-5.032 0.463c-5.837 0.525-9.264 3.15-9.264 8.584 0 6.145 5.28 9.603 11.826 9.603 3.365 0 5.99-0.586 8.43-1.729 1.05-0.494 1.636-0.896 2.038-1.328 0.462-0.556 0.586-1.018 0.586-1.729 0-0.617-0.34-1.389-0.773-1.852-0.524-0.525-1.111-0.803-1.851-0.803-0.587 0-1.205 0.247-2.038 0.648-1.977 0.958-4.107 1.637-6.515 1.637-3.736 0-5.62-1.729-5.62-4.107 0-2.316 1.451-3.335 4.323-3.582l4.94-0.463c5.837-0.556 9.295-3.242 9.295-8.831 0-6.515-5.559-9.911-13.032-9.911-4.877 0-8.088 0.926-10.898 2.84' />
            </g>
            <g transform='translate(455.62 275.17)'>
                <path fill='#326d9b' d='m0 0c2.315 0 3.982-0.432 5.496-1.451 1.08-0.71 1.884-1.636 1.884-2.841 0-0.895-0.371-1.729-0.927-2.285-0.525-0.525-1.358-0.864-2.161-0.864-0.648 0-1.358 0.216-2.439 0.834-1.05 0.586-2.162 0.926-3.397 0.926-3.581 0-6.392-2.656-6.392-7.009v-14.945c0-1.513-0.092-2.069-0.462-2.809-0.433-0.834-1.328-1.483-2.687-1.483-1.358 0-2.254 0.649-2.686 1.483-0.371 0.74-0.463 1.296-0.463 2.809v23.189c0 1.729 0.124 2.285 0.555 2.995 0.525 0.834 1.544 1.328 2.594 1.328 1.019 0 1.977-0.402 2.563-1.235 0.339-0.495 0.494-0.957 0.556-2.285 1.76 2.192 4.693 3.643 7.966 3.643' />
            </g>
            <g transform='translate(477.98 248.49)'>
                <path fill='#326d9b' d='m0 0c2.254 0 4.107 0.247 5.712 1.791 0.989 0.926 1.39 2.346 1.39 4.662v2.47h-7.411c-3.705 0-5.588-1.606-5.588-4.508 0-2.841 1.915-4.415 5.897-4.415m-1.204 13.4h8.306v1.946c0 4.106-1.945 6.113-6.546 6.113-2.501 0-4.168-0.71-5.991-2.1-0.801-0.617-1.697-0.833-2.284-0.833-0.648 0-1.358 0.278-1.822 0.833-0.524 0.587-0.741 1.112-0.741 1.946 0 1.421 1.328 2.686 2.933 3.612 2.193 1.267 4.724 1.884 7.967 1.884 8.398 0 12.814-3.675 12.814-10.776v-16.859c0-1.544-0.062-2.13-0.463-2.841-0.463-0.864-1.483-1.451-2.687-1.451-1.018 0-1.791 0.371-2.285 0.896-0.37 0.432-0.648 0.926-0.71 2.253-2.47-2.408-4.569-3.242-8.398-3.242-3.922 0-6.546 0.896-8.43 2.841-1.605 1.636-2.501 4.045-2.501 6.7 0 5.249 3.736 9.078 10.838 9.078' />
            </g>
            <g transform='translate(505.28 265.9)'>
                <path fill='#009EE2' d='m0 0c0.22 1.346 0.621 2.382 1.202 3.109 0.581 0.726 1.392 1.206 2.435 1.442 0.071 0.016 0.144 0.032 0.218 0.046 1.257 0.25 2.173 1.345 2.173 2.626v0.07c0 1.75-1.577 3.036-3.289 2.745-0.585-0.1-1.137-0.22-1.657-0.359-1.824-0.492-3.317-1.346-4.479-2.564-1.163-1.218-2.014-2.843-2.555-4.871-0.541-2.031-0.812-4.583-0.812-7.66v-3.525c0-0.564 0.019-1.091 0.037-1.619-0.025-0.326-0.037-0.709-0.037-1.197v-23.132c0-1.455 0.089-1.99 0.446-2.702 0.416-0.803 1.277-1.427 2.584-1.427s2.169 0.624 2.585 1.427c0.356 0.712 0.445 1.247 0.445 2.702v11.522c0.559-0.262 1.144-0.496 1.786-0.669 0.52-0.139 1.072-0.259 1.657-0.359 1.712-0.291 3.289 0.995 3.289 2.732v1e-3c0 1.328-0.949 2.453-2.249 2.723-0.048 0.01-0.095 0.021-0.142 0.031-1.043 0.235-1.854 0.715-2.435 1.442-0.581 0.726-0.982 1.763-1.202 3.109-0.222 1.346-0.331 3.15-0.331 5.416v3.525c0 2.265 0.109 4.07 0.331 5.416' />
            </g>
            <g transform='translate(524.13 273.01)'>
                <path fill='#326d9b' d='m0 0c-1.163 1.218-2.655 2.072-4.479 2.564-0.673 0.181-1.403 0.327-2.185 0.439-1.654 0.237-3.134-1.076-3.134-2.747v-2e-3c0-1.326 0.94-2.492 2.247-2.716 0.18-0.031 0.353-0.065 0.517-0.101 1.042-0.236 1.853-0.717 2.434-1.443 0.581-0.727 0.983-1.763 1.204-3.109 0.219-1.346 0.33-3.151 0.33-5.415v-3.526c0-2.265-0.111-4.07-0.33-5.416-0.221-1.346-0.623-2.383-1.204-3.109-0.581-0.727-1.392-1.207-2.434-1.442-0.164-0.037-0.338-0.071-0.519-0.102-1.306-0.225-2.245-1.39-2.245-2.715v-2e-3c0-1.672 1.48-2.985 3.134-2.747 0.782 0.112 1.512 0.257 2.185 0.439 1.824 0.49 3.316 1.346 4.479 2.564 1.162 1.217 2.015 2.841 2.555 4.871 0.542 2.029 0.812 4.583 0.812 7.659v3.526c0 3.076-0.27 5.629-0.812 7.659-0.54 2.029-1.393 3.653-2.555 4.871' />
            </g>
            <g transform='translate(534.43 246.08)'>
                <path fill='#326d9b' d='m0 0c-0.989 0.68-1.729 1.544-1.729 2.625 0 0.803 0.37 1.637 1.018 2.162 0.494 0.432 1.05 0.648 1.73 0.648 0.648 0 1.389-0.278 2.346-0.896 2.193-1.42 4.385-2.1 7.565-2.1 4.014 0 6.885 1.421 6.885 4.416 0 2.1-1.204 3.428-4.23 3.705l-5.032 0.463c-5.836 0.525-9.264 3.15-9.264 8.584 0 6.145 5.28 9.603 11.826 9.603 3.365 0 5.99-0.586 8.43-1.729 1.05-0.494 1.636-0.896 2.038-1.328 0.462-0.556 0.586-1.018 0.586-1.729 0-0.617-0.34-1.389-0.772-1.852-0.524-0.525-1.112-0.803-1.852-0.803-0.587 0-1.205 0.247-2.038 0.648-1.977 0.958-4.107 1.637-6.515 1.637-3.736 0-5.62-1.729-5.62-4.107 0-2.316 1.451-3.335 4.323-3.582l4.94-0.463c5.837-0.556 9.295-3.242 9.295-8.831 0-6.515-5.559-9.911-13.032-9.911-4.877 0-8.088 0.926-10.898 2.84' />
            </g>
        </g>
    </svg>;
}
