import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {useLocalSort} from 'lib/filters';
import {SettingGroup, Product} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems, saveItem, deleteItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
import DeleteDialog from 'components/core/ui/DeleteDialog';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import ErrorCard from 'components/core/ui/mui/ErrorCard';
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';


/**
 * Renders detail of Setting Group - ADD & EDIT
 */
function Detail(props) {
    const edit = !!props.match.params.identifier;
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // open/close status of Delete dialog
    // sorting
    const [sortedProducts] = useLocalSort(props.products_items, !props.products_loaded);

    /**
     * During initialization fetch item if needed (we are in EDIT)
     */
    useEffect(() => {
        // check if we have item or not
        if (edit && !props.item) {
            props.fetchItem(SettingGroup, 'setting-groups', ['setting-groups', props.match.params.identifier]);
        }
    }, [props.match.params.identifier]);
    // related data fetch without affecting state, Products for required field
    useEffect(() => {
        if (props.products_loaded === false) {
            props.fetchItems(Product, 'products', 'products', null, null, {affect_state: false});
        }
    }, [props.products_loaded]);

    return <div>
        {['fetching_item_setting-groups', 'deleting_item_setting-groups'].includes(props.state)
            ? <Card>
                <CardHeader title={<FormattedMessage id='settinggroups.detail.unknown.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='setting_group' />
                            </ActionButton>} />
                <CardContent>
                    <LinearProgress />
                </CardContent>
            </Card>
            : edit && !props.item
            ? <ErrorCard
                title={<FormattedMessage id='settinggroups.detail.notfound.title' />}
                text={<FormattedMessage id='settinggroups.detail.notfound.text' />}
                icon={<WarningIcon color='secondary' />} />
            : props.formValues && <div>
            <Card>
                <CardHeader subheader={<FormattedMessage id='settinggroups.detail.subheader' />}
                            title={edit
                                ? <FormattedMessage id='settinggroups.detail.edit.title' />
                                : <FormattedMessage id='settinggroups.detail.add.title' />}
                            action={<ActionButton iconButton disabled>
                                <ModelIcon model='setting_group' />
                            </ActionButton>} />
                <CardContent>
                    <Form onSubmit={props.handleSubmit}>
                        <Field name='name' fieldType='TextField' label={`${props.intl.formatMessage({id: 'settinggroups.detail.form.fields.name'})}*`} />
                        <Field name='product_defaults_products' fieldType='Select' multiple
                               label={<FormattedMessage id='settinggroups.detail.form.fields.product_defaults_products' />}
                               helperText={<FormattedMessage id='settinggroups.detail.form.fields.product_defaults_products.help' />}
                               disabled={!props.products_loaded} loading={!props.products_loaded}>
                            <MenuItem fake value={props.formValues && props.formValues.product_defaults_products} />
                            {sortedProducts.map((product, idx) =>
                                <MenuItem key={idx} value={product.get(new Product().getUniqueIdentifier())}>{product.get('name')}</MenuItem>
                            )}
                        </Field>
                        <SpaceDivider none />
                        <Field name='provisioning_strategy' fieldType='Select'
                               label={<FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy' />}
                               helperText={<FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.help' />}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            <MenuItem value='Default'><FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.choices.default' /></MenuItem>
                            <MenuItem value='3CX'><FormattedMessage id='settinggroups.detail.form.fields.provisioning_strategy.choices.3cx' /></MenuItem>
                        </Field>
                        {edit && <Field
                            name='no_settings' fieldType='TextField' disabled={true} label={<FormattedMessage id='settinggroups.detail.form.fields.no_settings' />}
                            helperText={<FormattedMessage id='settinggroups.detail.form.fields.no_settings.help' />}
                        />}
                        <SpaceDivider />
                        <Field name='description' fieldType='TextArea' label={<FormattedMessage id='settinggroups.detail.form.fields.description' />} />
                    </Form>
                </CardContent>
                {['saving_item_setting-groups', 'saved_item_setting-groups', 'failed_save_item_setting-groups'].includes(props.state)
                    ? <CardActionsLoader success={props.state === 'saved_item_setting-groups'}
                                         failure={props.state === 'failed_save_item_setting-groups'}
                                         postAnimation={() => props.setState(null)} />
                    : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push('/setting-groups')}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {props.permission === 'RW' && <React.Fragment>
                            {edit &&
                                <Button variant='contained' color='secondary' disabled={props.state !== null}
                                        onClick={() => setDeleteDialogOpen(true)}>
                                    <DeleteIcon />
                                    <FormattedMessage id='actions.delete' />
                                </Button>
                            }
                            <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                                    onClick={() => props.handleSubmit()}>
                                <SaveIcon />
                                <FormattedMessage id='actions.save' />
                            </Button>
                        </React.Fragment>}
                    </CardActions>
                </ThemeProvider>}
                {(edit && props.permission === 'RW') && <DeleteDialog
                    item={props.intl.formatMessage({id: 'settinggroups.detail.deletedialog.item'})}
                    items={props.intl.formatMessage({id: 'settinggroups.detail.deletedialog.items'})}
                    open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    handleConfirm={() => {
                        setDeleteDialogOpen(false);
                        props.deleteItem(SettingGroup, 'setting-groups', props.item.getIn(['links', 'self']), props.item).then(() => props.history.push('/setting-groups'));
                    }}
                />}
            </Card>
        </div>}
    </div>;
}


/**
 * Convert SettingGroup record toObject(). Used for initial values
 *
 * @param setting_group - SettingGroup model
 */
function toObject(setting_group) {
    setting_group = setting_group.toJS();
    if (setting_group.product_defaults_products) {
        setting_group.product_defaults_products = [...setting_group.product_defaults_products];
    }
    return setting_group;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'provisioning_strategy', data.provisioning_strategy);

    return errors;
};

const DetailForm = reduxForm({
    form: 'settingGroupForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let rest_of_data = new SettingGroup().popUpFields(props.formValues);

        return dispatch(saveItem(SettingGroup, 'setting-groups', props.item ? props.item.getIn(['links', 'self']) : 'setting-groups', rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(create_data => {
            // in case of add, redirect to detail
            if (!props.item && create_data.get(new SettingGroup().getUniqueIdentifier())) {
                props.history.push(`/setting-groups/${create_data.get(new SettingGroup().getUniqueIdentifier())}`);
            }
        });
    }
})(Detail);

const ConnectedDetail = connect((state, props) => {
    const item = state.shared.getIn(['items', 'setting-groups']).find(el => el.get(new SettingGroup().getUniqueIdentifier()) === props.match.params.identifier);

    return {
        state: state.app.get('state'),
        item: item,
        products_loaded: state.shared.getIn(['loaded', 'products']),
        products_items: state.shared.getIn(['items', 'products']),
        initialValues: props.match.params.identifier
            ? item
                ? toObject(item)
                : undefined
            : {provisioning_strategy: 'Default'},
        formValues: getFormValues('settingGroupForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    fetchItem,
    fetchItems,
    saveItem,
    deleteItem
}, dispatch))(DetailForm);

export default injectIntl(withRouter(ConnectedDetail));
