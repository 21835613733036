import React from 'react';
import {FormattedMessage} from 'react-intl';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
// Components
import SRAPSBanner from 'components/modules/plain/SRAPSBanner';
import SubscriptionBanner from 'components/modules/subscription/Banner';
// material-ui
import {Link} from 'react-router-dom';
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';


const useStyles = makeStyles(theme => ({
    // align items
    grid: {
        display: 'grid',
        gridTemplateColumns: 'auto 400px',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        // match grid
        gap: `${theme.spacing(2)}px`,
        // responsive
        [theme.breakpoints.up('md')]: {
            // memory status takes center
            '& > div:nth-of-type(3)': {
                gridRowStart: '1',
                gridRowEnd: '3',
                gridColumnStart: '2'
            }
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            gap: `${theme.spacing(1)}px`
        }
    },
    // more space for text only content
    cardContent: {
        // enlarge CardContent spacing
        '&, &:last-child': {
            padding: `${theme.spacing(8)}px`,
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(4)}px`
            },
            [theme.breakpoints.down('sm')]: {
                padding: `${theme.spacing(2)}px`
            }
        },
        // change title proportion
        '& h4': {
            margin: `0 0 ${theme.spacing(2)}px`,
            color: theme.palette.snomGrey[800]
        }
    }
}));

/**
 * Main page of SRAPS
 */
export default function SRAPS() {
    const classes = useStyles();
    // redux store
    const {company} = useSelector(state => ({
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
    }), shallowEqual);

    return <div className={classes.grid}>
        <Card>
            <CardContent className={classes.cardContent}>
                <h4 className='newfont'><FormattedMessage id='home.title' /></h4>
                <p className='newfont'><FormattedMessage id='home.text.1' /></p>
                <p className='newfont'>
                    <FormattedMessage id='home.text.2' values={{link: company.get('api_enabled')
                            ? <Link to='/documentation' className='hover-border'><FormattedMessage id='home.text.2.link' /></Link>
                            : <FormattedMessage id='home.text.2.link' />}} />
                </p>
                <p className='newfont'>
                    <FormattedMessage id='home.text.3' values={{link:
                            <a href='https://helpdesk.snom.com/' className='hover-border' target='_blank' rel='noreferrer'>{'helpdesk.snom.com'}</a>}}
                    />
                </p>
                <p className='newfont'>
                    <FormattedMessage id='home.text.5' values={{link:
                            <Link to='/profile' className='hover-border'><FormattedMessage id='home.text.5.link' /></Link>}}
                    />
                </p>
            </CardContent>
        </Card>
        <SubscriptionBanner cardContentClassName={classes.cardContent} />
        <SRAPSBanner />
    </div>;
}
