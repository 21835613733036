import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useDebounce} from 'use-debounce';
import {theme} from 'theme';
// Components
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';
// icons
import SearchIcon from '@material-ui/icons/SearchOutlined';


const useStyles = makeStyles(theme => ({
    fieldHolder: {
        position: 'relative' // allow absolute wrap inside
    },
    fieldLoader: {
        // move bellow field line
        position: 'absolute',
        zIndex: '20',
        top: '46px',
        left: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`,
        // match input underline size
        height: '2px',
        // hide by default
        opacity: '0',
        transition: theme.transitions.create('opacity',
            {duration: theme.transitions.duration.short}),
        '&.show': {
            opacity: '1'
        }
    }
}));

/**
 * Field for data filtering in Lists with value debounce
 *
 * Example filter implementation:
 *  <SearchField label={<FormattedMessage id='INTL_KEY' />}
 *               value={filters.search || ''}
 *               search={(search) => filterItems('search', search)} />
 */
export default function SearchField(props) {
    const {search, loading, value: initialValue,
        error = false, errorValidation = (searchValue) => false,
        searchIcon = true, postChildren = (value, setValue, loader) => {},
        ...rest_of_props} = props;
    const classes = useStyles();
    // local state
    const [value, setValue] = useState('');
    const [debouncedValue] = useDebounce(value, 300);
    const loader = !!(loading || (value && value !== initialValue && value !== debouncedValue));
    const [debouncedLoader] = useDebounce(loader, theme.transitions.duration.short);

    // typing into input
    const onChange = (value) => {
        setValue(value);
        // if empty, search immediately
        if (!value) {
            search(value);
        }
    };

    // watch for change in debounced value to trigger search
    useEffect(() => {
        if (debouncedValue && value !== initialValue) {
            search(debouncedValue);
        }
    }, [debouncedValue]);

    // watch for changes in initialValue to change value
    useEffect(() => {
        setValue(initialValue || '');
    }, [initialValue]);

    return <FieldWithIconHolder className={classes.fieldHolder}>
        <Field withIcon={searchIcon} fieldType='NoReduxTextField'
               onChange={(event) => onChange(event.target.value)}
               error={error || errorValidation(debouncedValue)}
               {...rest_of_props}
               value={value} />
        {(loader || debouncedLoader) && <LinearProgress
            className={`${classes.fieldLoader}${loader ? ' show' : ''}`}
        />}
        {searchIcon && <FieldIcon disabled>
            <SearchIcon />
        </FieldIcon>}
        {postChildren(value, setValue, loader)}
    </FieldWithIconHolder>;
}
