import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {theme} from 'theme';
import {makeStyles} from '@material-ui/core/styles';
import {Statistics} from 'lib/models';
// Actions
import {fetchItem} from 'actions/shared';
import {setState} from 'actions/app';
// Components
import Graph from 'components/core/ui/Graph';
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    // card with graph inside
    graphCard: {
        // same height as rest of cards
        minHeight: '100%',
        // align graph to bottom
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        '& > div': {
            maxWidth: '100%'
        }
    },
    // holder of graph
    graphHolder: {
        margin: '0 auto',
        // limit size
        width: '380px',
        maxWidth: '100%',
        position: 'relative',
        // graph itself
        '& > canvas': {
            position: 'relative',
            zIndex: '20'
        },
        // add circle once empty
        '& .empty': {
            position: 'absolute',
            zIndex: '10',
            top: '0', bottom: '0', left: '0', right: '0',
            borderRadius: '100%',
            background: theme.palette.grey[300],
            // align line
            display: 'flex', alignItems: 'flex-start', justifyContent: 'center',
            '& .line': {
                width: '2px',
                height: '50%',
                background: theme.palette.common.white
            }
        }
    }
}));

/**
 * Dashboard with statistics related to SRAPS
 */
export default function Dashboard() {
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const props = useSelector(state => {
        const stats_device_manager = state.shared.getIn(['items', 'statistics']).find(el => el.get(new Statistics().getUniqueIdentifier()) === 'company-stats-device-manager')?.toJS().data;

        return {
            state: state.app.get('state'),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            stats_voice_quality: state.shared.getIn(['items', 'statistics'])
                .find(el => el.get(new Statistics().getUniqueIdentifier()) === 'voice-quality-report')?.toJS().data,
            stats_device_manager_endpoint: stats_device_manager && {
                endpoints_online: stats_device_manager.endpoints_online, endpoints_offline: stats_device_manager.endpoints_offline
            },
            stats_device_manager_sip: stats_device_manager && {
                sips_up: stats_device_manager.sips_up, sips_down: stats_device_manager.sips_down
            }
        };
    }, shallowEqual);

    /**
     * During initialization fetch statistics
     */
    useEffect(() => {
        dispatch(setState('fetching_items_statistics'));
        Promise.allSettled(['voice-quality-report', 'company-stats-device-manager'].map(statistic => dispatch(
            fetchItem(Statistics, 'statistics', props.company.getIn(['links', statistic]),
                {expand_item: new Statistics({statistic_name: statistic}), expand_item_data: true})
        ))).finally(() => dispatch(setState(null)));
    }, []);

    return <React.Fragment>
        <Card>
            <CardHeader title={<FormattedMessage id='dashboard.title' />}
                        subheader={<FormattedMessage id='dashboard.subheader' />}
                        action={<ActionButton iconButton disabled>
                            <ModelIcon model='dashboard' />
                        </ActionButton>} />
        </Card>
        <SpaceDivider grid />
        <Row wrap stretch>
            <Col flex='1'>
                <Card className={classes.graphCard}>
                    <CardHeader title={<FormattedMessage id='dashboard.dm.voicequality.title' />}
                                subheader={<FormattedMessage id='dashboard.dm.voicequality.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='voice-quality' />
                                </ActionButton>} />
                    <CardContent>
                        <Graph loading={props.state === 'fetching_items_statistics'} type='pie' data={props.stats_voice_quality}
                               options={{base_intl: 'dashboard.dm.voicequality.data', colors: [theme.gradients.blue.color1, theme.gradients.green.color1,
                                       theme.gradients.gray.color1, theme.gradients.orange.color1, theme.gradients.red.color2],
                                   labels: ['no_perfect', 'no_fair', 'no_annoying', 'no_very_annoying', 'no_impossible']
                               }}/>
                    </CardContent>
                </Card>
            </Col>
            <Col flex='1'>
                <Card className={classes.graphCard}>
                    <CardHeader title={<FormattedMessage id='dashboard.dm.endpoints.title' />}
                                subheader={<FormattedMessage id='dashboard.dm.endpoints.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='endpoints' />
                                </ActionButton>} />
                    <CardContent>
                        <Graph loading={props.state === 'fetching_items_statistics'} type='pie' data={props.stats_device_manager_endpoint}
                               options={{base_intl: 'dashboard.dm.endpoints.data', colors: [theme.palette.success[500], theme.gradients.gray.color1],
                                   labels: ['endpoints_online', 'endpoints_offline']
                               }}/>
                    </CardContent>
                </Card>
            </Col>
            <Col flex='1'>
                <Card className={classes.graphCard}>
                    <CardHeader title={<FormattedMessage id='dashboard.dm.sip.title' />}
                                subheader={<FormattedMessage id='dashboard.dm.sip.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <ModelIcon model='sip' />
                                </ActionButton>} />
                    <CardContent>
                        <Graph loading={props.state === 'fetching_items_statistics'} type='pie' data={props.stats_device_manager_sip}
                               options={{base_intl: 'dashboard.dm.sip.data', colors: [theme.palette.success[500], theme.gradients.gray.color1],
                                   labels: ['sips_up', 'sips_down']
                               }}/>
                    </CardContent>
                </Card>
            </Col>
        </Row>
    </React.Fragment>;
}
