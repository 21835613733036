import React from 'react';
import {FormattedMessage} from 'react-intl';
// components
import DMInfoCard from 'components/modules/tickets/dm/DMInfoCard';
// icons
import FirmwareIcon from '@material-ui/icons/DeveloperBoardOutlined';


/**
 * Render device manager info about firmware
 */
export default function DMFirmwareVersion(props) {
    const {item} = props;

    return <DMInfoCard
        title={<FormattedMessage id='devicemanagement.type.softwareversion' />}
        icon={<FirmwareIcon />}
        info={item?.getIn(['data', 'SoftwareVersion', 'value']) || '-'}
        lastUpdate={item?.getIn(['data', 'SoftwareVersion', 'updated_at'])}
    />;
}
