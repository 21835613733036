import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory, useLocation} from 'react-router';
import {useFilter, usePaginatedItems} from 'lib/filters';
import {ActivityLog, User, Company, Paginator, Role} from 'lib/models';
import {List as ImmutableList} from 'immutable';
// Actions
import {fetchItem, fetchItems, updatePaginator} from 'actions/shared';
// Components
import Field, {CompanyField} from 'components/core/ui/Field';
import Filters from 'components/core/ui/Filters';
import Pagination from 'components/core/ui/Pagination';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import {alpha} from '@material-ui/core/styles/colorManipulator';
import Tooltip from 'components/core/ui/mui/Tooltip';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import MUIList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import ModelsIcon from '@material-ui/icons/AppsOutlined';
import ActionCreateIcon from '@material-ui/icons/AddOutlined';
import ActionUpdateIcon from '@material-ui/icons/EditOutlined';
import ActionDeleteIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    // activities are loading
    loader: {
        // match size of empty text (24 size - LinearProgrees size (4))
        margin: `${(24 - 4) / 2}px 0`
    },
    // sticky Header in Activities style
    activitiesHeader: {
        // style
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        // reduce space between Header and Activities
        '&:last-child': {
            paddingTop: `${theme.spacing(1)}px`,
            paddingBottom: `${theme.spacing(1)}px`
        },
        '& $activitiesHolder': {
            marginTop: '0'
        }
    },
    // holder of ActivityLogs
    activitiesHolder: {
        // move closer to Header
        marginTop: `-${theme.spacing(1)}px`,
        // position
        zIndex: '0',
        // responsive
        [theme.breakpoints.down('xs')]: {
            // to sides
            marginLeft: `-${theme.spacing(2)}px`,
            marginRight: `-${theme.spacing(2)}px`
        }
    },
    // ActivityLog itself
    activity: {
        flexWrap: 'wrap',
        // unify colors link / normal
        color: theme.palette.text['primary'],
        '&:hover': {
            color: theme.palette.text['primary']
        },
        // new hover effect
        backgroundColor: 'transparent !important', // get rid of previous one
        '& > .hover': {
            // full-size
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            top: '0',
            zIndex: '5', // bellow everything
            transition: theme.transitions.create(['box-shadow', 'border'],
                {duration: theme.transitions.duration.short})
        },
        '&:hover > .hover': {
            // taken from MUI Button style
            backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
        },
        // click effect
        '& > span:last-child': {
            zIndex: '8'
        },
        // every div is above new hover effect
        '& > div': {
            position: 'relative',
            zIndex: '10'
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            // increase space between Activities
            paddingBottom: `${theme.spacing(1)}px`,
            paddingTop: `${theme.spacing(1)}px`
        }
    },
    // sections for responsive scaling
    activitySection: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        flexWrap: 'nowrap',
        // responsive
        [theme.breakpoints.down('sm')]: {
            // new line
            flexGrow: '1',
            width: '100%',
            // space between
            marginTop: `${theme.spacing(1)}px`
        }
    },
    // '12/03' Subheader date
    activityDate: {
        // change proportion
        padding: `${theme.spacing(1)}px 0 ${theme.spacing(0.5)}px`,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: '1.1875em',
        // display as 'monospace' to better readability and same size
        fontFamily: '"Roboto Mono", monospace',
        // responsive
        [theme.breakpoints.down('sm')]: {
            // highlight more
            textAlign: 'center',
            padding: `${theme.spacing(1)}px 0`,
            margin: `${theme.spacing(1)}px 0`,
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    },
    // '11:58' time of Action
    activityTime: {
        width: '38px',
        textAlign: 'center',
        flexGrow: '0',
        flexShrink: '0',
        marginRight: `${theme.spacing(1)}px`, // space between
        '& span': {
            // highlight date
            fontSize: theme.typography.pxToRem(12),
            fontWeight: theme.typography.fontWeightBold,
            // display as 'monospace' to better readability and same size
            fontFamily: '"Roboto Mono", monospace'
        }
    },
    // User 'admin' or Company 'Awesome'
    activityCreator: {
        background: theme.palette.common.white, // highlight above hover effect of parent
        width: '125px', // fixed size
        flexShrink: '0',
        marginRight: `${theme.spacing(1)}px`, // space between
        // text
        '& span': {
            lineHeight: theme.typography.body2.lineHeight,
            // make sure that user doesn't overflow (due to fixed size)
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        // link leading to nowhere (disabled basically)
        '&.disabled-like': {
            cursor: 'default',
            // no hover effect and click effect
            background: `${theme.palette.common.white} !important`,
            boxShadow: 'none !important',
            '& > span:last-child': {
                display: 'none'
            }
        },
        // make sure to hide button effect and hover effect from parent Activity link
        '&:hover': {
            '& ~ span:last-child': {
                display: 'none'
            },
            '& ~ .hover': {
                backgroundColor: 'transparent !important'
            }
        },
        // connect activityCreators together
        '&.connect': {
            marginRight: '0',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            '& ~ $activityCreator': {
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                borderLeft: '0'
            }
        },
        // responsive
        [theme.breakpoints.down('xs')]: {
            width: '100px'
        }
    },
    // 'create/delete/update' Action
    activityAction: {
        // position and size
        width: '120px', // fixed size
        marginRight: `${theme.spacing(1)}px`, // space between
        flexShrink: '0',
        // highlight text
        textTransform: 'uppercase',
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
        // soften background
        background: theme.palette.snomGrey[200],
        // hide icon by default
        '& > span > svg': {
            display: 'none'
        },
        // correct cursor (Activity is link)
        '&.link': {
            cursor: 'pointer'
        },
        // colors
        '&.create': {
            color: theme.palette.green['main']
        },
        '&.update': {
            color: theme.palette.primary[500]
        },
        '&.delete': {
            color: theme.palette.danger['main']
        },
        // responsive
        [theme.breakpoints.down('xs')]: {
            width: '48px',
            // show icon, hide text
            '& > span > svg': {
                display: 'block'
            },
            '& > span > span': {
                display: 'none'
            }
        }
    },
    // 'Endpoints'/'Provisioning Profile' Model
    activityModel: {
        [theme.breakpoints.up('md')]: {
            width: '200px' // fixed size
        },
        // make sure model doesn't rescale
        flexGrow: '0',
        flexShrink: '0',
        // space between
        marginRight: `${theme.spacing(1)}px`,
        paddingRight: `${theme.spacing(1)}px`,
        borderRight: `1px solid ${theme.palette.divider}`,
        '& > span': {
            // v-align
            display: 'inline-flex',
            verticalAlign: 'middle',
            alignItems: 'center',
            fontWeight: theme.typography.fontWeightMedium, // highlight
            // icon
            '& svg': {
                marginRight: `${theme.spacing(1)}px` // space between
            }
        }
    },
    // '0004137ff99b'/'testing provisioning' Identifier
    activityIdentifier: {
        '& > span': {
            display: 'block',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // limit size
            width: '205px',
            maxWidth: '100%'
        }
    }
}));

/**
 * Renders Activity Logs
 */
export default function List() {
    const intl = useIntl();
    const classes = useStyles();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        const company_identifier = searchParams.get('company')
            // ensure we don't create duplicate 'my' collection with UUID
            ? !pathname.startsWith('/all-') && searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
                ? 'my'
                : searchParams.get('company')
            : pathname.startsWith('/all-') ? 'all' : 'my';

        const items_placement = `${new ActivityLog().getPlacement()}${company_identifier === 'all' ? '-global' : company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            selectedCompany: company_identifier === 'my' ? company : company_identifier === 'all' ? undefined : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user,
            permissions: state.auth.get('permissions')
        };
    }, shallowEqual);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new ActivityLog().getUniqueIdentifier());
    // sorting & filtering (API)
    // const [sortName, sortDirection] = ['created_at', 'asc'];
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, `${new ActivityLog().getPlacement()}${pathname.startsWith('/all-') ? '-global' : ''}`,
        ['company', ...(pathname.startsWith('/all-') ? ['model_name'] : [])], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length) && (company_identifier === 'all' || props.selectedCompany)) {
            const {company, ...filtersWithoutCompany} = filters;
            dispatch(fetchItems(ActivityLog, items_placement, company_identifier === 'all' ? 'activity-logs' : props.selectedCompany.getIn(['links', 'activity-logs']), null,
                Object.keys(filters).length ? filtersWithoutCompany : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters), props.selectedCompany]);
    // silent auto re-fetch with outdated
    useEffect(() => {
        if (props.outdated && props.paginator.get('page') === 1 && (company_identifier === 'all' || props.selectedCompany)) {
            const {company, ...filtersWithoutCompany} = filters;
            dispatch(fetchItems(ActivityLog, items_placement, company_identifier === 'all' ? 'activity-logs' : props.selectedCompany.getIn(['links', 'activity-logs']), null,
                Object.keys(filters).length ? filtersWithoutCompany : null, {paginate: true, paginator_page: 1, affect_state: false}));
        }
    }, [props.outdated, props.paginator.get('page')]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'activities.list.global.title'
                : 'activities.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'activities.list.global.subheader'
                : 'activities.list.subheader'} />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='activities' />
            </ActionButton>}
        />
        {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <Filters disabled={props.state !== null}>
            {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <CompanyField
                value={postponedFilters.company || filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
                all={pathname.startsWith('/all-')} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            {pathname.startsWith('/all-') && <Field
                label={<FormattedMessage id='activities.list.global.filter.model' />}
                fieldType='NoReduxSelect' value={postponedFilters.model_name || filters.model_name || ''}
                onChange={event => filterItems('model_name', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                {new ActivityLog().getModelNames().map((model, idx) => <MenuItem key={idx} value={model}>
                    <ListItemIcon className='icon'><ModelIcon model={new ActivityLog().getCorrectModelName(model)} /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id={`activities.activity.model.${new ActivityLog().getCorrectModelName(model)}`} /></ListItemText>
                </MenuItem>)}
            </Field>}
        </Filters>}
        <StickyTable
            header={<CardContent className={classes.activitiesHeader}>
                <MUIList disablePadding={true} dense={true} className={classes.activitiesHolder}>
                    <ListItem className={classes.activity}>
                        <ListItemText className={classes.activityTime}>
                            <FormattedMessage id='activities.activity.header.date' />
                        </ListItemText>
                        {(company_identifier === 'all') && <Chip
                            variant='outlined' color='primary'
                            className={`${classes.activityCreator} connect`}
                            label={<FormattedMessage id='activities.activity.header.company' />}
                        />}
                        <Chip variant='outlined' className={classes.activityCreator}
                              label={<FormattedMessage id='activities.activity.header.creator' />} />
                        <div className={classes.activitySection}>
                            <Chip className={classes.activityAction}
                                  label={<FormattedMessage id='activities.activity.header.action' />} />
                            <ListItemText className={classes.activityModel}>
                                <ModelsIcon /><FormattedMessage id='activities.activity.header.model' />
                            </ListItemText>
                            <ListItemText className={classes.activityIdentifier}>
                                <FormattedMessage id='activities.activity.header.object_name' />
                            </ListItemText>
                        </div>
                    </ListItem>
                </MUIList>
            </CardContent>}
            content={<CardContent>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    'fetching_items_activities', 'fetching_items_activities-global', `fetching_items_activities-${company_identifier}`
                ].includes(props.state) || filtersInitializing
                    ? <LinearProgress className={classes.loader} />
                    : !paginatedItems.size
                    ? <Typography variant='body1' className='text-center'><FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : 'activities.list.empty'} /></Typography>
                    : <MUIList disablePadding={true} dense={true} className={classes.activitiesHolder}>
                    {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((item, idx) => {
                        // get link to model detail
                        let link = null;
                        if (item.get('action') !== 'delete') {
                            // get base link
                            link = new ActivityLog().getModelLink(item.getCorrectModelName(), pathname.startsWith('/all-'));
                            if (link) {
                                // check permission
                                if (props.permissions.get(new Role().getPermission(link)) === 'X') {
                                    link = null;
                                } else {
                                    if (['invitation', 'company', 'billing'].includes(item.getCorrectModelName())) {
                                        link = item.get('company') === props.company.get(new Company().getUniqueIdentifier())
                                            ? '/company'
                                            : `${link}/${item.get('company')}`;
                                    } else if (link === '/customers' && props.user.get(new User().getUniqueIdentifier()) === item.get('model_id')) {
                                        link = '/profile';
                                    } else if (!['/partner/claim'].includes(link)) {
                                        // append ID
                                        let new_link = ['provisioning_file', 'endpoints_import'].includes(item.getCorrectModelName())
                                            ? link
                                            : `${link}/${item.get('model_id')}`;
                                        // append Company
                                        if (item.get('company') !== props.company.get(new Company().getUniqueIdentifier()) || link.startsWith('/all-') || ['/companies'].includes(link)) {
                                            if (['/submissions'].includes(link)) {
                                                new_link = null;
                                            } else {
                                                new_link = `${new_link}?company=${item.get('company')}`;
                                            }
                                        }
                                        link = new_link;
                                    }
                                }
                            }
                        }

                        // get link to action creator - User / Token
                        const creator_link = item.get('user') && props.permissions.get('user') !== 'X'
                            ? props.user.get(new User().getUniqueIdentifier()) === item.get('user')
                                ? '/profile'
                                : `/customers/${item.get('user')}${item.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${item.get('company')}` : ''}`
                            : null;

                        const date = Moment(item.get('created_at')).format('l');
                        return <React.Fragment key={idx}>
                            {idx === 0
                                ? <ListSubheader className={classes.activityDate}>
                                    {Moment().format('l') === date
                                        ? <FormattedMessage id='activities.activity.date.today' />
                                        : date
                                    }
                                </ListSubheader>
                                : Moment(paginatedItems.get(idx - 1).get('created_at')).format('l') !== date && <ListSubheader
                                className={classes.activityDate}>
                                {date}
                            </ListSubheader>}
                            <ListItem className={classes.activity} button={!!link}
                                      onClick={() => props.state === null && link ? history.push(link) : {}}
                                      disabled={props.state !== null}>
                                <ListItemText className={classes.activityTime}>
                                    {Moment(item.get('created_at')).format('HH:mm')}
                                </ListItemText>
                                {(company_identifier === 'all') && <Tooltip
                                    title={item.get('company_name')
                                        ? item.get('company_name')
                                        : item.get('company')
                                            ? intl.formatMessage({id: 'activities.activity.company.removed'})
                                            : intl.formatMessage({id: 'activities.activity.company.nocompany'})}>
                                    <Chip variant='outlined' color={item.get('company') ? 'primary' : undefined}
                                          className={`${classes.activityCreator}${item.get('company') ? '' : ' disabled-like'} connect`}
                                          label={item.get('company_name') || '----'}
                                          onClick={props.state === null ? (e) => {
                                              e.stopPropagation();
                                              if (item.get('company')) {
                                                  history.push(`/companies/${item.get('company')}`);
                                              }
                                          } : undefined}
                                    />
                                </Tooltip>}
                                <Tooltip title={item.get('user')
                                    ? item.get('user')
                                    : item.get('token')
                                        ? `${intl.formatMessage({id: 'activities.activity.creator.api_key'})}: ${item.get('token_name') || item.get('token')}`
                                        : intl.formatMessage({id: 'activities.activity.creator.auto'})
                                }>
                                    <Chip variant='outlined' className={`${classes.activityCreator}${creator_link ? '' : ' disabled-like'}`}
                                          avatar={!item.get('user') && item.get('token') ? <ModelIcon model='api_key' /> : undefined}
                                          label={item.get('user')
                                              ? item.get('user')
                                              : item.get('token')
                                                  ? (item.get('token_name') || item.get('token'))
                                                  : '----'
                                          }
                                          onClick={props.state === null ? (e) => {
                                              e.stopPropagation();
                                              if (creator_link) {
                                                  history.push(creator_link);
                                              }
                                          } : undefined}
                                    />
                                </Tooltip>
                                <div className={classes.activitySection}>
                                    <Chip className={`${classes.activityAction}${link ? ' link' : ''} ${item.get('action')}`}
                                          label={<React.Fragment>
                                              {item.get('action') === 'create' ? <ActionCreateIcon /> : item.get('action') === 'update' ? <ActionUpdateIcon /> : item.get('action') === 'delete' ? <ActionDeleteIcon /> : <span />}
                                              <FormattedMessage id={`activities.activity.action.${item.get('action')}`} />
                                          </React.Fragment>} />
                                    <ListItemText className={classes.activityModel}>
                                        <ModelIcon model={item.getCorrectModelName()} />
                                        <FormattedMessage id={`activities.activity.model.${item.getCorrectModelName()}`} />
                                    </ListItemText>
                                    <Tooltip title={item.get('object_name') || item.get('model_id')} placement='bottom-start'>
                                        <ListItemText className={classes.activityIdentifier}>
                                            {item.get('object_name') || item.get('model_id')}
                                        </ListItemText>
                                    </Tooltip>
                                </div>
                                {link && <div className='hover' />}
                            </ListItem>
                        </React.Fragment>;
                    })}
                </MUIList>}
            </CardContent>}
            footer={<Table size={1}>
                <Pagination model={ActivityLog} placement={items_placement} filters={filters} />
            </Table>} />
    </Card>;
}
