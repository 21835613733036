import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Company, User} from 'lib/models';
// Actions
import {markFiltered, saveItem} from 'actions/shared';
import {setState} from 'actions/app';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import {withRouter} from 'react-router-dom';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Typography from '@material-ui/core/Typography';
// icons
import NoAccessIcon from '@material-ui/icons/NotInterestedOutlined';
import SuccessIcon from '@material-ui/icons/CheckOutlined';


/**
 * Page displayed when you are trying to access SRAPS/Partner Portal without proper permission
 * Resellers may request access to Partner Portal via pp_tos_accepted
 */
function Unallowed(props) {
    return <Card>
        <CardHeader
            title={<FormattedMessage id={`unallowed${props.portal ? '.portal' : ''}.title`} />}
            subheader={<FormattedMessage id={`unallowed${props.portal ? '.portal' : ''}.subheader`} />}
            action={<ActionButton iconButton disabled>
                <NoAccessIcon />
            </ActionButton>} />
        {(props.portal && props.canRequestPortalAccess) && <React.Fragment>
            <CardContent>
                {props.user.get('pp_tos_accepted')
                    ? <React.Fragment>
                        <p className='notmargin'>
                            <Typography variant='body1' component='span'>
                                <FormattedMessage id='unallowed.portal.requested.text.1' />
                            </Typography>
                        </p>
                        <p><FormattedMessage id='unallowed.portal.requested.text.2' /></p>
                    </React.Fragment>
                    : <React.Fragment>
                        <p className='notmargin'>
                            <FormattedMessage id='unallowed.portal.text.1' /><br />
                            <FormattedMessage id='unallowed.portal.text.2' />
                        </p>
                        <p>
                            <Typography variant='body1' component='span'>
                                <FormattedMessage id='unallowed.portal.text.3' /><br />
                                <FormattedMessage id='unallowed.portal.text.4' />
                            </Typography>
                        </p>
                        <p><FormattedMessage id='unallowed.portal.text.5' /></p>
                    </React.Fragment>
                }
            </CardContent>
            {['saving_item_users', 'saved_item_users', 'failed_save_item_users'].includes(props.state)
                ? <CardActionsLoader success={props.state === 'saved_item_users'}
                                     failure={props.state === 'failed_save_item_users'}
                                     postAnimation={() => props.setState(null)} />
                : !props.user.get('pp_tos_accepted') && <ThemeProvider alt>
                <CardActions>
                    <Form onSubmit={props.handleSubmit} inCardActions>
                        <Field name='tos' fieldType='Checkbox'
                               size='full' className='flip'
                               label={<React.Fragment>
                                   <FormattedMessage id='tosfield.portal.description'
                                                     values={{
                                                         toc_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.toc.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.toc' />
                                                         </a>,
                                                         pp_link: <a href={props.intl.formatMessage({id: 'tosfield.portal.description.pp.link'})} className='hover-border' target='_blank' rel='noreferrer'>
                                                             <FormattedMessage id='tosfield.portal.description.pp' />
                                                         </a>
                                                     }} />
                                   {'*'}
                               </React.Fragment>} />
                    </Form>
                    <Button variant='contained' color='primary' type='submit'
                            disabled={props.state !== null || !props.formValues || props.formValues.tos !== true}
                            onClick={props.handleSubmit}>
                        <SuccessIcon />
                        <FormattedMessage id='unallowed.portal.request.submit' />
                    </Button>
                </CardActions>
            </ThemeProvider>}
        </React.Fragment>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isTrue(
        props.intl.formatMessage({id: 'tosfield.portal.error.required'}),
        errors, 'tos', data.tos
    );

    return errors;
};

const UnallowedForm = reduxForm({
    form: 'requestAccess',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // get data from user, ignoring formValues
        let rest_of_data = new User().popUpFields(props.user.toJS(), props.user.isAdmin());
        rest_of_data['pp_tos_accepted'] = true;

        // same save as in users.Detail
        return dispatch(saveItem(User, 'users', props.user.getIn(['links', 'self']), rest_of_data, props.user)).then(() => {
            // mark not updated lists for reload
            dispatch(markFiltered(User, `companyUsers-${props.company.get(new Company().getUniqueIdentifier())}`));
        });
    }
})(Unallowed);

const ConnectedUnallowed = connect((state, props) => ({
    portal: props.location.pathname.startsWith('/partner'),
    state: state.app.get('state'),
    user: state.auth.get('user'),
    company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
    initialValues: {
        tos: false
    },
    formValues: getFormValues('requestAccess')(state)
}), (dispatch) => bindActionCreators({
    saveItem,
    setState,
    markFiltered
}, dispatch))(UnallowedForm);

export default injectIntl(withRouter(ConnectedUnallowed));
