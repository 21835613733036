import React from 'react';


/**
 * Wave for forgotten password
 */
export default function ForgottenPassword(props) {
    const {viewBox = '0 0 940 214.35', style = {display: 'block'}, ...rest_of_props} = props;

    return <svg viewBox={viewBox} style={style} {...rest_of_props}>
        <g transform='translate(-.033203 -407.79)'>
            <path fill='url(#forgotten_password_wave_linear_0)' d='m-6.7873 481.93c0.02134-0.416 0.03202-0.832 0.05336-1.259 0.42694-10.652-1.7611-20.92 3.0206-31.146 4.6643-9.958 14.324-16.832 23.962-22.649 19.724-11.89 41.328-18.369 63.838-9.766 46.366 17.718 56.164 69.143 85.825 102.66 50.507 57.071 142.21 59.099 208.73 29.309 30.462-13.64 59.974-33.824 93.585-33.13 52.364 1.088 91.578 47.657 124.36 81.065 22.361 22.788 40.026 56.431 74.832 63.188 25.808 5.016 51.744-7.162 73.646-21.177 31.572-20.194 62.269-41.136 102.3-37.026 31.155 3.202 58.458 19.468 83.103 36.952 23.119 16.405 52.609 33.728 66.869 58.565 9.113 15.893 8.043 43.047-14.911 45.662-6.553 0.747-13.192-0.865-19.276-3.363-17.761-7.311-31.166-21.656-48.137-30.302-18.924-9.638-40.847-15.423-61.917-17.899-40.239-4.729-73.614 6.244-110.53 19.287-39.502 13.95-79.325 13.875-118.8-0.438-26.353-9.563-49.504-26.331-75.504-35.777-29.459-10.706-59.515-18.957-91.215-13.118-22.479 4.141-41.392 15.37-60.754 26.62-41.007 23.823-83.829 41.552-130.16 52.535-36.898 8.763-74.565 14.089-112.25 18.316-29.17 3.266-58.853 7.268-88.237 7.738-13.844 0.224-27.836-0.598-41.328-3.821-6.5641-1.569-12.99-3.704-19.095-6.554-4.2907-2.006-14.206-5.902-16.533-10.193-8.6348-15.925-4.6323-35.084-3.9385-52.418 0-33.408 2.679-67.03 4.0239-100.41 1.4729-37.154 2.9565-74.309 4.4508-111.45z' />
            <path fill='url(#forgotten_password_wave_linear_1)' d='m1.226 449.15c1.9746-7.685 10.396-14.153 18.7-13.79 8.0798 0.352 15.687 7.794 18.7 13.79 3.0127 5.997 2.3303 4.529 2.3303 4.529 1.3298 2.519 0 0 3.1499 5.732s29.998 66.45 49.499 97.82c17.419 28.018 59.397 35.478 88.963 23.353 22.371-9.179 41.808-23.919 63.411-34.593 14.633-7.236 30.696-12.509 47.005-11.709 41.894 2.05 65.653 42.961 82.25 76.391 5.934 11.965 10.876 26.139 20.952 35.372 2.006 1.836 4.323 3.48 6.991 3.949 3.213 0.566 6.489-0.629 9.361-2.177 12.157-6.554 20.29-19.18 26.224-31.263 8.987-18.294 12.509-38.905 18.967-58.043 6.094-18.081 12.936-35.948 21.208-53.207 14.921-31.167 36.973-68.951 68.246-87.448 35.478-20.985 72.409-7.974 88.579 28.103 10.695 23.855 18.155 48.885 25.509 73.861 5.924 20.141 9.329 50.422 26.449 64.863 12.403 10.46 33.781 11.495 40.463-4.846 11.442-27.986 3.768-67.691 34.038-85.271 52.62-30.558 80.659 44.178 105.97 72.388 9.414 10.492 21.464 19.063 35.243 22.959 13.769 3.896 29.32 2.764 41.531-4.547 8.976-5.379 24.282-11.922 33.311-3.255 24.808 23.791-3.116 66.784-9.403 92.785-1.014 4.194-2.092 8.72-5.337 11.57-3.276 2.892-8.069 3.383-12.402 2.796-9.862-1.345-19.202-5.294-29.032-6.767-20.536-3.074-40.228-11.292-57.039-23.45-14.921-10.79-28.306-35.276-47.977-37.037-25.563-2.284-35.799 29.107-48.959 44.637-27.142 32.042-79.474 26.449-109.32 1.281-18.679-15.754-38.233-28.637-62.856-32.341-27.239-4.088-55.737 2.775-78.834 17.569-24.015 15.391-42.747 37.603-66.741 53.047-19.362 12.467-43.398 22.212-66.72 18.657-42.928-6.542-63.848-46.184-100.14-64.041-28.445-13.993-50.496 3.587-76.336 14.004-29.203 11.773-56.762 14.975-87.949 13.15-21.016-1.228-41.936-3.683-62.792-6.554-16.789-2.316-40.719-1.345-55.747-9.702-14.484-8.059-12.307-28.146-13.096-42.267-0.7471-13.513-0.4163-27.058-1.0567-40.56-2.2307-46.899 2.9566-94.215 14.687-139.74z' />
            <path fill='transparent' stroke='#fbe332' strokeMiterlimit='10' strokeWidth='3' d='m34.275 757.24c27.281-10.364 45.842-37.923 57.007-63.667 10.941-25.254 16.704-52.375 24.4-78.792 10.065-34.508 23.599-68.162 42.416-98.784 18.316-29.79 40.89-54.552 76.315-63.07 54.797-13.182 100.5 19.479 133.71 59.729 0.214 0.256 0.427 0.513 0.641 0.769 21.357 25.926 42.106 52.375 61.777 79.592 19.17 26.524 37.454 54.19 58.053 79.817 18.946 23.556 41.04 45.384 68.204 58.651 33.728 16.48 71.127 15.22 104.44-1.388 27.356-13.63 49.642-34.817 68.022-58.96 19.383-25.467 33.184-54.328 51.457-80.447 6.244-8.912 14.025-17.152 23.972-21.549 33.92-15.018 54.445 24.805 72.43 45.917 15.53 18.22 42.48 27.687 61.084 7.504 7.642-8.294 10.652-19.063 14.217-29.545 9.948-29.288 18.134-59.014 31.892-86.893'  />
        </g>
        <defs>
            <linearGradient id='forgotten_password_wave_linear_0' x1='1060.9' x2='-152.56' y1='920.64' y2='804.57' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#F08046' offset='.052083' />
                <stop stopColor='#1A6E99' offset='.51042' />
                <stop stopColor='#31B7BC' offset='.91146' />
            </linearGradient>
            <linearGradient id='forgotten_password_wave_linear_1' x1='-13.973' x2='988.36' y1='566.69' y2='566.69' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#F08046' stopOpacity='.8' offset='4.4753e-7' />
                <stop stopColor='#F29546' stopOpacity='.8' offset='.0749' />
                <stop stopColor='#F7C241' stopOpacity='.8' offset='.2695' />
                <stop stopColor='#FADA37' stopOpacity='.8' offset='.4123' />
                <stop stopColor='#FBE332' stopOpacity='.8' offset='.487' />
                <stop stopColor='#F9D43A' stopOpacity='.8' offset='.5416' />
                <stop stopColor='#F6B943' stopOpacity='.8' offset='.6418' />
                <stop stopColor='#F3A145' stopOpacity='.8' offset='.7399' />
                <stop stopColor='#F18F46' stopOpacity='.8' offset='.8341' />
                <stop stopColor='#F08446' stopOpacity='.8' offset='.9227' />
                <stop stopColor='#F08046' stopOpacity='.8' offset='1' />
            </linearGradient>
        </defs>
    </svg>;
}
