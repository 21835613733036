import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
// Components
import ThemeProvider from 'components/ThemeProvider';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import {makeStyles} from '@material-ui/core/styles';
import Button from 'components/core/ui/mui/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import ConfirmIcon from '@material-ui/icons/CloseOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';


const useStyles = makeStyles(theme => ({
    // holder of everything
    dialog: {
        '& .MuiPaper-root' : {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            textAlign: 'center',
            padding: `${theme.spacing(2)}px 0`
        }
    },
    // holder of icon
    stateIcon: {
        color: theme.palette.primary[500],
        // icon itself
        '& svg': {
            fontSize: `${theme.spacing(7)}px`,
            display: 'block'
        }
    }
}));

/**
 * DMActivateDialog alerts the user that the chosen provisioning profile will activate DM on the newly added endpoint
 */
export default function DMActivateDialog(props) {
    const {accept} = props;
    const [open, setOpen] = useState(true); // flag for dialog open/close state
    // styles
    const classes = useStyles();

    return <Dialog open={open} className={classes.dialog}>
        <div className={classes.stateIcon}>
            <InfoIcon />
        </div>
        <DialogTitle>
            <FormattedMessage id={'dmactivatedialog.title'} />
        </DialogTitle>
        <DialogContent>
            <FormattedMessage id='dmactivatedialog.description.1' /><br />
            <FormattedMessage id='dmactivatedialog.description.2' />
            <SpaceDivider />
        </DialogContent>
        <ThemeProvider alt>
            <DialogActions>
                <Button variant='contained' color='primary' 
                    onClick={() => {setOpen(false); accept();}}>
                    <ConfirmIcon />
                    <FormattedMessage id='actions.confirm' />
                </Button>
            </DialogActions>
        </ThemeProvider>
    </Dialog>;
}
