import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Russian flag
 */
export default function RuFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <g fillRule='evenodd' strokeWidth='1pt'>
            <path fill='#ffffff' d='M0 0h640v480H0z' />
            <path fill='#0039a6' d='M0 160h640v320H0z' />
            <path fill='#d52b1e' d='M0 320h640v160H0z' />
        </g>
    </SvgIcon>;
}
