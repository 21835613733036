import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {useFilter, usePaginatedItems} from 'lib/filters';
import {DeadLetter, Paginator} from 'lib/models';
import {List as ImmutableList} from 'immutable';
import Moment from 'moment/moment';
// Actions
import {fetchItems, markOutdated} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';


/**
 * Renders Dead Letters from backend - view only
 */
export default function List() {
    // redux store
    const dispatch = useDispatch();
    const items_placement = new DeadLetter().getPlacement();
    const props = useSelector(state => ({
        state: state.app.get('state'),
        loaded: !!state.shared.getIn(['loaded', items_placement]),
        outdated: !!state.shared.getIn(['outdated', items_placement]),
        items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
        paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
        page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList()
    }), shallowEqual);
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'message_id', label: <FormattedMessage id='deadletters.list.table.message_id' />},
        {sortName: 'created_at', label: <FormattedMessage id='deadletters.list.table.created_at' />},
        {sortName: 'ttl', label: <FormattedMessage id='deadletters.list.table.ttl' />},
        {sortName: 'actor_name', label: <FormattedMessage id='deadletters.list.table.actor_name' />},
        {sortName: 'args', label: <FormattedMessage id='deadletters.list.table.args' />}
    ];
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new DeadLetter().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = ['created_at', 'asc'];
    const [, filters, filtersInitializing] = useFilter(
        false, items_placement, [], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(DeadLetter, items_placement, 'dead-letters', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters)]);
    // silent auto re-fetch with outdated
    useEffect(() => {
        if (props.outdated && props.paginator.get('page') === 1) {
            dispatch(fetchItems(DeadLetter, items_placement, 'dead-letters', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1, affect_state: false}));
        }
    }, [props.outdated, props.paginator.get('page')]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='deadletters.list.title' />}
            subheader={<FormattedMessage id='deadletters.list.subheader' />}
            action={<ActionButton iconButton disabled>
                <ModelIcon model='dead_letters' />
            </ActionButton>}
        />
        <Filters
            refreshDisabled={!props.loaded || props.state !== null}
            refresh={() => dispatch(markOutdated(DeadLetter, items_placement))}
        />
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell numeric={item.numeric} key={idx}>
                                {sortName === item.sortName
                                    ? <TableSortLabel active direction={sortDirection} disabled>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[`fetching_items_${items_placement}`, `fetching_items_pagination-${items_placement}`].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === `fetching_items_pagination-${items_placement}`}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                    : !paginatedItems.size
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : Object.keys(filters).length ? 'filters.empty' : 'deadletters.list.table.empty'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((item, idx) => {
                                return <TableRow
                                    key={idx} link hover
                                    onClick={() => props.state === null ? history.push(`/dead-letter-queue/${item.get(new DeadLetter().getUniqueIdentifier())}`) : {}}>
                                    <TableCell>{item.get('message_id')}</TableCell>
                                    <TableCell>{Moment(item.get('created_at')).format('l, LT')}</TableCell>
                                    <TableCell>{Moment(item.get('ttl')).format('l, LT')}</TableCell>
                                    <TableCell>{item.get('actor_name')}</TableCell>
                                    <TableCell>
                                        {item.get('args').entrySeq().map(([key, value]) => <div key={key}><strong>{key}</strong>{': '}{`${value}`}</div>)}
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <Pagination model={DeadLetter} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
    </Card>;
}
