import React from 'react';
import {theme} from 'theme';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Gradient Folder icon used in Asset File Manager
 */
export default function GradientFolder(props) {
    return <SvgIcon viewBox='0 0 63 51' {...props}>
        <path fill='url(#folder_icon_linear)' d='M25.25 0.079834H6.5C3.0625 0.079834 0.28125 2.92983 0.28125 6.41317L0.25 44.4132C0.25 47.8965 3.0625 50.7465 6.5 50.7465H56.5C59.9375 50.7465 62.75 47.8965 62.75 44.4132V12.7465C62.75 9.26317 59.9375 6.41317 56.5 6.41317H31.5L25.25 0.079834Z' />
        <defs>
            <linearGradient id='folder_icon_linear' x1='0.25' y1='25.406' x2='62.728' y2='25.406' gradientUnits='userSpaceOnUse'>
                <stop stopColor={theme.gradients.green.color1} />
                <stop stopColor={theme.gradients.green.color2} offset='1' />
            </linearGradient>
        </defs>
    </SvgIcon>;
}
