import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import {AssetFolder} from 'lib/models';
// components
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
// material-ui
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// vectors
import FileIcon from 'components/core/vectors/asset_manager/FileIcon';
import GradientFolder from 'components/core/vectors/asset_manager/GradientFolder';


const useStyles = makeStyles((theme) => ({
    itemContainer: {
        width: `${theme.spacing(17.5)}px`
    },
    card: {
        position: 'relative',
        border: 'none',
        width: '100%',
        height: `${theme.spacing(17.5)}px`,
        '&>.MuiCardActionArea-root': {
            width: '100%',
            height: '100%',
            border: `1px solid ${theme.palette.snomGrey[200]}`,
            borderRadius: 'inherit',
            // set ripple on card to fill the entire component
            position: 'unset'
        },
        '& .MuiCardHeader-root': {
            position: 'relative',
            padding: `${theme.spacing(2)}px ${theme.spacing(1.75)}px 0`,
            // display over CardMedia
            zIndex: 1
        },
        '& .MuiCardMedia-root': {
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiSvgIcon-root.folderIcon': {
                fontSize: `${theme.spacing(7.75)}px`
            }
        }
    },
    cardNameContainer: {
        color: theme.palette.snomGrey[800],
        marginTop: `${theme.spacing(1)}px`,
        wordBreak: 'break-word'
    }
}));

/**
 * Render asset file grid card
 * @param {object} props - props object
 * @param {object} props.asset - folder/file asset item
 * @param {() => void} props.onClick - on click card
 * @param {JSX.Element} props.cardHeaderAction - card header action component
 */
export default function AssetFileGridItem(props) {
    const {asset, onClick, cardHeaderAction} = props;
    const classes = useStyles();
    const isFolder = useMemo(() => asset.getPlacement() === new AssetFolder().getPlacement(), [asset.getPlacement()]);

    return <div className={classes.itemContainer}>
        <Card className={classes.card}>
            <CardActionArea component='div' onClick={onClick}>
                <CardHeader action={cardHeaderAction} />
                <CardMedia component='div'>
                    {isFolder ? <GradientFolder fontSize='large' className='folderIcon' /> :
                        asset.get('thumbnail_url') ? <img alt={asset.get('name')} src={asset.get('thumbnail_url')} />
                            : (<FileIcon mimetype={asset.get('mimetype')} fontSize='large' roundedContainer />)
                    }
                </CardMedia>
            </CardActionArea>
        </Card>
        <CardContent className={classes.cardNameContainer} nospace>
            <Typography align='center' variant='caption' component='div'>
                {asset.get('name')}
            </Typography>
        </CardContent>
    </div>;
}
