import React, {useEffect, useMemo} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router';
import {List as ImmutableList} from 'immutable';
import Moment from 'moment/moment';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useLocalFilter, useLocalPagination, useLocalSort} from 'lib/filters';
import {companySelector} from 'lib/company';
import {Company, Endpoint, Paginator, EndpointsImport, ProvisioningProfile} from 'lib/models';
// Actions
import {fetchItem, fetchItems, updatePaginator} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {CompanyField} from 'components/core/ui/Field';
import LocalPagination from 'components/core/ui/LocalPagination';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Button from 'components/core/ui/mui/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// vectors
import FailureIcon from '@material-ui/icons/ErrorOutlineOutlined';
import FinishedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';


const useStyles = makeStyles(theme => ({
    tableRow: {
        position: 'relative',
        '& > *:first-of-type::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: `${theme.spacing(0.5)}px`,
            height: '100%',
            backgroundColor: theme.palette.snomGrey[400]
        },
        '&.failure': {
            '& $statusCell': {
                color: theme.palette.error.main
            },
            '& > *:first-of-type::before': {
                backgroundColor: theme.palette.error.main
            }
        },
        '&.finished ': {
            '& $statusCell': {
                color: theme.palette.success.main
            },
            '& > *:first-of-type::before': {
                backgroundColor: theme.palette.success.main
            }
        }
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.spacing(1)}px`,
        color: theme.palette.snomGrey.dark,
        '& .MuiLinearProgress-root': {
            width: '100%',
            '&::before': {
                backgroundImage: theme.gradients.gray.color
            }
        }
    }
}));

export default function EndpointImports(passed_props) {
    const {item} = passed_props;
    // styles
    const classes = useStyles();
    const theme = useTheme();
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    // redux store
    const dispatch = useDispatch();
    const load_task_identifier = useMemo(() => new EndpointsImport().getUniqueIdentifier(), []);
    const {company_identifier, items_placement, ...props} = useSelector(state => {
        const {company, company_identifier, selectedCompany, company_loaded} = companySelector(state, search, pathname);
        const items_placement = `${new EndpointsImport().getPlacement()}${item ? `-${item.get(new Endpoint().getUniqueIdentifier())}` : company_identifier === 'my' ? '' : `-${company_identifier}`}`;
        const provprofile_placement = `${new ProvisioningProfile().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;
        const items = state.shared.getIn(['items', items_placement]) || ImmutableList();

        return {
            items,
            items_placement,
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            outdated: !!state.shared.getIn(['outdated', items_placement]),
            company,
            selectedCompany,
            company_identifier,
            company_loaded,
            state: state.app.get('state'),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            provisioning_profiles_loaded: !!state.shared.getIn(['loaded', provprofile_placement]),
            provisioning_profiles_items: state.shared.getIn(['items', provprofile_placement]) || ImmutableList(),
            provisioning_profiles_placement: provprofile_placement
        };
    }, shallowEqual);
    // define table columns
    const tableHead = useMemo(() => [
        {sortName: 'created_at', label: <FormattedMessage id='endpoints.imports.list.table.header.created_at' />},
        // uncomment when filename is implemented
        // {label: <FormattedMessage id='endpoints.imports.list.table.header.file' />},
        {sortName: 'provisioning_profile', label: <FormattedMessage id='endpoints.imports.list.table.header.provprofile' />},
        {sortName: 'total', label: <FormattedMessage id='endpoints.imports.list.table.header.total' />},
        {label: <FormattedMessage id='endpoints.imports.list.table.header.status' />}
    ], []);
    // local sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded, items_placement, tableHead.map(item => item.sortName), 'created_at', 'asc');
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, items_placement, ['company']);
    // pagination
    const [paginatedItems, page, pages, setPage] = useLocalPagination(filteredItems);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.selectedCompany && (props.loaded === false || props.outdated === true)) {
            dispatch(fetchItems(EndpointsImport, items_placement, props.selectedCompany.getIn(['links', 'endpoint-bulk-load-tasks']), null, null, {affect_state: !props.loaded}));
        }
    }, [props.loaded, props.selectedCompany, props.outdated]);
    // fetch related data without affecting state
    useEffect(() => {
        if (!props.provisioning_profiles_loaded && props.selectedCompany) {
            dispatch(fetchItems(ProvisioningProfile, props.provisioning_profiles_placement, props.selectedCompany.getIn(['links', 'provisioning-profiles']), null, null, {affect_state: false}));
        }
    }, [props.provisioning_profiles_loaded, props.provisioning_profiles_placement, props.selectedCompany?.getIn(['links', 'provisioning-profiles'])]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (company_identifier !== 'all' && !props.selectedCompany) {
            dispatch(fetchItem(Company, 'my-companies', ['companies', company_identifier], {success_affect_state: false, ignore_403: true}));
        }
    }, [company_identifier, props.selectedCompany]);

    return <Card>
        <CardHeader title={<FormattedMessage id='endpoints.imports.list.title' />}
            subheader={<FormattedMessage id='endpoints.imports.list.subheader' />} />
        <Filters disabled={props.state !== null}>
            {((props.company.get('company_type') === 'reseller') && !item) && <CompanyField
                value={filters.company || ''}
                change={value => {
                    // make sure to move previous paginator to page 1
                    dispatch(updatePaginator({...props.paginator.toJS(), page: 1}, items_placement));
                    filterItems('company', value);
                }}
                fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies
            />}
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {(tableHead).map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName} direction={sortDirection} onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label
                                }
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {props.state === `fetching_items_${items_placement}` || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.items.size ? 'filters.empty' : 'endpoints.imports.list.table.empty'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        : <TableBody>
                            {props.loaded && paginatedItems.map(importItem => {
                                const percent = importItem.get('last_processed') * 100 / importItem.get('total');
                                const progress = isNaN(percent) ? 0 : Math.round(percent);
                                const status = importItem.get('status');
                                const hasErrors = importItem.get('has_errors');
                                const onClick = hasErrors ? () => history.push({pathname: `/phones/imports/${importItem.get(load_task_identifier)}`, search}) : undefined;

                                return <TableRow key={importItem.get(load_task_identifier)} className={`${classes.tableRow} ${status}`} hover={hasErrors}>
                                    <TableCell link={!!onClick} onClick={onClick}>{Moment(importItem.get('created_at')).format('l, LT')}</TableCell>
                                    {/** uncomment when filename is implemented */}
                                    {/* <TableCell>
                                        <FormattedMessage id='endpoints.imports.list.table.body.file' values={{filename: importItem.get('filename')}} />
                                    </TableCell> */}
                                    <TableCell>
                                        {!props.provisioning_profiles_loaded
                                            ? <CircularProgress size={36} />
                                            : importItem.get('provisioning_profile')
                                                ? <Link className='hover-border' to={`/provisioning-profiles/${importItem.get('provisioning_profile')}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`}>
                                                    {props.provisioning_profiles_items.find(el => el.get(new ProvisioningProfile().getUniqueIdentifier()) === importItem.get('provisioning_profile'))?.name || ''}
                                                </Link>
                                                : <FormattedMessage id='filters.none' />}
                                    </TableCell>
                                    <TableCell link={!!onClick} onClick={onClick}>{importItem.get('total')}</TableCell>
                                    <TableCell link={!!onClick} onClick={onClick}>
                                        <div className={classes.status}>
                                            {['failure', 'completed'].includes(status)
                                                ? <React.Fragment>
                                                    {hasErrors ? <FailureIcon color='error' /> : <FinishedIcon />}
                                                    {hasErrors ? <Button variant='outlined' size='small' style={{marginLeft: 'auto',color: theme.palette.danger.main, borderColor: theme.palette.danger.main}}>
                                                    <FormattedMessage id="endpoints.imports.list.table.body.status.failure.showErrors" />
                                                    </Button> : <FormattedMessage id={`endpoints.imports.list.table.body.status.${status}`} />}
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    <LinearProgress variant='determinate' value={progress} color={progress === 100 ? 'secondary' : 'primary'} />
                                                    <Typography variant='caption' color='inherit'>{progress}%</Typography>
                                                </React.Fragment>}
                                        </div>
                                    </TableCell>
                                </TableRow>;
                            })}
                        </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <LocalPagination page={page} pages={pages}
                                 onChange={(page) => setPage(page)} />
            </Table>} />
    </Card>;
}
