import {constants} from 'reducers/app';


/**
 * Simple action creator to modify application state.
 * State is not globally implemented, use this state for various action, e.g. saving user changes
 *
 * @param {string|null} state - e.g. null, 'saving_user'
 * @param {string|null} identifier - optional identifier
 */
export function setState(state, identifier = null) {
    return {
        type: constants.APP_STATE,
        state,
        identifier
    };
}

/**
 * Simple action creator to Open or Close menu
 *
 * @param {boolean} open - Should we open or close menu?
 */
export function toggleMenu(open) {
    return {
        type: constants.APP_TOGGLE_MENU,
        open
    };
}

/**
 * Simple action creator to Open or Close notifications drawer
 *
 * @param {boolean} open - Should we open or close drawer?
 */
export function toggleNotificationsDrawer(open) {
    return {
        type: constants.APP_TOGGLE_NOTIFICATIONS,
        open
    };
}

/**
 * Simple action creator to add message to store
 *
 * @param data - Raw data of message model
 * @param notification - Flag is message is header notification
 */
export function addMessage(data, notification = false) {
    return {
        type: constants.APP_ADD_MESSAGE,
        data,
        notification
    };
}

/**
 * Simple action creator to remove message/notification from store
 *
 * @param index - index or identifier of message to remove
 * @param notification - Flag is message is header notification
 */
export function removeMessage(index, notification = false) {
    return {
        type: constants.APP_REMOVE_MESSAGE,
        index,
        notification
    };
}

/**
 * Simple action creator to set backup of Forms into app store
 *
 * @param forms - state.form
 */
export function backupForms(forms) {
    return {
        type: constants.APP_BACKUP_FORMS,
        forms
    };
}
