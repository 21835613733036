import React, {useCallback, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/styles';
// material ui
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// icons
import MoreVert from '@material-ui/icons/MoreVert';


const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.snomGrey[400]
    },
    menu: {
        '& .delete': {
            color: theme.palette.danger.main
        }
    }
}));

/**
 * Render file's action button with choices:
 * - Edit file
 * - Delete file
 * @param {object} props - component props
 * @param {string} props.fileId - id of target file
 */
export default function FileActionButton(props) {
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const onClickMenuItem = useCallback((selectedDialog) => {
        history.push(`/asset-manager/${match.params.identifier}/files/${props.fileId}/${selectedDialog}`);
        setAnchorEl(null);
    }, [props.fileId, match.params.identifier]);

    const stopPropagation = useCallback((e) => e.stopPropagation(), []);

    return <div onClick={stopPropagation}
        // stop parents ripple effect
        onTouchStart={stopPropagation} onMouseDown={stopPropagation}
    >
        <IconButton component='div' className={classes.button} size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVert />
        </IconButton>
        <Menu className={classes.menu} anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
            {['rename', 'move', 'delete'].map(action => (
                <MenuItem key={action} onClick={() => onClickMenuItem(action)} className={action}>
                    <FormattedMessage id={`assets.file_manager.file.actions.${action}`} />
                </MenuItem>
            ))}
        </Menu>
    </div>;
}
