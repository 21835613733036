import React from 'react';
import {makeStyles} from '@material-ui/styles';
// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'flex',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        justifyItems: 'center',
        gap: ({flat}) => !flat && `${theme.spacing(2)}px`,
        padding: ({flat}) => !flat && `${theme.spacing(4)}px ${theme.spacing(8)}px`,
        '& .loader': {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
            padding: ({flat}) => !flat && `${theme.spacing(2)}px ${theme.spacing(4)}px`
        }
    }
}));

/**
 * Render asset files and folders in grid
 * @param {object} props - props object
 * @param {boolean} props.isLoading - loading flag
 * @param {boolean} props.flat - flat render flag
 */
export default function AssetFileGrid(props) {
    const {isLoading, children, flat} = props;
    const classes = useStyles({flat});

    return <div className={classes.grid}>
        {isLoading && <div className='loader'><LinearProgress /></div>}
        {!isLoading ? children :  null}
    </div>;
}
