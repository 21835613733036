import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Statistics} from 'lib/models';
import {theme} from 'theme';
// actions
import {setState} from 'actions/app';
import {fetchItem, fetchLinks} from 'actions/shared';
// components
import Graph from 'components/core/ui/Graph';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';


/**
 * Render performance graph
 */
export default function StatisticPerformance() {
    const dispatch = useDispatch();

    const props = useSelector(state => {
        const findStatistic = (identifier) => state.shared.getIn(['items',   'statistics']).find(el => el.get(new Statistics().getUniqueIdentifier()) === identifier);
        const loaded_links = state.api.getIn(['loaded_links', 'reports']);
        let stats_performance = findStatistic('performance')?.toJS();

        // split data to series
        const stats_performance_series = stats_performance?.data?.length ? stats_performance?.data?.reduce((series, item) => {
            series[0].data.push({value: item.apex_latency.avg, label: item.date});
            series[1].data.push({value: item.apex_latency.min, label: item.date});
            series[2].data.push({value: item.apex_latency.max, label: item.date});
            series[3].data.push({value: item.endpoint_errors, label: item.date});
            return series;
        }, [{data: []}, {data: []}, {data: []}, {data: []}]) : [];

        return {
            state: state.app.get('state'), stats_performance: stats_performance_series,
            loaded_links, stats_performance_loading: !loaded_links && !stats_performance_series
        };
    }, shallowEqual);
        // fetch reports links and performance statistic
    useEffect(() => {
        if (props.loaded_links === false) {
            dispatch(fetchLinks('reports', 'reports', {success_affect_state: false}));
        } else if (!props.stats_performance?.length) {
            dispatch(setState('fetching_items_statistics'));
            // fetch performance statistic
            dispatch(fetchItem(Statistics, 'statistics', 'reports.performance',
                {expand_item: new Statistics({statistic_name: 'performance'}), expand_item_data: true, affect_state: false}
            )).then(() => dispatch(setState(null))).catch(() => dispatch(setState(null)));
        }
    }, [props.loaded_links]);
    useEffect(() => {
        if (props.state === 'fetching_links_reports') {
            dispatch(setState(null));
        }
    }, [props.state]);

    return <Card>
        <CardHeader title={<FormattedMessage id='statistics.performance.title' />}
                    subheader={<FormattedMessage id='statistics.performance.subheader' />}
                    action={<ActionButton iconButton disabled><ModelIcon model='statistics_performance' /></ActionButton>} />
        <CardContent>
            <Graph type='area' loading={props.stats_performance_loading} data={props.stats_performance} momentFormat='HH:mm' options={{
                base_intl: 'statistics.performance.data', labels: ['apex_latency_avg', 'apex_latency_min', 'apex_latency_max', 'endpoint_errors'],
                colors: [theme.graphs[1], theme.palette.grey[500], theme.palette.grey[300], theme.graphs['danger']],
                xaxis: {tickAmount: 30, labels: {rotate: -45}},
                disabledLabels: ['apex_latency_min', 'apex_latency_max']
            }} />
            </CardContent>
    </Card>;
}
