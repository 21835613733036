import {Map, List} from 'immutable';
import keymirror from 'keymirror';
import {asModel, Message, Notification} from 'lib/models';


export const constants = keymirror({
    APP_STATE: null,
    APP_TOGGLE_MENU: null,
    APP_TOGGLE_NOTIFICATIONS: null,
    APP_ADD_MESSAGE: null,
    APP_REMOVE_MESSAGE: null,
    APP_BACKUP_FORMS: null
});

const defaults = Map({
    state: null,
    identifier: null,
    openMobileMenu: false,
    openNotifications: false,
    messages: List(),
    notifications: List(),
    backupForms: null
});

const reducer = (state = defaults, action) => {
    switch (action.type) {
        // sets application state
        case constants.APP_STATE:
            return state.set('state', action.state).set('identifier', action.identifier);

        // open or close mobile menu
        case constants.APP_TOGGLE_MENU:
            return state.set('openMobileMenu', action.open);

        // open or close notifications drawer
        case constants.APP_TOGGLE_NOTIFICATIONS:
            return state.set('openNotifications', action.open);

        // add Message/Notification to list
        case constants.APP_ADD_MESSAGE:
            return state.set(action.notification ? 'notifications' : 'messages',
                List([
                    asModel(action.notification ? Notification : Message, action.data),
                    ...state.get(action.notification ? 'notifications' : 'messages')
                ]));

        // remove Message/Notification from list or all of them
        case constants.APP_REMOVE_MESSAGE:
            return state.set(action.notification ? 'notifications' : 'messages', action.index === null
                ? defaults.get(action.notification ? 'notifications' : 'messages')
                : !isNaN(action.index)
                ? state.get(action.notification ? 'notifications' : 'messages').delete(action.index)
                : state.get(action.notification ? 'notifications' : 'messages').filter(message => message.get('identifier') !== action.index));

        // make backup of Forms for future restore
        case constants.APP_BACKUP_FORMS:
            return state.set('backupForms', action.forms || null);

        default:
            return state;
    }
};
export default reducer;
