import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// Components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import MUICircularProgress from '@material-ui/core/CircularProgress';
// icons
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import FailureIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles(theme => ({
    // holder of everything
    root: {
        position: 'relative'
    },
    // circular by itself
    progress: {
        opacity: '1',
        // allow animation
        transition: props => theme.transitions.create('opacity',
            {duration: props.animationLength / 2}),
        '&.anim': {
            // hide
            opacity: '0'
        }
    },
    // success or failure icon
    icon: {
        display: 'block',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        margin: '0 auto',
        // correct size
        fontSize: (props) => props.size ? `${props.size}px` : '40px',
        width: (props) => props.size ? `${props.size}px` : '40px',
        // hide by default
        opacity: '0',
        // allow animation
        transition: props => theme.transitions.create('opacity',
            {duration: props.animationLength / 2}),
        transitionDelay: props => `${(props.animationLength / 2)}ms`,
        '&.success': {
            color: theme.palette.success[500]
        },
        '&.failure': {
            color: theme.palette.danger[500]
        },
        '&.anim': {
            // display icon
            opacity: '1'
        }
    }
}));

/**
 * CircularProgress with additional success/failure/postAnimation functionality like CardActionsLoader
 */
export default function CircularProgress(props) {
    // Split some stuff from props
    let {_classes = {}, className, size, success = false, failure = false, postAnimation, ...rest_of_props} = props;
    // local state
    const animationLength = 500; // duration of success animation in MS
    const [successState, setSuccess] = useState(false);
    const [failureState, setFailure] = useState(false);
    const [firstMount, setFirstMount] = useState(true);
    // Merge classes from props and our custom
    const classes = useStyles({animationLength: animationLength, size: size});
    const {root, progress, icon} = _classes;
    const rootClasses = [
        root, classes.root
    ].filter(Boolean).join(' ');
    const iconClasses = [
        classes.icon, icon
    ].filter(Boolean).join(' ');
    let progressClasses = [
        classes.progress, progress, className
    ].filter(Boolean).join(' ');
    // success and failure with animation
    let successIconClasses = `${iconClasses} success`;
    let failureIconClasses = `${iconClasses} failure`;
    if (successState) {
        progressClasses = `${progressClasses} anim`;
        successIconClasses = `${successIconClasses} anim`;
    } else if (failureState) {
        progressClasses = `${progressClasses} anim`;
        failureIconClasses = `${failureIconClasses} anim`;
    }

    useEffect(() => {
        if (success || failure) {
            setTimeout(() => {
                if (success) {
                    setSuccess(true);
                    setTimeout(() => {
                        postAnimation(true);
                    }, (animationLength + 1000));
                } else if (failure) {
                    setFailure(true);
                    setTimeout(() => {
                        postAnimation(false);
                    }, (animationLength + 1000));
                }
            }, firstMount ? 300 : 0);
        }
        setFirstMount(false);
    }, [success, failure]);

    return <div className={rootClasses}>
        <MUICircularProgress size={size} className={progressClasses} {...rest_of_props} />
        {postAnimation && <ThemeProvider alt>
            <SuccessIcon className={successIconClasses} />
            <FailureIcon className={failureIconClasses} />
        </ThemeProvider>}
    </div>;
}
