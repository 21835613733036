import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
// material-ui
import MUICardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles(theme => ({
    cardContentRoot: {
        '&.notspace': {
            paddingTop: '0'
        },
        '&.notbspace': {
            paddingTop: '0',
            paddingBottom: '0'
        },
        '&.nospace': {
            padding: '0'
        }
    }
}));

/**
 * Extends CardContent component to change some styles
 */
export default function CardContent(props) {
    // Split some stuff from props
    const {_classes, className, nospace, notbspace, notspace, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        classes.cardContentRoot, root, className,
        nospace ? 'nospace' : notbspace ? 'notbspace' : notspace ? 'notspace' : ''
    ].filter(Boolean).join(' ');

    return <MUICardContent
        classes={{
            root: rootClasses,
            ...rest_of_classes
        }}
        {...rest_of_props}
    />;
}
