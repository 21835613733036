import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {FeatureFlag} from 'lib/models';


/**
 * Function to evaluate featureFlag access
 *
 * @param {object} featureFlag - FeatureFlag instance
 * @param {object} options - options object contains references {context_key: id}
 * @return {boolean}
 */
export function evaluateFeatureFlag(featureFlag, options = {}) {
    // in case we don't have feature flag at all or feature flag is not enabled, disallow
    if (!featureFlag || !featureFlag.get('enabled')) {
        return false;
    }
    // check if we don't have rules, allow
    if (featureFlag.get('rules').size === 0) {
        return true;
    } else {
        // allow/disallow based on the rules
        return !!featureFlag.get('rules').reduce(([lastResult, operator], rule) => {
            const reference = rule.get('reference').map(r =>  r ? r.toLowerCase(): r);
            const option_context_key = options[rule.get('context_key')];
            const referenceValue = (option_context_key || '').toLowerCase();
            const currentResult = reference.includes(referenceValue);

            return operator
                ? [operator === 'or' ? lastResult || currentResult : lastResult && currentResult, rule.get('logical_operator')]
                : [currentResult, rule.get('logical_operator')];
        }, [])[0];
    }
}

/**
 * Retrieve state of the required feature, example usage as:
 *     const featureFlag = useFeatureFlag('feature_name', {company: props.selectedCompany.get(new Company().getUniqueIdentifier())});
 *
 * @param {string} name - feature name
 * @param {object} options - options object contains references {context_key: id}
 * @return {boolean}
 */
export default function useFeatureFlag(name, options = {}) {
    const featureFlagIdentifier = useMemo(() => new FeatureFlag().getUniqueIdentifier(), []);
    const featureFlag = useSelector(state => state.shared.getIn(['items', 'feature-flags']).find(item => item.get(featureFlagIdentifier) === name));

    return useMemo(() => {
        return evaluateFeatureFlag(featureFlag, options);
    }, [featureFlag?.get(featureFlagIdentifier), JSON.stringify(options)]);
}
