'use strict';
import React from 'react';
import {connect} from 'react-redux';
import {hexToSnomRGBA, snomRGBAtoHex} from 'lib/util';
// Components
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import {SketchPicker as ColorPicker} from 'react-color';
// material-ui
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// icons
import ColorPickerIcon from '@material-ui/icons/ColorLensOutlined';


/**
 * Redux Field for SNOM RGB color picker
 */
class ColorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colorDialogOpen: false, // open/close status of Color dialog
            colorDialogAnchor: null, // anchor element for popper
            color: null // selected color (in component to increase performance)
        };
    }

    render() {
        // Split some stuff from props
        let {permissions, permission, selectedValue, change, name, ...rest_of_props} = this.props;

        return <FieldWithIconHolder>
            <Field name={name} fieldType='TextField' withIcon={true} {...rest_of_props} />
            {(!rest_of_props.excludePermission && permissions.get(permission) === 'R') || rest_of_props.disabled
                ? <FieldIcon disabled={true}>
                <ColorPickerIcon style={{color: snomRGBAtoHex(selectedValue)}} />
            </FieldIcon>
                : <React.Fragment>
                <Popper open={this.state.colorDialogOpen}
                        anchorEl={this.state.colorDialogAnchor}
                        style={{zIndex: 1600}}
                        placement='bottom-end'>
                    <ClickAwayListener onClickAway={() => this.setState({colorDialogOpen: false})}>
                        <ColorPicker color={this.state.color ? this.state.color : selectedValue ? snomRGBAtoHex(selectedValue) : '#000000'}
                                     onChange={(color, event) => this.setState({color: color.hex})}
                                     onChangeComplete={(color, event) => change(name, hexToSnomRGBA(color.hex))}
                                     disableAlpha={true} presetColors={[]} />
                    </ClickAwayListener>
                </Popper>
                <FieldIcon disabled={this.state.colorDialogOpen}
                           onClick={(event) => this.setState({colorDialogAnchor: event.currentTarget, colorDialogOpen: !this.state.colorDialogOpen})}>
                    <ColorPickerIcon style={{color: snomRGBAtoHex(selectedValue)}} />
                </FieldIcon>
            </React.Fragment>}
        </FieldWithIconHolder>;
    }
}

ColorField = connect((state, props) => ({
    permissions: state.auth.get('permissions'),
    permission: state.auth.get('permission')
}))(ColorField);
export default ColorField;
