import React from 'react';
// material-ui
import MUITooltip from '@material-ui/core/Tooltip';


/**
 * Extends Tooltip with support for disabled status (removed tooltip)
 */
export default function Tooltip(props) {
    // Split some stuff from props
    const {disabled = false, title, ...rest_of_props} = props;

    return <MUITooltip title={disabled ? '' : title}{...rest_of_props} />;
}
