import React, {useEffect, useRef} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Field as ReduxField} from 'redux-form';
import Moment from 'moment';
import {useDebounce} from 'use-debounce';
import {theme} from 'theme';
// Components
import {TextField, Select, Checkbox, Switch} from 'redux-form-material-ui/es/index';
import DatePicker from 'components/core/ui/DatePicker';
import ColorField from 'components/core/ui/fields/ColorField';
import CountryField from 'components/core/ui/fields/CountryField';
import LanguageField from 'components/core/ui/fields/LanguageField';
import MarkdownField from 'components/core/ui/fields/MarkdownField';
import SearchField from 'components/core/ui/fields/SearchField';
import DateSearchField from 'components/core/ui/fields/DateSearchField';
import JSONField from 'components/core/ui/fields/JSONField';
import SalutationField from 'components/core/ui/fields/SalutationField';
import ClaimStatusField from 'components/core/ui/fields/ClaimStatusField';
import SubmissionStatusField from 'components/core/ui/fields/SubmissionStatusField';
import CompanyField from 'components/core/ui/fields/CompanyField';
import CompanyTypeField from 'components/core/ui/fields/CompanyTypeField';
import CompanyStatusField from 'components/core/ui/fields/CompanyStatusField';
import CompanySourcesField from 'components/core/ui/fields/CompanySourcesField';
import CompanyPartnerLevelField from 'components/core/ui/fields/CompanyPartnerLevelField';
import DepartmentField from 'components/core/ui/fields/DepartmentField';
import DynamicPageSlug from 'components/core/ui/fields/DynamicPageSlug';
import TimeField from 'components/core/ui/fields/TimeField';
import ChoicesField from 'components/core/ui/fields/ChoicesField';
import IconButtonSelect from 'components/core/ui/fields/IconButtonSelect';
import CertificateField from 'components/core/ui/fields/CertificateField';
import MACSField from 'components/core/ui/fields/MACSField';
import HandsetsField from 'components/core/ui/fields/HandsetsField';
import AutoCompleteField from 'components/core/ui/fields/AutoCompleteField';
import SubmissionProductVariantField from 'components/core/ui/fields/SubmissionProductVariantField';
import SalesClustersField from 'components/core/ui/fields/SalesClustersField';
import DropFile from 'components/core/ui/fields/DropFile';
import SettingField, {validateSettingsField, getSettingInitialValue, getSettingsInitialValues} from 'components/core/ui/fields/SettingFieldDecider';
import SettingFieldType from 'components/core/ui/fields/SettingFieldType';
import TicketStatusField from 'components/core/ui/fields/TicketTypeField';
import TicketTypeField from 'components/core/ui/fields/TicketTypeField';
import DMUpgradeVersionField from 'components/core/ui/fields/DMUpgradeVersionField';
// material-ui
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MUITextField from '@material-ui/core/TextField';
import MUISwitch from '@material-ui/core/Switch';
import MUISelect from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles(theme => ({
    formControl: {
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
        width: '320px',
        maxWidth: '100%',
        position: 'relative',
        zIndex: '10',
        whiteSpace: 'normal',
        // field itself
        '& input, & textarea': {
            letterSpacing: 'normal',
            lineHeight: theme.typography.body2.lineHeight
        },
        // highlighted input
        '&.highlight': {
            '& label': {
                color: theme.palette.secondary[500]
            },
            '& > div:before, & > div:after': {
                borderColor: `${theme.palette.secondary[500]} !important`
            }
        },
        // icon after the text and before the text
        '&.icon-after': {
            '& input, & textarea': {
                paddingRight: (props) => `${theme.spacing(1) + (props.iconWidth || 48)}px`
            },
            '& .selectSelectMenu': {
                paddingRight: (props) => `${theme.spacing(1 + 3) + (props.iconWidth || 48)}px`
            },
            '& .selectSelectMenu ~ svg': {
                right: (props) => `${theme.spacing(1) + (props.iconWidth || 48)}px` // select arrow
            }
        },
        '&.icon-before': {
            '& label': {
                marginLeft: `${theme.spacing(1) + 48}px`
            },
            '& input, & textarea, & .selectSelectMenu': {
                paddingLeft: `${theme.spacing(1) + 48}px`
            }
        },
        // specific for field types
        '&.flip': {
            // flip checkbox
            '& label': {
                textAlign: 'right',
                // flip label and checkbox
                marginLeft: '16px',
                marginRight: '-11px',
                flexDirection: 'row-reverse',
                // required error message
                '& ~ p': {
                    textAlign: 'right'
                }
            }
        },
        '&.errorfield': {
            // by default move into content above
            marginTop: `-${theme.spacing(1)}px`
        },
        // dynamic size for checkboxes and switches
        '&.dynamic': {
            width: 'auto',
            maxWidth: '320px'
        },
        // normal size (could be used in combination with textArea
        '&.regular': {
            width: '320px'
        },
        // double (textarea)
        '&.double': {
            width: `${(320 * 2) + (theme.spacing(2))}px`
        },
        // half size
        '&.half': {
            width: `${(320 / 2) - (theme.spacing(1))}px`
        },
        // 1/3 size
        '&.third': {
            width: `${(320 / 3) - theme.spacing(2)}px`
        },
        // full size
        '&.full': {
            width: '100%'
        },
        // highlight values in disabled fields
        '& input:disabled, & textarea:disabled': {
            color: theme.palette.text['primary']
        }
    },
    // content like title directly in the <Form />
    inFormContent: {
        width: '100%',
        margin: `0 ${theme.spacing(1)}px`,
        '&.inline': {
            display: 'inline-flex',
            width: 'auto'
        }
    },
    checkboxHelperText: {
        marginTop: '-8px' // move closer to checkbox
    },
    disabledCheckbox: {
        // highlight disabled checkbox
        color: `${theme.palette.text['primary']} !important`
    },
    disabledSelect: {
        // highlight disabled select
        color: `${theme.palette.text['primary']} !important`,
        // hide arrow from select
        '&.hide-arrow ~ svg:last-child': {
            display: 'none'
        }
    },
    // support for ListItemText and ListItemIcon with 'icon' and 'text' classes
    selectListItemSupport: {
        '& .icon, & .text': {
            display: 'inline-flex',
            verticalAlign: 'top'
        },
        // correct proportion of icon
        '& .icon': {
            minWidth: '0', // get rid of not necessary min-width
            marginLeft: `${theme.spacing(1)}px`, // space between icon and wall
            marginRight: `${theme.spacing(2)}px` // space between icon and text
        },
        '& .icon > svg, & .icon > span': {
            margin: '-2.5px 0' // from 24px -> 19px
        },
        // correct proportion of text
        '& .text': {
            margin: '0',
            '& > span': {
                lineHeight: '1.1875em'
            }
        }
    },
    // select menu
    selectMenuPaper: {
        // limit size to display nicely large choices
        maxWidth: '320px',
        // menu items
        '& li': {
            display: 'block',
            minHeight: 'auto',
            // don't overflow
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // correct proportion of icon
            '& .icon': {
                marginLeft: '0' // get rid of space between icon and wall
            }
        }
    },
    // holder of Field with FieldIcon
    fieldWithIconHolder: {
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        maxWidth: '100%',
        '&.full': {
            width: '100%'
        }
    },
    // in field icon
    fieldIcon: {
        display: 'inline-flex',
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 -${theme.spacing(1) + 48}px`,
        position: 'relative',
        zIndex: '20',
        height: '48px', // fixed height
        // 2nd icon
        '&:nth-last-child(2)': {
            left: `-${48}px`
        },
        // icon Before field and not After
        '&.before': {
            margin: `${theme.spacing(1)}px -${theme.spacing(1) + 48}px ${theme.spacing(1)}px ${theme.spacing(1)}px`
        },
        // normal color even for disabled variant
        '&.disabled': {
            color: theme.palette.text.secondary
        }
    },
    // in field button
    fieldButton: {
        display: 'inline-flex',
        position: 'relative',
        zIndex: '20',
        height: '100%',
        width: (props) => `${props.iconWidth || 100}px`, // fixed size
        flexShrink: '0',
        // align into field
        margin: (props) => `10px ${theme.spacing(1)}px 0 -${theme.spacing(1) + (props.iconWidth || 100)}px !important`,
        // this makes button semi-transparent background not transparent
        background: theme.palette.common.white,
        // action button inside
        '& > button': {
            width: '100%', // respecting fixed size of parent
            margin: '0 !important' // margin is handled in parent
        }
    },
    // LinearProgress bellow field serving as loader
    fieldLoader: {
        // move bellow field line
        position: 'absolute',
        zIndex: '20',
        top: '46px',
        left: '0',
        right: '0',
        // match input underline size
        height: '2px',
        // hide by default
        opacity: '0',
        transition: theme.transitions.create('opacity',
            {duration: theme.transitions.duration.short}),
        '&.show': {
            opacity: '1'
        }
    }
}));

/**
 * DateTimePicker field, use in redux-form Field as component (component={_renderDateTimeField})
 *
 * @param field - reduxform field
 * @private
 */
const _renderDateTimeField = (field) =>
    <FormControl fullWidth={field.fullWidth ? true : undefined} required={field.required}
                 error={field.meta.touched && field.meta.error ? true : undefined}
                 disabled={field.disabled}
                 className={field.className}>
        <div id={`datetimefieldref-${field.input.name}`} style={{height: '58px', position: 'absolute', left: '0', right: '0', top: '0'}} />
        {field.label && <InputLabel>{field.label}</InputLabel>}
        <input {...field.input} type='hidden' />
        {field.disabled
            ? <Input
                fullWidth={field.fullWidth ? true : undefined}
                style={field.label ? {marginTop: '16px'} : {}}
                value={(field.input.value && Moment(field.input.value).isValid()) ? Moment(field.input.value).format('l, LT') : field.input.value}
            />
            : <DatePicker
                onChange={(value) => {
                    if (Moment.isMoment(value)) {
                        value = value.format('YYYY-MM-DDTHH:mm:ssZ');
                    }
                    return field.input.onChange(value);
                }}
                onChangeRaw={event => {
                    let value = event.target.value;
                    if (Moment(value, 'l, LT', true).isValid()) {
                        value = Moment(value).format('YYYY-MM-DDTHH:mm:ssZ');
                    }
                    return field.input.onChange(value);
                }}
                selected={field.input.value && Moment(field.input.value, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid() ? Moment(field.input.value) : null}
                showTimeSelect={field.time !== undefined ? field.time : true}
                timeFormat='HH:mm'
                dateFormat={field.time === false ? 'l' : 'l, LT'}
                timeIntervals={10}
                fixedHeight
                disabledKeyboardNavigation
                calendarContainer={props => <Popper
                    style={{zIndex: 1600}}
                    open={true}
                    anchorEl={document.getElementById(`datetimefieldref-${field.input.name}`)}
                    placement='bottom-start'
                    modifiers={{flip: {enabled: false}, preventOverflow: {enabled: false}, hide: {enabled: false}}}>
                    <div className={props.className}>
                        {props.children}
                    </div>
                    <div className='react-datepicker-popper' data-placement='bottom-start' style={{marginTop: 0}}>
                        <div className='react-datepicker__triangle' />
                    </div>
                </Popper>}
                customInput={
                    <Input fullWidth={field.fullWidth ? true : undefined}
                           style={field.label ? {marginTop: '16px'} : {}} />
                }
            />
        }
        {field.meta.touched && field.meta.error
            ? <FormHelperText>{field.meta.error}</FormHelperText>
            : field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
    </FormControl>;

/**
 * Adds missing HelperText and error handling to Checkbox
 *
 * @private
 */
const _renderCheckbox = ({input, label, classes, meta: {touched, error}, fullWidth, className, _classes, name, helperText, autoFocus, ...rest_of_props}) => {
    const ref = useRef(null);
    // implementation of autoFocus
    useEffect(() => {
        if (autoFocus && ref.current) {
            ref.current.focus();
        }
    }, [autoFocus]);

    return <FormControl fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <FormControlLabel label={label} inputRef={ref} control={
            <Checkbox name={name}
                      color='primary'
                      classes={{disabled: classes.disabledCheckbox, ..._classes}}
                      {...rest_of_props}
                      input={input}
            />
        } />
        {touched && error
            ? <FormHelperText className={classes.checkboxHelperText}>{error}</FormHelperText>
            : helperText && <FormHelperText className={classes.checkboxHelperText}>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Adds missing HelperText and error handling to Switch
 *
 * @private
 */
const _renderSwitch = ({input, label, classes, meta: {touched, error}, fullWidth, className, name, helperText, ...rest_of_props}) => {
    return <FormControl fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <FormControlLabel label={label} control={
            <Switch name={name}
                    classes={{...classes}}
                    {...rest_of_props}
                    input={input}
            />
        } />
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Adds missing HelperText and error handling to Select
 *
 * @private
 */
const _renderSelect = ({input, label, InputLabelProps = {}, classes, meta: {touched, error}, children, fullWidth, className, name, _classes, helperText, loading, hideDisabledArrow, MenuProps, open, ...rest_of_props}) => {
    return <FormControl required={rest_of_props.required} key={`${name}-${rest_of_props.disabled}`} fullWidth={fullWidth} className={className} error={Boolean(touched && error)}>
        <InputLabel htmlFor={name} {...InputLabelProps}>{label}</InputLabel>
        <Select key={open !== undefined}
                name={name}
                classes={{
                    selectMenu: `selectSelectMenu ${classes.selectListItemSupport}`,
                    disabled: `${classes.disabledSelect}${hideDisabledArrow ? ' hide-arrow' : ''}`,
                    ..._classes
                }}
                MenuProps={{...(MenuProps || {}), classes: {paper: `${classes.selectMenuPaper} ${classes.selectListItemSupport}`}}}
                open={open}
                {...rest_of_props}
                input={input}>
            {children}
        </Select>
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
        {loading && <LinearProgress className={`${classes.fieldLoader}${loading === 'show' ? ' show' : ''}`} />}
    </FormControl>;
};


/**
 * Render 'TextField' with hidden Input. Useful in combination with no-redux-form elements, e.g. DropZone
 *
 * @private
 */
const _renderErrorField = ({input, classes, meta: {touched, error}, children, fullWidth, className, name, _classes, helperText, ...rest_of_props}) => {
    return <FormControl fullWidth={fullWidth} className={`${className} errorfield`} error={Boolean(touched && error)}>
        <input {...input} type='hidden' />
        {children}
        {touched && error
            ? <FormHelperText>{error}</FormHelperText>
            : helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>;
};

/**
 * Add Icon to field, use it like this:
 * (It's a alternative way instead of start and end Adornment)
 *
 * <FieldWithIconHolder>
 *  <Field withIcon ... />
 *  <FieldIcon><Icon /></FieldIcon>
 * </FieldWithIconHolder>
 *
 * or
 *
 * <FieldWithIconHolder>
 *  <FieldIcon before><Icon /></FieldIcon>
 *  <Field withIconBefore ... />
 * </FieldWithIconHolder>
 *
 *
 * Also supports ActionButton
 *
 * <FieldWithIconHolder>
 *  <Field withIcon iconWidth={120} ... />
 *  <FieldIcon button iconWidth={120}><ActionButton ... /></FieldIcon>
 * </FieldWithIconHolder>
 */
function FieldIcon(props) {
    // Split some stuff from props
    const {_classes, className, before, button, iconWidth, ...rest_of_props} = props;
    const classes = useStyles(props);
    // Merge classes from props and our custom
    const {root, ...rest_of_classes} = _classes || {};
    const rootClasses = [
        button ? classes.fieldButton : classes.fieldIcon,
        root, className,
        before ? 'before' : '',
        rest_of_props.disabled ? 'disabled' : ''
    ].filter(Boolean).join(' ');

    return button
        ? <div className={rootClasses} {...rest_of_props} />
        : <IconButton
            classes={{
                root: rootClasses,
                ...rest_of_classes
            }}
            {...rest_of_props}
        />;
}

/**
 * Simple holder of Field with Icon to prevent wrap
 *
 * <FieldWithIconHolder>
 *  <Field withIcon ... />
 *  <FieldIcon><Icon /></FieldIcon>
 * <FieldWithIconHolder>
 */
function FieldWithIconHolder(props) {
    // split some stuff from props
    const {className, size, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    let rootClasses = [
        classes.fieldWithIconHolder,
        className,
        size === 'full' ? 'full' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} {...rest_of_props} />;
}

/**
 * To negate margin of Form to normal wrap content in this Component
 *
 * <Form onSubmit={this.props.handleSubmit}>
 *  <InFormContent>
 *     <Typography variant='body1'><FormattedMessage id='INTL_ID' /></Typography>
 *  </InFormContent>
 * </Form>
 */
const InFormContent = React.forwardRef((props, ref) => {
    // split some stuff from props
    const {className, inline, ...rest_of_props} = props;
    const classes = useStyles();
    // Merge classes from props and our custom
    const rootClasses = [
        classes.inFormContent, className,
        inline ? 'inline' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} ref={ref} {...rest_of_props} />;
});

/**
 * ReduxField with additional Permission integration which turns Field disabled
 */
const EnhancedReduxField = React.forwardRef((props, ref) => {
    // split some stuff from props
    const {excludePermission, disabled, ...rest_of_props} = props;
    // redux store
    const {permissions, permission} = useSelector(state => ({
        permissions: state.auth.get('permissions'),
        permission: state.auth.get('permission')
    }), shallowEqual);

    return <ReduxField ref={ref} {...rest_of_props} disabled={(!excludePermission && permissions.get(permission) === 'R') || disabled} />;
});

/**
 * Handles all Fields, no matter if used as switcher or in redux-form
 *
 * TextField
 *  <Field name='NAME' fieldType='TextField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * TextField (password)
 *  import Visibility from '@material-ui/icons/VisibilityOutlined';
 *  import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
 *
 *  <FieldWithIconHolder>
 *      <Field withIcon name='NAME' type={this.state.showPassword ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='INTL_ID' />} />
 *      <FieldIcon onClick={() => this.setState({showPassword: !this.state.showPassword})}>
 *          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
 *      </FieldIcon>
 *  </FieldWithIconHolder>
 *
 * URLField
 *  <Field name='NAME' fieldType='URLField' label={<FormattedMessage id='INTL_ID' />}
 *         change={this.props.change} selectedValue={this.props.selectedNAME} />
 *
 * NoReduxTextField
 *  <Field fieldType='NoReduxTextField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * DateField
 *  <Field name='NAME' fieldType='DateField' label={<FormattedMessage id='INTL_ID' />} />
 *
 * TextArea
 *  <Field name='NAME' fieldType='TextArea' label={<FormattedMessage id='INTL_ID' />} />
 *
 * Checkbox
 *  <Field name='NAME' fieldType='Checkbox' label={<FormattedMessage id='INTL_ID' />} />
 *
 * Switch
 *  <Field name='NAME' fieldType='Switch' label={<FormattedMessage id='INTL_ID' />} />
 *
 * NoReduxSwitch
 *  <Field fieldType='NoReduxSwitch' label={<FormattedMessage id='INTL_ID' />}
 *         checked={this.state.checked} onChange{() => this.setState(checked: !this.state.checked)} />
 *
 * Select
 *  import MenuItem from 'components/core/ui/mui/MenuItem';
 *
 *  <Field name='NAME' fieldType='Select' label={<FormattedMessage id='INTL_ID' />} >
 *      <MenuItem value='ITEM_VALUE'><FormattedMessage id='INTL_ID' /></MenuItem>
 *  </Field>
 *
 * NoReduxSelect
 *  import MenuItem from 'components/core/ui/mui/MenuItem';
 *
 *  <Field fieldType='NoReduxSelect' label={<FormattedMessage id='INTL_ID' />} >
 *      <MenuItem value='ITEM_VALUE'>READABLE_VALUE</MenuItem>
 *  </Field>
 *
 * ErrorField
 *  <Field name='NAME' fieldType='ErrorField' />
 */
const Field = React.forwardRef((props, ref) => {
    // Split some stuff from props
    const {_classes, className, fieldType, label, name, size, highlight, withIcon, withIconBefore, iconWidth, helperText, minRows, maxRows, loading, change, selectedValue, hideDisabledArrow, ...rest_of_props} = props;
    const classes = useStyles(props);
    // Merge classes from props and our custom
    const formControlClasses = [
        classes.formControl,
        className,
        fieldType ? fieldType.toLowerCase() : '',
        size || (fieldType === 'TextArea' ? 'double' : ''),
        withIcon ? 'icon-after' : withIconBefore ? 'icon-before' : '',
        highlight ? 'highlight' : ''
    ].filter(Boolean).join(' ');
    const [debouncedLoading] = useDebounce(loading, theme.transitions.duration.short);

    switch (fieldType) {
        case 'TextField':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={_classes}
                component={TextField}
                label={label}
                name={name}
                helperText={helperText}
                ref={ref}
                {...rest_of_props}
                inputProps={{
                    ...(rest_of_props.type === 'number' ? {min: '0', step: '1'} : {}),
                    ...(rest_of_props.inputProps || {})
                }}
            />;
        case 'URLField':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={_classes}
                component={TextField}
                label={label}
                name={name}
                type='url'
                onFocus={() => !selectedValue ? change(name, 'https://') : {}}
                onBlur={(e) => { if (selectedValue === 'https://') { change(name, ''); e.preventDefault(); } }}
                helperText={helperText}
                ref={ref}
                {...rest_of_props} />;
        case 'NoReduxTextField':
            return <MUITextField fullWidth
                                 className={formControlClasses}
                                 classes={_classes}
                                 label={label}
                                 helperText={helperText}
                                 ref={ref}
                                 {...rest_of_props} />;
        case 'DateField':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={_classes}
                component={_renderDateTimeField}
                label={label}
                name={name}
                helperText={helperText}
                ref={ref}
                {...rest_of_props} />;
        case 'TextArea':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={_classes}
                component={TextField}
                label={label}
                name={name}
                multiline minRows={minRows || 2} maxRows={maxRows !== undefined ? maxRows : 15}
                helperText={helperText}
                ref={ref}
                {...rest_of_props} />;
        case 'Checkbox':
            return <EnhancedReduxField
                fullWidth
                className={`${formControlClasses}${size ? '' : ' dynamic'}`}
                classes={classes}
                _classes={_classes}
                label={label}
                component={_renderCheckbox}
                name={name}
                helperText={helperText}
                ref={ref}
                normalize={(value) => !!value}
                {...rest_of_props} />;
        case 'Switch':
            return <EnhancedReduxField
                fullWidth
                className={`${formControlClasses}${size ? '' : ' dynamic'}`}
                classes={_classes}
                label={label}
                component={_renderSwitch}
                name={name}
                helperText={helperText}
                ref={ref}
                normalize={(value) => !!value}
                {...rest_of_props} />;
        case 'NoReduxSwitch':
            return <FormControl fullWidth ref={ref}
                                className={`${formControlClasses}${size ? '' : ' dynamic'}`}>
                <FormControlLabel label={label} control={
                    <MUISwitch classes={_classes}
                               {...rest_of_props} />
                } />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>;
        case 'Select':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={classes}
                _classes={_classes}
                label={label}
                component={_renderSelect}
                name={name}
                helperText={helperText}
                ref={ref}
                loading={(loading || debouncedLoading)
                    ? loading ? 'show' : true : false}
                hideDisabledArrow={hideDisabledArrow}
                {...rest_of_props}
                MenuProps={{
                    ...(rest_of_props.multiple ? {getContentAnchorEl: null} : {}),
                    ...(rest_of_props.MenuProps || {})
                }} />;
        case 'NoReduxSelect':
            return <FormControl key={`no-redux-select-${rest_of_props.disabled}`} fullWidth className={formControlClasses} error={props.error} ref={ref}>
                <InputLabel>{label}</InputLabel>
                <MUISelect classes={{
                    selectMenu: `selectSelectMenu ${classes.selectListItemSupport}`,
                    disabled: `${classes.disabledSelect}${hideDisabledArrow ? ' hide-arrow' : ''}`,
                    ..._classes
                }}
                           {...rest_of_props}
                           MenuProps={{
                               ...(rest_of_props.multiple ? {getContentAnchorEl: null} : {}),
                               ...(rest_of_props.MenuProps || {}),
                               classes: {paper: `${classes.selectMenuPaper} ${classes.selectListItemSupport}`}
                           }} />
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
                {(loading || debouncedLoading) && <LinearProgress className={`${classes.fieldLoader}${loading ? ' show' : ''}`} />}
            </FormControl>;
        case 'ErrorField':
            return <EnhancedReduxField
                fullWidth
                className={formControlClasses}
                classes={_classes}
                component={_renderErrorField}
                name={name}
                helperText={helperText}
                ref={ref}
                {...rest_of_props} />;
        default:
            console.error(`Not defined Field.js type: ${fieldType}`);
            return <div />;
    }
});

export default Field;
export {EnhancedReduxField, FieldIcon, FieldWithIconHolder, InFormContent, ColorField, CountryField, LanguageField,
    MarkdownField, SearchField, DateSearchField, JSONField, SalutationField, CompanyField, CompanyTypeField,
    CompanyStatusField, CompanySourcesField, CompanyPartnerLevelField, DepartmentField, DynamicPageSlug, ClaimStatusField,
    SubmissionStatusField, TimeField, ChoicesField, IconButtonSelect, CertificateField, MACSField, HandsetsField,
    AutoCompleteField, SubmissionProductVariantField, SalesClustersField, DropFile, SettingFieldType,
    TicketTypeField, TicketStatusField,  SettingField, validateSettingsField,
    getSettingInitialValue, getSettingsInitialValues, DMUpgradeVersionField};
