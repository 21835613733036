import React, {useState, useMemo, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Company, Paginator} from 'lib/models';
import {Map as ImmutableMap} from 'immutable';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
// Components
import {AutoCompleteField, FieldIcon} from 'components/core/ui/Field';
// icons
import ClearIcon from '@material-ui/icons/CloseOutlined';
import LinkToIcon from '@material-ui/icons/ChevronRightOutlined';


const useStyles = makeStyles(theme => ({
    // <Input />
    input: {
        // remove dotted border disabled style
        '&:before': {
            borderBottomStyle: 'solid !important'
        },
        // placeholder 'All Companies' or empty
        '& input::placeholder': {
            // match style with regular value in input
            ...theme.typography.body1,
            opacity: '1',
            color: theme.palette.text['secondary'],
            letterSpacing: 'normal',
            lineHeight: theme.typography.body2.lineHeight
        }
    }
}));

/**
 * AutoCompleteField for Company
 *
 * Example filter implementation:
 *  <CompanyField
 *      value={postponedFilters.company || filters.company || ''}
 *      change={value => filterItems('company', value)}
 *  />
 */
export default function CompanyField(props) {
    const {value, change, loading: externalLoading = false, error = false, my_companies = false, all = true, fetch_company = true, ...rest_of_props} = props;
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    // local state
    const [localLoading, setLoading] = useState(!!value);
    const loading = externalLoading || localLoading;
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [postponedSearch, setPostponedSearch] = useState(null);
    const [suggestionIDs, setSuggestionIDs] = useState([]);
    // redux store
    const dispatch = useDispatch();
    const {state, companies, company, selectedCompany, my_companies_loaded, my_companies_paginator} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const companies = state.shared.getIn(['items', my_companies ? 'my-companies' : 'companies']);
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));

        return {
            state: state.app.get('state'),
            my_companies_loaded: state.shared.getIn(['loaded', 'my-companies']),
            my_companies_paginator: state.shared.getIn(['paginator', 'my-companies']) || new Paginator(),
            companies: companies,
            company: company,
            selectedCompany: value
                ? value === company.get(new Company().getUniqueIdentifier())
                    ? company
                    : companies.find(company => company.get(new Company().getUniqueIdentifier()) === value)
                : undefined
        };
    }, shallowEqual);

    // create simple map with name and id from my_companies
    const initialItems = useMemo(() => my_companies ? companies.map(el =>
        ImmutableMap({name: el.get('name'), value: el.get(new Company().getUniqueIdentifier())})
    ) : undefined, [companies, my_companies]);
    // suggested items, transformed to ImmutableList filled with ImmutableMaps containing name & value
    const items = useMemo(() => companies.map(el => {
        const id = el.get(new Company().getUniqueIdentifier());
        const my = company.get(new Company().getUniqueIdentifier()) === id;
        if (suggestionIDs.includes(id) && (!my || (my && all))) {
            return ImmutableMap({
                name: my
                    ? intl.formatMessage({id: 'companyfield.choices.my'})
                    : el.get('name'),
                value: id
            });
        } else {
            return null;
        }
    }).filter(el => el), [companies, suggestionIDs]);

    /**
     * Fetches Selected Company if needed
     */
    useEffect(() => {
        if (fetch_company && value && !selectedCompany) {
            setLoading(true);
            dispatch(fetchItem(Company, my_companies ? 'my-companies' : 'companies', ['companies', value],
                {affect_state: false, ignore_403: !!my_companies})).then(() => {
                setLoading(false);
            });
        } else if (loading === true) {
            setLoading(false);
        }
    }, [value]);

    /**
     * Watch postponed search to actually trigger fetching
     */
    useEffect(() => {
        if (postponedSearch !== null && !loading) {
            search(postponedSearch);
            setPostponedSearch(null);
        }
    }, [postponedSearch, loading]);

    /**
     * Fetches Companies in order to get items for AutoCompleteField suggestions
     *
     * @param value - search string
     */
    const search = (value) => {
        // postpone value if we are still fetching previous search
        if (loading) {
            setPostponedSearch(value);
        } else {
            if (value) {
                // fetch search results
                setLoading(true);
                dispatch(fetchItems(Company, my_companies ? 'my-companies' : 'companies', my_companies ? company.getIn(['links', 'child-companies']) : 'companies',
                    null, {search: encodeURIComponent(value)}, {affect_state: false, update: true})
                ).then((result) => {
                    setSuggestionIDs((result.data || []).map(raw_company => raw_company[new Company().getUniqueIdentifier()]));
                    setLoading(false);
                });
            } else {
                // reset
                change(value);
            }
        }
    };

    /**
     * Clears value in order to display field itself
     *
     * @param setSearchValue - function to clear input (SearchField) value
     */
    const clearValue = (setSearchValue) => {
        setSearchValue('');
        change('');
    };

    /**
     * During first focus fetch initial items
     */
    const onFocus = () => {
        // only for my-companies
        if (my_companies && !my_companies_loaded && !loading) {
            // fetch first page of my-companies for initial items
            setLoading(true);
            dispatch(fetchItems(Company, 'my-companies', company.getIn(['links', 'child-companies']),
                null, null, {affect_state: false, paginate: true, paginator_page: 1})
            ).then(() => {
                setLoading(false);
            });
        }
    };

    /**
     * Watch scroll of suggestions list to fetch additional data
     */
    const onScroll = (e, searchValue) => {
        // once we reach bottom and we have page to load
        if (my_companies && !loading && !paginationLoading && !searchValue && my_companies_paginator.get('next') &&
            (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight)) {
            // fetch another page for initialItems
            setPaginationLoading(true);
            dispatch(fetchItems(Company, 'my-companies', my_companies_paginator.get('next'),
                null, null, {affect_state: false, paginate: true, paginator_page: (my_companies_paginator.get('page') + 1)})
            ).then(() => {
                setPaginationLoading(false);
            });
        }
    };

    return <AutoCompleteField
        label={<FormattedMessage id='companyfield.label' />}
        items={items}
        initialItems={initialItems}
        loading={loading}
        suggestionLoading={paginationLoading}
        change={change}
        placeholder={intl.formatMessage({id: `companyfield.choices.${all ? 'all' : 'my'}`})}
        error={error || (!!value && !selectedCompany && !loading)}
        InputLabelProps={{shrink: true}}
        InputProps={!value ? {className: classes.input} : {
            className: classes.input, disabled: true,
            value: selectedCompany
                ? company.get(new Company().getUniqueIdentifier()) === selectedCompany.get(new Company().getUniqueIdentifier())
                    ? intl.formatMessage({id: 'companyfield.choices.my'})
                    : selectedCompany.get('name')
                : loading
                    ? ' ' // selected company is fetching
                    : intl.formatMessage({id: 'companyfield.choices.invalid'})
        }}
        searchIcon={!value && all} withIcon={true}
        iconWidth={value && selectedCompany ? 96 : undefined}
        postChildren={(searchValue, setSearchValue, loader) => (!value && all) ? undefined : <React.Fragment>
            {(value || !all) && <FieldIcon
                disabled={loader || state !== null}
                onClick={() => history.push(value === company.get(new Company().getUniqueIdentifier())
                    ? '/company' : `/${my_companies ? 'my-' : ''}companies/${value}`)}>
                <LinkToIcon />
            </FieldIcon>}
            {(!rest_of_props.disabled && value) && <FieldIcon
                disabled={loader} onClick={() => clearValue(setSearchValue)}>
                <ClearIcon />
            </FieldIcon>}
        </React.Fragment>}
        search={search}
        onFocus={onFocus}
        onScroll={onScroll}
        {...rest_of_props}
    />;
}
