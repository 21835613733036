import React from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
// Actions
import {toggleMenu} from 'actions/app';
// material-ui
import Button from 'components/core/ui/mui/Button';


const useStyles = makeStyles(theme => ({
    // switch to Portal / SRAPS bellow menu
    platformSwitcherHolder: {
        // center link
        textAlign: 'center',
        // button is bellow menu
        '&.inmenu': {
            // align with menu
            marginLeft: `${theme.spacing(2)}px`
        }
    },
    platformSwitcher: {
        // get rid of disabled style
        color: `${theme.palette.text['primary']} !important`
    },
    platformSwitcherLabel: {
        fontWeight: theme.typography.fontWeightMedium
    }
}));

/**
 * Buttons serving as switch between SRAPS / Partner Portal
 */
export default function PlatformSwitcher(props) {
    // Split some stuff from props
    const {className, inMenu, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        classes.platformSwitcherHolder, className || '',
        inMenu ? 'inmenu' : ''
    ].filter(Boolean).join(' ');
    // router
    const history = useHistory();
    const {pathname} = useLocation();
    const portal = pathname.startsWith('/partner');
    // redux store
    const dispatch = useDispatch();
    const {state, authState, openMobileMenu} = useSelector(state => ({
        state: state.app.get('state'),
        authState: state.auth.get('state'),
        openMobileMenu: state.app.get('openMobileMenu')
    }), shallowEqual);

    return <div className={rootClasses}>
        <Button _classes={{root: classes.platformSwitcher, label: classes.platformSwitcherLabel}}
                disabled={state !== null || ![null, 'logged'].includes(authState)}
                onClick={() => {
                    history.push(`${portal ? '' : '/partner'}${authState === 'logged' ? '/home' : ''}`);
                    openMobileMenu && dispatch(toggleMenu(false));
                }}
                {...rest_of_props}>
            <FormattedMessage id={`menu.switch.${portal ? 'sraps' : 'portal'}`} />
        </Button>
    </div>;
}
