import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {Company} from 'lib/models';
// Actions
import {setState, addMessage} from 'actions/app';
import {simplePost} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import AutoCompleteOff from 'components/core/ui/AutoCompleteOff';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Button from 'components/core/ui/mui/Button';
// icons
import InviteIcon from '@material-ui/icons/PersonAddOutlined';
import SubmitIcon from '@material-ui/icons/SendOutlined';


/**
 * Card with form to invite User to join your Company
 * props.item_company is provided via parent in companies/Detail.js
 */
function Invite(props) {
    return <Card>
        <CardHeader
            title={<FormattedMessage id='companies.detail.invite.title' />}
            subheader={<FormattedMessage id='companies.detail.invite.subheader'
                                         values={{company_name: props.item_company && <strong>{props.item_company.get('name')}</strong>}} />}
            action={<ActionButton iconButton disabled>
                <InviteIcon />
            </ActionButton>}
        />
        <CardContent>
            <Form onSubmit={props.handleSubmit} center>
                <AutoCompleteOff />
                <Field name='email' type='email' fieldType='TextField' label={`${props.intl.formatMessage({id: 'companies.detail.invite.form.fields.email'})}*`} />
            </Form>
            <SpaceDivider />
        </CardContent>
        {['posting_invite', 'posted_invite', 'failed_invite'].includes(props.state)
            ? <CardActionsLoader success={props.state === 'posted_invite'}
                                 failure={props.state === 'failed_invite'}
                                 postAnimation={success => {
                                     props.setState(null);
                                     if (success) {
                                         // success message
                                         props.addMessage({intl_id: 'companies.detail.invite.success', path: 'on-change'});
                                         // clear form
                                         props.reduxFormReset('companyInviteUserForm');
                                     }
                                 }} />
            : <ThemeProvider alt>
            <CardActions>
                <Button disabled={props.state !== null || !props.item_company || !props.item_company.get(new Company().getUniqueIdentifier())}
                        variant='contained' color='primary' type='submit'
                        onClick={() => props.handleSubmit()}>
                    <SubmitIcon />
                    <FormattedMessage id='companies.detail.invite.form.fields.submit' />
                </Button>
            </CardActions>
        </ThemeProvider>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'email', data.email) &&
    validator.isEmail(null, errors, 'email', data.email);

    return errors;
};

const InviteForm = reduxForm({
    form: 'companyInviteUserForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let {password2, ...rest_of_data} = JSON.parse(JSON.stringify(props.formValues));

        return dispatch(simplePost('invite', props.item_company.getIn(['links', 'invitations']), rest_of_data));
    }
})(Invite);

const ConnectedInvite = connect((state, props) => {
    const portal = props.location.pathname.startsWith('/partner');

    return {
        state: state.app.get('state'),
        initialValues: {
            service: portal ? 'partner_portal' : 'sraps'
        },
        formValues: getFormValues('companyInviteUserForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    simplePost,
    addMessage,
    reduxFormReset
}, dispatch))(InviteForm);

export default injectIntl(withRouter(ConnectedInvite));
