import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
// components
import Field from 'components/core/ui/Field';
import Tabs from 'components/core/ui/Tabs';
import Tab from 'components/core/ui/mui/Tab';


const useStyles = makeStyles(theme => ({
    root: {
        // match field size
        width: '320px',
        margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`
    },
    tab: {
        // two tabs next to each other
        width: '50%',
        minWidth: '0'
    }
}));

/**
 * Render version field with switching input(url|version)
 * @param {object} props - component props
 * @param {(name: string, value: any) => void} props.change - redux-form change function, required for reset switched field
 */
export default function DMUpgradeVersionField(props) {
    const {change} = props;
    const classes = useStyles();

    return <div className={classes.root}>
        <Tabs
            tabsProps={{scrollButtons: 'off'}} initialSelectedTab={0} destroyNotSelected
            onChange={(tab) => change?.(tab === 0 ? 'params.url' : 'params.version', null)}
            tabs={['version', 'url'].map((tname, idx) => <Tab
                key={idx} label={<FormattedMessage id={`devicemanagement.type.upgrade.confirm.form.${tname}`} />}
                className={classes.tab}
            />)}
            content={['version', 'url'].map((fname, idx) => <Field
                key={idx} fieldType='TextField' name={`params.${fname}`}
                size='full' className='nomargin'
                label={<FormattedMessage id={`devicemanagement.type.upgrade.confirm.form.${fname}`} />} required
            />)}
        />
    </div>;
}
