import React from 'react';


/**
 * Not Found Icon
 */
export default function Icon(props) {
    const {viewBox = '0 0 280 280', color = '#4A5D68', ...rest_of_props} = props;

    return <svg viewBox={viewBox} {...rest_of_props}>
        <path fillRule='evenodd' clipRule='evenodd' d='M203.637 107.724C203.637 160.388 160.944 203.081 108.279 203.081C55.6149 203.081 12.9219 160.388 12.9219 107.724C12.9219 55.0592 55.6149 12.3662 108.279 12.3662C160.944 12.3662 203.637 55.0592 203.637 107.724ZM179.328 188.209C160.398 204.932 135.524 215.081 108.279 215.081C48.9874 215.081 0.921875 167.016 0.921875 107.724C0.921875 48.4318 48.9874 0.366211 108.279 0.366211C167.571 0.366211 215.637 48.4318 215.637 107.724C215.637 135.453 205.124 160.727 187.867 179.777L278.135 270.045C280.478 272.388 280.478 276.187 278.135 278.531C275.792 280.874 271.993 280.874 269.65 278.531L179.328 188.209Z' fill={color}/>
        <path d='M63.9126 132.33C63.9126 134.486 64.0446 135.278 64.5726 136.334C65.1886 137.522 66.4646 138.446 68.4006 138.446C70.3366 138.446 71.6126 137.522 72.2286 136.334C72.7566 135.278 72.8886 134.486 72.8886 132.33V128.458H75.8806C77.9046 128.458 78.7846 128.37 79.7526 127.842C80.8966 127.182 81.6886 125.906 81.6886 124.234C81.6886 122.562 80.8966 121.286 79.7526 120.626C78.7846 120.098 77.9046 120.01 75.8806 120.01H72.8886V110.858C72.8886 108.702 72.7566 107.91 72.2286 106.854C71.6126 105.666 70.3366 104.742 68.4006 104.742C66.4646 104.742 65.1886 105.666 64.5726 106.854C64.0446 107.91 63.9126 108.702 63.9126 110.858V120.01H49.7886L68.4446 82.6102C69.3246 80.8502 69.5886 80.0582 69.5886 79.0462C69.5886 76.8022 67.4766 75.0862 65.3646 75.0862C64.5726 75.0862 63.8686 75.3062 63.2526 75.7462C62.4166 76.3622 61.8886 77.0222 60.9646 78.8702L40.3286 120.23C39.5806 121.726 39.1406 122.914 39.1406 123.97C39.1406 125.29 39.7126 126.566 40.7246 127.314C41.8686 128.238 43.1886 128.458 45.3446 128.458H63.9126V132.33Z' fill={color}/>
        <path d='M125.575 120.67V92.8622C125.575 81.6862 117.479 74.9102 107.447 74.9102C97.5025 74.9102 89.4065 81.6862 89.4065 92.8622V120.67C89.4065 131.846 97.5025 138.622 107.447 138.622C117.479 138.622 125.575 131.846 125.575 120.67ZM116.599 120.318C116.599 126.302 113.167 130.526 107.447 130.526C101.815 130.526 98.3825 126.302 98.3825 120.318V93.2142C98.3825 87.2302 101.815 83.0062 107.447 83.0062C113.167 83.0062 116.599 87.2302 116.599 93.2142V120.318Z' fill={color}/>
        <path d='M157.756 132.33C157.756 134.486 157.888 135.278 158.416 136.334C159.032 137.522 160.308 138.446 162.244 138.446C164.18 138.446 165.456 137.522 166.072 136.334C166.6 135.278 166.732 134.486 166.732 132.33V128.458H169.724C171.748 128.458 172.628 128.37 173.596 127.842C174.74 127.182 175.532 125.906 175.532 124.234C175.532 122.562 174.74 121.286 173.596 120.626C172.628 120.098 171.748 120.01 169.724 120.01H166.732V110.858C166.732 108.702 166.6 107.91 166.072 106.854C165.456 105.666 164.18 104.742 162.244 104.742C160.308 104.742 159.032 105.666 158.416 106.854C157.888 107.91 157.756 108.702 157.756 110.858V120.01H143.632L162.288 82.6102C163.168 80.8502 163.432 80.0582 163.432 79.0462C163.432 76.8022 161.32 75.0862 159.208 75.0862C158.416 75.0862 157.712 75.3062 157.096 75.7462C156.26 76.3622 155.732 77.0222 154.808 78.8702L134.172 120.23C133.424 121.726 132.984 122.914 132.984 123.97C132.984 125.29 133.556 126.566 134.568 127.314C135.712 128.238 137.032 128.458 139.188 128.458H157.756V132.33Z' fill={color}/>
    </svg>;
}

