import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormattedMessage} from 'react-intl';
import {useLocation, useHistory} from 'react-router';
import {theme} from 'theme';
// Components
import Form from 'components/core/ui/Form';
// material-ui
import Tooltip from 'components/core/ui/mui/Tooltip';
import CardContent from 'components/core/ui/mui/CardContent';
import Button from 'components/core/ui/mui/Button';
import IconButton from '@material-ui/core/IconButton';
// icons
import ClearFiltersIcon from '@material-ui/icons/LayersClearOutlined';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';


const useStyles = makeStyles(theme => ({
    // root
    cardContent: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px `,
        position: 'relative',
        // style
        background: theme.palette.snomGrey[200],
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        // form and button inside
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '0',
        // left indicator border
        '&.border:before': {
            // position
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            display: 'block',
            content: '""',
            // size
            width: `${theme.spacing(0.5)}px`,
            // MuiInput bottomLineColor (underline)
            background: 'rgba(0, 0, 0, 0.42)',
            transition: theme.transitions.create('background', {
                duration: theme.transitions.duration.short
            })
        },
        '&.active': {
            '&:before': {
                background: theme.palette.primary['main']
            }
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    // form holding filters fields
    filtersForm: {
        marginBottom: '0'
    },
    // refresh & reset buttons
    controls: {
        flexShrink: '0',
        margin: `${theme.spacing(1)}px 0 0 auto`,
        // buttons to each other
        display: 'flex',
        alignItems: 'center',
        '&.down': {
            alignSelf: 'flex-end'
        }
    },
    refreshButton: {
        transform: 'rotate(0deg)',
        '&.clicked': {
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.complex
            }),
            transform: 'rotate(360deg)'
        }
    }
}));

/**
 * Styled <CardContent /> to be used as Filters section above List tables
 */
export default function Filters(props) {
    const {className, disabled, children, controls, refresh, refreshDisabled = false, controlsDown = false,
        supportedFilters, clear, ...rest_of_props} = props;
    // local state
    const [refreshClicked, setRefreshClicked] = useState(false);
    // router
    const history = useHistory();
    const {search, hash} = useLocation();
    const searchParams = new URLSearchParams(search);
    // styling
    const classes = useStyles();
    const active = supportedFilters
        ? !!supportedFilters.map(filter => searchParams.get(filter)).filter(el => el).length
        : !!search;
    const cardContentClasses = [
        className,
        children ? 'border' : '',
        active ? 'active' : '',
        classes.cardContent
    ].filter(Boolean).join(' ');

    return <CardContent className={cardContentClasses}
                        {...rest_of_props}>
        {children && <Form className={classes.filtersForm}>
            {children}
        </Form>}
        <div className={`${classes.controls}${controlsDown ? ' down' : ''}`}>
            {children && <Button variant='outlined' disabled={!active || disabled}
                    onClick={() => clear ? clear() : history.push({hash: hash, search: ''})}>
                <ClearFiltersIcon /><FormattedMessage id='filters.clear' />
            </Button>}
            {refresh && <Tooltip title={<FormattedMessage id='actions.reload' />} disabled={refreshDisabled}>
                <IconButton disabled={refreshDisabled}
                            className={`${classes.refreshButton}${refreshClicked ? ' clicked' : ''}`}
                            onClick={() => {
                                if (!refreshClicked) {
                                    setRefreshClicked(true);
                                    setTimeout(() => setRefreshClicked(false),
                                        theme.transitions.duration.complex);
                                }
                                refresh();
                            }}>
                    <RefreshIcon />
                </IconButton>
            </Tooltip>}
        </div>
    </CardContent>;
}
