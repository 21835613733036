import React, {useState} from 'react';
// components
import AssetManagerLayout from 'components/modules/assets/AssetManagerLayout';
import AssetFoldersMenu from 'components/modules/assets/FolderMenu';
import AssetManager from 'components/modules/assets/AssetManager';


/**
 * Renders Asset Manager for customers from certain folder without managing options
 */
export default function CustomerAssetManager(props) {
    const {folder, variant} = props;

    const [path, setPath] = useState(folder);
    const [viewType, setViewType] = useState('grid');

    return <AssetManagerLayout disableToolbar={variant === 'flat'} rootFolder={folder} folderPath={path} viewType={viewType} onChangeView={setViewType}
        onClickBreadcrumbButton={setPath} onClickHome={() => setPath(folder)} foldersMenu={variant !== 'flat' && <AssetFoldersMenu rootFolder={folder} parentPath={folder} value={path} onChange={setPath} />}>
        <AssetManager viewType={variant === 'flat' ? 'grid' : viewType} disableFetchFolders={false} flat={variant === 'flat'} disablePlaceholder folderPath={path} onClickFolder={setPath} />
    </AssetManagerLayout>;
}
