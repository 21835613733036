import React, {useCallback, useEffect, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {useLocalSort} from 'lib/filters';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {PaymentMethod} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {fetchItems, simplePost} from 'actions/shared';
// components
import CreditCard from 'components/modules/paymentmethods/ListItem';
// material ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';
// vectors
import AddIcon from '@material-ui/icons/AddOutlined';
import ModelIcon from 'components/core/vectors/ModelIcon';


const useStyles = makeStyles(theme => ({
    // grid cardContent
    cardsHolder: {
        // bellow each other
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        // space between
        gap: `${theme.spacing(2)}px`
    }
}));

/**
 * View and manage stripe payment methods (credit cards)
 */
export default function List(passed_props) {
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const items_placement = useMemo(() => new PaymentMethod().getPlacement(), []);
    const uuid = useMemo(() => new PaymentMethod().getUniqueIdentifier(), []);
    const props = useSelector(state => ({
        state: state.app.get('state'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        loaded: state.shared.getIn(['loaded', items_placement]),
        items: state.shared.getIn(['items', items_placement])
    }), shallowEqual);
    // sorting
    const [sortedItems,,,, sortInitializing] = useLocalSort(props.items, !props.loaded, null, ['created'], 'created', 'asc');

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.loaded === false) {
            dispatch(fetchItems(PaymentMethod, items_placement, props.company.getIn(['links', 'payment-methods'])));
        }
    }, [props.loaded]);

    /**
     * Request new payment-session from backend and redirect user to stripe gateway
     */
    const handleAdd = useCallback(() => {
        dispatch(simplePost('payment-methods', props.company.getIn(['links', 'payment-methods']), {
            success_url: window.location.href,
            cancel_url: window.location.href
        })).then(result => {
            // redirect user to stripe gateway
            if (result?.data?.setup_url) {
                window.location.href = result.data.setup_url;
            }
        }).finally(() => {
            dispatch(setState(null));
        });
    }, []);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='paymentmethods.list.title' />}
            subheader={<FormattedMessage id='paymentmethods.list.subheader' />}
            action={passed_props.permission === 'RW'
                ? <ActionButton
                    variant='contained' color='secondary' disabled={props.state !== null}
                    loading={['posting_payment-methods', 'posted_payment-methods', 'failed_payment-methods'].includes(props.state)}
                    onClick={() => handleAdd()}>
                    <AddIcon />
                    <FormattedMessage id='paymentmethods.list.add' />
                </ActionButton>
                : <ActionButton iconButton disabled>
                    <ModelIcon model='payment_methods' />
                </ActionButton>
            }
        />
        <CardContent className={classes.cardsHolder}>
            {props.state === `fetching_items_${items_placement}` || sortInitializing
                ? (props.items.size ? props.items : ['empty']).map((e, idx) => <CreditCard key={idx}><LinearProgress /></CreditCard>)
                : !props.items.size ? <CreditCard><FormattedMessage id='paymentmethods.list.empty' /></CreditCard>
                : props.loaded && sortedItems.sort((a, b) => b.get('default') - a.get('default')).map(card => <CreditCard
                    {...passed_props} key={card.get(uuid)} card={card}
                />)}
        </CardContent>
    </Card>;
}
