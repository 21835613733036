import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router';
import {List as ImmutableList} from 'immutable';
import {useEffect, useMemo, useState} from 'react';
import Moment from 'moment';
// Actions
import {fetchItem, fetchItems} from 'actions/shared';
import {setState} from 'actions/app';
import {Paginator, PhoneLinkMigrationReport, PhoneLinkSource} from 'lib/models';
import {usePaginatedItems} from 'lib/filters';
// Components
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import Button from 'components/core/ui/mui/Button';
import MigrationDialog from 'components/modules/phonelink/MigrationDialog';
import StickyTable from 'components/core/ui/StickyTable';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import Pagination from 'components/core/ui/Pagination';
// MUI
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// Icons
import AddIcon from '@material-ui/icons/AddOutlined';
import Dialog from '@material-ui/core/Dialog';
import PhoneLinkMigrationErrors from './PhoneLinkMigrationErrors';


const useStyles = makeStyles({
    dialog: {
        maxWidth: '66%'
    }
});

// Render details of a specific PhoneLink source, including its migration reports.
function Detail(props) {
    const [openedPhoneLinkMigrationErrorID, setOpenedPhoneLinkMigrationErrorID] = useState(null);

    // Fetch PhoneLink source on load.
    useEffect(() => {
        if (!props.item) {
            props.fetchItem(
                PhoneLinkSource,
                props.phonelink_source_placement,
                [
                    props.company.getIn(['links', 'phonelink']),
                    props.identifier
                ]
            );
        }

        if ([`fetching_item_${props.phonelink_source_placement}`].includes(props.state) && props.item) {
            props.setState(null);
        }
    }, [props.item]);

    // Fetch PhoneLink migration reports data when loading a new PhoneLink source.
    useEffect(() => {
        if (props.item) {
            props.fetchItems(
                PhoneLinkMigrationReport,
                props.phonelink_migration_report_placement,
                props.item.getIn(['links', 'migration-reports']),
                null, null,
                {paginate: true, paginator_page: 1, affect_state: false}
            );
        }
    }, [props.item]);


    // pagination
    const [paginatedItems] = usePaginatedItems(
        props.phonelink_migration_reports,
        props.phonelink_migration_reports_paginator,
        props.phonelink_migration_reports_page_references,
        new PhoneLinkMigrationReport().getUniqueIdentifier(),
    );


    // Local State
    const [phoneLinkMigrationDialogOpen, setPhoneLinkMigrationDialogOpen] = useState(false);

    // Sorting
    const [sortName, sortDirection] = [false, 'asc'];
    const tableHead = useMemo(() => [
        {sortName: 'timestamp', label: <FormattedMessage id="phonelink.detail.table.timestamp"/>},
        {sortName: 'found', label: <FormattedMessage id="phonelink.detail.table.found"/>},
        {sortName: 'migrated', label: <FormattedMessage id="phonelink.detail.table.migrated"/>},
        {sortName: 'already_exists', label: <FormattedMessage id="phonelink.detail.table.already_exists"/>},
        {sortName: 'failed', label: <FormattedMessage id="phonelink.detail.table.failed"/>}
    ], []);

    // Style
    const classes = useStyles();

    return (
        [`fetching_item_${props.phonelink_source_placement}`].includes(props.state) ?
            <LinearProgress/> :
            <Card>
                <CardHeader
                    title={<Typography variant="h5"><FormattedMessage
                        id="phonelink.detail.table.title"/> - {props.item && props.item.get('name')}
                    </Typography>}
                    subheader={<FormattedMessage id="phonelink.detail.table.subheader"/>}
                    action={<>
                        {
                            phoneLinkMigrationDialogOpen &&
                            <MigrationDialog
                                open={phoneLinkMigrationDialogOpen}
                                classes={{paper: classes.dialog}}
                                phonelink_source={props.item}
                                handleClose={() => setPhoneLinkMigrationDialogOpen(false)}/>
                        }
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={props.state === `posting_${props.phonelink_source_placement}`}
                            onClick={() => setPhoneLinkMigrationDialogOpen(true)}>
                            <AddIcon/>
                            <FormattedMessage id="phonelink.detail.table.start_migration"/>
                        </Button>
                    </>}
                />
                <CardContent>
                    <StickyTable
                        header={<Table size={tableHead.length}>
                            <TableHead>
                                <TableRow>
                                    {tableHead.map((item, idx) =>
                                        <TableCell key={idx}>
                                            {sortName === item.sortName
                                                ? <TableSortLabel active direction={sortDirection}
                                                                  disabled={true}>
                                                    {item.label}
                                                </TableSortLabel>
                                                : item.label
                                            }
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                        </Table>}
                        content={<Table size={tableHead.length}>
                            <TableBody>
                                {props.phonelink_migration_reports_loaded && paginatedItems.size ? paginatedItems.map((report, idx) => {
                                        return <TableRow key={idx} hover>
                                            <TableCell>
                                                {Moment(report.created_at).format('l, LT')}
                                            </TableCell>
                                            <TableCell>{report.found}</TableCell>
                                            <TableCell>{report.migrated}</TableCell>
                                            <TableCell>{report.already_exists}</TableCell>
                                            <TableCell>
                                                {<>
                                                    <Dialog open={openedPhoneLinkMigrationErrorID === report.uuid}
                                                            classes={{paper: classes.dialog}}
                                                            onClose={() => setOpenedPhoneLinkMigrationErrorID(null)}>
                                                        {openedPhoneLinkMigrationErrorID === report.uuid && <PhoneLinkMigrationErrors
                                                            errors={report?.errors}
                                                            handleClose={() => setOpenedPhoneLinkMigrationErrorID(null)}
                                                        />}
                                                    </Dialog>
                                                    <Typography component='span'
                                                                style={report.errors?.size ? {textDecoration: 'underline', cursor: 'pointer', color: '#009EE2'} : null}
                                                                onClick={() => setOpenedPhoneLinkMigrationErrorID(report.uuid)}>
                                                        {report.failed}
                                                    </Typography>
                                                </>}
                                            </TableCell>
                                        </TableRow>;
                                    })
                                    :
                                    <TableRow>
                                        <TableCell>
                                            <FormattedMessage id="phonelink.detail.table.empty"/>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>}
                        footer={<Table size={1}>
                            <Pagination model={PhoneLinkMigrationReport} placement={props.phonelink_migration_report_placement}
                                        paginationLoading={true}/>
                        </Table>}/>
                </CardContent>
            </Card>
    );
}

const ConnectedDetail = connect((state, props) => {
    const phonelink_source_placement = new PhoneLinkSource().getPlacement();
    const phonelink_migration_report_placement = new PhoneLinkMigrationReport().getPlacement();
    const identifier = props.match.params.identifier;
    const items = state.shared.getIn(['items', phonelink_source_placement]) || ImmutableList();
    const item = items.find(el => el.get(new PhoneLinkSource().getUniqueIdentifier()) === identifier);

    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

    return {
        item,
        phonelink_source_placement,
        phonelink_migration_report_placement,
        phonelink_migration_reports: state.shared.getIn(['items', phonelink_migration_report_placement]),
        phonelink_migration_reports_loaded: !!state.shared.getIn(['loaded', phonelink_migration_report_placement]),
        phonelink_migration_reports_page_references: state.shared.getIn(['page_references', phonelink_migration_report_placement]) || ImmutableList(),
        phonelink_migration_reports_paginator: state.shared.getIn(['paginator', phonelink_migration_report_placement]) || new Paginator(),
        identifier,
        company,
        state: state.app.get('state')
    };
}, dispatch => bindActionCreators({
    fetchItem,
    fetchItems,
    setState
}, dispatch))(Detail);

export default injectIntl(withRouter(ConnectedDetail));
