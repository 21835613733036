import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory, useLocation} from 'react-router';
import {useLocalFilter, useLocalSort} from 'lib/filters';
import {ProductDefaults, Company, Product, Firmware} from 'lib/models';
import {List as ImmutableList, Map as ImmutableMap} from 'immutable';
// Actions
import {setState} from 'actions/app';
import {fetchItem, fetchItems} from 'actions/shared';
// Components
import Filters from 'components/core/ui/Filters';
import {CompanyField, SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import ActionButton from 'components/core/ui/mui/ActionButton';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Renders Products with Settings Defaults set and firmware - to view and manage them
 */
export default function List(passed_props) {
    // router
    const history = useHistory();
    const {search, pathname} = useLocation();
    const searchParams = new URLSearchParams(search);
    // redux store
    const dispatch = useDispatch();
    const {company_identifier, items_placement, firmware_placement, ...props} = useSelector(state => {
        const auth_user = state.auth.get('user');
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
        const companies = state.shared.getIn(['items', pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
        // ensure we don't create duplicate 'my' collection with UUID
        const company_identifier = searchParams.get('company') && searchParams.get('company') !== company.get(new Company().getUniqueIdentifier())
            ? searchParams.get('company')
            : 'my';

        const items_placement = `${new ProductDefaults().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;
        const firmware_placement = `${new Firmware().getPlacement()}${company_identifier === 'my' ? '' : `-${company_identifier}`}`;

        return {
            company_identifier: company_identifier,
            items_placement: items_placement,
            firmware_placement: firmware_placement,
            state: state.app.get('state'),
            loaded: !!state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            products_loaded: state.shared.getIn(['loaded', 'products']),
            products_items: state.shared.getIn(['items', 'products']),
            firmware_loaded: !!state.shared.getIn(['loaded', firmware_placement]),
            firmware_items: state.shared.getIn(['items', firmware_placement]) || ImmutableList(),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
            selectedCompany: company_identifier === 'my' ? company : companies.find(company => company.get(new Company().getUniqueIdentifier()) === searchParams.get('company')),
            user: auth_user
        };
    }, shallowEqual);
    // define table columns with sorting
    const tableHead = [
        {sortName: 'code', label: <FormattedMessage id='productdefaults.list.table.code' />},
        {sortName: 'version', label: <FormattedMessage id='productdefaults.list.table.firmware_version' />}
    ];
    // sorting & filtering
    const [sortedItems, sortItems, sortName, sortDirection, sortInitializing] = useLocalSort(
        props.items, !props.loaded || !props.products_loaded || !props.firmware_loaded,
        new ProductDefaults().getPlacement(), tableHead.map(item => item.sortName), 'code', 'desc', {
            code: (obj) => (props.products_items.find(el => el.get(new Product().getUniqueIdentifier()) === obj.get('code')) || new ImmutableMap({name: obj.get('code') || ''})).get('name'),
            version: (obj) => (props.firmware_items.find(el => el.get('code') === obj.get('code')) || new ImmutableMap({version: ''})).get('version')
        });
    const [filteredItems, filterItems, filters, filtersInitializing] = useLocalFilter(
        sortedItems, sortInitializing, new ProductDefaults().getPlacement(),
        ['company', 'search'], ['code'],
        {
            company: () => true
        });

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (props.selectedCompany && props.loaded === false) {
            dispatch(fetchItems(ProductDefaults, items_placement,
                props.selectedCompany.getIn(['links', 'product-defaults']),
                null, null, {success_affect_state: false}));
        }
    }, [props.loaded, props.selectedCompany]);
    useEffect(() => {
        if (props.loaded) {
            if (props.products_loaded === false) {
                dispatch(fetchItems(Product, 'products', 'products'));
            } else if (props.state === `fetching_items_${items_placement}`) {
                dispatch(setState(null));
            }
        }
    }, [props.products_loaded, props.loaded]);
    // ensure we have SelectedCompany
    useEffect(() => {
        if (!props.selectedCompany) {
            dispatch(fetchItem(Company, pathname.startsWith('/all-') ? 'companies' : 'my-companies', ['companies', company_identifier],
                {success_affect_state: false, ignore_403: !pathname.startsWith('/all-')}));
        }
    }, [props.selectedCompany, company_identifier]);
    // fetch related data without affecting state
    useEffect(() => {
        if (props.selectedCompany && props.firmware_loaded === false) {
            dispatch(fetchItems(Firmware, firmware_placement, props.selectedCompany.getIn(['links', 'firmware']), null, null, {affect_state: false}));
        }
    }, [props.firmware_loaded, props.selectedCompany]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'productdefaults.list.global.title'
                : 'productdefaults.list.title'} />}
            subheader={<FormattedMessage id={pathname.startsWith('/all-')
                ? 'productdefaults.list.global.subheader'
                : 'productdefaults.list.subheader'} />}
            action={passed_props.permission === 'RW'
                ? <ActionButton variant='contained' color='secondary' disabled={props.state !== null}
                                onClick={() => history.push(`/${pathname.startsWith('/all-') ? 'all-' : ''}product-defaults/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`)}>
                <AddIcon />
                <FormattedMessage id='actions.create' />
            </ActionButton>
                : <ActionButton iconButton disabled>
                <ModelIcon model='product_defaults' />
            </ActionButton>}
        />
        <Filters>
            {(pathname.startsWith('/all-') || props.company.get('company_type') === 'reseller') && <CompanyField
                value={filters.company || ''}
                change={value => filterItems('company', value)}
                all={false} fetch_company={false}
                loading={['fetching_item_companies', 'fetching_item_my-companies'].includes(props.state)}
                my_companies={!pathname.startsWith('/all-')}
            />}
            <SearchField
                label={<FormattedMessage id='productdefaults.list.search' />}
                value={filters.search || ''}
                search={(search) => filterItems('search', search)} />
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) =>
                            <TableCell key={idx} numeric={item.numeric}>
                                {item.sortName
                                    ? <TableSortLabel active={sortName === item.sortName}
                                                      direction={sortDirection}
                                                      onClick={() => sortItems(item.sortName)}>
                                        {item.label}
                                    </TableSortLabel>
                                    : item.label}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {[
                    'fetching_item_my-companies', 'fetching_item_companies',
                    `fetching_items_${items_placement}`
                ].includes(props.state) || filtersInitializing ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}><LinearProgress /></TableCell>
                    </TableRow>
                </TableBody>
                    : !props.items.size || (!!Object.keys(filters).length && !filteredItems.size)
                    ? <TableBody>
                    <TableRow>
                        <TableCell colSpan={tableHead.length}>
                            <FormattedMessage id={props.items.size ? 'filters.empty' : 'productdefaults.list.table.empty'}
                                              values={{link: passed_props.permissions === 'RW'
                                                      ? <Link to={`/${pathname.startsWith('/all-') ? 'all-' : ''}product-defaults/add${['my', 'all'].includes(company_identifier) ? '' : `?company=${company_identifier}`}`} className='hover-border'>
                                                          <FormattedMessage id='productdefaults.list.table.empty.link' />
                                                      </Link>
                                                      : <FormattedMessage id='productdefaults.list.table.empty.link' />}} />
                        </TableCell>
                    </TableRow>
                </TableBody>
                    : <TableBody>
                    {props.loaded && filteredItems.map((item, idx) => {
                        const link = `/${pathname.startsWith('/all-') ? 'all-' : ''}product-defaults/${item.get(new ProductDefaults().getUniqueIdentifier())}${company_identifier === 'my' ? '' : `?company=${company_identifier}`}`;
                        const onClick = () => props.state === null ? history.push(link) : {};

                        const product = props.products_items.find(el => el.get(new Product().getUniqueIdentifier()) === item.get('code'));
                        const firmware = props.firmware_items.find(el => el.get('code') === item.get('code'));

                        return <TableRow key={idx} link hover onClick={onClick}>
                            <TableCell>{product ? product.get('name') : item.get('code')}</TableCell>
                            <TableCell>
                                {!props.firmware_loaded
                                    ? <CircularProgress size={36} />
                                    : firmware
                                    ? firmware.get('version')
                                    : ''}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>} />
    </Card>;
}
