import React, {useCallback, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import Moment from 'moment';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {PaymentMethod} from 'lib/models';
// actions
import {deleteItem, markFiltered, saveItem} from 'actions/shared';
// components
import ThemeProvider from 'components/ThemeProvider';
// material ui
import Card from 'components/core/ui/mui/Card';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';
// vectors
import MenuIcon from '@material-ui/icons/MoreVertOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined';
import ActiveIcon from '@material-ui/icons/CheckOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Visa from 'components/core/vectors/subscription/Visa';
import MasterCard from 'components/core/vectors/subscription/MasterCard';


const useStyles = makeStyles(theme => ({
    // main card
    card: {
        position: 'relative',
        // style
        borderWidth: '2px',
        // none
        '&.empty': {
            borderStyle: 'dotted',
            color: theme.palette.text.secondary
        },
        // selected as default
        '&.active': {
            borderColor: theme.palette.grey[500],
            // recolor status icon
            '& .status .icon': {
                color: theme.palette.secondary.main
            }
        },
        // status expired
        '&.expired': {
            // recolor status text and icon
            '& .status .icon, & .status .text': {
                color: theme.palette.danger.main
            }
        },
        // opened
        '&.selected': {
            borderColor: theme.palette.primary.main
        }
    },
    // holder of everything
    contentHolder: {
        // get rid of extra padding from last-child CardContent
        '&, &:last-child': {
            paddingBottom: `${theme.spacing(2)}px`
        },
        // align info next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        minHeight: `${theme.spacing(10)}px`,
        // child next to each other
        '& .number, & .status': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: `${theme.spacing(2)}px`,
            margin: '0',
            // style
            fontWeight: theme.typography.fontWeightMedium
        },
        '& .number': {
            // proportion
            flexGrow: '1',
            // tweak size
            fontSize: theme.typography.pxToRem(18),
            '& .exp': {
                whiteSpace: 'nowrap'
            },
            // icons
            '& .card': {
                fontSize: `${theme.spacing(4)}px`,
                marginRight: `${theme.spacing(2)}px`
            },
            '& .brand-icon': {
                flexShrink: '0',
                height: `${theme.spacing(1.5)}px`,
                '&.mastercard': {
                    height: `${theme.spacing(2)}px`
                }
            },
            // responsive
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        '& .status': {
            // responsive
            [theme.breakpoints.down('sm')]: {
                '&, & .text': {
                    flexGrow: '1'
                }
            }
        },
        // loader children
        '& .MuiLinearProgress-root': {
            flexGrow: '1'
        }
    },
    // holder of linear progress indicating saving / removing
    loader: {
        // bottom
        position: 'absolute',
        bottom: '0',
        left: '0',
        right: '0'
    },
    // new dialog menu
    menuItem: {
        width: '100%',
        // proportion
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        // icon and text next to each other
        display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
        gap: `${theme.spacing(2)}px`,
        // style
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.disabled': {
            opacity: theme.palette.action.disabledOpacity
        },
        // variants
        '&.set_default svg': {
            color: theme.palette.secondary.main
        },
        '&.delete': {
            color: theme.palette.danger.main
        }
    }
}));

/**
 * Renders single payment method
 */
export default function ListItem(passed_props) {
    const {card, className, children, permission} = passed_props;
    const classes = useStyles();
    // redux store
    const dispatch = useDispatch();
    const items_placement = useMemo(() => new PaymentMethod().getPlacement(), []);
    const uuid = useMemo(() => new PaymentMethod().getUniqueIdentifier(), []);
    const props = useSelector(state => ({
        state: state.app.get('state'),
        state_identifier: state.app.get('identifier')
    }), shallowEqual);
    // local state
    const [open, setOpen] = useState(false);

    // get expiration date
    const exp = useMemo(() => {
        return card?.get('exp_year') && card?.get('exp_month')
            ? Moment(`${card.get('exp_month')}-${card.get('exp_year')}`, 'M-YYYY')
            : null;
    }, [card?.get('exp_year'), card?.get('exp_month')]);
    // get icon for brand
    const BrandIcon = useMemo(() => {
        switch(card?.get('brand')) {
            case 'mastercard':
                return MasterCard;
            case 'visa':
                return Visa;
            default:
                return null;
        }
    }, [card?.get('brand')]);
    // get status and its icon
    const [status, StatusIcon] = useMemo(() => {
        if (card) {
            // check if card is expired
            if (exp?.isBefore()) {
                return ['expired', CloseIcon];
            } else if (card.get('default')) {
                return ['active', ActiveIcon];
            }
        }
        return [null, null];
    }, [card?.get('default'), exp?.format()]);

    /**
     * Set payment-method as new default
     */
    const handleSetDefault = useCallback(card => {
        dispatch(saveItem(PaymentMethod, items_placement, card.getIn(['links', 'self']), {default: true}, card, {update_method: 'put'})).then(() => {
            // mark collection for re-fetch
            dispatch(markFiltered(PaymentMethod, items_placement));
        });
    }, []);

    /**
     * Delete payment-method
     */
    const handleDelete = useCallback(card => {
        dispatch(deleteItem(PaymentMethod, items_placement, card.getIn(['links', 'self']), card));
    }, []);

    return <React.Fragment>
        <Card
            className={[
                classes.card, className, status,
                card ? '' : 'empty',
                open ? 'selected' : ''
            ].filter(Boolean).join(' ')}
            raised={open}>
            <CardContent
                className={classes.contentHolder}
                component={(card && permission === 'RW') ? CardActionArea : undefined}
                onClick={(card && permission === 'RW') ? () => setOpen(!open) : undefined}>
                <h5 className='number newfont'>
                    <CreditCardIcon className='card' />
                    {card ? <React.Fragment>
                        <span className='text'>
                            {`**** **** **** ${card.get('last4')}`}
                        </span>
                        {exp && <span className='exp'>
                            {exp.format('MM/YY')}
                        </span>}
                        {BrandIcon
                            ? <BrandIcon className={`brand-icon ${card.get('brand')}`} />
                            : <span className='brand'>{card.get('brand')}</span>}
                    </React.Fragment> : children}
                </h5>
                <p className={['status', 'newfont', status].filter(Boolean).join(' ')}>
                    {status ? <React.Fragment>
                        <StatusIcon className='icon' />
                        <span className='text'>
                            <FormattedMessage id={`paymentmethods.list.card.status.${status}`} />
                        </span>
                    </React.Fragment> : <span className='text' />}
                    {(card && permission === 'RW') && <IconButton component='span' disableRipple>
                        <MenuIcon className='menu-icon' />
                    </IconButton>}
                </p>
            </CardContent>
            {(props.state_identifier === card?.get(uuid) && [
                'saving_item_payment-methods', 'saved_item_payment-methods', 'failed_save_item_payment-methods', 'deleting_item_payment-methods'
            ].includes(props.state)) && <div className={classes.loader}>
                {props.state === 'deleting_item_payment-methods'
                    ? <ThemeProvider alt>
                        <LinearProgress color='secondary' />
                    </ThemeProvider>
                    : <LinearProgress />}
            </div>}
        </Card>
        {(card && permission === 'RW') && <Dialog open={open} onClose={() => setOpen(false)}>
            <List>
                {[
                    {
                        icon: <ActiveIcon />, indicator: 'set_default',
                        label: 'paymentmethods.list.card.set_default',
                        onClick: () => handleSetDefault(card),
                        disabled: card.get('default')
                    },
                    {
                        icon: <CloseIcon />, indicator: 'delete',
                        label: 'actions.delete',
                        onClick: () => handleDelete(card)
                    }
                ].map(mprops => {
                    const {indicator, icon, label, onClick, disabled, ...rest_of_mprops} = mprops;

                    return <ButtonBase
                        key={indicator} disabled={disabled}
                        className={[
                            classes.menuItem, indicator, 'newfont',
                            disabled ? 'disabled' : ''
                        ].filter(Boolean).join(' ')}
                        onClick={() => {
                            setOpen(false);
                            onClick();
                        }} {...rest_of_mprops}>
                        {icon}<FormattedMessage id={label} />
                    </ButtonBase>;
                })}
            </List>
        </Dialog>}
    </React.Fragment>;
}
