import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
import {User} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {saveItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {FieldIcon, FieldWithIconHolder} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import PasswordIcon from '@material-ui/icons/SecurityOutlined';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import SaveIcon from '@material-ui/icons/LockOutlined';


/**
 * Changes User password - new password and confirmation
 * props.item is provided via parent in users/Detail.js
 */
function PasswordChange(props) {
    // local state
    const [showPasswords, setShowPasswords] = useState(false);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='users.detail.password.title' />}
            subheader={<FormattedMessage id='users.detail.password.subheader' />}
            action={
                <ActionButton iconButton disabled>
                    <PasswordIcon />
                </ActionButton>
            }
        />
        <CardContent>
            <Form onSubmit={props.handleSubmit} center>
                <Field name='password' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='users.detail.password.form.fields.password' />}
                       helperText={<FormattedMessage id='users.detail.password.form.fields.password.help' values={{length: 12}} />} />
                <SpaceDivider none />
                <FieldWithIconHolder>
                    <Field withIcon name='password2' type={showPasswords ? 'text' : 'password'} fieldType='TextField' label={<FormattedMessage id='users.detail.password.form.fields.password2' />} />
                    <FieldIcon onClick={() => setShowPasswords(!showPasswords)}>
                        {showPasswords ? <VisibilityOff /> : <Visibility />}
                    </FieldIcon>
                </FieldWithIconHolder>
            </Form>
            <SpaceDivider />
        </CardContent>
        {['saving_item_users_password', 'saved_item_users_password', 'failed_save_item_users_password'].includes(props.state)
            ? <CardActionsLoader success={props.state === 'saved_item_users_password'}
                                 failure={props.state === 'failed_save_item_users_password'}
                                 postAnimation={success => {
                                     props.setState(null);
                                     if (success) {
                                         // clear form
                                         props.reduxFormReset('userPasswordForm');
                                     }
                                 }} />
            : <ThemeProvider alt>
            <CardActions>
                <Button variant='contained' color='primary' type='submit'
                        disabled={props.state !== null}
                        onClick={() => props.handleSubmit()}>
                    <SaveIcon />
                    <FormattedMessage id='users.detail.password.form.submit' />
                </Button>
            </CardActions>
        </ThemeProvider>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isLength(
        props.intl.formatMessage({id: 'users.detail.password.error.length'}, {length: 12}), errors, 'password', data.password,
        {min: 12, max: 128}) &&
    validator.isNotNull(null, errors, 'password', data.password) &&
    validator.containsUpperCase(props.intl.formatMessage({id: 'user.detail.password.error.uppercase'}), errors, 'password', data.password) &&
    validator.containsLowerCase(props.intl.formatMessage({id: 'user.detail.password.error.lowercase'}), errors, 'password', data.password) &&
    validator.containsNumber(props.intl.formatMessage({id: 'user.detail.password.error.number'}), errors, 'password', data.password) &&
    validator.containsSpecialCharacter(props.intl.formatMessage({id: 'user.detail.password.error.special'}), errors, 'password', data.password);
    validator.isNotNull(null, errors, 'password2', data.password2) &&
    validator.equals(
        props.intl.formatMessage({id: 'users.detail.password.error.no_match'}),
        errors, 'password2',
        data.password, data.password2);

    return errors;
};

const PasswordChangeForm = reduxForm({
    form: 'userPasswordForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        let {password2, ...rest_of_data} = JSON.parse(JSON.stringify(props.formValues));

        return dispatch(saveItem(User, 'users', props.item.getIn(['links', 'self']), rest_of_data, props.item, {state_name: 'users_password'}));
    }
})(PasswordChange);

const ConnectedPasswordChange = connect((state) => ({
    state: state.app.get('state'),
    formValues: getFormValues('userPasswordForm')(state)
}), (dispatch) => bindActionCreators({
    setState,
    saveItem,
    reduxFormReset
}, dispatch))(PasswordChangeForm);

export default injectIntl(ConnectedPasswordChange);
