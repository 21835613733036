import React, {useMemo} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router';
import {Role} from 'lib/models';
// Components
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import Submissions from 'components/modules/submissions/List';
import PartnerLevel from 'components/modules/companies/PartnerLevel';
import {Col, Row} from 'components/core/ui/Grid';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import ActionButton from 'components/core/ui/mui/ActionButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import RebateIcon from '@material-ui/icons/AccountBalanceWalletOutlined';


/**
 * Dashboard with components which are on other Portal pages
 */
export default function Dashboard() {
    const history = useHistory();
    // redux store
    const props = useSelector(state => ({
        state: state.app.get('state'),
        user: state.auth.get('user'),
        company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company'])),
        submissions_loaded: state.shared.getIn(['loaded', 'submissions']),
        submissions_items: state.shared.getIn(['items', 'submissions']),
        permissions: state.auth.get('permissions')
    }), shallowEqual);
    const submissionPerm = useMemo(() => {
        return props.permissions.get(new Role().getPermission('/submissions'));
    }, [JSON.stringify(props.permissions)]);
    const total_rebate = useMemo(() => {
        return props.submissions_items.map(submission => submission.get('total_rebate') || 0).reduce((a, b) => a + b, 0);
    }, [props.submissions_loaded]);

    return <Row wrap>
        <Col width='66.6667%'>
            <Row wrap>
                <Col width='50%'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='dashboard.profile.title' />}
                                    subheader={<FormattedMessage id='dashboard.profile.subheader' />}
                                    action={<ActionButton iconButton disabled>
                                        <ModelIcon model='user' />
                                    </ActionButton>} />
                        <CardContent>
                            <Form center>
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.profile.form.fields.username' />}
                                       value={props.user.get('username') || ''} />
                                <SpaceDivider none />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.profile.form.fields.first_name' />}
                                       value={props.user.get('first_name') || ''} />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.profile.form.fields.last_name' />}
                                       value={props.user.get('last_name') || ''} />
                                <SpaceDivider none />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.profile.form.fields.email' />}
                                       value={props.user.get('email') || ''} />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.profile.form.fields.phone' />}
                                       value={props.user.get('phone_number') || ''} />
                            </Form>
                        </CardContent>
                        <CardActions center>
                            <Button variant='outlined'
                                    color='primary' disabled={props.state !== null}
                                    onClick={() => history.push('/partner/profile')}>
                                <FormattedMessage id='dashboard.profile.goto' />
                            </Button>
                        </CardActions>
                    </Card>
                </Col>
                <Col width='50%'>
                    <Card>
                        <CardHeader title={<FormattedMessage id='dashboard.company.title' />}
                                    subheader={<FormattedMessage id='dashboard.company.subheader' />}
                                    action={<ActionButton iconButton disabled>
                                        <ModelIcon model='company' />
                                    </ActionButton>} />
                        <CardContent>
                            <Form center>
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.company.form.fields.name' />}
                                       value={props.company.get('name') || ''} />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.company.form.fields.vat' />}
                                       value={props.company.get('vat') || ''} />
                                <SpaceDivider none />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.company.form.fields.email' />}
                                       value={props.company.get('email') || ''} />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.company.form.fields.phone' />}
                                       value={props.company.get('phone_number') || ''} />
                                <SpaceDivider none />
                                <Field fieldType='NoReduxTextField' disabled={true} label={<FormattedMessage id='dashboard.company.form.fields.website' />}
                                       value={props.company.get('website') || ''} />
                            </Form>
                        </CardContent>
                        <CardActions center>
                            <Button variant='outlined'
                                    color='primary' disabled={props.state !== null}
                                    onClick={() => history.push('/partner/company')}>
                                <FormattedMessage id='dashboard.company.goto' />
                            </Button>
                        </CardActions>
                    </Card>
                </Col>
            </Row>
            {(submissionPerm !== 'X' && props.company.get('cluster_company_submission_enabled') !== false) && <React.Fragment>
                <SpaceDivider grid />
                <Submissions dashboard permission={submissionPerm} />
            </React.Fragment>}
        </Col>
        <Col width='33.3333%'>
            <PartnerLevel item={props.company} />
            {(submissionPerm !== 'X' && props.company.get('cluster_company_submission_enabled') !== false) && <React.Fragment>
                <SpaceDivider grid />
                <Card>
                    <CardHeader title={<FormattedMessage id='dashboard.rebate.title' />}
                                subheader={<FormattedMessage id='dashboard.rebate.subheader' />}
                                action={<ActionButton iconButton disabled>
                                    <RebateIcon />
                                </ActionButton>} />
                    <CardContent>
                        {!props.submissions_loaded
                            ? <LinearProgress />
                            : <Typography variant='h3' color='primary' className='text-center'>
                                {`${total_rebate} €`}
                            </Typography>}
                    </CardContent>
                </Card>
            </React.Fragment>}
        </Col>
    </Row>;
}
