import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import {User, Company, Paginator} from 'lib/models';
import {useFilter, usePaginatedItems} from 'lib/filters';
import {useHistory} from 'react-router';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import RequestCSV from 'components/core/ui/RequestCSV';
import Pagination, {PaginationLoading} from 'components/core/ui/Pagination';
import Field, {SearchField} from 'components/core/ui/Field';
import {Link} from 'react-router-dom';
import StickyTable from 'components/core/ui/StickyTable';
import Filters from 'components/core/ui/Filters';
// material-ui
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Table from 'components/core/ui/mui/Table';
import TableHead from 'components/core/ui/mui/TableHead';
import TableRow from 'components/core/ui/mui/TableRow';
import TableCell from 'components/core/ui/mui/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from '@material-ui/core/TableBody';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import CheckIcon from '@material-ui/icons/CheckBoxOutlined';
import UnCheckIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import {List as ImmutableList} from 'immutable';


/**
 * Renders Users from backend - to view and manage them
 */
export default function List() {
    // router
    const history = useHistory();
    // define table columns with sorting
    const tableHead = [
        {sortName: 'username', label: <FormattedMessage id='users.list.table.username' />},
        {sortName: 'name', label: <FormattedMessage id='users.list.table.name' />},
        {sortName: 'email', label: <FormattedMessage id='users.list.table.email' />},
        {sortName: 'company_name', label: <FormattedMessage id='users.list.table.company' />},
        {sortName: 'created_at', label: <FormattedMessage id='users.list.table.created_at' />}
    ];
    // redux store
    const dispatch = useDispatch();
    const items_placement = new User().getPlacement();
    const props = useSelector(state => {
        return {
            state: state.app.get('state'),
            loaded: state.shared.getIn(['loaded', items_placement]),
            items: state.shared.getIn(['items', items_placement]) || ImmutableList(),
            paginator: state.shared.getIn(['paginator', items_placement]) || new Paginator(),
            page_references: state.shared.getIn(['page_references', items_placement]) || ImmutableList(),
            company: state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']))
        };
    }, shallowEqual);
    // pagination
    const [paginatedItems] = usePaginatedItems(props.items, props.paginator, props.page_references, new User().getUniqueIdentifier());
    // sorting & filtering (API)
    const [sortName, sortDirection] = [false, false];
    const [filterItems, filters, filtersInitializing, postponedFilters] = useFilter(
        false, new User().getPlacement(), ['search', 'subscription'], props.state !== null);

    /**
     * During initialization fetch items
     */
    useEffect(() => {
        if (!filtersInitializing && (props.loaded === false || !!Object.keys(filters).length)) {
            dispatch(fetchItems(User, items_placement, 'users', null,
                Object.keys(filters).length ? filters : null, {paginate: true, paginator_page: 1}));
        }
    }, [filtersInitializing, JSON.stringify(filters)]);

    return <Card>
        <CardHeader
            title={<FormattedMessage id='users.list.title' />}
            subheader={<FormattedMessage id='users.list.subheader' />}
            action={<RequestCSV placement={items_placement} url='users-export'
                                filtersOptions={[
                                    {intl: 'users.list.exports.request.all', icon: <ModelIcon model='users' />, filters: {}},
                                    {intl: 'users.list.exports.request.subscribed', icon: <CheckIcon />, filters: {subscription: true}},
                                    {intl: 'users.list.exports.request.not_subscribed', icon: <UnCheckIcon />, filters: {subscription: false}}
                                ]} />}
        />
        <Filters disabled={props.state !== null}>
            <SearchField label={<FormattedMessage id='users.list.search' />}
                         value={postponedFilters.search || filters.search || ''}
                         search={(search) => filterItems('search', search)} />
            <Field label={<FormattedMessage id='users.list.filter.subscription' />}
                   fieldType='NoReduxSelect' value={postponedFilters.subscription || filters.subscription || ''}
                   onChange={(event) => filterItems('subscription', event.target.value)}>
                <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                <MenuItem value='true'>
                    <ListItemIcon className='icon'><CheckIcon color='primary' /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='users.list.filter.subscription.choice.true' /></ListItemText>
                </MenuItem>
                <MenuItem value='false'>
                    <ListItemIcon className='icon'><UnCheckIcon /></ListItemIcon>
                    <ListItemText className='text'><FormattedMessage id='users.list.filter.subscription.choice.false' /></ListItemText>
                </MenuItem>
            </Field>
        </Filters>
        <StickyTable
            header={<Table size={tableHead.length}>
                <TableHead>
                    <TableRow>
                        {tableHead.map((item, idx) => <TableCell key={idx}>
                            {sortName === item.sortName
                                ? <TableSortLabel disabled active direction={sortDirection}>
                                    {item.label}
                                </TableSortLabel>
                                : item.label}
                        </TableCell>)}
                    </TableRow>
                </TableHead>
            </Table>}
            content={<Table size={tableHead.length}>
                {['fetching_items_users', 'fetching_items_pagination-users'].includes(props.state) || filtersInitializing ? <PaginationLoading
                        paginationLoading={props.state === 'fetching_items_pagination-users'}
                        tableHead={tableHead} paginator={props.paginator}
                    />
                    : !paginatedItems.size
                        ? <TableBody>
                            <TableRow>
                                <TableCell colSpan={tableHead.length}>
                                    <FormattedMessage id={props.paginator.get('page') > 1 ? 'pagination.empty' : 'filters.empty'} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    : <TableBody>
                    {(props.loaded || !!Object.keys(filters).length) && paginatedItems.map((user, idx) => {
                        const onClick = () => props.state === null ? history.push(`/customers/${user.get(new User().getUniqueIdentifier())}${user.get('company') !== props.company.get(new Company().getUniqueIdentifier()) ? `?company=${user.get('company')}` : ''}`) : {};

                        return <TableRow key={idx} hover>
                            <TableCell link urlView onClick={onClick}>
                                {user.get('username')}
                            </TableCell>
                            <TableCell link onClick={onClick}>
                                {`${user.get('first_name')} ${user.get('last_name')}`}
                            </TableCell>
                            <TableCell link urlView onClick={onClick}>
                                {user.get('email')}
                            </TableCell>
                            <TableCell>
                                <Link className='hover-border' to={`/companies/${user.get('company')}`}>
                                    {user.get('company_name')}
                                </Link>
                            </TableCell>
                            <TableCell link onClick={onClick}>
                                {Moment(user.get('created_at')).format('l, LT')}
                            </TableCell>
                        </TableRow>;
                    })}
                </TableBody>}
            </Table>}
            footer={<Table size={1}>
                <Pagination model={User} placement={items_placement} filters={filters}
                            paginationLoading={true} />
            </Table>} />
    </Card>;
}
