import {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, initialize as reduxFormInitialize} from 'redux-form';
// Actions
import {backupForms} from 'actions/app';


/**
 * Hackish component to restore destroyed Forms after Language switch
 */
function FormRestoration(props) {
    /**
     * Watch for filled backupForms (from <LanguageSwitcherMenu /> and removed restorationForm from store
     */
    useEffect(() => {
        // check if we have forms to restore and restoration form is destroyed
        if (props.backupedForms !== null && !Object.keys(props.forms).includes('restorationForm')) {
            Object.keys(props.backupedForms).forEach(form => {
                if (props.backupedForms[form].values) {
                    props.reduxFormInitialize(form, props.backupedForms[form].values);
                }
            });
            props.backupForms();
        }
    }, [props.backupedForms, (props.forms || {})['restorationForm']]);

    return null;
}

const FormRestorationForm = reduxForm({
    form: 'restorationForm'
})(FormRestoration);

const ConnectedFormRestoration = connect((state) => ({
    forms: state.form,
    initialValues: {backup: 'ready'},
    backupedForms: state.app.get('backupForms')
}), (dispatch) => bindActionCreators({
    backupForms,
    reduxFormInitialize
}, dispatch))(FormRestorationForm);

export default ConnectedFormRestoration;
