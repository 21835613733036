import React, {useEffect, useState} from 'react';
import {connect, shallowEqual, useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {reduxForm, getFormValues, reset as reduxFormReset, initialize as reduxFormInitialize} from 'redux-form';
import {withRouter} from 'react-router-dom';
import validator from 'lib/valitator';
import {ProvisioningFile, ProductGroup, ProvisioningFileType, Company, ProvisioningProfile} from 'lib/models';
import Moment from 'moment';
import {useLocalSort} from 'lib/filters';
import {List as ImmutableList} from 'immutable';
import useFeatureFlag from 'lib/featureFlag';
// Actions
import {setState} from 'actions/app';
import {fetchItems, markFiltered, saveItem, uploadFiles} from 'actions/shared';
// Components
import ErrorMessage from 'components/core/ui/ErrorMessage';
import ThemeProvider from 'components/ThemeProvider';
import {Row, Col} from 'components/core/ui/Grid';
import Form from 'components/core/ui/Form';
import Field, {CompanyField, DropFile} from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import ActionButton from 'components/core/ui/mui/ActionButton';
import MenuItem from 'components/core/ui/mui/MenuItem';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import UploadIcon from '@material-ui/icons/CloudOutlined';
import CancelIcon from '@material-ui/icons/UndoOutlined';
import DeleteIcon from '@material-ui/icons/CloseOutlined';


/**
 * Wrapper of Detail to create standalone Page
 */
function DetailWrapper() {
    // redux store
    const dispatch = useDispatch();
    const {permission, ...props} = useSelector(state => ({
        provisioning_file_types_loaded: state.shared.getIn(['loaded', 'provisioning-file-types']),
        provisioning_file_types_items: state.shared.getIn(['items', 'provisioning-file-types']),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        product_groups_items: state.shared.getIn(['items', 'product-groups']),
        permission: state.auth.get('permissions').get('provisioning_files')
    }), shallowEqual);
    // related sorting
    const [sortedProvisioningFileTypes] = useLocalSort(props.provisioning_file_types_items, !props.provisioning_file_types_loaded);
    const [sortedProductGroups] = useLocalSort(props.product_groups_items, !props.product_groups_loaded);

    // fetch related data without affecting state
    useEffect(() => {
        if (props.provisioning_file_types_loaded === false) {
            dispatch(fetchItems(ProvisioningFileType, new ProvisioningFileType().getPlacement(), 'provisioning-file-types', null, null, {affect_state: false}));
        }
    }, [props.provisioning_file_types_loaded]);
    useEffect(() => {
        if (props.product_groups_loaded === false) {
            dispatch(fetchItems(ProductGroup, new ProductGroup().getPlacement(), 'product-groups', null, null, {affect_state: false}));
        }
    }, [props.product_groups_loaded]);

    return <Card>
        <CardHeader title={<FormattedMessage id='provfiles.detail.add.title' />}
                    subheader={<FormattedMessage id='provfiles.detail.subheader' />}
                    action={<ActionButton iconButton disabled>
                        <ModelIcon model='provisioning_file' />
                    </ActionButton>} />
        <Detail permission={permission} card
                sortedProvisioningFileTypes={sortedProvisioningFileTypes}
                sortedProductGroups={sortedProductGroups} />
    </Card>;
}

/**
 * ADD or Preview of Provisioning File.
 *  used in DetailWrapper as standalone /add page or as inline upload in List
 */
function RAWDetail(props) {
    const intl = useIntl();
    const items_placement = props.items_placement;
    const edit = !!props.item;
    const [dropFileKey, setDropFileKey] = useState(0);
    const featureFlag_companyDefault = useFeatureFlag('staging_provisioning', {company: props.selectedCompany?.get(new Company().getUniqueIdentifier())});

    return <div>
        <CardContent nospace={!props.card}>
            <Row wrap>
                <Col width='320px'>
                    <Form onSubmit={props.handleSubmit}>
                        <DropFile label={`${intl.formatMessage({id: 'provfiles.detail.form.fields.file'})}*`}
                                  initialFile={props.initialValues ? {id: props.initialValues.uuid, url: props.initialValues.file_url} : {}}
                                  selectedFile={props.formValues ? {id: props.formValues.uuid, url: props.formValues.file_url} : {}}
                                  fieldChange={(id, url) => {
                                      props.change('uuid', id);
                                      props.change('file_url', url);
                                      props.change('file_to_upload', null);
                                  }}
                                  onChange={(file) => {
                                      props.change('uuid', file);
                                      props.change('file_url', null);
                                      props.change('file_to_upload', file);

                                      props.clearSubmitErrors();
                                  }}
                                  key={dropFileKey}
                                  disabled={edit}
                                  required />
                        <SpaceDivider none />
                        {(props.submitFailed && props.error && ['file_bad_type', 'file_too_large'].includes(props.error)) && <React.Fragment>
                            <ErrorMessage>
                                <FormattedMessage id={props.error === 'file_too_large' ? 'upload.error.size' : 'upload.error.type'} />
                            </ErrorMessage>
                        </React.Fragment>}
                        <Field name='file_to_upload' fieldType='ErrorField' />
                    </Form>
                </Col>
                <Col>
                    <Form onSubmit={props.handleSubmit}>
                        {(props.card && (props.company.get('company_type') === 'reseller' || props.location.pathname.startsWith('/all-'))) && <React.Fragment>
                            <CompanyField
                                value={!edit && props.selectedCompanyIdentifier === 'my' ? ''
                                    : props.selectedCompanyIdentifier === 'my' ? props.selectedCompany.get(new Company().getUniqueIdentifier()) : props.selectedCompanyIdentifier}
                                change={value => props.history.push({search: value ? `?company=${value}` : ''})}
                                disabled={edit} all={false}
                                my_companies={!props.location.pathname.startsWith('/all-')}
                            />
                            <SpaceDivider none />
                        </React.Fragment>}
                        <Field name='name' fieldType='TextField' label={`${intl.formatMessage({id: 'provfiles.detail.form.fields.name'})}*`} />
                        <SpaceDivider none />
                        <Field name='type' fieldType='Select' disabled={edit}
                               label={`${intl.formatMessage({id: 'provfiles.detail.form.fields.type'})}*`}
                               loading={!props.provisioning_file_types_loaded}>
                            <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
                            <MenuItem fake value={props.formValues && props.formValues.type} />
                            {props.sortedProvisioningFileTypes.map((type, idx) =>
                                <MenuItem key={idx} value={type.get(new ProvisioningFileType().getUniqueIdentifier())}>{type.get('name')}</MenuItem>
                            )}
                        </Field>
                        {featureFlag_companyDefault && <React.Fragment>
                            <SpaceDivider none />
                            <Field name='company_default' fieldType='Checkbox' size='full' color='secondary'
                                   label={<FormattedMessage id='provfiles.detail.form.fields.company_default' />}
                                   helperText={edit ? undefined : <FormattedMessage id='provfiles.detail.form.fields.company_default.help' />} />
                        </React.Fragment>}
                        <SpaceDivider none />
                        <Field name='product_groups' fieldType='Select' multiple
                               label={`${intl.formatMessage({id: 'provfiles.detail.form.fields.product_groups'})}*`}
                               loading={!props.product_groups_loaded}
                               disabled={edit || !!props.filterProductGroups}>
                            <MenuItem fake value={props.formValues && props.formValues.product_groups} />
                            {props.sortedProductGroups.map((product_group, idx) =>
                                <MenuItem key={idx} value={product_group.get(new ProductGroup().getUniqueIdentifier())}>{product_group.get('name')}</MenuItem>
                            )}
                        </Field>
                        {edit && <React.Fragment>
                            <SpaceDivider none />
                            <Field name='created_at' fieldType='TextField' label={<FormattedMessage id='provfiles.detail.form.fields.created_at' />}
                                   format={value => value ? Moment(value).format('l, LT') : ''} disabled={true} />
                        </React.Fragment>}
                    </Form>
                </Col>
            </Row>
        </CardContent>
        {props.card
            ? [
                `uploading_${items_placement}`, `uploaded_${items_placement}`, `failed_upload_${items_placement}`,
                `saving_item_${items_placement}`, `saved_item_${items_placement}`, `failed_save_item_${items_placement}`
            ].includes(props.state)
                ? <CardActionsLoader
                    success={props.state === `saved_item_${items_placement}`}
                    failure={[`failed_upload_${items_placement}`, `failed_save_item_${items_placement}`].includes(props.state)}
                    postAnimation={(success) => {
                        props.setState(null);
                        if (success) {
                            // redirect to List
                            props.history.push(
                                `/${props.location.pathname.startsWith('/all-provisioning-files') ? 'all-' : ''}provisioning-files${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`
                            );
                        }
                    }} />
                : <ThemeProvider alt>
                    <CardActions>
                        <Button disabled={props.state !== null}
                                onClick={() => props.history.push(
                                    `/${props.location.pathname.startsWith('/all-provisioning-files') ? 'all-' : ''}provisioning-files${props.selectedCompanyIdentifier === 'my' ? '' : `?company=${props.selectedCompanyIdentifier}`}`
                                )}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>
                        {(props.permission === 'RW') && <Button
                            variant='contained' color='primary' type='submit' disabled={props.state !== null}
                            onClick={props.handleSubmit}>
                                <UploadIcon />
                                <FormattedMessage id='actions.upload' />
                            </Button>
                        }
                    </CardActions>
                </ThemeProvider>
            : <React.Fragment>
                <SpaceDivider />
                <ThemeProvider alt>
                    <CardActions nospace left={!edit}>
                        {props.cancel && <Button onClick={() => props.cancel()}>
                            <CancelIcon />
                            <FormattedMessage id='actions.cancel' />
                        </Button>}
                        {(props.permission === 'RW' && props.deleteItem) && <Button
                            variant='contained' color='secondary' disabled={props.state !== null}
                            onClick={() => props.deleteItem()}>
                            <DeleteIcon />
                            <FormattedMessage id='actions.delete' />
                        </Button>}
                        {props.permission === 'RW' && <Button variant='contained' color='primary' type='submit'
                                disabled={props.state !== null || props.permission !== 'RW' || !props.selectedCompany}
                                onClick={() => props.handleSubmit()}
                                loading={[
                                    `uploading_${items_placement}`, `uploaded_${items_placement}`, `failed_upload_${items_placement}`,
                                    `saving_item_${items_placement}`, `saved_item_${items_placement}`, `failed_save_item_${items_placement}`
                                ].includes(props.state)}
                                success={props.state === `saved_item_${items_placement}`}
                                failure={[`failed_upload_${items_placement}`, `failed_save_item_${items_placement}`].includes(props.state)}
                                postAnimation={(success) => {
                                    props.setState(null);
                                    if (success) {
                                        // reset form and <DropFile />
                                        props.reduxFormReset(props.form_name);
                                        setDropFileKey(dropFileKey + 1);
                                        // optional extra postSave action
                                        if (props.postSave) {
                                            props.postSave(edit);
                                        }
                                    }
                                }}>
                            {edit ? <SaveIcon /> : <UploadIcon />}
                            <FormattedMessage id={`actions.${edit ? 'save' : 'upload'}`} />
                        </Button>}
                    </CardActions>
                </ThemeProvider>
            </React.Fragment>
        }
    </div>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'name', data.name);
    validator.isNotNull(null, errors, 'type', data.type);
    validator.isNotNull(null, errors, 'product_groups', data.product_groups);
    if (!props.item) {
        validator.isNotNull(null, errors, 'file_to_upload', data.file_to_upload);
    }

    return errors;
};

const DetailForm = reduxForm({
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        // pop-up some values which we don't want to send
        const {file_url, file_to_upload, ...rest_of_data} = new ProvisioningFile().popUpFields(props.formValues, !props.item);

        // in case of eit skip upload
        return (props.item
            ? new Promise(resolve => resolve(true))
            : dispatch(uploadFiles(props.items_placement, props.selectedCompany.getIn(['links', 'provisioning-files-upload']), [file_to_upload]))
        ).then(results => {
            if (!props.item) {
                // update file_id with newly uploaded file
                rest_of_data.file_id = results[0].data.provisioning_file_id;
            }
            // save object
            return dispatch(saveItem(ProvisioningFile, props.items_placement, props.item ? props.item.getIn(['links', 'self']) : props.selectedCompany.getIn(['links', 'provisioning-files']), rest_of_data, props.item, {update_method: 'put', add_mark_filtered: false})).then(() => {
                // mark not updated lists for reload
                return dispatch(markFiltered(ProvisioningProfile, `${new ProvisioningFile().getPlacement()}-global`));
            });
        });
    }
})(RAWDetail);

const ConnectedDetail = connect((state, props) => {
    const form_name = props.item ? 'provisioningFilePreviewForm' : 'provisioningFileForm';

    const searchParams = new URLSearchParams(props.location.search);
    const auth_user = state.auth.get('user');
    const companies = state.shared.getIn(['items', props.location.pathname.startsWith('/all-') ? 'companies' : 'my-companies']) || ImmutableList();
    const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === auth_user.getIn(['links', 'company']));
    const selectedCompanyIdentifier = !searchParams.get('company') || searchParams.get('company') === company.get(new Company().getUniqueIdentifier())
        ? 'my' : searchParams.get('company');
    const selectedCompany = selectedCompanyIdentifier === 'my' ? company
        : companies.find(el => el.get(new Company().getUniqueIdentifier()) === selectedCompanyIdentifier);

    return {
        form: form_name,
        form_name: form_name,
        state: state.app.get('state'),
        selectedCompanyIdentifier: selectedCompanyIdentifier,
        selectedCompany: selectedCompany,
        items_placement: `${new ProvisioningFile().getPlacement()}${selectedCompanyIdentifier === 'my' ? '' : `-${selectedCompanyIdentifier}`}`,
        initialValues: props.item
            ? {company_default: false, ...props.item.toJS()}
            : {product_groups: props.filterProductGroups || [], company_default: false},
        provisioning_file_types_loaded: state.shared.getIn(['loaded', 'provisioning-file-types']),
        product_groups_loaded: state.shared.getIn(['loaded', 'product-groups']),
        company: company,
        user: auth_user,
        formValues: getFormValues(form_name)(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    saveItem,
    uploadFiles,
    reduxFormReset,
    reduxFormInitialize
}, dispatch))(DetailForm);

const Detail = withRouter(ConnectedDetail);

export {Detail};
export default DetailWrapper;
