import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Norway flag
 */
export default function NoFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <path fill='#ed2939' d='M0 0h640v480H0z' />
        <path fill='#ffffff' d='M180 0h120v480H180z' />
        <path fill='#ffffff' d='M0 180h640v120H0z' />
        <path fill='#002664' d='M210 0h60v480h-60z' />
        <path fill='#002664' d='M0 210h640v60H0z' />
    </SvgIcon>;
}
