import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Sweden flag
 */
export default function SeFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <path fill='#066aa7' d='M0 0h640v480H0z' />
        <path fill='#fecc00' d='M0 192h640v96H0z' />
        <path fill='#fecc00' d='M176 0h96v480h-96z' />
    </SvgIcon>;
}
