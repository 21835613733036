import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {theme} from 'theme';
import {AssetFolder} from 'lib/models';
// actions
import {fetchItem} from 'actions/shared';
// Components
import AssetFoldersMenu from 'components/modules/assets/FolderMenu';
import Menu from 'components/core/ui/mui/Menu';
import Button from 'components/core/ui/mui/Button';
// material-ui
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
// icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


/**
 * Render folder selection
 * @param {object} props - props object
 * @param {string} props.value - folder id
 * @param {(value: string) => void} props.onChange - on change value
 */
export default function FolderSelectButton(props) {
    const {value, onChange} = props;
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const asset_folder_placement = useMemo(() => `${new AssetFolder().getPlacement()}-${value}-item`, [value]);
    const asset_folder_identifier = useMemo(() => new AssetFolder().getUniqueIdentifier(), []);

    const {item, item_loaded, item_loading, rootLink} = useSelector((state) => {
        const folder = state.shared.getIn(['items', asset_folder_placement, 0]);
        const loaded = state.shared.getIn(['loaded', asset_folder_placement, 0]);

        return {
            item: folder, item_loaded: loaded, loading: !folder && !loaded,
            rootLink: state.api?.get('client')?.rootMap.get('folders')
        };
    });

    useEffect(() => {
        if (!item_loaded) {
            dispatch(fetchItem(AssetFolder, asset_folder_placement, `${rootLink}${value}`, {affect_state: false}));
        }
    }, [item_loaded, asset_folder_placement]);

    return <React.Fragment>
        <Box component={Button} marginX={1} variant='outlined' endIcon={<ExpandMoreIcon />} onClick={e => setAnchorEl(e.currentTarget)}>
            {item_loading ? <CircularProgress size={24} /> : (
                item?.get('name') || <FormattedMessage id='pages.builder.component.assets.field.placeholder' />
            )}
        </Box>
        <Box component={Menu} minWidth={theme.spacing(37)} anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
            <AssetFoldersMenu select selectWithRoot={false} unmountOnExit={false} initialValue={item?.get(asset_folder_identifier)}
                value={value} onChange={(...args) => {
                    setAnchorEl(null);
                    onChange(...args);
                }} />
        </Box>
    </React.Fragment>;
};
