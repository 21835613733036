import React from 'react';


/**
 * Wave vector for Voice Quality
 */
export default function VoiceQuality(props) {
    const {color1, color2} = props;

    return <svg viewBox='0 0 200 71' style={{display: 'block', fill: 'none'}} {...props}>
        <g clipPath={`voicequality_clip_${color1}_${color2}`}>
            <path d='M283.327 11.4379C283.326 11.2161 283.33 10.9946 283.329 10.7672C283.36 5.09543 284.736 -0.3078 282.503 -5.85982C280.325 -11.2671 275.49 -15.158 270.64 -18.4867C260.714 -25.2913 249.694 -29.2602 237.842 -25.2476C213.43 -16.9718 207.143 10.1178 191.001 27.2C163.514 56.2889 116.011 55.1103 82.2953 37.6414C66.8549 29.6423 52.0609 18.1892 34.652 17.731C7.52938 17.0212 -13.8649 40.806 -31.6173 57.7548C-43.7277 69.3157 -53.6648 86.7612 -71.8359 89.4956C-85.3097 91.5267 -98.4428 84.4161 -109.445 76.4291C-125.304 64.9197 -140.694 53.0347 -161.503 54.2339C-177.701 55.1691 -192.214 63.1424 -205.381 71.8278C-217.732 79.9778 -233.403 88.4588 -241.37 101.308C-246.463 109.53 -246.553 123.988 -234.735 125.943C-231.361 126.501 -227.887 125.808 -224.68 124.63C-215.316 121.181 -208.04 113.887 -199.053 109.71C-189.033 105.053 -177.551 102.518 -166.59 101.72C-145.655 100.197 -128.644 106.85 -109.848 114.69C-89.7366 123.076 -69.1278 124.016 -48.3643 117.381C-34.5012 112.946 -22.1245 104.604 -8.44645 100.224C7.05094 95.2588 22.7995 91.6134 39.0652 95.4963C50.5991 98.2503 60.1205 104.683 69.8733 111.139C90.5296 124.809 112.269 135.285 135.985 142.262C154.872 147.827 174.237 151.584 193.639 154.758C208.657 157.211 223.922 160.069 239.117 161.042C246.275 161.501 253.535 161.409 260.593 160.028C264.027 159.355 267.402 158.379 270.629 157.014C272.897 156.054 278.12 154.227 279.426 152.004C284.271 143.753 282.653 133.472 282.704 124.243C283.495 106.487 282.904 88.5526 282.998 70.7812C283.11 50.9983 283.215 31.2208 283.327 11.4379Z' fill={`url(#voicequality_linear_${color1}_${color2})`} />
            <path d='M-38.4761 65.9845C-19.0118 67.4331 -11.061 47.8662 3.74372 38.7583C23.2987 26.7302 35.3074 50.6762 52.1007 56.7131C74.143 64.6436 95.8066 56.2429 113.47 40.949C128.144 28.2404 137.728 7.11466 157.126 2.38749C187.123 -4.92196 211.405 28.6796 236.14 41.1844C254.192 50.3152 275.001 55.3848 294.099 49.0591C321.024 40.1389 337.862 11.676 354.093 -13.61C370.324 -38.896 392.084 -65.1446 420.16 -65.4313C451.839 -65.7559 474.293 -33.7209 499.752 -13.1483C536.006 16.1427 583.072 23.5592 628.139 25.5302' stroke={color2}  strokeWidth='2' strokeMiterlimit='10' />
        </g>
        <defs>
            <linearGradient id={`voicequality_linear_${color1}_${color2}`} x1='-120.814' y1='56.5731' x2='285.801' y2='67.8551' gradientUnits='userSpaceOnUse'>
                <stop stopColor={color1} />
                <stop offset='1' stopColor={color2} />
            </linearGradient>
            <clipPath id={`voicequality_clip_${color1}_${color2}`}>
                <rect fill='white' transform='translate(0 0.0554199)' />
            </clipPath>
        </defs>
    </svg>;
}
