import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {SalesCluster} from 'lib/models';
// Actions
import {fetchItems} from 'actions/shared';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';


/**
 * Select for Sales Clusters fetched from API, used in Companies, Submissions, etc.
 */
export default function SalesClustersField(props) {
    // Split some stuff from props
    const {name, label, required, multiple, disabled, excludedSalesClusters, ...rest_of_props} = props;
    const intl = useIntl();
    const items_placement = new SalesCluster().getPlacement();

    // redux store
    const dispatch = useDispatch();
    const sales_clusters = useSelector(state => state.shared.getIn(['items', items_placement]));

    /**
     * During initialization fetch Sales Clusters if needed
     */
    useEffect(() => {
        if (!sales_clusters?.size) {
            dispatch(fetchItems(SalesCluster, items_placement, 'sales-clusters'));
        }
    }, [!sales_clusters?.size]);

    return <Field label={label || `${intl.formatMessage({id: `salesclustersfield.label${multiple ? '.multiple' : ''}`})}${required ? '*' : ''}`}
                  fieldType='Select' multiple={multiple} name={name || 'sales_clusters'}
                  loading={!sales_clusters} disabled={disabled || !sales_clusters}
                  renderValue={!multiple ? undefined : values => {
                      const real_values = [];
                      values.forEach(value => {
                          real_values.push(intl.formatMessage({id: `salesclustersfield.choice.${value.replace(' ', '_')}`}));
                      });
                      return real_values.sort().join(', ');
                  }}
                  {...rest_of_props}>
        {!multiple && <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>}
        {sales_clusters?.map((value) => {
            return (!excludedSalesClusters || !excludedSalesClusters.includes(value))
                && <MenuItem key={value.get('uuid')} value={value.get('uuid')}>
                    {value.get('name')}
                </MenuItem>;
        })}
    </Field>;
}
