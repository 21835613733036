import React from 'react';
import {makeStyles} from '@material-ui/styles';
// components
import ThemeProvider from 'components/ThemeProvider';
// material-ui
import Card from 'components/core/ui/mui/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from 'components/core/ui/mui/CardContent';
import IconButton from '@material-ui/core/IconButton';
// vectors
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/CloseOutlined';


const useStyles = makeStyles((theme) => ({
    rootCard: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    },
    dragIndicator: {
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        cursor: 'row-resize',
        color: theme.palette.snomGrey[400]
    },
    closeButton: {
        position: 'absolute',
        top: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`,
        zIndex: `${theme.zIndex.mobileStepper - 10}`
    },
    cardContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    }
}));

/**
 * DraggableCard component with drag indicator to be used with SortableContainer
 * @param {object} props - component props with parameters provided by SortableContainer
 * @param {object} props.style - Style object provided by SortableContainer
 * @param {Function} props.onRemove - Callback function to remove the card
 * @param {boolean} props.editable - Whether the card is editable
 * @param {object} props.classes - classes object 
 */
const DraggableCard = React.forwardRef((props, ref) => {
    const {classes = {}, style, onRemove, editable, children, ...rest_of_props} = props;
    const _classes = useStyles();

    return <Card className={[_classes.rootCard, classes.rootCard].filter(Boolean).join(' ')} style={style} ref={ref}>
        {editable && <React.Fragment>
            <div {...rest_of_props} className={[_classes.dragIndicator, classes.dragIndicator].filter(Boolean).join(' ')}>
                <DragIndicatorIcon fontSize='small' />
            </div>
            <Divider flexItem orientation="vertical"  />
        </React.Fragment>}
        <CardContent className={[_classes.cardContent, classes.cardContent].filter(Boolean).join(' ')}>
            {editable && onRemove && <ThemeProvider alt>
                <IconButton className={_classes.closeButton} onClick={onRemove} color='secondary' size='small'><CloseIcon /></IconButton>
            </ThemeProvider>}
            {children}
        </CardContent>
    </Card>;
});

export default DraggableCard;
