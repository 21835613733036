import React from 'react';


/**
 * Helper Component that during mount checks component ref scrollbar, if not presented it triggers action
 */
export default function ScrollCheck(props) {
    const {componentRef, action, extraDep = ''} = props;

    /**
     * Check if we have componentRef and scroll is lower than scroll space
     */
    React.useEffect(() => {
        if (componentRef.current && componentRef.current.scrollWidth && componentRef.current.clientHeight &&
            componentRef.current.scrollWidth < componentRef.current.clientHeight) {
            action();
        }
    }, [componentRef.current, extraDep]);

    return null;
}
