import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import EnFlag from 'components/core/vectors/flags/en';
// import CsFlag from 'components/core/vectors/flags/cs';
import DeFlag from 'components/core/vectors/flags/dach';
import EsFlag from 'components/core/vectors/flags/es';
import ItFlag from 'components/core/vectors/flags/it';
import FrFlag from 'components/core/vectors/flags/fr';
import RuFlag from 'components/core/vectors/flags/ru';


/**
 * Render Country flag
 *
 * Props:
 *  code - language code, e.g. 'en'
 */
export function LanguageIcon(props) {
    const {code, ...rest_of_props} = props;

    let Flag;
    switch (props.code) {
        case 'en':
            Flag = EnFlag;
            break;
        case 'de':
            Flag = DeFlag;
            break;
        case 'es':
            Flag = EsFlag;
            break;
        case 'it':
            Flag = ItFlag;
            break;
        case 'fr':
            Flag = FrFlag;
            break;
        case 'ru':
            Flag = RuFlag;
            break;
        default:
            return <span />;
    }
    return <Flag {...rest_of_props} />;
}

/**
 * Returns country string
 *
 * Props:
 *  code - language code, e.g. 'en'
 */
export function getLanguageName(code) {
    switch (code) {
        case 'en':
            return 'English';
        case 'de':
            return 'Deutsch';
        case 'es':
            return 'Español';
        case 'it':
            return 'Italiano';
        case 'fr':
            return 'Française';
        case 'ru':
            return 'Pусский';
        case 'keys':
            return 'INTL Keys';
        default:
            return '';
    }
}

/**
 * Field with supported languages by the application
 */
export default function LanguageField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'languagefield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {['en', 'de', 'es', 'it', 'fr', 'ru'].map(code => <MenuItem value={code} key={code}>
            <ListItemIcon className='icon'><LanguageIcon code={code} /></ListItemIcon>
            <ListItemText className='text'>{getLanguageName(code)}</ListItemText>
        </MenuItem>)}
    </Field>;
}
