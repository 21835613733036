import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {Ticket} from 'lib/models';
// Components
import Field from 'components/core/ui/Field';
// material-ui
import MenuItem from 'components/core/ui/mui/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// icons
import ScheduledIcon from '@material-ui/icons/InsertInvitationOutlined';
import ProcessingIcon from '@material-ui/icons/DateRangeOutlined';
import PendingIcon from '@material-ui/icons/CalendarTodayOutlined';
import FinishedIcon from '@material-ui/icons/EventAvailableOutlined';
import ExpiredIcon from '@material-ui/icons/EventBusyOutlined';


const useStyles = makeStyles(theme => ({
    statusIcon: {
        // variants
        '&.scheduled': {
            color: theme.palette.grey[500]
        },
        '&.sent': {
            color: theme.palette.primary[500]
        },
        '&.resolved': {
            color: theme.palette.success[500]
        },
        '&.error, &.expired': {
            color: theme.palette.error[500]
        }
    }
}));

/**
 * Render Ticket Status Icon
 *
 * Props:
 *  identifier - Status, e.g. 'pending'
 */
export function StatusIcon(props) {
    // Split some stuff from props
    const {_classes, className, identifier, ...rest_of_props} = props;
    // Merge classes from props and our custom
    const classes = useStyles();
    const {root} = _classes || {};
    const rootClasses = [
        classes.statusIcon, root, className,
        identifier
    ].filter(Boolean).join(' ');

    let IconElement;
    switch (identifier) {
        case 'scheduled':
            IconElement = ScheduledIcon;
            break;
        case 'pending':
            IconElement = PendingIcon;
            break;
        case 'sent':
            IconElement = ProcessingIcon;
            break;
        case 'resolved':
            IconElement = FinishedIcon;
            break;
        case 'error':
        case 'expired':
        case 'overlimit':
            IconElement = ExpiredIcon;
            break;
        default:
            return <span />;
    }

    return <IconElement className={rootClasses} {...rest_of_props} />;
}

/**
 * Select field for Ticket status with Icon and Text
 */
export default function TicketStatusField(props) {
    // Split some stuff from props
    const {label, required, ...rest_of_props} = props;
    const intl = useIntl();

    return <Field fieldType='Select' label={label || `${intl.formatMessage({id: 'ticketstatusfield.label'})}${required ? '*' : ''}`}
                  {...rest_of_props}>
        <MenuItem value=''><em><FormattedMessage id='filters.none' /></em></MenuItem>
        {new Ticket().getStatuses().map((identifier, idx) =>
            <MenuItem value={identifier} key={idx}>
                <ListItemIcon className='icon'><StatusIcon identifier={identifier} /></ListItemIcon>
                <ListItemText className='text'><FormattedMessage id={`ticketstatusfield.choice.${identifier}`} /></ListItemText>
            </MenuItem>
        )}
    </Field>;
}
