import {constants} from 'reducers/shared';
import {SubmissionError} from 'redux-form';
import {parseLinkHeader} from 'lib/util';
import {fromJS} from 'immutable';
import {handleErrors, getErrorCode} from 'actions/errors';
import {updateClientLinks} from 'actions/api';
import {setState, addMessage} from 'actions/app';
import {setUser, setPermissions} from 'actions/auth';
import {User, Company, Role, Submission} from 'lib/models';
import fileDownload from 'js-file-download';


/**
 * Simple action creator to store fetched Items in Collection
 *
 * @param model - raw model, e.g. User
 * @param {string} placement - where to store data, e.g. 'users'
 * @param {array} items - items to set
 * @param {boolean} update - should we replace collection from scratch or just update it
 * @param {boolean} loaded - collection should be marked as loaded
 * @param {object|null} paginator - optional RAW data for Paginator
 */
export function setCollection(model, placement, items, update = false, loaded = true, paginator = null) {
    return {
        type: constants.SHARED_SET_COLLECTION,
        model,
        placement,
        items,
        update,
        loaded,
        paginator
    };
}

/**
 * In case we are moving backward or forward (in already fetched Pages), we need to update Paginator
 *
 * @param paginator - RAW data for Paginator
 * @param placement - placement of Paginator in store
 */
export function updatePaginator(paginator, placement) {
    return {
        type: constants.SHARED_UPDATE_PAGINATOR,
        paginator,
        placement
    };
}

/**
 * Simple action creator to remove single Item from Collection
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param item - items to remove
 */
export function removeFromCollection(model, placement, item) {
    return {
        type: constants.SHARED_REMOVE_FROM_COLLECTION,
        model,
        placement,
        item
    };
}

/**
 * Simple action creator to store active filters of Items in Collection
 *
 * @param placement - where to store data, e.g. 'users'
 * @param filters - object containing active filters with values
 */
export function setFilter(placement, filters = {}) {
    return {
        type: constants.SHARED_SET_FILTER,
        placement,
        filters
    };
}
/**
 * Simple action creator to store current ordering of Items in Collection
 *
 * @param placement - where to store data, e.g. 'users'
 * @param ordering - name of sorted by field with optional desc '-' prefix
 */
export function setOrdering(placement, ordering) {
    return {
        type: constants.SHARED_SET_ORDERING,
        placement,
        ordering
    };
}

/**
 * Simple action creator to mark that Items in Collection were Filtered/Ordered/Whatever and needs reload
 * it simply turns 'loaded' into false
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 */
export function markFiltered(model, placement) {
    return {
        type: constants.SHARED_FILTERED,
        model,
        placement
    };
}

/**
 * Simple action creator to mark that Items in Collection are no longer up-to-date and therefore should reload
 * Used by Websocket
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 */
export function markOutdated(model, placement) {
    return {
        type: constants.SHARED_OUTDATED,
        model,
        placement
    };
}

/**
 * Set local state of the component to store. Used in Components that integrates third party modules.
 *
 * @param state - e.g. null, 'fetching'
 * @param placement - where to store data, e.g. 'documentation'
 */
export function setLocalState(state, placement) {
    return {
        type: constants.SHARED_LOCAL_STATE,
        state,
        placement
    };
}

/**
 * Fetches Links for specific URL and updates Client.js link map, e.g. 'tables'
 *
 * @param placement - placement in link map, e.g. 'statistics'
 * @param url       - URL to Link map
 * @param options   - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_links_tables'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 */
export function fetchLinks(placement, url, options = {}) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // process options and it's defaults
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        const success_state = options.success_state !== undefined ? options.success_state : null;

        if (affect_state) {
            dispatch(setState(`fetching_links_${state_name}`));
        }
        return client.get(url).then(result => {
            return dispatch(updateClientLinks(placement, result.data));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(null));
            }
            return handleErrors(`fetchLinks-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch Items from backend
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param ordering - optional ordering of request, e.g. 'name'
 * @param filter - optional filter, e.g. '{type: 'events.gcal'}'
 * @param options - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_items_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  paginate                - default: false; is request paginated?
 *  paginator_page          - default: 1; which Page we are fetching?
 *  update                  - default: false; collection will be updated instead of replaced
 */
export function fetchItems(model, placement, url, ordering = null, filter = null, options = {}) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // process options and it's defaults
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        const success_state = options.success_state !== undefined ? options.success_state : null;
        const paginate = options.paginate !== undefined ? options.paginate : false;
        const paginator_page = options.paginator_page !== undefined ? options.paginator_page : 1;
        const update = options.update !== undefined ? options.update : false;

        let qparams = {};
        if (ordering) {
            qparams['ordering'] = ordering;
        }
        if (filter) {
            qparams = {...qparams, ...filter};
        }

        if (affect_state) {
            dispatch(setState(`fetching_items_${state_name}`));
        }
        return client.get(url, qparams).then(result => {
            let paginator_data = null;
            if (paginate) {
                // get links from header
                const links = result.headers?.link ? parseLinkHeader(result.headers.link) : {};
                const paginate_by = parseInt(new URLSearchParams(links.next?.split('?')[1]).get('page_size') || '0', 10);
                // process data for paginator
                paginator_data = {
                    // next link
                    next: links.next,
                    // paginate_by might not be presented if we don't have next link
                    ...(paginate_by ? {paginate_by: paginate_by} : {}),
                    // set new page
                    page: paginator_page || null,
                    // maximum loaded page (we expect that we are fetching new records only)
                    maxLoadedPage: paginator_page
                };
            }
            dispatch(setCollection(model, placement, result.data || [], update, update || filter === null, paginator_data));
            if (affect_state && success_affect_state) {
                dispatch(setState(success_state));
            }
            return result; // return result
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(null));
            }
            return handleErrors(`fetchItems-${state_name}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Fetch specific Items from backend
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. ['pages', id] or specific URL
 * @param options - {} contains optional options
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_item_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  failure_state           - default: null; what should be set after failure fetch as state
 *  expand_item             - default: null; item which should be expanded with additional data
 *  expand_item_data        - default: false; fetched data are data for Item
 *  company                 - default: null; item should belong to this Company
 *  ignore_403              - default: false; should we ignore #403 error? (promise will return false)
 */
export function fetchItem(model, placement, url, options = {}) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');
        let item_object = null; // it's here to get rid of eslint no-undef warning

        // process options and it's defaults
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        const success_state = options.success_state !== undefined ? options.success_state : null;
        const failure_state = options.failure_state !== undefined ? options.failure_state : null;
        const expand_item = options.expand_item !== undefined ? options.expand_item : null;
        const expand_item_data = options.expand_item_data !== undefined ? options.expand_item_data : false;
        const company = options.company !== undefined ? options.company : null;
        const ignore_403 = options.ignore_403 !== undefined ? options.ignore_403 : false;

        if (affect_state) {
            dispatch(setState(`fetching_item_${state_name}`));
        }
        return client.get(url).then(result => {
            item_object = expand_item_data ? {data: result.data} : result.data;
            // check if we are expanding existing item
            if (expand_item) {
                item_object = {...expand_item.toObject(), ...item_object};
            }
            // check if item shares the same Company
            if (company && company.getIn(['links', 'self']) !== result.data.links.company) {
                if (affect_state) {
                    return dispatch(setState(failure_state));
                }
            }
            return dispatch(setCollection(model, placement, [item_object], true));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).then(() => {
            return fromJS(item_object); // return data (so we can work with them)
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(failure_state));
            }
            const error_code = getErrorCode(error);
            switch (error_code) {
                // detail not found, we don't need to do anything
                case 404:
                    return false;
                case 403:
                    if (ignore_403) {
                        return false;
                    }
                    break;
            }
            return handleErrors(`fetchItem-${state_name}`, dispatch, getState, error, error_code);
        });
    };
}

/**
 * Add or Update provided item.
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param data - new data
 * @param item - do we already have item? (so editing not adding)
 * @param options - {} contains optional options
 *  global_placement    - default: null; optionally also update -global, pass global name e.g. 'endpoints-global'
 *  add_mark_filtered   - default: true; adding new items marksFiltered collection
 *  state_name          - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  different_add_state - default: false; should state be changed to e.g. 'added_item_pages' when adding
 *  affect_state        - default: true; should method affect state with e.g. 'saving_item_pages'
 *  update_method       - default: 'patch'; which method should be used during update (patch / put)
 *  save_method         - default: 'post'; which method should be used during create (post / put)
 *  error_field_prefix  - default: null; should we prefix submission errors? e.g. 'firmware' will change 'version' -> 'firmware.version'
 *  ignore_400          - default: false; should we ignore #404 error? (promise will return false)
 *  ignore_403          - default: false; should we ignore #403 error? (promise will return false)
 *  qparams             - default: {}; optional query params appended to the url
 */
export function saveItem(model, placement, url, data, item = null, options = {}) {
    return (dispatch, getState) => {
        const state = getState();
        const client = state.api.get('client');
        let result_data = null; // it's here to get rid of eslint no-undef warning

        // process options and it's defaults
        const global_placement = options.global_placement !== undefined ? options.global_placement : false;
        const add_mark_filtered = options.add_mark_filtered !== undefined ? options.add_mark_filtered : true;
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const different_add_state = options.different_add_state !== undefined ? options.different_add_state : false;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const update_method = options.update_method !== undefined ? options.update_method : 'patch';
        const save_method = options.save_method !== undefined ? options.save_method : 'post';
        const error_field_prefix = options.error_field_prefix !== undefined ? options.error_field_prefix : null;
        const ignore_400 = options.ignore_400 !== undefined ? options.ignore_400 : false;
        const ignore_403 = options.ignore_403 !== undefined ? options.ignore_403 : false;
        const qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setState(`saving_item_${state_name}`, item && item.get(new model().getUniqueIdentifier())));
        }
        const client_promise = item
            ? update_method === 'put' ? client.put(url, data, qparams) : client.patch(url, data, qparams)
            : save_method === 'put' ? client.put(url, data, qparams) : client.post(url, data, qparams);
        return client_promise.then(result => {
            result_data = result.data;
            // if client is editing himself (logged user), we also have to store it to auth app
            if (item && model === User && state.auth.getIn(['user', 'username']) === result_data.username) {
                dispatch(setUser({...result_data, admin_access: state.auth.getIn(['user', 'admin_access'])}));
                // handle permission
                const role = state.shared.getIn(['items', 'roles']).find(role => role.get(new Role().getUniqueIdentifier()) === result_data.role);
                if (role) {
                    // prepare object with everything RW
                    const permissions = Object.fromEntries(Object.keys(new Role().getPermissionsMapping()).map(permission => [permission, 'rw']));
                    // set to auth store
                    dispatch(setPermissions({...role.toJS(), permissions: {...permissions, ...role.get('permissions').toJS()}}));
                }
            }
            // update global collection
            if (global_placement) {
                dispatch(setCollection(model, global_placement, [result_data], true));
            }
            // update collection
            return dispatch(setCollection(model, placement, [result_data], true));
        }).then(() => {
            // create (add) and must mark filtered collection for re-fetch (because it doesn't use local sorting)
            if (!item && add_mark_filtered) {
                dispatch(markFiltered(model, placement));
            }
            if (affect_state) {
                dispatch(setState(`${different_add_state && !item ? 'added' : 'saved'}_item_${state_name}`)); // trigger success animation, loader will reset state
            }
            return fromJS(result_data); // return data (so we can work with them)
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(`failed_save_item_${state_name}`)); // trigger failure animation, loader will reset state
            }
            const error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    if (ignore_400) {
                        return false;
                    } else {
                        let submission_errors = error.response.data.details;
                        if (error_field_prefix) {
                            submission_errors = {[error_field_prefix]: submission_errors};
                        }
                        // display field errors
                        throw new SubmissionError(submission_errors);
                    }
                case 403:
                    if (ignore_403) {
                        return false;
                    }
                    break;
            }
            return handleErrors(`saveItem-${state_name}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Function to mass update multiple items, using saveItem function
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param data - data to update
 * @param items - items to update
 * @param options - {} contains optional options passed down saveItem function, expect those mentioned bellow
 *  popUpFieldsAttrs    - default: []; extra attributes passed to models popUpFields() function
 *  companyRelated      - default: false; data are stored in company structure: 'placement-global', 'placement', 'placement-${company_identifier}'
 *  containsCompany     - default: false; contains 'company' field so we don't have to search in collection for company ID
 */
export function saveItems(model, placement, data, items, options = {}) {
    return (dispatch, getState) => {
        const state = getState();

        // process options and it's defaults
        let {popUpFieldsAttrs, companyRelated, containsCompany, ...passedOptions} = options;
        popUpFieldsAttrs = popUpFieldsAttrs !== undefined ? popUpFieldsAttrs : [];
        companyRelated = companyRelated !== undefined ? companyRelated : false;
        containsCompany = containsCompany !== undefined ? containsCompany : false;

        // get user company, used later to correct placement
        const company = state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === state.auth.get('user').getIn(['links', 'company']));

        // prepare saveItem promises
        const promises = [];
        items.forEach(item => {
            let rest_of_data = new model().popUpFields(item.toJS(), ...[popUpFieldsAttrs]);
            rest_of_data = {...rest_of_data, ...data};

            // special case
            if (model === Submission) {
                // we must popUpFields from Submission related objects directly stored in the Submission
                rest_of_data.items.forEach((product, idx) => {
                    // pop-up rebate
                    delete rest_of_data.items[idx].product_rebate;
                });
                rest_of_data.invoices.forEach((invoice, idx) => {
                    // pop-up invoices
                    delete rest_of_data.invoices[idx].file_url;
                });
            }

            // get correct placement (beware of updating just -global collection!)
            let itemPlacement = placement;
            if (companyRelated && placement.endsWith('-global')) {
                itemPlacement = itemPlacement.replace('-global',
                    item.getIn(['links', 'company']) === company.getIn(['links', 'self']) ? '' : containsCompany
                        ? `-${item.get('company')}`
                        : `${state.shared.getIn(['items', 'companies']).find(el => el.getIn(['links', 'self']) === item.getIn(['links', 'company'])).get(new Company().getUniqueIdentifier())}`
                );
            }

            promises.push(dispatch(saveItem(model, itemPlacement, item.getIn(['links', 'self']), rest_of_data, item,
                {affect_state: false, global_placement: companyRelated && placement.endsWith('-global') ? placement : undefined, ...passedOptions})));
        });

        dispatch(setState(`saving_items_${placement}`));
        // save all
        return Promise.all(promises).then(results => {
            dispatch(setState(`saved_items_${placement}`));
            return results;
        }).catch(error => {
            dispatch(setState(`failed_save_item_${placement}`));
            return handleErrors(`saveItems-${placement}`, dispatch, getState, error, null);
        });
    };
}

/**
 * Delete specific item
 *
 * @param model - raw model, e.g. User
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages-order'
 * @param item - Item to be deleted
 * @param options - {} contains optional options
 *  error_message_intl      - default: null; If provided, error message will be displayed when #400 occurs
 *  affect_state            - default: true; should method affect state with e.g. 'deleting_item_pages'
 *  state_name              - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  success_state           - default: null; what should be set after successful fetch as state
 *  failure_state           - default: null; what should be set after failure fetch as state
 *  qparams         - default: {}; optional query params appended to the url
 */
export function deleteItem(model, placement, url, item, options = {}) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // process options and it's defaults
        const error_message_intl = options.error_message_intl !== undefined ? options.error_message_intl : null;
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        const success_state = options.success_state !== undefined ? options.success_state : null;
        const failure_state = options.failure_state !== undefined ? options.failure_state : null;
        const qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setState(`deleting_item_${state_name}`, item.get(new model().getUniqueIdentifier())));
        }
        return client.delete(url, qparams).then(() => {
            return dispatch(removeFromCollection(model, placement, item));
        }).then(() => {
            if (affect_state && success_affect_state) {
                return dispatch(setState(success_state));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(failure_state));
            }
            const error_code = getErrorCode(error);
            switch (error_code) {
                // something failed (deletion blocked)
                case 400:
                    if (error_message_intl) {
                        dispatch(addMessage({intl_id: error_message_intl, type: 'error', path: 'on-change'}));
                        return false;
                    }
            }
            return handleErrors(`deleteItem-${placement}`, dispatch, getState, error, error_code);
        });
    };
}

/**
 * Simple POST of data to API
 *
 * @param placement - name of state, e.g. 'invite' => 'posting_invite', 'posted_invite', 'failed_invite'
 * @param url - URL where to post
 * @param data - Data from Form
 * @param options - {} contains optional options
 *  post_method             - default: 'post'; which method should be used to POST data
 *  setState                - default: app/setState; which setState action should be used, could be from AUTH
 *  affect_state            - default: true; should method affect state with e.g. 'fetching_item_pages'
 *  success_affect_state    - default: true; should successful fetch modify state? (default setState(null))
 *  submissionError         - default: true; returns submissionsError if response error_code is 400, otherwise returns false
 *  conflictEmail           - default: false; returns submissionError to email if response error_code is 409
 *  ignore_403              - default: false; should we ignore #403 error?
 *  ignore_ax_timeout          - default: false; should we ignore axios timeout error?
 *  qparams                 - default: {}; optional query params appended to the url
 */
export function simplePost(placement, url, data, options = {}) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // process options and it's defaults
        const post_method = options.post_method !== undefined ? options.post_method : 'post';
        const setStateMethod = options.setState !== undefined ? options.setState : setState;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;
        const success_affect_state = options.success_affect_state !== undefined ? options.success_affect_state : true;
        const success_state = options.success_state !== undefined ? options.success_state : `posted_${placement}`;
        const failure_state = options.failure_state !== undefined ? options.failure_state : `failed_${placement}`;
        const submissionError = options.submissionError !== undefined ? options.submissionError : true;
        const conflictEmail = options.conflictEmail !== undefined ? options.conflictEmail : false;
        const ignore_403 = options.ignore_403 !== undefined ? options.ignore_403 : false;
        const ignore_ax_timeout = options.ignore_ax_timeout !== undefined ? options.ignore_ax_timeout : false;
        const qparams = options.qparams !== undefined ? options.qparams : {};

        if (affect_state) {
            dispatch(setStateMethod(`posting_${placement}`));
        }
        const client_promise = post_method === 'delete'
            ? client.delete(url, data, qparams)
            : post_method === 'put'
                ? client.put(url, data, qparams)
                : post_method === 'patch'
                    ? client.patch(url, data, qparams)
                    : post_method === 'get'
                        ? client.get(url, qparams)
                        : client.post(url, data, qparams);
        return client_promise.then((result) => {
            if (affect_state && success_affect_state) {
                dispatch(setStateMethod(success_state)); // trigger success animation, loader will reset state
            }
            return result; // returns result
        }).catch(error => {
            if (affect_state) {
                dispatch(setStateMethod(failure_state)); // trigger failure animation, loader will reset state
            }
            const error_code = getErrorCode(error);

            // handle axios timeout
            if (ignore_ax_timeout && error && error.code === 'ECONNABORTED') {
                return false;
            }

            switch (error_code) {
                case 400:
                    if (submissionError) {
                        // display field errors
                        throw new SubmissionError(error.response.data.details);
                    } else {
                        return false;
                    }
                case 403:
                    if (ignore_403) {
                        return false;
                    }
                    break;
                case 409:
                    if (conflictEmail) {
                        // User with the same email address already exists
                        throw new SubmissionError({email: error.response.data.error}); // assign error to email field
                    } else {
                        break;
                    }
            }
            return handleErrors(`simplePost-${placement}`, dispatch, getState, error, error_code);
        });
    };
}

/**
 * Upload File to API (e.g. for SubmissionProductVariants), set it to Files collection and return link to it
 *
 * @param placement - name of state, e.g. 'submission-products' => 'uploading_submission-products', 'uploaded_...', 'failed_upload_...'
 * @param url - client URL, e.g. 'pages'
 * @param files - Files for upload
 */
export function uploadFiles(placement, url, files) {
    return (dispatch, getState) => {
        const client = getState().api.get('client');

        // prepare upload promises
        const promises = [];
        files.forEach(file => {
            promises.push(client.upload(url, file));
        });

        dispatch(setState(`uploading_${placement}`));
        // upload files
        return Promise.all(promises).then(results => {
            dispatch(setState(`uploaded_${placement}`));
            return results;
        }).catch(error => {
            dispatch(setState(`failed_upload_${placement}`));
            const error_code = getErrorCode(error);
            switch (error_code) {
                case 400:
                    throw new SubmissionError({_error: 'file_bad_type'});
                case 413:
                    throw new SubmissionError({_error: 'file_too_large'});
            }
            return handleErrors('uploadFiles', dispatch, getState, error, error_code);
        });
    };
}

/**
 * Fetches and triggers download of CSV
 *
 * @param placement - where to store data, e.g. 'users'
 * @param url - client URL, e.g. 'pages'
 * @param ordering - optional ordering of request, e.g. 'name'
 * @param filter - optional filter, e.g. '{type: 'events.gcal'}'
 * @param options - {} contains optional options
 *  state_name          - default: 'placement' (from param); what name should we use to affect state (useful when you have two forms to the same model on the same page)
 *  affect_state        - default: true; should method affect state with e.g. 'saving_item_pages'
 */
export function downloadCSV(placement, url, ordering = null, filter = null, options = {}) {
    return (dispatch, getState) => {
        const state = getState();
        const client = state.api.get('client');

        // process options and it's defaults
        const state_name = options.state_name !== undefined ? options.state_name : placement;
        const affect_state = options.affect_state !== undefined ? options.affect_state : true;

        let qparams = {all: 'true'};
        if (ordering) {
            qparams['ordering'] = ordering;
        }
        if (filter) {
            qparams = {...qparams, ...filter};
        }

        if (affect_state) {
            dispatch(setState(`fetching_csv_${state_name}`));
        }
        return client.get(url, qparams, {Accept: 'text/csv'}).then(result => {
            fileDownload(result.data, `${placement}.csv`);
            if (affect_state) {
                dispatch(setState(`fetched_csv_${state_name}`));
            }
        }).catch(error => {
            if (affect_state) {
                dispatch(setState(`failed_fetch_csv_${state_name}`));
            }
            return handleErrors(`downloadCSV-${state_name}`, dispatch, getState, error, null);
        });
    };
}
