import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {reduxForm, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {Endpoint, ProvisioningProfile, Ticket, TicketGroup} from 'lib/models';
// Actions
import {setState} from 'actions/app';
import {markOutdated, saveItem} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field, {TicketTypeField, DMUpgradeVersionField} from 'components/core/ui/Field';
// material-ui
import Button from 'components/core/ui/mui/Button';
import Card from 'components/core/ui/mui/Card';
import CardHeader from 'components/core/ui/mui/CardHeader';
import CardContent from 'components/core/ui/mui/CardContent';
import ActionButton from 'components/core/ui/mui/ActionButton';
import CardActions from 'components/core/ui/mui/CardActions';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
// icons
import ModelIcon from 'components/core/vectors/ModelIcon';
import AddIcon from '@material-ui/icons/AddOutlined';


/**
 * Allow creating Endpoint Ticket or Provisioning Profile Ticket Group from Device Management
 */
function Add(props) {
    const intl_base = props.ticket_group ? 'ticketgroups' : 'tickets';

    return !props.formValues ? null : <Card>
        <CardHeader title={<FormattedMessage id={`${intl_base}.detail.add.title`} />}
                    subheader={<FormattedMessage id={`${intl_base}.detail.add.subheader`} />}
                    action={<ActionButton iconButton disabled>
                        <ModelIcon model={props.ticket_group ? 'ticket_groups' : 'tickets'} />
                    </ActionButton>} />
        <CardContent>
            <Form onSubmit={props.handleSubmit}>
                <TicketTypeField name='type' required />
                <Field name='scheduled_at' fieldType='DateField'
                       label={<FormattedMessage id={`${intl_base}.detail.form.fields.scheduled_at`} />}
                       helperText={<FormattedMessage id={`${intl_base}.detail.form.fields.scheduled_at.help`} />} />
                {props.formValues.type === 'upgrade' && <DMUpgradeVersionField change={props.change} />}
            </Form>
        </CardContent>
        {[`saving_item_${props.placement}`, `saved_item_${props.placement}`, `failed_save_item_${props.placement}`].includes(props.state)
            ? <CardActionsLoader success={props.state === `saved_item_${props.placement}`}
                                 failure={props.state === `failed_save_item_${props.placement}`}
                                 postAnimation={(success) => {
                                     props.setState(null);
                                     if (success) {
                                         props.close();
                                     }
                                 }} />
            : <ThemeProvider alt>
            <CardActions>
                <Button variant='contained' color='primary' type='submit' disabled={props.state !== null}
                        onClick={() => props.handleSubmit()}>
                    <AddIcon />
                    <FormattedMessage id='actions.create' />
                </Button>
            </CardActions>
        </ThemeProvider>}
    </Card>;
}

const validate = (data, props) => {
    const errors = {params: {}};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'type', data.type);
    if (data.scheduled_at) {
        validator.isDateTime(null, errors, 'scheduled_at', data.scheduled_at, null) &&
        validator.isInFuture(null, errors, 'scheduled_at', data.scheduled_at, null);
    }
    // upgrade validation
    if (data.type === 'upgrade') {
        if (data.params === undefined) { data.params = {}; }
        if (data.params.version) {
            validator.isEmpty(null, errors, 'params.url', data.params.url);
        } else if (data.params.url) {
            validator.isEmpty(null, errors, 'params.version', data.params.version);
        } else {
            validator.isNotNull(null, errors, 'params.version', data.params.version);
            validator.isNotNull(null, errors, 'params.url', data.params.url);
        }
    }

    return errors;
};

const AddForm = reduxForm({
    form: 'ticketForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        return dispatch(saveItem(props.Model, props.placement, props.ticket_group ? props.related_item.getIn(['links', 'ticket_groups']) : props.related_item.getIn(['links', 'tickets']), props.formValues, null, {add_mark_filtered: false})).then(() => {
            dispatch(markOutdated(props.Model, props.placement));
        });
    }
})(Add);

const ConnectedAdd = connect((state, props) => {
    return {
        state: state.app.get('state'),
        ticket_group: props.ticket_group || false,
        Model: props.ticket_group ? TicketGroup : Ticket,
        placement: props.ticket_group
            ? `${new TicketGroup().getPlacement()}-${props.related_item.get(new ProvisioningProfile().getUniqueIdentifier())}`
            : `${new Ticket().getPlacement()}-${props.related_item.get(new Endpoint().getUniqueIdentifier())}`,
        initialValues: {},
        formValues: getFormValues('ticketForm')(state)
    };
}, (dispatch) => bindActionCreators({
    setState,
    markOutdated,
    saveItem
}, dispatch))(AddForm);

export default injectIntl(ConnectedAdd);
