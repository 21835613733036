import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {reduxForm, reset as reduxFormReset, getFormValues} from 'redux-form';
import validator from 'lib/valitator';
import {injectIntl, FormattedMessage} from 'react-intl';
// Actions
import {setState, addMessage} from 'actions/app';
import {simplePost} from 'actions/shared';
// Components
import ThemeProvider from 'components/ThemeProvider';
import Form from 'components/core/ui/Form';
import Field from 'components/core/ui/Field';
import SpaceDivider from 'components/core/ui/SpaceDivider';
// material-ui
import Button from 'components/core/ui/mui/Button';
import CardActionsLoader from 'components/core/ui/mui/CardActionsLoader';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// icons
import CancelIcon from '@material-ui/icons/UndoOutlined';
import SubmitIcon from '@material-ui/icons/SendOutlined';


/**
 * Renders dialog with simple form to request new settings
 */
function NewSettingDialog(props) {
    return <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
            <FormattedMessage id='newsetting.title' />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id='newsetting.description' />
            </DialogContentText>
            <SpaceDivider />
            <Form onSubmit={props.handleSubmit} center>
                <Field name='message' fieldType='TextArea' excludePermission
                       label={`${props.intl.formatMessage({id: 'newsetting.form.fields.message'})}*`} />
            </Form>
        </DialogContent>
        {['posting_new_setting_request', 'posted_new_setting_request', 'failed_new_setting_request'].includes(props.state)
            ? <CardActionsLoader dialogActions
                                 success={props.state === 'posted_new_setting_request'}
                                 failure={props.state === 'failed_new_setting_request'}
                                 postAnimation={success => {
                                     // clear state
                                     props.setState(null);
                                     if (success) {
                                         // success message
                                         props.addMessage({
                                             intl_id: 'newsetting.success',
                                             path: 'on-change'
                                         });
                                         // clear form
                                         props.reduxFormReset('requestNewSettingForm');
                                         // close
                                         props.handleClose();
                                     }
                                 }} />
            : <ThemeProvider alt>
            <DialogActions>
                <Button onClick={props.handleClose}>
                    <CancelIcon />
                    <FormattedMessage id='actions.cancel' />
                </Button>
                <Button disabled={props.state !== null}
                        variant='contained' color='primary' type='submit'
                        onClick={() => props.handleSubmit()}>
                    <SubmitIcon />
                    <FormattedMessage id='newsetting.form.submit' />
                </Button>
            </DialogActions>
        </ThemeProvider>}
    </Dialog>;
}

const validate = (data, props) => {
    const errors = {};
    data = props.formValues; // storing object fix
    if (data === undefined) { data = {}; }

    validator.isNotNull(null, errors, 'message', data.message);

    return errors;
};

const NewSettingDialogForm = reduxForm({
    form: 'requestNewSettingForm',
    validate,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
        return dispatch(simplePost('new_setting_request', 'setting-new', props.formValues));
    }
})(NewSettingDialog);

const ConnectedNewSettingDialog = connect((state, props) => ({
    state: state.app.get('state'),
    formValues: getFormValues('requestNewSettingForm')(state)
}), (dispatch) => bindActionCreators({
    simplePost,
    setState,
    addMessage,
    reduxFormReset
}, dispatch))(NewSettingDialogForm);

export default injectIntl(ConnectedNewSettingDialog);
