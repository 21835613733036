import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import Moment from 'moment';
import {makeStyles} from '@material-ui/styles';
// material ui
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from 'components/core/ui/mui/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
// icons
import ResetIcon from '@material-ui/icons/Loop';


const useStyles = makeStyles((theme) => ({
    actionButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    // custom labels not to screw canvas ratio
    labels: {
        // labels next to each other
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: `${theme.spacing(0.5)}px`,
        // space between labels and graph
        marginTop: `${theme.spacing(2)}px`,
        // match style and proportion of graph labels
        '& > button': {
            // icon and label next to each other
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: `${theme.spacing(0.5)}px`,
            // proportion
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            // style
            fontSize: theme.typography.pxToRem(13),
            color: theme.palette.grey[700],
            // strike through label when disabled
            '&.disabled': {
                '& .text': {
                    textDecoration: 'line-through'
                }
            },
            // icon before label
            '& .icon': {
                // size
                height: '18px',
                width: '18px',
                // style
                flexShrink: '0',
                borderRadius: '100%',
                border: `1px solid ${theme.palette.common.white}`
            }
        }
    }
}));

/**
 * Render line chart
 *
 * @param {object} props - component props
 * @param {number} props.id - unique id for graph from useId hook
 * @param {string} props.type - graph type
 * @param {object} props.options - graph options
 * @param {array} props.data - raw data from backend that will be processed into chart
 * @param {<T, R>(data: T) => R} props.processDataItem - mutate structure of data item
 */
export default function Line(props) {
    const {id, type = 'line', options = {}, data = [], momentFormat = 'DD.MM.YYYY', processDataItem, ...rest_of_props} = props;
    const {labels = [], base_intl, series_name_key = 'name', label_key = 'label', value_key = 'value',  ...rest_of_options} = options;
    const classes = useStyles();
    const intl = useIntl();

    const [disabledLabels, setDisabledLabels] = useState(() =>
        options.disabledLabels?.map((label) => intl.formatMessage({id: `${base_intl}.${label}`, defaultMessage: label})) || []
    );

    // convert raw data from backend to series (generic function)
    const series = useMemo(() => {
        return (data instanceof Array ? data : [data]).map((item, index) => {
            const name = labels[index] || item[series_name_key];
            const formattedName = intl.formatMessage({id: `${base_intl}.${name}`, defaultMessage: name});

            return {
                name: formattedName,
                data: item.data.map((dataItem) => {
                    const label = dataItem[label_key];
                    const value = dataItem[value_key];
                    // in future add supporting other types
                    // visit for info - https://apexcharts.com/docs/series/
                    switch (options.xaxis?.type) {
                        case 'datetime':
                        default:
                            return [label, value];
                    }
                })
            };
        });
    }, [JSON.stringify(data), series_name_key, label_key, value_key, labels.length, base_intl, options.xaxis?.type]);

    // check for disabled series
    useEffect(() => {
        const chart = ApexCharts.getChartByID(id);
        series.forEach((series_item) => {
            const formattedName = intl.formatMessage({id: `${base_intl}.${series_item.name}`, defaultMessage: series_item.name});
            const isDisabled = disabledLabels.includes(formattedName);
            isDisabled ? chart.hideSeries(formattedName) : chart.showSeries(formattedName);
        });
    }, [disabledLabels.length, series.length, id]);

    // generate options for apex chart
    const processedOptions = useMemo(() => ({
        dataLabels: {enabled: false},
        stroke: {curve: 'straight', width: 2},
        tooltip: {followCursor: true},
        ...rest_of_options,
        legend: {show: false, ...rest_of_options.legend},
        chart: {
            zoom: {enabled: true},
            toolbar: {show: false},
            ...(rest_of_options.chart || {}),
            id: id, type: type
        },
        xaxis: {
            type: 'datetime', tickAmount: 12,
            ...(rest_of_options.xaxis || {}),
            labels: {
                offsetY: 10, rotate: -15, rotateAlways: true,
                formatter: (_value, timestamp) => Moment(new Date(timestamp)).format(momentFormat),
                ...(rest_of_options.xaxis?.labels || {})
            }
        }
    }), [id, type, JSON.stringify(rest_of_options)]);

    // custom un-zoom function
    const onResetZoom = useCallback(() => {
        series.length && ApexCharts.exec(id, 'zoomX', options.xaxis?.min, options.xaxis?.max);
    }, [id, options.xaxisMin, options.xaxisMax, series.length]);
    
    return <Box>
        <Box className={classes.actionButtonWrapper}>
            <Tooltip title='Reset' placement='top'>
                <IconButton onClick={onResetZoom}>
                    <ResetIcon />
                </IconButton>
            </Tooltip>
        </Box>
        <ReactApexChart type={type} series={series} options={processedOptions} {...rest_of_props} />
        {series.length > 1 ? <div className={classes.labels}>
            {series.map((param, index) => {
                const isDisabled = disabledLabels.includes(param.name);

                return <ButtonBase key={param.name} className={isDisabled ? 'disabled' : undefined}
                    onClick={() => setDisabledLabels(isDisabled ? disabledLabels.filter(seriesName => seriesName !== param.name) : [...disabledLabels, param.name])}>
                    <span className='icon' style={{backgroundColor: options.colors?.[index]}} />
                    <span className='text'>{param.name}</span>
                </ButtonBase>;
            })}
        </div> : null}
    </Box>;
}
