import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    container: {
        margin: '0 auto 0 0', // left-align
        padding: `0 ${theme.spacing(2)}px`,
        maxWidth: '1920px', // limit size
        width: '100%',
        // smooth size change
        '&.smooth': {
            transition: theme.transitions.create(['max-width', 'padding'],
                {duration: theme.transitions.duration.short})
        },
        // lower size
        '&.limited': {
            maxWidth: `${(theme.spacing(4)) + 690}px` // limit size
        },
        // full-size (can be used just for padding)
        '&.full': {
            maxWidth: '100%'
        },
        '&.fullsize': {
            maxWidth: '100%',
            padding: '0',
            // affects Card.js
            '& .full-card': {
                // remove card style
                borderRadius: '0',
                borderLeft: '0',
                borderRight: '0',
                margin: `-${theme.spacing(2) + 1}px 0`,
                [theme.breakpoints.down('xs')]: {
                    margin: `-${theme.spacing(1) + 1}px 0`
                }
            }
        },
        '&.limited, &.full': {
            margin: '0 auto' // center align
        },
        // responsive
        [theme.breakpoints.down('md')]: {
            margin: '0 auto' // center
        },
        [theme.breakpoints.down('xs')]: {
            padding: `0 ${theme.spacing(1)}px`,
            '&.fullsize': {
                padding: '0'
            }
        }
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '&.v-center': {
            justifyContent: 'center'
        },
        '&.space-between': {
            justifyContent: 'space-between'
        },
        '&.h-center': {
            alignItems: 'center'
        },
        '&.h-bottom': {
            alignItems: 'flex-end'
        },
        '&.stretch': {
            alignItems: 'stretch'
        },
        margin: `0 -${theme.spacing(1)}px`,
        '&.nospace': {
            margin: '0 !important',
            '& > div': {
                margin: '0 !important',
                padding: '0 !important'
            },
            '& > div > .colwrap': {
                left: '0 !important',
                right: '0 !important'
            }
        },
        '&.undivider': {
            marginBottom: `-${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                marginBottom: `-${theme.spacing(1)}px`
            }
        },
        // responsive
        [theme.breakpoints.down('sm')]: {
            '&.wrap': {
                '& > div': {
                    flexBasis: '100%',
                    width: '100% !important',
                    maxWidth: '100% !important',
                    minWidth: '100% !important',
                    marginBottom: `${theme.spacing(2)}px`,
                    '&:last-child, &.nobmargin': {
                        marginBottom: '0'
                    }
                },
                '&.switch > div': {
                    '&:nth-last-child(2), &.nobmargin': {
                        marginBottom: '0'
                    },
                    '&:last-child': {
                        marginBottom: `${theme.spacing(2)}px`,
                        order: '-1'
                    }
                }
            }
        },
        [theme.breakpoints.down('xs')]: {
            margin: `0 -${theme.spacing(1)}px`,
            '&.wrap': {
                '& > div': {
                    marginBottom: `${theme.spacing(1)}px`,
                    '&:last-child, &.nobmargin': {
                        marginBottom: '0'
                    }
                },
                '&.switch > div': {
                    '&:nth-last-child(2), &.nobmargin': {
                        marginBottom: '0'
                    },
                    '&:last-child': {
                        marginBottom: `${theme.spacing(1)}px`
                    }
                }
            }
        }
    },
    col: {
        flexGrow: '1',
        maxWidth: '100%',
        width: '0',
        padding: `0 ${theme.spacing(1)}px`,
        position: 'relative',
        '&.h-divider': {
            marginBottom: `${theme.spacing(2)}px`,
            // responsive
            [theme.breakpoints.down('xs')]: {
                marginBottom: `${theme.spacing(1)}px !important`
            }
        }
    },
    colWrap: {
        position: 'absolute',
        bottom: '0',
        top: '0',
        left: `${theme.spacing(1)}px`,
        right: `${theme.spacing(1)}px`,
        // responsive
        [theme.breakpoints.down('xs')]: {
            left: `${theme.spacing(1)}px`,
            right: `${theme.spacing(1)}px`
        }
    }
}));

/**
 * Page content holder
 */
function Container(props) {
    const {className, smooth, limited, full, fullsize, children, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        classes.container, className || '',
        smooth ? 'smooth' : '',
        limited ? 'limited' : full ? 'full' : fullsize ? 'fullsize' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} {...rest_of_props}>
        {children}
    </div>;
}

/**
 * Row for Grid system
 *
 * Props:
 *  wrap        - Responsive wrap (cols become 100%)
 *  wrapSwitch  - Responsive wrap (cols became 100% and switch places - last become first)
 *  nospace     - no space between cols
 *  vCenter     - Vertical center
 *  hCenter     - Horizontal center
 *  hBottom     - Horizontal bottom
 *  center      - Center
 *  stretch     - Cols will stretch (flexbox). Not working with 'hCenter' or 'center'
 *  undivider   - Nulls 'divider' (h-divider) on last column
 */
function Row(props) {
    const {className, children, wrap, wrapSwitch, nospace, undivider,
        center, vCenter, stretch, spaceBetween, hCenter, hBottom, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        classes.row, className || '',
        wrap ? 'wrap' : '',
        wrapSwitch ? 'wrap switch' : '',
        nospace ? 'nospace' : '',
        undivider ? 'undivider' : '',
        // vertical
        center ? 'v-center' : vCenter ? 'v-center' : stretch ? 'stretch' : '',
        // horizontal
        center ? 'h-center' : hCenter ? 'h-center' : hBottom ? 'h-bottom' : spaceBetween ? 'space-between' : ''
    ].filter(Boolean).join(' ');

    return <div className={rootClasses} {...rest_of_props}>
        {children}
    </div>;
}

/**
 * Col (should be used as Row children)
 *
 * Props:
 *  width       - Specify width either with PXs or %
 *  maxWidth    - limit size
 *  minHeight   - limit size
 *  flex        - define flex-[grow, shrink, basis]
 *  relative    - Make Col absolute to Col. Works great with <Row stretch />
 *  divider     - Height divider bellow
 */
function Col(props) {
    const {className, children, width, minWidth, maxWidth, minHeight, divider, relative, flex, ...rest_of_props} = props;
    const classes = useStyles();
    const rootClasses = [
        classes.col, className || '',
        divider ? 'h-divider' : ''
    ].filter(Boolean).join(' ');
    const style = {
        ...(width ? {minWidth: width, flexGrow: 0} : {}),
        ...(minWidth ? {width: minWidth} : {}),
        ...(maxWidth ? {maxWidth: maxWidth} : {}),
        ...(minHeight ? {minHeight: minHeight} : {}),
        ...(flex && {flex})
    };

    return <div className={rootClasses} style={style} {...rest_of_props}>
        {relative
            ? <div className={`${classes.colWrap} colwrap`}>
            {children}
        </div>
            : children}
    </div>;
}

export {Container, Row, Col};
