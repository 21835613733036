import React from 'react';
// material-ui
import SvgIcon from '@material-ui/core/SvgIcon';


/**
 * Austria flag
 */
export default function AtFlag(props) {
    const {viewBox = '0 0 640 480', ...rest_of_props} = props;

    return <SvgIcon viewBox={viewBox} {...rest_of_props}>
        <g fillRule='evenodd'>
            <path fill='#fff' d='M640 480H0V0h640z' />
            <path fill='#ed2939' d='M640 480H0V320h640zm0-319.9H0V.1h640z' />
        </g>
    </SvgIcon>;
}
